import { useIntl } from '@weenat/wintl'
import Button from 'app/src/kit/Button'
import Card from 'app/src/kit/Card'
import CollapsibleCard from 'app/src/kit/CollapsibleCard'
import Icons from 'app/src/kit/Icons'
import Text from 'app/src/kit/Text'
import { memo } from 'react'
import { styled } from 'styled-components'
import { HelpLinkProps } from './HelpLink'
import SubSection from './HelpSubSection'

const SensorLinksSectionDescription = styled(Text)`
  margin-bottom: ${(p) => p.theme.spacings.md}px;
`

const VideoTutorialTitle = styled(Text)`
  margin: ${(p) => p.theme.spacings.md}px 0;
`

const SensorsSetupSection: FC = () => {
  const { t } = useIntl()

  const humidityProbeLinks: HelpLinkProps[] = [
    {
      href: t('weenat.help.maintenance.humidityProbe.videoUrl'),
      message: 'help.sensor_maintenance_humidity_probe_video',
      source: 'youtube'
    }
  ]

  const rainGaugeLinks: HelpLinkProps[] = [
    {
      href: t('weenat.help.installation.rainGauge.videoUrl'),
      message: 'help.installation_rain_gauge_video',
      source: 'youtube'
    },
    {
      href: t('weenat.help.maintenance.rainGauge.videoUrl'),
      message: 'help.sensor_maintenance_rain_gauge_video',
      source: 'youtube'
    }
  ]

  const anemometerLinks: HelpLinkProps[] = [
    {
      href: t('weenat.help.installation.anenometer.videoUrl'),
      message: 'help.installation_anenometer_video',
      source: 'youtube'
    }
  ]

  const irrigationKitLinks: HelpLinkProps[] = [
    {
      href: t('weenat.help.installation.irrigationKit.videoUrl'),
      message: 'help.installation_irrigation_kit_video',
      source: 'youtube'
    }
  ]

  const capacitiveProbeLinks: HelpLinkProps[] = [
    {
      href: t('weenat.help.installation.capacitiveProbe.videoUrl'),
      message: 'help.installation_capacitiveProbe_video',
      source: 'youtube'
    }
  ]

  const frostSensorLinks: HelpLinkProps[] = [
    {
      href: t('weenat.help.installation.frostSensor.videoUrl'),
      message: 'help.installation_frost_sensor_video',
      source: 'youtube'
    }
  ]

  return (
    <CollapsibleCard title={t('help.installation_title')} $mb='lg'>
      <Flex $flexDirection='column' $gap='lg'>
        <Card $borderColor={'primary.800'} $backgroundColor={'primary.500'}>
          <Flex $flexDirection='column' $alignItems='center' $justifyContent='center' $width='100%'>
            <Icons.PdfFile $color={'grayscale.white'} $size='xl' $mb='md' />
            <SensorLinksSectionDescription $color={'grayscale.white'} $textAlign='center'>
              {t('help.find_all_guides_on_website')}
            </SensorLinksSectionDescription>
            <Button
              IconRight={Icons.OpenInNew}
              backgroundColor={'grayscale.white'}
              color={'primary.500'}
              onPress={() => {
                window.open(t('help.all_sensors_guides_url').trim(), '_blank')
              }}
            >
              {t('actions.lets_go')}
            </Button>
          </Flex>
        </Card>
        <VideoTutorialTitle $fontWeight='bold' $fontSize='md'>
          {t('help.video_tutorials', { capitalize: true })}
        </VideoTutorialTitle>
        <SubSection title='help.installation_rain_gauge_title' links={rainGaugeLinks} />
        <SubSection title='help.installation_anenometer_title' links={anemometerLinks} />
        <SubSection title='help.installation_irrigation_kit_title' links={irrigationKitLinks} />
        <SubSection title='help.installation_capacitiveProbe_title' links={capacitiveProbeLinks} />
        <SubSection
          title='help.sensor_maintenance_humidity_probe_title'
          links={humidityProbeLinks}
        />
        <SubSection title='help.installation_frost_sensor_title' links={frostSensorLinks} />
      </Flex>
    </CollapsibleCard>
  )
}

export default memo(SensorsSetupSection)
