import { styled } from 'styled-components'

type BannerFeedback = 'info' | 'warn' | 'error' | 'danger' | 'success'

const Banner = styled(Box)<{ $feedback?: BannerFeedback }>`
  z-index: 10;
  padding: ${(p) => p.theme.spacings.lg}px;
  background-color: ${({ $feedback, theme }) =>
    $feedback === 'info'
      ? theme.colors.primary['500']
      : $feedback === 'danger' || $feedback === 'error'
        ? theme.colors.feedback.error['500']
        : $feedback === 'warn'
          ? theme.colors.feedback.warning['500']
          : $feedback === 'success'
            ? theme.colors.feedback.success['500']
            : theme.colors.grayscale.black};
`

export default Banner
