import { useSlotProvider } from 'app/state'
import { styled } from 'styled-components'
const ActionsContainer = styled(Flex)`
  flex-direction: row;
  justify-content: flex-end;
`

const LayoutHeaderActionsArea: FC = () => {
  const [{ headerActions }] = useSlotProvider()
  return <ActionsContainer>{headerActions}</ActionsContainer>
}

export default LayoutHeaderActionsArea
