import { useIntl } from '@weenat/wintl'
import { useNavigate } from 'app/routx-router'
import Button from 'app/src/kit/Button'
import Icons from 'app/src/kit/Icons'
import LinkFromKit from 'app/src/kit/LinkComponent'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import { styled } from 'styled-components'
import { PlotCreationStepProps } from './types'

const SUCCESS_CIRCLE_SIZE = 64

const Link = styled(LinkFromKit)`
  margin-left: auto;
  margin-right: auto;
`

const SuccessCircle = styled(Flex)`
  align-items: center;
  justify-content: center;

  width: ${SUCCESS_CIRCLE_SIZE}$px;
  height: ${SUCCESS_CIRCLE_SIZE}$px;

  border-radius: ${({ theme }) => theme.radiuses.rounded}px;
  background-color: ${({ theme }) => theme.colors.feedback.success['500']};
  margin: auto;
`

interface SuccessStepProps
  extends Pick<PlotCreationStepProps, 'searchParams' | 'handleDeleteShape'> {}

export const SuccessStep = ({ searchParams, handleDeleteShape }: SuccessStepProps) => {
  const { t } = useIntl()
  const nav = useNavigate()

  return (
    <Flex
      $flexDirection='column'
      $gap='xl'
      $borderRadius={4}
      $borderColor={'grayscale.100'}
      $borderWidth={1}
      $p='lg'
      $backgroundColor={'grayscale.white'}
    >
      <Text $fontWeight='bold'>{t('models.plot.creation.success_step_title')}</Text>
      <SuccessCircle>
        <Icons.Checkmark $color={'grayscale.white'} $size='lg' />
      </SuccessCircle>
      <Button
        onPress={() => {
          if (!isNil(searchParams.plotId)) {
            handleDeleteShape()
            nav(`/plots/${searchParams.plotId}`)
          }
        }}
      >
        {t('models.plot.misc.successful_added_virtual_device_see_plot')}
      </Button>
      <Link $color={'primary-500'} to='/plots' onPress={handleDeleteShape}>
        {t('models.plot.creation.success_step_back_cta')}
      </Link>
    </Flex>
  )
}
