import Text, { TextProps } from 'app/src/kit/Text'
import { HTMLProps } from 'react'
import { Tooltip, TooltipContent, TooltipOptions, TooltipTrigger } from './Tooltip'

interface TextTooltipProps extends TooltipOptions, Omit<TextProps, 'children'> {
  content: string
  style?: HTMLProps<HTMLDivElement>['style']
}

const TextTooltip: FC<TextTooltipProps> = ({
  children,
  content,
  initialOpen,
  placement,
  open,
  disabled,
  onOpenChange,
  style,
  restMs,
  enableSafePolygon,
  ...textProps
}) => {
  return (
    <Tooltip
      initialOpen={initialOpen}
      placement={placement}
      open={open}
      disabled={disabled}
      onOpenChange={onOpenChange}
      restMs={restMs}
      enableSafePolygon={enableSafePolygon}
    >
      <TooltipTrigger>{children}</TooltipTrigger>
      <TooltipContent style={style}>
        <Text $color={'grayscale.white'} {...textProps}>
          {content}
        </Text>
      </TooltipContent>
    </Tooltip>
  )
}

export default TextTooltip
