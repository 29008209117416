import { InvitationStatus } from '@weenat/client/dist/enums'
import { Invitation } from '@weenat/client/dist/resources/invitations'
import { WeenatColor } from '@weenat/theme'
import { useIntl } from '@weenat/wintl'
import isNil from 'lodash-es/isNil'
import { memo } from 'react'
import AdminBadge from './AdminBadge'

type Qualifier = keyof typeof InvitationStatus

interface StatusBadgeProps {
  invitation: Invitation
}

const StatusBadge: FC<StatusBadgeProps> = ({ invitation }) => {
  const { t } = useIntl()
  const { status } = invitation

  let qualifier: Qualifier | null = null
  let backgroundColor: WeenatColor = 'primary.500'
  let labelColor: WeenatColor = 'grayscale.white'

  switch (status) {
    case InvitationStatus.accepted:
      qualifier = 'accepted'
      backgroundColor = 'feedback.success.500'
      labelColor = 'grayscale.black'
      break

    case InvitationStatus.declined:
      qualifier = 'declined'
      backgroundColor = 'feedback.error.500'
      labelColor = 'grayscale.black'
      break

    case InvitationStatus.pending:
      qualifier = 'pending'
      break

    case InvitationStatus.duplicated:
      qualifier = 'duplicated'
      backgroundColor = 'grayscale.500'
      break

    case InvitationStatus.invalid:
      qualifier = 'invalid'
      backgroundColor = 'feedback.warn.500'
      labelColor = 'grayscale.black'
      break

    case InvitationStatus.cancel:
      qualifier = 'cancel'
      backgroundColor = 'feedback.error.500'
      labelColor = 'grayscale.black'
      break
  }

  const message = !isNil(qualifier)
    ? t(`qualifiers_feminine.${qualifier}`, { capitalize: true })
    : null

  return !isNil(message) ? (
    <AdminBadge $backgroundColor={backgroundColor} labelColor={labelColor} label={message} />
  ) : null
}

export default memo(StatusBadge)
