import { Universe } from '@weenat/client/dist/core/universes'
import { DeviceSummary } from '@weenat/client/dist/resources/measurements.type'
import { useIntl } from '@weenat/wintl'
import Icons from 'app/src/kit/Icons'
import { TextFieldPrimitive } from 'app/src/kit/fields/TextField'
import isNil from 'lodash-es/isNil'
import noop from 'lodash-es/noop'
import { useCallback, useEffect, useRef } from 'react'
import { styled } from 'styled-components'
import Geolocation from '../../../map/Geolocation'
import ControlsContainer from './ControlsContainer'
import DashboardMapLoading from './DashboardMapLoading'
import MapControlsOverlay from './MapControlsOverlay'
import MapOverlay from './MapOverlay'
import Radar from './Radar/Radar'
import useIsRadarEnabled from './Radar/hooks/useIsRadarEnabled'
import RefreshBackgroundMap from './RefreshBackgroundMap'
import ZoomButtons from './ZoomButtons'
import { elementIds } from './constants'
import {
  useBackgroundMapContext,
  useBackgroundMapDispatcher
} from './contexts/BackgroundMapContext'
import NewActionsControls from './universes/NewActionsControls'
import UniverseChipsTabs from './universes/UniverseChipsTabs'

const PlacesInput = styled(TextFieldPrimitive)`
  position: relative;
  z-index: 1;

  min-width: 256px;
  max-width: 480px;

  margin: 0px;
  pointer-events: auto;
`

interface WeatherMapControlsProps {
  summaries: DeviceSummary[]
}

const WeatherMapControls: FC<WeatherMapControlsProps> = ({ summaries }) => {
  const { t } = useIntl()
  const [isRadarEnabled] = useIsRadarEnabled()

  const {
    api: { map, maps }
  } = useBackgroundMapContext()
  const dispatch = useBackgroundMapDispatcher()

  const autocompleteInputRef = useRef(null)

  const onGeolocationSuccess = useCallback<PositionCallback>(
    ({ coords: { latitude, longitude } }) => {
      dispatch({
        type: 'setGeolocation',
        newGeolocation: {
          lat: latitude,
          lng: longitude
        }
      })
    },
    [dispatch]
  )

  useEffect(() => {
    if (!isNil(map) && !isNil(maps) && !isNil(autocompleteInputRef.current)) {
      new maps.places.PlacesService(map)

      const googlePlacesAutocompleteInput = new maps.places.Autocomplete(
        autocompleteInputRef.current
      )

      const placeChangeListener = maps.event.addListener(
        googlePlacesAutocompleteInput,
        'place_changed',
        () => {
          const { geometry: { location, viewport } = {} } = googlePlacesAutocompleteInput.getPlace()

          if (!isNil(location) && !isNil(viewport)) {
            dispatch({
              type: 'setTownGeolocation',
              newGeolocation: {
                location: {
                  lat: location.lat(),
                  lng: location.lng()
                },
                bounds: viewport
              }
            })
          }
        }
      )

      return () => maps.event.removeListener(placeChangeListener)
    }
    return undefined
  }, [map])

  return (
    <MapControlsOverlay>
      {!isRadarEnabled ? (
        <Flex $flex={1} $flexDirection='column' $justifyContent='space-between'>
          {/* TOP */}
          <Flex $alignItems='flex-start' $justifyContent='space-between' $gap='md'>
            <Flex $alignItems='center' $gap='md' $flex={1}>
              <UniverseChipsTabs />

              <PlacesInput
                onChange={noop}
                hideErrors
                id='googlePlacesAutocompleteInput'
                type='text'
                ref={autocompleteInputRef}
                placeholder={t('actions.search_city')}
                leftAdornment={<Icons.TravelExplore $size='md' />}
              />
            </Flex>
          </Flex>

          {/* FILL SPACE */}
          <Flex $flexDirection='column' $flex={1} $width='100%' $gap='lg'>
            <Flex
              $flex={1}
              $flexDirection='column'
              $justifyContent='center'
              $alignItems='center'
              $alignSelf='flex-end'
              $gap='lg'
            >
              <ControlsContainer $flexDirection='column-reverse' $py='sm'>
                <ZoomButtons />
              </ControlsContainer>

              <ControlsContainer>
                <Geolocation onSuccess={onGeolocationSuccess} tooltipPlacement='left' />
              </ControlsContainer>

              <ControlsContainer>
                <RefreshBackgroundMap tooltipPlacement='left' $size='xl' $m='sm' $rounded />
              </ControlsContainer>

              <Radar map={map} maps={maps} />
            </Flex>
            <NewActionsControls summaries={summaries} />
          </Flex>
        </Flex>
      ) : (
        <Radar map={map} maps={maps} />
      )}
    </MapControlsOverlay>
  )
}

const EMPTY_ARRAY: Universe[] = [] as const

type WeatherMapOverlayProps = {
  isLoading: boolean
  summaries: DeviceSummary[]
}

const WeatherMapOverlay: FC<WeatherMapOverlayProps> = ({ children, isLoading, summaries }) => {
  const {
    api: { map, maps }
  } = useBackgroundMapContext()

  const displayControls = !isNil(map) && !isNil(maps)

  return (
    <MapOverlay id={elementIds.OverlayContainer}>
      {isLoading ? (
        <DashboardMapLoading />
      ) : (
        <>
          {displayControls ? (
            <WeatherMapControls
              // all summaries not only current
              summaries={summaries}
            />
          ) : null}
          {children}
        </>
      )}
    </MapOverlay>
  )
}

export default WeatherMapOverlay
