import { useIntl } from '@weenat/wintl'
import TextField, { CustomFieldProps } from './TextField'

const OrgNameField: FC<CustomFieldProps> = ({ name = 'organizationName', ...props }) => {
  const { t } = useIntl()
  return (
    <TextField
      name={name}
      label={t('models.billingAccount.fields.organizationName.label', { capitalize: true })}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
}

export default OrgNameField
