import { Fragment } from 'react'
import { styled } from 'styled-components'
import Scrollable from '../kit/Scrollable'
import LayoutHeaderActionsArea from './LayoutHeaderActionsArea'

const ChildrenWrapper = styled(Flex)`
  position: relative;
  flex: 1;
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.grayscale[50]};
  border-radius: 0 ${(p) => p.theme.radiuses.xl}px 0 0;
  left: -${(p) => p.theme.radiuses.xl}px;
  margin-right: -${(p) => p.theme.radiuses.lg}px;
  padding-left: ${(p) => p.theme.radiuses.xl}px;
`

const ChildrenContainer = styled(Box)<{
  $useAllWidth?: boolean
  $useRelativeHeight?: boolean
}>`
  height: 100%;
  margin: auto;
  padding: ${(p) => p.theme.spacings.xl}px;
  padding-top: 0;

  ${(p) =>
    !p.$useAllWidth &&
    `
      max-width: 1080px;
    `}

  ${(p) =>
    p.$useRelativeHeight &&
    `
      height: initial;
    `}
`

const PublicLayout: FC<{ isScrollable?: boolean }> = ({ children, isScrollable = true }) => {
  const MaybeScrollable = isScrollable ? Scrollable : Fragment
  return (
    <ChildrenWrapper>
      <Flex $alignItems='center' $justifyContent='flex-end' $px='2xl' $py='md'>
        <LayoutHeaderActionsArea />
      </Flex>

      <Flex $flexDirection='column' $flex={1}>
        <MaybeScrollable>
          <ChildrenContainer>{children}</ChildrenContainer>
        </MaybeScrollable>
      </Flex>
    </ChildrenWrapper>
  )
}

export default PublicLayout
