import { useClient, useQuery } from '@weenat/client'
import { Horizon } from '@weenat/client/dist/resources/horizons'
import { Id } from '@weenat/client/dist/resources/types'
import { useIntl } from '@weenat/wintl'
import Text from 'app/src/kit/Text'
import { SelectFieldPrimitive } from 'app/src/kit/fields/SelectField'
import isNil from 'lodash-es/isNil'
import { memo, useMemo, useState } from 'react'
import { useTheme } from 'styled-components'

const EMPTY_HORIZONS: Horizon[] = []

const HORIZON_REQUEST_STALE_TIME = 60 * 1000
/** 30 CM */
const DEFAULT_HORIZON_ID = 6

interface DeviceHorizonSelectorProps {
  initialId?: Id
  isLoading?: boolean
  onChange: (horizonId: Id) => void
  canEdit: boolean
}

/**
 * Fetches all possible horizon
 * Updates the given measurementConfig on change
 */
const DeviceHorizonSelector: FC<DeviceHorizonSelectorProps> = ({
  initialId,
  onChange,
  isLoading,
  canEdit
}) => {
  const { t } = useIntl()
  const client = useClient()
  const theme = useTheme()

  const horizonsRequest = useQuery(client.horizons.getAll(), {
    staleTime: HORIZON_REQUEST_STALE_TIME
  })

  const horizons = horizonsRequest.data?.results || EMPTY_HORIZONS

  const [value, setValue] = useState(!isNil(initialId) ? initialId : DEFAULT_HORIZON_ID)

  const options = useMemo(
    () =>
      horizons.map((h) => ({
        value: h.id,
        label: `${h.value} cm`
      })),
    [horizons]
  )

  return (
    <Box>
      <SelectFieldPrimitive
        isLoading={isLoading}
        isDisabled={horizonsRequest.isLoading && canEdit}
        value={value}
        options={options}
        onChange={(newId: number) => {
          onChange(newId)
          setValue(newId)
        }}
        placeholder={t('models.device.fields.horizon.label', { capitalize: true })}
      />
      {isNil(value) ? (
        <Text $fontSize='sm' $fontStyle='italic' $color={theme.colors.feedback.error[500]}>
          {t('models.device.actions.add_horizon')}
        </Text>
      ) : null}
    </Box>
  )
}

export default memo(DeviceHorizonSelector)
