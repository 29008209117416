import { useClient, useQuery } from '@weenat/client'
import { useHasAtLeastOneBilledOrg } from '@weenat/client/dist/core/me'
import { DeviceSummary } from '@weenat/client/dist/resources/measurements.type'
import { Slot } from 'app/routx-router'
import WeatherMapOverlay from 'app/src/dashboard/components/DashboardMap/WeatherMapOverlay'
import usePODClusters from 'app/src/dashboard/components/DashboardMap/hooks/usePODClusters'
import useUniverseBackgroundMapContext from 'app/src/dashboard/components/DashboardMap/universes/useUniverseBackgroundMapContext'
import { MapOverlayFallback } from 'app/src/kit/FeatGuarded'
import { useUniverse } from 'app/state'
import { isEmpty, isNil } from 'lodash-es'
import { useMemo } from 'react'

// 10 Minutes
// Might need to change when frost universe is active
const REFETCH_INTERVAL = 10 * 60 * 1000

const EMPTY_DEVICE_SUMMARIES: DeviceSummary[] = []

const WeatherMapLayout = () => {
  const [universe] = useUniverse()
  const client = useClient()

  const { hasAtLeastOneBilledOrg, isLoading: isOrgLoading } = useHasAtLeastOneBilledOrg()
  const { focusedMetricId } = useUniverseBackgroundMapContext()

  const deviceSummariesRequest = useQuery(
    client.measurements.devices.summaries.getAllPages({
      universe
    }),
    {
      enabled: hasAtLeastOneBilledOrg,
      refetchInterval: REFETCH_INTERVAL
    }
  )

  const summaries = deviceSummariesRequest.data ?? EMPTY_DEVICE_SUMMARIES
  const summariesHavingTheSelectedMetric = useMemo(() => {
    let items = summaries

    if (!isNil(focusedMetricId)) {
      items = items.filter((sum) => {
        const metaItem = sum.metadata.device

        return (
          !isEmpty(metaItem.availableMeasures) &&
          metaItem.availableMeasures.some((metric) => metric === focusedMetricId)
        )
      })
    }

    return items
  }, [summaries, focusedMetricId])

  const isLoading = isOrgLoading || deviceSummariesRequest.isLoading

  usePODClusters({
    summaries: summariesHavingTheSelectedMetric,
    isPlotsMap: false,
    isFetching: deviceSummariesRequest.isFetching
  })

  return (
    <WeatherMapOverlay summaries={summaries} isLoading={isLoading}>
      {hasAtLeastOneBilledOrg ? <Slot /> : isLoading ? null : <MapOverlayFallback />}
    </WeatherMapOverlay>
  )
}

export const Component = WeatherMapLayout
export default WeatherMapLayout
