import { useNavigate, usePathname } from 'app/routx-router'
import logEvent from 'app/utils/analytics'

const PLAN_PRESENTATION_PAGE_PATH = 'view_plans_presentation'

const useLogAndNavToUpgradePlans = () => {
  const origin = usePathname()
  const nav = useNavigate()

  return () => {
    logEvent(PLAN_PRESENTATION_PAGE_PATH, { origin })
    nav(`/administration/upgrade-to-premium`, {
      search: { step: 'choose_subscription', origin },
      origin
    })
  }
}

export default useLogAndNavToUpgradePlans
