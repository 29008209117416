import Text from 'app/src/kit/Text'
import { useField } from 'formik'
import isNil from 'lodash-es/isNil'
import React, { useCallback } from 'react'
import { Range, getTrackBackground } from 'react-range'
import { styled, useTheme } from 'styled-components'
import sliderThumbImg from '../../../images/slider-thumb.png'
import Icons from '../Icons'
import FieldLabel from './FieldLabel'

const THUMB_SIZE = '24px'

const ThumbText = styled(Text)`
  white-space: nowrap;
  font-size: ${(p) => p.theme.typography.sizes.sm}px;
  text-align: center;
`

const RangeInputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const SliderContainer = styled.div`
  height: 32px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

interface TrackProps {
  values: number[]
  max: number
  min: number
  $notThresholdedColor: string
  $color: string
}

const Track = styled.div<TrackProps>`
  height: 6px;
  width: calc(100% - ${THUMB_SIZE});
  border-radius: ${(p) => p.theme.radiuses.rounded}px;
  background: ${({ values, $color: $color, $notThresholdedColor, min, max }) =>
    getTrackBackground({
      values,
      colors: [$color, $notThresholdedColor],
      min: min,
      max: max
    })};
  align-self: center;
`

const ThumbContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${THUMB_SIZE};
  width: ${THUMB_SIZE};
  border-radius: ${({ theme }) => theme.radiuses.rounded}px;
  background-color: ${({ theme }) => theme.colors.primary[500]};
  box-shadow: 0 2px 6px rgb(0 0 0 / 20%);
`

const ThumbTextHolder = styled.div<{ $bgColor: string }>`
  position: absolute;
  padding: ${({ theme }) => theme.spacings.sm}px;
  border-radius: ${({ theme }) => theme.radiuses.rounded}px;
  background-color: ${({ $bgColor }) => $bgColor};
  top: -24px;
`

const defaultRenderValue = (x: number) => x.toString()

interface RangeInputProps {
  helpers?: string | React.ReactElement
  hideThumbValue?: boolean
  id: string
  isRequired?: boolean
  label?: string
  max: number
  min: number
  noMargin?: boolean
  onChange: (value: number) => void
  renderValue?: (value: number) => string
  showPlusMinus?: boolean
  /** Defaults to 1 */
  step?: number
  thumbTextBgColor?: string
  value: number
  warning?: string | React.ReactElement
  withoutLabel?: boolean
}

export const RangeInput: React.FC<RangeInputProps> = ({
  noMargin,
  step = 1,
  value,
  thumbTextBgColor = 'transparent',
  onChange,
  id,
  min,
  max,
  showPlusMinus = false,
  label,
  isRequired = true,
  hideThumbValue,
  renderValue = defaultRenderValue,
  helpers,
  warning,
  withoutLabel
}) => {
  const { colors } = useTheme()

  const values = [value]

  // eslint-disable-next-line prefer-destructuring
  const color = colors.primary[500]

  // eslint-disable-next-line prefer-destructuring
  const notThresholdedColor = colors.grayscale[300]

  const handleChange = useCallback(([newValue]: number[]) => onChange(newValue), [onChange])

  const handleMinus = useCallback(
    () => onChange(Math.max(value - step, min)),
    [onChange, value, step, min]
  )

  const handlePlus = useCallback(
    () => onChange(Math.min(value + step, max)),
    [onChange, value, step, max]
  )

  return (
    <Box $width='100%' $my={noMargin ? 0 : 'lg'}>
      {!withoutLabel && (
        <FieldLabel
          label={label}
          isFieldRequired={isRequired}
          helpers={helpers}
          warning={warning}
        />
      )}

      <RangeInputContainer id={id}>
        {showPlusMinus ? (
          <Box $pr='sm'>
            <Icons.MinusSign onPress={handleMinus} $size='lg' />
          </Box>
        ) : null}
        <Box $width='100%'>
          <Range
            step={step}
            min={min}
            max={max}
            values={values}
            onChange={handleChange}
            renderTrack={({ props, children }) => (
              <SliderContainer
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={props.style}
              >
                <Track
                  ref={props.ref}
                  $color={color}
                  $notThresholdedColor={notThresholdedColor}
                  min={min}
                  max={max}
                  values={values}
                >
                  {children}
                </Track>
              </SliderContainer>
            )}
            renderThumb={({ props }) => (
              <ThumbContainer {...props} style={props.style}>
                <img src={sliderThumbImg} alt='slider-thumb' />
                {!hideThumbValue && (
                  <ThumbTextHolder $bgColor={thumbTextBgColor}>
                    <ThumbText>{renderValue(value)}</ThumbText>
                  </ThumbTextHolder>
                )}
              </ThumbContainer>
            )}
          />
        </Box>
        {showPlusMinus ? <Icons.PlusSign onPress={handlePlus} $size='lg' /> : null}
      </RangeInputContainer>
    </Box>
  )
}

type SliderFieldPrimitiveProps = Omit<RangeInputProps, 'id'>

const SliderFieldPrimitive: FC<SliderFieldPrimitiveProps> = ({
  min,
  max,
  onChange,
  step,
  value,
  label,
  showPlusMinus
}) => (
  <RangeInput
    hideThumbValue
    id='slider'
    label={label}
    max={max}
    min={min}
    onChange={onChange}
    showPlusMinus={showPlusMinus}
    step={step}
    value={value}
  />
)

export type SliderFieldProps = Omit<SliderFieldPrimitiveProps, 'onChange' | 'value'> & {
  name: string
  customLabel?: string
}

/** Return a correctly rounded slider value */
const sliderRoundedValue = (x: number) => Math.round((x + Number.EPSILON) * 100) / 100

const SliderField: FC<SliderFieldProps> = ({ min, max, name, customLabel, label, ...props }) => {
  const [field, , helpers] = useField<number>(name)

  return (
    <SliderFieldPrimitive
      label={!isNil(customLabel) ? customLabel : `${label} : ${field.value}`}
      /** See https://stackoverflow.com/a/41716722 */
      onChange={(x) => helpers.setValue(sliderRoundedValue(x))}
      value={field.value}
      min={min}
      max={max}
      {...props}
    />
  )
}

export default SliderField
