import { Slot } from 'app/routx-router'
import PublicLayout from 'app/src/layouts/PublicLayout'
export const settings = {
  public: 'always'
}
const NetworksLayout: FC = () => {
  return (
    <PublicLayout>
      <Slot />
    </PublicLayout>
  )
}

export const Component = NetworksLayout
export default NetworksLayout
