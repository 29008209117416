import { HTMLProps } from 'react'
import { css, styled } from 'styled-components'
import WeenatPressable from './WeenatPressable'

const StyledCard = styled(WeenatPressable)<{
  $isSelected: boolean
  $isDisabled?: boolean
}>`
  cursor: pointer;

  border-radius: ${(p) => p.theme.radiuses.md}px;
  padding: 16px;

  border-style: solid;
  border-width: 1px;
  border-color: ${(p) => p.theme.colors.grayscale[300]};

  ${(p) =>
    p.$isSelected &&
    css`
      border-color: ${p.theme.colors.primary[500]};
      background-color: ${p.theme.colors.primary[200]};
    `}

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.75;
    `}
    
  &:hover {
    border-color: ${(p) =>
      p.$isDisabled ? p.theme.colors.grayscale.white : p.theme.colors.primary[500]};
  }

  transition: border-color 0.3s ease;
  will-change: border-color;
`

interface SelectableCardProps extends Omit<HTMLProps<HTMLDivElement>, 'onClick'> {
  isSelected: boolean
  isDisabled: boolean
  onPress: HTMLProps<HTMLDivElement>['onClick']
}

const SelectableCard: FC<SelectableCardProps> = ({
  children,
  isSelected,
  isDisabled,
  onPress,
  ...props
}) => {
  return (
    <StyledCard
      $isSelected={isSelected}
      $isDisabled={isDisabled}
      onPress={isDisabled ? undefined : onPress}
      {...props}
    >
      {children}
    </StyledCard>
  )
}

export default SelectableCard
