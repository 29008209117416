import { Me } from '@weenat/client/dist/resources/me'
import isNil from 'lodash-es/isNil'
import { forwardRef } from 'react'
import { css, styled } from 'styled-components'
import Text from './Text'
import { FlexProps } from './primitives/themeMappings/props'

const AvatarBubble = styled(Flex)`
  align-items: center;
  justify-content: center;

  border-radius: ${(p) => p.theme.radiuses.rounded}px;
  border: 2px solid transparent;
  background-color: ${(p) => p.theme.colors.grayscale[100]};

  ${(p) =>
    !isNil(p.onClick)
      ? css`
          &:hover {
            border-color: ${(p) => p.theme.colors.grayscale[300]};
            border-width: 2px;
            cursor: pointer;
          }
        `
      : ''}

  will-change: border-color, border-width;
  transition:
    border-color 0.1s linear,
    border-width 0.1s linear;
`

interface AvatarProps {
  me?: Me
  size?: 'lg' | 'rg'
  onPress?: FlexProps['onClick']
  className?: string
}

const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  ({ me, size = 'rg', onPress, className }, ref) => {
    const avatarSize = size === 'rg' ? 32 : 48

    return (
      <AvatarBubble
        ref={ref}
        $width={avatarSize}
        $height={avatarSize}
        onClick={onPress}
        className={className}
      >
        <Text $fontWeight='bold' $fontSize={size}>
          {(me?.firstName ?? 'W').charAt(0).toUpperCase() +
            (me?.lastName ?? 'W').charAt(0).toUpperCase()}
        </Text>
      </AvatarBubble>
    )
  }
)

export default Avatar
