import Link, { StyledLinkProps } from './LinkComponent'

interface PhoneLinkProps extends Omit<StyledLinkProps, 'href' | 'to' | 'target'> {
  phoneNumber: string
}

const PhoneLink: FC<PhoneLinkProps> = ({ phoneNumber, ...props }) => (
  <Link {...props} href={`tel:${phoneNumber}`} target='_blank' />
)

export default PhoneLink
