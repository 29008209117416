import { Device } from '@weenat/client/dist/resources/devices'
import { Horizon } from '@weenat/client/dist/resources/horizons'
import { useIntl } from '@weenat/wintl'
import DeviceCard from 'app/src/devices/DeviceCard'
import Text from 'app/src/kit/Text'
import AddOrRemoveDeviceButton from 'app/src/plots/creation/AddOrRemoveDeviceButton'
import type { PlotCreationStepProps } from 'app/src/plots/creation/types'
import { showHorizonForDevice } from 'app/src/plots/creation/utils'
import isNil from 'lodash-es/isNil'
import { CSSProperties, memo } from 'react'
import { styled } from 'styled-components'
import Icons from '../kit/Icons'
import WeenatPressable from '../kit/WeenatPressable'

const Pressable = styled(WeenatPressable)<{ $isSelected: boolean }>`
  background-color: ${(p) => p.theme.colors.grayscale[100]};
  padding: ${(p) => p.theme.spacings.md}px;
  border-radius: ${(p) => p.theme.radiuses.lg}px;
  border: 1px solid
    ${(p) =>
      p.$isSelected ? p.theme.colors.feedback.success['500'] : p.theme.colors.grayscale[700]};

  &:hover {
    border-color: ${(p) => p.theme.colors.primary[500]};
  }

  transition: border-color 0.3s ease-in-out;
  will-change: border-color;
`

export interface DeviceSelectionCardProps
  extends Pick<PlotCreationStepProps, 'add' | 'remove' | 'org' | 'focus'> {
  device: Device
  depth?: string
  isSelected: boolean
  isClosest: boolean
  style: CSSProperties
  horizons: Horizon[]
}

const DeviceSelectionCard: FC<DeviceSelectionCardProps> = memo(
  ({ isClosest, device, isSelected, depth, style, add, remove, org, focus, horizons }) => {
    const { t, formatNumber } = useIntl()

    const { distanceFrom } = device

    const distanceFormatted = formatNumber(!isNil(distanceFrom) ? distanceFrom / 1000 : undefined, {
      unit: 'kilometers',
      abbreviatedUnit: true
    })

    return (
      <Box style={style}>
        <Pressable $isSelected={isSelected} onPress={() => focus(device.id)}>
          <Flex $pb='md' $alignItems='center' $justifyContent='space-between'>
            <Flex $alignItems='center' $gap='xs'>
              <Icons.Distance $size='rg' />
              <Text $fontSize='sm'>
                {isClosest
                  ? t('models.device.nearest_from_plot', { distance: distanceFormatted })
                  : t('models.device.distance_to_plot', { distance: distanceFormatted })}
              </Text>
            </Flex>
            <Flex $alignItems='center' $gap='md'>
              <AddOrRemoveDeviceButton
                billingStatus={org.billingStatus}
                device={device}
                isSelected={isSelected}
                onAdd={add}
                onRemove={remove}
                showHorizons={showHorizonForDevice(device)}
                variant='iconOnly'
                horizons={horizons}
                depth={depth}
              />
            </Flex>
          </Flex>
          <DeviceCard device={device} depth={depth} />
        </Pressable>
      </Box>
    )
  }
)

export default DeviceSelectionCard
