import { HeadquartersLocationPin, PlotLocationPin } from 'app/src/kit/icons/generic-icons'

const SIZE = 48

interface ItemLocationPinProps {
  mt?: number
  item: 'plot' | 'headquarter'
}

const ItemLocationPin = ({ mt, item }: ItemLocationPinProps) => {
  return (
    <Box $position='relative' $mt={mt}>
      {item === 'plot' ? (
        <PlotLocationPin height={SIZE} width={SIZE} />
      ) : (
        <HeadquartersLocationPin height={SIZE} width={SIZE} />
      )}
    </Box>
  )
}

export default ItemLocationPin
