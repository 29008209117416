import { useIntl } from '@weenat/wintl'
import AppUsageSupportSection from 'app/src/help/components/AppUsageSupportSection'
import ConfidentialitySection from 'app/src/help/components/ConfidentialitySection'
import CookiesPolicySection from 'app/src/help/components/CookiesPolicySection'
import CustomerSupportSection from 'app/src/help/components/CustomerSupportSection'
import DSSGuidesSection from 'app/src/help/components/DSSGuidesSection'
import LegalNoticeSection from 'app/src/help/components/LegalNoticeSection'
import SensorsSetupSection from 'app/src/help/components/SensorsSetupSection'
import HelmetAndBreadcrumbs from 'app/src/kit/HelmetAndBreadcrumbs'
import LayoutHeaderActions from 'app/src/layouts/LayoutHeaderActions'

const HelpScreen = () => {
  const { t } = useIntl()

  return (
    <>
      <HelmetAndBreadcrumbs screenTitle={t('titles.help')} />
      <LayoutHeaderActions />
      <Box $py='lg'>
        <CustomerSupportSection />
        <AppUsageSupportSection />
        <SensorsSetupSection />
        <DSSGuidesSection />
        <LegalNoticeSection />
        <ConfidentialitySection />
        <CookiesPolicySection />
      </Box>
    </>
  )
}

export const Component = HelpScreen
export default HelpScreen
