import DelimitedBox from 'app/src/kit/DelimitedBox'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import AccountSectionDescription from './AccountSectionDescription'

interface AccountSectionProps {
  title: string
  description?: string
}

const AccountSection: FC<AccountSectionProps> = ({ children, description, title }) => {
  return (
    <DelimitedBox $width='100%' $py='md' $mb='lg'>
      <Text $fontSize='md' $fontWeight='medium' $lineHeight='lg'>
        {title}
      </Text>
      {!isNil(description) ? (
        <AccountSectionDescription>{description}</AccountSectionDescription>
      ) : null}
      <Box $width='100%' $my='md'>
        {children}
      </Box>
    </DelimitedBox>
  )
}

export default AccountSection
