import { LogoFullLight } from 'app/src/kit/icons/logos'
import { styled } from 'styled-components'
import fieldsImg from '../../../images/field-view.webp'

type AuthLayoutProps = { $headerPosition?: 'top' | 'left' }

const FieldView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  flex: 1;
  max-width: 1024px;

  background:
    linear-gradient(rgb(0 0 0 / 10%), rgb(0 0 0 / 10%)),
    url(${fieldsImg}) no-repeat center center fixed;
  background-size: cover;

  border-radius: 24px;
  padding: 32px;

  user-select: none;
  user-drag: none;
`

const AuthLayout: FC<AuthLayoutProps> = ({ children }) => {
  return (
    <Flex $p='2xl' $flex={1}>
      <FieldView>
        <LogoFullLight height={96} />
      </FieldView>
      <Flex $flexDirection='column' $justifyContent='space-around' $flex={1}>
        {children}
      </Flex>
    </Flex>
  )
}

export default AuthLayout
