import { useOrgContext } from 'app/orgProvider'
import { Redirect } from 'app/routx-router'

/**
 * Redirect admin paths containing an organizations segment to a path with said segment
 * If the backend links are even aligned with the new paths, we could remove this redirect
 */
const OrgIndex = () => {
  const { currentOrgId } = useOrgContext()
  return (
    <Redirect
      to={`/administration${currentOrgId !== null ? `/${currentOrgId}/info` : ''}`}
      replace
    />
  )
}

export const Component = OrgIndex
export default OrgIndex
