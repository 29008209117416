import { Predicate, PredicateStatus } from '@weenat/client/dist/resources/alerts.types'
import { TKeyLeafs, useIntl } from '@weenat/wintl'
import ProgressBar from 'app/src/kit/ProgressBar'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import moment from 'moment-timezone'
function AlertProgressIndicator({
  currentProgress,
  total,
  unit,
  since
}: {
  currentProgress: number
  total: number
  unit: TKeyLeafs<'units'>
  since: number | undefined
}) {
  const { t, formatNumber } = useIntl()

  return (
    <Box>
      <Flex $alignItems='center'>
        <Text $fontSize='sm' $fontWeight='bold'>{`${formatNumber(currentProgress)}/${total} ${t(
          ('units.' + unit) as 'units.celsius_degrees_day_abbr'
        )}`}</Text>
        <Box $ml='sm'>
          <ProgressBar progress={currentProgress} total={total} height={6} width={64} />
        </Box>
      </Flex>
      <Text $fontSize='sm'>
        {t('periods.since', {
          since: isNil(since) ? '-' : moment.unix(since).format(t('formats.datetime_chart'))
        })}
      </Text>
    </Box>
  )
}

export const CumulGtRRProgress = ({
  predicate,
  status
}: {
  status: PredicateStatus<'RR', 'cumul-gt'>
  predicate: Predicate<'RR', 'cumul-gt'>
}) => {
  const currentProgress = status.acc
  const total = predicate.params.threshold
  return (
    <AlertProgressIndicator
      currentProgress={currentProgress}
      total={total}
      since={status.since ?? predicate.params.since ?? undefined}
      unit={'millimeters_abbr'}
    />
  )
}
export const CumulGtGDDProgress = ({
  predicate,
  status
}: {
  status: PredicateStatus<'GDD', 'cumul-gt'>
  predicate: Predicate<'GDD', 'cumul-gt'>
}) => {
  const currentProgress = status.acc
  const total = predicate.params.threshold
  return (
    <AlertProgressIndicator
      currentProgress={currentProgress}
      total={total}
      since={status.since ?? predicate.params.since ?? undefined}
      unit={'celsius_degrees_day_abbr'}
    />
  )
}
export const CumulGtLW_DProgress = ({
  predicate,
  status
}: {
  status: PredicateStatus<'LW_D', 'cumul-gt'>
  predicate: Predicate<'LW_D', 'cumul-gt'>
}) => {
  const currentProgress = Math.floor(status.acc / 3600)
  const total = predicate.params.threshold
  return (
    <AlertProgressIndicator
      currentProgress={currentProgress}
      total={total}
      since={status.since ?? predicate.params.since ?? undefined}
      unit={total === 1 ? 'hours' : 'hours_plr'}
    />
  )
}
