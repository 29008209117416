import Icons from 'app/src/kit/Icons'
import MapMarkerOutline from 'app/src/map/MapMarkerOutline'

interface RestrictedMarkerProps {
  lat: number
  lng: number
}

const RestrictedMarker = ({ lat, lng, ...other }: RestrictedMarkerProps) => {
  return (
    <MapMarkerOutline $lat={lat} $lng={lng} {...other} $backgroundColor={'grayscale.black'}>
      <Icons.Lock $color={'grayscale.white'} $size='lg' />
    </MapMarkerOutline>
  )
}

export default RestrictedMarker
