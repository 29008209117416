import { BackgroundMapUniverseMetric } from '@weenat/client/dist/core/universes'
import { PlotSummary } from '@weenat/client/dist/resources/measurements.type'
import { RestrictionReason } from 'app/src/hooks/useIsAppAccessRestricted'
import isNil from 'lodash-es/isNil'
import { memo } from 'react'
import FrostPlotListItemValues from './FrostPlotListItemValues'
import IrrelisPlotListItemValues from './IrrelisPlotListItemValues'
import IrrigationPlotListItemValues from './IrrigationPlotListItemValues'
import LeafWetnessPlotListItemValues from './LeafWetnessPlotListItemValues'
import NowMinMaxPlotListItemValues from './NowMinMaxPlotListItemValues'
import RainfallsPlotListItemValues from './RainfallsPlotListItemValues'
import SolarPlotListItemValues from './SolarPlotListItemValues'
import WindPlotListItemValues from './WindPlotListItemValues'

function getValueFromSummaryData(
  data: PlotSummary['data'] | undefined,
  key: keyof PlotSummary['data']
) {
  const value = data?.[key]
  return !isNil(value) && (typeof value === 'string' || typeof value === 'number') ? value : null
}

interface PlotListItemMetricValuesProps {
  metric: BackgroundMapUniverseMetric | undefined
  data: PlotSummary['data']
  focusedHorizon: number | null
  isVirtualDevice: boolean
  /** does not account for virtual device rules */
  isRestricted: boolean
  reason: RestrictionReason
  provider?: string
}

const PlotListItemMetricValues: FC<PlotListItemMetricValuesProps> = ({
  metric,
  data,
  focusedHorizon,
  isRestricted,
  reason,
  isVirtualDevice,
  provider
}) => {
  switch (metric) {
    case 'RR':
      return (
        <RainfallsPlotListItemValues
          rainfallsToday={getValueFromSummaryData(data, 'RR_D')}
          rainfallsYesterday={getValueFromSummaryData(data, 'RR_DD')}
          rainfallsLastSevenDays={getValueFromSummaryData(data, 'RR_7D')}
          isVirtualDevice={isVirtualDevice}
          isRestricted={isRestricted}
          reason={reason}
        />
      )

    case 'T':
    case 'U':
      return (
        <NowMinMaxPlotListItemValues
          now={getValueFromSummaryData(data, `${metric}_L`)}
          min={getValueFromSummaryData(data, `${metric}_N`)}
          max={getValueFromSummaryData(data, `${metric}_X`)}
          metric={metric}
          isVirtualDevice={isVirtualDevice}
          isRestricted={isRestricted && !isVirtualDevice}
          reason={reason}
        />
      )

    case 'T_SOIL':
    case 'ECP':
    case 'SWC':
      return (
        <NowMinMaxPlotListItemValues
          now={getValueFromSummaryData(data, `${metric}_L`)}
          min={getValueFromSummaryData(data, `${metric}_N`)}
          max={getValueFromSummaryData(data, `${metric}_X`)}
          metric={metric}
          isVirtualDevice={isVirtualDevice}
          isRestricted={isRestricted}
          reason={reason}
        />
      )

    case 'PPFD':
    case 'PPFD_MAX':
    case 'PPFD_MIN':
      return (
        <SolarPlotListItemValues
          now={getValueFromSummaryData(data, `PPFD_L`)}
          min={getValueFromSummaryData(data, `PPFD_MIN_L`)}
          max={getValueFromSummaryData(data, `PPFD_MAX_L`)}
          metric={metric}
          isVirtualDevice={isVirtualDevice}
          isRestricted={isRestricted}
          reason={reason}
        />
      )

    case 'SSI':
    case 'SSI_MAX':
    case 'SSI_MIN':
      return (
        <SolarPlotListItemValues
          now={getValueFromSummaryData(data, `SSI_L`)}
          min={getValueFromSummaryData(data, `SSI_MIN_L`)}
          max={getValueFromSummaryData(data, `SSI_MAX_L`)}
          metric={metric}
          isVirtualDevice={isVirtualDevice}
          isRestricted={isRestricted}
          reason={reason}
        />
      )

    case 'FF':
    case 'FXY':
    case 'DD':
      return (
        <WindPlotListItemValues
          averageWindNow={getValueFromSummaryData(data, 'FF_L')}
          windGustNow={getValueFromSummaryData(data, 'FXY_L')}
          windDirectionNow={getValueFromSummaryData(data, 'DD_L')}
          isVirtualDevice={isVirtualDevice}
          isRestricted={isRestricted && !isVirtualDevice}
          reason={reason}
        />
      )

    case 'T_WET':
    case 'T_DRY':
      return <FrostPlotListItemValues summaryData={data} provider={provider} />

    case 'HPOT':
    case 'U_CAPA':
      return (
        <IrrigationPlotListItemValues
          summaryData={data}
          focusedHorizon={focusedHorizon}
          metric={metric}
        />
      )

    case 'HDEF':
      return <IrrelisPlotListItemValues summaryData={data} />

    case 'LW_V':
    case 'LW_D':
      return (
        <LeafWetnessPlotListItemValues data={data} isRestricted={isRestricted} reason={reason} />
      )

    case undefined:
      return null

    default:
      throw new Error(
        `PlotListItemMetricValues was not implemented for the following metric : ${metric}`
      )
  }
}

export default memo(PlotListItemMetricValues)
