import { useClient, useQuery } from '@weenat/client'
import { useHasPaymentIncident } from '@weenat/client/dist/core/billing'
import { BillingStatus } from '@weenat/client/dist/enums'
import { Id } from '@weenat/client/dist/resources/types'
import { useOrgContext } from 'app/orgProvider'
import { useToken } from 'app/state'
import isNil from 'lodash-es/isNil'

export type RestrictionReason = 'downgrade' | 'freemium'

/**
 *
 * check if access shouldbe restricted based on unpaid bills + feature flag
 * this hook should be deleted and replace by useHasPaymentIncident on flag deletion
 */
export const useIsAppAccessRestricted = (props?: { enabled?: boolean; orgId?: Id }) => {
  const client = useClient()
  const { currentOrgId } = useOrgContext()

  const enabled = props?.enabled ?? true

  const orgId = props?.orgId ?? currentOrgId
  const org = useQuery(client.orgs.get(orgId as number), {
    enabled: enabled && !isNil(orgId)
  }).data
  const [token] = useToken()
  const { hasPaymentIncident, paymentIncidentRequest } = useHasPaymentIncident({
    enabled: enabled && !isNil(orgId) && !isNil(token),
    orgId: orgId as number
  })

  const reason: RestrictionReason = hasPaymentIncident
    ? ('downgrade' as const)
    : ('freemium' as const)

  return {
    isRestricted: org?.billingStatus === BillingStatus.essential,
    reason: reason,
    isLoading: paymentIncidentRequest.isLoading
  }
}
