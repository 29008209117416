function SvgWrench(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 24 24' {...props}>
      <path d='M0 0h24v24H0V0z' fill='none' />
      <path d='M12.09 2.91C10.08.9 7.07.49 4.65 1.67L8.28 5.3c.39.39.39 1.02 0 1.41L6.69 8.3c-.39.4-1.02.4-1.41 0L1.65 4.67C.48 7.1.89 10.09 2.9 12.1a6.507 6.507 0 006.89 1.48l7.96 7.96a2.613 2.613 0 003.71 0 2.613 2.613 0 000-3.71L13.54 9.9c.92-2.34.44-5.1-1.45-6.99z' />
    </svg>
  )
}

export default SvgWrench
