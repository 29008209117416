import type { SVGProps } from 'react'

const SvgPlotLocationPin = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 48 48' {...props}>
    <path
      fill='red'
      stroke='white'
      strokeWidth={2}
      d='M24 4C15.6 4 8 10.546 8 20.669c0 6.464 4.9 14.067 14.68 22.828.76.67 1.9.67 2.66 0C35.1 34.736 40 27.133 40 20.669 40 10.546 32.4 4 24 4Z'
      clipPath='url(#plot-location-pin_svg__a)'
      filter='url(#plot-location-pin_svg__b)'
      transform='translate(-.5)'
    />
    <g fill='white'>
      <path
        d='M20.732 23.205A21.5 21.5 0 0 1 19 21.172h-1a.999.999 0 0 0-.895.562l-2.5 5.081c-.154.315-.138.69.044.99.182.3.504.482.85.482h17a.997.997 0 0 0 .851-.482c.183-.3.2-.674.044-.989l-2.5-5.082a.999.999 0 0 0-.894-.562h-1a21.5 21.5 0 0 1-1.732 2.033h2.114l1.5 3.05H17.118l1.5-3.05z'
        clipPath='url(#plot-location-pin_svg__c)'
        transform='translate(-.5)'
      />
      <path
        fillRule='evenodd'
        d='M24 24.73a.817.817 0 0 1-.516-.2c-1.673-1.558-2.922-3.005-3.747-4.34-.824-1.336-1.237-2.584-1.237-3.744 0-1.813.553-3.257 1.659-4.332C21.265 11.038 22.545 10.5 24 10.5s2.736.538 3.841 1.614c1.106 1.075 1.659 2.52 1.659 4.332 0 1.16-.412 2.408-1.237 3.744-.826 1.335-2.074 2.782-3.747 4.34a.817.817 0 0 1-.516.2m0-7.115c1.105 0 2-.91 2-2.033 0-1.122-.895-2.032-2-2.032-1.104 0-2 .91-2 2.032 0 1.123.896 2.033 2 2.033'
        clipPath='url(#plot-location-pin_svg__d)'
        clipRule='evenodd'
        transform='translate(-.5)'
      />
    </g>
    <defs>
      <clipPath id='plot-location-pin_svg__d'>
        <path fill='white' d='M0 0h48v48H0z' />
      </clipPath>
      <clipPath id='plot-location-pin_svg__c'>
        <path fill='white' d='M0 0h48v48H0z' />
      </clipPath>
      <clipPath id='plot-location-pin_svg__a'>
        <path fill='white' d='M0 0h48v48H0z' />
      </clipPath>
      <filter
        id='plot-location-pin_svg__b'
        width={48}
        height={56}
        x={1}
        y={-3}
        colorInterpolationFilters='sRGB'
        filterUnits='userSpaceOnUse'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          result='hardAlpha'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dx={1} dy={1} />
        <feGaussianBlur stdDeviation={4} />
        <feColorMatrix values='0 0 0 0 0 0 0 0 0 0.14902 0 0 0 0 0.207843 0 0 0 0.25 0' />
        <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_1548_3019' />
        <feBlend in='SourceGraphic' in2='effect1_dropShadow_1548_3019' result='shape' />
      </filter>
    </defs>
  </svg>
)
export default SvgPlotLocationPin
