import isNil from 'lodash-es/isNil'
import styled from 'styled-components'

const PADDING = 16

const MapControlsOverlay = styled(Flex)<{ $left?: number }>`
  position: absolute;
  z-index: -1;

  top: 0px;
  left: ${(p) => (!isNil(p.$left) ? p.$left : 0) + PADDING}px;
  right: ${PADDING}px;

  flex: 1;
  flex-direction: column;

  height: 100%;
  padding-bottom: ${PADDING}px;
`

export default MapControlsOverlay
