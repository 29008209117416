import { PaginationQueryParams } from '@weenat/client/dist/resources/types'
import { useIntl } from '@weenat/wintl'
import { FilterCategory, ListFilter } from 'app/utils/lists'
import { isNil } from 'lodash-es'
import { useState } from 'react'
import { css, styled, useTheme } from 'styled-components'
import useDisclosure from '../hooks/useDisclosure'
import Badge from './Badge'
import Button from './Button'
import Checkbox from './Checkbox'
import DelimitedFlex from './DelimitedFlex'
import Icons from './Icons'
import MenuItem from './MenuItem'
import Text from './Text'
import WeenatPressable from './WeenatPressable'
import { Dropdown, DropdownContent, DropdownTrigger } from './fields/Select/SelectDropdown'
import { FlexProps } from './primitives/themeMappings/props'

const badgeSectionStyle = css`
  display: flex;
  flex-direction: row;
  padding: 8px;
  border-left: 1px solid ${(p) => p.theme.colors.grayscale[300]};
`

const BadgeSection = styled.div`
  ${badgeSectionStyle}
`

const PressableBadgeSection = styled(WeenatPressable)`
  ${badgeSectionStyle}
`

const BadgeText = styled(Text)`
  user-select: none;
`

const MenuItemText = styled(BadgeText)`
  flex: 1;
`

interface FilterMenuItemProps {
  onPress: () => void
  label: string
  showCheckbox?: boolean
  showChevron?: boolean
  checked?: boolean
}

const FilterMenuItem = ({
  onPress,
  label,
  showCheckbox = false,
  showChevron = false,
  checked = false
}: FilterMenuItemProps) => {
  return (
    <MenuItem onPress={onPress}>
      <Flex $alignItems='center' $p='md' $gap='md'>
        {showCheckbox ? <Checkbox type='checkbox' checked={checked} /> : null}
        <MenuItemText>{label}</MenuItemText>
        {showChevron ? <Icons.ChevronLeft $rotate={180} /> : null}
      </Flex>
    </MenuItem>
  )
}

interface ActiveFilterCategoryProps<
  CategoryID extends string = string,
  QueryParams = PaginationQueryParams
> {
  category: FilterCategory<CategoryID, QueryParams>
  onFilterSelection: (filter: ListFilter) => void
  onRemove: () => void
}

function ActiveFilterCategory<
  CategoryID extends string = string,
  QueryParams = PaginationQueryParams
>({ category, onFilterSelection, onRemove }: ActiveFilterCategoryProps<CategoryID, QueryParams>) {
  const { isOpen, toggle } = useDisclosure(false)

  const activeFilters = category.filters.filter((cat) => cat.active)

  return (
    <Dropdown
      isVisible={isOpen}
      onVisibleChange={toggle}
      placement='bottom-start'
      followTriggerElement={false}
    >
      <DropdownTrigger>
        <Badge
          onClick={toggle}
          $borderRadius={'md'}
          $backgroundColor={`grayscale.100`}
          $borderColor={`grayscale.300`}
          $borderWidth={'sm'}
          $p={0}
          $px={0}
          $py={0}
          $cursor='pointer'
          $overflow='hidden'
        >
          <Box $p='md'>
            <BadgeText $fontWeight='bold'>{category.label}</BadgeText>
          </Box>
          {activeFilters.map((activeFilter) => (
            <BadgeSection key={activeFilter.id}>
              <BadgeText>{activeFilter.label}</BadgeText>
            </BadgeSection>
          ))}
          <PressableBadgeSection onPress={onRemove}>
            <Icons.Close $p={0} />
          </PressableBadgeSection>
        </Badge>
      </DropdownTrigger>
      <DropdownContent width={300}>
        <Box $py={'sm'}>
          {category.filters.map((opt) => {
            return (
              <FilterMenuItem
                key={opt.id}
                onPress={() => {
                  onFilterSelection(opt)

                  if (!category.multi) {
                    toggle()
                  }
                }}
                label={opt.label}
                showCheckbox
                checked={opt.active}
              />
            )
          })}
        </Box>
      </DropdownContent>
    </Dropdown>
  )
}

interface FilterButtonProps<
  CategoryID extends string = string,
  QueryParams = PaginationQueryParams
> {
  categories: FilterCategory<CategoryID, QueryParams>[]
  onFilterSelection: (category: FilterCategory<CategoryID, QueryParams>, filter: ListFilter) => void
}

function FilterButton<CategoryID extends string = string, QueryParams = PaginationQueryParams>({
  categories,
  onFilterSelection
}: FilterButtonProps<CategoryID, QueryParams>) {
  const { t } = useIntl()
  const { colors } = useTheme()

  const { isOpen, toggle } = useDisclosure(false)
  const [openedCategory, setOpenedCategory] = useState<
    FilterCategory<CategoryID, QueryParams> | undefined
  >(undefined)

  return (
    <Dropdown
      isVisible={isOpen}
      onVisibleChange={() => {
        setOpenedCategory(undefined)
        toggle()
      }}
      placement='bottom-start'
      followTriggerElement={false}
    >
      <DropdownTrigger>
        <Button
          onPress={toggle}
          IconLeft={Icons.FilterList}
          backgroundColor={'grayscale.100'}
          color={'grayscale.black'}
          style={{
            padding: 8,
            minHeight: 'unset',
            borderRadius: 8
          }}
        >
          {t('filtering.filters', { capitalize: true })}
        </Button>
      </DropdownTrigger>
      <DropdownContent
        width={300}
        Header={
          !isNil(openedCategory) ? (
            <DelimitedFlex
              $backgroundColor={colors.grayscale.white}
              $alignItems='center'
              $p='md'
              $gap='lg'
            >
              <Icons.ArrowBack $p='sm' onPress={() => setOpenedCategory(undefined)} />
              <BadgeText $fontSize='sm' $fontWeight='medium'>
                {openedCategory.label}
              </BadgeText>
            </DelimitedFlex>
          ) : null
        }
      >
        <Box $py={'sm'}>
          {openedCategory === undefined ? (
            <>
              {categories.map((c) => {
                return (
                  <FilterMenuItem
                    key={c.id}
                    onPress={() => setOpenedCategory(c)}
                    label={c.label}
                    showChevron
                  />
                )
              })}
            </>
          ) : (
            <>
              {openedCategory.filters.map((opt) => {
                return (
                  <FilterMenuItem
                    key={opt.id}
                    onPress={() => {
                      onFilterSelection(openedCategory, opt)

                      if (!openedCategory.multi) {
                        setOpenedCategory(undefined)
                        toggle()
                      }
                    }}
                    checked={opt.active}
                    showCheckbox
                    label={opt.label}
                  />
                )
              })}
            </>
          )}
        </Box>
      </DropdownContent>
    </Dropdown>
  )
}

interface FiltersBarProps<CategoryID extends string = string, QueryParams = PaginationQueryParams> {
  categories: FilterCategory<CategoryID, QueryParams>[]
  onFilterSelection: (category: FilterCategory<CategoryID, QueryParams>, filter: ListFilter) => void
  onFilterCategoryReset: (category: FilterCategory<CategoryID, QueryParams>) => void
  $mx?: FlexProps['$mx']
}

function FiltersBar<CategoryID extends string = string, QueryParams = PaginationQueryParams>({
  categories,
  onFilterSelection,
  onFilterCategoryReset,
  $mx = 0
}: FiltersBarProps<CategoryID, QueryParams>) {
  const activeCategories = categories.filter((cat) => cat.filters.some((f) => f.active))

  return (
    <Flex $alignItems='center' $flexWrap='wrap' $gap='md' $mx={$mx}>
      {activeCategories.map((cat) => (
        <ActiveFilterCategory
          key={cat.id}
          category={cat}
          onFilterSelection={(filter) => onFilterSelection(cat, filter)}
          onRemove={() => onFilterCategoryReset(cat)}
        />
      ))}
      <FilterButton categories={categories} onFilterSelection={onFilterSelection} />
    </Flex>
  )
}

export default FiltersBar
