import { useIntl } from '@weenat/wintl'
import { useOrgContext } from 'app/orgProvider'
import { useNavigate } from 'app/routx-router'
import Button from 'app/src/kit/Button'
import SmartText from 'app/src/kit/SmartText'
import Text from 'app/src/kit/Text'
import { useUniverse } from 'app/state'
import isEmpty from 'lodash-es/isEmpty'
import UniverseChipsTabs from './universes/UniverseChipsTabs'
import useUniverseOptionsQuery from './universes/useUniverseOptionsQuery'

const NoSummaries = () => {
  const { t } = useIntl()
  const [univ] = useUniverse()
  const { currentOrgId } = useOrgContext()
  const nav = useNavigate()
  const { universeOptions } = useUniverseOptionsQuery(currentOrgId)

  return (
    <Flex $cursor='auto' $flexDirection='column' $alignItems='flex-start' $gap='lg'>
      {isEmpty(universeOptions) ? (
        <>
          <SmartText tkey='help.no_plots_or_stations_yet' />
          <Button
            style={{ width: '100%' }}
            onPress={() => nav(`/administration/${currentOrgId}/devices/add`)}
          >
            {t('models.station.actions.add', { capitalize: true })}
          </Button>
          <Button style={{ width: '100%' }} importance={'sd'} onPress={() => nav(`/plots/create`)}>
            {t('models.plot.actions.create', { capitalize: true })}
          </Button>
        </>
      ) : (
        <Flex $flexDirection='column' $gap='md'>
          <Text>{t('help.no_plot_or_station_in_universe')}</Text>
          <UniverseChipsTabs omit={[univ]} />
        </Flex>
      )}
    </Flex>
  )
}

export default NoSummaries
