import { RequestType } from '@weenat/client/dist/resources/invitations'
import * as zodSchemas from '@weenat/client/dist/zod-schemas'
import { useIntl } from '@weenat/wintl'
import { Slot, useMatch, useNavigate, useSearchParams } from 'app/routx-router'
import useDisclosure from 'app/src/hooks/useDisclosure'
import ConfirmationModal from 'app/src/kit/ConfirmationModal'
import FullScreenStepper from 'app/src/kit/fullscreens/FullScreenStepper'
import { isNil } from 'lodash-es'
import { z } from 'zod'

export const settings = {
  search: z.object({ invite: zodSchemas.emailInvitation.optional() })
}

const OnboardingLayout = () => {
  const nav = useNavigate()
  const { t } = useIntl()

  const confirmLeaveModal = useDisclosure()

  const [{ invite }] = useSearchParams(settings.search)

  const greetingsStep = {
    id: 'greetings',
    label: t('onboardings.steps.greetings'),
    onPress: () => nav('/onboarding/welcome')
  }

  const steps =
    !isNil(invite) && invite.type === RequestType.autoAcceptedMemberInvitation
      ? [
          greetingsStep,
          {
            id: 'members-tips',
            label: t('onboardings.steps.members_tips'),
            onPress: () => nav('/onboarding/members-tips')
          }
        ]
      : [
          greetingsStep,

          { id: 'organization', label: t('onboardings.steps.organization') },
          {
            id: 'sensors',
            label: t('onboardings.steps.sensors'),
            onPress: () => nav('/onboarding/sensors')
          },
          { id: 'subscription', label: t('onboardings.steps.subscription') }
        ]

  const sensorsMatch = useMatch('/onboarding/sensors/*')
  const subscriptionMatch = useMatch('/administration/upgrade-to-premium')
  const networkMatch = useMatch('/onboarding/networks/*')
  const myFarmMatch = useMatch('/onboarding/my-farm')

  const currentStepIndex = !isNil(myFarmMatch)
    ? 1
    : !isNil(networkMatch) || !isNil(sensorsMatch)
      ? 2
      : !isNil(subscriptionMatch)
        ? 3
        : 0

  return (
    <FullScreenStepper
      steps={steps}
      currentStep={steps[currentStepIndex]}
      onClose={confirmLeaveModal.open}
    >
      <Flex $flexDirection='column' $flex={1} $maxWidth={896} $mx='auto'>
        <Slot />
      </Flex>
      <ConfirmationModal
        onConfirm={() => nav('/plots', { replace: true })}
        onCancel={confirmLeaveModal.close}
        isOpen={confirmLeaveModal.isOpen}
        confirmationMessage={t('onboardings.leave_onboarding_warning')}
      />
    </FullScreenStepper>
  )
}

export default OnboardingLayout
