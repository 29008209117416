import { useClient } from '@weenat/client'
import { InvitationStatus } from '@weenat/client/dist/enums'
import {
  Invitation,
  InvitationReceivedQueryParams
} from '@weenat/client/dist/resources/invitations'
import { useIntl } from '@weenat/wintl'
import AdvancedClientList from '../kit/AdvancedClientList'
import { FilterCategory } from '../kit/FiltersBar'
import ListEmpty from '../kit/ListEmpty'
import InvitationsSentToMeListItem from './InvitationsSentToMeListItem'

type FilterCategoryId = 'status'

const queryParams: InvitationReceivedQueryParams = {
  status: InvitationStatus.pending
}

const InvitationsSentToMeList: FC = () => {
  const client = useClient()
  const { t } = useIntl()

  const renderItem = (invitation: Invitation) => (
    <InvitationsSentToMeListItem key={invitation.id} invitation={invitation} />
  )

  const filterCategories: FilterCategory<FilterCategoryId, InvitationReceivedQueryParams>[] = [
    {
      id: 'status',
      label: t('alerts.status', { capitalize: true }),
      multi: true,
      queryParamKey: 'status',
      filters: [
        {
          id: 'pending',
          value: InvitationStatus.pending,
          label: t('qualifiers_feminine.pending', { capitalize: true }),
          active: false
        }
      ]
    }
  ]

  return (
    <AdvancedClientList
      model='invitation'
      ListEmptyComponent={<ListEmpty model='invitation' noItemsKey='no_items_received' />}
      initialQueryParams={queryParams}
      filterCategories={filterCategories}
      renderItem={renderItem}
      requesterBuilder={client.invitations.received.getPage}
    />
  )
}

export default InvitationsSentToMeList
