import Text from 'app/src/kit/Text'
import { FlexProps } from 'app/src/kit/primitives/themeMappings/props'

const BoldLabeledInfo: FC<{ label: string; children: string | React.ReactElement } & FlexProps> = ({
  label,
  children,
  ...props
}) => {
  const maybeTextWrappedChildren = typeof children === 'string' ? <Text>{children}</Text> : children
  return (
    <Flex {...props}>
      <Box>
        <Text $fontWeight='bold'>{label}</Text>
      </Box>
      <Box $ml='md'>{maybeTextWrappedChildren}</Box>
    </Flex>
  )
}

export default BoldLabeledInfo
