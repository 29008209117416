import { Redirect } from 'app/routx-router'
import AcceptTermsOfService from 'app/src/authentication/components/AcceptTermsOfService'
import Flex from 'app/src/kit/primitives/Flex'
import { useToken } from 'app/state'
import { useUserContext } from 'app/userProvider'
import isNil from 'lodash-es/isNil'
import { useTheme } from 'styled-components'

const AcceptTOS = () => {
  const [token] = useToken()
  const { colors, radiuses } = useTheme()
  const { user, requestState } = useUserContext()

  const myProfile = user?.profile
  const callToServiceHasBeenMade = requestState.isSuccess && !!myProfile
  const hasAcceptTOS = callToServiceHasBeenMade && myProfile.hasAcceptedTermsOfService
  const hasNotAcceptTOS = callToServiceHasBeenMade && !myProfile.hasAcceptedTermsOfService

  const style = { borderRadius: radiuses.md }

  return !isNil(token) && hasNotAcceptTOS ? (
    <Flex $width='100%' $alignItems='center' $justifyContent='center'>
      <Box $backgroundColor={colors.grayscale.white} $p={12} style={style}>
        <AcceptTermsOfService />
      </Box>
    </Flex>
  ) : !isNil(token) && hasAcceptTOS ? (
    <Redirect to='/' />
  ) : null
}
export const Component = AcceptTOS
export default AcceptTOS
