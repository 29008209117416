import { Redirect } from 'app/routx-router'

// the /info path being kind of a useless path
// we'd rather use /:orgId to display the info, so this page will only redirect to it
const AdministrationOrgInfo = () => {
  return <Redirect to={`info`} replace />
}

export const Component = AdministrationOrgInfo
export default AdministrationOrgInfo
