import { useField } from 'formik'
import isNil from 'lodash-es/isNil'
import { CheckboxPrimitive } from './CheckboxField'
import FieldLabel from './FieldLabel'

type PossibleValue = string | number | undefined

interface CheckboxOption {
  value: PossibleValue
  label: string
  isDisabled?: boolean
}

interface CheckBoxGroupFieldProps {
  /** Label / Field Name */
  label: string
  name: string
  options: CheckboxOption[]
}

const CheckBoxGroupField: FC<CheckBoxGroupFieldProps> = ({ name, options, label }) => {
  const [field, , helpers] = useField<PossibleValue[] | undefined>(name)

  return (
    <Box>
      <FieldLabel label={label} isFieldRequired={true} />
      {options.map((opt) => {
        const isSelected = !isNil(field.value) ? field.value.includes(opt.value) : false
        return (
          <CheckboxPrimitive
            key={opt.value}
            label={opt.label}
            value={isSelected}
            $isDisabled={opt.isDisabled}
            onChange={() => {
              let newValue: PossibleValue[] = []
              if (isSelected) {
                newValue = [...(field.value ?? [])].filter((v) => v !== opt.value)
              } else {
                newValue = [...(field.value ?? []), opt.value]
              }

              helpers.setValue(newValue)
            }}
          />
        )
      })}
    </Box>
  )
}

export default CheckBoxGroupField
