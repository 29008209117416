import { DeviceAvailableMeasure } from '@weenat/client/dist/resources'
import { IconProps } from './Icon'
import Icons from './Icons'
import MetricIcons from './icons/MetricIcons'

/**
 * list of metric that have the same pictogram as another metric
 * we exclude them from stacked icons to avoid displaying same pictogram twice
 */
const metricWithSamePictogram: Readonly<DeviceAvailableMeasure[]> = [
  'FXY',
  'DXY',
  'LW_D',
  'SSI_MIN',
  'SSI_MAX',
  'PPFD_MAX',
  'PPFD_MIN'
] as const

const StackedMetrics: FC<{ metrics: DeviceAvailableMeasure[] }> = ({ metrics }) => {
  return (
    <Flex>
      {metrics.map((metric, idx) => {
        const Icon: FC<IconProps> = MetricIcons[metric] ?? Icons.Bug
        const metricColor = (`metrics.${metric}.500` as const) || 'grayscale.black'
        return metricWithSamePictogram.includes(metric) ? null : (
          <Box
            $borderRadius={'md'}
            $boxShadow='sm'
            key={metric}
            $zIndex={metrics.length - idx}
            $ml={-8}
          >
            <Icon
              $color={'grayscale.white'}
              $size='md'
              $backgroundColor={metricColor}
              $borderColor={`metrics.${metric}.800`}
              $p='sm'
            />
          </Box>
        )
      })}
    </Flex>
  )
}

export default StackedMetrics
