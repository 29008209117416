import { useIntl } from '@weenat/wintl'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import { isValidElement } from 'react'
import Button, { ButtonProps } from './Button'
import { ClientButtonWithConfirmProps } from './ButtonWithConfirm'
import Modal, { ModalProps } from './Modal'

export interface ConfirmationModalProps extends ModalProps {
  cancelButton?: React.ReactElement
  /** Message or component explaining what will happen when the confirm button is pressed */
  confirmationMessage?: string | React.ReactElement | string[]
  confirmButton?: React.ReactElement
  isDisabled?: boolean
  kind?: 'danger'
  onCancel: ButtonProps['onPress']
  onConfirm: ButtonProps['onPress']
  confirmButtonState?: ClientButtonWithConfirmProps['state']
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  cancelButton = null,
  children,
  confirmationMessage,
  confirmButton = null,
  confirmButtonState,
  isDisabled = false,
  isOpen = false,
  kind,
  onCancel,
  onConfirm,
  title,
  zIndex
}) => {
  const { t } = useIntl()

  const messages = isNil(confirmationMessage)
    ? [t('actions.no_rollback_action_ask_confirmation')]
    : Array.isArray(confirmationMessage)
      ? confirmationMessage
      : [confirmationMessage]

  return (
    <Modal
      hasCloseIcon
      width={512}
      isOpen={isOpen}
      close={onCancel}
      title={title}
      kind={kind}
      zIndex={zIndex}
    >
      <Flex $flexDirection='column'>
        {children}
        <Box $mb='2xl'>
          {isValidElement(confirmationMessage)
            ? confirmationMessage
            : // eslint-disable-next-line react/no-array-index-key
              messages.map((m, i) => <Text key={i}>{m}</Text>)}
        </Box>
        <Flex $alignItems='center' $justifyContent='flex-end' $mt='auto'>
          <Box $mr='lg'>
            {cancelButton || (
              <Button importance='sd' onPress={onCancel}>
                {t('actions.cancel')}
              </Button>
            )}
          </Box>
          {confirmButton || (
            <Button onPress={onConfirm} isDisabled={isDisabled} state={confirmButtonState}>
              {t('actions.confirm')}
            </Button>
          )}
        </Flex>
      </Flex>
    </Modal>
  )
}

export default ConfirmationModal
