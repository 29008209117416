import { schemas } from '@weenat/client'
import { useBillingAccountEdition } from '@weenat/client/dist/core/billing'
import { PlanName } from '@weenat/client/dist/enums'
import { PossibleError } from '@weenat/client/dist/errors'
import { Org } from '@weenat/client/dist/resources/orgs'
import { useIntl } from '@weenat/wintl'
import useToasts from 'app/src/hooks/useToasts'
import Text from 'app/src/kit/Text'
import { FormikProps } from 'formik'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { useCallback, useMemo, useRef } from 'react'
import { styled } from 'styled-components'
import { EditBillingAccountFields } from '../administration/billing/EditBillingAccountForm'
import Scrollable from '../kit/Scrollable'
import SuperForm, { SuperFormSubmitButton } from '../kit/SuperForm'
import LoadingCircle from '../kit/loaders/LoadingCircle'
import SubscriptionCard from './SubscriptionCard'

const schema = schemas.billing.editBillingAccount
type Values = typeof schema.initialValues

const FormContainer = styled(Flex)`
  flex: 1;
  flex-direction: column;
`

interface SetupBillingAccountProps {
  org: Org
  plan: PlanName
  onSuccess: () => void
}

const SetupBillingAccount: FC<SetupBillingAccountProps> = ({ org, plan, onSuccess }) => {
  const { t } = useIntl()
  const { addErrorToast } = useToasts()

  const formRef = useRef<FormikProps<Values>>(null)

  const isEmptyMsg = t('forms.field_is_empty')

  const onFieldErrors = useCallback(
    (errors: PossibleError) => {
      if (!isNil(errors) && !isNil(errors.parsedErrors) && !isNil(formRef.current)) {
        const { values, setFieldError } = formRef.current

        /**
         * keys will be backend formatted
         * fields name should frontend key format
         */
        Object.keys(errors.parsedErrors).forEach((k) => {
          if (k === 'name' && isEmpty(values.organizationName)) {
            setFieldError('organizationName', isEmptyMsg)
          } else if (k === 'siret') {
            if (isEmpty(values.siret)) {
              setFieldError('siret', isEmptyMsg)
            } else if (
              errors.parsedErrors &&
              'siret' in errors.parsedErrors &&
              Array.isArray(errors.parsedErrors.siret)
            ) {
              setFieldError('siret', errors.parsedErrors.siret[0].message)
            }
          } else if (k === 'address_line_1' && isEmpty(values.addressLine1)) {
            setFieldError('addressLine1', isEmptyMsg)
          } else if (k === 'address_zip' && isEmpty(values.zipCode)) {
            setFieldError('zipCode', isEmptyMsg)
          } else if (k === 'city' && isEmpty(values.city)) {
            setFieldError('city', isEmptyMsg)
          } else if (k === 'vat_number') {
            if (isEmpty(values.vatNumber)) {
              setFieldError('vatNumber', isEmptyMsg)
            } else if (
              !isNil(errors.parsedErrors) &&
              'vat_number' in errors.parsedErrors &&
              Array.isArray(errors.parsedErrors.vat_number)
            ) {
              setFieldError('vatNumber', t('error_codes.220_01'))
            }
          }
        })
      }
    },
    [isEmptyMsg, t]
  )

  const {
    billingAccount: initialBillingAccount,
    getBillingAccountRequest,
    editBillingAccount,
    editBillingAccountRequest
  } = useBillingAccountEdition({
    onError: (e) => {
      addErrorToast(e)
    },
    onFieldErrors,
    organizationId: org.id,
    onSuccess
  })

  const handleSubmit = useCallback(
    ({
      addressLine1,
      addressLine2,
      city,
      country,
      organizationName,
      siret,
      zipCode,
      vatNumber
    }: Values) => {
      editBillingAccount({
        addressLine1,
        addressLine2,
        city,
        country,
        organizationName,
        siret,
        zipCode,
        vatNumber
      })
    },
    [editBillingAccount]
  )

  const initialValues = useMemo(() => {
    return {
      ...(initialBillingAccount || {}),
      country: initialBillingAccount?.country ?? 'FR',
      vatNumber: initialBillingAccount?.vatNumber ?? undefined
    }
  }, [initialBillingAccount])

  return (
    <SuperForm
      schema={schema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      formRef={formRef}
    >
      {({ values }) => (
        <>
          <Scrollable>
            <Flex $flex={1} $gap='xl' $p='lg'>
              <Box $width={400}>
                <SubscriptionCard plan={plan} org={org} variant='billing' />
              </Box>
              <FormContainer>
                <Box $mb='lg'>
                  <Text $fontWeight='bold' $fontSize='md'>
                    {t(`ecommerce.plans.journey.setup_billing_account`)}
                  </Text>
                </Box>
                {!getBillingAccountRequest.isLoading ? (
                  <>
                    <EditBillingAccountFields />
                    <Flex $justifyContent='flex-end'>
                      <SuperFormSubmitButton
                        state={editBillingAccountRequest}
                        onPress={() => handleSubmit(values)}
                      >
                        {t('actions.next')}
                      </SuperFormSubmitButton>
                    </Flex>
                  </>
                ) : (
                  <Flex
                    $flexDirection='column'
                    $flex={1}
                    $justifyContent='center'
                    $alignItems='center'
                    $gap='md'
                  >
                    <LoadingCircle size='lg' />
                    <Text $textAlign='center'>{t('status.loading')}</Text>
                  </Flex>
                )}
              </FormContainer>
            </Flex>
          </Scrollable>
        </>
      )}
    </SuperForm>
  )
}

export default SetupBillingAccount
