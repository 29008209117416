import { PaginationQueryParams } from '@weenat/client/dist/resources/types'
import { isNil } from 'lodash-es'

export interface ListFilter {
  id: string
  label: string
  active: boolean
  value: undefined | boolean | string | number | Array<string | number>
}

export interface FilterCategory<
  CategoryID extends string = string,
  QueryParams = PaginationQueryParams
> {
  id: CategoryID
  label: string
  queryParamKey: keyof QueryParams
  filters: ListFilter[]
  multi: boolean
}

export interface SortOption {
  id: string
  label: string
  active: boolean
  value: 'asc' | 'desc'
}

export interface SortCategory<
  CategoryID extends string = string,
  QueryParams = PaginationQueryParams
> {
  id: CategoryID
  label: string
  sortKey: keyof QueryParams
  options: SortOption[]
}

interface FromFilterCategoryToQueryParamsArgs<
  CategoryID extends string = string,
  QueryParams = PaginationQueryParams
> {
  category: FilterCategory<CategoryID, QueryParams>
}

/**
 * Transform a filter category into a query param object
 * active filters from the category are assign to an object with the queryParamsKey
 * @example
 * { orgId: [] }
 */
export function fromFilterCategoryToQueryParams<
  CategoryID extends string = string,
  QueryParams = PaginationQueryParams
>({ category }: FromFilterCategoryToQueryParamsArgs<CategoryID, QueryParams>) {
  const params: Partial<QueryParams> = {}

  const { multi, queryParamKey, filters } = category

  if (multi) {
    const activeFilters = filters.filter((f) => f.active)

    params[queryParamKey] = activeFilters.flatMap((af) => af.value)
  } else {
    const activeFilter = filters.find((f) => f.active)

    if (!isNil(activeFilter)) {
      params[queryParamKey] = activeFilter.value
    } else {
      params[queryParamKey] = undefined
    }
  }

  return params
}

interface FromSortOptionCategoryToQueryParamsArgs {
  category: SortCategory
}

/**
 * Transform a sort option category into a query param object
 * active filters from the category are assign to an object with the queryParamsKey
 * @example
 * { orgId: [] }
 */
export function fromSortOptionCategoryToQueryParams({
  category
}: FromSortOptionCategoryToQueryParamsArgs) {
  const params = {}

  const { options, sortKey } = category

  const activeOption = options.find((opt) => opt.active)

  if (!isNil(activeOption)) {
    params.sortBy = sortKey
    params.sortOrder = activeOption.value
  }

  return params
}
