import { useIntl } from '@weenat/wintl'
import { useNavigate } from 'app/routx-router'
import { isNil } from 'lodash-es'
import {
  ContactDetailsLine,
  CustomerSupportContactDetails
} from '../help/components/CustomerSupportSection'
import useDisclosure from '../hooks/useDisclosure'
import Button from '../kit/Button'
import Icons from '../kit/Icons'
import Modal from '../kit/Modal'
import Text from '../kit/Text'
import TextTooltip from '../kit/tooltips/TextTooltip'

interface OnboardingNavigationHeaderProps {
  title?: string
  variant?: 'support' | 'network'
}

const OnboardingNavigationHeader = ({ title, variant }: OnboardingNavigationHeaderProps) => {
  const nav = useNavigate()
  const { t } = useIntl()

  const {
    isOpen: isContactSupportModalOpen,
    open: openContactSupportModal,
    close: closeContactSupportModal
  } = useDisclosure(false)

  return (
    <>
      <Flex $alignItems='center' $width='100%' $py='lg' $gap='md'>
        <Flex $flex={1} $alignItems='center' $gap='md'>
          <TextTooltip content={t('actions.go_back', { capitalize: true })}>
            <Icons.ArrowBack
              onPress={() => {
                nav(-1)
              }}
              $size='lg'
              $p='md'
              $backgroundColor={'grayscale.100'}
              $color={'grayscale.black'}
            />
          </TextTooltip>
          {!isNil(title) ? (
            <Text $fontSize='md' $fontWeight='bold'>
              {title}
            </Text>
          ) : null}
        </Flex>

        {variant === 'network' ? (
          <Button importance='sd' onPress={() => nav('/plots')}>
            {t('models.network.actions.do_not_wish_to_join', { capitalize: true })}
          </Button>
        ) : variant === 'support' ? (
          <Button IconLeft={Icons.SupportAgent} onPress={() => openContactSupportModal()}>
            {t('help.need_help', { capitalize: true })}
          </Button>
        ) : null}
      </Flex>
      <Modal
        width={512}
        title={t('help.support', { capitalize: true })}
        isOpen={isContactSupportModalOpen}
        close={closeContactSupportModal}
      >
        <CustomerSupportContactDetails />
        <ContactDetailsLine Icon={Icons.Offices}>
          <Text>{t('help.legal_notice_address_2')}</Text>
        </ContactDetailsLine>
      </Modal>
    </>
  )
}

export default OnboardingNavigationHeader
