import { CalendarEventType } from '@weenat/client/dist/core/calendars'
import { defaultTimezone } from '@weenat/client/dist/core/history'
import { WeenatColor } from '@weenat/theme'
import Text from 'app/src/kit/Text'
import noop from 'lodash-es/noop'
import moment from 'moment-timezone'
import { transparentize } from 'polished'
import { styled } from 'styled-components'
import Icons from '../Icons'
import CalendarEvent from './CalendarEvent'

const MIN_HEIGHT = 42

interface CalendarDayContainerProps {
  $isDisabled: boolean
  $isFirstOfSelection?: boolean
  $isLastOfSelection?: boolean
  $isSelected?: boolean
  $variant?: 'compact' | 'full'
  $height: number
  $isFromAnotherMonth?: boolean
}

const AddButton = styled(Flex)`
  align-items: center;
  justify-content: center;
  margin-top: auto;
  padding: ${(p) => p.theme.spacings.sm}px;
  width: 100%;
  border-radius: ${(p) => p.theme.radiuses.sm}px;
  opacity: 0;
  background-color: ${(props) => transparentize(0.5, props.theme.colors.grayscale[300])};
  transition: opacity 0.3s ease;
`

const CalendarDayContainer = styled(Flex)<CalendarDayContainerProps>`
  flex-direction: column;
  justify-content: ${(p) => (p.$variant === 'full' ? 'flex-start' : 'center')};
  align-items: ${(p) => (p.$variant === 'full' ? 'flex-start' : 'center')};
  padding: 8px;
  height: ${(props) => `${props.$height}px`};
  border-radius: ${({ $isLastOfSelection, $isFirstOfSelection, $variant }) =>
    `${$isFirstOfSelection || $variant === 'full' ? '8px' : '0px'} ${
      $isLastOfSelection || $variant === 'full' ? '8px' : '0px'
    } ${$isLastOfSelection || $variant === 'full' ? '8px' : '0px'} ${
      $isFirstOfSelection || $variant === 'full' ? '8px' : '0px'
    }`};
  background-color: ${(props) =>
    props.$isSelected
      ? props.theme.colors.primary[200]
      : props.$isDisabled
        ? props.theme.colors.grayscale[200]
        : props.$variant === 'full'
          ? props.theme.colors.grayscale[100]
          : 'transparent'};
  cursor: ${(props) => (props.$isDisabled ? 'not-allowed' : 'pointer')};

  &:hover ${AddButton} {
    opacity: 1;
  }
`

interface CalendarDayProps {
  day: Date
  events?: CalendarEventType[]
  onPress: () => void
  onEventPress?: (e: CalendarEventType) => void
  timezone: string
  /** Whether or not the user can interact with the day */
  isDisabled: boolean
  /** Whether or not the day is the first day of the selected period */
  isFirstOfSelection?: boolean
  /** Whether or not the day is the last day of the selected period */
  isLastOfSelection?: boolean
  /** Whether or not the day part of the selected period */
  isSelected?: boolean
  /** How should the day be displayed */
  variant?: 'compact' | 'full'
  /** Height of the component */
  height: number
  /** Whether or not the date is part of the currently displayed month */
  isFromAnotherMonth?: boolean
}

const CalendarDay: FC<CalendarDayProps> = ({
  day,
  events = [],
  isDisabled = false,
  isFirstOfSelection,
  isFromAnotherMonth,
  isLastOfSelection,
  isSelected = false,
  onEventPress = noop,
  onPress,
  timezone = defaultTimezone,
  variant = 'compact',
  height = MIN_HEIGHT
}) => {
  const today = moment().tz(timezone)
  const isToday = moment(day).tz(timezone).isSame(today, 'date')

  const dayNumber = day.getDate().toString()
  const dayNumberColor: WeenatColor = isToday
    ? 'primary.500'
    : isDisabled
      ? 'grayscale.100'
      : isFromAnotherMonth
        ? 'grayscale.300'
        : 'grayscale.black'

  const isCompactVariant = variant === 'compact'

  return (
    <Box
      $flex={1 / 7}
      $mb='sm'
      onClick={
        isDisabled
          ? undefined
          : (e) => {
              onPress()
              e.stopPropagation()
            }
      }
    >
      <CalendarDayContainer
        $isDisabled={isDisabled}
        $isSelected={isSelected}
        $isFirstOfSelection={isFirstOfSelection}
        $isLastOfSelection={isLastOfSelection}
        $isFromAnotherMonth={isFromAnotherMonth}
        $variant={variant}
        $mx={isCompactVariant ? 0 : 'md'}
        $height={height}
      >
        {isCompactVariant ? (
          <Text $fontWeight='bold' $textAlign='center' $color={dayNumberColor}>
            {dayNumber}
          </Text>
        ) : (
          <Flex $width='100%' $height='100%' $flexDirection='column'>
            <Box $alignSelf='end'>
              <Text $fontWeight='bold' $textAlign='right' $color={dayNumberColor}>
                {dayNumber}
              </Text>
            </Box>
            {events.map((event) => {
              return <CalendarEvent event={event} onPress={onEventPress} key={event.id} />
            })}
            <AddButton>
              <Icons.PlusSign $size='md' />
            </AddButton>
          </Flex>
        )}
      </CalendarDayContainer>
    </Box>
  )
}

export default CalendarDay
