import { useIntl } from '@weenat/wintl'
import Card from 'app/src/kit/Card'
import Text from 'app/src/kit/Text'
import LoadingCircle from 'app/src/kit/loaders/LoadingCircle'
import { useTheme } from 'styled-components'

const DashboardMapLoading: FC = () => {
  const { colors } = useTheme()
  const { t } = useIntl()

  const color = colors.grayscale.black
  return (
    <Flex $flex={1} $alignItems='center' $justifyContent='center' $flexDirection='column' $p='lg'>
      <Card>
        <Box $p='md'>
          <LoadingCircle size='lg' color={color} />
        </Box>
        <Text $color={color} $textAlign='center'>
          {t('status.loading')}
        </Text>
      </Card>
    </Flex>
  )
}

export default DashboardMapLoading
