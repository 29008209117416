import { useIntl } from '@weenat/wintl'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { Fragment, ReactNode } from 'react'
import { styled } from 'styled-components'
import Icons from '../Icons'
import TextTooltip from '../tooltips/TextTooltip'

const StyledText = styled.label`
  font-size: ${(p) => p.theme.typography.sizes.rg}px;
  line-height: ${(p) => p.theme.typography.lineHeights.xxl};
  transition: color 0.3s ease-in-out;
`

const WarningContainer = styled(Flex)`
  align-items: center;
`

export interface FieldLabelProps {
  label: ReactNode
  isFieldRequired: boolean
  helpers?: string | React.ReactElement
  warning?: string | React.ReactElement
}

const FieldLabel: FC<FieldLabelProps> = ({ label, helpers, warning, isFieldRequired }) => {
  const { t } = useIntl()

  const LabelContainer = !isEmpty(warning) ? WarningContainer : Fragment

  const finalLabel = isFieldRequired
    ? label
    : !isNil(label)
      ? t('forms.optional_label', { label })
      : label

  const labelContent = (
    <LabelContainer>
      <StyledText>{finalLabel}</StyledText>
      {!isEmpty(warning) ? (
        <TextTooltip content={warning}>
          <Icons.AlertTriangle $color={'feedback.warning.500'} $size='md' />
        </TextTooltip>
      ) : null}
    </LabelContainer>
  )

  return !isNil(helpers) && typeof helpers === 'string' ? (
    <Flex $alignItems='center'>
      {labelContent}
      <TextTooltip content={helpers}>
        <Icons.Help $color={'grayscale.700'} $size='md' />
      </TextTooltip>
    </Flex>
  ) : (
    labelContent
  )
}

export default FieldLabel
