/* eslint-disable no-param-reassign */
import { useClient } from '@weenat/client'
import { BaseClient } from '@weenat/client/dist/Client'
import { RadarTimestamp, RadarTimestamps } from '@weenat/client/dist/resources/radar'
import { GoogleMapApi } from 'app/src/map/utils'
import { useToken } from 'app/state'
import isNil from 'lodash-es/isNil'
import { useEffect, useRef } from 'react'
import { useRadarContext } from '../../contexts/RadarContext'

/**
 * This function should only be called once otherwise the OffsetableImageMapType would be redefined multiple times
 * We can not define OffsetableImageMapType outside of this function scope because we need maps to create a new Size
 * */

const getOffsetableImageMapType = ({
  maps,
  tokenParam,
  nameParam,
  client
}: Pick<GoogleMapApi, 'maps'> & {
  tokenParam: string
  nameParam: string
  client: BaseClient
}) => {
  interface Tile {
    img: HTMLImageElement
    coords: google.maps.Point
    zoom: number
    timestamp: string
  }

  class OffsetableImageMapType implements google.maps.MapType {
    /**
     * contains tiles image handle by this instance
     */
    private tiles: Tile[]

    /**
     * counting image currently fetching
     */
    private pendingRequest = 0

    /**
     * timestamp to display in this instance
     */
    private timestamp: RadarTimestamp | undefined

    /**
     * document which own tiles
     */
    private ownerDocument: Document | undefined

    /**
     * is this imagetype is currently visible or is it hidden for prefetching tiles
     */
    private visible = false

    private maxTimePerTile = 0

    constructor(
      public name: string,
      private token: string,
      public tileSize = new maps.Size(256, 256),
      public maxZoom = 13
    ) {
      this.tiles = []
    }
    alt: string | null
    minZoom: number
    projection: google.maps.Projection | null
    radius: number

    /**
     * all work is done here
     * first we test if currently loaded tile correspond to wanted timestamp
     *  we change show tile if this is the right timestamp and overlay is the one to display
     *  if timestamp is not correct we load wanted timestamp
     *  if wanted timestamp match
     *    we check if there is still pending request and wait an extra time
     */
    draw() {
      const shouldChangeImage =
        this.tiles.length > 0 && this.tiles[0].timestamp !== this.timestamp?.originalTimestamp
      this.tiles.forEach((tile) => {
        tile.img.style.opacity = this.visible && !shouldChangeImage ? '1' : '0'
      })
      if (shouldChangeImage) {
        this.tiles.forEach((tile) => {
          this.setImgSrc(tile)
        })
      } else if (this.pendingRequest !== 0) {
        this.sendLoadingEvent()
        setTimeout(() => {
          if (this.pendingRequest !== 0) {
            // some request might have been cancel we flush pendingRequest
            this.pendingRequest = 0
            this.loaded()
          }
        }, this.maxTimePerTile * 2)
      }
    }

    /**
     * method responsible of setting url on image and handle counting of pending request
     */
    setImgSrc(tile: Tile) {
      const url = this.geTileUrl(tile.coords, tile.zoom)
      tile.img.src = url
      tile.timestamp = this.timestamp?.originalTimestamp || ''
      const date = Date.now()
      this.pendingRequest += 1
      if (this.pendingRequest > 0) {
        this.sendLoadingEvent()
      }
      const handleResult = () => {
        tile.img.style.opacity = this.visible ? '1' : '0'
        this.pendingRequest -= 1
        this.maxTimePerTile = Math.max(this.maxTimePerTile, Date.now() - date)
        if (this.pendingRequest < 1) {
          this.loaded()
        }
      }
      tile.img.onload = handleResult
      tile.img.onerror = handleResult
    }

    /**
     * implementation of get tile url which use timestamp
     */
    geTileUrl(coords: google.maps.Point, zoom: number): string {
      return client.radar
        .getTileUrlTemplate({
          token: this.token,
          forecastTimestampsCreatedAt: this.timestamp?.createdAt,
          originalTimestamp: this.timestamp?.originalTimestamp
        })
        .replaceAll('{x}', `${coords.x}`)
        .replaceAll('{y}', `${coords.y}`)
        .replaceAll('{z}', `${zoom}`)
    }

    /**
     * this method associate coords and zoom to correct tile
     */
    getTile(coords: google.maps.Point, zoom: number, ownerDocument: Document): HTMLElement {
      if (zoom < this.maxZoom) {
        this.ownerDocument = ownerDocument
        const imgTile = ownerDocument.createElement('img')
        imgTile.style.opacity = this.visible ? '1' : '0'
        imgTile.width = this.tileSize.width
        imgTile.height = this.tileSize.height
        const tile = {
          img: imgTile,
          coords,
          zoom,
          timestamp: this.timestamp?.originalTimestamp || ''
        }
        this.setImgSrc(tile)
        this.tiles.push(tile)
        return imgTile
      }
      return ownerDocument.createElement('div')
    }

    /**
     * handle action when imagetype tiles are all loaded
     */
    private loaded() {
      if (this.visible) {
        this.tiles.forEach((tile) => {
          tile.img.style.opacity = '1'
        })
      }
      this.sendLoadedEvent()
    }

    /**
     * we use this method to delete tile no more needed
     */
    releaseTile(elt: HTMLElement) {
      this.tiles = this.tiles.filter((tile) => tile.img !== elt)
      elt.remove()
    }

    setTimestamp(timestamp: RadarTimestamp) {
      this.timestamp = timestamp
    }

    setVisible(visible: boolean) {
      this.visible = visible
    }

    getVisible() {
      return this.visible
    }

    /**
     * this event indicate that currently visible image type finished to load
     */
    private sendLoadedEvent() {
      if (this.visible) {
        this.ownerDocument?.dispatchEvent(new Event(`offsetable-loaded-${this.name}`))
      }
    }

    /**
     * this event indicate that currently visible image type is fetching
     */
    private sendLoadingEvent() {
      if (this.visible) {
        this.ownerDocument?.dispatchEvent(new Event(`offsetable-loading-${this.name}`))
      }
    }
  }
  return new OffsetableImageMapType(nameParam, tokenParam)
}

const useRadarTiles = ({
  map,
  maps,
  radarTimestamps
}: GoogleMapApi & { radarTimestamps?: RadarTimestamps }) => {
  const client = useClient()
  const [token] = useToken()

  const {
    state: { currentTimestampIndex },
    dispatch
  } = useRadarContext()

  const imts = useRef<ReturnType<typeof getOffsetableImageMapType>[]>([])

  // effect use to create imagemaptype as soon as ressource are ready
  useEffect(() => {
    // Only create the imt if it was not already created
    if (
      !isNil(map) &&
      !isNil(maps) &&
      !isNil(radarTimestamps) &&
      !isNil(token) &&
      imts.current.length === 0
    ) {
      const imt1 = getOffsetableImageMapType({
        maps,
        tokenParam: token,
        nameParam: 'imt1',
        client
      })
      const imt2 = getOffsetableImageMapType({
        maps,
        tokenParam: token,
        nameParam: 'imt2',
        client
      })
      map.overlayMapTypes.push(imt1)
      map.overlayMapTypes.push(imt2)
      imts.current = [imt1, imt2]
    }

    return () => {
      if (!isNil(map) && !isNil(map.overlayMapTypes.getLength())) {
        map.overlayMapTypes.clear()
      }
    }
  }, [map, maps, radarTimestamps])

  // effect used to change timestamp and visibility of
  // imagemaptype based on current timestamp
  useEffect(() => {
    if (
      !isNil(currentTimestampIndex) &&
      !isNil(radarTimestamps) &&
      !isNil(radarTimestamps.timestamps) &&
      !isNil(imts.current) &&
      imts.current.length > 1
    ) {
      const shouldDisplayFirstLayout = currentTimestampIndex % 2 === 0
      const getTimestampIndexFromStep = (curStep: number) => {
        const firstLayoutIdx =
          (curStep % 2 === 0 ? curStep : curStep + 1) % radarTimestamps.timestamps.length
        const secondLayouIdx =
          (curStep % 2 === 0 ? curStep + 1 : curStep) % radarTimestamps.timestamps.length

        return { firstLayoutIdx, secondLayouIdx }
      }
      const currentTimestamps = getTimestampIndexFromStep(currentTimestampIndex)
      const firstTimestampObject = radarTimestamps.timestamps[currentTimestamps.firstLayoutIdx]
      const secondTimestampObject = radarTimestamps.timestamps[currentTimestamps.secondLayouIdx]
      imts.current[0].setTimestamp(firstTimestampObject)
      imts.current[1].setTimestamp(secondTimestampObject)
      imts.current[0].setVisible(shouldDisplayFirstLayout)
      imts.current[1].setVisible(!shouldDisplayFirstLayout)
      imts.current[0].draw()
      imts.current[1].draw()
    }
  }, [currentTimestampIndex, radarTimestamps, imts.current])

  // handle event for loading start end
  useEffect(() => {
    const handleLoaded = () => {
      dispatch({ type: 'setIsLoading', newValue: false })
    }
    const handleLoading = () => {
      dispatch({ type: 'setIsLoading', newValue: true })
    }
    const itmEventHandlers = imts.current.map((currImt) => [
      // useful for first frame in this case we wait for first render of tiles
      {
        name: `offsetable-loading-${currImt.name}`,
        handle: handleLoading
      },
      // this event is send when visible imagetype has finish to load
      { name: `offsetable-loaded-${currImt.name}`, handle: handleLoaded }
    ])
    itmEventHandlers.forEach((evtHandlers) => {
      evtHandlers.forEach((evtHandler) => {
        document.addEventListener(evtHandler.name, evtHandler.handle)
      })
    })
    return () => {
      itmEventHandlers.forEach((evtHandlers) => {
        evtHandlers.forEach((evtHandler) => {
          document.removeEventListener(evtHandler.name, evtHandler.handle)
        })
      })
    }
  }, [imts.current])
}
export default useRadarTiles
