import { WeenatColor } from '@weenat/theme'
import { TKey, useIntl } from '@weenat/wintl'
import Card from './Card'
import { IconProps } from './Icon'
import Text from './Text'

type IconTextCardProps = {
  titleMsgKey: TKey
  titleOptions?: Record<string, string>
  bodyMsgKey: TKey
  Icon: FC<IconProps>
  backgroundColor?: string
  borderColor?: WeenatColor
  contentColor?: WeenatColor
}

const IconTextCard: FC<IconTextCardProps> = ({
  titleMsgKey,
  bodyMsgKey,
  Icon,
  children,
  backgroundColor,
  borderColor,
  contentColor: propsContentColor,
  titleOptions
}) => {
  const { t } = useIntl()

  const contentColor = propsContentColor ?? 'grayscale.black'

  return (
    <Card $backgroundColor={backgroundColor ?? 'grayscale.100'} $borderColor={borderColor}>
      <Box $p='sm'>
        <Icon $size='xl' $color={contentColor} />
      </Box>
      <Box $p='sm'>
        <Text $fontSize='md' $fontWeight='semiBold' $color={contentColor} $textAlign='center'>
          {/* @ts-expect-error This is fine */}
          {t(titleMsgKey, titleOptions)}
        </Text>
      </Box>
      <Text $fontSize='rg' $color={contentColor} $textAlign='center'>
        {/* @ts-expect-error This is fine */}
        {t(bodyMsgKey)}
      </Text>
      {children}
    </Card>
  )
}

export default IconTextCard
