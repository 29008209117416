import { schemas } from '@weenat/client'
import { useOrderedFields } from '@weenat/client/dist/core'
import { useIntl } from '@weenat/wintl'
import UseOnChange from 'app/src/kit/fields/UseOnChange'
import isNil from 'lodash-es/isNil'
import noop from 'lodash-es/noop'
import { useCallback } from 'react'
import Icons from './Icons'
import SuperForm from './SuperForm'
import TextField from './fields/TextField'

const SEARCH_QUERY_SCHEMA = schemas.searchQuery

interface SearchQueryFormProps {
  /** Callback executed when the query changes */
  onQueryChange: (searchQuery: string) => void
  /** Text field placeholder */
  placeholder?: string
}

const SearchQueryForm: FC<SearchQueryFormProps> = ({ onQueryChange, placeholder }) => {
  const { t } = useIntl()
  const fieldProps = useOrderedFields(['searchQuery'])

  const onChange = useCallback(
    ({ searchQuery }: { searchQuery: string }) => {
      onQueryChange(searchQuery)
    },
    [onQueryChange]
  )

  return (
    <Box>
      <SuperForm onSubmit={noop} schema={SEARCH_QUERY_SCHEMA}>
        <UseOnChange onChange={onChange} />
        <TextField
          {...fieldProps.searchQuery}
          leftAdornment={<Icons.Search $size='md' />}
          placeholder={!isNil(placeholder) ? placeholder : t('actions.search')}
          isClearable
        />
      </SuperForm>
    </Box>
  )
}

export default SearchQueryForm
