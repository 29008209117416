import { AlertCreationType } from '@weenat/client/dist/core/alerts/alerts'
import { useIntl } from '@weenat/wintl'
import Pop from 'app/src/kit/Pop'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import { useState } from 'react'
import { styled } from 'styled-components'
import AlertCreationStep, { AlertCreationStepProps } from './AlertCreationStep'

const TypeCardContainer = styled(Box)<{ $isSelected: boolean }>`
  padding: 16px;
  margin-bottom: 8px;
  border-radius: ${(p) => p.theme.radiuses.md}px;
  border: 2px solid;
  border-color: ${(props) =>
    props.$isSelected ? props.theme.colors.primary[500] : props.theme.colors.grayscale[300]};
  background-color: ${(props) =>
    props.$isSelected ? props.theme.colors.primary[200] : 'transparent'};

  &:hover {
    cursor: pointer;
    border-color: ${(p) => p.theme.colors.primary[500]};
  }
`

interface TypeCardProps {
  label: string
  description: string
  example: string
  isSelected: boolean
  onPress: () => void
  id?: string
}

const TypeCard: FC<TypeCardProps> = ({ id, description, example, label, isSelected, onPress }) => (
  <TypeCardContainer id={id} $isSelected={isSelected} onClick={onPress}>
    <Text $fontWeight='bold'>{label}</Text>
    <Text>{description}</Text>
    <Text $fontStyle='italic'>{example}</Text>
  </TypeCardContainer>
)

type TypeStepProps = AlertCreationStepProps<AlertCreationType>

interface PickAlertCreationTypeProps extends TypeStepProps {
  initialType: AlertCreationType
}

const PickAlertCreationType: FC<PickAlertCreationTypeProps> = ({
  onPressNext,
  initialType,
  ...props
}) => {
  const { t } = useIntl()

  const [selectedType, setSelectedType] = useState<AlertCreationType>(initialType)
  return (
    <AlertCreationStep
      {...props}
      canProgressToNext={!isNil(selectedType)}
      onPressNext={() => onPressNext?.(selectedType)}
    >
      <TypeCard
        label={t('alerts.types.simple.name')}
        description={t('alerts.types.simple.description')}
        example={t('alerts.types.simple.example')}
        isSelected={selectedType === 'simple'}
        onPress={() => setSelectedType('simple')}
      />

      <Pop tourName='discover_alerts' stepName='alert_type_explanation'>
        <TypeCard
          label={t('alerts.types.multiParams.name')}
          description={t('alerts.types.multiParams.description')}
          example={t('alerts.types.multiParams.example')}
          isSelected={selectedType === 'multiParams'}
          onPress={() => setSelectedType('multiParams')}
        />
      </Pop>
    </AlertCreationStep>
  )
}

export default PickAlertCreationType
