import { useClient, useMutation } from '@weenat/client'
import ErrCode from '@weenat/client/dist/ErrCode'
import { getDeviceCommonName } from '@weenat/client/dist/core/devices'
import canBeAStation from '@weenat/client/dist/core/devices/canBeAStation'
import canBeOnWeatherMap from '@weenat/client/dist/core/devices/canBeOnWeatherMap'
import { Device } from '@weenat/client/dist/resources/devices'
import { Plot } from '@weenat/client/dist/resources/plots'
import { useIntl } from '@weenat/wintl'
import { useNavigate } from 'app/routx-router'
import useToasts from 'app/src/hooks/useToasts'
import AdvancedClientList from 'app/src/kit/AdvancedClientList'
import Button from 'app/src/kit/Button'
import Checkbox from 'app/src/kit/Checkbox'
import ChoiceCard from 'app/src/kit/ChoiceCard'
import DelimitedFlex from 'app/src/kit/DelimitedFlex'
import Icons from 'app/src/kit/Icons'
import ListEmpty from 'app/src/kit/ListEmpty'
import Modal from 'app/src/kit/Modal'
import SmartText from 'app/src/kit/SmartText'
import Text from 'app/src/kit/Text'
import TextEllipsisContainer from 'app/src/kit/TextEllipsisContainer'
import ToastActions from 'app/src/kit/ToastActions'
import WeenatPressable from 'app/src/kit/WeenatPressable'
import { getCoordinatesFromDevice } from 'app/src/map/utils'
import { plot_creation_href } from 'app/src/plots/creation/constants'
import { capitalize } from 'app/utils'
import { omit } from 'lodash-es'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { useCallback, useState } from 'react'
import { useTheme } from 'styled-components'
import PlotOriginBadge from '../plots/PlotOriginBadge'

const plotParams = { limit: 10 }

interface DeviceCreationFollowUpActionsProps {
  device: Device
  isOpen: boolean
  close: () => void
  selectedPlots: Record<string, Plot>
  setSelectedPlots: React.Dispatch<React.SetStateAction<Record<string, Plot>>>
}

const DeviceCreationFollowUpActions: FC<DeviceCreationFollowUpActionsProps> = ({
  isOpen,
  close,
  device,
  selectedPlots,
  setSelectedPlots
}) => {
  const { t } = useIntl()
  const { colors } = useTheme()
  const nav = useNavigate()
  const client = useClient()
  const { addErrorToast, addSuccessToast } = useToasts()

  const [step, setStep] = useState<'options' | 'plots'>('options')

  const isWeatherDevice = !isNil(device) && canBeAStation(device)
  const canBeViewed = !isNil(device) && canBeOnWeatherMap(device)

  const commonName = getDeviceCommonName(device)
  const technicalName = device.endUserName.join(' ')
  const completeDeviceName = `${commonName} (${technicalName})`

  const coordinates = !isNil(device) ? getCoordinatesFromDevice(device, false) : undefined
  const stationId =
    !isNil(device?.stationIds) && device?.stationIds.length > 0 ? device?.stationIds[0] : undefined

  const [linkPlots, linkPlotsRequest] = useMutation(client.devices.linkPlots(device?.id))

  const handleSubmit = async () => {
    const plotIds = Object.values(selectedPlots).map(({ id }) => id)

    await linkPlots(
      { plotIds },
      {
        onSuccess: () => {
          addSuccessToast(
            <>
              <Box $p='md' $pt={0}>
                <Text>{t('models.device.successLinkPlot')}</Text>
              </Box>
              {plotIds.length === 1 && (
                <ToastActions
                  actions={[
                    {
                      id: 'view_plot',
                      onPress: () => {
                        nav(`/administration/${device?.organizationId}/plots/${plotIds[0]}`)
                      },
                      label: t('models.plot.actions.view_plot', { capitalize: true })
                    }
                  ]}
                />
              )}
            </>
          )

          close()
        },
        onError: (error) => {
          const genericErrorMessage = t('models.device.errorLinkPlots', {
            capitalize: true
          })

          const errors = error.nonFieldErrors?.map((err) => {
            return err.code === ErrCode.deviceAlreadyInUse ||
              err.code === ErrCode.deviceMetricsConflictWithDevicePlotConfig
              ? t(`error_codes.${err.code}`, { capitalize: true })
              : genericErrorMessage
          })

          if (!isNil(errors) && !isEmpty(errors)) {
            addErrorToast(errors.join('\n'))
          } else {
            addErrorToast(genericErrorMessage)
          }
        }
      }
    )
  }

  const actions = [
    {
      id: 0,
      title: t('models.station.actions.view_station', { capitalize: true }),
      subtitle: t('addDeviceModal.showStationDescription'),
      icon: <Icons.Preview $size='lg' />,
      isHidden: !canBeViewed || !isWeatherDevice || isNil(stationId),
      action: () => {
        nav(`/stations/${stationId}/summary`)
      }
    },
    {
      id: 1,
      title: t('models.device.actions.view_device', { capitalize: true }),
      subtitle: t('addDeviceModal.showDeviceDescription'),
      icon: <Icons.Preview $size='lg' />,
      isHidden: !canBeViewed || isWeatherDevice,
      action: () => {
        !isNil(device) && nav(`/weather-map/${device.id}/summary`)
      }
    },
    {
      id: 2,
      title: t('models.plot.actions.create'),
      subtitle: t('addDeviceModal.createPlotDescription'),
      icon: <Icons.PlotsFilled $size='lg' />,
      action: () =>
        nav(plot_creation_href, {
          search: {
            step: 'location',
            selectedDeviceIds: !isNil(device) ? [device.id] : undefined,
            latitude: !isNil(coordinates) ? coordinates.lat : undefined,
            longitude: !isNil(coordinates) ? coordinates.lng : undefined
          }
        })
    },
    {
      id: 3,
      title: t('addDeviceModal.linkPlot'),
      subtitle: t('addDeviceModal.linkPlotDescription'),
      icon: <Icons.Webhook $size='lg' />,
      isHidden: false,
      action: () => setStep('plots')
    }
  ]

  const availableActions = actions.filter((item) => !item.isHidden)

  const renderItem = useCallback(
    (item: Plot) => {
      const isSelected = !isNil(selectedPlots[item.id])
      return (
        <WeenatPressable
          key={item.id}
          onPress={() => {
            setSelectedPlots((previousPlotsIds) =>
              !isNil(previousPlotsIds[item.id])
                ? omit(previousPlotsIds, item.id)
                : { ...previousPlotsIds, [item.id]: item }
            )
          }}
        >
          <DelimitedFlex
            $flexWrap='wrap'
            $gap='md'
            $backgroundColor={isSelected ? 'primary-200' : undefined}
            $p='lg'
            $alignItems='center'
          >
            <Checkbox checked={isSelected} />
            <TextEllipsisContainer>
              <Text $ellipsis $color={isSelected ? 'primary.500' : undefined}>
                {item.name}
              </Text>
            </TextEllipsisContainer>
            <PlotOriginBadge plot={item} />
          </DelimitedFlex>
        </WeenatPressable>
      )
    },
    [selectedPlots, setSelectedPlots]
  )

  return (
    <Modal
      isOpen={isOpen}
      title={capitalize(completeDeviceName)}
      close={close}
      width={600}
      footer={
        <DelimitedFlex $isDelimitedOnTop $p='lg' $justifyContent='flex-end' $gap='md'>
          {step === 'options' ? (
            <>
              <Button importance='sd' onPress={() => nav('/plots')}>
                {t('actions.go_back_to_app')}
              </Button>
              <Button onPress={close}>{t('addDeviceModal.addAnotherDevice')}</Button>
            </>
          ) : (
            <>
              <Button importance='sd' onPress={() => setStep('options')}>
                {t('actions.go_back')}
              </Button>
              <Button onPress={handleSubmit} isLoading={linkPlotsRequest.isPending}>
                {t('actions.confirm')}
              </Button>
            </>
          )}
        </DelimitedFlex>
      }
    >
      <Flex $gap='lg' $flexDirection='column' $flex={1}>
        {step === 'options' ? (
          <>
            <Flex
              $flexDirection='column'
              $alignItems='center'
              $justifyContent='center'
              $gap='md'
              $flex={1}
            >
              <Icons.CheckCircle $size='lg' $color={'feedback.success.500'} />
              <SmartText
                vars={{ deviceName: completeDeviceName }}
                tkey='models.device.actions.create_success_with_name'
                $textAlign='center'
                $fontSize='md'
              />
            </Flex>
            {availableActions.map((item) => {
              return (
                <ChoiceCard
                  onPress={item.action}
                  key={item.id}
                  title={item.title}
                  body={item.subtitle}
                  icon={item.icon}
                />
              )
            })}
          </>
        ) : step === 'plots' ? (
          <Box $flex={1}>
            <AdvancedClientList
              inputBgColor={colors.grayscale[100]}
              searchLabel={t('models.device.actions.select_linked_plots')}
              hideTopNav
              searchable
              renderItem={renderItem}
              ListEmptyComponent={<ListEmpty model='plot' />}
              initialQueryParams={plotParams}
              requesterBuilder={client.plots.getPage}
            />
          </Box>
        ) : null}
      </Flex>
    </Modal>
  )
}

export default DeviceCreationFollowUpActions
