import { MapOptions } from 'google-map-react'
import { LatLng } from './types'

export const mapOptions: MapOptions = {
  clickableIcons: false,
  mapTypeId: 'hybrid',
  fullscreenControl: false,
  mapTypeControl: false,
  rotateControl: false,
  scaleControl: false,
  zoomControl: false,
  // There are no plots on Mars
  restriction: {
    latLngBounds: {
      north: 85,
      south: -85,
      west: -180,
      east: 180
    }
  },
  styles: [
    {
      featureType: 'road',
      elementType: 'all',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'transit',
      elementType: 'all',
      stylers: [{ visibility: 'off' }]
    }
  ]
}

export const defaultCenter: LatLng = {
  lat: 46.26576825,
  lng: 2.20720289999997
}

export const defaultZoom = 5

const controlsTypes = ['GEOLOCALISATOR', 'DRAWING', 'ZOOM'] as const
export type MapsControlsType = (typeof controlsTypes)[number]

const drawingControlsTypes = ['GRAB', 'POLYGON', 'TRASH', 'FULLSCREEN'] as const
export type MapsDrawingControlsType = (typeof drawingControlsTypes)[number]

export const defaultControlsTypes: MapsControlsType[] = ['GEOLOCALISATOR', 'ZOOM']

export const zoomControlsTypes = ['ZOOM_IN', 'ZOOM_OUT'] as const
export type MapsZoomControlsType = (typeof zoomControlsTypes)[number]

export type AllMapsControlsType = MapsControlsType | MapsZoomControlsType | MapsDrawingControlsType

export interface MapControlOptions {
  active?: boolean
  disabled?: boolean
  onPress?: () => void
  onSuccess?: PositionCallback | (() => void)
  onError?: () => void
}

type MapControlsRecord = Record<AllMapsControlsType, MapControlOptions>

export type MapsControlsOptions = Partial<
  Record<AllMapsControlsType, MapControlOptions | Partial<MapControlsRecord>>
>
