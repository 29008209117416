import { transparentize } from 'polished'
import { styled } from 'styled-components'

const SIZE = 48
const INNER_SIZE = 16

const WideRadius = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme }) => transparentize(0.5, theme.colors.tertiary[500])};

  width: ${SIZE}px;
  height: ${SIZE}px;

  /* the marker will be displayed on map by its top left corner so we have to offset it by -50% */
  margin-top: -${SIZE / 2}px;
  margin-left: -${SIZE / 2}px;

  box-shadow: ${(p) => p.theme.shadows.md.boxShadow};
  border-radius: ${(p) => p.theme.radiuses.rounded}px;
`

const Dot = styled.div`
  background-color: ${({ theme }) => theme.colors.tertiary[500]};
  border: 2px solid ${(p) => p.theme.colors.grayscale.white};
  border-radius: ${(p) => p.theme.radiuses.rounded}px;
  width: ${INNER_SIZE}px;
  height: ${INNER_SIZE}px;
  box-shadow: ${(p) => p.theme.shadows.lg.boxShadow};
`

const UserGeolocationMarker = (
  /** Needed for GoogleMap to not move the marker on pan, even if not used otherwise */
  _props: {
    lat: number
    lng: number
  }
) => (
  <WideRadius>
    <Dot />
  </WideRadius>
)

export default UserGeolocationMarker
