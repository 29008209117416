import { GoogleMapApi } from 'app/src/map/utils'
import { useUserLanguage } from 'app/state'
import GoogleMapReact, { ChangeEventValue, Props as GoogleMapReactProps } from 'google-map-react'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { useCallback, useRef } from 'react'
import { styled } from 'styled-components'
import { defaultCenter, defaultZoom, mapOptions } from '../../../map/utils/defaults'
import {
  useBackgroundMapContext,
  useBackgroundMapDispatcher
} from './contexts/BackgroundMapContext'

type ContainerProps = {
  $isHidden?: boolean
  $isEmbedded?: boolean
}

const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  ${(p) =>
    p.$isHidden &&
    `
      width: 0%;
      height: 0%;
      visibility: hidden;
    `}
  ${(p) =>
    p.$isEmbedded &&
    `
     position: initial;
    `}
`

interface Props extends GoogleMapReactProps, ContainerProps {}

const BackgroundGoogleMap: FC<Props> = ({ $isHidden, $isEmbedded, children, ...props }) => {
  const [language] = useUserLanguage()
  const ref = useRef(null)

  const { markers, renderMarkers, onChange } = useBackgroundMapContext()

  const dispatch = useBackgroundMapDispatcher()

  const bootstrapURLKeys = {
    key: import.meta.env.VITE_GOOGLE_API_KEY,
    libraries: ['drawing', 'places', 'geometry'],
    language
  }

  const initializeBackgroundMapApi = useCallback(
    (api: GoogleMapApi) => {
      dispatch({ type: 'setApi', newApi: api })
    },
    [dispatch]
  )

  const updateBackgroundMapDetails = useCallback(
    (event: ChangeEventValue) => {
      dispatch({
        type: 'setMapDetails',
        newMapDetails: {
          zoom: event.zoom,
          bbox: [event.bounds.nw.lng, event.bounds.se.lat, event.bounds.se.lng, event.bounds.nw.lat]
        }
      })

      if (!isNil(onChange)) onChange()
    },
    [dispatch, onChange]
  )

  return (
    <Container $isHidden={$isHidden} $isEmbedded={$isEmbedded}>
      <GoogleMapReact
        ref={ref}
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={bootstrapURLKeys}
        options={mapOptions}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        onGoogleApiLoaded={initializeBackgroundMapApi}
        onChange={updateBackgroundMapDetails}
        debounced
        {...props}
      >
        {!isEmpty(markers) && !isNil(renderMarkers) ? markers.map(renderMarkers) : null}
        {children}
      </GoogleMapReact>
    </Container>
  )
}

export default BackgroundGoogleMap
