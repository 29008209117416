import ErrCode from '@weenat/client/dist/ErrCode'
import { useIntl } from '@weenat/wintl'
import Button from 'app/src/kit/Button'
import Modal, { ModalProps } from 'app/src/kit/Modal'
import SupportContactInfos from 'app/src/kit/SupportContactInfos'
import Text from 'app/src/kit/Text'

const IncompatibleDeviceModal: FC<Omit<ModalProps, 'title'>> = ({
  isOpen,
  close,
  ...modalProps
}) => {
  const { t } = useIntl()

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      title={t('models.device.errors.incompatible_device')}
      {...modalProps}
      width={600}
    >
      <Text>{t(`error_codes.${ErrCode.deviceIncompatibleWithPlanOrPlot}`)}</Text>
      <Box $mt='md'>
        <SupportContactInfos />
      </Box>
      <Flex $alignItems='center' $justifyContent='flex-end' $mt='lg'>
        <Button onPress={close}>{t('actions.understand')}</Button>
      </Flex>
    </Modal>
  )
}

export default IncompatibleDeviceModal
