import { getMarkerBackgroundColor, getMarkerColorScale } from '@weenat/client/dist/core/map'
import {
  extractValueFromSummaryData,
  getZIndex,
  PointProperties
} from '@weenat/client/dist/core/measurements'
import { isHydricStress } from '@weenat/client/dist/enums/MetricIds'
import { useConvertedValue } from '@weenat/client/dist/hooks'
import { Device } from '@weenat/client/dist/resources/devices'
import { Station } from '@weenat/client/dist/resources/stations'
import { useNavigate } from 'app/routx-router'
import FocusedDeviceSummaryCard from 'app/src/devices/FocusedDeviceSummaryCard'
import { useIsAppAccessRestricted } from 'app/src/hooks/useIsAppAccessRestricted'
import Icons from 'app/src/kit/Icons'
import HydricStressTrendIcon from 'app/src/kit/icons/HydricStressTrendIcon'
import WindDirectionIcon from 'app/src/kit/icons/WindDirectionIcon'
import { Tooltip, TooltipContent, TooltipTrigger } from 'app/src/kit/tooltips/Tooltip'
import MapMarkerOutline, { DEFAULT_MARKER_SIZE_IN_PX } from 'app/src/map/MapMarkerOutline'
import { useUniverseHorizon } from 'app/state'
import isNil from 'lodash-es/isNil'
import { readableColor } from 'polished'
import { ReactElement, useCallback, useMemo } from 'react'
import { useTheme } from 'styled-components'
import { useBackgroundMapDispatcher } from '../contexts/BackgroundMapContext'
import useUniverseBackgroundMapContext from '../universes/useUniverseBackgroundMapContext'
import { isFreemiumAccessibleAggregate } from '../utils/fitBoundsWithPadding'
import MarkerText from './MarkerText'
import RestrictedMarker from './RestrictedMarker'

const HYDRIC_STRESS_MARKER_SIZE = 64

interface DumbMarkerProps {
  backgroundColor: string
  value: string
  lat: number
  lng: number
  size: number
  shape: 'circle' | 'square'
  zIndex: number
  onPress: () => void
  onMouseEnter: () => void
  isFocused: boolean
}

const DumbMarker: FC<DumbMarkerProps> = ({
  backgroundColor,
  lat,
  lng,
  size,
  shape,
  zIndex,
  onPress,
  onMouseEnter,
  isFocused,
  children
}) => {
  const radius = shape === 'circle' ? undefined : 8

  return (
    <MapMarkerOutline
      $zIndex={zIndex}
      $isFocused={isFocused}
      $backgroundColor={backgroundColor}
      $size={size}
      $borderRadius={radius}
      $lat={lat}
      $lng={lng}
      onClick={onPress}
      onMouseEnter={(e) => {
        e.stopPropagation()
        onMouseEnter()
      }}
    >
      {children}
    </MapMarkerOutline>
  )
}

const TOOLTIP_STYLE = {
  padding: 0,
  backgroundColor: 'transparent',
  border: 'none',
  borderRadius: 0,
  boxShadow: 'none'
}

interface PODMarkerProps
  extends Pick<
    PointProperties,
    'isFavorite' | 'countByMetric' | 'summary' | 'isVirtualDeviceByMetric' | 'kind'
  > {
  lat: number
  lng: number
  shape: 'circle' | 'square'
  resourceId: number | string | undefined
  summaryLink: string
  isFocused: boolean
  device?: Device | Station
}

/**
 * POD stands for Plot Or Device
 */
const PODMarker: FC<PODMarkerProps> = ({
  resourceId,
  isFavorite,
  countByMetric,
  summaryLink,
  isFocused,
  isVirtualDeviceByMetric,
  kind,
  lat,
  lng,
  shape,
  summary,
  device
}) => {
  const dispatch = useBackgroundMapDispatcher()
  const nav = useNavigate()
  const { colors } = useTheme()
  const { convertValue } = useConvertedValue()
  const [horizon] = useUniverseHorizon()
  const { focusedMetricId, aggregate, universeAggregate, span } = useUniverseBackgroundMapContext()

  const { isRestricted: isAccessRestrictedByPlan } = useIsAppAccessRestricted({
    enabled: true
  })

  const dataFromSummary =
    !isNil(focusedMetricId) && !isNil(universeAggregate)
      ? extractValueFromSummaryData({
          data: summary,
          metricId: focusedMetricId,
          aggregate,
          universeAggregate,
          horizon
        })
      : undefined

  const zIndex = useMemo(
    () =>
      getZIndex({
        isFavorite,
        isFocused,
        metricId: focusedMetricId,
        rawValue: dataFromSummary?.value
      }),
    [isFavorite, isFocused, focusedMetricId, dataFromSummary?.value]
  )

  const setHoverIntent = useCallback(() => {
    dispatch({
      type: 'setHoverIntent',
      newHoverIntent: {
        itemId: resourceId,
        source: 'marker'
      }
    })
  }, [dispatch, resourceId])

  const pressAndClearIntent = useCallback(() => {
    dispatch({
      type: 'resetHoverIntent'
    })
    nav(summaryLink)
    // nav should not be included
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, summaryLink, kind, resourceId])

  if (isNil(focusedMetricId) || !countByMetric.has(focusedMetricId)) {
    return null
  }

  const isRestricted = isAccessRestrictedByPlan
    ? isVirtualDeviceByMetric.get(focusedMetricId)
      ? !isFreemiumAccessibleAggregate(universeAggregate)
      : true
    : false

  if (isRestricted) {
    return <RestrictedMarker key={resourceId} lat={lat} lng={lng} />
  }

  const { value, threshold, trend } = dataFromSummary ?? {
    value: null,
    threshold: null,
    trend: null
  }

  const colorScale = getMarkerColorScale(focusedMetricId, span)
  const backgroundColor = getMarkerBackgroundColor({
    rawValue: value,
    colorScale,
    colors,
    focusedMetricId,
    threshold,
    isRestricted
  })

  const displayed = convertValue({ metric: focusedMetricId, value, displayUnit: false })

  let valueElement: ReactElement | undefined

  const textColor = readableColor(backgroundColor)

  if (!isNil(value) && Number.isFinite(value)) {
    if (focusedMetricId === 'DD') {
      valueElement = <WindDirectionIcon windOrigin={value} $color='grayscale.white' $size='sm' />
    } else if (!isNil(focusedMetricId) && isHydricStress(focusedMetricId) && !isNil(trend)) {
      valueElement = (
        <Flex $flexDirection='column' $alignItems='center'>
          <HydricStressTrendIcon trend={trend} $color={textColor} $size='md' />
          <MarkerText $color={textColor}>{displayed}</MarkerText>
        </Flex>
      )
    }
  } else {
    valueElement = <Icons.MinusSign $size='lg' />
  }

  return (
    <Tooltip enableSafePolygon restMs={200}>
      <TooltipTrigger>
        <DumbMarker
          lat={lat}
          lng={lng}
          backgroundColor={backgroundColor}
          value={displayed}
          onPress={pressAndClearIntent}
          onMouseEnter={setHoverIntent}
          zIndex={zIndex}
          shape={shape}
          size={
            !isNil(focusedMetricId) && isHydricStress(focusedMetricId)
              ? HYDRIC_STRESS_MARKER_SIZE
              : DEFAULT_MARKER_SIZE_IN_PX
          }
          isFocused={isFocused}
        >
          {isNil(valueElement) ? (
            <MarkerText $color={readableColor(backgroundColor)}>{displayed}</MarkerText>
          ) : (
            valueElement
          )}
        </DumbMarker>
      </TooltipTrigger>
      <TooltipContent style={TOOLTIP_STYLE}>
        {!isNil(device) ? (
          <FocusedDeviceSummaryCard
            device={device}
            summary={summary}
            onClose={() => {
              dispatch({ type: 'resetHoverIntent' })
            }}
          />
        ) : null}
      </TooltipContent>
    </Tooltip>
  )
}

export default PODMarker
