import { RequestType } from '@weenat/client/dist/resources/invitations'
import * as zodSchemas from '@weenat/client/dist/zod-schemas'
import { useIntl } from '@weenat/wintl'
import { useNavigate, useSearchParams } from 'app/routx-router'
import Button from 'app/src/kit/Button'
import SmartText from 'app/src/kit/SmartText'
import Text from 'app/src/kit/Text'
import OnboardingCard from 'app/src/onboardings/OnboardingCard'
import { useUserContext } from 'app/userProvider'
import { isNil } from 'lodash-es'
import { z } from 'zod'

export const settings = {
  search: z.object({ invite: zodSchemas.emailInvitation.optional() })
}

const OnboardingWelcome = () => {
  const { t } = useIntl()
  const nav = useNavigate()
  const { me } = useUserContext()

  const [{ invite }] = useSearchParams(settings.search)

  return (
    <OnboardingCard>
      <Flex $flexDirection='column' $p='lg' $gap='lg'>
        <Text $fontWeight='bold' $fontSize='md' $textAlign='center'>
          {t('onboardings.greetings', { name: me?.firstName })}
        </Text>
        <Text $textAlign='center'>{t('onboardings.welcome_to_weenat')}</Text>
        {!isNil(invite) ? (
          <>
            {invite.type === RequestType.autoAcceptedAdherentFarmInvitation ? (
              <SmartText
                tkey='models.network.misc.thanks_for_joining'
                vars={{ networkName: invite.networkName }}
                $textAlign='center'
              />
            ) : invite.type === RequestType.autoAcceptedMemberInvitation ? (
              <SmartText
                tkey='models.organization.misc.thanks_for_joining_as_member'
                vars={{
                  farmName: invite.orgName
                }}
                $textAlign='center'
              />
            ) : null}
          </>
        ) : null}
        <Text $textAlign='center'>{t('onboardings.setup_together')}</Text>
      </Flex>
      <Button
        onPress={() => {
          if (!isNil(invite) && invite.type === RequestType.autoAcceptedMemberInvitation) {
            nav('/onboarding/members-tips', { search: { invite } })
          } else {
            nav('/onboarding/my-farm', { search: { invite } })
          }
        }}
      >
        {t('actions.lets_go', { capitalize: true })}
      </Button>
    </OnboardingCard>
  )
}

export default OnboardingWelcome
