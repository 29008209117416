import { superscheme, validators as v } from '@weenat/client'
import { OrgRole } from '@weenat/client/dist/enums'
import { Id } from '@weenat/client/dist/resources/types'
import { useIntl } from '@weenat/wintl'
import useSelectOrg, { UseSelectOrgArgs } from 'app/src/hooks/useSelectOrg'
import SuperForm from 'app/src/kit/SuperForm'
import SelectField from 'app/src/kit/fields/SelectField'
import UseOnChange from 'app/src/kit/fields/UseOnChange'
import { LogoSphere } from 'app/src/kit/icons/logos'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import noop from 'lodash-es/noop'
import { styled } from 'styled-components'
import logEvent from '../../../../utils/analytics'

// TODO: Add null as a possibility for childOrganizationId
const orgSelectionSchema = superscheme({
  organizationId: v.number,
  childOrganizationId: v.optionalNumber
})

type SelectOrgFieldsProps = Omit<UseSelectOrgArgs, 'setSelectedOrgs'> & {
  orgFieldName?: string
  childOrgFieldName?: string
  setSelectedOrgs?: UseSelectOrgArgs['setSelectedOrgs']
}
const NetworkLogo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`
/**
 * Render only the fields without a form wrapper
 */
export const SelectOrgFields = ({
  selectedOrgs,
  setSelectedOrgs = noop,
  selectorPredicate,
  withoutChild,
  orgFieldName = 'organizationId',
  childOrgFieldName = 'childOrganizationId'
}: SelectOrgFieldsProps) => {
  const { t } = useIntl()

  const {
    network,
    org,
    selectOrgLabel,
    selectChildOrgLabel,
    orgOptions,
    childOrgOptions,
    selectedOrgs: { childOrganizationId },
    isOrgNetwork,
    orgsRequest,
    isLoading: networkOrgIsLoading,
    setEnableNetworkOrgRequest
  } = useSelectOrg({
    selectedOrgs,
    setSelectedOrgs,
    selectorPredicate
  })

  return (
    <>
      <SelectField
        leftAdornment={
          network ? (
            <Box $height='100%' $maxWidth={64}>
              {isNil(network?.logo) ? (
                <LogoSphere height='100%' width={24} />
              ) : (
                <NetworkLogo src={network?.logo} />
              )}
            </Box>
          ) : null
        }
        name={orgFieldName}
        isDisabled={orgsRequest.isLoading}
        options={orgOptions}
        label={selectOrgLabel}
      />
      {!withoutChild &&
      isOrgNetwork &&
      org?.myRole !== OrgRole.viewer &&
      !isEmpty(childOrgOptions) ? (
        <SelectField
          isAutocomplete
          name={childOrgFieldName}
          label={selectChildOrgLabel}
          options={childOrgOptions}
          isDisabled={networkOrgIsLoading}
          isLoading={networkOrgIsLoading}
          placeholder={!isNil(childOrganizationId) ? undefined : t('map.network_plots')}
          onToggle={(isOpen) => setEnableNetworkOrgRequest(isOpen)}
        />
      ) : null}
    </>
  )
}

const SelectOrg = ({ selectedOrgs, setSelectedOrgs, selectorPredicate }: UseSelectOrgArgs) => {
  const {
    selectedOrgs: { organizationId, childOrganizationId },
    org
  } = useSelectOrg({
    selectedOrgs,
    setSelectedOrgs,
    selectorPredicate
  })

  const handleChange = (formValues: {
    organizationId: number
    childOrganizationId?: number | null
  }) => {
    // Not using isNil because childOrganizationId = null is a valid value here
    if (
      formValues.childOrganizationId !== undefined &&
      formValues.childOrganizationId !== childOrganizationId
    ) {
      logEvent('change_child_organisation')
      setSelectedOrgs({
        organizationId: formValues.organizationId,
        childOrganizationId: formValues.childOrganizationId
      })
    } else if (formValues.organizationId !== organizationId) {
      setSelectedOrgs({
        organizationId: formValues.organizationId,
        childOrganizationId: null
      })
    }
  }

  if (!isNil(organizationId)) {
    return (
      <Flex $flexDirection='column'>
        <SuperForm
          schema={orgSelectionSchema}
          enableReinitialize
          initialValues={{
            organizationId: org?.id,
            childOrganizationId: childOrganizationId as Id
          }}
        >
          <SelectOrgFields
            selectedOrgs={selectedOrgs}
            setSelectedOrgs={setSelectedOrgs}
            selectorPredicate={selectorPredicate}
          />
          <UseOnChange onChange={handleChange} />
        </SuperForm>
      </Flex>
    )
  }
  return null
}

export default SelectOrg
