import { useIntl } from '@weenat/wintl'
import { useOrgContext } from 'app/orgProvider'
import { useNavigate } from 'app/routx-router'
import AlertsList from 'app/src/alerts/AlertsList'
import CreateAlertButton from 'app/src/alerts/CreateAlertButton'
import FeatGuarded from 'app/src/kit/FeatGuarded'
import HelmetAndBreadcrumbs from 'app/src/kit/HelmetAndBreadcrumbs'
import Text from 'app/src/kit/Text'
import LayoutHeaderActions from 'app/src/layouts/LayoutHeaderActions'
import { useCallback } from 'react'

function Alerts() {
  const nav = useNavigate()
  const { t } = useIntl()
  const { org } = useOrgContext()
  const handleOpenCreateModal = useCallback(() => nav('/alerts/create'), [])

  return (
    <>
      <HelmetAndBreadcrumbs screenTitle={t('models.alert.model.name', { capitalize: true })} />
      <FeatGuarded org={org} feat={'FEAT-ALERT-GLOBAL'}>
        <LayoutHeaderActions>
          <CreateAlertButton onPress={handleOpenCreateModal} />
        </LayoutHeaderActions>
        <Text $fontSize='lg' $fontWeight='bold'>
          {t('alerts.my_alerts', { capitalize: true })}
        </Text>
        <AlertsList searchable />
      </FeatGuarded>
    </>
  )
}

export default Alerts

export const ErrorBoundary = () => null
