import { useIntl } from '@weenat/wintl'
import Text from 'app/src/kit/Text'
import { memo } from 'react'
import { NetworkPresentationCommonProps } from './NetworkPresentationLayoutTools'

const NetworkDescriptionSection: FC<NetworkPresentationCommonProps> = ({ network }) => {
  const { t } = useIntl()

  return (
    <Flex $flexDirection='column' $p='lg' $gap='md'>
      <Text $fontSize='lg' $fontWeight='bold'>
        {t('models.network.misc.about')}
      </Text>
      <Text>{network.description}</Text>
    </Flex>
  )
}

export default memo(NetworkDescriptionSection)
