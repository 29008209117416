/* eslint-disable react/no-array-index-key */
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import ContentLoader from 'react-content-loader'
import { styled } from 'styled-components'

const svgRowHeight = 48
const defaultWidth = 600

const Container = styled.div<{ $height: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${(props) => `${props.$height}px`};
  margin-bottom: 16px;
`

interface LoadingTableProps {
  numberOfRows?: number
  numberOfColumns?: number
  uniqueKey: string
}

const LoadingTable: FC<LoadingTableProps> = memo(
  ({ numberOfRows = 3, numberOfColumns = 5, uniqueKey }) => {
    const ref = useRef<HTMLDivElement>(null)
    const [width, setWidth] = useState(defaultWidth)
    const height = svgRowHeight * numberOfRows

    const columns = useMemo(() => {
      const tempCol = []
      for (let rowIndex = 0; rowIndex < numberOfRows; rowIndex += 1) {
        for (let i = 0; i < numberOfColumns; i += 1) {
          const colWidth = width / numberOfColumns
          tempCol.push({
            x: 16 + colWidth * i,
            y: 32 + svgRowHeight * rowIndex,
            width: colWidth - 32,
            height: svgRowHeight - 32
          })
        }
      }
      return tempCol
    }, [numberOfColumns, width])

    useEffect(() => {
      if (ref.current) setWidth(ref.current.offsetWidth)
    }, [ref.current])

    return (
      <Container ref={ref} $height={height}>
        <ContentLoader viewBox={`0 0 ${width} ${height}`} height={height} uniqueKey={uniqueKey}>
          {columns.map((col, colIndex) => (
            <rect
              key={`loading-table-row-${colIndex}`}
              x={col.x}
              y={col.y}
              rx='0'
              ry='0'
              width={col.width}
              height={col.height}
            />
          ))}
        </ContentLoader>
      </Container>
    )
  }
)
LoadingTable.displayName = 'LoadingTable'

export default LoadingTable
