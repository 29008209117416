import { setUser as setSentryUser } from '@sentry/react'
import { useClient } from '@weenat/client'
import { canUser, ops } from '@weenat/client/dist/core/access'
import { useHasPaymentIncident } from '@weenat/client/dist/core/billing'
import { useIntl } from '@weenat/wintl'
import GlobalStyles from 'app/GlobalStyles'
import Providers from 'app/Providers'
import { useOrgContext } from 'app/orgProvider'
import { Redirect, Slot, useMatch, usePathname, useProtectedRoute } from 'app/routx-router'
import ShouldVerifyEmail from 'app/src/authentication/components/ShouldVerifyEmail'
import BackgroundMap from 'app/src/dashboard/components/DashboardMap/BackgroundMap'
import useCloseRadarOnPageChange from 'app/src/dashboard/components/DashboardMap/Radar/hooks/useCloseRadarOnPageChange'
import { BackgroundMapContextProvider } from 'app/src/dashboard/components/DashboardMap/contexts/BackgroundMapContext'
import { RadarContextProvider } from 'app/src/dashboard/components/DashboardMap/contexts/RadarContext'
import { useIsAppAccessRestricted } from 'app/src/hooks/useIsAppAccessRestricted'
import useRefreshToken from 'app/src/hooks/useRefreshToken'
import useSelectOrg from 'app/src/hooks/useSelectOrg'
import useTourStepper from 'app/src/hooks/useTourStepper'
import Banner from 'app/src/kit/Banner'
import DowngradeWarn from 'app/src/kit/DowngradeWarn'
import DownloadMobileScreen from 'app/src/kit/DownloadMobileScreen'
import MapBackgroundImage from 'app/src/kit/MapBackgroundImage'
import SmartText from 'app/src/kit/SmartText'
import NavBar from 'app/src/layouts/NavBar'
import { useSelectedOrgs, useToken, useUserLanguage } from 'app/state'
import { useUserContext } from 'app/userProvider'
import { useInitTracking } from 'app/utils/analytics'
import breakpoints from 'app/utils/window/breakpoints'
import isNil from 'lodash-es/isNil'
import omitBy from 'lodash-es/omitBy'
import { default as momentTZ } from 'moment-timezone'
import { useEffect } from 'react'
import { useWindowSize } from 'react-use'
import { styled, useTheme } from 'styled-components'

const useLocaleSync = () => {
  const { locales } = useIntl()
  const [, setUserLanguage] = useUserLanguage()

  useEffect(() => {
    momentTZ.locale(locales.current)
    setUserLanguage(locales.current)
  }, [locales.current])

  return locales.current
}

const ContentContainer = styled(Flex)`
  align-items: stretch;
  flex: 1;
  overflow: hidden;
  height: 100%;
`

/**
 * Effects that need to be run (hence rendered) as children or below the Providers, since it's dependent on store
 */
const EntryPointBehavior: FC = ({ children }) => {
  const pathname = usePathname()
  const [token] = useToken()
  const { colors } = useTheme()
  const { currentOrgId, org } = useOrgContext()
  const [selectedOrgs, setSelectedOrgs] = useSelectedOrgs()
  const { width } = useWindowSize()
  const { user, requestState } = useUserContext()

  const upgradePathMatch = useMatch('/administration/upgrade-to-premium')
  const authMatch = useMatch('/auth/*')
  const plotCreation = useMatch('/plots/create')
  const plotEdition = useMatch('/plots/edit')
  const headquarterEdit = useMatch('/administration/*/edit/headquarter')

  useEffect(() => {
    if (requestState.isSuccess) {
      setSentryUser(omitBy(user, (value) => isNil(value)))
    }
  }, [requestState.isSuccess, user])

  const myAccount = user?.me
  const myProfile = user?.profile

  /** They are the same as for {@link useIsAppAccessRestricted} */
  const paymentIncidentArgs = {
    enabled: !isNil(token) && !isNil(currentOrgId),
    orgId: currentOrgId as number
  }
  const appRestriction = useIsAppAccessRestricted(paymentIncidentArgs)
  const { hasPaymentIncident, paymentIncidentRequest } = useHasPaymentIncident(paymentIncidentArgs)
  const hasDowngradeWarn =
    !isNil(org) &&
    canUser(ops.edit, 'billing', { organization: org }) &&
    appRestriction.isRestricted &&
    appRestriction.reason === 'downgrade' &&
    !isNil(paymentIncidentRequest.data)

  const shouldDisplayDownloadMobileScreen =
    width < breakpoints.TABLET && !pathname.includes('verify-email') && !upgradePathMatch

  const isAcceptTOS = pathname === '/accept-tos'
  const isAcceptPP = pathname === '/accept-pp'

  useProtectedRoute(() => !isNil(token))
  useLocaleSync()
  useInitTracking()
  useCloseRadarOnPageChange()
  useRefreshToken()
  useTourStepper(myAccount, hasDowngradeWarn)

  // this will trigger the default useSelectOrg behavior if currentOrgId happens to be null
  useSelectOrg({
    selectedOrgs:
      currentOrgId === null ? { childOrganizationId: null, organizationId: null } : selectedOrgs,
    setSelectedOrgs
  })

  return shouldDisplayDownloadMobileScreen ? (
    <DownloadMobileScreen />
  ) : myProfile && !myProfile.hasAcceptedTermsOfService && !isAcceptTOS && !isAcceptPP ? (
    <Redirect to='/accept-tos' />
  ) : myProfile && !myProfile.hasAcceptedPolicyPrivacy && !isAcceptPP && !isAcceptTOS ? (
    <Redirect to='/accept-pp' />
  ) : (
    <>
      {/* myAccount.email can be undefined if I just logged in through the debug mode */}
      {myAccount && myAccount.email && !myAccount.isEmailVerified && (
        <ShouldVerifyEmail email={myAccount.email} />
      )}
      {!isNil(currentOrgId) && (
        <>
          {hasDowngradeWarn && (
            <DowngradeWarn
              orgId={currentOrgId}
              paymentDueDate={paymentIncidentRequest.data?.paymentDueDate}
            />
          )}

          {hasPaymentIncident && (
            <Banner $feedback='error'>
              <SmartText
                tkey='ecommerce.billing.payment_incident.web'
                vars={{ orgId: currentOrgId }}
                $color={colors.grayscale.white}
              />
            </Banner>
          )}
        </>
      )}
      {!isNil(token) &&
      !authMatch &&
      !isAcceptTOS &&
      !plotCreation &&
      !plotEdition &&
      !headquarterEdit ? (
        <NavBar key={`navbar_${myProfile?.language}`} />
      ) : null}
      {children}
    </>
  )
}

const BackgroundMapLayout: FC = () => {
  const client = useClient()

  const isMatchingPlotsMapRoute = useMatch('/plots')
  const isMatchingDevicesMapRoute = useMatch('/weather-map')
  const isMatchingPlotCreationRoute = useMatch('/plots/create')
  const isMatchingNewPlotEditionRoute = useMatch('/plots/edit')
  const isMatchingAnAuthRoute = useMatch('/auth/*')
  const isMatchingHeadquarterEditionRoute = useMatch('/administration/*/edit/headquarter')

  /**
   * There are 3 different cases in which we want to have a background map
   * My farm map (/plots), Weather Map (/weather-map), Plot Creation (/create-plot)
   */
  const isMatchingAMapRoute =
    !isNil(isMatchingPlotsMapRoute) ||
    !isNil(isMatchingDevicesMapRoute) ||
    !isNil(isMatchingPlotCreationRoute) ||
    !isNil(isMatchingNewPlotEditionRoute) ||
    !isNil(isMatchingHeadquarterEditionRoute)

  const getBoundingBoxRequester = !isNil(isMatchingPlotsMapRoute)
    ? client.boundingBoxes.plotsAndStations.getBoundingBox
    : isMatchingNewPlotEditionRoute
      ? undefined
      : client.boundingBoxes.devices.getBoundingBox

  /**
   * Important
   * if we keep the MapBackgroundImage when we are on a fullscreen map,
   * this will lead to a flickering/blank google maps tiles issue in Safari when panning the map
   */
  const appBackground = isMatchingAMapRoute ? (
    <BackgroundMap
      isPlotsMap={!isNil(isMatchingPlotsMapRoute)}
      getBoundingBoxRequester={getBoundingBoxRequester}
    />
  ) : isMatchingAnAuthRoute ? null : (
    <MapBackgroundImage />
  )

  return (
    <BackgroundMapContextProvider>
      <RadarContextProvider>
        <Flex $height={'100vh'} $position='relative' $flexDirection='column'>
          <EntryPointBehavior>
            {appBackground}
            <ContentContainer>
              <Slot />
            </ContentContainer>
          </EntryPointBehavior>
        </Flex>
      </RadarContextProvider>
    </BackgroundMapContextProvider>
  )
}

const ProvidersWrappedAppLayout = () => {
  return (
    <Providers>
      <GlobalStyles />
      <BackgroundMapLayout>
        <Slot />
      </BackgroundMapLayout>
    </Providers>
  )
}

export const Component = ProvidersWrappedAppLayout
export default ProvidersWrappedAppLayout
