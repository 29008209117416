import { useClient, useMutation, useQuery } from '@weenat/client'
import { Device } from '@weenat/client/dist/resources/devices'
import { useIntl } from '@weenat/wintl'
import { useNavigate } from 'app/routx-router'
import DeviceCard from 'app/src/devices/DeviceCard'
import useToasts from 'app/src/hooks/useToasts'
import Button from 'app/src/kit/Button'
import Icons from 'app/src/kit/Icons'
import InfoCard from 'app/src/kit/InfoCard'
import LinkFromKit from 'app/src/kit/LinkComponent'
import Text from 'app/src/kit/Text'
import { TextFieldPrimitive } from 'app/src/kit/fields/TextField'
import LoadingCircle from 'app/src/kit/loaders/LoadingCircle'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { useEffect } from 'react'
import { styled } from 'styled-components'
import { plot_creation_href } from './constants'
import { GeoJSON, PlotCreationStepProps } from './types'
import { Point, flex1, getDeviceDepth, getLatLngFromShape, isDeviceSelected } from './utils'

const Link = styled(LinkFromKit)`
  margin-left: auto;
  margin-right: auto;
`

const StyledInfoCard = styled(InfoCard)`
  margin: 16px 0;
`

const VirtualDeviceCard = () => {
  const { t } = useIntl()

  return (
    <Box
      $backgroundColor={'grayscale.100'}
      $p='md'
      $borderRadius={8}
      $borderColor={'grayscale.300'}
      $borderWidth='sm'
    >
      <Flex $justifyContent='flex-end' $alignItems='center' $mb='md'>
        <Box>
          <Icons.MeteoVision
            $backgroundColor={'primary.500'}
            $color={'grayscale.white'}
            $size='md'
            $p='md'
          />
        </Box>
      </Flex>
      <Flex $flexDirection='column' $gap='xs'>
        <Text $fontWeight='semiBold'>
          {t('dataSources.spatialized_weather', { capitalize: true })}
        </Text>
      </Flex>
    </Box>
  )
}

interface SubmitStepProps
  extends Pick<
    PlotCreationStepProps,
    'searchParams' | 'devices' | 'org' | 'shape' | 'plotName' | 'setPlotName'
  > {}

export const SubmitStep = ({
  searchParams,
  devices,
  org,
  shape,
  plotName,
  setPlotName
}: SubmitStepProps) => {
  const { t, formatErrorMessage } = useIntl()
  const nav = useNavigate()

  const { source, depths } = searchParams
  const selectedDevices: Device[] = devices.filter((d) => isDeviceSelected(searchParams, d))
  const client = useClient()
  const { addErrorToast } = useToasts()

  const [createPlot, createPlotState] = useMutation(client.plots.create(), {
    onSuccess: (data) => {
      nav(plot_creation_href, {
        search: {
          ...searchParams,
          step: 'success',
          plotId: data.id
        }
      })
    },
    onError: (e) =>
      addErrorToast(formatErrorMessage({ error: e.nonFieldErrors?.[0], model: 'plots' }))
  })

  const {
    latitude,
    longitude,
    geoJSON
  }: {
    latitude: number | undefined
    longitude: number | undefined
    geoJSON: GeoJSON | Point | undefined
  } = getLatLngFromShape(shape)

  const isLatLngNotNil = !isNil(latitude) && !isNil(longitude)
  const reverseGeocodingRequest = useQuery(
    client.reverseGeoCoding.get({ latitude: latitude as number, longitude: longitude as number }),
    {
      enabled: isLatLngNotNil,
      retry: (failureCount) => {
        return failureCount < 1
      }
    }
  )
  const locationAsText = reverseGeocodingRequest.data?.locationAsText
  useEffect(() => {
    if (isNil(plotName) && !isNil(locationAsText)) {
      setPlotName(locationAsText)
    }
  }, [plotName, locationAsText, setPlotName])
  const createPlotDisabled =
    isNil(latitude) || isNil(longitude) || isNil(plotName) || isEmpty(plotName)

  return reverseGeocodingRequest.isLoading ? (
    <LoadingCircle />
  ) : (
    <Flex $flexDirection='column' $flex={1}>
      <Flex $flex={1} $flexDirection='column' $minHeight='auto'>
        <Text>{t('models.plot.creation.submit_step_title')}</Text>

        <StyledInfoCard
          type='tip'
          title={t('single_words.tip')}
          message={t('models.plot.creation.tip_body')}
        >
          <TextFieldPrimitive
            name='plotName'
            label={undefined}
            min={1}
            value={plotName}
            onChange={(e) => setPlotName(e.currentTarget.value)}
          />
        </StyledInfoCard>

        <Box>
          <Flex $alignItems='center' $gap='md'>
            <Box $flex={1}>
              <Text $fontWeight='semiBold' $ellipsis>
                {t('models.plot.creation.submit_step_source_list_title')}
              </Text>
            </Box>
            <Link
              $color={'primary-500'}
              to={plot_creation_href}
              search={{ ...searchParams, step: 'devicesSelection' }}
              $textAlign='right'
              $underlined
            >
              {t('actions.modify')}
            </Link>
          </Flex>
          <Flex $flexDirection='column' $gap='md' $mt={16}>
            {source === 'vision' ? (
              <VirtualDeviceCard />
            ) : (
              selectedDevices.map((device) => (
                <DeviceCard
                  key={device.id}
                  variant='summary'
                  device={device}
                  depth={getDeviceDepth(searchParams, device)}
                />
              ))
            )}
          </Flex>
        </Box>
      </Flex>
      <Flex $p='lg' $alignItems='center' $gap='xl' $py='md' $minHeight='auto'>
        <Button
          importance='sd'
          color='transparent'
          onPress={() => {
            nav(plot_creation_href, {
              search: {
                ...searchParams,
                step: source === 'vision' ? 'dataSource' : 'devicesSelection'
              }
            })
          }}
        >
          <Text $color={'primary.500'} $fontWeight='semiBold'>
            {t('actions.previous')}
          </Text>
        </Button>
        <Button
          style={flex1}
          state={createPlotState}
          isDisabled={createPlotDisabled}
          onPress={() => {
            if (!createPlotDisabled && !isNil(geoJSON)) {
              const horizons = {} as { [key: string]: number }

              if (!isNil(depths) && !isEmpty(depths)) {
                depths.map((depth) => {
                  const [deviceId, horizonId] = depth.split('_')

                  horizons[deviceId] = parseInt(horizonId, 10)
                })
              }

              createPlot({
                name: plotName,
                organizationId: org.id,
                shape: geoJSON,
                reverseGeocoding: locationAsText ?? null,
                deviceIds: selectedDevices.map((d) => d.id),
                horizons: horizons,
                hasVirtualDevice: searchParams.source === 'vision'
              })
            }
          }}
        >
          {t('actions.confirm')}
        </Button>
      </Flex>
    </Flex>
  )
}
