import {
  AlertCreationType,
  PredicateWithOptionalParam
} from '@weenat/client/dist/core/alerts/alerts'
import { useIntl } from '@weenat/wintl'
import Badge from 'app/src/kit/Badge'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import { styled } from 'styled-components'
import PredicateBreadcrumbs from './PredicateBreadcrumbs'

const StyledBox = styled(Box)`
  width: 100%;
  padding: 8px 0 2px;
  margin-bottom: 8px;
  border-top: 1px solid ${(p) => p.theme.colors.grayscale[300]};
`

interface AlertBreadcrumbsProps {
  predicatesLength: number
  displayedPredicate: PredicateWithOptionalParam
  creationType: AlertCreationType
  navigateToOp?: () => void
  navigateToParams?: () => void
  navigateToSource?: () => void
}

const AlertBreadcrumbs: FC<AlertBreadcrumbsProps> = ({
  predicatesLength,
  creationType,
  displayedPredicate,
  navigateToOp,
  navigateToParams,
  navigateToSource
}) => {
  const { t } = useIntl()
  const couldDisplayCurrentPredicate = displayedPredicate.source !== undefined
  const nonDisplayablePredicateCount = couldDisplayCurrentPredicate ? 0 : 1

  const numberOfAdditionalPredicates =
    predicatesLength - nonDisplayablePredicateCount > 1 ? predicatesLength - 1 : null

  return (
    <StyledBox>
      <Flex $alignItems='center' $justifyContent='space-between'>
        {creationType === 'multiParams' && !isNil(numberOfAdditionalPredicates) && (
          <Badge $backgroundColor={`grayscale.700`} $py='sm' $px='md'>
            <Text $fontSize='sm'>{`+${numberOfAdditionalPredicates} ${t(
              'alerts.validation.events'
            )}`}</Text>
          </Badge>
        )}
      </Flex>
      <Flex $justifyContent='space-between' $alignItems='center'>
        <PredicateBreadcrumbs
          predicate={displayedPredicate}
          navigateToOp={navigateToOp}
          navigateToParams={navigateToParams}
          navigateToSource={navigateToSource}
        />
      </Flex>
    </StyledBox>
  )
}

export default AlertBreadcrumbs
