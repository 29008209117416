import { HydricStressTrend } from '@weenat/client/dist/resources/measurements.type'
import React from 'react'
import { IconProps } from '../Icon'
import Icons from '../Icons'

interface HydricStressTrendIconProps extends IconProps {
  trend: HydricStressTrend
}

const HydricStressTrendIcon: React.FC<HydricStressTrendIconProps> = ({ trend, ...rest }) => {
  switch (trend) {
    case HydricStressTrend.highIncrease:
      return <Icons.TrendingFlat $rotate={-45} {...rest} />
    case HydricStressTrend.slightIncrease:
      return <Icons.TrendingUp {...rest} />
    case HydricStressTrend.stable:
      return <Icons.ArrowRight {...rest} />
    case HydricStressTrend.slightDecrease:
      return <Icons.TrendingDown {...rest} />
    case HydricStressTrend.highDecrease:
      return <Icons.TrendingFlat $rotate={45} {...rest} />
    default:
      return null
  }
}

export default HydricStressTrendIcon
