function SvgEtp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 24 24' {...props}>
      <path
        d='M12 2.16l-2.23 4.1h1.459a3.43 3.43 0 00.29 2.42c.167.353.328.663.481.93.6 1.12.8 1.499.32 2.359-1.15 1.9-1.79 3.191-.9 5.101l.33.66c.6 1.19.96 1.9.1 3.14a.61.61 0 00.15.86.62.62 0 00.32.11.63.63 0 00.55-.27c1.3-1.85.64-3.15 0-4.42-.1-.2-.21-.4-.31-.62-.55-1.18-.4-1.92.84-3.92.88-1.46.38-2.4-.25-3.59-.15-.27-.3-.551-.45-.881a2.21 2.21 0 01-.108-1.879h1.638L12 2.16zM7.76 5.35L5.53 9.44H7.04a2.58 2.58 0 00.29 1.23c.12.24.229.45.339.65.42.79.49.96.2 1.45-.81 1.33-1.29 2.31-.64 3.72l.23.46c.41.83.62 1.24.09 2a.62.62 0 00.15.87.59.59 0 00.36.11.63.63 0 00.51-.25 2.75 2.75 0 000-3.26l-.21-.43c-.35-.76-.25-1.18.57-2.55a2.33 2.33 0 00-.16-2.68c-.1-.18-.21-.38-.31-.6a1.31 1.31 0 01-.173-.72H9.99L7.76 5.35zm8.48 0l-2.23 4.09h1.62a2.59 2.59 0 00.29 1.29l.33.59c.43.79.5.96.2 1.45-.8 1.33-1.29 2.31-.63 3.72l.23.46c.41.83.62 1.24.09 2a.62.62 0 00.15.87.59.59 0 00.36.11.63.63 0 00.47-.26 2.75 2.75 0 000-3.26l-.21-.43c-.35-.76-.26-1.18.57-2.55a2.34 2.34 0 00-.16-2.68c-.1-.18-.21-.38-.32-.6a1.33 1.33 0 01-.137-.71h1.608l-2.23-4.09z'
        data-name='Calque 6'
      />
    </svg>
  )
}

export default SvgEtp
