import { SVGProps } from 'react'

const SvgPlotsFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M8.732 15.75a21.24 21.24 0 0 1-1.732-2H6a1 1 0 0 0-.895.553l-2.5 5A1.001 1.001 0 0 0 3.5 20.75h17a1 1 0 0 0 .894-1.447l-2.5-5A1 1 0 0 0 18 13.75h-1a21.246 21.246 0 0 1-1.732 2h2.114l1.5 3H5.118l1.5-3h2.114Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 17.25a.818.818 0 0 1-.516-.196c-1.673-1.534-2.922-2.958-3.747-4.271C6.912 11.469 6.5 10.24 6.5 9.1c0-1.784.553-3.205 1.659-4.263 1.105-1.058 2.386-1.587 3.84-1.587 1.456 0 2.736.53 3.842 1.587C16.947 5.895 17.5 7.317 17.5 9.1c0 1.141-.413 2.369-1.238 3.683-.825 1.313-2.074 2.737-3.747 4.27a.817.817 0 0 1-.515.197Zm0-7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z'
      fill='currentColor'
    />
  </svg>
)

export default SvgPlotsFilled
