import { useClient, useMutation } from '@weenat/client'
import { useIntl } from '@weenat/wintl'
import useToasts from 'app/src/hooks/useToasts'
import Button from 'app/src/kit/Button'
import SmartText from 'app/src/kit/SmartText'
import { useCallback } from 'react'
import { styled } from 'styled-components'

const Container = styled(Box)`
  text-align: center;
`

const AcceptTermsOfService: FC = () => {
  const { t } = useIntl()
  const client = useClient()
  const { addToast } = useToasts()

  const [acceptTermsOfService, acceptTermsOfServiceState] = useMutation(
    client.me.acceptTermsOfService(),
    {
      keysToInvalidate: [client.me.getMyProfile().key],
      onSuccess: () => {
        addToast(
          t(
            'help.terms_of_service_acceptance_success',
            {
              terms_of_service: t('models.account.fields.terms_of_service.label')
            },
            { capitalize: true }
          ),
          { appearance: 'success' }
        )
      }
    }
  )

  const acceptTOS = useCallback(() => {
    acceptTermsOfService()
  }, [acceptTermsOfService])

  return (
    <Container>
      <Flex
        height={'100%'}
        $p='lg'
        $gap='lg'
        $justifyContent={'center'}
        $flexDirection={'column'}
        $alignItems={'center'}
      >
        <SmartText
          tkey='help.terms_of_service_update_warning'
          vars={{
            gcu_url: t('weenat.help.termsAndConditions.generalConditionsOfUseUrl')
          }}
        />
        <Flex $justifyContent='center' $mt='lg'>
          <Button onPress={acceptTOS} state={acceptTermsOfServiceState}>
            {t('actions.accept')}
          </Button>
        </Flex>
      </Flex>
    </Container>
  )
}
export default AcceptTermsOfService
