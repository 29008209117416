import {
  FontFamilyTokens,
  FontSizeToken,
  FontWeightToken,
  LetterSpacingToken,
  LineHeightPalette,
  SpacingToken,
  WeenatColor,
  fromColorPathToColor
} from '@weenat/theme'
import isNil from 'lodash-es/isNil'
import React, { CSSProperties } from 'react'
import { css, styled } from 'styled-components'

export interface TextProps extends Pick<React.HTMLProps<HTMLParagraphElement>, 'children'> {
  className?: string
  $fontFamily?: FontFamilyTokens
  $fontSize?: FontSizeToken
  $fontWeight?: FontWeightToken
  $lineHeight?: keyof LineHeightPalette
  /** use LetterSpacing rather than Size */
  $letterSpacing?: LetterSpacingToken
  $color?: WeenatColor
  $mb?: SpacingToken
  $fontStyle?: CSSProperties['fontStyle']
  $ellipsis?: boolean
  $underlined?: boolean
  $lineThrough?: boolean
  $textAlign?: CSSProperties['textAlign']
  $flexed?: boolean
  $fontVariantNumeric?: CSSProperties['fontVariantNumeric']
}

const Text = styled.p.attrs((props) => ({
  $fontFamily: 'pm',
  $fontSize: 'rg',
  $lineHeight: 'rg',
  $letterSpacing: 'normal',
  $lineThrough: false,
  $underlined: false,
  $ellipsis: false,
  $fontWeight: 'light',
  $fontVariantNumeric: undefined,
  ...props
}))<TextProps>`
  font-family: ${({ $fontFamily, theme }) =>
    theme.typography.families[$fontFamily as FontFamilyTokens]};
  font-size: ${({ $fontSize, theme }) => theme.typography.sizes[$fontSize as FontSizeToken]}px;
  font-weight: ${({ $fontWeight, theme }) =>
    theme.typography.weights[$fontWeight as FontWeightToken]};
  line-height: ${({ $lineHeight, theme }) =>
    theme.typography.lineHeights[$lineHeight as keyof LineHeightPalette] ?? 'normal'};
  letter-spacing: ${({ $letterSpacing, theme }) =>
    theme.typography.letterSpacings[$letterSpacing as LetterSpacingToken]};
  color: ${({ $color, theme }) =>
    !isNil($color) ? fromColorPathToColor($color) : theme.colors.grayscale.black};
  white-space: pre-line;

  ${({ $flexed }) =>
    !isNil($flexed)
      ? css`
          flex: 1;
        `
      : ''}

  ${({ $fontStyle }) =>
    !isNil($fontStyle)
      ? css`
          font-style: ${$fontStyle};
        `
      : ''}

  ${({ $underlined }) =>
    $underlined
      ? css`
          text-decoration: underline;
        `
      : ''}

  ${({ $lineThrough }) =>
    $lineThrough
      ? css`
          text-decoration: line-through;
        `
      : ''}

  ${({ $ellipsis }) =>
    $ellipsis
      ? css`
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        `
      : ''}

  ${({ $textAlign }) =>
    $textAlign
      ? css`
          text-align: ${$textAlign};
        `
      : ''}

  ${({ $fontVariantNumeric }) =>
    !isNil($fontVariantNumeric)
      ? css`
          font-variant-numeric: ${$fontVariantNumeric};
        `
      : ''}

  ${({ $mb, theme }) =>
    !isNil($mb)
      ? css`
          margin-bottom: ${theme.spacings[$mb]}px;
        `
      : ''}
      
  min-height: auto;
`

export default Text
