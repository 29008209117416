import { useClient, useMutation } from '@weenat/client'
import { OrgRole } from '@weenat/client/dist/enums'
import { Org } from '@weenat/client/dist/resources/orgs'
import { Id } from '@weenat/client/dist/resources/types'
import { useIntl } from '@weenat/wintl'
import Modal from 'app/src/kit/Modal'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import { useCallback, useState } from 'react'
import Button from '../kit/Button'
import SelectOrganization from '../kit/SelectOrganization'

const AcceptNetworkInvitationModal: FC<{
  invitationId: Id
  close: () => void
  onSuccess?: () => void
}> = ({ onSuccess, close, invitationId }) => {
  const client = useClient()
  const { t } = useIntl()

  const [hasNoOrganizations, setHasNoOrganizations] = useState(false)
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<Id | null>(null)

  const selectorPredicate = useCallback((org: Org) => isNil(org.networkType), [])

  const [acceptInvitation, acceptInvitationRequest] = useMutation(
    client.invitations.received.accept(invitationId),
    {
      onSuccess
    }
  )

  return (
    <Modal
      isOpen
      disableCloseOnBackgroundClick
      title={t(`models.invitation.misc.select_org_to_join_network_with`)}
      close={close}
    >
      {hasNoOrganizations ? (
        <Text>{t('models.invitation.misc.must_be_owner_to_accept_invite')}</Text>
      ) : (
        <Flex $flexDirection='column' $alignItems='center'>
          <SelectOrganization
            queryParams={{ myRole: OrgRole.owner }}
            selectorPredicate={selectorPredicate}
            onNoOrganizationsFound={() => setHasNoOrganizations(true)}
            onChange={setSelectedOrganizationId}
          />
          <Button
            isError={acceptInvitationRequest.isError}
            isSuccess={acceptInvitationRequest.isSuccess}
            onPress={() => acceptInvitation({ organizationId: selectedOrganizationId as Id })}
            isLoading={acceptInvitationRequest.isPending}
            isDisabled={isNil(selectedOrganizationId)}
          >
            {t('actions.confirm')}
          </Button>
        </Flex>
      )}
    </Modal>
  )
}

export default AcceptNetworkInvitationModal
