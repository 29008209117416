import { useClient, useQuery } from '@weenat/client'
import { useHasPaymentIncident, useRestrictedPlan } from '@weenat/client/dist/core/billing'
import { PaymentStepId, usePaymentSteps } from '@weenat/client/dist/core/payment'
import { BillingStatus, PlanName } from '@weenat/client/dist/enums'
import { Href } from '@weenat/client/dist/routx/runtime-core'
import * as zodSchemas from '@weenat/client/dist/zod-schemas'
import { useIntl } from '@weenat/wintl'
import { useOrgContext } from 'app/orgProvider'
import { Head, useNavigate, useSearchParams } from 'app/routx-router'
import ErrorScreen from 'app/src/errors/ErrorScreen'
import {
  ContactDetailsLine,
  CustomerSupportContactDetails
} from 'app/src/help/components/CustomerSupportSection'
import useDisclosure from 'app/src/hooks/useDisclosure'
import Button from 'app/src/kit/Button'
import Icons from 'app/src/kit/Icons'
import LockedFeat from 'app/src/kit/LockedFeat'
import Modal from 'app/src/kit/Modal'
import { ControlledModalOverlay } from 'app/src/kit/ModalOverlay'
import Text from 'app/src/kit/Text'
import FullScreenStepper from 'app/src/kit/fullscreens/FullScreenStepper'
import LoadingCircle from 'app/src/kit/loaders/LoadingCircle'
import ExternalRedirect from 'app/src/plans/ExternalRedirect'
import SetupBillingAccount from 'app/src/plans/SetupBillingAccount'
import SubscriptionCard from 'app/src/plans/SubscriptionCard'
import { usePaymentCtx, useSelectedOrgs } from 'app/state'
import isNil from 'lodash-es/isNil'
import { ReactNode } from 'react'
import { styled } from 'styled-components'

const VISIBLE_SUBSCRIPTIONS: PlanName[] = ['plus', 'expert']

const FullScreenContent = styled(Flex)`
  flex-direction: column;
  flex: 1;

  margin: 0 auto;

  width: 100%;
  max-width: 1024px;
`

const UpgradeToPremium = () => {
  const { t } = useIntl()
  const nav = useNavigate()
  const client = useClient()
  const { org } = useOrgContext()
  const { currentOrgId } = useOrgContext()
  const [selectedOrgs] = useSelectedOrgs()
  const [, setPaymentContext] = usePaymentCtx()

  const [{ selected_subscription, step, origin, from }, setSearchParams] = useSearchParams(
    zodSchemas.upgradeToPremiumQueryParams
  )

  const { steps, titlePerSteps } = usePaymentSteps(step)

  const {
    isOpen: isContactSupportModalOpen,
    open: openContactSupportModal,
    close: closeContactSupportModal
  } = useDisclosure(false)

  const disabledSubscription = useRestrictedPlan(currentOrgId ?? undefined)
  const { hasPaymentIncident } = useHasPaymentIncident({
    enabled: !isNil(org),
    orgId: org?.id as number
  })
  const isDowngradeToFreemium = org?.billingStatus === BillingStatus.essential && hasPaymentIncident

  const { close, isOpen } = useDisclosure(isDowngradeToFreemium)

  const subscriptionOrder = useQuery(
    client.billing.getSubscriptionOrder(
      currentOrgId as number,
      selected_subscription === 'expert' ? BillingStatus.expert : BillingStatus.plus
    ),
    {
      enabled:
        !isNil(currentOrgId) && !isNil(selected_subscription) && step === 'billing_information'
    }
  )

  const goToSetupBillingAccountStep = (subscription: PlanName) => {
    // set search params
    setSearchParams({ selected_subscription: subscription, step: 'billing_information' })
  }

  const contentPerSteps: Record<PaymentStepId, ReactNode> = {
    choose_subscription: (
      <Flex $gap='lg' $justifyContent='center' $alignItems='self-start' $p='xl'>
        {VISIBLE_SUBSCRIPTIONS.map((sub) => (
          <SubscriptionCard
            key={sub}
            plan={sub}
            onPress={() => {
              return goToSetupBillingAccountStep(sub)
            }}
            isDisabled={disabledSubscription === sub}
          />
        ))}
      </Flex>
    ),
    billing_information: (
      <SetupBillingAccount
        org={org}
        plan={selected_subscription}
        onSuccess={() => {
          setPaymentContext({
            from: from ?? 'subscription',
            selectedOrgs
          })
          setSearchParams({
            selected_subscription,
            step: 'redirect_to_payment'
          })
        }}
      />
    ),
    redirect_to_payment: (
      <Flex $flexDirection='column' $alignItems='center' $justifyContent='center' $p='lg' $gap='md'>
        <LoadingCircle size='lg' />
        <Text $textAlign='center'>
          {t('ecommerce.plans.journey.redirect_to_payment_explanation')}
        </Text>
        {/* TODO: Input correct URL from back-end */}
        {subscriptionOrder.data && (
          <ExternalRedirect href={subscriptionOrder.data.paymentLink} delay={3000} />
        )}
      </Flex>
    )
  }

  const displayedSteps = steps.map((stepName) => ({
    label: titlePerSteps[stepName],
    id: stepName,
    onPress: () => {
      setSearchParams({ selected_subscription, step: stepName })
    }
  }))

  const currentStepIndex = displayedSteps.findIndex((stp) => stp.id === step)
  const currentStep = currentStepIndex !== -1 ? displayedSteps[currentStepIndex] : null

  return (
    <>
      <Head title={t('ecommerce.plans.discover')} />
      {!isNil(currentStep) ? (
        <>
          <FullScreenStepper
            steps={displayedSteps}
            currentStep={currentStep}
            onClose={() => {
              nav(origin as Href)
            }}
          >
            {!isDowngradeToFreemium ? (
              <FullScreenContent>
                <Flex
                  $justifyContent={currentStepIndex !== 0 ? 'space-between' : 'flex-end'}
                  $alignItems='center'
                  $width='100%'
                  $p='lg'
                >
                  {currentStepIndex !== 0 ? (
                    <Button
                      IconLeft={Icons.ArrowBack}
                      onPress={() => {
                        nav(-1)
                      }}
                      backgroundColor={'grayscale.100'}
                      color={'grayscale.black'}
                    >
                      {t('actions.go_back', { capitalize: true })}
                    </Button>
                  ) : null}
                  <Button IconLeft={Icons.SupportAgent} onPress={() => openContactSupportModal()}>
                    {t('help.need_help', { capitalize: true })}
                  </Button>
                </Flex>
                <div>{contentPerSteps[step]}</div>
              </FullScreenContent>
            ) : (
              <ControlledModalOverlay
                isOpen={isOpen || isDowngradeToFreemium}
                close={() => {
                  close()
                  nav(-1)
                }}
              >
                <LockedFeat
                  body='ecommerce.plans.change_plan_restricted_message'
                  title='ecommerce.plans.change_plan_restricted_title'
                >
                  <Flex $mt='lg' width='100%' $alignItems='center' $justifyContent='center'>
                    <Button
                      rightAdornment={<Icons.ArrowRight $color={'grayscale.white'} $size='md' />}
                      onPress={(e) => {
                        nav(`/administration/${org?.id}/billing`)
                        e.stopPropagation()
                      }}
                    >
                      {t('ecommerce.plans.change_plan_restricted_cta')}
                    </Button>
                  </Flex>
                </LockedFeat>
              </ControlledModalOverlay>
            )}
          </FullScreenStepper>
          {isContactSupportModalOpen ? (
            <Modal
              width={512}
              title={t('help.support', { capitalize: true })}
              isOpen={isContactSupportModalOpen}
              close={closeContactSupportModal}
            >
              <CustomerSupportContactDetails />
              <ContactDetailsLine Icon={Icons.Offices}>
                <Text>{t('help.legal_notice_address_2')}</Text>
              </ContactDetailsLine>
            </Modal>
          ) : null}
        </>
      ) : (
        <ErrorScreen status={404} />
      )}
    </>
  )
}

export const Component = UpgradeToPremium
export default UpgradeToPremium

export const settings = {
  search: zodSchemas.upgradeToPremiumQueryParams
}
