import { MyProfile } from '@weenat/client/dist/resources/me'
import { availableLanguages, defaultLanguage } from '@weenat/wintl'
import intersection from 'lodash-es/intersection'

function getBrowserLanguagesSource() {
  const source =
    // Browser ordered preferred languages
    window.navigator.languages ??
    // Language of Windows Regional Options
    window.navigator.userLanguage ??
    // Windows language
    window.navigator.systemLanguage
  // {user,system}Language are not arrays
  return (
    (Array.isArray(source) ? source : [source])
      // Shorten strings to use two chars ('en-US' -> 'en')
      .map((x) => x.substr(0, 2))
  )
}

const detectBrowserLanguage = () => {
  const browserLanguagesSource = getBrowserLanguagesSource()
  const [firstCompatibleBrowserLanguage] = intersection<MyProfile['language']>(
    browserLanguagesSource,
    availableLanguages
  )
  return firstCompatibleBrowserLanguage || defaultLanguage
}

export default detectBrowserLanguage
