import Text from 'app/src/kit/Text'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import isNumber from 'lodash-es/isNumber'
import isString from 'lodash-es/isString'
import { transparentize } from 'polished'
import { ReactNode } from 'react'
import { styled, useTheme } from 'styled-components'
import LoadingList from './LoadingList'
import TextField, { TextFieldProps } from './fields/TextField'

interface DescriptionListItem {
  key?: string | number
  label?: string
  value: number | string | ReactNode
  fieldName?: string
  CustomField?: React.FunctionComponent<TextFieldProps>
}

type CellProps = {
  isBottomRounded?: boolean
  isModifying?: boolean
} & Pick<DescriptionListItem, 'fieldName' | 'CustomField' | 'label' | 'value'>

const CellContainer = styled(Flex)<Pick<CellProps, 'isBottomRounded'>>`
  padding: 16px;
  justify-content: space-between;
  ${(p) =>
    p.isBottomRounded &&
    `
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    `}
`
const CellsContainer = styled(Box)`
  ${CellContainer}:first-child {
    border-top: 1px solid ${(p) => p.theme.colors.grayscale[300]};
  }

  ${CellContainer}:nth-child(2n + 1) {
    background-color: ${(p) => p.theme.colors.grayscale[100]};
  }
`

const CellField: FC<Pick<DescriptionListItem, 'fieldName' | 'label' | 'CustomField'>> = ({
  label,
  fieldName: name,
  CustomField
}) => {
  const Field = CustomField || TextField
  const fieldProps = { label, name, autoFocus: true }
  return (
    <Flex $flexDirection={'row'} $flex={1} $alignItems='center'>
      <Field {...fieldProps} name={name as string} />
    </Flex>
  )
}

const Cell: FC<CellProps> = ({
  isBottomRounded,
  label,
  value,
  isModifying,
  fieldName,
  CustomField
}) => {
  const { colors } = useTheme()

  return (
    <CellContainer isBottomRounded={isBottomRounded}>
      {!isNil(fieldName) && isModifying ? (
        <CellField fieldName={fieldName} CustomField={CustomField} label={label} />
      ) : (
        <Flex $flexDirection='row' $justifyContent='space-between' $flex={1}>
          <Box $mb='sm'>
            <Text $fontWeight='bold' $color={transparentize(0.25, colors.grayscale.black)}>
              {label}
            </Text>
          </Box>
          {isString(value) || isNumber(value) ? <Text>{value}</Text> : value}
        </Flex>
      )}
    </CellContainer>
  )
}

interface DescriptionListProps {
  isLoading?: boolean
  title?: ReactNode
  items: DescriptionListItem[]
  isModifying?: boolean
}

const DescriptionList: FC<DescriptionListProps> = ({
  items,
  title,
  isLoading = false,
  isModifying = false
}) => {
  return (
    <Box $width='100%'>
      {!isNil(title) ? (
        <Box $p='lg'>{isString(title) ? <Text $fontWeight='bold'>{title}</Text> : title}</Box>
      ) : null}
      {isLoading ? (
        <LoadingList size={6} variant='single-line' uniqueKey='DescriptionList' />
      ) : !isEmpty(items) ? (
        <CellsContainer>
          {items.map((it) => (
            <Cell
              fieldName={it.fieldName}
              CustomField={it.CustomField}
              key={isNil(it.key) ? it.label : it.key}
              label={it.label}
              value={it.value}
              isModifying={isModifying}
            />
          ))}
        </CellsContainer>
      ) : null}
    </Box>
  )
}

export default DescriptionList
