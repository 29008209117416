import { useIntl } from '@weenat/wintl'
import { useNavigate } from 'app/routx-router'
import Text from 'app/src/kit/Text'
import logEvent from 'app/utils/analytics'
import isNil from 'lodash-es/isNil'
import { ReactNode } from 'react'
import { styled, useTheme } from 'styled-components'
import Button from './Button'
import CloseButton from './CloseButton'
import Icons from './Icons'
import Link from './LinkComponent'
import Modal from './Modal'

const IllustrationContainer = styled(Flex)`
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex: 1;
  background-color: ${(p) => p.theme.colors.primary[500]};

  svg {
    width: 100%;
    height: 100%;
  }
`

const CTAHolder = styled(Flex)`
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap-reverse;
  gap: 16px;
  margin: 32px 0 8px;
`

const CloseBtnContainer = styled(Box)`
  position: absolute;
  top: 16px;
  right: 16px;
`

const CONTENT_CONTAINER_PROPS = {
  style: {
    height: 'calc(60vh - 64px)',
    display: 'flex',
    flexDirection: 'column',
    padding: 0
  }
}

const HEADER_PROPS = { style: { display: 'none' } }

interface CTA {
  label?: string
  to?: string
  onPress?: () => void
}

interface CTAFullScreenProps {
  title: string
  description?: string
  Illustration?: ReactNode
  mainCTA?: CTA
  secondaryCTA?: CTA
  isOpen: boolean
  onClose: () => void
}

const CTAFullScreen: FC<CTAFullScreenProps> = ({
  title,
  onClose,
  description,
  Illustration,
  isOpen,
  mainCTA,
  secondaryCTA
}) => {
  const { t } = useIntl()
  const { colors } = useTheme()
  const nav = useNavigate()

  const surfaceColor = 'primary.500'
  const textColor = 'grayscale.black'

  const logDiscoverMenuEvent = (to?: string) => {
    logEvent('discover_menu_cta', { type: 'fullscreen', destination: !isNil(to) ? to : 'none' })
  }

  const handleMainCTAPress = () => {
    if (!isNil(mainCTA)) {
      logDiscoverMenuEvent(mainCTA.to)
      if (!isNil(mainCTA.to)) nav(mainCTA.to, { replace: true })
      mainCTA.onPress?.()
    }
  }

  const modalStyle = { borderColor: colors.primary[800] }

  return isOpen ? (
    <Modal
      title={undefined}
      hasCloseIcon={false}
      isOpen={isOpen}
      width={640}
      height={640}
      $p={0}
      contentContainerProps={CONTENT_CONTAINER_PROPS}
      headerProps={HEADER_PROPS}
      style={modalStyle}
    >
      {!isNil(Illustration) ? (
        <IllustrationContainer>
          {Illustration}
          <CloseBtnContainer>
            <CloseButton onPress={onClose} />
          </CloseBtnContainer>
        </IllustrationContainer>
      ) : null}
      <Box $p='lg'>
        <Text $fontSize='md' $fontWeight='medium' $lineHeight='lg' $color={textColor}>
          {title}
        </Text>
        {!isNil(description) ? (
          <Text $fontSize='rg' $lineHeight='md' $color={textColor}>
            {description}
          </Text>
        ) : null}
        <CTAHolder>
          {!isNil(secondaryCTA) ? (
            <Link
              $underlined
              replace
              to={secondaryCTA.to}
              $color={surfaceColor}
              onPress={() => {
                logDiscoverMenuEvent(secondaryCTA.to)
                secondaryCTA.onPress?.()
              }}
            >
              {!isNil(secondaryCTA.label) ? secondaryCTA.label : t('actions.learn_more')}
            </Link>
          ) : null}
          {!isNil(mainCTA) ? (
            <Button
              color={colors.grayscale.white}
              backgroundColor={surfaceColor}
              onPress={handleMainCTAPress}
              IconRight={Icons.ArrowRight}
            >
              {!isNil(mainCTA.label) ? mainCTA.label : t('actions.learn_more')}
            </Button>
          ) : null}
        </CTAHolder>
      </Box>
    </Modal>
  ) : null
}

export default CTAFullScreen
