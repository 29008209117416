import { useIntl } from '@weenat/wintl'
import SmartText from '../SmartText'
import CheckboxField from './CheckboxField'
import { CustomFieldProps } from './TextField'

const HasAcceptedTermsOfServiceField: FC<CustomFieldProps> = ({
  name = 'hasAcceptedTermsOfService',
  ...props
}) => {
  const { t } = useIntl()

  return (
    <CheckboxField
      name={name}
      label={
        <SmartText
          tkey='actions.accept_tos_and_privacy_agreement'
          vars={{
            gcu_url: t('weenat.help.termsAndConditions.generalConditionsOfUseUrl'),
            privacy_policy_url: t('weenat.help.termsAndConditions.privacy_policy')
          }}
        />
      }
      {...props}
    />
  )
}

export default HasAcceptedTermsOfServiceField
