import { Alert } from '@weenat/client/dist/resources/alerts.types'
import isEmpty from 'lodash-es/isEmpty'
import AlertCreationStep, { AlertCreationStepProps } from './AlertCreationStep'
import PredicateTable from './PredicateTable'

type MultiplePredicatesStepProps = AlertCreationStepProps<Alert['predicates']>

interface ValidateMultiplePredicatesProps extends MultiplePredicatesStepProps {
  initialPredicates: Alert['predicates']
  onPressAddPredicate: (currentPredicates: Alert['predicates']) => void
  onPressDeletePredicate: (idx: number) => void
  onPressEditPredicate: (idx: number) => void
}

const ValidateMultiplePredicates: FC<ValidateMultiplePredicatesProps> = ({
  initialPredicates,
  onPressAddPredicate,
  onPressEditPredicate,
  onPressDeletePredicate,
  onPressNext,
  ...props
}) => {
  return (
    <AlertCreationStep
      {...props}
      canProgressToNext={!isEmpty(initialPredicates)}
      onPressNext={() => onPressNext?.(initialPredicates)}
      previousKey={'actions.restart'}
    >
      <PredicateTable
        predicates={initialPredicates}
        onPredicateAddition={onPressAddPredicate}
        onPredicateEdition={onPressEditPredicate}
        onPredicateDeletion={onPressDeletePredicate}
      />
    </AlertCreationStep>
  )
}

export default ValidateMultiplePredicates
