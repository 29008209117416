import { useIntl } from '@weenat/wintl'
import { useTheme } from 'styled-components'
import { IconProps } from './Icon'
import Icons from './Icons'
import TextTooltip from './tooltips/TextTooltip'

const CloseButton: FC<IconProps> = ({ ml = 3, mx, my, mr, mt, mb, ...props }) => {
  const { colors } = useTheme()
  const { t } = useIntl()

  return (
    <Box $mx={mx} $my={my} $mt={mt} $mb={mb} $mr={mr} $ml={ml}>
      <TextTooltip content={t('actions.close', { capitalize: true })}>
        <Icons.Close $size='lg' $p='md' $backgroundColor={colors.grayscale[100]} {...props} />
      </TextTooltip>
    </Box>
  )
}

export default CloseButton
