import isNil from 'lodash-es/isNil'
import { styled } from 'styled-components'
import mapImage from '../../images/map.jpg'

const MapBackgroundImage = styled.div<{ $zIndex?: number }>`
  z-index: ${(p) => (isNil(p.$zIndex) ? -1 : p.$zIndex)};
  position: fixed;
  inset: 0;
  background: url(${mapImage}) no-repeat center center fixed;
  background-size: cover;
`

export default MapBackgroundImage
