import { useIntl } from '@weenat/wintl'
import TextField, { CustomFieldProps } from './TextField'

const FirstNameField: FC<CustomFieldProps> = ({ name = 'firstName', ...props }) => {
  const { t } = useIntl()

  return (
    <TextField
      name={name}
      label={t('models.account.fields.first_name.label', { capitalize: true })}
      inputType='firstName'
      {...props}
    />
  )
}

export default FirstNameField
