import { Client } from '@weenat/client'
import { getCurrentOrgId, getLoginAs, getToken, getUserLanguage } from 'app/state'
import isNil from 'lodash-es/isNil'

const optionalHeader = (key: string, value?: string | null | number) =>
  !isNil(value) ? { [key]: `${value}` } : {}

const client = Client(() => {
  const loginAs = getLoginAs()
  const language = getUserLanguage()
  const targetOrganizationId = getCurrentOrgId()
  const token = getToken()
  const apiHost = `${import.meta.env.VITE_API_HOST}/api`
  const smagCsrfToken = document.cookie
    .split(';')
    .find((item) => item.trim().startsWith('smag_csrf_token='))
    ?.replace('smag_csrf_token=', '')
  return {
    protocol: import.meta.env.VITE_API_PROTOCOL as 'https',
    host: apiHost,
    hosts: {
      api: apiHost,
      tiles: import.meta.env.VITE_TILES_API_HOST,
      dss: import.meta.env.VITE_DSS_API_HOST,
      ts: import.meta.env.VITE_TS_API_HOST
    },
    headers: {
      // Important to omit the whole authorization header if we have no token otherwise the backend will not decode the token
      ...optionalHeader('authorization', !isNil(token) ? `JWT ${token}` : undefined),
      ...optionalHeader('x-weenat-login-as', loginAs?.email),
      ...optionalHeader('x-weenat-login-as-uid', loginAs?.id),
      ...optionalHeader('x-weenat-target-org', targetOrganizationId),
      ...optionalHeader('accept-language', language),
      ...optionalHeader('X-CSRF-TOKEN', smagCsrfToken)
    }
  }
})

export default client
