import Card, { CardProps } from '../kit/Card'

const MapMediumContainer = (props: CardProps) => {
  return (
    <Card
      $position='relative'
      $flex={1}
      $height={400}
      width='100%'
      $p={0}
      $overflow='hidden'
      {...props}
    />
  )
}

export default MapMediumContainer
