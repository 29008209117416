import { extractValueFromSummaryData } from '@weenat/client/dist/core/measurements'
import { Aggregations } from '@weenat/client/dist/enums'
import { PlotSummary } from '@weenat/client/dist/resources/measurements.type'
import { WeenatColor } from '@weenat/theme'
import { useIntl } from '@weenat/wintl'
import UpdateInformation from 'app/src/kit/UpdateInformation'
import MetricIcons from 'app/src/kit/icons/MetricIcons'
import { useUniverseAggregate } from 'app/state'
import isNil from 'lodash-es/isNil'
import { memo } from 'react'
import { styled } from 'styled-components'
import PlotListItemValue, { PlotSummaryValue } from './PlotListItemValue'

const FrostTemperatureValueContainer = styled(Flex)`
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
`

const StyledPlotListItemValue = styled(PlotListItemValue)`
  padding: 0;
`

interface FrostMetricValueProps {
  metric: 'T_WET' | 'T_DRY'
  value: PlotSummaryValue
}

const FrostMetricValue = memo<FrostMetricValueProps>(({ metric, value }) => {
  const { t } = useIntl()

  const Icn = MetricIcons[metric]
  const color: WeenatColor = `metrics.${metric}.500` as const

  const isDryTemp = metric === 'T_DRY'

  return (
    <FrostTemperatureValueContainer>
      {!isNil(Icn) ? (
        <Icn $size='lg' $mr='md' $p='md' $backgroundColor={color} $color={'grayscale.white'} />
      ) : null}
      <StyledPlotListItemValue
        metric={metric}
        name={t(`map.universes.frost.${isDryTemp ? 'dry' : 'wet'}_temperature_abbr`)}
        value={value}
        isMainValue
      />
    </FrostTemperatureValueContainer>
  )
})

const MyUpdateInformation = styled(UpdateInformation)`
  justify-content: center;
  margin-top: 8px;
`

interface FrostPlotListItemValuesProps {
  /** Plot summary data */
  summaryData: PlotSummary['data']
  provider?: string
}

/** Carousel values for Frost */
const FrostPlotListItemValues: FC<FrostPlotListItemValuesProps> = ({ summaryData, provider }) => {
  const [universeAggregate] = useUniverseAggregate()
  let wetTemperature: number | null = null
  let dryTemperature: number | null = null
  let timestamp: string | null = null
  if (universeAggregate != null) {
    const { value, timestamp: extractedTimestamp } = extractValueFromSummaryData({
      data: summaryData,
      metricId: 'T_WET',
      aggregate: Aggregations.lastValues,
      universeAggregate
    })
    wetTemperature = value
    timestamp = extractedTimestamp

    dryTemperature = extractValueFromSummaryData({
      data: summaryData,
      metricId: 'T_DRY',
      aggregate: Aggregations.lastValues,
      universeAggregate
    })?.value
  }

  return (
    <Box $width='100%'>
      <Flex $width='100%' $gap='md'>
        <FrostMetricValue metric='T_WET' value={wetTemperature} />
        <FrostMetricValue metric='T_DRY' value={dryTemperature} />
      </Flex>
      {!isNil(timestamp) && (
        <MyUpdateInformation timestamp={parseInt(timestamp, 10)} name={provider} />
      )}
    </Box>
  )
}

export default memo(FrostPlotListItemValues)
