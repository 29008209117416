import Text from 'app/src/kit/Text'
import { useTheme } from 'styled-components'
import Badge from './Badge'

const CountBadge: FC<{ total: number; count?: number }> = ({ total, count }) => {
  const { colors } = useTheme()

  return (
    <Badge $px='md' $py='xs' $backgroundColor='primary.500'>
      <Text
        $fontSize='sm'
        $fontWeight='bold'
        $color={colors.grayscale.white}
      >{`${count} / ${total}`}</Text>
    </Badge>
  )
}

export default CountBadge
