import {
  BackgroundMapUniverseMetric,
  univAggToAggregation,
  univAggToTimeStep,
  UniverseAgg
} from '@weenat/client/dist/core/universes'
import { useCurrentUnivMetric, useUniverseAggregate } from 'app/state'

/**
 * @return the focused metric & aggregate & span
 */
const useUniverseBackgroundMapContext = () => {
  const [universeAggregate] = useUniverseAggregate()
  const currentUnivMetric = useCurrentUnivMetric()

  const univFocusedMetricId: BackgroundMapUniverseMetric | undefined =
    universeAggregate === 'real-time-FXY'
      ? 'FXY'
      : universeAggregate === 'real-time-DD'
        ? 'DD'
        : universeAggregate === 'real-time-SSI_MAX'
          ? 'SSI_MAX'
          : universeAggregate === 'real-time-SSI_MIN'
            ? 'SSI_MIN'
            : universeAggregate === 'real-time-PPFD_MAX'
              ? 'PPFD_MAX'
              : universeAggregate === 'real-time-PPFD_MIN'
                ? 'PPFD_MIN'
                : universeAggregate === 'cumul-measured-12H' ||
                    universeAggregate === 'cumul-measured-4H'
                  ? 'LW_D'
                  : currentUnivMetric

  const focusedMetricId = univFocusedMetricId
  const aggregate = univAggToAggregation[universeAggregate as UniverseAgg]
  const span = univAggToTimeStep[universeAggregate as UniverseAgg]

  return {
    span,
    focusedMetricId,
    aggregate,
    universeAggregate
  }
}

export default useUniverseBackgroundMapContext
