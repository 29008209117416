function SvgSolarFlares(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 24 24' {...props}>
      <path
        d='M17.925 8.44a.58.58 0 00.59-.59v-.1a.59.59 0 00-.59-.59h-2.3a3.74 3.74 0 00-.51-1.34v-.1l-.19-.22 2-2a.59.59 0 10-.83-.83l-2 2-.17-.02-.35-.19-.43-.17h-.08a1.09 1.09 0 00-.24-.07h-.22V2a.59.59 0 00-.59-.58.58.58 0 00-.59.58v2.16h-.23a1.09 1.09 0 00-.27.11v.03a2.73 2.73 0 00-.43.16l-.36.19-.09.06-2-2a.6.6 0 00-.84 0 .59.59 0 000 .83l2 2a1.45 1.45 0 00-.28.19 1.33 1.33 0 000 .12 4.58 4.58 0 00-.35.77 2.76 2.76 0 00-.13.29l-.06.27h-2.31a.59.59 0 00-.59.59v.1a.58.58 0 00.59.59h2.3v.07a3.42 3.42 0 00.55 1.21.59.59 0 000 .17l.18.23-2 2a.61.61 0 00-.16.48v6.39h-1.32l1.94 3.57 1.95-3.57h-1.37v-6.27l1.84-1.83a4.29 4.29 0 00.89.43h.08l.25.07h.2v7.6h-1.34l1.94 3.57 1.92-3.57h-1.32v-7.56h.16l.27-.07h.08a3.67 3.67 0 00.91-.47l1.83 1.83v6.27h-1.36l1.94 3.57 1.94-3.57h-1.36v-6.46a.58.58 0 00-.16-.48l-1.93-1.96c.06-.08.12-.15.17-.23s0-.09 0-.09a3.69 3.69 0 00.52-1.35zm-5.92 1.58a2.22 2.22 0 112.22-2.21 2.21 2.21 0 01-2.22 2.21z'
        data-name='Calque 6'
      />
    </svg>
  )
}

export default SvgSolarFlares
