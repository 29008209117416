import { Source, TargetsWithTZ } from '@weenat/client/dist/resources/alerts.types'
import { useIntl } from '@weenat/wintl'
import Button from 'app/src/kit/Button'
import Modal, { ModalProps } from 'app/src/kit/Modal'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import { useState } from 'react'
import { styled, useTheme } from 'styled-components'
import AlertTargetsSelector from './AlertTargetsSelector'

const ContentContainer = styled(Flex)`
  position: relative;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`

interface AddAlertTargetsModalProps extends ModalProps {
  alertName?: string
  initialTargets: TargetsWithTZ
  onConfirm: (newTargets: TargetsWithTZ) => void
  sources: Source[]
}

const AddAlertTargetsModal: FC<AddAlertTargetsModalProps> = ({
  alertName,
  close,
  initialTargets,
  isOpen,
  onConfirm,
  sources
}) => {
  const { t } = useIntl()
  const { colors } = useTheme()

  const [selectedTargets, setSelectedTargets] = useState<TargetsWithTZ>(initialTargets)

  return (
    <Modal
      isOpen={isOpen}
      disableCloseOnBackgroundClick
      width={812}
      height={812}
      title={
        <Box>
          <Text $fontSize='lg' $fontWeight='bold'>
            {t('alerts.add_more_targets', {
              capitalize: true
            })}
          </Text>
          {!isNil(alertName) && (
            <Text $fontSize='sm' $color={colors.grayscale[300]}>
              {alertName}
            </Text>
          )}
        </Box>
      }
      close={close}
    >
      <ContentContainer>
        <Box $height='90%'>
          <AlertTargetsSelector
            initialTargets={selectedTargets}
            onTargetsChange={setSelectedTargets}
            sources={sources}
          />
        </Box>

        <Flex
          $height='10%'
          $alignItems='center'
          $justifyContent='center'
          $backgroundColor={colors.grayscale.white}
        >
          <Button
            onPress={() => {
              onConfirm(selectedTargets)
              close?.()
            }}
          >
            {t('actions.confirm')}
          </Button>
        </Flex>
      </ContentContainer>
    </Modal>
  )
}

export default AddAlertTargetsModal
