import { Href } from '@weenat/client/dist/routx/runtime-core'
import { useNavigate, useSearchParams } from 'app/routx-router'
import { useSelectedOrgs } from 'app/state'
import isNil from 'lodash-es/isNil'
import { useEffect } from 'react'
import { z } from 'zod'

export const settings = {
  search: z.object({
    network_id: z.coerce.number(),
    organization_id: z.coerce.number(),
    redirect: z.string()
  })
}

const PushNotification = () => {
  const [, setSelectedOrgs] = useSelectedOrgs()
  const nav = useNavigate()
  const [{ network_id, organization_id, redirect }] = useSearchParams(settings.search)

  useEffect(() => {
    if (!isNil(network_id)) {
      setSelectedOrgs({
        organizationId: parseInt(network_id, 10),
        childOrganizationId: !isNil(organization_id) ? parseInt(organization_id, 10) : null
      })
    } else if (!isNil(organization_id)) {
      setSelectedOrgs({
        organizationId: parseInt(organization_id, 10),
        childOrganizationId: null
      })
    }

    if (!isNil(redirect)) {
      nav(redirect as Href)
    }
  }, [])

  return null
}

export const Component = PushNotification
export default PushNotification
