import { useOrgContext } from 'app/orgProvider'
import { Redirect } from 'app/routx-router'

const Index = () => {
  const { currentOrgId } = useOrgContext()

  return <Redirect to={`/administration/${currentOrgId}`} replace />
}

export const Component = Index
export default Index
