import { Summary } from '@weenat/client/dist/resources/measurements.type'
import { useHandleScreenQueryParamChange } from 'app/src/hooks/useScreenQueryParam'
import Pop from 'app/src/kit/Pop'
import isNil from 'lodash-es/isNil'
import UniverseAggregate from './UniverseAggregate'
import UniverseMetricSelector from './UniverseMetricSelector'

const NewActionsControls: FC<{ summaries: Summary[] }> = ({ summaries }) => {
  const handleScreenQueryParamChange = useHandleScreenQueryParamChange()
  const firstSummaryWithIrriData = summaries.find(
    (s) => !isNil(s.data) && ('U_CAPA_Li' in s.data || 'HPOT_Li' in s.data)
  )?.metadata
  const plotId =
    !isNil(firstSummaryWithIrriData) && 'plot' in firstSummaryWithIrriData
      ? firstSummaryWithIrriData.plot.id
      : undefined

  return (
    <Flex $alignItems='center' $justifyContent='center' $flexWrap='wrap' $gap='lg'>
      <UniverseMetricSelector summaries={summaries} />
      <Pop
        tourName='discover_irrigation'
        stepName='track_change'
        placement='top'
        onNext={() => {
          handleScreenQueryParamChange('summary', () => true, `/plots/${plotId}/summary`)
        }}
      >
        <UniverseAggregate />
      </Pop>
    </Flex>
  )
}

export default NewActionsControls
