import { UseQueryResult } from '@tanstack/react-query'
import { useClient, useQuery } from '@weenat/client'
import { PossibleError } from '@weenat/client/dist/errors'
import { Org } from '@weenat/client/dist/resources/orgs'
import { useSelectedOrgs, useToken } from 'app/state'
import isNil from 'lodash-es/isNil'
import { ReactNode, createContext, useContext, useMemo } from 'react'

interface IOrgContext {
  org: Org | undefined
  orgRequest: UseQueryResult<Org, PossibleError>
  currentOrgId: number | undefined
}

const OrgContext = createContext<IOrgContext | null>(null)

export function useOrgContext() {
  const context = useContext<IOrgContext | null>(OrgContext)

  if (!context) {
    throw new Error(
      'You may have forgot to render <OrgContextProvider /> because OrgContext is not defined'
    )
  }
  return context
}

export function OrgContextProvider({ children }: { children: ReactNode }) {
  const client = useClient()
  const [selectedOrgs] = useSelectedOrgs()
  const [token] = useToken()

  const orgId = isNil(selectedOrgs.childOrganizationId)
    ? selectedOrgs.organizationId
    : selectedOrgs.childOrganizationId

  const requestOption = { enabled: !isNil(orgId) && !isNil(token) }

  const getOrg = client.orgs.get(orgId)
  const orgRequest = useQuery(getOrg, requestOption)
  const org = orgRequest.data

  const value = useMemo(() => {
    return { org, orgRequest, currentOrgId: org?.id }
  }, [org, orgRequest])

  return <OrgContext.Provider value={value}>{children}</OrgContext.Provider>
}
