import useRestrictedMetrics from '@weenat/client/dist/core/orgs/useRestrictedMetrics'
import { BillingStatus } from '@weenat/client/dist/enums'
import { Device } from '@weenat/client/dist/resources/devices'
import { Horizon } from '@weenat/client/dist/resources/horizons'
import { useIntl } from '@weenat/wintl'
import useDisclosure from 'app/src/hooks/useDisclosure'
import Button from 'app/src/kit/Button'
import Icons from 'app/src/kit/Icons'
import Modal from 'app/src/kit/Modal'
import Text from 'app/src/kit/Text'
import { ChipGroupPrimitive } from 'app/src/kit/fields/ChipGroupField'
import {
  Dropdown,
  DropdownContent,
  DropdownTrigger
} from 'app/src/kit/fields/Select/SelectDropdown'
import TextTooltip from 'app/src/kit/tooltips/TextTooltip'
import intersection from 'lodash-es/intersection'
import isEmpty from 'lodash-es/isEmpty'

interface AddOrRemoveDeviceButtonProps {
  showHorizons: boolean
  onAdd?: (deviceId: number, depth?: number | undefined, horizonId?: number | undefined) => void
  onRemove?: (deviceId: number) => void
  device: Device
  billingStatus: BillingStatus
  isSelected?: boolean
  variant?: 'iconOnly' | 'iconText'
  isDisabled?: boolean
  horizons: Horizon[]
  depth?: string
}

const AddOrRemoveDeviceButton: FC<AddOrRemoveDeviceButtonProps> = ({
  device,
  onAdd,
  showHorizons,
  billingStatus,
  isSelected,
  onRemove,
  variant = 'iconText',
  isDisabled = false,
  horizons,
  depth
}) => {
  const { t } = useIntl()

  const restrictedModal = useDisclosure()
  const horizonDropdown = useDisclosure()

  const deviceName = `${device.endUserName.join(' ')}`

  const restrictedMetrics = useRestrictedMetrics(billingStatus)
  const isRestricted = !isEmpty(intersection(device.availableMeasures, restrictedMetrics))

  const handleAdd = () => (isRestricted ? restrictedModal.open() : onAdd?.(device.id))
  const handleRemove = () => onRemove?.(device.id)

  const hasDepth = !isEmpty(depth)

  const horizonOptions = horizons.map((h) => ({ label: h.value.toString(), value: h.id }))

  return (
    <>
      {variant === 'iconText' ? (
        <>
          {isSelected ? (
            <Button IconLeft={Icons.MinusSign} onPress={handleRemove}>
              {t('models.plot.creation.source_step_delete_cta')}
            </Button>
          ) : (
            <Button
              IconLeft={isRestricted ? Icons.Lock : Icons.PlusSignCircle}
              onPress={handleAdd}
              isDisabled={isDisabled}
            >
              {isRestricted ? (
                <Text $ellipsis $color={'grayscale.white'}>
                  {t('models.plot.creation.devices_step_restricted_device')}
                </Text>
              ) : (
                t('models.plot.creation.source_step_add_cta')
              )}
            </Button>
          )}
        </>
      ) : (
        <>
          <TextTooltip
            content={
              isSelected
                ? t('models.plot.creation.source_step_delete_cta')
                : t('models.plot.creation.source_step_add_cta')
            }
          >
            {isSelected ? (
              <Flex
                $cursor='pointer'
                $alignItems='center'
                $backgroundColor={'feedback.success.500'}
                $borderRadius={8}
                $pl={hasDepth ? 'md' : undefined}
                onClick={(e) => {
                  e.stopPropagation()
                  handleRemove()
                }}
              >
                {hasDepth && (
                  <Text $color={'grayscale.white'} $fontWeight='medium'>
                    {depth} cm
                  </Text>
                )}
                <Icons.CheckCircle
                  $size='rg'
                  $p='sm'
                  $backgroundColor={'feedback.success.500'}
                  $color={'grayscale.white'}
                />
              </Flex>
            ) : (
              <>
                {showHorizons ? (
                  <Dropdown
                    isVisible={showHorizons && horizonDropdown.isOpen}
                    onVisibleChange={horizonDropdown.toggle}
                  >
                    <DropdownTrigger>
                      <Icons.PlusSign
                        $size='rg'
                        $p='sm'
                        $backgroundColor={'grayscale.black'}
                        $color={'grayscale.white'}
                        onPress={horizonDropdown.open}
                      />
                    </DropdownTrigger>
                    <DropdownContent width={300}>
                      <Box $p='lg'>
                        <ChipGroupPrimitive
                          label={t('models.device.actions.add_horizon')}
                          isMonoChoice
                          options={horizonOptions}
                          value={
                            hasDepth
                              ? horizonOptions.find((opt) => opt.label === depth)?.value
                              : undefined
                          }
                          onChange={(newValue: number) => {
                            const selectedOption = horizonOptions.find(
                              (opt) => opt.value === newValue
                            )
                            if (selectedOption) {
                              onAdd?.(device.id, parseInt(selectedOption.label, 10), newValue)
                              horizonDropdown.close()
                            }
                          }}
                        />
                      </Box>
                    </DropdownContent>
                  </Dropdown>
                ) : (
                  <Icons.PlusSign
                    $size='rg'
                    $p='sm'
                    $backgroundColor={'grayscale.black'}
                    $color={'grayscale.white'}
                    onPress={handleAdd}
                  />
                )}
              </>
            )}
          </TextTooltip>
        </>
      )}

      <Modal title={deviceName} isOpen={restrictedModal.isOpen} close={restrictedModal.close}>
        <Text>{t('error_codes.605')}</Text>
      </Modal>
    </>
  )
}

export default AddOrRemoveDeviceButton
