import isNil from 'lodash-es/isNil'
import useGoBack from '../hooks/useGoBack'
import { IconProps } from './Icon'
import Icons from './Icons'

interface GoBackButtonProps {
  backgroundColor?: IconProps['$backgroundColor']
  color?: IconProps['$color']
  mr?: IconProps['$mr']
  onPress?: IconProps['onPress']
}

const GoBackButton: FC<GoBackButtonProps> = ({ backgroundColor, color, mr, onPress }) => {
  const { goBack } = useGoBack()

  return (
    <Icons.ArrowBack
      $size='lg'
      $mr={mr}
      $p='md'
      $color={color}
      $backgroundColor={backgroundColor}
      onPress={!isNil(onPress) ? onPress : goBack}
    />
  )
}

export default GoBackButton
