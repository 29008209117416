import * as zodSchemas from '@weenat/client/dist/zod-schemas'
import { useIntl } from '@weenat/wintl'
import { useNavigate, useSearchParams } from 'app/routx-router'
import Button from 'app/src/kit/Button'
import SmartText from 'app/src/kit/SmartText'
import Text from 'app/src/kit/Text'
import OnboardingCard from 'app/src/onboardings/OnboardingCard'
import OnboardingNavigationHeader from 'app/src/onboardings/OnboardingNavigationHeader'
import { z } from 'zod'

export const settings = {
  search: z.object({ invite: zodSchemas.emailInvitation.optional() })
}

const flexedButtonStyle = { flex: 1 }

const OnboardingSensors = () => {
  const { t } = useIntl()
  const nav = useNavigate()
  const [{ invite }] = useSearchParams(settings.search)

  return (
    <Flex $flexDirection='column' $gap='lg'>
      <OnboardingNavigationHeader />
      <OnboardingCard>
        <Text $fontWeight='bold' $fontSize='md' $textAlign='center'>
          {t('onboardings.do_you_own_sensors')}
        </Text>
        <SmartText tkey={'onboardings.tips_to_add_sensor'} $textAlign='center' />
        <Flex $width='100%' $alignItems='center' $gap='lg'>
          <Button
            importance='sd'
            onPress={() => {
              nav('/onboarding/networks', { search: { invite } })
            }}
            style={flexedButtonStyle}
          >
            {t('actions.answer_no', { capitalize: true })}
          </Button>
          <Button
            onPress={() => {
              nav('/onboarding/sensors/add', { search: { invite } })
            }}
            style={flexedButtonStyle}
          >
            {t('actions.answer_yes', { capitalize: true })}
          </Button>
        </Flex>
      </OnboardingCard>
    </Flex>
  )
}

export default OnboardingSensors
