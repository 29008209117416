// Generated code. DO NOT EDIT WILL BE OVERRIDE.
import * as z from 'zod'
import { pathParamsParsers } from '@weenat/client/dist/routx/runtime-core'

declare module '@weenat/client/dist/routx/runtime-core' {
  interface PathParams {
    orgId: number
    deviceId: number
    memberId: number
    invitationId: number
    networkOrgId: number
    membershipRequestId: number
    optionCode: string
    plotId: number
    stationId: number
    alertId: string
    loginMagicToken: string
    resetPasswordUid: string
    resetPasswordToken: string
    verifyEmailKey: string
    sectionSlug: string
    articleSlug: string
    invitationBase64: string
    networkId: number
    thresholdId: number
  }

  type GoBackHref = -1 | -2
  type RelativeHref =
    | 'accept-pp'
    | 'accept-tos'
    | 'actura'
    | 'administration'
    | 'billing'
    | 'history'
    | 'my-subscription-detail'
    | 'next'
    | 'devices'
    | 'events'
    | 'linked-plots'
    | 'add'
    | 'agro-weather'
    | 'irrigation'
    | 'edit'
    | 'headquarter'
    | 'info'
    | 'members'
    | 'invite'
    | 'members-invitations'
    | 'my-farm'
    | 'network'
    | 'invitations'
    | 'upgrade'
    | 'members-trials'
    | 'membership-requests'
    | 'option-requests'
    | 'options'
    | 'plots-activations'
    | 'settings'
    | 'stations-activations'
    | 'org-plots'
    | 'plots'
    | 'option-activations'
    | 'stations'
    | 'organizations'
    | 'upgrade-to-premium'
    | 'alerts'
    | 'create'
    | 'auth'
    | 'login'
    | 'magic'
    | 'confirm'
    | 'register'
    | 'reset-password'
    | 'send-verification-email'
    | 'verify-email'
    | 'discover'
    | 'export'
    | 'help'
    | 'me'
    | 'account'
    | 'cluster-settings'
    | 'contact-information'
    | 'debug'
    | 'external-connections'
    | 'feature-toggle'
    | 'accept'
    | 'mobile-devices'
    | 'preferences'
    | 'notifications'
    | 'profile'
    | 'networks'
    | 'onboarding'
    | 'members-tips'
    | 'find'
    | 'sensors'
    | 'success'
    | 'welcome'
    | 'payment'
    | 'failed'
    | 'forecasts'
    | 'irrelis'
    | 'summary'
    | 'crop-tracking'
    | 'decitrait'
    | 'sanitary-state-notes'
    | 'treatments'
    | 'reports'
    | 'thresholds'
    | 'maizy'
    | 'tavmills'
    | 'water-balance'
    | 'water-balance-arvalis'
    | 'create-plot'
    | 'water-balance-ri'
    | 'weephyt'
    | 'push-notification'
    | 'smag'
    | 'unsubscribe-emails'
    | 'weather-map'
  type AbsoluteHref =
    | `/accept-pp`
    | `/accept-tos`
    | `/actura`
    | `/administration`
    | `/administration/${number}/billing/history`
    | `/administration/${number}/billing`
    | `/administration/${number}/billing/my-subscription-detail`
    | `/administration/${number}/billing/next`
    | `/administration/${number}/devices/${number}/events`
    | `/administration/${number}/devices/${number}`
    | `/administration/${number}/devices/${number}/linked-plots`
    | `/administration/${number}/devices/add`
    | `/administration/${number}/devices/agro-weather`
    | `/administration/${number}/devices`
    | `/administration/${number}/devices/irrigation`
    | `/administration/${number}/edit/headquarter`
    | `/administration/${number}/edit`
    | `/administration/${number}`
    | `/administration/${number}/info`
    | `/administration/${number}/members/${number}`
    | `/administration/${number}/members`
    | `/administration/${number}/members/invite`
    | `/administration/${number}/members-invitations/${number}`
    | `/administration/${number}/members-invitations`
    | `/administration/${number}/my-farm`
    | `/administration/${number}/network`
    | `/administration/${number}/network/invitations/${number}`
    | `/administration/${number}/network/invitations`
    | `/administration/${number}/network/invite`
    | `/administration/${number}/network/members/${number}`
    | `/administration/${number}/network/members/${number}/upgrade`
    | `/administration/${number}/network/members`
    | `/administration/${number}/network/members-trials/${number}`
    | `/administration/${number}/network/members-trials`
    | `/administration/${number}/network/membership-requests/${number}`
    | `/administration/${number}/network/membership-requests`
    | `/administration/${number}/network/option-requests/${number}`
    | `/administration/${number}/network/option-requests`
    | `/administration/${number}/options/${string}`
    | `/administration/${number}/options/${string}/plots-activations`
    | `/administration/${number}/options/${string}/settings`
    | `/administration/${number}/options/${string}/stations-activations`
    | `/administration/${number}/options`
    | `/administration/${number}/org-plots`
    | `/administration/${number}/org-plots/${number}`
    | `/administration/${number}/plots/${number}/devices`
    | `/administration/${number}/plots/${number}`
    | `/administration/${number}/plots/${number}/option-activations`
    | `/administration/${number}/plots`
    | `/administration/${number}/stations/${number}`
    | `/administration/${number}/stations/${number}/option-activations`
    | `/administration/${number}/stations`
    | `/administration/organizations`
    | `/administration/organizations/${string}`
    | `/administration/plots`
    | `/administration/upgrade-to-premium`
    | `/alerts/${string}`
    | `/alerts/create`
    | `/alerts`
    | `/auth`
    | `/auth/login`
    | `/auth/login/magic/confirm`
    | `/auth/login/magic/confirm/${string}`
    | `/auth/login/magic`
    | `/auth/register`
    | `/auth/reset-password`
    | `/auth/reset-password/${string}`
    | `/auth/reset-password/${string}/${string}`
    | `/auth/send-verification-email`
    | `/auth/verify-email`
    | `/auth/verify-email/${string}`
    | `/discover/${string}`
    | `/discover/${string}/${string}`
    | `/discover`
    | `/export`
    | `/help`
    | `/`
    | `/me/account`
    | `/me/cluster-settings`
    | `/me/contact-information`
    | `/me/debug`
    | `/me/external-connections`
    | `/me/feature-toggle`
    | `/me`
    | `/me/invitations/accept/${string}`
    | `/me/invitations/accept`
    | `/me/invitations`
    | `/me/mobile-devices`
    | `/me/preferences`
    | `/me/preferences/notifications`
    | `/me/profile`
    | `/networks/${number}`
    | `/networks`
    | `/onboarding`
    | `/onboarding/members-tips`
    | `/onboarding/my-farm`
    | `/onboarding/networks`
    | `/onboarding/networks/${number}`
    | `/onboarding/networks/find`
    | `/onboarding/sensors`
    | `/onboarding/sensors/add`
    | `/onboarding/sensors/success`
    | `/onboarding/welcome`
    | `/payment`
    | `/payment/failed`
    | `/payment/success`
    | `/plots/${number}/alerts`
    | `/plots/${number}/forecasts`
    | `/plots/${number}/history`
    | `/plots/${number}`
    | `/plots/${number}/irrelis`
    | `/plots/${number}/irrelis/settings`
    | `/plots/${number}/summary/crop-tracking`
    | `/plots/${number}/summary/decitrait`
    | `/plots/${number}/summary/decitrait/sanitary-state-notes`
    | `/plots/${number}/summary/decitrait/settings`
    | `/plots/${number}/summary/decitrait/treatments/add`
    | `/plots/${number}/summary/decitrait/treatments`
    | `/plots/${number}/summary`
    | `/plots/${number}/summary/irrigation`
    | `/plots/${number}/summary/irrigation/reports`
    | `/plots/${number}/summary/irrigation/settings/thresholds/${number}`
    | `/plots/${number}/summary/irrigation/settings/thresholds/create`
    | `/plots/${number}/summary/irrigation/settings/thresholds`
    | `/plots/${number}/summary/maizy`
    | `/plots/${number}/summary/tavmills`
    | `/plots/${number}/summary/water-balance`
    | `/plots/${number}/summary/water-balance/reports`
    | `/plots/${number}/summary/water-balance/settings`
    | `/plots/${number}/summary/water-balance-arvalis/create-plot`
    | `/plots/${number}/summary/water-balance-arvalis`
    | `/plots/${number}/summary/water-balance-arvalis/reports`
    | `/plots/${number}/summary/water-balance-arvalis/settings`
    | `/plots/${number}/summary/water-balance-ri`
    | `/plots/${number}/summary/water-balance-ri/reports`
    | `/plots/${number}/summary/water-balance-ri/settings`
    | `/plots/${number}/summary/weephyt`
    | `/plots/create`
    | `/plots/edit`
    | `/push-notification`
    | `/smag`
    | `/stations/${number}/alerts`
    | `/stations/${number}/forecasts`
    | `/stations/${number}/history`
    | `/stations/${number}`
    | `/stations/${number}/summary`
    | `/stations/${number}/summary/weephyt`
    | `/stations`
    | `/unsubscribe-emails`
    | `/weather-map/${number}/alerts`
    | `/weather-map/${number}/forecasts`
    | `/weather-map/${number}/history`
    | `/weather-map/${number}`
    | `/weather-map/${number}/summary`
    | `/${string}`
  type PathPattern =
    | '/accept-pp'
    | '/accept-tos'
    | '/actura'
    | '/administration'
    | '/administration/*'
    | '/administration/*/billing/history'
    | '/administration/*/billing'
    | '/administration/*/billing/*'
    | '/administration/*/billing/my-subscription-detail'
    | '/administration/*/billing/next'
    | '/administration/*/devices/*/events'
    | '/administration/*/devices/*'
    | '/administration/*/devices/*/*'
    | '/administration/*/devices/*/linked-plots'
    | '/administration/*/devices/add'
    | '/administration/*/devices/agro-weather'
    | '/administration/*/devices'
    | '/administration/*/devices/irrigation'
    | '/administration/*/edit/headquarter'
    | '/administration/*/edit'
    | '/administration/*/edit/*'
    | '/administration/*/*'
    | '/administration/*/info'
    | '/administration/*/members/*'
    | '/administration/*/members/*/*'
    | '/administration/*/members'
    | '/administration/*/members/invite'
    | '/administration/*/members-invitations/*'
    | '/administration/*/members-invitations/*/*'
    | '/administration/*/members-invitations'
    | '/administration/*/my-farm'
    | '/administration/*/network'
    | '/administration/*/network/*'
    | '/administration/*/network/invitations/*'
    | '/administration/*/network/invitations/*/*'
    | '/administration/*/network/invitations'
    | '/administration/*/network/invite'
    | '/administration/*/network/members/*'
    | '/administration/*/network/members/*/*'
    | '/administration/*/network/members/*/upgrade'
    | '/administration/*/network/members'
    | '/administration/*/network/members-trials/*'
    | '/administration/*/network/members-trials/*/*'
    | '/administration/*/network/members-trials'
    | '/administration/*/network/membership-requests/*'
    | '/administration/*/network/membership-requests/*/*'
    | '/administration/*/network/membership-requests'
    | '/administration/*/network/option-requests/*'
    | '/administration/*/network/option-requests/*/*'
    | '/administration/*/network/option-requests'
    | '/administration/*/options/*'
    | '/administration/*/options/*/*'
    | '/administration/*/options/*/plots-activations'
    | '/administration/*/options/*/settings'
    | '/administration/*/options/*/stations-activations'
    | '/administration/*/options'
    | '/administration/*/org-plots'
    | '/administration/*/org-plots/*'
    | '/administration/*/plots/*/devices'
    | '/administration/*/plots/*/devices/*'
    | '/administration/*/plots/*'
    | '/administration/*/plots/*/*'
    | '/administration/*/plots/*/option-activations'
    | '/administration/*/plots'
    | '/administration/*/stations/*'
    | '/administration/*/stations/*/*'
    | '/administration/*/stations/*/option-activations'
    | '/administration/*/stations'
    | '/administration/organizations'
    | '/administration/organizations/*'
    | '/administration/plots'
    | '/administration/plots/*'
    | '/administration/upgrade-to-premium'
    | '/alerts/*'
    | '/alerts/create'
    | '/alerts'
    | '/auth'
    | '/auth/*'
    | '/auth/login'
    | '/auth/login/*'
    | '/auth/login/magic/confirm'
    | '/auth/login/magic/confirm/*'
    | '/auth/login/magic'
    | '/auth/login/magic/*'
    | '/auth/register'
    | '/auth/reset-password'
    | '/auth/reset-password/*'
    | '/auth/reset-password/*/*'
    | '/auth/send-verification-email'
    | '/auth/verify-email'
    | '/auth/verify-email/*'
    | '/discover/*'
    | '/discover/*/*'
    | '/discover'
    | '/export'
    | '/help'
    | '/help/*'
    | '/'
    | '/me/account'
    | '/me/cluster-settings'
    | '/me/contact-information'
    | '/me/debug'
    | '/me/external-connections'
    | '/me/feature-toggle'
    | '/me'
    | '/me/*'
    | '/me/invitations/accept/*'
    | '/me/invitations/accept'
    | '/me/invitations'
    | '/me/invitations/*'
    | '/me/mobile-devices'
    | '/me/preferences'
    | '/me/preferences/*'
    | '/me/preferences/notifications'
    | '/me/profile'
    | '/networks/*'
    | '/networks/*/*'
    | '/networks'
    | '/onboarding'
    | '/onboarding/*'
    | '/onboarding/members-tips'
    | '/onboarding/my-farm'
    | '/onboarding/networks'
    | '/onboarding/networks/*'
    | '/onboarding/networks/*/*'
    | '/onboarding/networks/find'
    | '/onboarding/sensors'
    | '/onboarding/sensors/*'
    | '/onboarding/sensors/add'
    | '/onboarding/sensors/success'
    | '/onboarding/welcome'
    | '/payment'
    | '/payment/*'
    | '/payment/failed'
    | '/payment/success'
    | '/plots/*/alerts'
    | '/plots/*/forecasts'
    | '/plots/*/history'
    | '/plots/*'
    | '/plots/*/*'
    | '/plots/*/irrelis'
    | '/plots/*/irrelis/*'
    | '/plots/*/irrelis/settings'
    | '/plots/*/summary/crop-tracking'
    | '/plots/*/summary/decitrait'
    | '/plots/*/summary/decitrait/*'
    | '/plots/*/summary/decitrait/sanitary-state-notes'
    | '/plots/*/summary/decitrait/settings'
    | '/plots/*/summary/decitrait/treatments/add'
    | '/plots/*/summary/decitrait/treatments'
    | '/plots/*/summary/decitrait/treatments/*'
    | '/plots/*/summary'
    | '/plots/*/summary/*'
    | '/plots/*/summary/irrigation'
    | '/plots/*/summary/irrigation/*'
    | '/plots/*/summary/irrigation/reports'
    | '/plots/*/summary/irrigation/settings/thresholds/*'
    | '/plots/*/summary/irrigation/settings/thresholds/*/*'
    | '/plots/*/summary/irrigation/settings/thresholds/create'
    | '/plots/*/summary/irrigation/settings/thresholds'
    | '/plots/*/summary/maizy'
    | '/plots/*/summary/tavmills'
    | '/plots/*/summary/water-balance'
    | '/plots/*/summary/water-balance/*'
    | '/plots/*/summary/water-balance/reports'
    | '/plots/*/summary/water-balance/settings'
    | '/plots/*/summary/water-balance-arvalis/create-plot'
    | '/plots/*/summary/water-balance-arvalis'
    | '/plots/*/summary/water-balance-arvalis/*'
    | '/plots/*/summary/water-balance-arvalis/reports'
    | '/plots/*/summary/water-balance-arvalis/settings'
    | '/plots/*/summary/water-balance-ri'
    | '/plots/*/summary/water-balance-ri/*'
    | '/plots/*/summary/water-balance-ri/reports'
    | '/plots/*/summary/water-balance-ri/settings'
    | '/plots/*/summary/weephyt'
    | '/plots/create'
    | '/plots/edit'
    | '/push-notification'
    | '/smag'
    | '/stations/*/alerts'
    | '/stations/*/forecasts'
    | '/stations/*/history'
    | '/stations/*'
    | '/stations/*/*'
    | '/stations/*/summary'
    | '/stations/*/summary/*'
    | '/stations/*/summary/weephyt'
    | '/stations'
    | '/unsubscribe-emails'
    | '/weather-map/*/alerts'
    | '/weather-map/*/forecasts'
    | '/weather-map/*/history'
    | '/weather-map/*'
    | '/weather-map/*/*'
    | '/weather-map/*/summary'
    | '/*'
  type Href = GoBackHref | AbsoluteHref | RelativeHref | '.'
  type SearchParamsByAbsoluteHref = {
    [H in `/actura`]: z.infer<typeof import('./routes/actura').settings.search>
  } & {
    [H in `/administration/upgrade-to-premium`]: z.infer<
      typeof import('./routes/administration/upgrade-to-premium').settings.search
    >
  } & {
    [H in `/auth/login`]: z.infer<typeof import('./routes/auth/login/index').settings.search>
  } & {
    [H in `/auth/login/magic`]: z.infer<
      typeof import('./routes/auth/login/magic/index').settings.search
    >
  } & {
    [H in `/auth/register`]: z.infer<typeof import('./routes/auth/register').settings.search>
  } & {
    [H in `/auth/reset-password`]: z.infer<
      typeof import('./routes/auth/reset-password/index').settings.search
    >
  } & {
    [H in `/auth/send-verification-email`]: z.infer<
      typeof import('./routes/auth/send-verification-email').settings.search
    >
  } & { [H in `/export`]: z.infer<typeof import('./routes/export').settings.search> } & {
    [H in `/me/debug`]: z.infer<typeof import('./routes/me/debug').settings.search>
  } & {
    [H in `/onboarding/members-tips`]: z.infer<
      typeof import('./routes/onboarding/members-tips').settings.search
    >
  } & {
    [H in `/onboarding/my-farm`]: z.infer<
      typeof import('./routes/onboarding/my-farm').settings.search
    >
  } & {
    [H in `/onboarding/networks`]: z.infer<
      typeof import('./routes/onboarding/networks/index').settings.search
    >
  } & {
    [H in `/onboarding/sensors`]: z.infer<
      typeof import('./routes/onboarding/sensors/index').settings.search
    >
  } & {
    [H in `/onboarding/sensors/success`]: z.infer<
      typeof import('./routes/onboarding/sensors/success').settings.search
    >
  } & {
    [H in `/onboarding/welcome`]: z.infer<
      typeof import('./routes/onboarding/welcome').settings.search
    >
  } & {
    [H in `/plots/${number}/forecasts`]: z.infer<
      typeof import('./routes/plots/[plotId]/forecasts').settings.search
    >
  } & {
    [H in `/plots/${number}/summary/irrigation/settings/thresholds/create`]: z.infer<
      typeof import('./routes/plots/[plotId]/summary/irrigation/settings/thresholds/create').settings.search
    >
  } & {
    [H in `/push-notification`]: z.infer<
      typeof import('./routes/push-notification').settings.search
    >
  } & { [H in `/smag`]: z.infer<typeof import('./routes/smag').settings.search> } & {
    [H in `/stations/${number}/forecasts`]: z.infer<
      typeof import('./routes/stations/[stationId]/forecasts').settings.search
    >
  } & {
    [H in `/unsubscribe-emails`]: z.infer<
      typeof import('./routes/unsubscribe-emails').settings.search
    >
  } & {
    [H in `/weather-map/${number}/forecasts`]: z.infer<
      typeof import('./routes/weather-map/[deviceId]/forecasts').settings.search
    >
  }

  export interface GeneratedTypes {
    PathParams: PathParams
    Href: Href
    SearchParamsByAbsoluteHref: SearchParamsByAbsoluteHref
    AbsoluteHref: AbsoluteHref
    PathPattern: PathPattern
  }
}

export const paramsParserMap = {
  orgId: pathParamsParsers.number,
  deviceId: pathParamsParsers.number,
  memberId: pathParamsParsers.number,
  invitationId: pathParamsParsers.number,
  networkOrgId: pathParamsParsers.number,
  membershipRequestId: pathParamsParsers.number,
  optionCode: pathParamsParsers.string,
  plotId: pathParamsParsers.number,
  stationId: pathParamsParsers.number,
  '*': pathParamsParsers.string,
  alertId: pathParamsParsers.string,
  loginMagicToken: pathParamsParsers.string,
  resetPasswordUid: pathParamsParsers.string,
  resetPasswordToken: pathParamsParsers.string,
  verifyEmailKey: pathParamsParsers.string,
  sectionSlug: pathParamsParsers.string,
  articleSlug: pathParamsParsers.string,
  invitationBase64: pathParamsParsers.string,
  networkId: pathParamsParsers.number,
  thresholdId: pathParamsParsers.number
}
export const publicPathPatterns = {
  only: {
    '/actura': true,
    '/auth': true,
    '/auth/login': true,
    '/auth/login/magic/confirm': true,
    '/auth/login/magic/confirm/*': true,
    '/auth/login/magic': true,
    '/auth/register': true,
    '/auth/reset-password': true,
    '/auth/reset-password/*': true,
    '/auth/reset-password/*/*': true,
    '/smag': true
  },
  always: {
    '/auth/send-verification-email': true,
    '/auth/verify-email': true,
    '/auth/verify-email/*': true,
    '/discover/*': true,
    '/discover/*/*': true,
    '/discover': true,
    '/me/invitations/accept/*': true,
    '/networks/*': true,
    '/networks': true,
    '/unsubscribe-emails': true
  }
} as const
export const pathPatternResolver = {
  '': true,
  '*': true,
  me: {
    '*': true,
    debug: true,
    account: true,
    profile: true,
    invitations: { '*': true, accept: { '*': true } },
    preferences: { '*': true, notifications: true },
    'feature-toggle': true,
    'mobile-devices': true,
    'cluster-settings': true,
    'contact-information': true,
    'external-connections': true
  },
  auth: {
    '*': true,
    login: { '*': true, magic: { '*': true, confirm: { '*': true } } },
    register: true,
    'verify-email': { '*': true },
    'reset-password': { '*': { '*': true } },
    'send-verification-email': true
  },
  help: { '*': true },
  smag: true,
  actura: true,
  alerts: { '*': true, create: true },
  export: true,
  payment: { '*': true, failed: true, success: true },
  plots: {
    '*': {
      '*': true,
      alerts: true,
      history: true,
      irrelis: { '*': true, settings: true },
      summary: {
        '*': true,
        maizy: true,
        weephyt: true,
        tavmills: true,
        decitrait: {
          '*': true,
          settings: true,
          treatments: { '*': true, add: true },
          'sanitary-state-notes': true
        },
        irrigation: {
          '*': true,
          reports: true,
          settings: { thresholds: { '*': { '*': true }, create: true } }
        },
        'crop-tracking': true,
        'water-balance': { '*': true, reports: true, settings: true },
        'water-balance-ri': { '*': true, reports: true, settings: true },
        'water-balance-arvalis': { '*': true, reports: true, settings: true, 'create-plot': true }
      },
      forecasts: true
    },
    edit: true,
    create: true
  },
  discover: { '*': { '*': true } },
  networks: { '*': { '*': true } },
  stations: {
    '*': {
      '*': true,
      alerts: true,
      history: true,
      summary: { '*': true, weephyt: true },
      forecasts: true
    }
  },
  'accept-pp': true,
  'accept-tos': true,
  onboarding: {
    '*': true,
    'my-farm': true,
    sensors: { '*': true, add: true, success: true },
    welcome: true,
    networks: { '*': { '*': true }, find: true },
    'members-tips': true
  },
  'weather-map': {
    '*': { '*': true, alerts: true, history: true, summary: true, forecasts: true }
  },
  administration: {
    '*': {
      '*': true,
      edit: { '*': true, headquarter: true },
      info: true,
      plots: { '*': { '*': true, devices: { '*': true }, 'option-activations': true } },
      billing: { '*': true, next: true, history: true, 'my-subscription-detail': true },
      devices: {
        '*': { '*': true, events: true, 'linked-plots': true },
        add: true,
        irrigation: true,
        'agro-weather': true
      },
      members: { '*': { '*': true }, invite: true },
      'my-farm': true,
      network: {
        '*': true,
        invite: true,
        members: { '*': { '*': true, upgrade: true } },
        invitations: { '*': { '*': true } },
        'members-trials': { '*': { '*': true } },
        'option-requests': { '*': { '*': true } },
        'membership-requests': { '*': { '*': true } }
      },
      options: {
        '*': { '*': true, settings: true, 'plots-activations': true, 'stations-activations': true }
      },
      stations: { '*': { '*': true, 'option-activations': true } },
      'org-plots': { '*': true },
      'members-invitations': { '*': { '*': true } }
    },
    plots: { '*': true },
    organizations: { '*': true },
    'upgrade-to-premium': true
  },
  'push-notification': true,
  'unsubscribe-emails': true
} as const
