import { keyframes, styled } from 'styled-components'

const slideIn = keyframes`
  from { transform: translateY(50%); }
  to   { transform: translateY(0); }
`

const SlideIn = styled(Box)`
  animation: ${slideIn} 0.2s;
`

export default SlideIn
