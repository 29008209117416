import Text from 'app/src/kit/Text'
import { useField } from 'formik'
import noop from 'lodash-es/noop'
import { useCallback, ReactElement } from 'react'
import isNil from 'lodash-es/isNil'
import { styled, useTheme } from 'styled-components'
import FieldLabel, { FieldLabelProps } from './FieldLabel'
import ErrorMessage from '../ErrorMessage'

const CIRCLE_SIZE = 16
const INDICATOR_SIZE = 24
interface DotProps {
  $isChecked: boolean
}

const StyledDot = styled.span<{ $isChecked: boolean }>`
  border-radius: ${(p) => p.theme.radiuses.md}px;
  background-color: ${({ theme }) => theme.colors.primary[500]};
  width: ${(p) => (p.$isChecked ? CIRCLE_SIZE : 0)}px;
  height: ${(p) => (p.$isChecked ? CIRCLE_SIZE : 0)}px;
  transition:
    width 0.1s ease-out,
    height 0.1s ease-out;
`

/** Small dot that animate itself */
const Dot: FC<DotProps> = ({ $isChecked = false }) => <StyledDot $isChecked={$isChecked} />

interface RadioIndicatorProps extends DotProps {
  isDisabled?: boolean
}

const IndicatorCircle = styled(Flex)<{
  $isChecked?: boolean
  $isDisabled?: boolean
  $isInvalid?: boolean
}>`
  align-items: center;
  justify-content: center;
  width: ${INDICATOR_SIZE}px;
  height: ${INDICATOR_SIZE}px;
  border-radius: 12px;
  background-color: ${(p) =>
    p.$isDisabled ? p.theme.colors.grayscale[300] : p.theme.colors.grayscale.white};
  border: 1px solid
    ${(p) =>
      p.$isInvalid
        ? p.theme.colors.feedback.error['500']
        : p.$isChecked
          ? p.theme.colors.primary[500]
          : p.theme.colors.grayscale[300]};
  transition: border-color 0.2s ease-out;
  will-change: border-color;
`

/** Shows whether the option is active or not */
export const RadioIndicator: FC<RadioIndicatorProps> = ({
  $isChecked: isChecked = false,
  isDisabled = false,
  isInvalid = false
}) => {
  return (
    <IndicatorCircle $isDisabled={isDisabled} $isChecked={isChecked} $isInvalid={isInvalid}>
      <Dot $isChecked={isChecked} />
    </IndicatorCircle>
  )
}

const RadioPressable = styled.div<{ $isDisabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: ${INDICATOR_SIZE}px;
  margin: 4px 0 16px;

  ${(p) => (p.$isDisabled ? `opacity: 0.5;` : '')}

  &:hover {
    cursor: pointer;

    p {
      color: ${({ theme }) => theme.colors.primary[500]};
    }
  }

  &:hover ${IndicatorCircle} {
    border-color: ${({ theme }) => theme.colors.primary[500]};
  }

  transition: background-color 0.2s ease-out;
  will-change: background-color;
`

const TextInputErrorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 4px 0;
`
interface RadioProps extends RadioIndicatorProps {
  label: string
  onPress: () => void
}

const Radio: FC<RadioProps> = ({
  isDisabled = false,
  $isChecked = false,
  $isInvalid = false,
  label,
  onPress
}) => {
  const { colors } = useTheme()

  return (
    <RadioPressable $isDisabled={isDisabled} onClick={!isDisabled ? onPress : noop}>
      <Box $mr='md'>
        <RadioIndicator isDisabled={isDisabled} $isChecked={$isChecked} isInvalid={$isInvalid} />
      </Box>
      <Text $color={isDisabled ? colors.grayscale[300] : colors.grayscale.black}>{label}</Text>
    </RadioPressable>
  )
}

type PossibleValue = string | number | undefined

interface RadioOption {
  value: PossibleValue
  label: string
  isDisabled?: boolean
}

interface RadioGroupPrimitiveProps extends Omit<FieldLabelProps, 'isFieldRequired'> {
  value: PossibleValue
  options: RadioOption[]
  onChange: (value: PossibleValue) => void
  isRequired?: FieldLabelProps['isFieldRequired']
}

/** Primitive component to be used outside forms */
export const RadioGroupPrimitive: FC<RadioGroupPrimitiveProps> = ({
  label,
  options,
  value,
  onChange,
  isRequired = true,
  helpers,
  warning,
  hideErrors = false,
  displayedError
}) => {
  const handleChange = useCallback(
    (newValue: PossibleValue) => {
      onChange(newValue)
    },
    [onChange]
  )

  return (
    <>
      <Box $minHeight='auto'>
        <FieldLabel
          label={label}
          isFieldRequired={isRequired}
          helpers={helpers}
          warning={warning}
        />
        <Box $my='md'>
          {options.map((opt) => (
            <Radio
              key={opt.value}
              $isChecked={value === opt.value}
              $isInvalid={!isNil(displayedError)}
              label={opt.label}
              onPress={() => handleChange(opt.value)}
              isDisabled={opt.isDisabled}
            />
          ))}
        </Box>
      </Box>
      {hideErrors ? null : (
        <TextInputErrorsContainer>
          <ErrorMessage $error={displayedError}>{displayedError}&nbsp;</ErrorMessage>
        </TextInputErrorsContainer>
      )}
    </>
  )
}

type RadioGroupFieldProps = Omit<RadioGroupPrimitiveProps, 'value' | 'onChange'> & {
  name: string
  options: RadioOption[]
  displayedError?: ReactElement
}

const RadioGroupField: FC<RadioGroupFieldProps> = ({
  options,
  label,
  name,
  displayedError,
  ...rest
}) => {
  const [field, meta, helpers] = useField(name)

  /** Custom error take precedence */
  const finalError = !isNil(displayedError) ? displayedError : meta.error
  const displayedErr = meta.touched ? finalError : null

  return (
    <RadioGroupPrimitive
      value={field.value}
      onChange={(value) => {
        if (value !== field.value) {
          helpers.setValue(value)
        } else {
          helpers.setValue(undefined)
        }
      }}
      options={options}
      label={label}
      displayedError={displayedErr}
      {...rest}
    />
  )
}

export default RadioGroupField
