import { styled } from 'styled-components'

const DelimitedBox = styled(Box)<{ $isDelimitedOnTop?: boolean }>`
  ${(p) =>
    p.$isDelimitedOnTop
      ? `
          border-top: 1px solid ${p.theme.colors.grayscale[100]};
        `
      : `
          border-bottom: 1px solid ${p.theme.colors.grayscale[100]};
        `}
`

export default DelimitedBox
