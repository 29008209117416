import debounce from 'lodash-es/debounce'
import isNil from 'lodash-es/isNil'
import { MutableRefObject, useCallback, useLayoutEffect, useState } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

const getSize = (el?: HTMLElement) => {
  if (!el) {
    return {
      width: undefined,
      height: undefined
    }
  }

  return {
    width: el.offsetWidth,
    height: el.offsetHeight
  }
}

function useComponentSize(ref: MutableRefObject<HTMLElement>, debounceInterval = 0) {
  const [componentSize, setComponentSize] = useState(getSize(!isNil(ref) ? ref.current : undefined))

  const handleResize = useCallback(() => {
    if (!isNil(ref.current)) {
      setComponentSize(getSize(ref.current))
    }
  }, [ref])

  const debouncedHandleResize = debounce(handleResize, debounceInterval)

  useLayoutEffect(() => {
    if (!isNil(ref.current)) {
      debouncedHandleResize()

      const resizeObserver = new ResizeObserver(() => {
        debouncedHandleResize()
      })
      resizeObserver.observe(ref.current)

      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [ref.current])

  return componentSize
}

export default useComponentSize
