import { IconProps } from '../Icon'
import Icons from '../Icons'

interface WindDirectionIconProps extends Omit<IconProps, 'rotate'> {
  windOrigin: number
}

const WindDirectionIcon: React.FC<WindDirectionIconProps> = ({ windOrigin, ...rest }) => {
  return <Icons.Navigation $rotate={windOrigin + 180} {...rest} />
}

export default WindDirectionIcon
