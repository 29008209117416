import { createPortal } from 'react-dom'
import { styled } from 'styled-components'
import CloseButton from '../CloseButton'
import DelimitedFlex from '../DelimitedFlex'
import { LogoSphere } from '../icons/logos'
import FullScreenStepProgressIndicator, {
  FullScreenStepProgressIndicatorProps
} from './FullScreenStepProgressIndicator'
import FullScreenSurface from './FullScreenSurface'

const ChildrenContainer = styled(Flex)`
  flex: 1;

  height: 100%;
  width: 100%;

  padding: ${({ theme }) => theme.spacings.lg}px;
`

const Header = styled(DelimitedFlex)`
  align-items: center;

  width: 100%;

  gap: ${({ theme }) => theme.spacings.lg}px;
  padding: ${({ theme }) => theme.spacings.xl}px;

  background-color: ${({ theme }) => theme.colors.grayscale.white};
`

const StepsHolder = styled(Flex)`
  flex: 1;
  align-items: center;
  justify-content: center;
`

interface FullScreenStepperProps extends FullScreenStepProgressIndicatorProps {
  onClose?: () => void
}

/**
 * Common fullscreen container that displays steps
 * Mainly used on upgrade tunnel, and onboarding
 */
const FullScreenStepper: FC<FullScreenStepperProps> = ({
  children,
  steps,
  currentStep,
  onClose
}) => {
  return createPortal(
    <FullScreenSurface>
      <Flex $flexDirection='column' $flex={1}>
        {/** Header */}
        <Header>
          <LogoSphere width={48} height={48} />

          <StepsHolder>
            <FullScreenStepProgressIndicator steps={steps} currentStep={currentStep} />
          </StepsHolder>

          {onClose ? <CloseButton onPress={onClose} /> : null}
        </Header>
        {/** Content */}
        <ChildrenContainer>{children}</ChildrenContainer>
      </Flex>
    </FullScreenSurface>,
    document.body
  )
}

export default FullScreenStepper
