import { useIntl } from '@weenat/wintl'
import { Head } from 'app/routx-router'
import InvitationsSentToMeList from 'app/src/me/InvitationsSentToMeList'

const Invitations = () => {
  const { t } = useIntl()

  return (
    <>
      <Head title={t(`titles.received_invitations`)} />
      <InvitationsSentToMeList />
    </>
  )
}

export const Component = Invitations
export default Invitations
