import { schemas } from '@weenat/client'
import { Target, convertTargetsToTargetsWithTz } from '@weenat/client/dist/core/alerts'
import useGetAvailableAlertTargets from '@weenat/client/dist/core/alerts/useGetAvailableAlertTargets'
import {
  isDevice,
  isPlot as isPlotChecker
} from '@weenat/client/dist/core/utils/resourcesTypeCheckers'
import {
  Alert,
  Source,
  TargetKind,
  TargetsWithTZ,
  isTargetsWithTZ
} from '@weenat/client/dist/resources/alerts.types'
import { useIntl } from '@weenat/wintl'
import useDebounce from 'app/src/hooks/useDebounce'
import ListEmpty from 'app/src/kit/ListEmpty'
import LoadingList from 'app/src/kit/LoadingList'
import Pop from 'app/src/kit/Pop'
import Scrollable from 'app/src/kit/Scrollable'
import SelectChip from 'app/src/kit/SelectChip'
import SuperForm from 'app/src/kit/SuperForm'
import TextField from 'app/src/kit/fields/TextField'
import UseOnChange from 'app/src/kit/fields/UseOnChange'
import { isNil } from 'lodash-es'
import isEmpty from 'lodash-es/isEmpty'
import noop from 'lodash-es/noop'
import { useCallback, useState } from 'react'
import Icons from '../kit/Icons'
import PaginationControls from '../kit/PaginationControls'
import TextTooltip from '../kit/tooltips/TextTooltip'
import AlertTargetListItem from './AlertTargetListItem'

const ITEMS_PER_PAGE = 40

const TargetKindRadio: FC<{
  setTargetKind: (targetKind: TargetKind) => void
  targetKind: TargetKind
  shouldDisableDevice?: boolean
}> = ({ setTargetKind, targetKind, shouldDisableDevice = false }) => {
  const { t } = useIntl()

  const filters: { id: 'plot' | 'device' | 'station'; label: string; targetKind: TargetKind }[] = [
    {
      id: 'plot',
      label: t('models.plot.model.name'),
      targetKind: 'plots'
    },
    {
      id: 'device',
      label: t('models.device.model.name'),
      targetKind: 'devices'
    },
    {
      id: 'station',
      label: t('models.agro_weather_device.model.name', { capitalize: true }),
      targetKind: 'stations'
    }
  ]

  return (
    <Flex $alignItems='center'>
      {filters.map(({ label, id, targetKind: filterTargetKind }) => {
        const isDisabled = shouldDisableDevice
        const isSelected = filterTargetKind === targetKind
        const input = (
          <SelectChip
            key={label}
            Icon={isSelected ? Icons.Checkmark : null}
            iconProps={{ $size: 'md' }}
            isDisabled={isDisabled}
            isSelected={isSelected}
            onPress={() => {
              setTargetKind(filterTargetKind)
            }}
          >
            {label}
          </SelectChip>
        )

        return isDisabled ? (
          <TextTooltip key={id} content={t('alerts.device_target_unavailable')} $textAlign='center'>
            {input}
          </TextTooltip>
        ) : (
          input
        )
      })}
    </Flex>
  )
}

interface PickAlertTargetsProps {
  initialTargets: TargetsWithTZ | Alert['targets']
  sources: Source[]
  onTargetsChange: (targets: TargetsWithTZ) => void
}

const AlertTargetsSelector: FC<PickAlertTargetsProps> = ({
  initialTargets: initialTargetsFromProps,
  sources,
  onTargetsChange
}) => {
  const { t } = useIntl()

  const [currentPage, setCurrentPage] = useState(0)
  const [targetKind, setTargetKind] = useState<TargetKind>('plots')
  const [searchInputValue, setSearchInputValue] = useState('')
  const debouncedSearchInputValue = useDebounce(searchInputValue, 500)

  const { completeTargets, isLoading, count, hasPrevious, hasNext, hasPlotOnlySource } =
    useGetAvailableAlertTargets({
      sources,
      q: debouncedSearchInputValue,
      currentPage,
      limit: ITEMS_PER_PAGE,
      targetKind
    })

  const initialTargets = isTargetsWithTZ(initialTargetsFromProps)
    ? initialTargetsFromProps
    : convertTargetsToTargetsWithTz({ targetsToConvert: initialTargetsFromProps, completeTargets })

  const handleTargetSelection = useCallback(
    (target: Target, isSelected: boolean) => {
      let newTargets = initialTargets

      if (!isSelected) {
        const targetWithTz = { id: target.id, timezone: target.timezone }

        if (isPlotChecker(target)) {
          const newPlots = [...initialTargets.plots, targetWithTz]
          newTargets = {
            plots: newPlots,
            devices: initialTargets.devices,
            stations: initialTargets.stations
          }
        } else if (isDevice(target)) {
          const newDevices = [...initialTargets.devices, targetWithTz]

          newTargets = {
            plots: initialTargets.plots,
            devices: newDevices,
            stations: initialTargets.stations
          }
        } else {
          const newStations = [...initialTargets.stations, targetWithTz]

          newTargets = {
            plots: initialTargets.plots,
            devices: initialTargets.devices,
            stations: newStations
          }
        }
      } else {
        if (isPlotChecker(target)) {
          const newPlots = initialTargets.plots.filter((tgt) => tgt.id !== target.id)

          newTargets = {
            plots: newPlots,
            devices: initialTargets.devices,
            stations: initialTargets.stations
          }
        } else if (isDevice(target)) {
          const newDevices = initialTargets.devices.filter((tgt) => tgt.id !== target.id)

          newTargets = {
            plots: initialTargets.plots,
            devices: newDevices,
            stations: initialTargets.stations
          }
        } else {
          const newStations = initialTargets.stations.filter((tgt) => tgt.id !== target.id)

          newTargets = {
            plots: initialTargets.plots,
            devices: initialTargets.devices,
            stations: newStations
          }
        }
      }
      onTargetsChange(newTargets)
    },
    [onTargetsChange, initialTargets]
  )

  const handleTargetKindChange = useCallback(
    (newTargetKind: TargetKind) => {
      setTargetKind(newTargetKind)
      setCurrentPage(0)
    },
    [setTargetKind, setCurrentPage]
  )

  const startOfPage = (currentPage !== 0 ? currentPage * ITEMS_PER_PAGE : 0) + 1
  const endOfPage = Math.min(startOfPage - 1 + ITEMS_PER_PAGE, count ?? Number.MAX_SAFE_INTEGER)

  return (
    <Flex $flexDirection='column' $flex={1} $height='100%'>
      <SuperForm onSubmit={noop} schema={schemas.searchQuery}>
        <UseOnChange
          onChange={({ searchQuery }) => {
            setSearchInputValue(searchQuery as string)
          }}
        />
        <TextField name={'searchQuery'} label={t('actions.search')} />
      </SuperForm>
      <Flex $alignItems='center' $justifyContent='space-between' $flexWrap='wrap'>
        <Pop tourName='discover_alerts' stepName='targets' placement='bottom'>
          <TargetKindRadio
            targetKind={targetKind}
            setTargetKind={handleTargetKindChange}
            shouldDisableDevice={hasPlotOnlySource}
          />
        </Pop>
        <PaginationControls
          startOfPage={startOfPage}
          handleNextClick={() => setCurrentPage(currentPage + 1)}
          handlePreviousClick={() => setCurrentPage(currentPage - 1)}
          hasNext={hasNext}
          hasPrevious={hasPrevious}
          isLoading={isLoading}
          isPendingAndHasNoItems={count === 0 && isLoading}
          totalNumberOfItems={count}
          endOfPage={endOfPage}
        />
      </Flex>
      {/* List of allowed targets */}
      {!isLoading ? (
        <Box $flex={1} $height='100%'>
          {!isEmpty(completeTargets) ? (
            <Scrollable>
              {completeTargets?.map((completedTarget) => {
                const isSelected = !isNil(
                  initialTargets[targetKind].find(
                    (initialTarget) => initialTarget.id === completedTarget.id
                  )
                )
                return (
                  <AlertTargetListItem
                    key={completedTarget.id}
                    target={completedTarget}
                    $isSelected={isSelected}
                    onPress={() => handleTargetSelection(completedTarget, isSelected)}
                  />
                )
              })}
            </Scrollable>
          ) : (
            <ListEmpty message={t('alerts.no_targets')} />
          )}
        </Box>
      ) : (
        <LoadingList uniqueKey='AlertTargetsSelector' />
      )}
    </Flex>
  )
}

export default AlertTargetsSelector
