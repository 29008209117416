import { GoogleMapApi, LatLng } from 'app/src/map/utils'
import { BBox } from 'geojson'
import isEqual from 'lodash-es/isEqual'
import { ReactNode, createContext, useContext, useReducer } from 'react'

const HOVER_INTENT_SOURCES = ['marker', 'list', null] as const

type HoverIntentSource = (typeof HOVER_INTENT_SOURCES)[number]

interface HoverIntent {
  itemId: number | null
  source: HoverIntentSource
}

interface MapDetails {
  bbox: BBox | null
  zoom: number | null
}

interface TownGeolocation {
  bounds: google.maps.LatLngBounds | null
  location: LatLng | null
}

interface BackgroundMapState<T = unknown> {
  /**
   * A Ref to the BackgroundMap
   */
  api: GoogleMapApi
  /**
   * Whether or not markers should be drawn on the BackgroundMap
   */
  drawMarkers: boolean
  /**
   * Whether or not an item hovered on the plot list and should be highlighted on the BackgroundMap
   */
  hoverIntent: HoverIntent
  /**
   * Whether or not background map related data is being fetched
   */
  isFetching: boolean
  /**
   * Details of the map (bbox, zoom)
   */
  mapDetails: MapDetails
  /**
   * The users geolocation, if defined it will appear on the BackgroundMap
   */
  userGeolocation: LatLng | null
  /**
   * The headquarter geolocation, if defined it will appear on the BackgroundMap
   */
  headquarterLocation: LatLng | null
  /**
   * Whether or not the map zoom level is too high for radar too be displayed
   */
  zoomLevelTooHighForRadar: boolean
  /**
   * The global search query on the background map
   */
  search: string
  /**
   * The current town geolocation
   */
  townGeolocation: TownGeolocation
  /**
   * The markers that should be rendered on the map
   */
  markers: T[]
  /**
   * Single marker render function
   */
  renderMarkers: ((marker: T) => ReactNode) | null
  /**
   * onChange
   */
  onChange: (() => void) | null
}

const defaultState: BackgroundMapState = {
  api: { maps: null, map: null },
  drawMarkers: false,
  hoverIntent: { itemId: null, source: null },
  isFetching: false,
  mapDetails: { zoom: null, bbox: null },
  markers: [],
  renderMarkers: null,
  search: '',
  townGeolocation: { location: null, bounds: null },
  userGeolocation: null,
  headquarterLocation: null,
  zoomLevelTooHighForRadar: false,
  onChange: null
}

type BackgroundMapContextActions<T = unknown> =
  | { type: 'resetHoverIntent' }
  | { type: 'resetSearch' }
  | { type: 'setApi'; newApi: GoogleMapApi }
  | { type: 'setDrawMarkers'; newDrawMarkers: boolean }
  | { type: 'setFetching'; newValue: boolean }
  | { type: 'setGeolocation'; newGeolocation: LatLng | null }
  | { type: 'setHeadquarter'; newHeadquarterLocation: LatLng | null }
  | { type: 'setHoverIntent'; newHoverIntent: HoverIntent }
  | { type: 'setMapDetails'; newMapDetails: MapDetails }
  | { type: 'setMarkers'; newMarkers: T[] }
  | { type: 'setOnChange'; onChange: (() => void) | null }
  | { type: 'setRenderMarkers'; newRenderMarkers: ((marker: T) => ReactNode) | null }
  | { type: 'setSearch'; newSearch: string }
  | { type: 'setTownGeolocation'; newGeolocation: TownGeolocation }
  | { type: 'setZoomLevelTooHighForRadar'; newValue: boolean }

type BackgroundMapDispatcher<T> = (action: BackgroundMapContextActions<T>) => void

function throwUnknownBackgroundMapAction(p: never): never
function throwUnknownBackgroundMapAction(p: BackgroundMapContextActions) {
  throw new Error('Unknown action for BackgroundMapContext : ' + p.type)
}

const reducer = (
  state: BackgroundMapState,
  action: BackgroundMapContextActions
): BackgroundMapState => {
  switch (action.type) {
    case 'resetHoverIntent':
      if (state.hoverIntent.itemId !== null || state.hoverIntent.source != null) {
        return {
          ...state,
          hoverIntent: { itemId: null, source: null }
        }
      } else {
        return state
      }
    case 'resetSearch':
      if (state.search !== '') {
        return {
          ...state,
          search: ''
        }
      } else {
        return state
      }
    case 'setGeolocation':
      return {
        ...state,
        userGeolocation: action.newGeolocation
      }
    case 'setHeadquarter':
      return {
        ...state,
        headquarterLocation: action.newHeadquarterLocation
      }
    case 'setDrawMarkers':
      if (state.drawMarkers !== action.newDrawMarkers) {
        return {
          ...state,
          drawMarkers: action.newDrawMarkers
        }
      } else {
        return state
      }
    case 'setFetching':
      if (state.isFetching !== action.newValue) {
        return {
          ...state,
          isFetching: action.newValue
        }
      } else {
        return state
      }
    case 'setHoverIntent':
      if (!isEqual(action.newHoverIntent, state.hoverIntent)) {
        return {
          ...state,
          hoverIntent: action.newHoverIntent
        }
      } else {
        return state
      }
    case 'setMapDetails':
      if (!isEqual(action.newMapDetails, state.mapDetails)) {
        return {
          ...state,
          mapDetails: action.newMapDetails
        }
      } else {
        return state
      }
    case 'setApi':
      return {
        ...state,
        api: action.newApi
      }
    case 'setZoomLevelTooHighForRadar':
      return {
        ...state,
        zoomLevelTooHighForRadar: action.newValue
      }
    case 'setMarkers':
      if (!isEqual(state.markers, action.newMarkers)) {
        return {
          ...state,
          markers: action.newMarkers
        }
      } else {
        return state
      }
    case 'setOnChange':
      return {
        ...state,
        onChange: action.onChange
      }
    case 'setRenderMarkers':
      if (!isEqual(state.renderMarkers, action.newRenderMarkers)) {
        return {
          ...state,
          renderMarkers: action.newRenderMarkers
        }
      } else {
        return state
      }
    case 'setSearch':
      return {
        ...state,
        search: action.newSearch
      }
    case 'setTownGeolocation':
      return {
        ...state,
        townGeolocation: action.newGeolocation
      }
    default:
      // Force switch to be complete
      throwUnknownBackgroundMapAction(action)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BackgroundMapCtx = createContext<BackgroundMapState<any> | undefined>(undefined)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const BackgroundMapDispatch = createContext<BackgroundMapDispatcher<any> | undefined>(undefined)

export const BackgroundMapContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, defaultState)

  return (
    <BackgroundMapCtx.Provider value={state}>
      <BackgroundMapDispatch.Provider value={dispatch}>{children}</BackgroundMapDispatch.Provider>
    </BackgroundMapCtx.Provider>
  )
}

export function useBackgroundMapContext<MarkerType>() {
  const context = useContext<BackgroundMapState<MarkerType> | undefined>(BackgroundMapCtx)
  if (!context) {
    throw new Error(
      'You may have forgot to render <BackgroundMapContextProvider /> because BackgroundMapContext is not defined'
    )
  }
  return context
}

export function useBackgroundMapDispatcher<MarkerType>() {
  const context = useContext<BackgroundMapDispatcher<MarkerType> | undefined>(BackgroundMapDispatch)
  if (!context) {
    throw new Error(
      'You may have forgot to render <BackgroundMapContextProvider /> because BackgroundMapDispatch is not defined'
    )
  }
  return context
}
