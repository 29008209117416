import { useClient, useQuery } from '@weenat/client'
import { sliderMsBetweenSteps } from '@weenat/client/dist/core/radar'
import { useIntl } from '@weenat/wintl'
import Icons from 'app/src/kit/Icons'
import Text from 'app/src/kit/Text'
import { RangeInput } from 'app/src/kit/fields/SliderField'
import { GoogleMapApi } from 'app/src/map/utils'
import isNil from 'lodash-es/isNil'
import { useEffect, useState } from 'react'
import { useDebounce as useDebounceReactUse } from 'react-use'
import { styled } from 'styled-components'
import logEvent from '../../../../../utils/analytics'
import useDebounce from '../../../../hooks/useDebounce'
import { MAP_ICON_SIZE } from '../constants'
import { useBackgroundMapContext } from '../contexts/BackgroundMapContext'
import { useRadarContext } from '../contexts/RadarContext'
import useRadarTiles from './hooks/useRadarTiles'

const RadarSliderContainer = styled(Flex)`
  align-items: center;
  background-color: ${(p) => p.theme.colors.grayscale.white};
  border-radius: ${(p) => p.theme.radiuses.rounded}px;
  flex: 1;

  /* This is the remaining padding from RadarSliderContainerHatch, the padding is not applied on the main wrapper otherwise it would also pad the hatched background */
  padding-left: ${(p) => p.theme.spacings.md}px;

  pointer-events: auto;
`

const RadarSliderContainerHatch = styled(Flex)`
  flex: 1;
  border-radius: ${(p) => p.theme.radiuses.rounded}px;
  background: repeating-linear-gradient(
      135deg,
      ${(p) => p.theme.colors.grayscale[100]},
      ${(p) => p.theme.colors.grayscale[100]} 2px,
      white 2px,
      white 6px
    )
    no-repeat;

  /* Only half of the background is hatched */
  background-size: 50% 100%;
  background-position: right;
  padding: ${(p) =>
    `${p.theme.spacings.md}px ${p.theme.spacings.lg}px ${p.theme.spacings.md}px ${p.theme.spacings.lg}px`};
`

const RadarRangeInput = styled(RangeInput)`
  position: relative;
  flex: 1;

  /* Because we have a thumb on the slider which make it impossible to center correctly without this hack */
  top: -20px;
`

const RadarSlider = ({ map, maps }: GoogleMapApi) => {
  const { t } = useIntl()
  const client = useClient()
  const timestampsRequest = useQuery(client.radar.getTimestamps())

  const {
    state: { isLoading, currentTimestampIndex },
    dispatch
  } = useRadarContext()

  const { zoomLevelTooHighForRadar } = useBackgroundMapContext()

  // actual state of slider
  const [isPaused, setIsPaused] = useState(true)
  // this intermediate state is use to store user willing
  // because otherwise we cannot know if slider is paused because of loading or user action
  const [userWantPause, setUserWantPause] = useState(true)
  // this state is a workaround to track user seeking behaviours
  const [trackingSeek, setTrackingSeek] = useState<number | null>(null)

  const allTimestamps = timestampsRequest.data ? timestampsRequest.data.timestamps : []
  const hasTimestamps = allTimestamps.length !== 0
  const timestampIndex = hasTimestamps ? currentTimestampIndex : 0

  useEffect(() => {
    if (allTimestamps.length > 0) {
      const index = Math.round((allTimestamps.length - 1) / 2)
      // Most middle index of allTimestamps
      dispatch({ type: 'setCurrentTimestampIndex', newIndex: index })
    }
  }, [allTimestamps.length])

  useEffect(() => {
    if (!isLoading && !isPaused && !isNil(timestampIndex)) {
      const handle = setInterval(() => {
        const index = !isNil(allTimestamps[timestampIndex + 1]) ? timestampIndex + 1 : 0
        dispatch({ type: 'setCurrentTimestampIndex', newIndex: index })
      }, sliderMsBetweenSteps)
      return () => clearInterval(handle)
    }
  }, [isPaused, timestampIndex, isLoading])

  const debouncedIsRadarLoading = useDebounce(isLoading, 100)

  useEffect(() => {
    let handle: number | undefined
    if (debouncedIsRadarLoading) {
      setIsPaused(true)
    } else {
      handle = setTimeout(() => {
        if (!userWantPause) {
          setIsPaused(false)
        }
      }, sliderMsBetweenSteps) as unknown as number
    }
    return () => {
      clearTimeout(handle)
    }
  }, [debouncedIsRadarLoading, userWantPause])

  useRadarTiles({
    map,
    maps,
    radarTimestamps: timestampsRequest.data
  })

  useDebounceReactUse(
    () => {
      if (!isNil(trackingSeek)) {
        logEvent('radar_seek')
      }
    },
    800,
    [trackingSeek]
  )

  return (
    <RadarSliderContainer>
      {timestampsRequest.isError || zoomLevelTooHighForRadar ? (
        <Flex $p='lg' $justifyContent='center' $alignItems='center' $flex={1}>
          <Text $textAlign='center'>
            {timestampsRequest.isError ? t('errors.unknown') : t('status.radar_too_zoomed')}
          </Text>
        </Flex>
      ) : (
        <>
          {isPaused ? (
            <Icons.Play
              $size={MAP_ICON_SIZE}
              $p='sm'
              $rounded
              $isLoading={debouncedIsRadarLoading}
              onPress={() => {
                logEvent('radar_play')
                setIsPaused(false)
                setUserWantPause(false)
              }}
            />
          ) : (
            <Icons.Pause
              $size={MAP_ICON_SIZE}
              $p='sm'
              $rounded
              $isLoading={debouncedIsRadarLoading}
              onPress={() => {
                logEvent('radar_pause')
                setIsPaused(true)
                setUserWantPause(true)
              }}
            />
          )}
          <RadarSliderContainerHatch>
            <RadarRangeInput
              withoutLabel
              thumbTextBgColor='white'
              noMargin
              id='radar-slider'
              value={timestampIndex}
              onChange={(index: number) => {
                setTrackingSeek(Date.now())
                dispatch({ type: 'setCurrentTimestampIndex', newIndex: index })
              }}
              renderValue={(i: number) => {
                const currentDate = !isNil(allTimestamps[i])
                  ? new Date(parseInt(allTimestamps[i].originalTimestamp, 10))
                  : null

                return !isNil(currentDate)
                  ? `${currentDate.getHours()}:${currentDate.getMinutes() === 0 ? '00' : currentDate.getMinutes()}`
                  : ''
              }}
              min={0}
              step={1}
              max={hasTimestamps ? allTimestamps.length - 1 : 2}
            />
          </RadarSliderContainerHatch>
        </>
      )}
    </RadarSliderContainer>
  )
}

export default RadarSlider
