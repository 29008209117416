import { useClient, useMutation } from '@weenat/client'
import { InvitationStatus } from '@weenat/client/dist/enums'
import { Invitation, RequestType } from '@weenat/client/dist/resources/invitations'
import { useIntl } from '@weenat/wintl'
import useToasts from 'app/src/hooks/useToasts'
import DeclineAndAcceptButtons from 'app/src/kit/DeclineAndAcceptButtons'
import DelimitedFlex from 'app/src/kit/DelimitedFlex'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import moment from 'moment-timezone'
import { useState } from 'react'
import StatusBadge from '../kit/StatusBadge'
import TrialBadge from '../kit/TrialBadge'
import AcceptNetworkInvitationModal from './AcceptNetworkInvitationModal'

const InvitationsSentToMeListItem: FC<{ invitation: Invitation }> = ({ invitation }) => {
  const { t } = useIntl()
  const client = useClient()
  const { addToast } = useToasts()

  const showErrorToast = () => addToast(t('errors.unknown'), { appearance: 'error' })

  const [accept, acceptState] = useMutation(client.invitations.received.accept(invitation.id), {
    onSuccess: () => addToast(t('actions.accept_success'), { appearance: 'success' }),
    onError: showErrorToast
  })
  const [decline, declineState] = useMutation(client.invitations.received.decline(invitation.id), {
    onSuccess: () => addToast(t('actions.decline_success'), { appearance: 'success' }),
    onError: showErrorToast
  })

  const { senderOrganizationName, senderName, type, createdAt, status } = invitation

  const creationMoment = !isNil(createdAt) ? moment.unix(parseInt(createdAt, 10)) : null

  const isNetworkInvitation = [
    RequestType.adherentFarmInvitation,
    RequestType.adherentFarmInvitationWithTrial,
    RequestType.autoAcceptedAdherentFarmInvitation
  ].includes(type)

  const [modalInvitation, setModalInvitation] = useState<Invitation | null>(null)

  const invitationDescription = isNetworkInvitation
    ? t('models.invitation.misc.someone_invites_you_to_join_network', {
        senderName,
        networkName: senderOrganizationName
      })
    : t('models.invitation.misc.someone_invites_you_to_join_farm', {
        senderName,
        farmName: senderOrganizationName
      })

  return (
    <>
      <DelimitedFlex $flexDirection='column' $width='100%' $p='lg'>
        <Flex $alignItems='center' $gap='md'>
          <StatusBadge invitation={invitation} />
          {type === RequestType.adherentFarmInvitationWithTrial ? <TrialBadge /> : null}
        </Flex>
        <Flex $width='100%' $my='md'>
          <Box $flex={1}>
            {type === RequestType.orgOwnershipTransfer ? (
              <Text $fontWeight='bold'>
                {t('models.invitation.misc.invitation_transfers_ownership')}
              </Text>
            ) : (
              <>
                <Text>{invitationDescription}</Text>
                {!isNil(creationMoment) ? (
                  <Text $fontSize='sm' $lineHeight='lg'>
                    {t('models.invitation.misc.received_at_date', {
                      date: creationMoment.format(`${t('formats.full_date_short')} HH:mm`)
                    })}
                  </Text>
                ) : null}
              </>
            )}
          </Box>
          {status === InvitationStatus.pending ? (
            <DeclineAndAcceptButtons
              variant='icons'
              acceptStatus={acceptState}
              declineStatus={declineState}
              handleAcceptPress={() =>
                isNetworkInvitation ? setModalInvitation(invitation) : accept()
              }
              handleDeclinePress={() => {
                decline()
              }}
            />
          ) : null}
        </Flex>
      </DelimitedFlex>
      {modalInvitation ? (
        <AcceptNetworkInvitationModal
          invitationId={modalInvitation.id}
          close={() => setModalInvitation(null)}
          onSuccess={() => setModalInvitation(null)}
        />
      ) : null}
    </>
  )
}
export default InvitationsSentToMeListItem
