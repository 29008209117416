import Card from 'app/src/kit/Card'
import isNil from 'lodash-es/isNil'
import { List as ContentLoaderList } from 'react-content-loader'
import { styled } from 'styled-components'

const CARD_HEIGHT = 256

const Container = styled(Card)<{ $height?: number }>`
  box-shadow: none;
  max-height: ${(p) => (!isNil(p.$height) ? p.$height : CARD_HEIGHT)}px;
  margin: 8px 0;
`

interface LoadingCardProps {
  uniqueKey: string
  height?: number
}

const LoadingCard: FC<LoadingCardProps> = ({ height = 192, uniqueKey }) => (
  <Container>
    <ContentLoaderList uniqueKey={uniqueKey} height={height} />
  </Container>
)

export default LoadingCard
