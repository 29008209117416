import { Form, Formik, FormikConfig, FormikProps, useFormikContext } from 'formik'
import noop from 'lodash-es/noop'
import Button, { ButtonProps } from './Button'

/** TODO: find a way to correctly type this, when using an extend, the call sites types are broken */
function SuperForm<S>({
  preventEnterSubmit = false,
  schema,
  onSubmit = noop,
  initialValues,
  children,
  className,
  enableReinitialize = false,
  formRef = null,
  validateOnMount = false
}: {
  schema: S
  /** Prevent the form from being submitted using an enter press key, useful when using ButtonWithConfirm as a fake submit button,
   * TODO: find an ergonomic way to trigger the buttonWithConfirm when we press enter
   */
  preventEnterSubmit?: boolean
  onSubmit?: FormikConfig<(typeof schema)['initialValues']>['onSubmit']
  initialValues?: Partial<(typeof schema)['initialValues']>
  children:
    | React.ReactNode
    | ((props: FormikProps<Partial<(typeof schema)['initialValues']>>) => React.ReactNode)

  className?: string
  enableReinitialize?: boolean
  formRef?: React.Ref<FormikProps<Partial<(typeof schema)['initialValues']>>>
  validateOnMount?: boolean
}) {
  if (!('validate' in schema)) {
    throw new Error(
      `You passed something that does not look like a schema to SuperForm. There is no validate method on the passed props.schema object`
    )
  }
  return (
    <Formik
      validate={schema.validate}
      onSubmit={onSubmit}
      enableReinitialize={enableReinitialize}
      initialValues={{
        ...schema.initialValues,
        ...(initialValues || {})
      }}
      innerRef={formRef ? formRef : undefined}
      validateOnMount={validateOnMount}
    >
      {typeof children !== 'function' ? (
        <Form
          className={className}
          onKeyDown={
            preventEnterSubmit ? (e) => (e.key === 'Enter' ? e.preventDefault() : null) : undefined
          }
        >
          {children}
        </Form>
      ) : (
        children
      )}
    </Formik>
  )
}

export default SuperForm

export const SuperFormSubmitButton: FC<ButtonProps> = (props: ButtonProps) => {
  const { isValid, submitForm } = useFormikContext()
  return <Button type='submit' isDisabled={!isValid} onPress={submitForm} {...props} />
}
