import { useIntl } from '@weenat/wintl'
import Text from 'app/src/kit/Text'
import { memo } from 'react'
import Card from '../kit/Card'
import SmartText from '../kit/SmartText'
import { NetworkPresentationCommonProps } from './NetworkPresentationLayoutTools'
import RequestNetworkMembership from './RequestNetworkMembership'

const JoinNetworkCTASection: FC<NetworkPresentationCommonProps> = ({ network }) => {
  const { t } = useIntl()

  return (
    <Card $m='lg' $borderColor={'primary.800'} $backgroundColor={'primary.500'}>
      <Flex $flexDirection='column' $gap='lg'>
        <Text $fontSize='md' $textAlign='center' $fontWeight='bold' $color={'grayscale.white'}>
          {t('models.network.misc.presentation_join_section_title', {
            networkName: network.name
          })}
        </Text>
        <SmartText
          tkey={'models.network.misc.try_features_for_free'}
          vars={{
            networkName: network.name
          }}
          $textAlign='center'
          $color={'grayscale.white'}
        />
        <RequestNetworkMembership
          network={network}
          buttonProps={{
            color: 'primary.500',
            backgroundColor: 'grayscale.white'
          }}
        />
      </Flex>
    </Card>
  )
}

export default memo(JoinNetworkCTASection)
