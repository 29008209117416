import { useIntl } from '@weenat/wintl'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import { useTheme } from 'styled-components'
import Cloudy, { CloudyMood } from './icons/Cloudy'

// TODO: try to extract those keys from wintl
type PossibleModel =
  | 'alert'
  | 'article'
  | 'device'
  | 'event'
  | 'invitation'
  | 'invoice'
  | 'member'
  | 'mobileDevices'
  | 'network'
  | 'networkMembershipRequest'
  | 'networkOrganization'
  | 'notification'
  | 'option'
  | 'optionActivationApprovalRequest'
  | 'organization'
  | 'organizationOptionActivation'
  | 'phoneNumber'
  | 'plot'
  | 'plotOptionActivation'
  | 'stationOptionActivation'
  | 'searchResult'
  | 'thresholdsProfile'
  | 'userMeasurementReport'
  | 'agro_weather_device'
  | 'irrigation_probe'

export interface ListEmptyProps {
  model?: PossibleModel
  isFetchPending?: boolean
  message?: string
  noItemsKey?: string
  mood?: CloudyMood
}

function ListEmpty({
  model,
  message,
  isFetchPending,
  mood = 'confused',
  noItemsKey = 'no_items'
}: ListEmptyProps) {
  const { t } = useIntl()
  const { colors } = useTheme()

  const CloudyIcn = Cloudy[mood]

  const finalMessage = model ? (`models.${model}.model.${noItemsKey}` as const) : 'lists.no_items'

  if (isFetchPending) return null

  return (
    <Flex $p='lg' $mx='lg' $flexDirection='column' $alignItems='center' $justifyContent='center'>
      <Box $mb='lg'>
        <CloudyIcn $rounded $size='2xl' $p='lg' $backgroundColor={colors.grayscale[100]} />
      </Box>
      <Text $textAlign='center'>{!isNil(message) ? message : t(finalMessage)}</Text>
    </Flex>
  )
}

export default ListEmpty
