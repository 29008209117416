import { useIntl } from '@weenat/wintl'
import Icons from 'app/src/kit/Icons'
import Text from 'app/src/kit/Text'

const FetchSummariesError = () => {
  const { t } = useIntl()

  return (
    <Flex $flexDirection='column' $justifyContent='center' $flex={1}>
      <Box $mb='sm'>
        <Text $fontSize='sm' $textAlign='center'>
          {t('errors.unknown')}
        </Text>
      </Box>
      <Flex $flexDirection='column' $alignItems='center'>
        <Text $fontSize='sm' $textAlign='center'>
          {t(`actions.retry_by_pressing_reload`)}
        </Text>
        <Icons.Refresh $size='rg' />
      </Flex>
    </Flex>
  )
}

export default FetchSummariesError
