import Button from './Button'
import DelimitedFlex from './DelimitedFlex'

interface ToastAction {
  id: string
  label: string
  onPress: () => void
}

interface ToastActionsProps {
  actions: [ToastAction] | [ToastAction, ToastAction]
}

const ToastActions: FC<ToastActionsProps> = ({ actions }) => {
  return (
    <DelimitedFlex
      $isDelimitedOnTop
      $justifyContent='flex-end'
      $gap='md'
      $backgroundColor='grayscale.50'
      $px='lg'
      $py='md'
    >
      {actions.map((action, i) => (
        <Button key={action.id} importance={i === 0 ? 'pm' : 'sd'} onPress={action.onPress}>
          {action.label}
        </Button>
      ))}
    </DelimitedFlex>
  )
}

export default ToastActions
