import Icon, { IconProps } from '../Icon'
import { Irrelis } from './generic-icons'
import {
  AcUnit,
  Capacitive,
  Etp,
  EtpSum,
  Humidity,
  HydricPotential,
  Irrigation,
  IrrigationSum,
  LeafWetness,
  PhotosyntheticActiveRadiation,
  Rainfalls,
  RainfallsSum,
  SoilWaterContent,
  SolarFlares,
  Temperature,
  TemperatureDry,
  TemperatureGround,
  TemperatureWet,
  VaporPressureDeficit,
  WaterElectroConductivity,
  Wind,
  WindDirection
} from './metric-icons'

interface MetricIconProps extends IconProps {
  svg: React.ReactElement
}

const MetricIcon: FC<MetricIconProps> = ({ svg, ...props }) => <Icon {...props}>{svg}</Icon>

const MetricIcons = {
  COLD_T_HOUR: (props: IconProps) => <MetricIcon svg={<AcUnit />} {...props} />,
  DD: (props: IconProps) => <MetricIcon svg={<WindDirection />} {...props} />,
  DXY: (props: IconProps) => <MetricIcon svg={<WindDirection />} {...props} />,
  etp: (props: IconProps) => <MetricIcon svg={<Etp />} {...props} />,
  FF: (props: IconProps) => <MetricIcon svg={<Wind />} {...props} />,
  FXY: (props: IconProps) => <MetricIcon svg={<Wind />} {...props} />,
  HDEF: (props: IconProps) => <MetricIcon svg={<Irrelis />} {...props} />,
  heatAccumulation: (props: IconProps) => <MetricIcon svg={<Temperature />} {...props} />,
  RRAccumulation: (props: IconProps) => <MetricIcon svg={<Rainfalls />} {...props} />,
  SSI_HOURSAccumulation: (props: IconProps) => <MetricIcon svg={<SolarFlares />} {...props} />,
  HPOT: (props: IconProps) => <MetricIcon svg={<HydricPotential />} {...props} />,
  irrigation: (props: IconProps) => <MetricIcon svg={<Irrigation />} {...props} />,
  LW_D: (props: IconProps) => <MetricIcon svg={<LeafWetness />} {...props} />,
  LW_V: (props: IconProps) => <MetricIcon svg={<LeafWetness />} {...props} />,
  RR: (props: IconProps) => <MetricIcon svg={<Rainfalls />} {...props} />,
  IRR: (props: IconProps) => <MetricIcon svg={<Irrigation />} {...props} />,
  SSI_HOURS: (props: IconProps) => <MetricIcon svg={<SolarFlares />} {...props} />,
  SSI: (props: IconProps) => <MetricIcon svg={<SolarFlares />} {...props} />,
  SSI_MIN: (props: IconProps) => <MetricIcon svg={<SolarFlares />} {...props} />,
  SSI_MAX: (props: IconProps) => <MetricIcon svg={<SolarFlares />} {...props} />,
  T_CAPA: (props: IconProps) => <MetricIcon svg={<TemperatureGround />} {...props} />,
  T_CAL: (props: IconProps) => <MetricIcon svg={<TemperatureGround />} {...props} />,
  T_DEW: (props: IconProps) => <MetricIcon svg={<Temperature />} {...props} />,
  T_DRY: (props: IconProps) => <MetricIcon svg={<TemperatureDry />} {...props} />,
  T_SOIL: (props: IconProps) => <MetricIcon svg={<TemperatureGround />} {...props} />,
  T_WET: (props: IconProps) => <MetricIcon svg={<TemperatureWet />} {...props} />,
  T: (props: IconProps) => <MetricIcon svg={<Temperature />} {...props} />,
  U_CAPA: (props: IconProps) => <MetricIcon svg={<Capacitive />} {...props} />,
  U: (props: IconProps) => <MetricIcon svg={<Humidity />} {...props} />,
  IRR_ACCUMULATION: (props: IconProps) => <MetricIcon svg={<IrrigationSum />} {...props} />,
  ETP_ACCUMULATION: (props: IconProps) => <MetricIcon svg={<EtpSum />} {...props} />,
  RR_ACCUMULATION: (props: IconProps) => <MetricIcon svg={<RainfallsSum />} {...props} />,
  PPFD: (props: IconProps) => <MetricIcon svg={<PhotosyntheticActiveRadiation />} {...props} />,
  PPFD_MIN: (props: IconProps) => <MetricIcon svg={<PhotosyntheticActiveRadiation />} {...props} />,
  PPFD_MAX: (props: IconProps) => <MetricIcon svg={<PhotosyntheticActiveRadiation />} {...props} />,
  SWC: (props: IconProps) => <MetricIcon svg={<SoilWaterContent />} {...props} />,
  ECP: (props: IconProps) => <MetricIcon svg={<WaterElectroConductivity />} {...props} />,
  VPD: (props: IconProps) => <MetricIcon svg={<VaporPressureDeficit />} {...props} />
}

export default MetricIcons
