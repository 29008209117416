import { useIntl } from '@weenat/wintl'
import useDisclosure from 'app/src/hooks/useDisclosure'
import Text from 'app/src/kit/Text'
import { useField } from 'formik'
import isArray from 'lodash-es/isArray'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { Fragment, ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { useDeepCompareEffect } from 'react-use'
import { styled } from 'styled-components'
import Badge from '../Badge'
import { DiscardableProps } from '../Discardable'
import Icons from '../Icons'
import LoadingCircle from '../loaders/LoadingCircle'
import InputFieldContainer, { InputFieldContainerProps } from './InputFieldContainer'
import InvisibleTextInput from './InvisibleInput'
import SearchSelectOptionsModal from './SearchSelectOptionsModal'
import { Dropdown, DropdownContent, DropdownTrigger } from './Select/SelectDropdown'
import SelectOptionItem from './Select/SelectOptionItem'
import { TextFieldProps } from './TextField'

const CHEVRON_ICON_ID = 'select-field-chevron'

/**
 * Documentation as to how I see the Select working
 *
 * Initial State
 * SIMPLE : Should default on undefined unless the forms gives it an initial value
 * MULTI : Should default on [] unless the forms gives it an initial value
 *
 * Change in options
 * SIMPLE : If the current value is no longer part of the options it should default back to undefined
 * MULTI : Should remove the current values that aren't part of the options from the values
 */

interface SelectOptionType<V> {
  value: V
  label: string
  isDisabled?: boolean
}

export interface SelectProps<V> {
  name: string
  leftAdornment?: InputFieldContainerProps['leftAdornment']
  displayedError?: ReactElement
  /** number of active filters */
  activeFilters?: number
  /** allows for styled-components overrides */
  className?: string
  /** Message to display when there is no match to the user's query */
  emptyOptionsMessage?: string
  /** Component the will render right under the search bar on modal */
  filterPage?: React.ReactElement
  /** Whether or not the select should allow filtering. False by default. */
  isAutocomplete?: boolean
  /** Whether or not to show a cross to the right that will allow to clear the value. True by default. */
  isClearable?: boolean
  /** Whether or not the select should default on the first available option, when options are updated. True by default. */
  isDefaultingOnFirstOption?: boolean
  /** Whether or not the select should appear as disabled. False by default. */
  isDisabled?: boolean
  /** Whether or not the select should appear as loading. False by default. */
  isLoading?: boolean
  /** Whether or not to open a Modal on select. False by default. */
  isModal?: boolean
  /** Whether or not the field is required. True by default. */
  isRequired?: boolean
  /** The initial state for the useDisclosure of the select if in modal mode */
  isModalInitiallyOpen?: boolean
  /** Enable multiple selected options, the value is an array when isMulti is true */
  isMulti?: boolean
  /** The options to choose from. [] by default. */
  options: SelectOptionType<V>[]
  /** Classic input placeholder */
  placeholder?: string
  /** Props to pass to the Discardable components shown when isMulti is true */
  selectedDiscardableProps?: (value: string) => Partial<DiscardableProps>
  /** Children to pass to the selectable items. Only activated when isModal is true */
  optionItemChildren?: (item: V) => ReactElement
  /** The modal width when isModal=true  */
  modalWidth?: string | number
  /** The modal height when isModal=true */
  modalHeight?: string | number
  modalFooter?: ReactElement
  warnings?: InputFieldContainerProps['warning']
  helpers?: InputFieldContainerProps['helpers']
  hideErrors?: InputFieldContainerProps['hideErrors']
  label: TextFieldProps['label'] | undefined
  onToggle?: (newState: boolean) => void
}

export interface SelectFieldPrimitiveProps<V> extends SelectProps<V> {
  value: V | V[]
  onChange: (newValue?: V | V[]) => void
}

const DisplayText = styled(Text)`
  overflow: hidden;
`

/**
 * Select with option to open modals, filter, multiple choices
 * NOTE: can be use with formik by using the setValue on the onChange prop
 */
export function SelectFieldPrimitive<V>({
  activeFilters,
  className,
  emptyOptionsMessage,
  displayedError,
  hideErrors = false,
  filterPage,
  isAutocomplete = false,
  isClearable = true,
  isDefaultingOnFirstOption = false,
  isDisabled = false,
  isModal = false,
  isMulti = false,
  isLoading = false,
  isRequired = true,
  isModalInitiallyOpen = false,
  label,
  leftAdornment,
  name,
  options = [],
  placeholder,
  selectedDiscardableProps,
  modalFooter,
  modalWidth,
  modalHeight,
  optionItemChildren,
  value,
  onChange,
  warnings,
  helpers,
  onToggle
}: SelectFieldPrimitiveProps<V>) {
  const { t } = useIntl()

  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined)
  const [displayedValue, setDisplayedValue] = useState<string | undefined>(
    isDefaultingOnFirstOption ? (!isEmpty(options) ? options[0].label : undefined) : undefined
  )
  const [filteredOptions, setFilteredOptions] = useState(options)

  const containerRef = useRef<HTMLDivElement>(null)

  const searchInputId = `searchQueryFor${name}`

  const {
    isOpen: isOptionListOpen,
    open: openOptionList,
    close: closeOptionList
  } = useDisclosure(isModalInitiallyOpen)

  const handleClose = useCallback(() => {
    onToggle?.(false)
    closeOptionList()
  }, [closeOptionList, onToggle])

  const closeOptionListIfOpen = () => {
    if (isOptionListOpen) handleClose()
  }

  const handlePress = useCallback(() => {
    if (isOptionListOpen) {
      handleClose()
    } else {
      if (!isDisabled) {
        onToggle?.(true)
        openOptionList()
        if (isAutocomplete) {
          setSearchQuery(undefined)
        }
      }
    }
  }, [isOptionListOpen, handleClose, isDisabled, onToggle, openOptionList, isAutocomplete])

  const handleSetValue = (newValue: V | undefined) => {
    if (!isNil(newValue)) {
      if (isMulti && isArray(value)) {
        if (value.includes(newValue)) {
          onChange(value.filter((x: unknown) => x !== newValue))
        } else {
          onChange(value.concat(newValue))
        }
      } else {
        if (!isRequired && value === newValue) {
          onChange(undefined)
        } else {
          onChange(newValue)
        }
      }
    } else {
      if (!isMulti) {
        onChange(newValue)
      }
    }
  }

  const handleSelectionChange = (option: SelectOptionType<V> | undefined) => {
    if (!isNil(option)) {
      setDisplayedValue(option.label)
      setSearchQuery(undefined)
      handleSetValue(option.value)
      closeOptionListIfOpen()
    } else {
      setDisplayedValue(undefined)
    }
  }

  const isSelected = (testedValue: V | undefined) =>
    isMulti && isArray(value) ? value.includes(testedValue) : value === testedValue

  const placeHolderComponent = !isNil(placeholder) ? (
    <DisplayText $color={'grayscale.700'} $ellipsis>
      {placeholder}
    </DisplayText>
  ) : null

  const finalLabel = isNil(label)
    ? undefined
    : isRequired
      ? label
      : t('forms.optional_label', { label })
  const chevronClass = `${CHEVRON_ICON_ID}-${name}`
  const inputContainerClass = `${CHEVRON_ICON_ID}-${name} ${
    isNil(className) ? '' : className
  }`.trim()

  // initializing search query & input value
  useDeepCompareEffect(() => {
    const isFieldValueNotEmpty = isMulti ? !isEmpty(value) : !isNil(value)

    if (!isEmpty(options)) {
      if (!isMulti) {
        if (isFieldValueNotEmpty) {
          const matchingOption = options.find((option) => option.value === value)
          setDisplayedValue(!isNil(matchingOption) ? matchingOption.label : undefined)
        } else {
          setDisplayedValue(undefined)
        }
      } else {
        setDisplayedValue(undefined)
      }
    }
  }, [options, value])

  // refreshing input and label when options are changing
  useEffect(() => {
    const currentValueNotInOptions = !isEmpty(options) && options.every((x) => x.value !== value)

    if (isDefaultingOnFirstOption && !isNil(options[0]) && currentValueNotInOptions) {
      setDisplayedValue(options[0].label)
      handleSetValue(options[0])
    }
  }, [options, isDefaultingOnFirstOption])

  // updating filtered options
  useEffect(() => {
    let tempOptions = options
    if (
      !isNil(searchQuery) &&
      !isNil(options) &&
      !isEmpty(options) &&
      isAutocomplete &&
      searchQuery.length !== 0
    ) {
      tempOptions = options.filter((opt) =>
        opt.label.toLowerCase().includes(searchQuery.toLowerCase())
      )
    }

    setFilteredOptions(tempOptions)
  }, [searchQuery, options])

  const MaybeTrigger = isModal ? Fragment : DropdownTrigger

  return (
    <Dropdown
      isVisible={!isModal && isOptionListOpen}
      onVisibleChange={() => {
        if (!isModal) {
          if (isOptionListOpen) {
            handleClose()
          } else {
            openOptionList()
          }
        }
      }}
    >
      <MaybeTrigger>
        <InputFieldContainer
          hideErrors={hideErrors}
          onPress={(e) => {
            e.stopPropagation()
            handlePress()
          }}
          $isDisabled={isDisabled}
          className={inputContainerClass}
          label={finalLabel}
          helpers={helpers}
          warning={warnings}
          isRequired={isRequired}
          leftAdornment={leftAdornment}
          rightAdornment={
            <Flex $alignItems='center'>
              <Box $mx='sm' $height='24px' $width='1px' $backgroundColor={'grayscale.200'} />
              <Icons.ChevronLeft
                $rotate={isOptionListOpen ? 90 : -90}
                $size='lg'
                $color={'grayscale.black'}
                onPress={(e) => {
                  e.stopPropagation()
                  handlePress()
                }}
                className={chevronClass}
              />
            </Flex>
          }
          value={value}
          isLoading={isLoading}
          isClearable={
            (isClearable && isOptionListOpen && isAutocomplete) || (!isRequired && !isNil(value))
          }
          onClear={() => {
            if (isDefaultingOnFirstOption) {
              onChange(options[0])
            } else {
              onChange(undefined)
            }
          }}
          $isPointerCursor
          $displayedError={displayedError}
          ref={containerRef}
        >
          {!isNil(value) ? (
            <>
              {isMulti && isArray(value) && !isEmpty(value) ? (
                <Flex $flexWrap='wrap' $alignItems='center' $width='100%' $flex={1}>
                  {value.map((optionValue: V) => {
                    const matchingOption = options.find((option) => option.value === optionValue)

                    const discardableProps: Partial<DiscardableProps> = selectedDiscardableProps
                      ? selectedDiscardableProps(optionValue)
                      : {}

                    return (
                      <Badge
                        key={optionValue}
                        $borderRadius='sm'
                        $mr='sm'
                        $pl='md'
                        $backgroundColor='primary.500'
                      >
                        <Flex $alignItems='center'>
                          <Text $fontSize='sm' $fontWeight='semiBold' $color={'grayscale.white'}>
                            {matchingOption?.label}
                          </Text>
                          <Icons.Close
                            $size='rg'
                            $color={'grayscale.white'}
                            onPress={() => {
                              handleSelectionChange(matchingOption)
                              if (discardableProps.onDiscard) {
                                discardableProps.onDiscard()
                              }
                            }}
                            $p='sm'
                          />
                        </Flex>
                      </Badge>
                    )
                  })}
                </Flex>
              ) : !isNil(displayedValue) ? (
                <DisplayText $ellipsis>{displayedValue}</DisplayText>
              ) : (
                placeHolderComponent
              )}
            </>
          ) : (
            placeHolderComponent
          )}
        </InputFieldContainer>
      </MaybeTrigger>
      {isModal ? (
        <SearchSelectOptionsModal
          isLoading={isLoading}
          isOpen={isOptionListOpen}
          close={handleClose}
          title={label}
          options={options}
          handleSelectionChange={handleSelectionChange}
          emptyOptionsMessage={emptyOptionsMessage}
          currentValue={value}
          filterPage={filterPage}
          activeFilters={activeFilters}
          width={modalWidth}
          height={modalHeight}
          optionItemChildren={optionItemChildren}
          isMulti={isMulti}
          footer={modalFooter}
        />
      ) : (
        <DropdownContent
          Header={
            isAutocomplete ? (
              <Flex $backgroundColor={'grayscale.100'} $p='md' $alignItems='center'>
                <Icons.Search $size='md' />
                <InvisibleTextInput
                  shouldFocusOnMount
                  id={searchInputId}
                  name={searchInputId}
                  autoComplete='on'
                  autoCorrect='off'
                  type='text'
                  value={searchQuery}
                  onChange={(e) => {
                    if (!isNil(e.target)) setSearchQuery(e.target.value)
                  }}
                  placeholder={placeholder}
                />
              </Flex>
            ) : null
          }
        >
          {isLoading ? (
            <Box $py='sm' $px='lg'>
              <LoadingCircle />
            </Box>
          ) : !isEmpty(filteredOptions) ? (
            <>
              {filteredOptions.map((option) => (
                <SelectOptionItem
                  key={`${option.label}-${option.value}`}
                  isMultiChoice={isMulti}
                  isSelected={isSelected(option.value)}
                  isDisabled={!isNil(option.isDisabled) && option.isDisabled}
                  onClick={(e) => {
                    e.stopPropagation()
                    handleSelectionChange(option)
                  }}
                >
                  {option.label}
                </SelectOptionItem>
              ))}
            </>
          ) : (
            <Box $py='lg' $px='lg'>
              <DisplayText $textAlign='center'>
                {!isNil(emptyOptionsMessage)
                  ? emptyOptionsMessage
                  : t('map.no_summaries_matching_search_term', {
                      searchTerm: !isNil(searchQuery) ? searchQuery : ''
                    })}
              </DisplayText>
            </Box>
          )}
        </DropdownContent>
      )}
    </Dropdown>
  )
}

function SelectField<V>({ name, displayedError, ...props }: SelectProps<V>) {
  const [selectField, selectMeta, selectFieldHelpers] = useField({ name, defaultValue: undefined })

  /** Custom error take precedence */
  const finalError = !isNil(displayedError) ? displayedError : selectMeta.error
  const displayedErr = selectMeta.touched ? finalError : null

  return (
    <SelectFieldPrimitive
      name={name}
      value={selectField.value}
      onChange={(newValue) => {
        selectFieldHelpers.setTouched(true)
        selectFieldHelpers.setValue(newValue)
      }}
      displayedError={displayedErr}
      {...props}
    />
  )
}

export default SelectField
