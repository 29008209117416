import { AbsoluteHref } from '@weenat/client/dist/routx/runtime-core'
import { useIntl } from '@weenat/wintl'
import { resolvePathPattern, usePathname } from 'app/routx-router'
import Text from 'app/src/kit/Text'
import { useBreadcrumbsCtx } from 'app/state'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { Fragment, useLayoutEffect, useState } from 'react'
import { useDeepCompareEffect } from 'react-use'
import { styled } from 'styled-components'
import Link, { LinkProps } from './LinkComponent'

type BreadcrumbsContextKey =
  | 'user'
  | 'plot'
  | 'device'
  | 'option'
  | 'org'
  | 'adherentFarm'
  | 'alert'
  | 'station'

export const useSetKeyToBreadcrumbCtx = (key: BreadcrumbsContextKey, newVal?: string) => {
  const [breadcrumbs, setBreadcrumbs] = useBreadcrumbsCtx()
  useLayoutEffect(() => {
    if (!isNil(newVal) && breadcrumbs[key] !== newVal) {
      setBreadcrumbs((currentBreadcrumbsCtx) => ({ ...currentBreadcrumbsCtx, [key]: newVal }))
    } else if (isNil(newVal) && !isNil(breadcrumbs[key])) {
      setBreadcrumbs((currentBreadcrumbsCtx) => {
        const newBreadcrumbs = { ...currentBreadcrumbsCtx }
        delete newBreadcrumbs[key]
        return newBreadcrumbs
      })
    }
  }, [newVal])
}

type BreadcrumbsItemProps = Omit<LinkProps, 'children'> & {
  width: string
}

const BreadcrumbLink = styled(Link)<{ width: string }>`
  max-width: ${({ width }) => `${width}`};

  /* css tricks from https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size this enforce compo to shrink to fit inside parent */
  min-width: 0;
`

const BreadcrumbsItem: FC<BreadcrumbsItemProps> = ({ children, ...props }) => (
  <BreadcrumbLink {...props}>
    <Text $fontSize='sm' $ellipsis>
      {children}
    </Text>
  </BreadcrumbLink>
)

export const Breadcrumbs: FC = () => {
  const { t } = useIntl()
  const [breadcrumbs, setBreadcrumbs] = useState<{ path: AbsoluteHref; label: string }[]>([])
  const pathname = usePathname()
  const [breadcrumbsCtx] = useBreadcrumbsCtx()

  const subtitleCrumbs =
    !isEmpty(breadcrumbs) && breadcrumbs.length > 1 ? [...breadcrumbs].slice(0, -1) : null

  const titleCrumb = !isEmpty(breadcrumbs) && !isNil(breadcrumbs) ? [...breadcrumbs].pop() : null

  useDeepCompareEffect(() => {
    // FIXME: Check but should be normalized before
    const pathPattern = resolvePathPattern(
      pathname.endsWith('/') ? (pathname.slice(0, -1) as AbsoluteHref) : pathname
    )
    const pathnameSplit = pathname.replace('/', '').split('/')

    const currentPathnameBreadcrumbs = pathPattern
      .replace('/', '')
      .split('/')
      .reduce(
        (acc, _, i, pathPatternSplit) => {
          const subPathname = ('/' + pathnameSplit.slice(0, i + 1).join('/')) as AbsoluteHref
          const subPathPattern = ('/' +
            pathPatternSplit.slice(0, i + 1).join('/')) as '/administration'
          const label = t(`breadcrumbs.${subPathPattern}` as const, breadcrumbsCtx)
          if (label && !label.includes('{')) {
            acc.push({ label, path: subPathname })
          }

          return acc
        },
        [] as { path: AbsoluteHref; label: string }[]
      )
    setBreadcrumbs(currentPathnameBreadcrumbs)
  }, [pathname, breadcrumbsCtx])

  return !isNil(breadcrumbs) ? (
    <Box $minWidth={0} $flex={1}>
      {!isNil(subtitleCrumbs) ? (
        <Flex
          $alignItems='center'
          $justifyContent='flex-start'
          $width='100%'
          // To align with the left menu title
          $pt='md'
          $gap='sm'
        >
          {subtitleCrumbs.map((crumb) => (
            <Fragment key={crumb.path}>
              <BreadcrumbsItem to={crumb.path} width={`${(1 / subtitleCrumbs.length) * 100}%`}>
                {crumb.label}
              </BreadcrumbsItem>
              <Text $fontSize='sm' $ellipsis>
                /
              </Text>
            </Fragment>
          ))}
        </Flex>
      ) : null}
      {!isNil(titleCrumb) ? (
        <Text $fontSize='lg' $lineHeight='lg' $fontWeight='medium' $ellipsis>
          {titleCrumb.label}
        </Text>
      ) : null}
    </Box>
  ) : null
}
