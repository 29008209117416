import { createContext, useContext, useReducer } from 'react'

interface RadarState {
  /**
   * The current index on the radar slider
   */
  currentTimestampIndex: number
  /**
   * Whether or not the radar is active on the BackgroundMap
   */
  isEnabled: boolean
  /**
   * Whether or not the radar's data is being loaded
   */
  isLoading: boolean
}

const defaultState: RadarState = {
  isEnabled: false,
  isLoading: false,
  currentTimestampIndex: 0
}

type RadarContextActions =
  | { type: 'setCurrentTimestampIndex'; newIndex: number }
  | { type: 'setIsEnabled'; newValue: boolean }
  | { type: 'setIsLoading'; newValue: boolean }

function throwUnknownRadarAction(p: never): never
function throwUnknownRadarAction(p: RadarContextActions) {
  throw new Error('Unknown action for RadarContext : ' + p.type)
}
interface RadarContext {
  state: RadarState
  dispatch: (action: RadarContextActions) => void
}

const reducer = (state: RadarState, action: RadarContextActions): RadarState => {
  switch (action.type) {
    case 'setCurrentTimestampIndex':
      return {
        ...state,
        currentTimestampIndex: action.newIndex
      }
    case 'setIsEnabled':
      return {
        ...state,
        isEnabled: action.newValue
      }
    case 'setIsLoading':
      return {
        ...state,
        isLoading: action.newValue
      }

    default:
      // Force switch to be complete
      throwUnknownRadarAction(action)
  }
}

const RadarCtx = createContext<RadarContext | undefined>(undefined)

export const RadarContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState)

  return <RadarCtx.Provider value={{ state, dispatch }}>{children}</RadarCtx.Provider>
}

export const useRadarContext = () => {
  const context = useContext(RadarCtx)
  if (!context) {
    throw new Error(
      'You may have forgot to render <RadarContextProvider /> because RadarContext is not defined'
    )
  }
  return context
}
