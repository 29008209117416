import { Head } from 'app/routx-router'
import { HelmetProps } from 'react-helmet-async'

interface HelmetAndBreadcrumbsProps {
  screenTitle: string
  helmetProps?: HelmetProps
}

const HelmetAndBreadcrumbs: FC<HelmetAndBreadcrumbsProps> = ({ screenTitle, helmetProps = {} }) => {
  return (
    <>
      <Head title={screenTitle} {...helmetProps} />
    </>
  )
}

export default HelmetAndBreadcrumbs
