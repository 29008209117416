import { Locale, defaultLanguage, useIntl } from '@weenat/wintl'
import Text from 'app/src/kit/Text'
import { memo } from 'react'
import { styled } from 'styled-components'
import Link from './LinkComponent'
import FullScreenSurface from './fullscreens/FullScreenSurface'
import { LogoFullLight } from './icons/logos'

type BadgeProps = { locale: Locale }

const APP_STORE_BADGE_LOCALES: Record<Locale, string> = {
  en: 'en-us',
  fr: 'fr-fr',
  de: 'de-de',
  it: 'it-it',
  es: 'es-es'
}

const STORE_BUTTON_WIDTH = 196
const STORE_BUTTON_HEIGHT = 96

const GooglePlayButton = styled(Link)<BadgeProps>`
  width: ${STORE_BUTTON_WIDTH}px;
  height: ${STORE_BUTTON_HEIGHT}px;

  background: ${(p) =>
    `url(https://play.google.com/intl/en_us/badges/static/images/badges/${p.locale}_badge_web_generic.png) center / contain no-repeat`};
`

const AppStoreButton = styled(Link)<BadgeProps>`
  width: ${STORE_BUTTON_WIDTH}px;
  height: ${STORE_BUTTON_HEIGHT}px;

  background: ${(p) =>
    `url(https://apple-resources.s3.amazonaws.com/media-badges/download-on-the-app-store/black/${
      APP_STORE_BADGE_LOCALES[p.locale]
    }.svg) center / contain no-repeat`};
`

const StyledText = styled(Text)`
  text-align: center;
  max-width: 640px;
`

const ContentContainer = styled(Flex)`
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;

  padding: 64px;

  overflow-y: scroll;
`

const GradientSurface = styled(FullScreenSurface)`
  background: linear-gradient(223deg, #002635 5%, #015579 50%, #0172a4 100%);
`

const DownloadMobileScreen = () => {
  const { t, locales } = useIntl()
  const locale = locales.current

  return (
    <GradientSurface>
      <ContentContainer>
        <div>
          <Flex $flexDirection='column' $alignItems='center' $justifyContent='center' $gap='xl'>
            <Link href={t('weenat.links.website')} target='_blank'>
              <LogoFullLight width={320} height={64} />
            </Link>
            <StyledText $color={'grayscale.white'} $fontSize='2xl' $fontWeight='regular'>
              {t('titles.install_app_title', {
                live: t('titles.install_app_title_live')
              })}
            </StyledText>
            <StyledText $color={'grayscale.white'} $fontSize='lg' $textAlign='center'>
              {t('titles.install_app_subtitle')}
            </StyledText>
            <Flex $alignItems='center' $gap='md'>
              <GooglePlayButton
                locale={locale || defaultLanguage}
                href={t('weenat.links.stores.google')}
              />
              <AppStoreButton locale={locale} href={t('weenat.links.stores.apple')} />
            </Flex>
          </Flex>
        </div>
      </ContentContainer>
    </GradientSurface>
  )
}

export default memo(DownloadMobileScreen)
