import { useIntl } from '@weenat/wintl'
import { ButtonProps } from 'app/src/kit/Button'
import ButtonCreate from 'app/src/kit/ButtonCreate'

const CreateAlertButton: FC<Pick<ButtonProps, 'onPress'>> = ({ onPress }) => {
  const { t } = useIntl()

  return <ButtonCreate onPress={onPress}>{t('alerts.create')}</ButtonCreate>
}

export default CreateAlertButton
