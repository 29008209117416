import Icons from 'app/src/kit/Icons'
import { useCallback } from 'react'
import { useBackgroundMapContext } from './contexts/BackgroundMapContext'

const ZoomButtons = () => {
  const {
    api: { map }
  } = useBackgroundMapContext()

  const onZoomIn = useCallback(() => {
    const currentZoom = map?.getZoom()
    map?.setZoom(currentZoom + 1)
  }, [map])

  const onZoomOut = useCallback(() => {
    const currentZoom = map?.getZoom()
    map?.setZoom(currentZoom - 1)
  }, [map])

  return (
    <>
      <Icons.MinusSign onPress={onZoomOut} $size={'xl'} $rounded />
      <Icons.PlusSign onPress={onZoomIn} $size={'xl'} $rounded />
    </>
  )
}

export default ZoomButtons
