import { schemas } from '@weenat/client'
import { isFrostAlert } from '@weenat/client/dist/core/alerts'
import useAlertDetails from '@weenat/client/dist/core/alerts/useAlertDetails'
import { Alert } from '@weenat/client/dist/resources/alerts.types'
import { useIntl } from '@weenat/wintl'
import { Head, useNavigate, useParams } from 'app/routx-router'
import AddAlertTargetsModal from 'app/src/alerts/AddAlertTargetsModal'
import AlertLastUpdate from 'app/src/alerts/AlertLastUpdate'
import AlertTargetTable from 'app/src/alerts/AlertTargetTable'
import CreateOrEditPredicate from 'app/src/alerts/CreateOrEditPredicate'
import PredicateTable from 'app/src/alerts/PredicateTable'
import { ErrorComponent } from 'app/src/errors/ErrorScreen'
import useDisclosure from 'app/src/hooks/useDisclosure'
import useGoBack from 'app/src/hooks/useGoBack'
import Button from 'app/src/kit/Button'
import ButtonWithConfirm from 'app/src/kit/ButtonWithConfirm'
import Icons from 'app/src/kit/Icons'
import Scrollable from 'app/src/kit/Scrollable'
import SuperForm from 'app/src/kit/SuperForm'
import Text from 'app/src/kit/Text'
import FadeIn from 'app/src/kit/animations/FadeIn'
import CheckboxField from 'app/src/kit/fields/CheckboxField'
import TextField from 'app/src/kit/fields/TextField'
import ToggleField from 'app/src/kit/fields/ToggleField'
import UseOnChange from 'app/src/kit/fields/UseOnChange'
import LoadingCircle from 'app/src/kit/loaders/LoadingCircle'
import TextTooltip from 'app/src/kit/tooltips/TextTooltip'
import isNil from 'lodash-es/isNil'
import noop from 'lodash-es/noop'
import { useCallback } from 'react'
import { styled } from 'styled-components'

const CenteredBox = styled(Scrollable)`
  position: relative;
  min-width: 820px;
  margin: 0 auto;
  padding: 0 32px 32px;
  border: 1px solid ${(p) => p.theme.colors.grayscale[300]};
  border-radius: ${(p) => p.theme.radiuses.md}px;
  background-color: ${(p) => p.theme.colors.grayscale.white};
`

const Header = styled(Flex)`
  position: sticky;
  top: 0;

  z-index: 3;

  background-color: ${(p) => p.theme.colors.grayscale.white};
  border-bottom: solid 1px ${(p) => p.theme.colors.grayscale[300]};

  justify-content: space-between;
  margin-bottom: ${(p) => p.theme.spacings.lg}px;
  padding-top: ${(p) => p.theme.spacings['2xl']}px;
  padding-bottom: ${(p) => p.theme.spacings.lg}px;
`

const AlertDetails: FC = () => {
  const { t } = useIntl()
  const nav = useNavigate()
  const { goBack } = useGoBack()
  const { alertId } = useParams()

  const {
    alert,
    deleteAlert,
    editedPredicate,
    handleFormChange,
    handlePredicateAddition,
    handlePredicateDeletion,
    handlePredicateEdition,
    handlePredicateEditionValidation,
    handleTargetDeletion,
    handleTargetEditionConfirmation,
    handleUpdate,
    isTouched,
    replaceAlertRequest,
    sources,
    updatedAlert,
    isLoading,
    selectedTzTargets,
    alertRequest
  } = useAlertDetails(alertId)
  const {
    isOpen: isPredicateEditionModalOpen,
    open: openPredicateEditionModal,
    close: closePredicateEditionModal
  } = useDisclosure(false)

  const {
    isOpen: isTargetEditionModalOpen,
    open: openTargetEditionModal,
    close: closeTargetEditionModal
  } = useDisclosure(false)

  const handleDelete = useCallback(
    async () => {
      await deleteAlert()
      nav('/alerts')
    },
    // do not add nav to the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deleteAlert]
  )

  const targetsCount = isNil(selectedTzTargets)
    ? 0
    : selectedTzTargets.devices.length + selectedTzTargets.plots.length
  const isAllowingEdition = !alert?.predicates?.['near-gt-RR']

  const status = alertRequest.error?.status
  return (
    <>
      <Head title={alert ? alert.name : '-'} />
      {!isLoading && alert && updatedAlert ? (
        <FadeIn>
          <CenteredBox>
            {/* Header - Name & Action buttons */}
            <Header>
              <Box>
                <Text $fontWeight='bold' $fontSize='lg'>
                  {alert.name}
                </Text>
                <AlertLastUpdate timestamp={alert.updatedAt} />
              </Box>
              <Flex $alignItems='flex-start'>
                <Button
                  backgroundColor={isTouched ? 'primary.500' : 'grayscale.300'}
                  isDisabled={!isTouched}
                  isLoading={replaceAlertRequest.isPending}
                  isError={replaceAlertRequest.isError}
                  isSuccess={replaceAlertRequest.isSuccess}
                  onPress={() => {
                    handleUpdate()
                  }}
                >
                  {t('actions.save_changes')}
                </Button>
                <Box $mx='md'>
                  <ButtonWithConfirm importance='sd' onPress={handleDelete} isDanger>
                    {t('alerts.delete', { capitalize: true })}
                  </ButtonWithConfirm>
                </Box>
                <TextTooltip content={t('actions.go_back')}>
                  <Icons.Close $p='sm' $size='lg' onPress={goBack} />
                </TextTooltip>
              </Flex>
            </Header>

            {/* Name & voice call edition */}
            <Text $fontWeight='bold' $fontSize='md'>
              {t('titles.general_infos')}
            </Text>

            <Box $my='lg'>
              <SuperForm
                schema={schemas.alertCreation.validation}
                initialValues={{
                  isActive: isNil(alert) ? true : alert.isActive,
                  name: alert?.name || '',
                  isVoiceCallEnabled: alert.isVoiceCallEnabled || false
                }}
                onSubmit={noop}
              >
                {({ isValid, values: formValues }) => {
                  return (
                    <>
                      <UseOnChange
                        onChange={(values) => {
                          const newAlert: Alert = {
                            ...updatedAlert,
                            isActive: values.isActive as boolean,
                            name: values.name as string,
                            isVoiceCallEnabled: values.isVoiceCallEnabled as boolean
                          }
                          handleFormChange({ newAlert, isValid })
                        }}
                      />
                      <TextField
                        label={t('models.plot.fields.name.label', { capitalize: true })}
                        name='name'
                      />
                      {isFrostAlert(updatedAlert.predicates) && (
                        <CheckboxField
                          label={t('alerts.paramSetups.fieldLabels.enable_voice_call')}
                          name='isVoiceCallEnabled'
                        />
                      )}
                      <ToggleField
                        name='isActive'
                        label={
                          formValues.isActive === true
                            ? t('alerts.activated', { capitalize: true })
                            : t('alerts.deactivated', { capitalize: true })
                        }
                      />
                    </>
                  )
                }}
              </SuperForm>
            </Box>
            {/* Predicate List */}
            <Text $fontWeight='bold' $fontSize='md'>
              {t('alerts.validation.events', { capitalize: true })}
            </Text>
            <Box $my='lg'>
              <PredicateTable
                isAllowingEdition={isAllowingEdition}
                predicates={updatedAlert.predicates}
                onPredicateEdition={(predicate) => {
                  handlePredicateEdition(predicate)
                  openPredicateEditionModal()
                }}
                onPredicateDeletion={handlePredicateDeletion}
                onPredicateAddition={() => {
                  handlePredicateAddition()
                  openPredicateEditionModal()
                }}
              />
            </Box>

            {/* Target List */}
            <Text $fontWeight='bold' $fontSize='md'>{`${t('alerts.validation.targets', {
              capitalize: true
            })} ${targetsCount !== 0 ? `(${targetsCount})` : ''}`}</Text>
            {selectedTzTargets && (
              <Box $py='lg'>
                <AlertTargetTable
                  alert={alert}
                  sources={sources}
                  targets={selectedTzTargets}
                  onTargetAddition={() => {
                    openTargetEditionModal()
                  }}
                  onTargetDeletion={handleTargetDeletion}
                />
              </Box>
            )}
          </CenteredBox>

          {isPredicateEditionModalOpen && (
            <CreateOrEditPredicate
              alert={alert}
              isOpen={isPredicateEditionModalOpen}
              close={closePredicateEditionModal}
              initialPredicate={editedPredicate}
              disabledSources={sources}
              onValidation={(finalPredicate) => {
                handlePredicateEditionValidation(finalPredicate)
                closePredicateEditionModal()
              }}
            />
          )}

          {isTargetEditionModalOpen && selectedTzTargets && (
            <AddAlertTargetsModal
              isOpen={isTargetEditionModalOpen}
              close={closeTargetEditionModal}
              initialTargets={selectedTzTargets}
              sources={sources}
              alertName={alert.name}
              onConfirm={handleTargetEditionConfirmation}
            />
          )}
        </FadeIn>
      ) : isLoading ? (
        <LoadingCircle />
      ) : alertRequest.isError && !isNil(status) ? (
        <ErrorComponent status={status} />
      ) : null}
    </>
  )
}

export const Component = AlertDetails
export default AlertDetails
