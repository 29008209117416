import { schemas } from '@weenat/client'
import { useOrderedFields } from '@weenat/client/dist/core'
import { useBillingAccountEdition } from '@weenat/client/dist/core/billing'
import { PossibleError } from '@weenat/client/dist/errors'
import { BillingAccount } from '@weenat/client/dist/resources/billing'
import { useIntl } from '@weenat/wintl'
import { useParams } from 'app/routx-router'
import useToasts from 'app/src/hooks/useToasts'
import DelimitedBox from 'app/src/kit/DelimitedBox'
import SubmitButton from 'app/src/kit/SubmitButton'
import SuperForm from 'app/src/kit/SuperForm'
import AddressLine1Field from 'app/src/kit/fields/AddressLine1Field'
import AddressLine2Field from 'app/src/kit/fields/AddressLine2Field'
import CityField from 'app/src/kit/fields/CityField'
import CountryPickerField from 'app/src/kit/fields/CountryPickerField'
import OrgNameField from 'app/src/kit/fields/OrgNameField'
import SiretField from 'app/src/kit/fields/SiretField'
import UseOnChange from 'app/src/kit/fields/UseOnChange'
import VATNumberField from 'app/src/kit/fields/VATNumberField'
import ZipCodeField from 'app/src/kit/fields/ZipCodeField'
import { FormikProps } from 'formik'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { useCallback, useMemo, useRef } from 'react'
import ContentLoader from 'react-content-loader'
import { styled } from 'styled-components'

const schema = schemas.billing.editBillingAccount

type EditBillingAccountFormValues = typeof schema.initialValues
type EditBillingAccountFieldName = keyof EditBillingAccountFormValues

const orderedFields: EditBillingAccountFieldName[] = [
  'organizationName',
  'country',
  'addressLine1',
  'addressLine2',
  'zipCode',
  'city',
  'siret',
  'vatNumber'
]

const StyledSuperForm = styled(SuperForm)`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const EditBillingAccountFields = () => {
  const fieldProps = useOrderedFields<EditBillingAccountFieldName>(orderedFields)

  return (
    <>
      <OrgNameField {...fieldProps.organizationName} isRequired />
      <CountryPickerField {...fieldProps.country} isRequired />
      <AddressLine1Field {...fieldProps.addressLine1} isRequired />
      <AddressLine2Field {...fieldProps.addressLine2} isRequired />
      <Flex $gap={'lg'}>
        <CityField {...fieldProps.city} isRequired />
        <ZipCodeField {...fieldProps.zipCode} isRequired />
      </Flex>
      <DelimitedBox $isDelimitedOnTop $mt='md' $pt='lg'>
        <SiretField {...fieldProps.siret} isRequired />
        <VATNumberField {...fieldProps.vatNumber} isRequired />
      </DelimitedBox>
    </>
  )
}

interface EditBillingAccountFormProps {
  organizationId: number
  onSuccess?: () => void
  /** Whether or not to hide the submit button - defaults to false */
  hideSubmit?: boolean
  /** Callback when form values are updated - defaults to false */
  onChange?: (values: EditBillingAccountFormValues) => void
}

/**
 * TODO: Add custom errors for each fields & success messages
 */
const EditBillingAccountForm: FC<EditBillingAccountFormProps> = ({
  onSuccess,
  organizationId: organizationIdFromProps,
  hideSubmit = false,
  onChange
}) => {
  const { t } = useIntl()
  const { addSuccessToast, addErrorToast } = useToasts()
  const { orgId: organizationIdFromRouteParams } = useParams()

  const organizationId = !isNil(organizationIdFromProps)
    ? organizationIdFromProps
    : organizationIdFromRouteParams

  const formRef = useRef<FormikProps<EditBillingAccountFormValues>>(null)

  const isEmptyMsg = t('forms.field_is_empty')

  const onFieldErrors = useCallback(
    (errors: PossibleError) => {
      if (!isNil(errors) && !isNil(errors.parsedErrors) && !isNil(formRef.current)) {
        const { values, setFieldError } = formRef.current

        /**
         * keys will be backend formatted
         * fields name should frontend key format
         */
        Object.keys(errors.parsedErrors).forEach((k) => {
          if (k === 'name' && isEmpty(values.organizationName)) {
            setFieldError('organizationName', isEmptyMsg)
          } else if (k === 'siret') {
            if (isEmpty(values.siret)) {
              setFieldError('siret', isEmptyMsg)
            } else if (
              errors.parsedErrors &&
              'siret' in errors.parsedErrors &&
              Array.isArray(errors.parsedErrors.siret)
            ) {
              setFieldError('siret', errors.parsedErrors.siret[0].message)
            }
          } else if (k === 'address_line_1' && isEmpty(values.addressLine1)) {
            setFieldError('addressLine1', isEmptyMsg)
          } else if (k === 'address_zip' && isEmpty(values.zipCode)) {
            setFieldError('zipCode', isEmptyMsg)
          } else if (k === 'city' && isEmpty(values.city)) {
            setFieldError('city', isEmptyMsg)
          } else if (k === 'vat_number') {
            if (isEmpty(values.vatNumber)) {
              setFieldError('vatNumber', isEmptyMsg)
            } else if (
              !isNil(errors.parsedErrors) &&
              'vat_number' in errors.parsedErrors &&
              Array.isArray(errors.parsedErrors.vat_number)
            ) {
              setFieldError('vatNumber', t('error_codes.220_01'))
            }
          }
        })
      }
    },
    [isEmptyMsg, t]
  )

  const {
    editBillingAccount,
    editBillingAccountRequest,
    billingAccount: initialBillingAccount,
    getBillingAccountRequest
  } = useBillingAccountEdition({
    organizationId,
    onError: (e) => {
      addErrorToast(e)
    },
    onFieldErrors,
    onSuccess: () => {
      addSuccessToast(t('actions.edit_success'))
      if (onSuccess) onSuccess()
    }
  })

  const handleSubmit = useCallback(
    ({
      addressLine1,
      addressLine2,
      city,
      country,
      organizationName,
      siret,
      zipCode,
      vatNumber
    }: Partial<BillingAccount>) => {
      editBillingAccount({
        addressLine1,
        addressLine2,
        city,
        country,
        organizationName,
        siret,
        zipCode,
        vatNumber
      })
    },
    [editBillingAccount]
  )

  const initialValues = useMemo(() => {
    return {
      ...(initialBillingAccount || {}),
      country: initialBillingAccount?.country ?? 'FR',
      vatNumber: initialBillingAccount?.vatNumber ?? undefined
    }
  }, [initialBillingAccount])

  return !getBillingAccountRequest.isLoading ? (
    <StyledSuperForm
      schema={schema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      formRef={formRef}
      enableReinitialize
    >
      <EditBillingAccountFields />
      {!isNil(onChange) ? <UseOnChange onChange={onChange} /> : null}

      {hideSubmit ? null : (
        <Flex $alignItems='center' $justifyContent='flex-end'>
          <SubmitButton topPadded state={editBillingAccountRequest}>
            {t('actions.confirm')}
          </SubmitButton>
        </Flex>
      )}
    </StyledSuperForm>
  ) : (
    <ContentLoader uniqueKey='EditBillingAccountForm' />
  )
}
export default EditBillingAccountForm
