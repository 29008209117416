import Icon, { IconProps } from '../Icon'
import WiCloudy from './weather-icons/WiCloudy'
import WiCloudyGusts from './weather-icons/WiCloudyGusts'
import WiDayCloudy from './weather-icons/WiDayCloudy'
import WiDayCloudyGusts from './weather-icons/WiDayCloudyGusts'
import WiDayPartlyCloudy from './weather-icons/WiDayPartlyCloudy'
import WiDayRain from './weather-icons/WiDayRain'
import WiDayShowers from './weather-icons/WiDayShowers'
import WiDaySnow from './weather-icons/WiDaySnow'
import WiDayStormShowers from './weather-icons/WiDayStormShowers'
import WiDaySunny from './weather-icons/WiDaySunny'
import WiDayThunderstorm from './weather-icons/WiDayThunderstorm'
import WiDayWindy from './weather-icons/WiDayWindy'
import WiFog from './weather-icons/WiFog'
import WiHail from './weather-icons/WiHail'
import WiHot from './weather-icons/WiHot'
import WiHurricane from './weather-icons/WiHurricane'
import WiNightAltShowers from './weather-icons/WiNightAltShowers'
import WiNightAltSnow from './weather-icons/WiNightAltSnow'
import WiNightClear from './weather-icons/WiNightClear'
import WiNightCloudy from './weather-icons/WiNightCloudy'
import WiNightPartlyCloudy from './weather-icons/WiNightPartlyCloudy'
import WiNightStormShowers from './weather-icons/WiNightStormShowers'
import WiRain from './weather-icons/WiRain'
import WiRainMix from './weather-icons/WiRainMix'
import WiSandstorm from './weather-icons/WiSandstorm'
import WiShowers from './weather-icons/WiShowers'
import WiSleet from './weather-icons/WiSleet'
import WiSmoke from './weather-icons/WiSmoke'
import WiSnow from './weather-icons/WiSnow'
import WiSnowflakeCold from './weather-icons/WiSnowflakeCold'
import WiSnowWind from './weather-icons/WiSnowWind'
import WiSprinkle from './weather-icons/WiSprinkle'
import WiStrongWind from './weather-icons/WiStrongWind'
import WiThunderstorm from './weather-icons/WiThunderstorm'
import WiTornado from './weather-icons/WiTornado'

interface WeatherIconProps extends IconProps {
  svgIcon: React.ReactElement
}

const WeatherIcon: FC<WeatherIconProps> = ({ svgIcon, ...props }) => (
  <Icon {...props}>{svgIcon}</Icon>
)

/**
 * @description Object mapping keys from The Weather Company's or Weather Measures' icon codes to Icons.
 * @link https://docs.google.com/document/d/1qpc4QN3YDpGDGGNYVINh7tfeulcZ4fxPSC5f4KzpR_U/edit
 * @example WeatherIcons["tornado"]
 */
const WeatherIcons: { [index: string]: typeof Icon } = {
  tornado: (props: IconProps) => <WeatherIcon svgIcon={<WiTornado />} {...props} />,
  tropicalStorm: (props: IconProps) => <WeatherIcon svgIcon={<WiHurricane />} {...props} />,
  hurricane: (props: IconProps) => <WeatherIcon svgIcon={<WiHurricane />} {...props} />,
  strongStorms: (props: IconProps) => <WeatherIcon svgIcon={<WiThunderstorm />} {...props} />,
  thunderstorms: (props: IconProps) => <WeatherIcon svgIcon={<WiThunderstorm />} {...props} />,
  rainSnow: (props: IconProps) => <WeatherIcon svgIcon={<WiRainMix />} {...props} />,
  rainSleet: (props: IconProps) => <WeatherIcon svgIcon={<WiRainMix />} {...props} />,
  wintryMix: (props: IconProps) => <WeatherIcon svgIcon={<WiRainMix />} {...props} />,
  freezingDrizzle: (props: IconProps) => <WeatherIcon svgIcon={<WiRainMix />} {...props} />,
  drizzle: (props: IconProps) => <WeatherIcon svgIcon={<WiSprinkle />} {...props} />,
  freezingRain: (props: IconProps) => <WeatherIcon svgIcon={<WiRainMix />} {...props} />,
  showers: (props: IconProps) => <WeatherIcon svgIcon={<WiShowers />} {...props} />,
  rain: (props: IconProps) => <WeatherIcon svgIcon={<WiRain />} {...props} />,
  flurries: (props: IconProps) => <WeatherIcon svgIcon={<WiSnow />} {...props} />,
  snowShowers: (props: IconProps) => <WeatherIcon svgIcon={<WiSnow />} {...props} />,
  blowingDriftingSnow: (props: IconProps) => <WeatherIcon svgIcon={<WiSnowWind />} {...props} />,
  snow: (props: IconProps) => <WeatherIcon svgIcon={<WiSnow />} {...props} />,
  hail: (props: IconProps) => <WeatherIcon svgIcon={<WiHail />} {...props} />,
  sleet: (props: IconProps) => <WeatherIcon svgIcon={<WiSleet />} {...props} />,
  blowingDustSandstorm: (props: IconProps) => <WeatherIcon svgIcon={<WiSandstorm />} {...props} />,
  foggy: (props: IconProps) => <WeatherIcon svgIcon={<WiFog />} {...props} />,
  haze: (props: IconProps) => <WeatherIcon svgIcon={<WiFog />} {...props} />,
  smoke: (props: IconProps) => <WeatherIcon svgIcon={<WiSmoke />} {...props} />,
  windy: (props: IconProps) => <WeatherIcon svgIcon={<WiStrongWind />} {...props} />,
  frigidIceCrystal: (props: IconProps) => <WeatherIcon svgIcon={<WiSnowWind />} {...props} />,
  cloudy: (props: IconProps) => <WeatherIcon svgIcon={<WiCloudy />} {...props} />,
  mostlyCloudyDay: (props: IconProps) => <WeatherIcon svgIcon={<WiDayCloudy />} {...props} />,
  mostlyCloudyNight: (props: IconProps) => <WeatherIcon svgIcon={<WiNightCloudy />} {...props} />,
  partlyCloudyNight: (props: IconProps) => (
    <WeatherIcon svgIcon={<WiDayPartlyCloudy />} {...props} />
  ),
  partlyCloudyDay: (props: IconProps) => (
    <WeatherIcon svgIcon={<WiNightPartlyCloudy />} {...props} />
  ),
  clear: (props: IconProps) => <WeatherIcon svgIcon={<WiNightClear />} {...props} />,
  sunny: (props: IconProps) => <WeatherIcon svgIcon={<WiDaySunny />} {...props} />,
  fairMostlyClear: (props: IconProps) => (
    <WeatherIcon svgIcon={<WiNightPartlyCloudy />} {...props} />
  ),
  fairMostlySunny: (props: IconProps) => <WeatherIcon svgIcon={<WiDayPartlyCloudy />} {...props} />,
  mixedRainAndHail: (props: IconProps) => <WeatherIcon svgIcon={<WiRainMix />} {...props} />,
  hot: (props: IconProps) => <WeatherIcon svgIcon={<WiHot />} {...props} />,
  isolatedThunderstorms: (props: IconProps) => (
    <WeatherIcon svgIcon={<WiDayStormShowers />} {...props} />
  ),
  scatteredThunderstormsDay: (props: IconProps) => (
    <WeatherIcon svgIcon={<WiDayThunderstorm />} {...props} />
  ),
  scatteredShowersDay: (props: IconProps) => <WeatherIcon svgIcon={<WiDayShowers />} {...props} />,
  heavyRain: (props: IconProps) => <WeatherIcon svgIcon={<WiRain />} {...props} />,
  scatteredSnowShowersDay: (props: IconProps) => <WeatherIcon svgIcon={<WiDaySnow />} {...props} />,
  heavySnow: (props: IconProps) => <WeatherIcon svgIcon={<WiSnowflakeCold />} {...props} />,
  blizzard: (props: IconProps) => <WeatherIcon svgIcon={<WiSnowflakeCold />} {...props} />,
  notAvailable: (props: IconProps) => <WeatherIcon svgIcon={null} {...props} />,
  scatteredShowersNight: (props: IconProps) => (
    <WeatherIcon svgIcon={<WiNightAltShowers />} {...props} />
  ),
  scatteredSnowShowersNight: (props: IconProps) => (
    <WeatherIcon svgIcon={<WiNightAltSnow />} {...props} />
  ),
  scatteredThunderstormsNight: (props: IconProps) => (
    <WeatherIcon svgIcon={<WiNightStormShowers />} {...props} />
  ),
  wmCloudyGusts: (props: IconProps) => <WeatherIcon svgIcon={<WiCloudyGusts />} {...props} />,
  wmDayCloudy: (props: IconProps) => <WeatherIcon svgIcon={<WiDayCloudy />} {...props} />,
  wmDayCloudyGusts: (props: IconProps) => <WeatherIcon svgIcon={<WiDayCloudyGusts />} {...props} />,
  wmDayRain: (props: IconProps) => <WeatherIcon svgIcon={<WiDayRain />} {...props} />,
  wmDaySunny: (props: IconProps) => <WeatherIcon svgIcon={<WiDaySunny />} {...props} />,
  wmCloudy: (props: IconProps) => <WeatherIcon svgIcon={<WiCloudy />} {...props} />,
  wmRainMix: (props: IconProps) => <WeatherIcon svgIcon={<WiRainMix />} {...props} />,
  wmRain: (props: IconProps) => <WeatherIcon svgIcon={<WiRain />} {...props} />,
  wmShowers: (props: IconProps) => <WeatherIcon svgIcon={<WiShowers />} {...props} />,
  wmSnow: (props: IconProps) => <WeatherIcon svgIcon={<WiSnow />} {...props} />,
  wmHot: (props: IconProps) => <WeatherIcon svgIcon={<WiHot />} {...props} />,
  wmDayWindy: (props: IconProps) => <WeatherIcon svgIcon={<WiDayWindy />} {...props} />,
  wmSleet: (props: IconProps) => <WeatherIcon svgIcon={<WiSleet />} {...props} />
}

export default WeatherIcons
