import { mergeMutationState, useClient, useMutation } from '@weenat/client'
import { PhoneNumber } from '@weenat/client/dist/resources/me'
import { useIntl } from '@weenat/wintl'
import useToasts from 'app/src/hooks/useToasts'
import FieldLabel from 'app/src/kit/fields/FieldLabel'
import { TelephoneFieldsPrimitive } from 'app/src/kit/fields/TelephoneFields'
import { TogglePrimitive } from 'app/src/kit/fields/ToggleField'
import isNil from 'lodash-es/isNil'
import { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import Icons from '../kit/Icons'

const PhoneFieldContainer = styled(Box)`
  margin-top: ${(p) => p.theme.spacings.lg}px;
  padding-bottom: ${(p) => p.theme.spacings.lg}px;
  border-bottom-width: 1px;
  border-bottom-color: ${(p) => p.theme.colors.grayscale[100]};
`

interface SecondaryPhoneNumberItemProps {
  phone: PhoneNumber
  onNumberChange: (phoneId: number, newCountryCode: PhoneNumber['number']) => void
  onCountryChange: (phoneId: number, newNumber: PhoneNumber['countryCode']) => void
  position: number
  error?: string
  label?: string
  disableDeletion?: boolean
  disableActivation?: boolean
}

const SecondaryPhoneNumberItem: FC<SecondaryPhoneNumberItemProps> = ({
  phone,
  onNumberChange,
  onCountryChange,
  position,
  error,
  label,
  disableDeletion = false,
  disableActivation = false
}) => {
  const { t } = useIntl()
  const { addSuccessToast, addErrorToast } = useToasts()
  const client = useClient()

  const [country, setCountry] = useState<PhoneNumber['countryCode']>(phone.countryCode)
  const [number, setNumber] = useState<PhoneNumber['number']>(phone.number)

  const [deletePhone, deletePhoneRequest] = useMutation(client.me.phoneNumbers.delete(phone.id), {
    onSuccess: () => {
      addSuccessToast(
        t('user_settings.phone_numbers.number_deletion_success', { phoneNumber: phone.number })
      )
    },
    onError: () => {
      addErrorToast(
        t('user_settings.phone_numbers.number_deletion_error', { phoneNumber: phone.number })
      )
    }
  })

  const [enablePhone, enablePhoneRequest] = useMutation(client.me.phoneNumbers.enable(phone.id), {
    onSuccess: () => {
      addSuccessToast(
        t(
          phone.isEnabled
            ? 'user_settings.phone_numbers.number_deactivation_success'
            : 'user_settings.phone_numbers.number_activation_success',
          { phoneNumber: phone.number }
        )
      )
    },
    onError: () => {
      addErrorToast(
        t(
          phone.isEnabled
            ? 'user_settings.phone_numbers.number_deactivation_error'
            : 'user_settings.phone_numbers.number_activation_error',
          { phoneNumber: phone.number }
        )
      )
    }
  })

  const handlePhoneActivation = (newValue: boolean) => {
    enablePhone({ isEnabled: newValue })
  }

  const handlePhoneDeletion = () => {
    deletePhone()
  }

  const { isPending: isLoading } = mergeMutationState(deletePhoneRequest, enablePhoneRequest)

  useEffect(() => {
    onCountryChange(phone.id, country)
  }, [country])

  useEffect(() => {
    onNumberChange(phone.id, number)
  }, [number])

  return (
    <PhoneFieldContainer>
      {isNil(label) ? (
        <FieldLabel
          isFieldRequired
          label={t(
            'user_settings.phone_numbers.secondary_phone_field_label',
            { number: position.toString() },
            { capitalize: true }
          )}
        />
      ) : null}
      <Flex $width='100%' $alignItems='center' $mb='md'>
        <Box $flex={1}>
          <TelephoneFieldsPrimitive
            hideLabel={isNil(label)}
            label={label}
            isLoading={isLoading}
            countryValue={country ?? 'FR'}
            onCountrySelect={(newCountry) => setCountry(newCountry)}
            value={number}
            onChange={(newNumber) => setNumber(newNumber)}
            $displayedError={error}
          />
        </Box>
        {disableDeletion ? null : (
          <Icons.Bin $p='sm' $ml='md' $size='lg' onPress={() => handlePhoneDeletion()} />
        )}
      </Flex>
      {disableActivation ? null : (
        <TogglePrimitive
          showIcons
          label={t(phone.isEnabled ? 'status.activated' : 'status.deactivated', {
            capitalize: true
          })}
          onToggle={(value) => handlePhoneActivation(!value)}
          value={phone.isEnabled}
        />
      )}
    </PhoneFieldContainer>
  )
}

export default SecondaryPhoneNumberItem
