import { PossibleError } from '@weenat/client/dist/errors'
import { useIntl } from '@weenat/wintl'
import { useField } from 'formik'
import isEmpty from 'lodash-es/isEmpty'
import { useEffect } from 'react'
import isNil from 'lodash-es/isNil'

const useBackendFieldError = ({
  name,
  errorPath,
  errorState
}: {
  name: string
  errorPath: string
  errorState?: PossibleError
}) => {
  const { formatErrorMessage, t } = useIntl()
  const [, , fieldHelpers] = useField(name)

  const allFieldErrors = errorState?.fieldErrors
  const fieldErrors = allFieldErrors && allFieldErrors[errorPath]
  const [firstFieldError] = Array.isArray(fieldErrors) ? fieldErrors : []

  useEffect(() => {
    if (!isNil(firstFieldError)) {
      if (!isNil(firstFieldError.code) && !isEmpty(t(`error_codes.${firstFieldError.code}`))) {
        fieldHelpers.setError(t(`error_codes.${firstFieldError.code as '110'}`))
      } else {
        fieldHelpers.setError(formatErrorMessage({ error: firstFieldError }))
      }
    }
  }, [fieldErrors])
}

export default useBackendFieldError
