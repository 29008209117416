import { HTMLProps } from 'react'
import styled from 'styled-components'

const PressableZone = styled.div`
  position: relative;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    background-color: transparent;
    inset: 0 4px;
    border-radius: ${(p) => p.theme.radiuses.sm}px;
  }

  &:hover::after {
    background-color: ${(p) => p.theme.colors.primary[200]};
  }
`

interface MenuItemProps {
  onPress: HTMLProps<HTMLDivElement>['onClick']
  className?: HTMLProps<HTMLDivElement>['className']
}

const MenuItem: FC<MenuItemProps> = ({ onPress, children, className }) => {
  return (
    <PressableZone role='button' onClick={onPress} className={className}>
      {children}
    </PressableZone>
  )
}

export default MenuItem
