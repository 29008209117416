import { Slot, useNavigate, useSearchParams } from 'app/routx-router'
import useGoBack from 'app/src/hooks/useGoBack'
import GoBackButton from 'app/src/kit/GoBackButton'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import { useTheme } from 'styled-components'

export const settings = {
  public: 'always'
}

interface DiscoverMenuLayoutProps {
  isHome?: boolean
  title?: string
}

const DiscoverMenuLayout: FC<DiscoverMenuLayoutProps> = ({ isHome = false, title }) => {
  const { colors } = useTheme()
  const [searchParams] = useSearchParams()
  const { goBack } = useGoBack()
  const nav = useNavigate()

  const handlePress = () => {
    if ('origin' in searchParams && !isNil(searchParams.origin)) {
      nav(searchParams.origin)
    } else {
      goBack()
    }
  }

  const contentColor = colors.grayscale.black

  return (
    <Flex
      $flexDirection='column'
      $zIndex={0}
      $width='100%'
      $height='100%'
      $backgroundColor='grayscale.50'
    >
      <Flex $flexDirection='column' $flex={1} $maxWidth={980} $my='lg' $mx='auto'>
        {/** Header */}
        <Flex $alignItems='center' $py='lg'>
          <Flex $alignItems='center' $justifyContent={isHome ? 'center' : 'start'} $flex={1}>
            {isHome ? null : <GoBackButton mr='md' onPress={handlePress} />}
            {!isNil(title) ? (
              <Text $fontSize='lg' $fontWeight='bold' $color={contentColor}>
                {title}
              </Text>
            ) : null}
          </Flex>
        </Flex>
        <Slot />
      </Flex>
    </Flex>
  )
}
export const Component = DiscoverMenuLayout
export default DiscoverMenuLayout
