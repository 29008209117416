import { useIntl } from '@weenat/wintl'
import Text from 'app/src/kit/Text'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { useState } from 'react'
import { styled, useTheme } from 'styled-components'

const strengthMeterRegexArr = [/[a-z]/, /[A-Z]/, /[0-9]/, /[^A-Za-z0-9]/]

const MAX_BAR_WIDTH = 200
const BAR_HEIGHT = 12

const STRENGTH_MESSAGES = [
  'passwordStrengthMeter.tooShort',
  'passwordStrengthMeter.weak',
  'passwordStrengthMeter.normal',
  'passwordStrengthMeter.strong',
  'passwordStrengthMeter.secure'
] as const

const BackgroundBar = styled.div`
  position: relative;
  width: ${MAX_BAR_WIDTH}px;
  height: ${BAR_HEIGHT}px;
  border-radius: ${(p) => p.theme.radiuses.md}px;
  background-color: ${(p) => p.theme.colors.grayscale[100]};
`

const MovingBar = styled.div<{ $backgroundColor?: string; $currentWidth: number }>`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${(p) => (!isNil(p.$backgroundColor) ? p.$backgroundColor : 'transparent')};
  width: ${(p) => p.$currentWidth}px;
  max-width: ${MAX_BAR_WIDTH}px;
  height: ${BAR_HEIGHT}px;
  border-radius: ${(p) => p.theme.radiuses.md}px;
  will-change: width;
  transition: width 0.3s cubic-bezier(0.21, 0.95, 0.62, 0);
`

interface StrengthMeterProps {
  password?: string
  maxLength: number
  minLength: number
}

const StrengthMeter: FC<StrengthMeterProps> = ({ password, maxLength, minLength }) => {
  const { t } = useIntl()
  const { colors } = useTheme()

  const labels = STRENGTH_MESSAGES.map((x) => t(x))
  const passwordLength = isNil(password) || isEmpty(password) ? 0 : password.length

  const [strengthScore, setStrengthScore] = useState(0)
  const barWidth = MAX_BAR_WIDTH * (passwordLength / maxLength)

  const pwdStrengthColor = [
    colors.data.danger,
    colors.data.bad,
    colors.data.warn,
    colors.data.perfect,
    colors.feedback.success['500']
  ]

  const interpolateColor = pwdStrengthColor[strengthScore]

  if (isNil(password) || isEmpty(password) || passwordLength < minLength) {
    if (strengthScore !== 0) setStrengthScore(0)
  } else {
    let newScore = 0

    strengthMeterRegexArr.forEach((rgx) => {
      if (rgx.test(password)) newScore += 1
    })

    if (newScore !== strengthScore) {
      setStrengthScore(newScore)
    }
  }

  return (
    <Flex $gap={'sm'}>
      {!isEmpty(password) ? (
        <Text $fontSize='sm' $color={interpolateColor}>
          {labels[strengthScore]}
        </Text>
      ) : null}
      <Flex $alignItems='center'>
        <BackgroundBar>
          <MovingBar $backgroundColor={interpolateColor} $currentWidth={barWidth} />
        </BackgroundBar>
      </Flex>
    </Flex>
  )
}

export default StrengthMeter
