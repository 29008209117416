import useDisclosure from 'app/src/hooks/useDisclosure'
import isNil from 'lodash-es/isNil'
import { ReactElement, cloneElement, isValidElement } from 'react'
import Button, { ButtonProps } from './Button'
import ConfirmationModal, { ConfirmationModalProps } from './ConfirmationModal'

type ButtonWithConfirmProps = Pick<
  ConfirmationModalProps,
  'confirmationMessage' | 'title' | 'kind'
> &
  ButtonProps & {
    onClick?: (e: unknown) => void
    onPress?: (e: unknown) => void
  }

const ButtonWithConfirm: FC<ButtonWithConfirmProps> = ({
  confirmationMessage,
  onClick,
  onPress,
  title,
  kind,
  ...buttonProps
}) => {
  const { isOpen, close, open } = useDisclosure()

  return (
    <>
      <ConfirmationModal
        kind={kind}
        title={title}
        confirmationMessage={confirmationMessage}
        isOpen={isOpen}
        onCancel={close}
        onConfirm={(e) => {
          close()
          onClick?.(e)
          onPress?.(e)
        }}
      />
      <Button onPress={open} {...buttonProps} />
    </>
  )
}

export type ClientButtonWithConfirmProps = ButtonProps &
  ButtonWithConfirmProps &
  Pick<ConfirmationModalProps, 'confirmButton' | 'zIndex'> & {
    /** This is used to not default on on the rendered button but instead inject the onPress: close to the cloned custom element */
    customChildren?: ReactElement
  }

export const ClientButtonWithConfirm: FC<ClientButtonWithConfirmProps> = ({
  confirmationMessage,
  onClick,
  onPress,
  title,
  kind,
  confirmButton,
  customChildren,
  zIndex,
  ...buttonProps
}) => {
  const { isOpen, close, open } = useDisclosure()
  const handleConfirm = (e: unknown) => {
    onClick?.(e)
    onPress?.(e)
    close()
  }
  return (
    <>
      <ConfirmationModal
        isDisabled={buttonProps.isDisabled}
        kind={kind}
        title={title}
        confirmationMessage={confirmationMessage}
        isOpen={isOpen}
        confirmButton={
          isValidElement(confirmButton)
            ? cloneElement(confirmButton, {
                // @ts-expect-error handleConfirm is cloned to trigger the close
                onPress: handleConfirm
              })
            : confirmButton
        }
        onCancel={close}
        onConfirm={handleConfirm}
        zIndex={zIndex}
      />
      {isNil(customChildren) ? (
        <Button onPress={open} {...buttonProps} />
      ) : (
        cloneElement(customChildren, { onPress: open })
      )}
    </>
  )
}

export default ButtonWithConfirm
