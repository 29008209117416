import {
  availableAmountsOfHours,
  availableRainIntensityThresholds,
  getMessagePerRainIntensityRange
} from '@weenat/client/dist/core/alerts/alerts'
import useSetupPredicateParams from '@weenat/client/dist/core/alerts/useSetupPredicateParams'
import {
  Predicate,
  alertCompatibleAppleScabLevels
} from '@weenat/client/dist/resources/alerts.types'
import { thresholdTypes } from '@weenat/client/dist/resources/thresholds.types'
import { useIntl } from '@weenat/wintl'
import SuperForm from 'app/src/kit/SuperForm'
import Text from 'app/src/kit/Text'
import CheckboxField from 'app/src/kit/fields/CheckboxField'
import ChipGroupField from 'app/src/kit/fields/ChipGroupField'
import DateTimePickerField from 'app/src/kit/fields/DateTimePickerField'
import NumberField, { PositiveNumberField } from 'app/src/kit/fields/NumberField'
import SelectField from 'app/src/kit/fields/SelectField'
import SliderField from 'app/src/kit/fields/SliderField'
import UseOnChange from 'app/src/kit/fields/UseOnChange'
import isDate from 'lodash-es/isDate'
import noop from 'lodash-es/noop'
import moment from 'moment-timezone'
import Icons from '../kit/Icons'
import AlertCreationStep, { AlertCreationStepProps } from './AlertCreationStep'

const CAPITALIZE = true

type ParamsStepProps = AlertCreationStepProps<Predicate['params']>

interface PickPredicateSourceProps extends ParamsStepProps {
  predicateToSetup: Predicate
}

const SetupPredicateParams: FC<PickPredicateSourceProps> = ({
  predicateToSetup,
  onPressNext = noop,
  ...props
}) => {
  const { t } = useIntl()

  const {
    appendUnit,
    convertNumberValue,
    fieldSettings,
    finalParams,
    initialValues,
    metric,
    op,
    orderedFields,
    predicateKey,
    schema,
    setFinalParams,
    source,
    availableHorizonOptions
  } = useSetupPredicateParams(predicateToSetup)

  return (
    <SuperForm
      schema={schema}
      initialValues={initialValues}
      onSubmit={noop}
      // Required to ensure we do not get a valid form status from invalid default values (for example empty arrays)
      validateOnMount
    >
      {({ values, isValid }) => {
        return (
          <AlertCreationStep
            {...props}
            canProgressToNext={isValid}
            onPressNext={() => onPressNext(finalParams)}
          >
            <UseOnChange
              onChange={(changedValues) => {
                const override: { since?: number } = {}
                if ('since' in changedValues && isDate(changedValues.since)) {
                  const sinceAsMoment = moment(changedValues.since)
                  override.since = sinceAsMoment.unix()
                }
                setFinalParams({ ...changedValues, ...override })
              }}
            />
            {predicateKey === 'cumul-gt-RR' ? (
              <>
                <PositiveNumberField
                  {...orderedFields.threshold}
                  label={appendUnit({
                    msg: t('models.alert.fields.threshold.label', { capitalize: CAPITALIZE }),
                    withParentheses: true
                  })}
                  min={fieldSettings.threshold?.min ?? 0}
                />
                <DateTimePickerField
                  {...orderedFields.since}
                  label={t('alerts.paramSetups.fieldLabels.start_date', {
                    capitalize: CAPITALIZE
                  })}
                  isShowingTimePicker
                  timeFieldLabel={t('alerts.paramSetups.fieldLabels.start_time')}
                />
                <CheckboxField
                  {...orderedFields.isRepeatable}
                  label={t('alerts.paramSetups.fieldLabels.repeatable', {
                    capitalize: CAPITALIZE
                  })}
                />
              </>
            ) : predicateKey === 'sliding-cumul-gt-RR' ? (
              <>
                <SliderField
                  {...orderedFields.threshold}
                  showPlusMinus
                  customLabel={`${t('alerts.paramSetups.fieldLabels.rain_intensity', {
                    capitalize: CAPITALIZE
                  })} : ${appendUnit({
                    msg: `${values.threshold as number}`
                  })}`}
                  // Step must also be converted from mm to inches
                  step={convertNumberValue({ metric, value: 1 }) as number}
                  min={fieldSettings.threshold?.min as number}
                  max={fieldSettings.threshold?.max as number}
                />
                <Flex $alignItems='center'>
                  <Icons.InfoCircle $size='lg' $mr='sm' />
                  <Text>{getMessagePerRainIntensityRange(values.threshold as number)}</Text>
                </Flex>
              </>
            ) : predicateKey === 'cumul-gt-GDD' ? (
              <>
                <PositiveNumberField
                  {...orderedFields.base}
                  label={t('models.alert.fields.base.label', { capitalize: CAPITALIZE })}
                  max={fieldSettings.base?.max as number}
                />
                <NumberField
                  {...orderedFields.threshold}
                  label={appendUnit({
                    msg: t('models.alert.fields.threshold.label', { capitalize: CAPITALIZE }),
                    withParentheses: true
                  })}
                  min={fieldSettings.threshold?.min ?? 0}
                />
                <DateTimePickerField
                  {...orderedFields.since}
                  label={t('alerts.paramSetups.fieldLabels.start_date', {
                    capitalize: CAPITALIZE
                  })}
                />
                <CheckboxField
                  {...orderedFields.isRepeatable}
                  label={t('alerts.paramSetups.fieldLabels.repeatable', {
                    capitalize: CAPITALIZE
                  })}
                />
              </>
            ) : predicateKey === 'cumul-gt-LW_D' ? (
              <>
                <SliderField
                  {...orderedFields.threshold}
                  showPlusMinus
                  customLabel={`${t('alerts.paramSetups.fieldLabels.wetness_duration', {
                    capitalize: CAPITALIZE
                  })} : ${values.threshold as number}${t('units.hours_abbr')}`}
                  min={fieldSettings.threshold?.min as number}
                  max={fieldSettings.threshold?.max as number}
                />
                <DateTimePickerField
                  {...orderedFields.since}
                  label={t('alerts.paramSetups.fieldLabels.start_date', {
                    capitalize: CAPITALIZE
                  })}
                  isShowingTimePicker
                  timeFieldLabel={t('alerts.paramSetups.fieldLabels.start_time')}
                />
                <CheckboxField
                  {...orderedFields.isRepeatable}
                  label={t('alerts.paramSetups.fieldLabels.repeatable', {
                    capitalize: CAPITALIZE
                  })}
                />
              </>
            ) : predicateKey === 'gt-RR' ? (
              <SelectField
                {...orderedFields.threshold}
                label={appendUnit({ msg: t('alerts.paramSetups.fieldLabels.rain_intensity') })}
                options={availableRainIntensityThresholds.map((thr) => ({
                  value: thr,
                  label: appendUnit({ msg: `${thr}` })
                }))}
              />
            ) : predicateKey === 'eq-IRRI_CAPA' || predicateKey === 'eq-IRRI_TENSIO' ? (
              <>
                <ChipGroupField
                  withSelectAll
                  {...orderedFields.horizons}
                  options={availableHorizonOptions}
                  label={t('alerts.paramSetups.fieldLabels.monitored_depths', {
                    capitalize: CAPITALIZE
                  })}
                />
                <ChipGroupField
                  withSelectAll
                  {...orderedFields.thresholds}
                  label={t('alerts.paramSetups.fieldLabels.water_status_threshold', {
                    capitalize: CAPITALIZE
                  })}
                  options={thresholdTypes.map((thr) => ({
                    value: thr,
                    label: t(`dss.irritensio.flags.${thr}`, { capitalize: CAPITALIZE })
                  }))}
                />
              </>
            ) : predicateKey === 'eq-TAVMILLS' ? (
              <SelectField
                isMulti
                {...orderedFields.infectionLevels}
                label={t('alerts.paramSetups.fieldLabels.infection_levels', {
                  capitalize: CAPITALIZE
                })}
                options={alertCompatibleAppleScabLevels.map((lvl) => ({
                  value: lvl,
                  label: t(`dss.tavmills.infectionLevel.${lvl}`)
                }))}
              />
            ) : source === 'U' ? (
              <SliderField
                {...orderedFields.threshold}
                showPlusMinus
                customLabel={`${t('models.alert.fields.threshold.label', {
                  capitalize: CAPITALIZE
                })} : ${appendUnit({
                  msg: `${values.threshold as number}`
                })}`}
                min={fieldSettings.threshold?.min as number}
                max={fieldSettings.threshold?.max as number}
              />
            ) : source === 'T' ? (
              <NumberField
                {...orderedFields.threshold}
                step={0.1}
                label={`${t('models.alert.fields.threshold.label', {
                  capitalize: CAPITALIZE
                })} : ${appendUnit({
                  msg: `${values.threshold as number}`
                })}`}
                min={fieldSettings.threshold?.min as number}
                max={fieldSettings.threshold?.max as number}
              />
            ) : source === 'T_CAPA' ? (
              <>
                <SliderField
                  {...orderedFields.threshold}
                  showPlusMinus
                  label={appendUnit({
                    msg: t('models.alert.fields.threshold.label', { capitalize: CAPITALIZE })
                  })}
                  min={fieldSettings.threshold?.min as number}
                  max={fieldSettings.threshold?.max as number}
                />
                <SelectField
                  {...orderedFields.horizon}
                  label={t('models.device.fields.horizon.label', { capitalize: true })}
                  options={availableHorizonOptions}
                />
              </>
            ) : op === 'lt' || op === 'gt' ? (
              source === 'LW_V' ? (
                <SliderField
                  {...orderedFields.threshold}
                  showPlusMinus
                  label={appendUnit({
                    msg: t('models.alert.fields.threshold.label', { capitalize: CAPITALIZE })
                  })}
                  min={fieldSettings.threshold?.min as number}
                  max={fieldSettings.threshold?.max as number}
                />
              ) : (
                <NumberField
                  {...orderedFields.threshold}
                  step={0.1}
                  min={fieldSettings.threshold?.min as number}
                  max={fieldSettings.threshold?.max as number}
                  label={appendUnit({
                    msg: t('models.alert.fields.threshold.label', { capitalize: CAPITALIZE }),
                    withParentheses: true
                  })}
                />
              )
            ) : op === 'near-gt' ? (
              <SelectField
                {...orderedFields.amountInHours}
                label={t('alerts.paramSetups.fieldLabels.amountInHours.name', {
                  capitalize: CAPITALIZE
                })}
                options={availableAmountsOfHours.map((hr) => ({
                  label: t(
                    'alerts.paramSetups.fieldLabels.amountInHours.optionsLabel',
                    { numberOfHours: hr },
                    { capitalize: CAPITALIZE }
                  ),
                  value: hr
                }))}
              />
            ) : null}
          </AlertCreationStep>
        )
      }}
    </SuperForm>
  )
}

export default SetupPredicateParams
