import { Href } from '@weenat/client/dist/routx/runtime-core'
import { useNavigate } from 'app/routx-router'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import { ReactNode, memo, useEffect, useRef, useState } from 'react'
import ContentLoader from 'react-content-loader'
import { styled } from 'styled-components'
import Icons from './Icons'

const loadingContainerHeight = 48
const defaultLoadingContainerWidth = 600

export interface NavigableProps {
  children?: ReactNode
  $isHeaderOnlyPressable?: boolean
  /** Required to override styles * */
  className?: string
  title?: string | React.ReactElement
  isMediumTitle?: boolean
  icon?: React.ReactNode
  isLoading?: boolean
  onPress?: () => void
  to?: Href
  href?: string
  $withHoverEffect?: boolean
}

const LoadingContainer = styled.div<{ $height: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${(props) => `${props.$height}px`};
`

interface LoadingTableProps {
  uniqueKey: string
}

const LoadingNavigable: FC<LoadingTableProps> = memo(({ uniqueKey }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState(defaultLoadingContainerWidth)

  useEffect(() => {
    if (ref.current) setWidth(ref.current.offsetWidth)
  }, [ref.current])

  return (
    <LoadingContainer ref={ref} $height={loadingContainerHeight}>
      <ContentLoader
        viewBox={`0 0 ${width} ${loadingContainerHeight}`}
        height={loadingContainerHeight}
        uniqueKey={uniqueKey}
      >
        <rect x={0} y={16} rx='4' ry='4' width={width / 3} height={16} />

        <rect x={width - 32} y={16} rx='4' ry='4' width={16} height={16} />
      </ContentLoader>
    </LoadingContainer>
  )
})

LoadingNavigable.displayName = 'LoadingNavigable'

type ContainerProps = Pick<
  NavigableProps,
  '$withHoverEffect' | 'to' | 'href' | '$isHeaderOnlyPressable'
> & {
  onClick?: () => void
}

const NavigableContainer = styled.div<ContainerProps>`
  ${(p) =>
    (!isNil(p.to) || !isNil(p.href) || !isNil(p.onClick)) &&
    !p.$isHeaderOnlyPressable &&
    `
      cursor: pointer;
    `}

  padding: ${(p) => p.theme.spacings.lg}px;
  border-bottom: 1px solid ${(p) => p.theme.colors.grayscale[100]};
  max-width: 100%;

  ${(p) =>
    p.$withHoverEffect &&
    `
      &:hover {
        background-color: ${p.theme.colors.primary[200]};
      }
    `}
`

const Navigable: FC<NavigableProps> = ({
  children,
  className,
  href,
  icon = null,
  isLoading = false,
  isMediumTitle,
  onPress,
  title,
  to,
  $withHoverEffect,
  $isHeaderOnlyPressable: isHeaderOnlyPressable = false
}) => {
  const nav = useNavigate()
  const handlePress = () => {
    if (!isLoading) {
      onPress?.()
      if (!isNil(to)) {
        nav(to)
      }
      if (!isNil(href)) window.open(href)
    }
  }

  return (
    <NavigableContainer
      className={className}
      to={to}
      href={href}
      $withHoverEffect={$withHoverEffect}
      $isHeaderOnlyPressable={isHeaderOnlyPressable}
      onClick={isHeaderOnlyPressable ? undefined : handlePress}
    >
      <Box
        onClick={isHeaderOnlyPressable ? handlePress : undefined}
        $mb={!isNil(children) ? 'lg' : undefined}
        $cursor={isHeaderOnlyPressable ? 'pointer' : 'auto'}
      >
        <Flex $justifyContent='space-between' $alignItems='center' $width='100%'>
          {typeof title === 'string' ? (
            <Box $flex={1}>
              <Text $ellipsis $fontSize={isMediumTitle ? 'md' : 'rg'}>
                {title}
              </Text>
            </Box>
          ) : (
            title
          )}
          {isNil(icon) && !isNil(to) ? (
            <Icons.ChevronLeft $rotate={180} $size='md' $ml='md' />
          ) : (
            icon
          )}
        </Flex>
      </Box>
      {isLoading ? <LoadingNavigable uniqueKey='loading-navigable' /> : children}
    </NavigableContainer>
  )
}

export default Navigable
