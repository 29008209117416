import { SVGProps } from 'react'

const SvgMapSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M9 3.117c-.22-.001-.44.034-.65.104L4.36 4.56C3.55 4.84 3 5.6 3 6.46v11.85c0 1.41 1.41 2.37 2.72 1.86l2.93-1.141c.22-.09.47-.09.69-.01l1.654.58a6.956 6.956 0 0 1-.898-2.434L9 16.78V5.11l6 2.111v2.068A7 7 0 0 1 17 9c1.483 0 2.858.462 3.99 1.248V5.69c0-1.41-1.41-2.37-2.72-1.86l-2.93 1.141c-.22.08-.46.09-.69.01l-5-1.75A1.967 1.967 0 0 0 9 3.117zm7.422 7.383a.434.434 0 0 0-.3.113.593.593 0 0 0-.175.287l-.224 1.1c-.2.083-.39.17-.565.262a4.419 4.419 0 0 0-.537.338l-1.074-.325a.48.48 0 0 0-.324.012.558.558 0 0 0-.25.213l-.602 1a.405.405 0 0 0-.06.324.525.525 0 0 0 .185.301l.826.725c-.033.233-.05.45-.05.65s.017.417.05.65l-.826.725a.525.525 0 0 0-.185.3.405.405 0 0 0 .06.325l.602 1c.066.1.15.172.25.213.1.042.207.045.324.012l1.074-.325c.183.134.363.246.537.338.176.092.365.179.565.262l.224 1.1c.034.116.09.212.174.287a.434.434 0 0 0 .3.113h1.2a.434.434 0 0 0 .3-.113.598.598 0 0 0 .177-.287l.224-1.1c.2-.083.387-.17.563-.262.174-.092.353-.204.537-.338l1.074.325c.117.033.226.03.326-.012a.554.554 0 0 0 .25-.213l.6-1a.407.407 0 0 0 .062-.324.528.528 0 0 0-.187-.301l-.824-.725c.033-.2.048-.408.048-.625s-.015-.425-.048-.625l.85-.724a.47.47 0 0 0 .099-.625l-.6-1.051a.624.624 0 0 0-.261-.225.4.4 0 0 0-.338 0l-1.051.375a3.978 3.978 0 0 0-.537-.375 5.068 5.068 0 0 0-.563-.275l-.224-1.1a.598.598 0 0 0-.176-.287.434.434 0 0 0-.3-.113h-1.2zm.6 3c.55 0 1.022.197 1.414.588.39.392.586.862.586 1.412s-.195 1.02-.586 1.412c-.392.391-.864.588-1.414.588s-1.021-.197-1.413-.588c-.392-.392-.587-.862-.587-1.412s.195-1.02.587-1.412a1.926 1.926 0 0 1 1.413-.588z'
      fill='currentColor'
    />
  </svg>
)

export default SvgMapSettings
