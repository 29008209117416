import { BoxProps } from 'app/src/kit/primitives/themeMappings/props'

import { TKey, useIntl } from '@weenat/wintl'
import Button from 'app/src/kit/Button'
import Pop from 'app/src/kit/Pop'
import Scrollable from 'app/src/kit/Scrollable'
import { useCurrentTour } from 'app/state'
import isNil from 'lodash-es/isNil'

type GenericOnPress<T> = (arg?: T) => void | Promise<void>

export interface AlertCreationStepProps<T> {
  isNextLoading?: boolean
  canProgressToNext?: boolean
  children?: React.ReactNode
  currentStepNumber: number
  isLastStep?: boolean
  isContentScrollable?: boolean
  onPressNext?: GenericOnPress<T>
  onPressPrevious?: GenericOnPress<T>
  previousKey?: TKey
}

const TOUR_NAME = 'discover_alerts'

function AlertCreationStep<T>({
  isNextLoading = false,
  canProgressToNext = true,
  children,
  currentStepNumber,
  isLastStep = false,
  isContentScrollable = true,
  onPressNext,
  onPressPrevious,
  previousKey = 'actions.previous'
}: AlertCreationStepProps<T>) {
  const { t } = useIntl()
  const [tour] = useCurrentTour()

  const ContentHolder = isContentScrollable ? Scrollable : Box
  const contentHolderProps = isContentScrollable
    ? {}
    : ({ height: '100%', style: { overflow: 'auto' } } as BoxProps)

  const nextButton = (
    <Button isDisabled={!canProgressToNext} onPress={onPressNext} isLoading={isNextLoading}>
      {isLastStep ? t('actions.confirm') : t('actions.next')}
    </Button>
  )

  const isAlertTourOnGoing = !isNil(tour) && tour.name === TOUR_NAME

  return (
    <Flex $flexDirection='column' $flex={1} $height='100%'>
      <ContentHolder {...contentHolderProps}>
        {currentStepNumber === 4 && isAlertTourOnGoing ? (
          <Pop placement='bottom' tourName={TOUR_NAME} stepName='params'>
            {children}
          </Pop>
        ) : (
          children
        )}
      </ContentHolder>

      <Flex $alignItems='center' $justifyContent='space-around' $height={48} $m='lg'>
        {onPressPrevious && currentStepNumber > 1 && (
          <Button importance='sd' onPress={onPressPrevious}>
            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              t(previousKey)
            }
          </Button>
        )}

        {onPressNext ? (
          <>
            {isAlertTourOnGoing ? (
              <>
                {currentStepNumber === 1 ? (
                  <Pop placement='top' tourName={TOUR_NAME} stepName='confirm_alert_type'>
                    {nextButton}
                  </Pop>
                ) : currentStepNumber === 2 ? (
                  <Pop placement='top' tourName={TOUR_NAME} stepName='confirm_source'>
                    {nextButton}
                  </Pop>
                ) : currentStepNumber === 4 || currentStepNumber === 5 ? (
                  <Pop placement='top' tourName={TOUR_NAME} stepName='confirm_targets'>
                    {nextButton}
                  </Pop>
                ) : (
                  nextButton
                )}
              </>
            ) : (
              nextButton
            )}
          </>
        ) : null}
      </Flex>
    </Flex>
  )
}

export default AlertCreationStep
