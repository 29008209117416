import { getAnalytics } from 'firebase/analytics'
import { FirebaseOptions, initializeApp } from 'firebase/app'
const initFirebase = () => {
  try {
    const firebaseConfig = JSON.parse(import.meta.env.VITE_FIREBASE_CONFIG) as FirebaseOptions
    initializeApp(firebaseConfig)
    getAnalytics()
  } catch (e) {}
}

export default initFirebase
