import { useEffect } from 'react'
import { useBackgroundMapDispatcher } from '../../contexts/BackgroundMapContext'
import { useRadarContext } from '../../contexts/RadarContext'

const useIsRadarEnabled = () => {
  const dispatchMapAction = useBackgroundMapDispatcher()

  const {
    state: { isEnabled },
    dispatch: dispatchRadarAction
  } = useRadarContext()

  function setIsRadarEnabled(newValue: boolean) {
    dispatchRadarAction({ type: 'setIsEnabled', newValue })
  }

  useEffect(() => {
    if (!isEnabled) {
      dispatchMapAction({ type: 'setDrawMarkers', newDrawMarkers: true })
    }
  }, [isEnabled])

  return [isEnabled, setIsRadarEnabled] as const
}

export default useIsRadarEnabled
