import { Slot, useMatch } from 'app/routx-router'
import AuthLayout from 'app/src/authentication/components/AuthLayout'

export const settings = {
  public: 'only'
}

const Layout = () => {
  const matchRegister = useMatch('/auth/register')
  const matchVerifyEmail = useMatch('/auth/verify-email/*')

  return matchVerifyEmail ? (
    <Slot />
  ) : (
    <AuthLayout $headerPosition={!matchRegister ? 'top' : 'left'}>
      <Slot />
    </AuthLayout>
  )
}

export const Component = Layout
export default Layout
