import { PaginationQueryParams } from '@weenat/client/dist/resources/types'
import { useIntl } from '@weenat/wintl'
import { SortCategory, SortOption } from 'app/utils/lists'
import { isEmpty } from 'lodash-es'
import { styled } from 'styled-components'
import useDisclosure from '../hooks/useDisclosure'
import ActivityDot from './ActivityDot'
import Button from './Button'
import Checkbox from './Checkbox'
import Icons from './Icons'
import MenuItem from './MenuItem'
import Text from './Text'
import { Dropdown, DropdownContent, DropdownTrigger } from './fields/Select/SelectDropdown'

const BadgeText = styled(Text)`
  user-select: none;
`

interface SortMenuItemProps {
  onPress: () => void
  label: string
  checked?: boolean
}

const SortMenuItem = ({ onPress, label, checked = false }: SortMenuItemProps) => {
  return (
    <MenuItem onPress={onPress}>
      <Flex $alignItems='center' $p='md' $gap='md'>
        <Checkbox type='checkbox' checked={checked} />
        <BadgeText unselectable='on'>{label}</BadgeText>
      </Flex>
    </MenuItem>
  )
}

interface SortMenuCategoryProps {
  category: SortCategory
  onSortOptionSelection: (filter: SortOption) => void
}

const SortMenuCategory = ({ category, onSortOptionSelection }: SortMenuCategoryProps) => {
  return (
    <Flex $flexDirection='column'>
      <Box $p='md'>
        <BadgeText $color={'grayscale.700'} $fontWeight='medium'>
          {category.label}
        </BadgeText>
      </Box>

      {category.options.map((opt) => {
        return (
          <SortMenuItem
            key={opt.id}
            onPress={() => onSortOptionSelection(opt)}
            label={opt.label}
            checked={opt.active}
          />
        )
      })}
    </Flex>
  )
}

interface SortOptionsButtonProps<
  CategoryID extends string = string,
  QueryParams = PaginationQueryParams
> {
  categories: SortCategory<CategoryID, QueryParams>[]
  onSortOptionSelection: (
    category: SortCategory<CategoryID, QueryParams>,
    filter: SortOption
  ) => void
}

function SortOptionsButton<
  CategoryID extends string = string,
  QueryParams = PaginationQueryParams
>({ categories, onSortOptionSelection }: SortOptionsButtonProps<CategoryID, QueryParams>) {
  const { t } = useIntl()

  const { isOpen, toggle } = useDisclosure(false)

  return (
    <Dropdown
      isVisible={isOpen}
      onVisibleChange={() => {
        toggle()
      }}
      placement='bottom-start'
      followTriggerElement={false}
    >
      <DropdownTrigger>
        <Button
          onPress={toggle}
          IconLeft={Icons.SortByAlpha}
          backgroundColor={'grayscale.100'}
          color={'grayscale.black'}
          style={{
            padding: 8,
            minHeight: 'unset',
            borderRadius: 8
          }}
        >
          {t('sorting.sort_list', { capitalize: true })}
        </Button>
      </DropdownTrigger>
      <DropdownContent width={300}>
        <Box $py={'sm'}>
          {categories.map((c) => {
            return (
              <SortMenuCategory
                key={c.id}
                category={c}
                onSortOptionSelection={(filter) => {
                  onSortOptionSelection(c, filter)
                  toggle()
                }}
              />
            )
          })}
        </Box>
      </DropdownContent>
    </Dropdown>
  )
}

interface SortOptionsMenuProps<
  CategoryID extends string = string,
  QueryParams = PaginationQueryParams
> {
  categories: SortCategory<CategoryID, QueryParams>[]
  onSortOptionSelection: (
    category: SortCategory<CategoryID, QueryParams>,
    filter: SortOption
  ) => void
}

function SortOptionsMenu<CategoryID extends string = string, QueryParams = PaginationQueryParams>({
  categories,
  onSortOptionSelection
}: SortOptionsMenuProps<CategoryID, QueryParams>) {
  const hasActiveCategory =
    !isEmpty(categories) &&
    categories.some((ctg) => !isEmpty(ctg.options) && ctg.options.some((opt) => opt.active))

  return (
    <Box $position='relative'>
      <SortOptionsButton categories={categories} onSortOptionSelection={onSortOptionSelection} />
      {hasActiveCategory ? <ActivityDot /> : null}
    </Box>
  )
}

export default SortOptionsMenu
