import { useIntl } from '@weenat/wintl'
import TextField, { TextFieldProps } from './TextField'

const SiretField = (props: TextFieldProps) => {
  const { t } = useIntl()
  return (
    <TextField
      {...props}
      label={t('models.billingAccount.fields.siret.label', { capitalize: true })}
    />
  )
}
export default SiretField
