import { useClient, useMutation, useQuery } from '@weenat/client'
import { Id } from '@weenat/client/dist/resources/types'
import { useIntl } from '@weenat/wintl'
import { useNavigate } from 'app/routx-router'
import useDisclosure from 'app/src/hooks/useDisclosure'
import isNil from 'lodash-es/isNil'
import moment from 'moment-timezone'
import Button from './Button'
import Icons from './Icons'
import Modal from './Modal'
import SmartText from './SmartText'

const DowngradeWarn = ({
  orgId,
  paymentDueDate
}: {
  orgId: Id
  // We seem to get back a string from the api
  paymentDueDate: string | number | null | undefined
}) => {
  const { t } = useIntl()
  const client = useClient()
  const nav = useNavigate()
  const { close, isOpen } = useDisclosure(true)
  const preferencesRequest = useQuery(client.preferences.get())
  const [updatePreferences] = useMutation(client.preferences.update())
  const downgradeOneTimeWarn = preferencesRequest.data?.oneTimeWarns?.downgrade
  if (isNil(paymentDueDate)) return null
  if (!preferencesRequest.isSuccess) return null
  // Hide ifthe user already had a downgradeOneTimeWarn?.shownAt & the paymentDueDate was before this time (proving this is not a new downgrade)
  if (
    !isNil(downgradeOneTimeWarn?.shownAt) &&
    (typeof paymentDueDate === 'string' ? parseInt(paymentDueDate, 10) : paymentDueDate) <
      parseInt(downgradeOneTimeWarn.shownAt, 10)
  ) {
    return null
  }
  /** Wrap a callback to update the one time warn before calling it */
  const handleUpdateOneTiweWarn = (cb: () => void) => () => {
    updatePreferences({
      oneTimeWarns: { downgrade: { shownAt: moment(new Date(Date.now())).unix().toString() } }
    })
    cb()
  }
  return (
    <Modal
      width={500}
      close={handleUpdateOneTiweWarn(close)}
      isOpen={isOpen}
      title={'⚠️ ' + t('ecommerce.billing.title')}
    >
      <SmartText tkey='ecommerce.plans.restrictions.check_billing_menu' />
      <Flex
        $justifyContent='center'
        $mt='lg'
        $flexDirection='column'
        $gap='md'
        $alignItems='center'
      >
        <Button
          IconRight={Icons.ArrowRight}
          onPress={handleUpdateOneTiweWarn(() => nav(`/administration/${orgId}/billing`))}
        >
          {t('actions.go_to_billing')}
        </Button>
        <Button
          importance='sd'
          IconLeft={Icons.SupportAgent}
          onPress={handleUpdateOneTiweWarn(() => nav('/help'))}
        >
          {t('actions.contact_customer_service')}
        </Button>
      </Flex>
    </Modal>
  )
}

export default DowngradeWarn
