import { getDeviceCommonName } from '@weenat/client/dist/core/devices'
import { useMetricProvider } from '@weenat/client/dist/core/metrics'
import { Device } from '@weenat/client/dist/resources/devices'
import { DeviceSummary } from '@weenat/client/dist/resources/measurements.type'
import { Station } from '@weenat/client/dist/resources/stations'
import { useIntl } from '@weenat/wintl'
import DelimitedFlex from 'app/src/kit/DelimitedFlex'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import { useCallback } from 'react'
import { useTheme } from 'styled-components'
import { useBackgroundMapContext } from '../dashboard/components/DashboardMap/contexts/BackgroundMapContext'
import PlotListItemMetricSummary from '../dashboard/components/DashboardMap/plotListItem/PlotListItemMetricSummary'
import useUniverseBackgroundMapContext from '../dashboard/components/DashboardMap/universes/useUniverseBackgroundMapContext'
import { useIsAppAccessRestricted } from '../hooks/useIsAppAccessRestricted'
import Card from '../kit/Card'
import Icons from '../kit/Icons'
import Link from '../kit/LinkComponent'
import { fromLngLatArrayToObject } from '../map/utils'

interface FocusedDeviceSummaryCardProps {
  device: Device | Station
  summary: DeviceSummary['data']
  onClose: () => void
}

const FocusedDeviceSummaryCard: FC<FocusedDeviceSummaryCardProps> = ({
  onClose,
  summary,
  device
}) => {
  const { colors } = useTheme()
  const { t } = useIntl()
  const { focusedMetricId: focusedMetric } = useUniverseBackgroundMapContext()
  const {
    api: { map, maps }
  } = useBackgroundMapContext()

  const { isRestricted, reason } = useIsAppAccessRestricted({
    enabled: true
  })

  const { name: provider } = useMetricProvider({
    resource: device,
    metric: focusedMetric,
    // since we won't have any virtual devices here
    isExpertOrg: false
  })

  const commonName = getDeviceCommonName(device)
  const technicalName = device.endUserName?.join(' ')
  const completeName = `${commonName} (${technicalName})`

  const focusDevice = useCallback(() => {
    const { location } = device
    if (!isNil(location) && location.coordinates.length >= 2 && !isNil(map) && !isNil(maps)) {
      const latLng = fromLngLatArrayToObject(location.coordinates)

      map.fitBounds(new maps.LatLngBounds(latLng, latLng), 90)
      map.setZoom(16)
    }
  }, [device, map, maps])

  return (
    <Card $p={0} $width={352} $pointerEvents='auto'>
      <DelimitedFlex $p='lg' $alignItems={'center'} $gap='md'>
        <Flex $flexDirection='column' $minWidth={0} $flex={1}>
          <Text $fontWeight='bold' $ellipsis>
            {!isNil(device.reverseGeocoding) ? device.reverseGeocoding : '-'}
          </Text>
          <Text $fontSize='sm' $ellipsis>
            {completeName}
          </Text>
        </Flex>
        <div>
          <Icons.Focus
            $backgroundColor={colors.grayscale.black}
            $color={colors.grayscale.white}
            $p='md'
            $size='lg'
            onPress={focusDevice}
          />
        </div>
        <div>
          <Icons.Close
            $backgroundColor={colors.grayscale[100]}
            $p='md'
            $size='lg'
            onPress={onClose}
          />
        </div>
      </DelimitedFlex>

      <Box $p='md'>
        <PlotListItemMetricSummary
          metric={focusedMetric}
          data={summary}
          isRestricted={isRestricted}
          isVirtualDevice={false}
          provider={provider}
          focusedHorizon={null}
          reason={reason}
        />
      </Box>

      <Link to={`/weather-map/${device.id}/summary`}>
        <DelimitedFlex
          $isDelimitedOnTop
          $p='md'
          $alignItems='center'
          $justifyContent='center'
          $gap='md'
        >
          <Text $fontWeight='medium' $color='primary.500'>
            {t('models.device.actions.view_device', { capitalize: true })}
          </Text>
          <Icons.ArrowRight $color='primary.500' $size='md' />
        </DelimitedFlex>
      </Link>
    </Card>
  )
}

export default FocusedDeviceSummaryCard
