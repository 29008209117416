import { useIntl } from '@weenat/wintl'
import Text from 'app/src/kit/Text'
import { styled } from 'styled-components'
import Button, { ButtonProps } from '../kit/Button'
import Card from '../kit/Card'
import Icons from '../kit/Icons'

interface DiscoverSectionItemProps {
  cover: string
  title: string
  overview: string
  $objectFit?: 'cover' | 'contain'
  onPress: ButtonProps['onPress']
  itemType: 'section' | 'article'
}

const CoverContainer = styled.div<{ $isFlex: boolean }>`
  position: relative;
  ${(p) =>
    p.$isFlex
      ? `
          display: flex;
          flex: 1;
        `
      : `
          height: 256px;
        `}
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.primary[200]};
`

const Cover = styled.img<Pick<DiscoverSectionItemProps, '$objectFit'>>`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: ${(p) => p.$objectFit};
  transition: transform 0.3s ease-out;
`

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;

  padding: 0;

  overflow: hidden;
  cursor: pointer;

  &:hover {
    border-color: ${(p) => p.theme.colors.primary[500]};
    box-shadow: ${(p) => p.theme.shadows.lg.boxShadow};
  }

  &:hover ${Cover} {
    transform: scale(1.1);
  }

  transition:
    border-color 0.3s ease-out,
    box-shadow 0.3s ease-out;
  will-change: box-shadow, border, transform;
`

const WideBtn = styled(Button)`
  margin-top: auto;
  width: 100%;
`

const OverviewText = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: auto;
`

const DiscoverSectionItem: FC<DiscoverSectionItemProps> = ({
  cover,
  overview,
  title,
  onPress,
  itemType,
  $objectFit = 'contain'
}) => {
  const { t } = useIntl()

  const isSection = itemType === 'section'

  return (
    <StyledCard onPress={onPress}>
      <CoverContainer $isFlex={isSection}>
        <Cover src={cover} $objectFit={$objectFit} />
      </CoverContainer>
      <Flex
        $flexDirection='column'
        $p='lg'
        $pt='md'
        $width='100%'
        $flex={isSection ? undefined : 1}
      >
        <Text $lineHeight='rg' $mb='md' $fontSize='md' $fontWeight='bold'>
          {title}
        </Text>
        <OverviewText $lineHeight='md'>{overview}</OverviewText>
        <Flex $width='100%' $alignItems='center' $justifyContent='stretch' $pt='lg' $flex={1}>
          <WideBtn color={'primary.500'} importance='sd' IconRight={Icons.ArrowRight}>
            {t('actions.learn_more')}
          </WideBtn>
        </Flex>
      </Flex>
    </StyledCard>
  )
}

export default DiscoverSectionItem
