import Modal, { ModalProps } from '../Modal'
import WeenatCalendar, { WeenatCalendarProps } from './WeenatCalendar'

const SIZE = 600

type WeenatCalendarModalProps = WeenatCalendarProps & Pick<ModalProps, 'isOpen' | 'close' | 'title'>

const WeenatCalendarModal: FC<WeenatCalendarModalProps> = ({ isOpen, close, title, ...props }) => {
  return (
    <Modal isOpen={isOpen} close={close} width={SIZE} height={SIZE} title={title}>
      <WeenatCalendar {...props} />
    </Modal>
  )
}

export default WeenatCalendarModal
