import { Devices } from '@weenat/client/dist/enums'
import { useIntl } from '@weenat/wintl'
import { useOrgContext } from 'app/orgProvider'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import moment from 'moment-timezone'
import Icons from './Icons'

export interface UpdateInformationProps {
  isVirtualMetric?: boolean
  isRaw?: boolean
  timestamp: number | null | undefined
  warningThreshold?: number
  className?: string
  name?: string
  deviceId?: number
}

const UpdateInformationTiming: FC<
  Pick<UpdateInformationProps, 'isRaw' | 'timestamp' | 'warningThreshold' | 'isVirtualMetric'>
> = ({ timestamp, isRaw, isVirtualMetric, warningThreshold }) => {
  const { formatRelativeTimeToNow } = useIntl()

  if (isNil(timestamp)) {
    return (
      <>
        <Icons.Update $color={'grayscale.700'} $size='rg' />
        <Text $lineHeight='none' $fontSize={isRaw ? 'xs' : 'sm'} $color={'grayscale.700'}>
          -
        </Text>
      </>
    )
  }

  const warningThresholdToUse = !isNil(warningThreshold)
    ? warningThreshold
    : isVirtualMetric
      ? Devices.UpdateWarningThresholds.virtualDevices
      : Devices.UpdateWarningThresholds.default

  const minutesSinceLastUpdate = moment().diff(moment.unix(timestamp), 'minutes')
  const thresholdExceeded = minutesSinceLastUpdate > warningThresholdToUse
  const color = thresholdExceeded ? 'data.danger' : 'grayscale.700'

  return (
    <>
      {!isRaw && <Icons.Update $color={color} $size='rg' />}
      <Text $lineHeight='none' $fontSize={isRaw ? 'xs' : 'sm'} $color={color}>
        {formatRelativeTimeToNow(timestamp)}
      </Text>
    </>
  )
}

const UpdateInformation: FC<UpdateInformationProps> = ({
  isVirtualMetric = false,
  timestamp = null,
  warningThreshold,
  isRaw = false,
  className,
  name,
  deviceId
}) => {
  const { t } = useIntl()
  const { currentOrgId } = useOrgContext()

  const informationOrigin = (
    <Text $lineHeight='none' $fontSize='sm' $color={'grayscale.700'}>
      {!isNil(name)
        ? name
        : isNil(timestamp)
          ? t('summaries.metric_providers.none', { capitalize: true })
          : null}
    </Text>
  )
  return (
    <Flex className={className} $alignItems='center' $gap='sm'>
      {!isNil(deviceId) ? (
        <a href={`/administration/${currentOrgId}/devices/${deviceId}`}>{informationOrigin}</a>
      ) : (
        informationOrigin
      )}
      <UpdateInformationTiming
        timestamp={timestamp}
        isRaw={isRaw}
        isVirtualMetric={isVirtualMetric}
        warningThreshold={warningThreshold}
      />
    </Flex>
  )
}

export default UpdateInformation
