import { useClient, useMutation } from '@weenat/client'
import * as zodSchemas from '@weenat/client/dist/zod-schemas'
import { useIntl } from '@weenat/wintl'
import { useNavigate, useSearchParams } from 'app/routx-router'
import Button from 'app/src/kit/Button'
import Card from 'app/src/kit/Card'
import MapBackgroundImage from 'app/src/kit/MapBackgroundImage'
import Text from 'app/src/kit/Text'
import { useToken } from 'app/state'
import isNil from 'lodash-es/isNil'
import { useEffect } from 'react'

export const settings = {
  public: 'only',
  search: zodSchemas.token
}

const Actura = () => {
  const client = useClient()
  const nav = useNavigate()
  const { t } = useIntl()
  const [weenatToken, setWeenatToken] = useToken()
  const [loginFromActura, loginFromActuraState] = useMutation(client.actura.login(), {
    onSuccess: ({ token }) => {
      setWeenatToken(token)
    },
    retry: 0
  })
  const [searchParams] = useSearchParams(settings.search)
  const { token } = searchParams
  useEffect(() => {
    if (!isNil(token)) {
      if (isNil(weenatToken)) {
        loginFromActura({ token })
      } else {
        nav('/plots')
      }
    }
  }, [weenatToken])
  return (
    <>
      {loginFromActuraState.isError && (
        <Flex
          $width='100%'
          $height='100%'
          $flexDirection='column'
          $justifyContent='center'
          $alignItems='center'
        >
          <Card $maxWidth='50vw'>
            <Flex $flexDirection='column' $alignItems='center' $justifyContent='center' $gap='lg'>
              <Text> {t(`error_codes.${loginFromActuraState.error.parsedErrors.code}`)}</Text>
              <Button onPress={() => nav('/')}>{t('actions.go_back_to_login')}</Button>
            </Flex>
          </Card>
        </Flex>
      )}
      <MapBackgroundImage />
    </>
  )
}
export const Component = Actura
export default Actura
