function FakeMyFarmScreenShot(props: object) {
  return (
    <svg viewBox='0 0 493 320' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <rect width='492.709' height='320' rx='16' fill='#FAFBFC' />
      <rect x='13.0613' y='13.0612' width='466.588' height='19.5918' rx='9.79592' fill='#CCD4D7' />
      <rect x='39.1838' y='17.9592' width='52.2449' height='9.79592' rx='4.89796' fill='#2E75B6' />
      <rect x='97.9592' y='17.9592' width='52.2449' height='9.79592' rx='4.89796' fill='white' />
      <rect x='156.735' y='17.9592' width='52.2449' height='9.79592' rx='4.89796' fill='white' />
      <rect x='215.51' y='17.9592' width='52.2449' height='9.79592' rx='4.89796' fill='white' />
      <rect x='19.5918' y='17.9592' width='9.79592' height='9.79592' rx='4.89796' fill='white' />
      <rect x='459.592' y='17.9592' width='9.79592' height='9.79592' rx='4.89796' fill='#7F9299' />
      <rect x='440' y='17.9592' width='9.79592' height='9.79592' rx='4.89796' fill='#7F9299' />
      <path
        d='M0 45.7143H111.347C120.183 45.7143 127.347 52.8777 127.347 61.7143V320H16C7.16345 320 0 312.837 0 304V45.7143Z'
        fill='#B3BEC3'
      />
      <rect x='11.9287' y='73.9694' width='100.224' height='51.2449' rx='7.5' fill='white' />
      <rect x='11.9287' y='73.9694' width='100.224' height='51.2449' rx='7.5' stroke='#B3BEC3' />
      <rect x='24.49' y='86.5306' width='75.102' height='6.53061' rx='3.26531' fill='#B3BEC3' />
      <rect x='24.49' y='99.5918' width='20.6803' height='13.0612' rx='4' fill='#B3BEC3' />
      <rect x='51.7009' y='99.5918' width='20.6803' height='13.0612' rx='4' fill='#B3BEC3' />
      <rect x='78.9116' y='99.5918' width='20.6803' height='13.0612' rx='4' fill='#B3BEC3' />
      <rect x='11.9287' y='132.745' width='100.224' height='51.2449' rx='7.5' fill='white' />
      <rect x='11.9287' y='132.745' width='100.224' height='51.2449' rx='7.5' stroke='#B3BEC3' />
      <rect x='24.49' y='145.306' width='75.102' height='6.53061' rx='3.26531' fill='#B3BEC3' />
      <rect x='24.49' y='158.367' width='20.6803' height='13.0612' rx='4' fill='#B3BEC3' />
      <rect x='51.7009' y='158.367' width='20.6803' height='13.0612' rx='4' fill='#B3BEC3' />
      <rect x='78.9116' y='158.367' width='20.6803' height='13.0612' rx='4' fill='#B3BEC3' />
      <rect x='11.9287' y='191.52' width='100.224' height='51.2449' rx='7.5' fill='white' />
      <rect x='11.9287' y='191.52' width='100.224' height='51.2449' rx='7.5' stroke='#B3BEC3' />
      <rect x='24.49' y='204.082' width='75.102' height='6.53061' rx='3.26531' fill='#B3BEC3' />
      <rect x='24.49' y='217.143' width='20.6803' height='13.0612' rx='4' fill='#B3BEC3' />
      <rect x='51.7009' y='217.143' width='20.6803' height='13.0612' rx='4' fill='#B3BEC3' />
      <rect x='78.9116' y='217.143' width='20.6803' height='13.0612' rx='4' fill='#B3BEC3' />
      <rect x='11.4287' y='57.1429' width='101.224' height='9.79592' rx='2' fill='#4D6872' />
      <rect x='133.877' y='47.3469' width='52.2449' height='13.0612' rx='6.53061' fill='#B3BEC3' />
      <rect x='195.918' y='47.3469' width='52.2449' height='13.0612' rx='6.53061' fill='#B3BEC3' />
      <rect x='222' y='283' width='19.5918' height='19.5918' rx='4' fill='#B3BEC3' />
      <rect x='248.122' y='283' width='19.5918' height='19.5918' rx='4' fill='#B3BEC3' />
      <rect x='274.245' y='283' width='19.5918' height='19.5918' rx='4' fill='#B3BEC3' />
      <rect x='306.898' y='283' width='78.3673' height='19.5918' rx='4' fill='#B3BEC3' />
      <rect x='368' y='100' width='40' height='64' rx='20' fill='#B3BEC3' />
      <rect x='371.333' y='104.667' width='33.3333' height='33.3333' rx='16.6667' fill='#2E75B6' />
      <rect x='304' y='144' width='32' height='32' rx='16' fill='#2E75B6' />
      <rect x='267' y='184' width='32' height='32' rx='4' fill='#2E75B6' />
      <rect x='183' y='138' width='40' height='64' rx='8' fill='#B3BEC3' />
      <rect x='186.333' y='142.667' width='33.3333' height='33.3333' rx='4' fill='#2E75B6' />
    </svg>
  )
}

export default FakeMyFarmScreenShot
