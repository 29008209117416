import { mergeQueryState, useClient, useQuery } from '@weenat/client'
import { QueryRequestState } from '@weenat/client/dist/mergeState'
import { Me, MyProfile } from '@weenat/client/dist/resources/me'
import { useLoginAs, useToken } from 'app/state'
import { isNil } from 'lodash-es'
import { ReactNode, createContext, useContext, useMemo } from 'react'

interface User {
  me: Me | null
  profile: MyProfile | null
}

interface IUserContext {
  me: Me | null
  profile: MyProfile | null
  user: User | null
  requestState: QueryRequestState
}

const UserContext = createContext<IUserContext | null>(null)

export function useUserContext() {
  const context = useContext<IUserContext | null>(UserContext)

  if (!context) {
    throw new Error(
      'You may have forgot to render <UserContextProvider /> because UserContext is not defined'
    )
  }

  return context
}

export function UserContextProvider({ children }: { children: ReactNode }) {
  const [loginAs] = useLoginAs()

  const client = useClient()
  const [token] = useToken()

  const config = {
    enabled: !isNil(token)
  }

  const meRequest = useQuery(client.me.get({ loginAsEmail: loginAs?.email }), config)
  const myProfileRequest = useQuery(
    client.me.getMyProfile({ loginAsEmail: loginAs?.email }),
    config
  )

  const requestState = mergeQueryState(myProfileRequest, meRequest)
  const profile = myProfileRequest.data
  const me = meRequest.data

  const value = useMemo(() => {
    return {
      me: me ?? null,
      profile: profile ?? null,
      user: {
        me: me ?? null,
        profile: profile ?? null
      },
      requestState
    }
  }, [me, profile, requestState])

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
