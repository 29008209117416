import { useIntl } from '@weenat/wintl'
import { useNavigate } from 'app/routx-router'
import { CustomerSupportContactDetails } from 'app/src/help/components/CustomerSupportSection'
import useDisclosure from 'app/src/hooks/useDisclosure'
import Button from 'app/src/kit/Button'
import Icons from 'app/src/kit/Icons'
import Modal from 'app/src/kit/Modal'
import Text from 'app/src/kit/Text'
import Flex from 'app/src/kit/primitives/Flex'
import { usePaymentCtx, useSelectedOrgs } from 'app/state'
import isEqual from 'lodash-es/isEqual'
import isNil from 'lodash-es/isNil'
import { useEffect } from 'react'
import { styled } from 'styled-components'

const SUCCESS_CIRCLE_SIZE = 64

const ErrorCircle = styled(Flex)`
  align-items: center;
  justify-content: center;

  width: ${SUCCESS_CIRCLE_SIZE}px;
  height: ${SUCCESS_CIRCLE_SIZE}px;

  border-radius: ${({ theme }) => theme.radiuses.rounded}px;
  background-color: ${({ theme }) => theme.colors.feedback.error['500']};
`

const Failed = () => {
  const { t } = useIntl()
  const [selectedOrgs, setSelectedOrgs] = useSelectedOrgs()
  const nav = useNavigate()
  const [paymentContext, setPaymentContext] = usePaymentCtx()

  const {
    isOpen: isContactSupportModalOpen,
    open: openContactSupportModal,
    close: closeContactSupportModal
  } = useDisclosure(false)

  const contextSelectedOrgs = paymentContext.selectedOrgs

  if (!isNil(contextSelectedOrgs) && !isEqual(selectedOrgs, contextSelectedOrgs)) {
    setSelectedOrgs(contextSelectedOrgs)
  }

  useEffect(() => {
    return () => {
      setPaymentContext({})
    }
  }, [])

  return (
    <Modal
      isOpen
      close={() => {
        nav('/plots', { replace: true })
      }}
      maxWidth={480}
    >
      <Flex
        $flexDirection='column'
        $alignItems='center'
        $justifyContent='space-between'
        $height='100%'
        $gap={48}
      >
        <Flex $flexDirection='column' $alignItems='center' $gap='lg'>
          <ErrorCircle>
            <Icons.Close $color={'grayscale.white'} $size='lg' />
          </ErrorCircle>

          <Text $fontSize='md' $fontWeight='bold' $textAlign='center'>
            {t('ecommerce.plans.payment_failure')}
          </Text>
          <Text $textAlign='center'>{t('ecommerce.plans.subscription_order_error_message')}</Text>
        </Flex>
        <Flex $alignItems='center' $justifyContent='flex-end' $gap='lg' $width='100%'>
          <Button IconLeft={Icons.SupportAgent} onPress={openContactSupportModal}>
            {t('help.support')}
          </Button>
        </Flex>
      </Flex>
      {isContactSupportModalOpen ? (
        <Modal
          width={512}
          title={t('help.support', { capitalize: true })}
          isOpen={isContactSupportModalOpen}
          close={closeContactSupportModal}
        >
          <CustomerSupportContactDetails />
        </Modal>
      ) : null}
    </Modal>
  )
}

export const Component = Failed
export default Failed
