import { mergeQueryState, useClient, useQuery } from '@weenat/client'
import { useArvalisClientIdFromOrg } from '@weenat/client/dist/core/dss/waterBalance'
import { Universe } from '@weenat/client/dist/core/universes'
import { BillingStatus } from '@weenat/client/dist/enums'
import { isMetricThatNeedsToBeFromDeviceToBeDisplayedOnMap } from '@weenat/client/dist/enums/MetricIds'
import { PlotOrStationSummary } from '@weenat/client/dist/resources/measurements'
import {
  PlotSummary,
  isPlotSummary,
  isStationSummary
} from '@weenat/client/dist/resources/measurements.type'
import { Id } from '@weenat/client/dist/resources/types'
import { useOrgContext } from 'app/orgProvider'
import { Slot, useMatch } from 'app/routx-router'
import { PLOT_LIST_WIDTH } from 'app/src/dashboard/components/DashboardMap/PlotList'
import PlotsMapOverlay from 'app/src/dashboard/components/DashboardMap/PlotsMapOverlay'
import { useBackgroundMapContext } from 'app/src/dashboard/components/DashboardMap/contexts/BackgroundMapContext'
import useDrawVisiblePlotShapes from 'app/src/dashboard/components/DashboardMap/hooks/useDrawVisiblePlotShapes'
import usePODClusters from 'app/src/dashboard/components/DashboardMap/hooks/usePODClusters'
import useUniverseBackgroundMapContext from 'app/src/dashboard/components/DashboardMap/universes/useUniverseBackgroundMapContext'
import fitBoundsWithPadding from 'app/src/dashboard/components/DashboardMap/utils/fitBoundsWithPadding'
import ExternalConnectionsErrorMessage from 'app/src/dashboard/components/common/ExternalConnectionsErrorMessage'
import useDebounce from 'app/src/hooks/useDebounce'
import FeatGuarded from 'app/src/kit/FeatGuarded'
import InvitNotificationPresentation from 'app/src/kit/InvitNotificationPresentation'
import { useSelectedOrgs, useUniverse, useUniverseHorizon } from 'app/state'
import logEvent from 'app/utils/analytics'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { useCallback, useEffect, useMemo } from 'react'

// 10 Minutes
// Might need to change when frost universe is active
const REFETCH_INTERVAL = 10 * 60 * 1000
const EMPTY_SUMMARIES: PlotOrStationSummary[] = []

const getFakeSummary = (_: unknown, id: Id): PlotSummary =>
  ({
    metadata: { plot: { id } },
    data: {}
  }) as PlotSummary

const ClassicalPlotMapLayout = () => {
  const client = useClient()
  const [universe] = useUniverse()
  const { focusedMetricId } = useUniverseBackgroundMapContext()
  const [focusedHorizon] = useUniverseHorizon()
  const { org, orgRequest, currentOrgId } = useOrgContext()

  const {
    api: { map, maps },
    search
  } = useBackgroundMapContext()

  const [, setHorizon] = useUniverseHorizon()
  const [selectedOrgs, setSelectedOrgs] = useSelectedOrgs()

  const isExpertOrg = !isNil(org) ? org.billingStatus === BillingStatus.expert : false

  const summariesQueryParams = {
    organizationId: currentOrgId as number,
    universe
  }

  const clientCode = useArvalisClientIdFromOrg(org)

  const summariesRequester =
    universe === Universe.irrelis
      ? client.waterBalanceArvalis.getSummaries({ orgId: currentOrgId, clientCode })
      : client.measurements.plotsAndStations.summaries.getAllPages(summariesQueryParams)

  const summariesRequest = useQuery(summariesRequester, {
    enabled: !isNil(currentOrgId),
    // Every 10 minutes
    refetchInterval: REFETCH_INTERVAL
  })

  const summaries = summariesRequest.data ?? EMPTY_SUMMARIES

  const hasAlreadyFetchedAndHasNoItems = summariesRequest.isFetched && isEmpty(summaries)

  const debouncedSearch = useDebounce(search, 300)
  const isSearching = !isEmpty(debouncedSearch)

  const { isLoading, isError, isFetching } = mergeQueryState(summariesRequest, orgRequest)

  /**
   * Computing the visible summaries depending on the context
   * If search is active we're filtering the non-matching summaries
   * If the current metric is an irrigation metric we're showing only the current metric summaries
   */
  const { areSearchResultsEmpty, visibleSummaries } = useMemo(() => {
    let items = summaries
    let areResultsEmpty = false

    if (focusedMetricId !== 'HDEF') {
      items = items.filter((sum) => {
        if (!(isPlotSummary(sum) || isStationSummary(sum))) {
          return false
        }
        if (isPlotSummary(sum)) {
          const metaItem = sum.metadata.plot

          if (!isNil(metaItem))
            return (
              !isEmpty(metaItem.currentMeasurementConfigs) &&
              metaItem.currentMeasurementConfigs.some(
                (conf) =>
                  conf.metric === focusedMetricId &&
                  (!isMetricThatNeedsToBeFromDeviceToBeDisplayedOnMap(conf.metric) ||
                    conf.deviceId != null)
              )
            )
        } else {
          const { availableMeasures } = sum.metadata.station
          return (
            !isEmpty(availableMeasures) &&
            availableMeasures.some((metric) => metric === focusedMetricId)
          )
        }
      })
    }

    if (isSearching) {
      const searchResults = items.filter(
        (item) =>
          !isNil(item) &&
          ((isPlotSummary(item) &&
            item.metadata.plot.name.toLowerCase().includes(debouncedSearch.toLowerCase())) ||
            (isStationSummary(item) &&
              item.metadata.station.name.toLowerCase().includes(debouncedSearch.toLowerCase())))
      )

      areResultsEmpty = isSearching && isEmpty(searchResults)

      items = searchResults
    }

    items =
      areResultsEmpty || hasAlreadyFetchedAndHasNoItems || isError
        ? // This may seems a little hacky but it is the more practical way to handle it without having to repeat
          // card layout measurement (using ListEmptyComponent),
          // or having to change keyExtractor putting guards inside render{ListItem,Marker}
          Array.from({ length: 1 }, getFakeSummary)
        : isLoading
          ? Array.from({ length: 3 }, getFakeSummary)
          : items

    return {
      areSearchResultsEmpty: areResultsEmpty,
      visibleSummaries: items
    }
  }, [
    debouncedSearch,
    focusedMetricId,
    hasAlreadyFetchedAndHasNoItems,
    isError,
    isLoading,
    isSearching,
    summaries
  ])

  useDrawVisiblePlotShapes({
    currentOrganizationId: currentOrgId,
    summaries
  })

  const fitToBounds = useCallback(
    (
      bounds:
        | google.maps.LatLngBounds
        | {
            sw: google.maps.LatLng
            ne: google.maps.LatLng
          }
    ) => {
      fitBoundsWithPadding({
        googleApi: { map, maps },
        correctedPadding: PLOT_LIST_WIDTH,
        bounds
      })
    },
    [map, maps]
  )

  const onHorizonChange = useCallback(
    (newHorizon: number | null): void => {
      logEvent('horizon_changed')
      setHorizon(newHorizon)
    },
    [setHorizon]
  )

  /** Fit to the bounding box to the visibleSummaries */
  useEffect(() => {
    if (!isNil(maps) && !isNil(map) && isSearching && !isEmpty(visibleSummaries)) {
      let bounds: google.maps.LatLngBounds | undefined
      visibleSummaries.forEach((summary) => {
        let location
        if (isPlotSummary(summary)) {
          // eslint-disable-next-line prefer-destructuring
          location = summary.metadata.plot.location
        } else {
          // eslint-disable-next-line prefer-destructuring
          location = summary.metadata.station.location
        }
        if (!isNil(location)) {
          const [lng, lat] = location.coordinates
          if (bounds === undefined) {
            bounds = new maps.LatLngBounds()
          }
          bounds.extend(new maps.LatLng(lat, lng))
        }
      })

      if (!isNil(bounds)) {
        fitToBounds(bounds)
      }
    }
  }, [
    // This effect should be triggered on debouncedSearchTerm and focusedMetricId change not visibleSummaries change,
    // since visible summary will only change if one of those two variables changes
    debouncedSearch,
    focusedMetricId
  ])

  usePODClusters({ summaries: visibleSummaries, isPlotsMap: true, isFetching: isFetching ?? false })
  return (
    <>
      <InvitNotificationPresentation />
      <ExternalConnectionsErrorMessage />

      <PlotsMapOverlay
        allSummaries={summaries}
        currentItems={visibleSummaries}
        focusedHorizon={focusedHorizon}
        hasAlreadyFetchedAndHasNoItems={hasAlreadyFetchedAndHasNoItems}
        hasNoItemsMatchingSearchTerm={areSearchResultsEmpty}
        isError={isError}
        isExpertOrg={isExpertOrg}
        isLoading={isLoading}
        onHorizonChange={onHorizonChange}
        selectedOrgs={selectedOrgs}
        setSelectedOrgs={setSelectedOrgs}
        summariesQueryCacheKey={summariesRequester.key}
      >
        <FeatGuarded org={org} feat='FEAT-WEATHERMAP-PLOT' mapFallback>
          <Slot />
        </FeatGuarded>
      </PlotsMapOverlay>
    </>
  )
}

const PlotsMapLayout = () => {
  const isPlotCreation = useMatch('/plots/create')
  const isPlotEdition = useMatch('/plots/edit')

  return !isNil(isPlotCreation) || !isNil(isPlotEdition) ? <Slot /> : <ClassicalPlotMapLayout />
}

export const Component = PlotsMapLayout
export default PlotsMapLayout
