import { useIntl } from '@weenat/wintl'
import SmartText from 'app/src/kit/SmartText'
import Text from 'app/src/kit/Text'
import { useUserContext } from 'app/userProvider'
import AccountSection from './AccountSection'

const MyEmail = () => {
  const { t } = useIntl()
  const { me } = useUserContext()

  return (
    <AccountSection title={t('models.account.fields.email.label', { capitalize: true })}>
      <SmartText tkey='models.account.actions.edit_email' />
      <Box $my='lg'>
        <Text $fontWeight='semiBold'>{me?.email}</Text>
      </Box>
    </AccountSection>
  )
}

export default MyEmail
