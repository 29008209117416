import isNil from 'lodash-es/isNil'
import { GoogleMapApi, LatLng } from './types'

const panToLatLngWithOffset = (
  googleMapApi: GoogleMapApi,
  latLng: LatLng,
  offset?: number | google.maps.Padding
) => {
  const { maps, map } = googleMapApi

  if (!isNil(map) && !isNil(maps)) {
    // compute bounds of a circle of 1km arround center point
    const bounds = new maps.Circle({ center: latLng, radius: 1000 }).getBounds()

    if (!isNil(bounds)) map.fitBounds(bounds, offset)
  }
}

export default panToLatLngWithOffset
