import { useClient, useMutation } from '@weenat/client'
import { Alert } from '@weenat/client/dist/resources/alerts.types'
import { useIntl } from '@weenat/wintl'
import useToasts from 'app/src/hooks/useToasts'
import { TogglePrimitive } from 'app/src/kit/fields/ToggleField'
import { useEffect, useState } from 'react'
import { styled } from 'styled-components'

const NoMarginToggle = styled(TogglePrimitive)`
  margin: 0 4px 0 0;
`

interface AlertActiveStateToggleProps {
  alert: Alert
}

const AlertActiveStateToggle: FC<AlertActiveStateToggleProps> = ({ alert }) => {
  const client = useClient()
  const { t } = useIntl()

  // necessary state for optimistic updates
  const [isAlertActive, setIsAlertActive] = useState(alert?.isActive)
  const { addToast } = useToasts()
  // Otherwise it is not reset once we quit edition, since it is not unmounted here https://gitlab.com/weenat-front/web/-/blob/e5ddaeae55994f5e948d08ae79837dcd71d4d25b/app/src/alerts/components/AlertListItem.tsx#L250
  useEffect(() => {
    setIsAlertActive(alert?.isActive)
  }, [alert?.isActive])
  // Handling alert update
  const [updateAlert] = useMutation(client.alerts.update()(alert?._id), {
    onSuccess: () => {
      addToast(t('actions.success'), { appearance: 'success' })
    }
  })

  return (
    <NoMarginToggle
      onToggle={() => {
        updateAlert({ isActive: !isAlertActive })
        setIsAlertActive(!isAlertActive)
      }}
      value={isAlertActive}
      label={
        isAlertActive
          ? t('alerts.activated', { capitalize: true })
          : t('alerts.deactivated', { capitalize: true })
      }
    />
  )
}

export default AlertActiveStateToggle
