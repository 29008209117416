import { enums, useClient, useLazyQuery, useQuery } from '@weenat/client'
import { NetworkOrg } from '@weenat/client/dist/resources/networkOrgs'
import { Org } from '@weenat/client/dist/resources/orgs'
import { useIntl } from '@weenat/wintl'
import { useMatch, useParams } from 'app/routx-router'
import { SelectedOrgs, useToken } from 'app/state'
import { useUserContext } from 'app/userProvider'
import isEmpty from 'lodash-es/isEmpty'
import isEqual from 'lodash-es/isEqual'
import isNil from 'lodash-es/isNil'
import { useCallback, useEffect, useMemo, useState } from 'react'

const EMPTY_ORGS: Omit<Org, 'virtualDevicesCount' | 'virtualDevicesQuota'>[] = []

export interface UseSelectOrgArgs {
  selectedOrgs: SelectedOrgs
  setSelectedOrgs: React.Dispatch<React.SetStateAction<SelectedOrgs>>
  withoutChild?: boolean
  selectorPredicate?: (org: Org) => boolean
}

/**
 * Hook used to handle default and change behavior of the SelectOrg
 */
const useSelectOrg = ({ selectedOrgs, setSelectedOrgs, selectorPredicate }: UseSelectOrgArgs) => {
  const client = useClient()
  const { t } = useIntl()
  const { orgId: paramsOrgId } = useParams()
  const [token] = useToken()
  const { user, requestState } = useUserContext()

  const [enableNetworkOrgRequest, setEnableNetworkOrgRequest] = useState<boolean>(
    !isNil(selectedOrgs.childOrganizationId)
  )

  const isMatchingAdminRoutes = useMatch('/administration/*')
  const isAdminPath = !isNil(isMatchingAdminRoutes)

  // organization data
  const { organizationId, childOrganizationId } = selectedOrgs

  const orgsRequest = useQuery(client.orgs.getAllPages(), { enabled: !isNil(token) })
  const orgsData = orgsRequest.data ?? EMPTY_ORGS

  const org =
    orgsData.find(({ id }) => id === organizationId) ??
    orgsData.find(({ id }) => id === childOrganizationId)

  const isOrgNetwork = !isNil(org?.networkType)
  const isAdherentFarm = !isNil(org?.subscribedNetworkId)

  const { data: stillActiveNetworkOrgsData, isLoading } = useLazyQuery({
    isEnabled: isOrgNetwork && enableNetworkOrgRequest,
    fetcher: client.networkOrgs.getMany,
    queryParams: {
      isActive: true,
      networkId: organizationId as number
    }
  })
  // users data
  const favoriteOrgId = user?.profile?.favoriteOrgId

  const networkId = isNil(org?.subscribedNetworkId) ? org?.id : org?.subscribedNetworkId

  const networkRequest = useQuery(client.networks.get(networkId), {
    enabled: isOrgNetwork || isAdherentFarm
  })
  const network = networkRequest.data

  /**
   * Sorting organization by premium status
   * Not showing org user only has read access to, on admin page (on which he's not an Advisor)
   * Not showing networks with no active child
   */
  const orgOptions = useMemo(() => {
    let orgsToDisplay = orgsData

    if (!isNil(selectorPredicate) && isAdminPath) {
      orgsToDisplay = [...orgsData].filter(
        (anOrg) =>
          selectorPredicate(anOrg) || (isOrgNetwork && !isEmpty(stillActiveNetworkOrgsData))
      )
    }

    return orgsToDisplay
      .sort((a) =>
        [enums.BillingStatus.plus, enums.BillingStatus.expert].includes(a.billingStatus) ? -1 : 1
      )
      .map(({ id, name }) => ({
        label: name,
        value: id
      }))
  }, [orgsData, selectorPredicate, isAdminPath, isOrgNetwork, stillActiveNetworkOrgsData])

  /**
   * Sorting childOrgs
   * NOTE : A users role on a child org is defined by it's role inside the network,
   *        if its network role isn't Reader then he's considered an Advisor
   */
  const childOrgOptions = useMemo(() => {
    const childOrgsToDisplay: NetworkOrg[] =
      !isNil(selectorPredicate) && !isNil(org) && isAdminPath && !selectorPredicate(org)
        ? []
        : stillActiveNetworkOrgsData

    return [
      {
        value: null,
        label: t('map.network_plots')
      },
      ...childOrgsToDisplay.map(({ org: { id, name } }) => ({
        value: id,
        label: name
      }))
    ]
  }, [selectorPredicate, org, isAdminPath, stillActiveNetworkOrgsData, t])

  const selectOrgLabel = isOrgNetwork
    ? t('models.network.model.singular_name', { capitalize: true })
    : t('models.organization.model.singular_name', { capitalize: true })

  const selectChildOrgLabel = t('models.networkOrganization.model.singular_name', {
    capitalize: true
  })

  const handleOrganizationIdChange = useCallback(
    (newOrganizationId: number) =>
      setSelectedOrgs((current) => {
        const newVal = {
          organizationId: newOrganizationId,
          childOrganizationId: null
        }
        if (!isEqual(current, newVal)) {
          return newVal
        } else {
          return current
        }
      }),
    [setSelectedOrgs]
  )

  const handleChildOrganizationIdChange = useCallback(
    (newChildOrganizationId: number | null) =>
      setSelectedOrgs({
        organizationId,
        childOrganizationId: newChildOrganizationId
      }),
    [organizationId, setSelectedOrgs]
  )

  const handleSetFavoriteOrg = useCallback(() => {
    if (!isNil(favoriteOrgId)) {
      setSelectedOrgs({
        organizationId: favoriteOrgId,
        childOrganizationId: null
      })
    }
  }, [favoriteOrgId, setSelectedOrgs])

  const handleSetOrgFromAdminPathname = useCallback(() => {
    if (isAdminPath && !isNil(paramsOrgId)) {
      handleOrganizationIdChange(paramsOrgId)
    }
  }, [isAdminPath, handleOrganizationIdChange, paramsOrgId])

  /**
   * Handles default behaviors if organizationId is undefined or if it's not inside orgOptions
   * NOTE: using orgOptions since they are sorted in the correct order (contrary to orgsData)
   */
  useEffect(() => {
    if (!isEmpty(orgOptions) && requestState.isSuccess && orgsRequest.isSuccess) {
      const [firstOrgOption] = orgOptions
      const orgIdNotInOptions = isNil(orgOptions.find(({ value: id }) => id === organizationId))

      /**
       * If something is wrong with the id
       * First check if it's admin and then take from url
       * then defaulting on favorite org if there is one
       * Then on the default option
       */
      if (orgIdNotInOptions || isNil(organizationId)) {
        if (isAdminPath) {
          handleSetOrgFromAdminPathname()
        } else if (
          !isNil(favoriteOrgId) &&
          !isNil(orgOptions.find(({ value: id }) => id === favoriteOrgId))
        ) {
          handleSetFavoriteOrg()
        } else if (!isNil(firstOrgOption)) {
          handleOrganizationIdChange(firstOrgOption.value)
        }
      }
    }
  }, [
    organizationId,
    requestState.isSuccess,
    favoriteOrgId,
    orgsRequest.isSuccess,
    isAdminPath,
    orgOptions,
    handleSetOrgFromAdminPathname,
    handleSetFavoriteOrg,
    handleOrganizationIdChange
  ])

  return {
    org,
    selectOrgLabel,
    selectChildOrgLabel,
    selectedOrgs,
    handleChildOrganizationIdChange,
    handleOrganizationIdChange,
    orgOptions,
    childOrgOptions,
    isOrgNetwork,
    orgsRequest,
    isLoading,
    network,
    setEnableNetworkOrgRequest
  }
}

export default useSelectOrg
