import { HrefWithoutGoBack } from '@weenat/client/dist/routx/runtime-core'
import { TKey, TransVars, UnionVal, useIntl } from '@weenat/wintl'
import Text, { TextProps } from 'app/src/kit/Text'
import { memo } from 'react'
import Link from './LinkComponent'

type SmartTextProps<K extends TKey = TKey> = {
  tkey: K
  vars?: UnionVal<K, TransVars>
  stopPropagation?: boolean
} & TextProps
const BOLD_SEP = '**'
const allMdLinksRe = /(?:__|[*#])|\[(.*?)\]\(.*?\)/gm
const oneMdLinkRe = /\[([^\[]+)\]\((.*)\)/
const extractMdLinks = (mdLikeStr: string) => {
  const matches = mdLikeStr.match(allMdLinksRe)

  const links: { full: string; text: string; url: string }[] = []

  if (matches) {
    for (let i = 0; i < matches.length; i++) {
      const match = oneMdLinkRe.exec(matches[i])
      if (match) {
        const [full, text, url] = match
        links.push({ full, text, url })
      }
    }
  }
  return links
}

const SmartText = memo(({ tkey, vars, stopPropagation, ...textProps }: SmartTextProps) => {
  const { t } = useIntl()
  // Example of correct markdown like:`**foo** [otherLink](https://google.com) foo **[anotherLink](https://google.com)** bla **bla**`
  const translated = vars
    ? t(tkey, vars)
    : // @ts-expect-error This is ok
      t(tkey)
  const boldSplittedParts = translated.split(BOLD_SEP).filter((x) => x.length !== 0)
  const startWithBold = translated.startsWith(BOLD_SEP)

  return (
    <Text {...textProps}>
      {boldSplittedParts.map((part, i) => {
        const modulo = i % 2
        const isBold = startWithBold ? modulo === 0 : modulo !== 0
        const mdLinks = extractMdLinks(part)
        let linkInterpolatedParts: (React.ReactElement | string)[] = [part]
        if (mdLinks.length > 0) {
          const interpolatedLinks = mdLinks.map((l, i) => {
            const [beforeLink, afterLink] = part.split(l.full)
            const interpolated: (React.ReactElement | string)[] = []

            if (i === 0) {
              interpolated.push(beforeLink)
            } else {
              // We want only the beforeLink but after the previous link
              interpolated.push(beforeLink.split(mdLinks[i - 1].full)[1])
            }

            interpolated.push(
              <Link
                {...textProps}
                {...(stopPropagation
                  ? {
                      onPress: (e) => {
                        e?.stopPropagation()
                      }
                    }
                  : {})}
                key={l.url}
                $underlined
                {...(l.url.startsWith('/')
                  ? { to: l.url as HrefWithoutGoBack }
                  : { href: l.url, target: '_blank' })}
              >
                {l.text}
              </Link>
            )

            if (i === 0 && mdLinks.length === 1) {
              interpolated.push(afterLink)
              // This branch is necessary to correctly transform multiple links in the same part
              /* eslint-disable  sonarjs/no-duplicated-branches */
            } else if (i !== 0 && mdLinks.length > 1) {
              interpolated.push(afterLink)
              /* eslint-enable */
            }
            return interpolated
          })
          linkInterpolatedParts = interpolatedLinks.flatMap((x) => x)
        }

        return isBold ? (
          // eslint-disable-next-line react/no-array-index-key
          <Text {...textProps} $fontWeight='bold' as='strong' key={i}>
            {linkInterpolatedParts}
          </Text>
        ) : (
          linkInterpolatedParts
        )
      })}
    </Text>
  )
})

export default SmartText
