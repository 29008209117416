import { Device, GetManyDevicesQueryParams } from '@weenat/client/dist/resources/devices'
import { Horizon } from '@weenat/client/dist/resources/horizons'
import { Org } from '@weenat/client/dist/resources/orgs'
import { Plot } from '@weenat/client/dist/resources/plots'
import { useSearchParams } from 'app/routx-router'
import { googleMapsPolygonToGeoJSONPolygon } from 'app/src/administration/plots/DrawPlotShapeMap'
import { RestrictionReason } from 'app/src/hooks/useIsAppAccessRestricted'
import { FixedSizeList } from 'react-window'
import { z } from 'zod'
import { plotCreationDataSources, plotCreationSteps } from './constants'

export type GeoJSON = ReturnType<typeof googleMapsPolygonToGeoJSONPolygon>

export const plotCreationSearchParamsSchema = z
  .object({
    brand: z.coerce.number(),
    depths: z.array(z.string()),
    focusedDevice: z.coerce.number(),
    hasConflict: z.preprocess((v) => (v === 'true' ? true : false), z.boolean()),
    latitude: z.coerce.number(),
    longitude: z.coerce.number(),
    metrics: z.array(z.string()),
    ownedByOrganizationId: z.coerce.number(),
    plotId: z.coerce.number(),
    q: z.string(),
    selectedDeviceIds: z.array(z.coerce.number()),
    sortBy: z.string(),
    sortOrder: z.string(),
    source: z.enum(plotCreationDataSources),
    step: z.enum(plotCreationSteps)
  })
  .partial()

type PlotCreationSearchParamsHookType = ReturnType<
  typeof useSearchParams<typeof plotCreationSearchParamsSchema>
>
export type PlotCreationSearchParams = PlotCreationSearchParamsHookType[0]

export type PlotCreationStepProps = {
  searchParams: PlotCreationSearchParams
  add: (id: number, depthValue?: number, horizonId?: number) => void
  remove: (id: number) => void
  focus: (id: number) => void
  search: (val: string) => void
  setOrder: (
    sortBy: GetManyDevicesQueryParams['sortBy'],
    sortOrder: GetManyDevicesQueryParams['sortOrder']
  ) => void
  setFilter: (
    newVals: Pick<GetManyDevicesQueryParams, 'brand' | 'metrics' | 'ownedByOrganizationId'>
  ) => void
  org: Org
  plot?: Plot
  devices: Device[]
  devicesLoading: boolean
  listRef: React.RefObject<FixedSizeList>
  closestDeviceId?: number
  isRestricted: boolean
  reason: RestrictionReason
  shape?: google.maps.Polygon | google.maps.Circle
  autoCompleteRef: React.RefObject<HTMLInputElement>
  drawComponent?: React.ReactNode
  grabComponent?: React.ReactNode
  trashComponent?: React.ReactNode
  plotName?: string
  setPlotName: (newName?: string) => void
  handleDeleteShape: () => void
  horizons: Horizon[]
}
