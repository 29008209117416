import { getDeviceEventName } from '@weenat/client/dist/core/devices/events'
import { DeviceEvent } from '@weenat/client/dist/resources/devices'
import { useIntl } from '@weenat/wintl'
import Icons from 'app/src/kit/Icons'
import Text from 'app/src/kit/Text'
import DeviceEventIcons from 'app/src/kit/icons/DeviceEventIcons'
import isNil from 'lodash-es/isNil'
import moment from 'moment-timezone'
import { styled } from 'styled-components'

const ICON_CIRCLE_SIZE = 48

const IconCircledContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.feedback.error['500']};
  border-radius: ${(props) => props.theme.radiuses.rounded}px;
  width: ${ICON_CIRCLE_SIZE}px;
  height: ${ICON_CIRCLE_SIZE}px;
  padding: 8px;
`

interface LineProps {
  $isFirst?: boolean
  $isLast?: boolean
}

const Line = styled(Box)<LineProps>`
  position: absolute;
  z-index: -1;
  ${({ $isLast: isLast, $isFirst: isFirst }) =>
    isLast
      ? `
          bottom: 50%;
        `
      : isFirst
        ? `
          top: 0px;
        `
        : `
          top: -25%;
        `}
  left: ${ICON_CIRCLE_SIZE / 2 - 2}px;
  width: 4px;
  height: ${({ $isLast }) => ($isLast ? '100%' : '150%')};
  background-color: ${(props) => props.theme.colors.grayscale[300]};
`

const DescriptionBox = styled(Flex)`
  flex-direction: column;
  flex: 1;
  justify-content: center;
  margin-left: 8px;
  background-color: ${(props) => props.theme.colors.grayscale.white};
  border-radius: ${(props) => props.theme.radiuses.md}px;
  border: 2px solid ${(props) => props.theme.colors.grayscale[300]};
  padding: 8px;
`

interface DeviceEventListItemProps extends LineProps {
  event: DeviceEvent
  isSingleEvent?: boolean
}

const DeviceEventListItem: FC<DeviceEventListItemProps> = ({
  event,
  $isFirst,
  $isLast,
  isSingleEvent
}) => {
  const { t } = useIntl()
  const { faultType, start, end } = event

  const Icon = DeviceEventIcons[faultType]

  return (
    <Flex $width='100%' $alignItems='center' $py='md'>
      <Box $position='relative' $zIndex={0}>
        {/* TIMELINE */}
        {!isSingleEvent ? <Line $isFirst={$isFirst} $isLast={$isLast} /> : null}
        {/* ICON */}
        <IconCircledContainer>
          <Icon $size='lg' $color={'grayscale.100'} />
        </IconCircledContainer>
      </Box>

      {/* Description */}
      <DescriptionBox>
        <Text $fontWeight='medium'>{getDeviceEventName(faultType, t)}</Text>
        <Flex $alignItems='center'>
          {!isNil(start) ? (
            <Text $fontSize='sm' $color={'grayscale.700'}>
              {moment.unix(parseInt(start, 10)).format(t('formats.full_datetime_short'))}
            </Text>
          ) : null}
          <Icons.ArrowRight $size='sm' $color={'grayscale.700'} />
          <Text $fontSize='sm' $color={'grayscale.700'}>
            {!isNil(end)
              ? moment.unix(parseInt(end, 10)).format(t('formats.full_datetime_short'))
              : t('models.device.event_on_going')}
          </Text>
        </Flex>
      </DescriptionBox>
    </Flex>
  )
}

export default DeviceEventListItem
