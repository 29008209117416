import { fromColorPathToColor, themes, WeenatColor } from '@weenat/theme'
import Text from 'app/src/kit/Text'
import { isNil } from 'lodash-es'
import { css, styled } from 'styled-components'
import Card, { CardProps } from './Card'
import WeenatPressable from './WeenatPressable'

const { shadows } = themes

const PressableCard = styled(Card)`
  width: 100%;

  padding: 0px;

  border-radius: ${(p) => p.theme.radiuses.lg}px;
  border-color: ${(p) => fromColorPathToColor(p.$borderColor ?? 'grayscale.300')};

  background-color: ${(p) => fromColorPathToColor(p.$backgroundColor ?? 'grayscale.white')};

  will-change: border-color;
  transition: border-color 0.3s ease-in-out;

  min-height: auto;
  height: fit-content;

  pointer-events: ${(p) => p.$pointerEvents};
  opacity: ${(p) => p.$opacity};

  ${(p) =>
    !isNil(p.$boxShadow)
      ? css`
          box-shadow: ${Object.keys(shadows).includes(p.$boxShadow)
            ? p.theme.shadows[p.$boxShadow].boxShadow
            : p.$boxShadow};
        `
      : ''}

  &:hover {
    border-color: ${(p) => p.theme.colors.primary[500]};
    cursor: pointer;
  }
`

interface ChoiceCardProps extends CardProps {
  icon: React.ReactNode
  onPress?: () => void
  title: string
  body: string
  color?: string
  isDisabled?: boolean
}
const borderRadius = { borderRadius: 16 }

const ChoiceCard: FC<ChoiceCardProps> = ({
  onPress,
  icon,
  title,
  body,
  color,
  $boxShadow,
  $borderColor,
  $backgroundColor,
  isDisabled = false,
  children
}) => {
  const finalBackground: WeenatColor | undefined = isDisabled ? 'grayscale.100' : $backgroundColor

  return (
    <PressableCard
      $boxShadow={$boxShadow}
      $borderColor={$borderColor}
      $backgroundColor={finalBackground}
      $pointerEvents={isDisabled ? 'none' : undefined}
      $opacity={isDisabled ? '0.5' : undefined}
    >
      <WeenatPressable onPress={onPress} style={borderRadius}>
        <Flex $gap='lg' $p='lg' $flexDirection='column'>
          <Flex $alignItems='flex-start'>{icon}</Flex>
          <Text $fontWeight='bold' $color={color}>
            {title}
          </Text>
          <Text $color={color}>{body}</Text>
        </Flex>
        {children}
      </WeenatPressable>
    </PressableCard>
  )
}

export default ChoiceCard
