import { useIntl } from '@weenat/wintl'
import Text from 'app/src/kit/Text'
import { useField } from 'formik'
import isArray from 'lodash-es/isArray'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { useTheme } from 'styled-components'
import SelectChip from '../SelectChip'
import FieldLabel from './FieldLabel'
import { TogglePrimitive } from './ToggleField'

type PossibleValue = string | number | undefined

interface ChipGroupFieldOption {
  value: PossibleValue
  label: string
  isDisabled?: boolean
}

interface ChipGroupFieldProps {
  /** Label / Field Name */
  label: string
  name: string
  options: ChipGroupFieldOption[]
  withSelectAll?: boolean
  isRequired?: boolean
  isMonoChoice?: boolean
}

export const ChipGroupPrimitive: FC<
  Pick<
    ChipGroupFieldProps,
    'options' | 'isMonoChoice' | 'withSelectAll' | 'label' | 'isRequired'
  > & {
    value: PossibleValue[] | PossibleValue
    onChange: (newValues?: PossibleValue[] | PossibleValue) => void
  }
> = ({
  label,
  withSelectAll,
  options,
  isRequired = true,
  isMonoChoice = false,
  value,
  onChange
}) => {
  const { t } = useIntl()
  const { colors } = useTheme()

  const isAllSelected =
    !isMonoChoice && !isNil(value) && isArray(value) && !isEmpty(value)
      ? options.every((o) => value.includes(o.value))
      : false

  return (
    <Box $mt='sm' $mb='lg'>
      <Flex $alignItems='center' $gap='lg' $mb='lg'>
        <Box $flex={1}>
          <FieldLabel label={label} isFieldRequired={isRequired} />
          {!isMonoChoice ? (
            <Text $fontSize='sm' $lineHeight='rg'>
              {t('forms.many_choices_available')}
            </Text>
          ) : null}
        </Box>
        {withSelectAll && !isMonoChoice && (
          <TogglePrimitive
            showIcons
            onToggle={() => {
              if (!isAllSelected) {
                onChange(options.map((o) => o.value))
              } else {
                onChange([])
              }
            }}
            value={isAllSelected}
            $backgroundColor={isAllSelected ? colors.feedback.success['500'] : undefined}
            label={t('actions.select_everything', { capitalize: true })}
          />
        )}
      </Flex>
      <Flex $flexWrap='wrap' $gap={12}>
        {options.map((opt) => {
          const isSelected = !isNil(value)
            ? isArray(value) && !isMonoChoice
              ? value.includes(opt.value)
              : value === opt.value
            : false

          const addOptionToValues: React.MouseEventHandler<HTMLDivElement> = (e) => {
            e.stopPropagation()
            if (isArray(value) && !isMonoChoice) {
              onChange([...value, opt.value])
            } else {
              onChange(opt.value)
            }
          }

          const removeOptionFromValues: React.MouseEventHandler<HTMLDivElement> = (e) => {
            e.stopPropagation()
            if (isArray(value) && !isMonoChoice) {
              onChange([...value].filter((v) => v !== opt.value))
            } else {
              onChange(undefined)
            }
          }

          return (
            <SelectChip
              key={opt.value}
              isSelected={isSelected}
              canBeRemoved={isSelected}
              isDisabled={opt.isDisabled}
              onPress={addOptionToValues}
              onRemove={removeOptionFromValues}
            >
              {opt.label}
            </SelectChip>
          )
        })}
      </Flex>
    </Box>
  )
}

const ChipGroupField: FC<ChipGroupFieldProps> = ({
  name,
  options,
  label,
  withSelectAll,
  isRequired = true,
  isMonoChoice = false
}) => {
  const [field, , helpers] = useField<PossibleValue | PossibleValue[]>(name)

  return (
    <ChipGroupPrimitive
      label={label}
      options={options}
      isMonoChoice={isMonoChoice}
      isRequired={isRequired}
      withSelectAll={withSelectAll}
      onChange={helpers.setValue}
      value={field.value}
    />
  )
}

export default ChipGroupField
