import { useIntl } from '@weenat/wintl'
import useLogAndNavToUpgradePlans from 'app/src/hooks/useLogAndNavToUpgradePlans'
import Button from 'app/src/kit/Button'
import Icons from 'app/src/kit/Icons'

const UpgradeToPremiumButton = () => {
  const { t } = useIntl()
  const logAndNav = useLogAndNavToUpgradePlans()

  return (
    <Button IconRight={Icons.ArrowRight} onPress={logAndNav}>
      {t('ecommerce.plans.discover')}
    </Button>
  )
}

export default UpgradeToPremiumButton
