import { styled } from 'styled-components'

const PlotListItemValuesContainer = styled(Flex)<{ $isRestricted: boolean }>`
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  gap: ${(p) => (p.$isRestricted ? '8px' : '0px')};
`

export default PlotListItemValuesContainer
