import Icon, { IconProps } from './Icon'
import DecitraitLeaf from './icons/DecitraitLeaf'
import * as genericIconsMap from './icons/generic-icons'

interface GenericIconProps extends IconProps {
  svg: React.ReactElement
}

const iconMap = {
  ...genericIconsMap,
  DecitraitLeaf
}

const GenericIcon: FC<GenericIconProps> = ({ svg, ...props }) => <Icon {...props}>{svg}</Icon>

/** Bake svg icons with GenericIcon */
function generify() {
  return Object.entries(iconMap).reduce(
    (acc, [key, SvgComp]) => {
      const Compo: FC<IconProps> = (props) => <GenericIcon svg={<SvgComp />} {...props} />
      const compoName = key as keyof typeof iconMap
      Compo.displayName = compoName
      acc[compoName] = Compo
      return acc
    },
    {} as { [k in keyof typeof iconMap]: FC<IconProps> }
  )
}
const Icons = generify()

export default Icons
