function SvgHumidity(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 500 500' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M324.19 227.81c5.078 5.053 5.078 13.304 0 18.383l-130 130a12.909 12.909 0 01-9.179 3.809c-3.338 0-6.665-1.27-9.204-3.809-5.078-5.053-5.078-13.304 0-18.383l130-130c5.08-5.078 13.304-5.078 18.383 0zM172 237.001c0-21.506 17.494-39 39-39s39 17.494 39 39-17.494 39-39 39-39-17.494-39-39zm26 0c0 7.186 5.827 13 13 13s13-5.814 13-13-5.827-13-13-13-13 5.814-13 13zm130 130c0 21.506-17.493 39-39 39-21.506 0-39-17.494-39-39s17.494-39 39-39c21.507 0 39 17.494 39 39zm-26 0c0-7.16-5.84-13-13-13s-13 5.84-13 13 5.84 13 13 13 13-5.84 13-13zm104-65c0 86.023-69.977 156-156 156-86.035 0-156-69.977-156-156 0-61.471 40.715-113.39 83.827-168.34 21.264-27.117 43.266-55.174 60.988-85.263A12.978 12.978 0 01249.999 42c4.595 0 8.86 2.438 11.21 6.399 17.735 30.112 39.724 58.144 61 85.288 43.113 54.946 83.789 106.87 83.789 168.32zm-26 0c0-52.456-38.009-100.95-78.305-152.3-17.571-22.395-35.598-45.449-51.696-70.079-16.097 24.629-34.137 47.658-51.708 70.053-40.283 51.365-78.293 99.836-78.293 152.32 0 71.678 58.322 130 130 130 71.679 0 130-58.322 130-130z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgHumidity
