import { useClient, useMutation } from '@weenat/client'
import { useIntl } from '@weenat/wintl'
import useDisclosure from 'app/src/hooks/useDisclosure'
import Button from 'app/src/kit/Button'
import Card from 'app/src/kit/Card'
import DelimitedFlex from 'app/src/kit/DelimitedFlex'
import InfoCard from 'app/src/kit/InfoCard'
import Modal from 'app/src/kit/Modal'
import Text from 'app/src/kit/Text'
import { TextFieldPrimitive } from 'app/src/kit/fields/TextField'
import { useUserContext } from 'app/userProvider'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { useEffect, useState } from 'react'

const ExternalConnections = () => {
  const { t } = useIntl()
  const { close, isOpen, open } = useDisclosure()
  const [userId, setUserId] = useState<string>()
  const client = useClient()
  const { profile, requestState } = useUserContext()
  const hasActuraId = !isNil(profile?.acturaUserId)

  const [updateActuraInfos, updateActuraInfosState] = useMutation(client.me.updateMyProfile())

  if (updateActuraInfosState.isSuccess) {
    close()
    updateActuraInfosState.reset()
  }

  useEffect(() => {
    if (requestState.isSuccess) {
      setUserId(profile?.acturaUserId)
    }
  }, [requestState.isSuccess])

  return (
    <>
      <Flex $flexDirection='column' $justifyContent='center' $gap='lg'>
        <Box>
          <Text $fontWeight='medium'>{t('actura.name')}</Text>
        </Box>
        <Card>
          <Flex $flexDirection='column' $justifyContent='center' $alignItems='center' $gap='lg'>
            {profile?.acturaEnabled === true ? (
              <>
                <Text>{t('actura.authorized_description')}</Text>
                {hasActuraId && (
                  <Flex $gap='lg'>
                    <Text>{`${t('actura.user_id_label')}:`}</Text>
                    <Text $fontWeight='medium'>{profile?.acturaUserId}</Text>
                  </Flex>
                )}
                <Flex $flexDirection='column' $gap='lg'>
                  <Button onPress={open}>{t('actura.modify_cta')}</Button>
                  <Button
                    onPress={() => {
                      updateActuraInfos({ acturaEnabled: false })
                    }}
                    isDanger
                  >
                    {t('actura.revoke_cta')}
                  </Button>
                </Flex>
              </>
            ) : (
              <>
                <Text>{t('actura.description')}</Text>
                <Button onPress={open}>{t('actura.connection_cta')}</Button>
              </>
            )}
          </Flex>
        </Card>
        <Modal
          isOpen={isOpen}
          hasCloseIcon
          close={close}
          title={t('actura.description')}
          width={600}
        >
          <InfoCard type='tip' message={t('actura.tips')} />
          <TextFieldPrimitive
            value={userId}
            onChange={(e) => setUserId(e.currentTarget.value)}
            label={t('actura.user_id_label')}
            isRequired={false}
          />
          <Flex $justifyContent='flex-end'>
            <Button
              state={updateActuraInfosState}
              onPress={() => {
                updateActuraInfos({
                  acturaEnabled: true,
                  acturaUserId: !isEmpty(userId) ? userId : undefined
                })
              }}
            >
              {t('actions.confirm')}
            </Button>
          </Flex>
        </Modal>
        <DelimitedFlex />
      </Flex>
    </>
  )
}
export const Component = ExternalConnections
export default ExternalConnections
