import Card, { CardProps } from '../kit/Card'

const OnboardingCard: FC<CardProps> = (props) => {
  return (
    <Card
      $alignSelf='center'
      $alignItems='center'
      height='fit-content'
      $gap='lg'
      $boxShadow='none'
      $maxWidth={464}
      {...props}
    />
  )
}

export default OnboardingCard
