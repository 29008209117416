const absoluteStyling = { position: 'absolute', top: -3.5, right: -3.5 }

const ActivityDot = () => {
  return (
    <Box
      $borderRadius={'rounded'}
      $width={12}
      $height={12}
      $backgroundColor={'secondary.500'}
      $borderWidth={2}
      $borderColor={'grayscale.50'}
      style={absoluteStyling}
    />
  )
}

export default ActivityDot
