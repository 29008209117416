import { tours } from '@weenat/client/dist/core/tours'
import { useIntl } from '@weenat/wintl'
import CollapsibleCard from 'app/src/kit/CollapsibleCard'
import Link from 'app/src/kit/LinkComponent'
import Text from 'app/src/kit/Text'
import ReplayTourLink from './ReplayTourLink'

const AppUsageSupportSection = () => {
  const { t } = useIntl()

  return (
    <CollapsibleCard $mb='lg' title={t('help.app_use_title')}>
      <Text $lineHeight='md'>{t('help.app_use_want_to_know_more')}</Text>
      <Text $lineHeight='md'>{t('help.app_use_find_answers')}</Text>
      <Link
        $color={'primary-500'}
        $underlined
        target='_blank'
        href={t('weenat.help.application.generalUsage.faq')}
      >
        {t('help.app_use_consult_guide')}
      </Link>
      <Box $my='lg'>
        <Text $fontWeight='medium'>{t('tours.help_title')}</Text>
      </Box>
      <ul>
        {tours
          .filter((tour) => tour.name !== 'my_farm')
          .map((tour) => (
            <li key={tour.name} style={{ listStyle: 'initial', margin: '0 0 0 20px' }}>
              <ReplayTourLink tour={tour} />
            </li>
          ))}
      </ul>
    </CollapsibleCard>
  )
}
export default AppUsageSupportSection
