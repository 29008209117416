import { keyframes, styled } from 'styled-components'

const fadeIn = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`

const FadeIn = styled(Box)`
  animation: ${fadeIn} 0.3s;
`

export default FadeIn
