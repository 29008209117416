import Card, { CardProps } from 'app/src/kit/Card'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import { ReactNode } from 'react'
import { useTheme } from 'styled-components'
import Icon from './Icon'
import Icons from './Icons'

interface InfoCardProps extends CardProps {
  message?: string | ReactNode
  title?: string
  type?: 'info' | 'help' | 'tip' | 'danger' | 'warning' | 'success'
}

/**
 * Full width text space to explain/present information to the user
 */
const InfoCard: FC<InfoCardProps> = ({
  children,
  $mb = 3,
  message,
  title,
  type = 'info',
  ...props
}) => {
  const { colors } = useTheme()

  let color: string = colors.grayscale.black
  let borderColor: string = colors.grayscale['300']
  let background: string = colors.grayscale.white
  let Icn: typeof Icon = Icons.InfoCircle

  switch (type) {
    case 'danger':
      color = colors.feedback.error['500']
      borderColor = colors.feedback.error['500']
      background = colors.feedback.error['200']
      Icn = Icons.AlertTriangle
      break

    case 'tip':
      color = colors.primary['500']
      borderColor = colors.primary['500']
      background = colors.primary['200']
      Icn = Icons.Lightbulb
      break

    case 'warning':
      color = colors.feedback.warning['500']
      borderColor = colors.feedback.warning['500']
      background = colors.feedback.warning['200']
      Icn = Icons.AlertTriangle
      break

    case 'help':
      color = colors.tertiary['500']
      borderColor = colors.tertiary['500']
      background = colors.tertiary['200']
      Icn = Icons.Help
      break

    case 'success':
      color = colors.feedback.success['500']
      borderColor = colors.feedback.success['500']
      background = colors.feedback.success['200']
      Icn = Icons.CheckCircle
      break
  }

  return (
    <Card
      $width='100%'
      $maxWidth='100%'
      $mb={$mb}
      $backgroundColor={background}
      $borderColor={borderColor}
      $borderWidth='rg'
      $gap='md'
      $alignItems='flex-start'
      {...props}
    >
      <Box $alignSelf='flex-start'>
        <Icn $size='md' $color={color} />
      </Box>

      {!isNil(title) ? (
        <Text $lineHeight='none' $fontSize='md' $fontWeight='medium' $color={color}>
          {title}
        </Text>
      ) : null}

      {typeof message === 'string' ? (
        <Flex $alignItems='flex-start' $justifyContent='flex-start' $py='sm'>
          <Text>{message}</Text>
        </Flex>
      ) : isNil(message) ? null : (
        message
      )}

      {children}
    </Card>
  )
}

export default InfoCard
