import { useIntl } from '@weenat/wintl'
import CollapsibleCard from 'app/src/kit/CollapsibleCard'
import Link from 'app/src/kit/LinkComponent'
import Text from 'app/src/kit/Text'
import { memo } from 'react'

const ConfidentialitySection = () => {
  const { t } = useIntl()

  const linkColor = 'primary.500'

  return (
    <CollapsibleCard title={t('help.confidentiality_title')} $mb='lg'>
      <Box $pt='lg'>
        <Box>
          <Text $fontWeight='bold'>{t('help.confidentiality_personal_title')}</Text>
          <Flex $flexDirection='column' $mb='lg'>
            <Text>{t('help.confidentiality_personal_1')}</Text>
            <Flex>
              <Text>{t('help.confidentiality_personal_2')}</Text>
              &nbsp;
              <Link
                href={t('weenat.help.cnil.userRightsUrl')}
                target='_blank'
                $color={linkColor}
                $fontWeight='medium'
              >
                {t('help.confidentiality_personal_3')}
              </Link>
            </Flex>
          </Flex>
        </Box>
        <Box>
          <Text $fontWeight='bold'>{t('help.confidentiality_data_protection_title')}</Text>
          <Flex $mb='lg'>
            <Link
              $underlined
              target='_blank'
              href={t('weenat.help.termsAndConditions.dataProtection')}
              $color={linkColor}
              $fontWeight='medium'
            >
              {t('help.consult')}
            </Link>
          </Flex>
        </Box>

        <Box>
          <Text $fontWeight='bold'>{t('help.confidentiality_cgu_title')}</Text>
          <Flex $mb='lg'>
            <Text>
              {t('help.confidentiality_consult')}
              &nbsp;
              <Link
                $underlined
                href={t('weenat.help.termsAndConditions.generalConditionsOfUseUrl')}
                target='_blank'
                $color={linkColor}
                $fontWeight='medium'
              >
                {t('help.confidentiality_cgu_link')}
              </Link>
            </Text>
          </Flex>
        </Box>

        <Box>
          <Text $fontWeight='bold'>{t('help.confidentiality_cgv_title')}</Text>
          <Flex>
            <Text>
              {t('help.confidentiality_consult')}
              &nbsp;
              <Link
                $underlined
                href={t('weenat.help.termsAndConditions.generalConditionsOfSaleUrl')}
                target='_blank'
                $color={linkColor}
                $fontWeight='medium'
              >
                {t('help.confidentiality_cgv_link')}
              </Link>
            </Text>
          </Flex>
        </Box>
      </Box>
    </CollapsibleCard>
  )
}

export default memo(ConfidentialitySection)
