function SvgCloudySleepy(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M13.5 39.45c-2.896 0-5.369-1-7.42-2.997C4.025 34.454 3 32.012 3 29.125c0-2.474.748-4.678 2.243-6.613 1.496-1.935 3.452-3.172 5.87-3.71.796-2.919 2.387-5.282 4.773-7.09C18.273 9.904 20.977 9 24 9c3.723 0 6.88 1.292 9.473 3.877 2.594 2.585 3.89 5.734 3.89 9.445 2.196.254 4.018 1.197 5.466 2.83C44.276 26.786 45 28.697 45 30.886c0 2.379-.835 4.401-2.505 6.067-1.67 1.665-3.7 2.497-6.086 2.497H13.5zm0-3.806h22.91c1.336 0 2.465-.46 3.388-1.38.922-.92 1.384-2.046 1.384-3.378 0-1.332-.462-2.458-1.384-3.378-.923-.92-2.053-1.38-3.389-1.38h-2.864v-3.806c0-2.633-.93-4.877-2.79-6.733-1.863-1.855-4.114-2.783-6.755-2.783-2.64 0-4.892.928-6.753 2.783-1.861 1.856-2.793 4.1-2.793 6.733H13.5c-1.845 0-3.42.65-4.725 1.95-1.305 1.301-1.957 2.871-1.957 4.71 0 1.84.652 3.41 1.957 4.711 1.304 1.3 2.88 1.95 4.725 1.95z'
        fill='#002635'
      />
      <path
        d='M26 24c0 .53.316 1.04.879 1.414.562.375 1.325.586 2.121.586s1.559-.21 2.121-.586C31.684 25.04 32 24.53 32 24M16 24c0 .53.316 1.04.879 1.414.562.375 1.325.586 2.121.586s1.559-.21 2.121-.586C21.684 25.04 22 24.53 22 24'
        stroke='#002635'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <circle cx={24} cy={32} r={2} fill='#002635' />
      <path
        d='M25.5 17.5a.5.5 0 000 1v-1zm1.5.5l.447.224A.5.5 0 0027 17.5v.5zm-1 2l-.447-.224A.5.5 0 0026 20.5V20zm1.5.5a.5.5 0 000-1v1zm1.25-5a.5.5 0 000 1v-1zm1.5.5l.447.224a.5.5 0 00-.447-.724v.5zm-1.5 3l-.447-.224a.5.5 0 00.447.724V19zm1.5.5a.5.5 0 000-1v1zm1-7a.5.5 0 000 1v-1zm2.5.5l.424.265a.5.5 0 00-.424-.765v.5zm-2.5 4l-.424-.265a.5.5 0 00.424.765V17zm2.5.5a.5.5 0 000-1v1zm-8.25 1H27v-1h-1.5v1zm1.053-.724l-1 2 .894.448 1-2-.894-.448zM26 20.5h1.5v-1H26v1zm2.75-4h1.5v-1h-1.5v1zm1.053-.724l-1.5 3 .894.448 1.5-3-.894-.448zM28.75 19.5h1.5v-1h-1.5v1zm2.5-6h2.5v-1h-2.5v1zm2.076-.765l-2.5 4 .848.53 2.5-4-.848-.53zM31.25 17.5h2.5v-1h-2.5v1z'
        fill='#018FA4'
      />
    </svg>
  )
}

export default SvgCloudySleepy
