import * as zodSchemas from '@weenat/client/dist/zod-schemas'
import { useIntl } from '@weenat/wintl'
import { useNavigate, useSearchParams } from 'app/routx-router'
import Button from 'app/src/kit/Button'
import Text from 'app/src/kit/Text'
import OnboardingCard from 'app/src/onboardings/OnboardingCard'
import OnboardingNavigationHeader from 'app/src/onboardings/OnboardingNavigationHeader'
import { isNil } from 'lodash-es'
import { z } from 'zod'

export const settings = {
  search: z.object({ invite: zodSchemas.emailInvitation.optional() })
}

const OnboardingNetworks = () => {
  const { t } = useIntl()
  const nav = useNavigate()
  const [{ invite }] = useSearchParams(settings.search)

  return (
    <Flex $flexDirection='column'>
      <OnboardingNavigationHeader />
      <OnboardingCard>
        <Flex $flexDirection='column' $p='lg' $gap='lg'>
          <Text $textAlign='center' $fontSize='md' $fontWeight='bold'>
            {t('onboardings.weather_without_sensor')}
          </Text>
          <Text $textAlign='center'>
            {t(
              isNil(invite)
                ? 'onboardings.manage_your_farm_without_sensor'
                : 'onboardings.manage_your_farm_with_network_stations'
            )}
          </Text>
        </Flex>
        <Flex $flexDirection='column' $gap='lg'>
          <Button
            onPress={() => {
              if (isNil(invite)) {
                nav('/plots/create')
              } else {
                nav('/weather-map')
              }
            }}
          >
            {isNil(invite)
              ? t('models.plot.actions.create_without_sensor', { capitalize: true })
              : t('models.network.actions.discover_network_stations', { capitalize: true })}
          </Button>
          <Button
            importance='sd'
            onPress={() => {
              if (isNil(invite)) {
                nav('/onboarding/networks/find')
              } else {
                nav('/plots/create')
              }
            }}
          >
            {isNil(invite)
              ? t('models.network.actions.discover_networks_close_by', { capitalize: true })
              : t('models.plot.actions.create_without_sensor', { capitalize: true })}
          </Button>
        </Flex>
      </OnboardingCard>
    </Flex>
  )
}

export default OnboardingNetworks
