import { schemas, useClient, useMutation } from '@weenat/client'
import ErrCode from '@weenat/client/dist/ErrCode'
import { useOrderedFields } from '@weenat/client/dist/core'
import { Device } from '@weenat/client/dist/resources/devices'
import { Org, isOrgPremium } from '@weenat/client/dist/resources/orgs'
import { Plot } from '@weenat/client/dist/resources/plots'
import { useIntl } from '@weenat/wintl'
import useDisclosure from 'app/src/hooks/useDisclosure'
import useToasts from 'app/src/hooks/useToasts'
import Card from 'app/src/kit/Card'
import SubmitButton from 'app/src/kit/SubmitButton'
import SuperForm from 'app/src/kit/SuperForm'
import TextField from 'app/src/kit/fields/TextField'
import { FormikHelpers } from 'formik/dist/types'
import { isEmpty } from 'lodash-es'
import isNil from 'lodash-es/isNil'
import { Fragment, useCallback, useState } from 'react'
import DeviceCreationFollowUpActions from './DeviceCreationFollowUpActions'
import FreemiumWithDeviceCTAModal from './FreemiumWithDeviceCTAModal'
import IncompatibleDeviceModal from './IncompatibleDeviceModal'

const SCHEMA = schemas.devices.create
type Fields = typeof SCHEMA.initialValues

const initialValues = { serial: '' }

interface CreateDeviceProps {
  organization: Org
  withoutCard?: boolean
  onDeviceCreated?: (device: Device) => void
  initialSelectedPlots?: Record<string, Plot>
  hideFollowupActions?: boolean
  successMessage?: string
}

const CreateDevice: FC<CreateDeviceProps> = ({
  organization,
  withoutCard = false,
  onDeviceCreated,
  initialSelectedPlots = {},
  hideFollowupActions = false,
  successMessage
}) => {
  const { t } = useIntl()
  const client = useClient()
  const isPremium = isOrgPremium(organization)

  const [formError, setFormError] = useState<string | undefined>(undefined)

  const { addSuccessToast } = useToasts()
  const fieldProps = useOrderedFields(['serial'])

  const [createDevice, createDeviceRequest] = useMutation(client.devices.create())

  const [selectedPlots, setSelectedPlots] = useState(initialSelectedPlots)

  const incompatibleDeviceModal = useDisclosure()

  const followUpModal = useDisclosure()

  const freemiumCTAModal = useDisclosure()

  const onDeviceCreationSuccess = useCallback(
    (device: Device, formikHelpers: FormikHelpers<Fields>) => {
      if (onDeviceCreated) onDeviceCreated(device)

      if (!hideFollowupActions) {
        formikHelpers.resetForm()
        setFormError(undefined)
        setSelectedPlots({})

        if (!isPremium) {
          freemiumCTAModal.open()
        } else {
          followUpModal.open()
        }
      } else {
        addSuccessToast(successMessage ?? t('models.device.actions.create_success'))
      }
    },
    [
      onDeviceCreated,
      hideFollowupActions,
      isPremium,
      freemiumCTAModal,
      followUpModal,
      addSuccessToast,
      successMessage,
      t
    ]
  )

  const handleSubmit = async (values: Fields, formikHelpers: FormikHelpers<Fields>) => {
    await createDevice(
      {
        organizationId: organization.id,
        serial: values.serial,
        plotIds: []
      },
      {
        onSuccess: (device) => {
          onDeviceCreationSuccess(device, formikHelpers)
        },
        onError: (e) => {
          const [firstNonFieldError] = !isNil(e.nonFieldErrors) ? e.nonFieldErrors : []

          if (!isNil(firstNonFieldError)) {
            if (firstNonFieldError.code === ErrCode.deviceIncompatibleWithPlanOrPlot) {
              incompatibleDeviceModal.open()
            } else {
              let message = t(`error_codes.${firstNonFieldError.code as '501'}`)

              if (isEmpty(message) && !isNil(firstNonFieldError.message)) {
                const { message: backendMsg } = firstNonFieldError
                message = backendMsg
              }

              setFormError(message)
            }
          }
        }
      }
    )
  }

  const MaybeCard = withoutCard ? Fragment : Card

  return (
    <>
      <MaybeCard>
        <SuperForm
          onSubmit={handleSubmit}
          schema={SCHEMA}
          initialValues={initialValues}
          enableReinitialize
        >
          <TextField
            {...fieldProps.serial}
            label={t('models.device.fields.serial.label')}
            $isDisabled={createDeviceRequest.isPending}
            $displayedError={formError}
          />
          <Flex $alignItems='center' $justifyContent='flex-end' $width='100%'>
            <SubmitButton state={createDeviceRequest}>{t('actions.add')}</SubmitButton>
          </Flex>
        </SuperForm>
      </MaybeCard>

      {incompatibleDeviceModal.isOpen ? (
        <IncompatibleDeviceModal
          isOpen={incompatibleDeviceModal.isOpen}
          close={incompatibleDeviceModal.close}
        />
      ) : null}

      {followUpModal.isOpen && !isNil(createDeviceRequest.data) ? (
        <DeviceCreationFollowUpActions
          device={createDeviceRequest.data}
          selectedPlots={selectedPlots}
          isOpen={followUpModal.isOpen}
          close={followUpModal.close}
          setSelectedPlots={setSelectedPlots}
        />
      ) : null}

      {freemiumCTAModal.isOpen && !isNil(createDeviceRequest.data) ? (
        <FreemiumWithDeviceCTAModal
          device={createDeviceRequest.data}
          onCloseHandler={() => {
            freemiumCTAModal.close()
            followUpModal.open()
          }}
        />
      ) : null}
    </>
  )
}

export default CreateDevice
