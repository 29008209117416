import { useClient, useQuery } from '@weenat/client'
import { Device, GetDeviceEventQueryParams } from '@weenat/client/dist/resources/devices'
import { useIntl } from '@weenat/wintl'
import useDisclosure from 'app/src/hooks/useDisclosure'
import Collapsible from 'app/src/kit/Collapsible'
import DelimitedFlex from 'app/src/kit/DelimitedFlex'
import Icons from 'app/src/kit/Icons'
import Text from 'app/src/kit/Text'
import TextEllipsisContainer from 'app/src/kit/TextEllipsisContainer'
import LoadingCircle from 'app/src/kit/loaders/LoadingCircle'
import isEmpty from 'lodash-es/isEmpty'
import { useMemo } from 'react'
import { styled } from 'styled-components'
import DeviceEventListItem from './DeviceEventsListItem'

const Container = styled(DelimitedFlex)`
  min-height: auto;
`

interface CollapsibleEventsProps {
  device: Device
}

const CollapsibleEvents: FC<CollapsibleEventsProps> = ({ device }) => {
  const { t } = useIntl()
  const client = useClient()

  const { isOpen, toggle } = useDisclosure()

  const params: GetDeviceEventQueryParams = useMemo(
    () => ({
      deviceId: device.id,
      since: undefined,
      before: undefined,
      faultType: undefined,
      currentEvents: true
    }),
    [device.id]
  )

  const eventsRequest = useQuery(client.devices.getEvents(params))

  const events = eventsRequest.data ?? []

  const label = isEmpty(events)
    ? t('models.event.model.no_items')
    : events.length === 1
      ? t('models.event.model.ongoing_count_singular', { count: 1 })
      : t('models.event.model.ongoing_count_plural', { count: events.length })

  return (
    <Container $flexDirection='column'>
      {/** HEADER */}
      {eventsRequest.isLoading ? (
        <LoadingCircle size={'md'} />
      ) : (
        <Flex
          $justifyContent='space-between'
          $alignItems='center'
          $p='lg'
          $minHeight='auto'
          onClick={toggle}
        >
          <Flex $flex={1} $gap='md' $alignItems='center'>
            <TextEllipsisContainer>
              <Text $ellipsis>{label}</Text>
            </TextEllipsisContainer>
            {isEmpty(events) ? (
              <Icons.CheckCircle $color={'feedback.success.500'} />
            ) : isOpen ? (
              <Icons.Collapse
                $backgroundColor={'grayscale.100'}
                $p='md'
                $size='md'
                onPress={toggle}
              />
            ) : (
              <Icons.Expand
                $backgroundColor={'grayscale.100'}
                $p='md'
                $size='md'
                onPress={toggle}
              />
            )}
          </Flex>
        </Flex>
      )}

      {/** Collapsible */}
      {!isEmpty(events) ? (
        <Collapsible isOpen={isOpen} direction='vertical'>
          <Box $py='md' $px='lg'>
            {events.map((e, i) => (
              <DeviceEventListItem
                key={e.faultType.toString()}
                event={e}
                $isFirst={i === 0}
                $isLast={i === events.length - 1}
              />
            ))}
          </Box>
        </Collapsible>
      ) : null}
    </Container>
  )
}

export default CollapsibleEvents
