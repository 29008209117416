import { Me } from '@weenat/client/dist/resources/me'
import { useIntl } from '@weenat/wintl'
import { useNavigate } from 'app/routx-router'
import Avatar from 'app/src/kit/Avatar'
import Card from 'app/src/kit/Card'
import DelimitedFlex from 'app/src/kit/DelimitedFlex'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import { styled } from 'styled-components'
import Icons from '../kit/Icons'

const ContainerCard = styled(Card)`
  padding: 0px;
  border-radius: ${(p) => p.theme.radiuses.lg}px;
  border: 1px solid ${(p) => p.theme.colors.grayscale[300]};

  box-shadow: none;
  overflow: hidden;

  &:hover {
    border-color: ${(p) => p.theme.colors.primary[500]};
    background-color: ${(p) => p.theme.colors.primary[200]};
  }

  transition: all 0.3s linear;
`

interface ProfileCardProps {
  me: Me
  hideFooter?: boolean
  className?: string
  onNav?: () => void
}

const ProfileCard: FC<ProfileCardProps> = ({ me, className, hideFooter = false, onNav }) => {
  const nav = useNavigate()
  const { t } = useIntl()

  return !isNil(me) ? (
    <ContainerCard
      onPress={() => {
        nav('/me/profile')
        onNav?.()
      }}
      className={className}
    >
      <Flex $p='lg' $alignItems='center' $gap='md'>
        <Avatar me={me} size='lg' />
        <Flex $flex={1} $flexDirection='column'>
          <Text $color={'grayscale.black'} $fontWeight='bold'>
            {`${me.firstName} ${me.lastName}`}
          </Text>
          <Text $color={'grayscale.black'} $fontWeight='light'>
            {me.email}
          </Text>
        </Flex>
      </Flex>
      {hideFooter ? null : (
        <DelimitedFlex
          $isDelimitedOnTop
          $p='md'
          $gap='lg'
          $justifyContent='center'
          $alignItems='center'
        >
          <Text $color={'grayscale.700'} $fontSize='sm' $textAlign='center'>
            {t('me_menu.edit_profil_cta')}
          </Text>
          <Icons.ArrowRight $color={'grayscale.700'} $size='md' />
        </DelimitedFlex>
      )}
    </ContainerCard>
  ) : null
}

export default ProfileCard
