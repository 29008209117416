import { Tour } from '@weenat/client/dist/core/tours'
import { Universe } from '@weenat/client/dist/core/universes'
import { useOrgContext } from 'app/orgProvider'
import { useUniverse } from 'app/state'
import isNil from 'lodash-es/isNil'
import { useCallback } from 'react'
import useUniverseOptionsQuery from './useUniverseOptionsQuery'

/**
 * Return a handler that take a tour & setup the appropriate universe if it is irrigation
 */
const useIrrigationUniverseTourSetup = () => {
  const { currentOrgId } = useOrgContext()

  const { universeOptions, universesRequest } = useUniverseOptionsQuery(currentOrgId, 'plots')
  const [universe, setUniverse] = useUniverse()

  /**
   * Return true if the tour setup was played, or was not necessary to play, otherwise false
   */
  const handleTourSetup = useCallback(
    (tour: Tour) => {
      if (tour.name === 'discover_irrigation') {
        if (universe === Universe.irrigation) return true
        else if (universeOptions.some((opt) => opt.value === Universe.irrigation)) {
          setUniverse(Universe.irrigation)
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    [setUniverse, universe, universeOptions]
  )

  const isEnabled = !isNil(universesRequest.data)

  return { handleTourSetup, isEnabled }
}

export default useIrrigationUniverseTourSetup
