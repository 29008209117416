import { useClient, useQuery } from '@weenat/client'
import { PlotOrStationSummary } from '@weenat/client/dist/resources/measurements'
import { isPlotSummary } from '@weenat/client/dist/resources/measurements.type'
import { Plot } from '@weenat/client/dist/resources/plots'
import { Id } from '@weenat/client/dist/resources/types'
import { GMCircle, GMMultiPolygon, GMPolygon, drawShape } from 'app/src/map/utils'
import isArray from 'lodash-es/isArray'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { useEffect, useRef, useState } from 'react'
import { useDeepCompareEffect } from 'react-use'
import { useTheme } from 'styled-components'
import { useBackgroundMapContext } from '../contexts/BackgroundMapContext'

const MIN_ZOOM_FOR_SHAPES = 12
const EMPTY_SHAPES: Plot['shape'][] = []

const getSummaryMetadataItem = (summary: PlotOrStationSummary) => {
  return isPlotSummary(summary) ? summary.metadata.plot : summary.metadata.station
}

interface UseDrawVisiblePlotShapesArg {
  currentOrganizationId: Id | null
  summaries: PlotOrStationSummary[]
}

const useDrawVisiblePlotShapes = ({
  currentOrganizationId,
  summaries
}: UseDrawVisiblePlotShapesArg) => {
  const theme = useTheme()
  const client = useClient()

  const {
    api: { map, maps }
  } = useBackgroundMapContext()

  const [visiblePlotsIds, setVisiblePlotsIds] = useState<Id[]>([])
  const drawnShapes = useRef<(GMCircle | GMPolygon | GMMultiPolygon)[]>([])

  const visiblePlotsRequest = useQuery(
    client.plots.getAllPages({
      ids: visiblePlotsIds,
      withShape: true,
      organizationId: currentOrganizationId as number
    }),
    {
      enabled: !isEmpty(visiblePlotsIds) && !isNil(currentOrganizationId)
    }
  )

  const visibleShapes = !isNil(visiblePlotsRequest.data)
    ? visiblePlotsRequest.data.map((p) => p.shape)
    : EMPTY_SHAPES

  const removeDrawnShapes = () => {
    drawnShapes.current.forEach((drawnShape) => {
      if (!isNil(drawnShape)) {
        if (isArray(drawnShape)) {
          drawnShape.forEach((gmShape) => {
            gmShape.setMap(null)
          })
        } else {
          drawnShape.setMap(null)
        }
      }
    })
    drawnShapes.current = []
  }

  const summaryDependencyString = summaries
    .map((summary) => getSummaryMetadataItem(summary).id)
    .join('-')

  // Setting up a listener on map bounds
  // Every time it changes we want to gather marker ids of those inside the bounds
  useEffect(() => {
    if (!isNil(maps) && !isNil(map)) {
      // The handler should not be extract in its own function, otherwise weird (stale data) bugs happen
      const listener = maps.event.addListener(map, 'bounds_changed', () => {
        const zoom = map.getZoom()

        if (!isEmpty(summaries) && !isNil(zoom) && zoom > MIN_ZOOM_FOR_SHAPES) {
          const bounds = map.getBounds()

          const newIds = summaries.reduce((acc, marker) => {
            const summary = marker

            const { location } = getSummaryMetadataItem(summary)

            if (!isNil(location) && !isNil(bounds)) {
              const [lng, lat] = location.coordinates
              if (bounds.contains(new maps.LatLng({ lat, lng }))) {
                acc.add(getSummaryMetadataItem(summary).id)
              }
            }

            return acc
          }, new Set<Id>())

          setVisiblePlotsIds(Array.from(newIds))
        } else {
          removeDrawnShapes()
        }
      })

      return () => {
        removeDrawnShapes()
        maps.event.removeListener(listener)
      }
    }

    return () => {
      removeDrawnShapes()
    }
  }, [map, summaryDependencyString])

  // Every time a fetch towards plots occurs, we redraw the shapes
  useDeepCompareEffect(() => {
    removeDrawnShapes()

    if (!isNil(map) && !isNil(maps) && !isEmpty(visibleShapes)) {
      visibleShapes.forEach((shape) => {
        if (!isNil(shape)) {
          const drawing = drawShape({
            map,
            maps,
            theme,
            shape
          })

          drawnShapes.current.push(drawing.shape)
        }
      })
    }
  }, [visibleShapes])
}

export default useDrawVisiblePlotShapes
