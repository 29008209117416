import { Slot } from 'app/routx-router'
import PublicLayout from 'app/src/layouts/PublicLayout'

const HelpLayout: FC = () => {
  return (
    <PublicLayout>
      <Slot />
    </PublicLayout>
  )
}
export const Component = HelpLayout
export default HelpLayout
