import { HTMLProps } from 'react'
import { styled } from 'styled-components'

const Pressable = styled.div`
  user-select: none;

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.primary[200]};
  }

  // without it content is shrink to 0 even if pressable content has big height
  // example in create plot step:"choose datasource" on small heights
  min-height: auto;
`

interface WeenatPressableProps extends Omit<HTMLProps<HTMLDivElement>, 'onClick'> {
  onPress: HTMLProps<HTMLDivElement>['onClick']
}

const WeenatPressable: FC<WeenatPressableProps> = ({ onPress, ...divProps }) => {
  return (
    <Pressable
      role='button'
      onClick={(e) => {
        e.stopPropagation()
        onPress?.(e)
      }}
      {...divProps}
    />
  )
}

export default WeenatPressable
