import { useQuery } from '@tanstack/react-query'
import { mergeQueryState, usePaginatedClient } from '@weenat/client'
import { Requester } from '@weenat/client/dist/Client'
import { PossibleError } from '@weenat/client/dist/errors'
import {
  PaginatedResponse,
  PaginationQueryParams,
  SearchableQueryParams
} from '@weenat/client/dist/resources/types'
import { useIntl } from '@weenat/wintl'
import {
  FilterCategory,
  ListFilter,
  SortCategory,
  SortOption,
  fromFilterCategoryToQueryParams,
  fromSortOptionCategoryToQueryParams
} from 'app/utils/lists'
import { cloneDeep, merge, noop } from 'lodash-es'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { useCallback, useMemo, useState } from 'react'
import { css, styled } from 'styled-components'
import { JsonObject } from 'type-fest'
import useDebounce from '../hooks/useDebounce'
import FiltersBar from './FiltersBar'
import ListEmpty from './ListEmpty'
import LoadingList from './LoadingList'
import PaginationControls from './PaginationControls'
import SearchQueryForm from './SearchQueryForm'
import SortOptionsMenu from './SortOptionsMenu'
import Table, { TableColumn, TableProps } from './Table'
import { FlexProps } from './primitives/themeMappings/props'

interface CommonItem {
  key?: string
  id: number
}

const ItemContainer = styled(Flex)`
  flex-direction: column;
  flex: 1;
  ${({ $height: height }) =>
    !isNil(height)
      ? css`
          max-height: ${height}px;
          overflow-y: auto;
        `
      : ''}
`

function defaultKeyExtractor<T extends CommonItem>(item: T) {
  return !isNil(item.key) ? item.key : item.id.toString()
}

type Variant = 'list' | 'table'

export interface PaginatedAndSearchableParams
  extends PaginationQueryParams,
    SearchableQueryParams {}

const EMPTY_FILTER_CATEGORIES: FilterCategory[] = []
const EMPTY_SORT_OPTIONS_CATEGORIES: SortCategory[] = []
const EMPTY_COLUMNS: TableColumn[] = []
const DEFAULT_NUMBER_OF_ITEMS_PER_PAGE = 20

export interface AdvancedClientListProps<T, Q extends PaginatedAndSearchableParams, E> {
  renderItem: (item: T) => React.ReactNode
  /** Usually resource.getPage */
  requesterBuilder: (
    page: number,
    params: Q
  ) => Requester<PaginatedResponse<T>, E, string | JsonObject>
  ListEmptyComponent: React.ReactNode
  initialQueryParams?: Q
  header?: React.ReactNode
  searchable?: boolean
  hideBottomNav?: boolean
  hideTopNav?: boolean
  withSkeleton?: boolean
  searchLabel?: string
  /** Kind of list to display: a normal list or a table */
  variant?: Variant
  /** When using tables declare the columns */
  columns?: TableProps<T>['columns']
  /** When using tables declare the getLinkFromItem */
  getLinkFromItem?: TableProps<T>['getLinkFromItem']
  /** Used for empty states */
  model?: TableProps<T>['model']
  /** Limit height of list to allow scrollable content on items */
  height?: number
  /** Whether or not the component should trigger fetches - defaults to true */
  enableQueries?: boolean
  /** Custom key extractor */
  keyExtractor?: (item: T) => string | number
  /** Categories of filters to display */
  filterCategories?: FilterCategory[]
  /** Categories of sorting options to display */
  sortOptionsCategories?: SortCategory[]
  filtersBarMargin?: FlexProps['$mx']
}

function AdvancedClientList<T extends CommonItem, Q extends PaginatedAndSearchableParams, E>({
  ListEmptyComponent,
  columns = EMPTY_COLUMNS,
  enableQueries = true,
  filterCategories = EMPTY_FILTER_CATEGORIES,
  height,
  hideBottomNav,
  hideTopNav,
  initialQueryParams,
  keyExtractor,
  model,
  getLinkFromItem,
  renderItem,
  requesterBuilder,
  searchLabel,
  searchable,
  sortOptionsCategories = EMPTY_SORT_OPTIONS_CATEGORIES,
  variant = 'list',
  withSkeleton,
  filtersBarMargin
}: AdvancedClientListProps<T, Q, E>) {
  const { t } = useIntl()

  const [searchInputValue, setSearchInputValue] = useState<string | undefined>(undefined)
  const [paginationPageToFetch, setPaginationPageToFetch] = useState<string | undefined | null>(
    undefined
  )

  const debouncedSearchInputValue = useDebounce(searchInputValue, 500)

  const numberOfItemsPerPage = initialQueryParams?.limit ?? DEFAULT_NUMBER_OF_ITEMS_PER_PAGE

  const [localFiltersCategories, setLocalFiltersCategories] = useState<FilterCategory[]>(
    cloneDeep(filterCategories)
  )
  const [localSortOptionsCategories, setLocalSortOptionsCategories] = useState<SortCategory[]>(
    cloneDeep(sortOptionsCategories)
  )

  const queryParams: Q = useMemo(() => {
    const paramsFromFilters = localFiltersCategories.reduce((acc, fc) => {
      const newParam = fromFilterCategoryToQueryParams({ category: fc })

      return merge(acc, newParam)
    }, {} as Q)

    const sortOptionsParams = localSortOptionsCategories.reduce((acc, soc) => {
      const newParam = fromSortOptionCategoryToQueryParams({ category: soc })

      return merge(acc, newParam)
    }, {} as Q)

    return {
      ...initialQueryParams,
      ...paramsFromFilters,
      ...sortOptionsParams,
      ...(isEmpty(debouncedSearchInputValue) ? {} : { q: debouncedSearchInputValue }),
      limit: numberOfItemsPerPage
    }
  }, [
    debouncedSearchInputValue,
    initialQueryParams,
    localFiltersCategories,
    localSortOptionsCategories,
    numberOfItemsPerPage
  ])

  let data: PaginatedResponse<T> | undefined = undefined

  const requester = requesterBuilder(0, queryParams)
  const firstRequest = usePaginatedClient(requester, {
    enabled: enableQueries && isNil(paginationPageToFetch)
  })

  const paginationRequest = useQuery<unknown, PossibleError, PaginatedResponse<T>>({
    queryKey: [paginationPageToFetch ?? 'EMPTY'],
    queryFn: !isNil(paginationPageToFetch)
      ? () => requester.fetchAndTransform(paginationPageToFetch)
      : noop,

    enabled: !isNil(paginationPageToFetch)
  })

  data = !isNil(paginationPageToFetch)
    ? paginationRequest.data ?? firstRequest.data
    : firstRequest.data

  const hasPrevious = !isNil(data?.previous)
  const hasNext = !isNil(data?.next)

  const offset =
    !isNil(data) && !isNil(data.previous)
      ? new URL(data.previous).searchParams.get('offset') ?? `0`
      : undefined

  const startOfPage = (!isNil(offset) ? Number.parseInt(offset, 10) + numberOfItemsPerPage : 0) + 1
  const endOfPage = !isNil(data) && !isNil(data.results) ? startOfPage - 1 + data.results.length : 0

  const { isLoading } = mergeQueryState(firstRequest, paginationRequest)

  const totalNumberOfItems = !isNil(data) ? data.count : undefined
  const hasItems = !isNil(data) && !isNil(data.results) && data.results.length !== 0

  const isNotFirstPage = startOfPage === 1

  const shouldShowBottomPaginationControls = !hideBottomNav && hasItems && (hasPrevious || hasNext)
  const shouldShowTopPaginationControls = !hideTopNav && (hasItems || (!hasItems && isNotFirstPage))

  const isPendingAndHasNoItems = isLoading && !hasItems

  /**
   * Using the categories from props to reset the chosen category
   */
  const onFilterCategoryReset = (categoryToReset: FilterCategory) => {
    if (!isEmpty(filterCategories)) {
      const updatedFiltersCategories = localFiltersCategories.map((fc) => {
        if (fc.id === categoryToReset.id) {
          const defaultCategory = filterCategories.find(
            (originalCategory) => originalCategory.id === categoryToReset.id
          )

          if (!isNil(defaultCategory)) return cloneDeep(defaultCategory)
        }
        return fc
      }, [] as FilterCategory[])

      setLocalFiltersCategories(updatedFiltersCategories)
    }
  }

  /**
   * Updating the local filters depending on the category
   */
  const onFilterSelection = (category: FilterCategory, filter: ListFilter) => {
    const updatedFiltersCategories = localFiltersCategories.map((newCategory) => {
      if (newCategory.id === category.id) {
        const currentFilterIndex = category.filters.findIndex((f) => f.id === filter.id)

        if (currentFilterIndex >= 0) {
          // inverting the state of the selected filter
          if (category.multi) {
            newCategory.filters[currentFilterIndex].active =
              !category.filters[currentFilterIndex].active
          }
          // resetting the state of all other filters and updating the one selected
          else {
            newCategory.filters = category.filters.map((currentFilter, index) => {
              currentFilter.active = index === currentFilterIndex

              return currentFilter
            })
          }
        }
      }

      return newCategory
    })

    setLocalFiltersCategories(updatedFiltersCategories)
  }

  const onSortOptionSelection = (category: SortCategory, option: SortOption) => {
    const updatedSortOptionsCategories = localSortOptionsCategories.map((newCategory) => {
      if (newCategory.id === category.id) {
        const currentFilterIndex = category.options.findIndex((f) => f.id === option.id)

        if (currentFilterIndex >= 0) {
          newCategory.options = category.options.map((currentFilter, index) => {
            if (index === currentFilterIndex) {
              currentFilter.active = !currentFilter.active
            } else {
              currentFilter.active = false
            }
            return currentFilter
          })
        }
      } else {
        newCategory.options = newCategory.options.map((currentFilter) => {
          currentFilter.active = false
          return currentFilter
        })
      }

      return newCategory
    })

    setLocalSortOptionsCategories(updatedSortOptionsCategories)
  }

  const setSearchQueryAndResetPagination = useCallback(
    (value: string) => {
      setSearchInputValue(value)
      // Resetting pagination state when the search changes since it will bring a new set of results
      if (!isEmpty(value) && !isNil(paginationPageToFetch)) {
        setPaginationPageToFetch(undefined)
      }
    },
    [paginationPageToFetch]
  )

  const searchInput = (
    <SearchQueryForm
      onQueryChange={setSearchQueryAndResetPagination}
      placeholder={!isNil(searchLabel) ? searchLabel : t('actions.search')}
    />
  )

  const paginationControls = (
    <PaginationControls
      hasNext={hasNext}
      hasPrevious={hasPrevious}
      handleNextClick={() => setPaginationPageToFetch(data?.next)}
      handlePreviousClick={() => setPaginationPageToFetch(data?.previous)}
      isLoading={isLoading}
      isPendingAndHasNoItems={isPendingAndHasNoItems ?? false}
      totalNumberOfItems={totalNumberOfItems as number}
      startOfPage={startOfPage}
      endOfPage={endOfPage}
    />
  )

  return (
    <>
      {searchable && searchInput}
      {shouldShowTopPaginationControls ||
      !isEmpty(localFiltersCategories) ||
      !isEmpty(sortOptionsCategories) ? (
        <Flex
          $alignItems='center'
          $justifyContent='space-between'
          $flexWrap='wrap'
          $py='md'
          $gap='lg'
          $mx={filtersBarMargin}
        >
          <Box $flex={1}>
            {!isEmpty(localFiltersCategories) ? (
              <FiltersBar
                categories={localFiltersCategories}
                onFilterSelection={onFilterSelection}
                onFilterCategoryReset={onFilterCategoryReset}
              />
            ) : null}
          </Box>
          {!isEmpty(sortOptionsCategories) ? (
            <SortOptionsMenu
              categories={localSortOptionsCategories}
              onSortOptionSelection={onSortOptionSelection}
            />
          ) : null}
          {shouldShowTopPaginationControls && paginationControls}
        </Flex>
      ) : null}

      {variant === 'table' ? (
        <Table
          data={data?.results}
          renderRow={renderItem}
          keyExtractor={
            !isNil(keyExtractor) ? keyExtractor : (item: T) => defaultKeyExtractor(item)
          }
          tableName='client-list-table'
          isLoading={isLoading}
          columns={columns}
          getLinkFromItem={
            !isNil(getLinkFromItem) ? (item: T | undefined) => getLinkFromItem(item) : undefined
          }
          model={model}
          emptyMessage={
            !isEmpty(debouncedSearchInputValue) && !isNil(debouncedSearchInputValue)
              ? t('map.no_summaries_matching_search_term', {
                  searchTerm: debouncedSearchInputValue
                })
              : undefined
          }
          isSearchEmpty={isEmpty(debouncedSearchInputValue)}
        />
      ) : (
        <ItemContainer $height={height}>
          {isLoading ? (
            withSkeleton ? (
              <LoadingList
                variant='single-line'
                size={numberOfItemsPerPage}
                uniqueKey='client-list-skeleton'
              />
            ) : null
          ) : hasItems ? (
            data?.results.map(renderItem)
          ) : !isEmpty(debouncedSearchInputValue) && !isNil(debouncedSearchInputValue) ? (
            <ListEmpty
              model={model}
              message={t('map.no_summaries_matching_search_term', {
                searchTerm: debouncedSearchInputValue
              })}
              mood='sad'
            />
          ) : (
            ListEmptyComponent
          )}
        </ItemContainer>
      )}
      {shouldShowBottomPaginationControls && paginationControls}
    </>
  )
}
export default AdvancedClientList
