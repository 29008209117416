import { useQuery } from '@tanstack/react-query'
import * as zodSchemas from '@weenat/client/dist/zod-schemas'
import { useIntl } from '@weenat/wintl'
import { useSearchParams } from 'app/routx-router'
import Card from 'app/src/kit/Card'
import MapBackgroundImage from 'app/src/kit/MapBackgroundImage'
import Text from 'app/src/kit/Text'
import Cloudy from 'app/src/kit/icons/Cloudy'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'

export const settings = {
  search: zodSchemas.url
}

let triggered = false

const Export = () => {
  const [searchParams] = useSearchParams(settings.search)
  const { url } = searchParams
  const { t } = useIntl()
  const urlIsDefined = !isNil(url) && !isEmpty(url) && typeof url === 'string'
  const { status } = useQuery({
    queryKey: urlIsDefined ? url : 'export',
    queryFn: async () => {
      if (urlIsDefined) {
        const response = await fetch(url, { method: 'GET', headers: { Range: `bytes=0-0` } })
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response
      }
    },
    retry: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  })

  const isError = status === 'error'
  if (status === 'success' && urlIsDefined && !triggered) {
    // this is an external link followed throught js so we cannot use router here
    // eslint-disable-next-line no-restricted-properties
    window.location.replace(url)
    triggered = true
  }
  return (
    <>
      <Flex
        $width='100%'
        $height='100%'
        $flexDirection='column'
        $justifyContent='center'
        $alignItems='center'
      >
        <Card $maxWidth='50vw'>
          <Flex $flexDirection='column' $alignItems='center' $justifyContent='center' $gap='lg'>
            {isError || !urlIsDefined ? <Cloudy.sad $size='2xl' /> : <Cloudy.happy $size='2xl' />}
            <Text>
              {isError
                ? t('export_menu.expired_link')
                : urlIsDefined
                  ? t('export_menu.ongoing_download')
                  : t('export_menu.invalid_link')}
            </Text>
          </Flex>
        </Card>
      </Flex>
      <MapBackgroundImage />
    </>
  )
}

export const Component = Export
export default Export
