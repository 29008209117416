import { useIsPartOfANetwork } from '@weenat/client/dist/core/orgs'
import { useIntl } from '@weenat/wintl'
import HelmetAndBreadcrumbs from 'app/src/kit/HelmetAndBreadcrumbs'
import Text from 'app/src/kit/Text'
import LoadingCircle from 'app/src/kit/loaders/LoadingCircle'
import LayoutHeaderActions from 'app/src/layouts/LayoutHeaderActions'
import NetworksList from 'app/src/networks/NetworksList'
import { useToken } from 'app/state'

function Networks() {
  const { t } = useIntl()
  const [token] = useToken()

  const title = t('models.network.model.name', { capitalize: true })

  const {
    orgsRequest,
    isPartOfANetwork,
    canAccessANetworkOrg: hasSomeNetworkOrg,
    canAccessAnOrgInsideANetwork: hasSomeNetworkSubscribedOrg
  } = useIsPartOfANetwork(token)

  const shouldNotShowNetworksListText = hasSomeNetworkOrg
    ? t('models.network.actions.hiding_network_list_because_has_network')
    : hasSomeNetworkSubscribedOrg
      ? t('models.network.actions.hiding_network_list_because_has_network_subscribed_org')
      : null

  return (
    <>
      <LayoutHeaderActions />
      <HelmetAndBreadcrumbs screenTitle={title} />
      {orgsRequest.isLoading ? (
        <LoadingCircle />
      ) : isPartOfANetwork ? (
        <Box $px='lg'>
          <Text $fontSize='lg' $textAlign='center'>
            {shouldNotShowNetworksListText}
          </Text>
        </Box>
      ) : (
        <NetworksList />
      )}
    </>
  )
}

export const Component = Networks
export default Networks
