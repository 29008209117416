import { getDeviceCommonName } from '@weenat/client/dist/core/devices'
import { BillingStatus } from '@weenat/client/dist/enums'
import { Device } from '@weenat/client/dist/resources/devices'
import { useIntl } from '@weenat/wintl'
import { useNavigate } from 'app/routx-router'
import CollapsibleEvents from 'app/src/administration/devices/CollapsibleEvents'
import CloseButton from 'app/src/kit/CloseButton'
import DelimitedFlex from 'app/src/kit/DelimitedFlex'
import StackedMetrics from 'app/src/kit/StackedMetrics'
import Text from 'app/src/kit/Text'
import { ChipGroupPrimitive } from 'app/src/kit/fields/ChipGroupField'
import AddOrRemoveDeviceButton from 'app/src/plots/creation/AddOrRemoveDeviceButton'
import { plot_creation_href, plot_modification_href } from 'app/src/plots/creation/constants'
import { PlotCreationStepProps } from 'app/src/plots/creation/types'
import { getDeviceHorizonId, showHorizonForDevice } from 'app/src/plots/creation/utils'
import isNil from 'lodash-es/isNil'
import { useEffect, useState } from 'react'
import Card from '../kit/Card'
import Icons from '../kit/Icons'

interface FocusedDeviceDetailsCardProps
  extends Pick<PlotCreationStepProps, 'add' | 'remove' | 'horizons' | 'searchParams'> {
  device: Device
  billingStatus?: BillingStatus
}

const FocusedDeviceDetailsCard: FC<FocusedDeviceDetailsCardProps> = ({
  device,
  searchParams,
  billingStatus,
  add,
  remove,
  horizons
}) => {
  const nav = useNavigate()
  const { t } = useIntl()
  const plot_url = !isNil(searchParams.plotId) ? plot_modification_href : plot_creation_href

  const [horizonId, setHorizonId] = useState<number | undefined>(undefined)

  const focusedDeviceIsSelected = !isNil(device)
    ? searchParams.selectedDeviceIds?.includes(device.id)
    : false

  const horizonIdFromPath = getDeviceHorizonId(searchParams, device)

  const commonName = getDeviceCommonName(device)
  const technicalName = device.endUserName.join(' ')

  const showHorizons = showHorizonForDevice(device)

  useEffect(() => {
    setHorizonId(!isNil(horizonIdFromPath) ? parseInt(horizonIdFromPath, 10) : undefined)
  }, [horizonIdFromPath])

  return (
    <Card $p={0} $pointerEvents='auto' style={{ display: 'flex', flexDirection: 'column' }}>
      <DelimitedFlex $alignItems='center' $p='lg' $gap='md'>
        <Box $flex={1}>
          <Text $fontWeight='bold'>{commonName}</Text>
          <Text>{technicalName}</Text>
        </Box>
        <CloseButton
          $size='lg'
          $p='md'
          onPress={() => {
            nav(plot_url, { search: { ...searchParams, focusedDevice: undefined } })
          }}
        />
      </DelimitedFlex>

      <Flex $flexDirection='column' style={{ overflowY: 'auto' }} $flex={1}>
        <DelimitedFlex $gap='md' $alignItems='center' $p='lg' $minHeight='auto'>
          <Icons.LocationPin $size='md' $p='sm' />
          {!isNil(device.reverseGeocoding) ? device.reverseGeocoding : '-'}
        </DelimitedFlex>
        {showHorizons ? (
          <DelimitedFlex $flexDirection='column' $p='lg' $minHeight='auto'>
            <Flex $alignItems='center' $gap='md'>
              <Icons.Depth $size='md' $p='sm' />
              <Text>{t('dss.irritensio.dss.depth')}</Text>
            </Flex>
            <ChipGroupPrimitive
              onChange={(newVal) => {
                if (typeof newVal === 'number') {
                  setHorizonId(newVal)
                }
              }}
              value={horizonId}
              label=''
              isMonoChoice
              options={horizons.map((horizon) => ({
                label: `${horizon.value}`,
                value: horizon.id
              }))}
            />
          </DelimitedFlex>
        ) : null}
        <DelimitedFlex $gap='md' $alignItems='center' $p='lg' $minHeight='auto'>
          <StackedMetrics metrics={device.availableMeasures} />
          <Text>
            {device.availableMeasures.length === 0
              ? t('models.metric.model.no_items')
              : `${device.availableMeasures.length} ${t(
                  device.availableMeasures.length === 1
                    ? 'models.metric.model.singular_name'
                    : 'models.metric.model.name'
                )}`}
          </Text>
        </DelimitedFlex>
        <CollapsibleEvents device={device} />
      </Flex>
      {!isNil(billingStatus) && (
        <Flex $p='lg' $justifyContent='center' $minHeight='auto'>
          <AddOrRemoveDeviceButton
            isSelected={focusedDeviceIsSelected}
            device={device}
            showHorizons={false}
            billingStatus={billingStatus}
            onAdd={() => add(device.id, horizons.find((h) => h.id === horizonId)?.value, horizonId)}
            onRemove={() => {
              remove(device.id)
              setHorizonId(undefined)
            }}
            isDisabled={showHorizons ? isNil(horizonId) : false}
            horizons={horizons}
            depth=''
          />
        </Flex>
      )}
    </Card>
  )
}

export default FocusedDeviceDetailsCard
