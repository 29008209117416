import { useClient, useQuery } from '@weenat/client'
import { useCanCreateMorePlots } from '@weenat/client/dist/core/plots'
import { Device } from '@weenat/client/dist/resources/devices'
import { Horizon } from '@weenat/client/dist/resources/horizons'
import { useIntl } from '@weenat/wintl'
import { useOrgContext } from 'app/orgProvider'
import { useNavigate, useSearchParams } from 'app/routx-router'
import ControlsContainer from 'app/src/dashboard/components/DashboardMap/ControlsContainer'
import {
  useBackgroundMapContext,
  useBackgroundMapDispatcher
} from 'app/src/dashboard/components/DashboardMap/contexts/BackgroundMapContext'
import DeviceMarker from 'app/src/dashboard/components/DashboardMap/markers/DeviceMarker'
import DevicesClusterMarker from 'app/src/dashboard/components/DashboardMap/markers/DevicesClusterMarker'
import FocusedDeviceDetailsCard from 'app/src/devices/FocusedDeviceDetailsCard'
import PlotsMapCTAIllustration from 'app/src/discover-menu/svg/PlotsMapCTAIllustration'
import useDisclosure from 'app/src/hooks/useDisclosure'
import useGoBack from 'app/src/hooks/useGoBack'
import { useIsAppAccessRestricted } from 'app/src/hooks/useIsAppAccessRestricted'
import useLogAndNavToUpgradePlans from 'app/src/hooks/useLogAndNavToUpgradePlans'
import Button from 'app/src/kit/Button'
import CTAFullScreen from 'app/src/kit/CTAFullScreen'
import Card from 'app/src/kit/Card'
import CloseButton from 'app/src/kit/CloseButton'
import ConfirmationModal from 'app/src/kit/ConfirmationModal'
import DelimitedFlex from 'app/src/kit/DelimitedFlex'
import Icons from 'app/src/kit/Icons'
import LockedFeat from 'app/src/kit/LockedFeat'
import { ControlledModalOverlay } from 'app/src/kit/ModalOverlay'
import Text from 'app/src/kit/Text'
import { TextFieldPrimitive } from 'app/src/kit/fields/TextField'
import Box from 'app/src/kit/primitives/Box'
import Flex from 'app/src/kit/primitives/Flex'
import CustomMarker from 'app/src/map/CustomMarker'
import Geolocation from 'app/src/map/Geolocation'
import {
  GoogleMapApi,
  LatLng,
  drawShape,
  fromLngLatArrayToObject,
  getShapeOptionsFromTheme
} from 'app/src/map/utils'
import { ChooseLocationStep } from 'app/src/plots/creation/ChooseLocationStep'
import ChooseSourceStep from 'app/src/plots/creation/ChooseSourceStep'
import SelectDevicesStep from 'app/src/plots/creation/SelectDevicesStep'
import { SubmitStep } from 'app/src/plots/creation/SubmitStep'
import { SuccessStep } from 'app/src/plots/creation/SuccessStep'
import {
  FOCUSED_MARKER_WIDTH,
  MARKER_WIDTH,
  plotCreationSteps,
  plot_creation_href
} from 'app/src/plots/creation/constants'
import {
  PlotCreationSearchParams,
  PlotCreationStepProps,
  plotCreationSearchParamsSchema
} from 'app/src/plots/creation/types'
import { getLatLngFromShape, isDeviceFocused, isDeviceSelected } from 'app/src/plots/creation/utils'
import isNil from 'lodash-es/isNil'
import noop from 'lodash-es/noop'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDeepCompareEffect } from 'react-use'
import { FixedSizeList } from 'react-window'
import { keyframes, styled, useTheme } from 'styled-components'
import Supercluster from 'supercluster'
import useSupercluster from 'use-supercluster'

const CONTROLS_ICONS_SIZE = 'lg'

const EMPTY_DEVICES: Device[] = []
const GOOGLE_PLACES_ID = 'googlePlacesAutocompleteInput'

export interface DeviceAdditionalProps {
  onPress: (device: Device) => void
  idx: number
  isSelected: boolean
  isFocused: boolean
}

const blink = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const StepperPosition: FC<{ searchParams: PlotCreationSearchParams }> = ({ searchParams }) => {
  const indexPosition = plotCreationSteps.indexOf(searchParams.step ?? 'location')
  const nav = useNavigate()

  return (
    <Flex $mb='lg' $gap='md' $minHeight='auto'>
      {plotCreationSteps.map((step, idx) => (
        <Box
          key={step}
          $width={12}
          $height={12}
          $borderRadius={'xxl'}
          $backgroundColor={idx <= indexPosition ? 'primary.500' : 'grayscale.300'}
          onClick={
            idx < indexPosition
              ? () => nav(plot_creation_href, { search: { ...searchParams, step } })
              : undefined
          }
        />
      ))}
    </Flex>
  )
}

const renderCluster =
  (
    map: GoogleMapApi['map'],
    supercluster: Supercluster<Device & DeviceAdditionalProps, Supercluster.AnyProps> | undefined,
    step: PlotCreationSearchParams['step']
  ) =>
  (
    cluster:
      | Supercluster.PointFeature<Device & DeviceAdditionalProps>
      | Supercluster.PointFeature<Supercluster.ClusterProperties & Supercluster.AnyProps>
  ) => {
    const { properties } = cluster
    if ('cluster' in properties) {
      return (
        <CustomMarker
          key={properties.cluster_id}
          lat={cluster.geometry.coordinates[1]}
          lng={cluster.geometry.coordinates[0]}
          size={40}
          height={22}
          zIndex={1}
          onClick={() => {
            map?.panTo(fromLngLatArrayToObject(cluster.geometry.coordinates as [number, number]))
            if (!isNil(supercluster)) {
              map?.setZoom(supercluster.getClusterExpansionZoom(properties.cluster_id))
            }
          }}
        >
          <DevicesClusterMarker count={properties.point_count} />
        </CustomMarker>
      )
    } else {
      const device = properties
      const { location } = device
      return location != null ? (
        <CustomMarker
          key={device.id}
          lat={location.coordinates[1]}
          lng={location.coordinates[0]}
          onClick={() => device.onPress(device)}
          size={device.isFocused ? FOCUSED_MARKER_WIDTH : MARKER_WIDTH}
          height={device.isFocused ? 48 : undefined}
          zIndex={device.isFocused ? 999999 : device.isSelected ? 9999 : 0}
        >
          <DeviceMarker
            key={`${device.id}_${device.isSelected}`}
            isSelected={device.isSelected}
            isFocused={device.isFocused}
            step={step}
            device={device}
          />
        </CustomMarker>
      ) : null
    }
  }

const ComponentByStep: FC<PlotCreationStepProps> = ({
  add,
  autoCompleteRef,
  closestDeviceId,
  devices,
  devicesLoading,
  drawComponent,
  focus,
  grabComponent,
  handleDeleteShape,
  horizons,
  isRestricted,
  listRef,
  org,
  plot,
  plotName,
  reason,
  remove,
  search,
  searchParams,
  setFilter,
  setOrder,
  setPlotName,
  shape,
  trashComponent
}) => {
  switch (searchParams.step ?? 'location') {
    case 'location':
      return (
        <ChooseLocationStep
          autoCompleteRef={autoCompleteRef}
          searchParams={searchParams}
          drawComponent={drawComponent}
          grabComponent={grabComponent}
          shape={shape}
          trashComponent={trashComponent}
        />
      )
    case 'dataSource':
      return (
        <ChooseSourceStep
          isRestricted={isRestricted}
          org={org}
          reason={reason}
          searchParams={searchParams}
        />
      )
    case 'devicesSelection':
      return (
        <SelectDevicesStep
          add={add}
          devices={devices}
          devicesLoading={devicesLoading}
          focus={focus}
          horizons={horizons}
          listRef={listRef}
          org={org}
          remove={remove}
          search={search}
          searchParams={searchParams}
          setFilter={setFilter}
          setOrder={setOrder}
          closestDeviceId={closestDeviceId}
          plot={plot}
        />
      )
    case 'submit':
      return (
        <SubmitStep
          devices={devices}
          org={org}
          searchParams={searchParams}
          setPlotName={setPlotName}
          plotName={plotName}
          shape={shape}
        />
      )
    case 'success':
      return <SuccessStep handleDeleteShape={handleDeleteShape} searchParams={searchParams} />
  }
}

const PlacesSearch = styled(TextFieldPrimitive)`
  margin: 0;
  max-width: 300px;
  pointer-events: auto;
  height: fit-content;
  &:has(input:focus) {
    div {
      animation: ${blink} 0.4s ease-in-out 2;
    }
  }
`

const RightToolsBar = styled(Flex)`
  position: absolute;
  right: 16px;
  top: 0px;
  height: 100%;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  pointer-events: auto;
`

const EMPTY_HORIZONS: Horizon[] = []

const ZOOM_ON_FOCUS = 18

const Component = () => {
  const theme = useTheme()
  const { t } = useIntl()
  const nav = useNavigate()
  const navToUpgrade = useLogAndNavToUpgradePlans()
  const client = useClient()
  const { currentOrgId, org } = useOrgContext()

  const { goBack } = useGoBack()
  const [searchParams, setSearchParams] = useSearchParams(plotCreationSearchParamsSchema)

  const discoverModal = useDisclosure()

  const [plotName, setPlotName] = useState<string>()
  const [currentTool, setCurrentTool] = useState<'GRAB' | 'DRAW'>('GRAB')
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [shape, setShape] = useState<google.maps.Polygon | google.maps.Circle | undefined>()
  const [isPlotInsideBoundaries, setIsPlotInsideBoundaries] = useState(true)

  // we use a ref to keep track of searchParams without rerender
  // and without modying callback this way callback could be the same across rerender
  // which allow us to avoid rerender in list items
  const lastVersionSearchParams = useRef<typeof plotCreationSearchParamsSchema._type | null>(null)
  lastVersionSearchParams.current = searchParams

  const autocompleteInputRef = useRef<HTMLInputElement>(null)
  const drawingManager = useRef<google.maps.drawing.DrawingManager | null>(null)
  const listRef = useRef<FixedSizeList<Device>>(null)

  const { api, mapDetails } = useBackgroundMapContext<
    | Supercluster.PointFeature<Device & DeviceAdditionalProps>
    | Supercluster.PointFeature<Supercluster.ClusterProperties & Supercluster.AnyProps>
  >()

  const dispatch = useBackgroundMapDispatcher()

  const canCreateMorePlots = useCanCreateMorePlots(org)

  const plotsRequest = useQuery(client.plots.getMany({ offset: 0, limit: 1, isArchived: false }))
  const plotsCount = plotsRequest.data?.count

  const add = useCallback(
    (deviceId: number, depthValue?: number, horizonId?: number) => {
      setSearchParams({
        ...(lastVersionSearchParams.current ?? {}),
        selectedDeviceIds: [
          ...(lastVersionSearchParams.current?.selectedDeviceIds ?? []),
          deviceId
        ],
        depths: isNil(depthValue)
          ? lastVersionSearchParams.current?.depths
          : [
              ...(lastVersionSearchParams.current?.depths ?? []),
              `${deviceId}_${depthValue}_${horizonId}`
            ]
      })
    },
    [setSearchParams]
  )

  const remove = useCallback(
    (deviceId: number) => {
      setSearchParams({
        ...(lastVersionSearchParams.current ?? {}),
        selectedDeviceIds:
          lastVersionSearchParams.current?.selectedDeviceIds?.filter((id) => deviceId !== id) ??
          undefined,
        depths:
          lastVersionSearchParams.current?.depths?.filter(
            (depth) => !depth.includes(`${deviceId}`)
          ) ?? undefined
      })
    },
    [setSearchParams]
  )

  const search = useCallback(
    (newVal: string) => {
      setSearchParams({
        ...(lastVersionSearchParams.current ?? {}),
        q: newVal
      })
    },
    [setSearchParams]
  )

  const setOrder: PlotCreationStepProps['setOrder'] = useCallback(
    (sortBy, sortOrder) => {
      setSearchParams({
        ...(lastVersionSearchParams.current ?? {}),
        sortBy,
        sortOrder
      })
      listRef.current?.scrollToItem(0)
    },
    [setSearchParams]
  )

  const setFilter: PlotCreationStepProps['setFilter'] = useCallback(
    (newVals) => {
      setSearchParams({
        ...(lastVersionSearchParams.current ?? {}),
        ...newVals
      })
      listRef.current?.scrollToItem(0)
    },
    [setSearchParams]
  )

  const location: LatLng | undefined = useMemo(() => {
    if (!isNil(shape)) {
      const shapeData = getLatLngFromShape(shape)
      return { lat: shapeData.latitude, lng: shapeData.longitude }
    } else {
      return undefined
    }
  }, [shape])

  const { data, isLoading } = useQuery(
    client.devices.getAll({
      viewableByOrganizationId: currentOrgId as number,
      distanceFrom:
        !isNil(location) && searchParams.step === 'devicesSelection' ? location : undefined,
      q: searchParams.q,
      sortBy: searchParams.sortBy as 'distance_from',
      sortOrder: searchParams.sortOrder as 'asc',
      brand: searchParams.brand,
      metrics: searchParams.metrics as ['T'],
      ownedByOrganizationId: searchParams.ownedByOrganizationId
    }),
    {
      enabled: !isNil(currentOrgId)
    }
  )

  const devices: Device[] = data ?? EMPTY_DEVICES

  const focusedDevice = devices.find(
    (d) => d.id === parseInt(searchParams.focusedDevice as unknown as string, 10)
  )

  const focus = useCallback(
    (deviceId: number) => {
      setSearchParams({
        ...(lastVersionSearchParams.current ?? {}),
        focusedDevice: deviceId
      })
    },
    [setSearchParams]
  )

  const { id: closestDeviceId } = devices.reduce(
    (acc, device) =>
      (device.distanceFrom ?? Number.MAX_SAFE_INTEGER) < acc.distance
        ? { distance: device.distanceFrom as number, id: device.id }
        : acc,
    { distance: Number.MAX_SAFE_INTEGER, id: 0 }
  )

  const { isRestricted, reason } = useIsAppAccessRestricted({ enabled: true, orgId: org?.id })

  const horizonsRequest = useQuery(client.horizons.getAll())
  const horizons = horizonsRequest.data?.results || EMPTY_HORIZONS

  const points: Supercluster.PointFeature<Device & DeviceAdditionalProps>[] = useMemo(() => {
    return devices.reduce(
      (acc, device, idx) => {
        if (device.location) {
          acc.push({
            geometry: { type: 'Point', coordinates: device.location?.coordinates },
            type: 'Feature',
            properties: {
              ...device,
              isSelected: isDeviceSelected(searchParams, device),
              isFocused: isDeviceFocused(searchParams, device) ?? false,
              onPress: () => {
                if (searchParams.step === 'devicesSelection') {
                  listRef.current?.scrollToItem(idx, 'start')
                  setSearchParams({ ...searchParams, focusedDevice: device.id })
                } else if (device.location) {
                  api?.map?.panTo(
                    fromLngLatArrayToObject(device.location.coordinates as [number, number])
                  )
                  api?.map?.setZoom(16)
                }
              },
              idx
            }
          })
        }
        return acc
      },
      [] as Supercluster.PointFeature<Device & DeviceAdditionalProps>[]
    )
  }, [devices, searchParams, api?.map, setSearchParams])

  const { clusters, supercluster } = useSupercluster<Device & DeviceAdditionalProps>({
    points: points,
    zoom: !isNil(mapDetails.zoom) ? mapDetails.zoom : api.map?.getZoom() ?? 8,
    bounds: !isNil(mapDetails.bbox) ? mapDetails.bbox : undefined
  })

  const onGeolocationSuccess: PositionCallback = ({ coords }) => {
    if (!isNil(coords)) {
      const { latitude, longitude } = coords
      nav(plot_creation_href, { search: { ...searchParams } })
      dispatch({ type: 'setGeolocation', newGeolocation: { lat: latitude, lng: longitude } })
    }
  }

  function handleDeleteShape() {
    if (drawingManager.current) {
      drawingManager.current.setMap(null)
    }
    if (!isNil(shape)) {
      shape.setMap(null)
      setShape(undefined)
    }
  }

  const drawComponent = (
    <Icons.DrawPolygon
      $isDisabled={!isNil(shape)}
      $borderColor={currentTool === 'DRAW' ? 'grayscale.black' : undefined}
      $size={CONTROLS_ICONS_SIZE}
      $p='md'
      onPress={() => {
        if (!isNil(drawingManager.current) && !isNil(api.maps) && !isNil(api.map)) {
          setCurrentTool('DRAW')
          drawingManager.current.setMap(api.map)
        }
      }}
    />
  )

  const trashComponent = (
    <Icons.Bin
      $backgroundColor={'feedback.error.500'}
      $color={'grayscale.white'}
      $size={CONTROLS_ICONS_SIZE}
      $isDisabled={isNil(shape)}
      onPress={handleDeleteShape}
      $p='md'
    />
  )

  const grabComponent = (
    <Icons.PanTool
      $size={CONTROLS_ICONS_SIZE}
      $p='md'
      $borderColor={currentTool === 'GRAB' ? 'grayscale.black' : undefined}
      onPress={() => {
        drawingManager.current?.setMap(null)
        setCurrentTool('GRAB')
      }}
    />
  )

  const isOnSuccessStep = searchParams.step === 'success'

  const currentBounds = !isNil(api.map) ? api.map.getBounds() : undefined

  // Setting up onChange callback in context when location changes
  useEffect(() => {
    if (!isNil(location)) {
      const isInsideBoundaries = !isNil(currentBounds) ? currentBounds.contains(location) : false

      // The point is in the box
      setIsPlotInsideBoundaries(isInsideBoundaries)
    }
  }, [location, currentBounds])

  // Initializing drawingManager when api is initialized
  useEffect(() => {
    if (!isNil(api.map) && !isNil(api.maps)) {
      drawingManager.current = new api.maps.drawing.DrawingManager({
        drawingMode: api.maps.drawing.OverlayType.POLYGON,
        drawingControl: false,
        polygonOptions: getShapeOptionsFromTheme(theme)
      })
    }

    let listener: google.maps.MapsEventListener | undefined

    if (!isNil(api.map)) {
      listener = api.map.addListener('click', () => {
        setSearchParams({ ...lastVersionSearchParams.current, focusedDevice: undefined })
      })
    }

    return () => {
      if (!isNil(listener)) {
        listener.remove()
      }
    }
  }, [api.map, api.maps])

  // Initializing drawing and places listeners when api is initialized
  useEffect(() => {
    let polygonDrawingListener: google.maps.MapsEventListener | undefined
    let polygonDrawingTimeout: string | number | undefined

    if (!isNil(api.map) && !isNil(api.maps) && !isNil(drawingManager.current)) {
      polygonDrawingListener = api.maps.event.addListener(
        drawingManager.current,
        'polygoncomplete',
        (poly: google.maps.Polygon) => {
          setShape(poly)
          setCurrentTool('GRAB')

          //there are exceptions thrown by google map library if we dereference map too early
          //@ts-expect-error no ref to nodetimeout
          polygonDrawingTimeout = setTimeout(() => drawingManager.current?.setMap(null), 100)
        }
      )
    }

    let placesAutocompleteListener: google.maps.MapsEventListener | undefined
    let placesAutocompleteTimeout: string | number | undefined

    if (!isNil(autocompleteInputRef.current) && !isNil(api.maps) && !isNil(api.map)) {
      const googlePlacesAutocompleteInput = new api.maps.places.Autocomplete(
        autocompleteInputRef.current
      )

      placesAutocompleteListener = api.maps.event.addListener(
        googlePlacesAutocompleteInput,
        'place_changed',
        () => {
          const { geometry: { location } = {} } = googlePlacesAutocompleteInput.getPlace()
          if (!isNil(location)) {
            api.map?.panTo(location)
            api.map?.setZoom(17)
          }
        }
      )
    }

    return () => {
      if (!isNil(polygonDrawingListener)) {
        api.maps?.event.removeListener(polygonDrawingListener)
      }
      if (!isNil(placesAutocompleteListener)) {
        api.maps?.event.removeListener(placesAutocompleteListener)
      }
      if (!isNil(polygonDrawingTimeout)) {
        clearTimeout(polygonDrawingTimeout)
      }
      if (!isNil(placesAutocompleteTimeout)) {
        clearTimeout(placesAutocompleteTimeout)
      }
    }
  }, [api.map, api.maps])

  // Updating context when cluster are changed
  useDeepCompareEffect(() => {
    dispatch({
      type: 'setMarkers',
      newMarkers: clusters
    })
  }, [clusters])

  // Updating context renderCluster arguments are changed
  useEffect(() => {
    dispatch({
      type: 'setRenderMarkers',
      newRenderMarkers: renderCluster(api.map, supercluster, searchParams.step)
    })
  }, [api.map, supercluster])

  // Listening for change in plots.data to show the discoverModal
  useEffect(() => {
    if (!isNil(plotsCount) && plotsCount < 2 && searchParams.step === 'location') {
      discoverModal.open()
    }
  }, [plotsCount])

  // Listening for change on searchParams.step
  useEffect(() => {
    const defaultedSearchParams = { ...searchParams }
    let shouldChange = false
    if (isNil(searchParams.step)) {
      defaultedSearchParams.step = 'location'
      shouldChange = true
    }
    if (searchParams.step === 'devicesSelection' && isNil(searchParams.sortBy)) {
      defaultedSearchParams.sortBy = 'distance_from'
      defaultedSearchParams.sortOrder = 'asc'
      shouldChange = true
    }
    if (shouldChange) {
      nav(plot_creation_href, { search: defaultedSearchParams, replace: true })
    }
  }, [searchParams.step])

  // If the focusedDevice changes we pan the map to the focused device location
  useEffect(() => {
    if (
      !isNil(api.map) &&
      !isNil(api.maps) &&
      !isNil(focusedDevice) &&
      !isNil(focusedDevice.location)
    ) {
      const newPosition = fromLngLatArrayToObject(
        focusedDevice.location?.coordinates as [number, number]
      )
      api.map.panTo(newPosition)
      api.map.setZoom(ZOOM_ON_FOCUS)
    }
  }, [focusedDevice])

  const [alreadyDraw, setAlreadyDraw] = useState(false)

  // Drawing shape from sensor location
  useEffect(() => {
    if (isNil(api.map) || isNil(api.maps)) return

    if (!isNil(searchParams.longitude) && !isNil(searchParams.latitude)) {
      const lng = searchParams.longitude
      const lat = searchParams.latitude

      const positions = [lng, lat] as [number, number]

      if ((isNil(shape) && !alreadyDraw) || (!isNil(shape) && shape.getMap() !== api.map)) {
        const { shape: drawnShape } = drawShape({
          map: api.map,
          maps: api.maps,
          theme,
          shape: {
            type: 'Point',
            coordinates: positions
          },
          options: {
            recenterOnShape: true,
            radius: 100,
            offset: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            }
          }
        })

        const newShape = drawnShape as google.maps.Circle

        shape?.setMap(null)
        setShape(newShape)
        setAlreadyDraw(true)
      }
    }
  }, [api.map, api.maps])

  return !canCreateMorePlots && !isOnSuccessStep ? (
    <>
      {isRestricted && reason === 'freemium' ? (
        <CTAFullScreen
          isOpen
          onClose={() => {
            goBack()
          }}
          title={t('models.plot.creation.cta_freemium_title')}
          description={t('models.plot.creation.cta_freemium_body')}
          mainCTA={{
            label: t('discover_menu.calls_to_action.discover_subscriptions'),
            onPress: navToUpgrade
          }}
          Illustration={<PlotsMapCTAIllustration />}
        />
      ) : isRestricted && reason === 'downgrade' ? (
        <ControlledModalOverlay
          isOpen
          close={() => {
            goBack()
          }}
        >
          <LockedFeat
            reason={reason}
            showCTA
            title='ecommerce.plans.restrictions.plot_title'
            body='ecommerce.plans.restrictions.carousel_value_downgrade_message'
          />
        </ControlledModalOverlay>
      ) : null}
    </>
  ) : (
    <>
      {searchParams.step === 'location' && discoverModal.isOpen ? (
        <CTAFullScreen
          isOpen={discoverModal.isOpen}
          onClose={() => {
            discoverModal.close()
            goBack()
          }}
          title={t('models.plot.actions.create', { capitalize: true })}
          description={t('discover_menu.calls_to_action.create_plot_description', {
            capitalize: true
          })}
          Illustration={<PlotsMapCTAIllustration />}
          mainCTA={{
            label: t('models.plot.actions.create', { capitalize: true }),
            onPress: () => {
              discoverModal.close()
            }
          }}
          secondaryCTA={{
            label: t('discover_menu.calls_to_action.discover_more_features'),
            to: '/discover'
          }}
        />
      ) : null}
      <Flex $flexDirection='column' $width='100%' $height='100%' $p='lg'>
        <Box
          $pb='lg'
          $height='fit-content'
          $width='100%'
          $zIndex={1}
          $position='relative'
          $pointerEvents='none'
        >
          <Button>{org?.name}</Button>
        </Box>
        <Flex
          $flex={1}
          $zIndex={1}
          $position='relative'
          $pointerEvents='none'
          $gap={12}
          $width='100%'
        >
          <Flex $flexDirection='column' $width={420}>
            <Card
              $boxShadow='md'
              $p={0}
              $backgroundColor='grayscale.50'
              $pointerEvents='auto'
              $flex={searchParams.step === 'devicesSelection' ? 1 : undefined}
            >
              <Flex $alignItems='center' $p='lg' $gap='md'>
                <Box $flex={1}>
                  <Text $fontSize='md' $fontWeight='bold'>
                    {t('models.plot.actions.create', { capitalize: true })}
                  </Text>
                </Box>
                <CloseButton
                  $size='lg'
                  $p='md'
                  onPress={() => {
                    setIsModalOpen(true)
                  }}
                />
                <ConfirmationModal
                  title={t('models.plot.creation.confirmationModal.title')}
                  confirmationMessage={t('models.plot.creation.confirmationModal.description')}
                  isOpen={isModalOpen}
                  onCancel={() => setIsModalOpen(false)}
                  onConfirm={() => {
                    handleDeleteShape()
                    nav('/plots')
                  }}
                />
              </Flex>
              <DelimitedFlex />
              <Flex $flexDirection='column' $flex={1} $p='lg' style={{ overflowY: 'auto' }}>
                <StepperPosition searchParams={searchParams} />
                {!isNil(org) ? (
                  <ComponentByStep
                    searchParams={searchParams}
                    remove={remove}
                    add={add}
                    focus={focus}
                    search={search}
                    setOrder={setOrder}
                    setFilter={setFilter}
                    org={org}
                    devices={devices}
                    devicesLoading={isLoading}
                    listRef={listRef}
                    closestDeviceId={closestDeviceId}
                    isRestricted={isRestricted}
                    reason={reason}
                    shape={shape}
                    autoCompleteRef={autocompleteInputRef}
                    drawComponent={searchParams.step === 'location' ? drawComponent : undefined}
                    grabComponent={searchParams.step === 'location' ? grabComponent : undefined}
                    trashComponent={searchParams.step === 'location' ? trashComponent : undefined}
                    plotName={plotName}
                    setPlotName={setPlotName}
                    handleDeleteShape={handleDeleteShape}
                    horizons={horizons}
                  />
                ) : null}
              </Flex>
            </Card>
          </Flex>
          <Flex $flexDirection='column' $gap={12} $width={300}>
            <Box $minHeight='auto'>
              <PlacesSearch
                id={GOOGLE_PLACES_ID}
                type='text'
                ref={autocompleteInputRef}
                placeholder={t('actions.search_city')}
                // The input is "controlled" by google maps, no need for an onChange handler
                onChange={noop}
                marginHeight={0}
              />
            </Box>
            {!isNil(focusedDevice) && (
              <FocusedDeviceDetailsCard
                device={focusedDevice}
                add={add}
                remove={remove}
                searchParams={searchParams}
                billingStatus={org?.billingStatus}
                horizons={horizons}
              />
            )}
          </Flex>
          <RightToolsBar>
            <ControlsContainer $flexDirection='column' $p={'sm'} $borderRadius={'md'}>
              <Icons.PlusSign
                onPress={() => api.map?.setZoom((api.map.getZoom() ?? 0) + 1)}
                $p={'md'}
                $size={CONTROLS_ICONS_SIZE}
                $m={0}
              />
              <Icons.MinusSign
                $p={'md'}
                $size={CONTROLS_ICONS_SIZE}
                $m={0}
                onPress={() => api.map?.setZoom((api.map.getZoom() ?? 0) - 1)}
              />
            </ControlsContainer>
            <ControlsContainer $flexDirection='column' $p={'sm'} $borderRadius={'md'}>
              <Geolocation
                onSuccess={onGeolocationSuccess}
                iconProps={{ $size: CONTROLS_ICONS_SIZE, $p: 'md', $m: 0, $rounded: false }}
              />
            </ControlsContainer>
            {searchParams.step === 'location' ? (
              <ControlsContainer $flexDirection='column' $gap={'md'} $p={'sm'} $borderRadius={'md'}>
                {drawComponent}
                {grabComponent}
                {trashComponent}
              </ControlsContainer>
            ) : null}
            {!isPlotInsideBoundaries &&
            (searchParams.step === 'location' || searchParams.step === 'devicesSelection') ? (
              <ControlsContainer $flexDirection='column' $p={'sm'} $borderRadius={'md'}>
                <Icons.FocusPin
                  $size={CONTROLS_ICONS_SIZE}
                  onPress={() => {
                    if (!isNil(api.map) && !isNil(api.maps) && !isNil(location)) {
                      const latLngBounds = new api.maps.LatLngBounds(location)
                      api.map.fitBounds(latLngBounds)
                      api.map.setZoom(ZOOM_ON_FOCUS - 1)
                    }
                  }}
                />
              </ControlsContainer>
            ) : null}
          </RightToolsBar>
        </Flex>
      </Flex>
    </>
  )
}

export default Component
