import { SVGProps } from 'react'

const FakeAlertScreenShot = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox='0 0 604 392' fill='none' {...props}>
    <rect width={603.568} height={392} rx={16} fill='#FAFBFC' />
    <rect x={16} y={16} width={571.57} height={24} rx={12} fill='#E6EAEB' />
    <rect x={48} y={22} width={64} height={12} rx={6} fill='#FFF' />
    <rect x={120} y={22} width={64} height={12} rx={6} fill='#FFF' />
    <rect x={192} y={22} width={64} height={12} rx={6} fill='#018FA4' />
    <rect x={264} y={22} width={64} height={12} rx={6} fill='#FAFBFC' />
    <rect x={24} y={22} width={12} height={12} rx={6} fill='#018FA4' />
    <rect x={563} y={22} width={12} height={12} rx={6} fill='#FFF' />
    <rect x={539} y={22} width={12} height={12} rx={6} fill='#FFF' />
    <g clipPath='url(#fi-alerts-web_svg__a)'>
      <path d='M0 57h572c8.837 0 16 7.163 16 16v319H0V57Z' fill='#E6EAEB' />
      <g clipPath='url(#fi-alerts-web_svg__b)'>
        <rect x={166} y={97} width={256} height={121} rx={8} fill='#FFF' />
        <path fill='#E05252' d='M166 97h256v24H166z' />
        <circle cx={186} cy={109} r={4} fill='#FFF' />
        <rect x={198} y={105} width={128} height={8} rx={4} fill='#FAFBFC' />
        <rect x={182} y={137} width={122} height={8} rx={4} fill='#B3BEC3' />
        <rect x={182} y={153} width={8} height={8} rx={4} fill='#E6EAEB' />
        <rect x={198} y={153} width={122} height={8} rx={4} fill='#E6EAEB' />
        <rect x={182} y={169} width={8} height={8} rx={4} fill='#E6EAEB' />
        <rect x={198} y={169} width={122} height={8} rx={4} fill='#E6EAEB' />
        <path fill='#E6EAEB' d='M182 185h224v1H182z' />
        <rect x={182} y={194} width={48} height={8} rx={4} fill='#018FA4' />
        <rect x={238} y={194} width={122} height={8} rx={4} fill='#B3BEC3' />
      </g>
      <rect x={166.5} y={97.5} width={255} height={120} rx={7.5} stroke='#E05252' />
      <g clipPath='url(#fi-alerts-web_svg__c)'>
        <rect x={166} y={226} width={256} height={97} rx={8} fill='#FFF' />
        <rect x={182} y={242} width={122} height={8} rx={4} fill='#B3BEC3' />
        <rect x={182} y={258} width={8} height={8} rx={4} fill='#E6EAEB' />
        <rect x={198} y={258} width={122} height={8} rx={4} fill='#E6EAEB' />
        <rect x={182} y={274} width={8} height={8} rx={4} fill='#E6EAEB' />
        <rect x={198} y={274} width={122} height={8} rx={4} fill='#E6EAEB' />
        <path fill='#E6EAEB' d='M182 290h224v1H182z' />
        <rect x={182} y={299} width={48} height={8} rx={4} fill='#018FA4' />
        <rect x={238} y={299} width={122} height={8} rx={4} fill='#B3BEC3' />
      </g>
      <rect x={166.5} y={226.5} width={255} height={96} rx={7.5} stroke='#B3BEC3' />
      <g clipPath='url(#fi-alerts-web_svg__d)'>
        <rect x={166} y={331} width={256} height={97} rx={8} fill='#FFF' />
        <rect x={182} y={347} width={122} height={8} rx={4} fill='#B3BEC3' />
        <rect x={182} y={363} width={8} height={8} rx={4} fill='#E6EAEB' />
        <rect x={198} y={363} width={122} height={8} rx={4} fill='#E6EAEB' />
        <rect x={182} y={379} width={8} height={8} rx={4} fill='#E6EAEB' />
        <rect x={198} y={379} width={122} height={8} rx={4} fill='#E6EAEB' />
      </g>
      <rect x={166.5} y={331.5} width={255} height={96} rx={7.5} stroke='#B3BEC3' />
      <rect x={166} y={73} width={122} height={12} rx={6} fill='#4D6872' />
    </g>
    <defs>
      <clipPath id='fi-alerts-web_svg__a'>
        <path d='M0 57h572c8.837 0 16 7.163 16 16v319H0V57Z' fill='#FFF' />
      </clipPath>
      <clipPath id='fi-alerts-web_svg__b'>
        <rect x={166} y={97} width={256} height={121} rx={8} fill='#FFF' />
      </clipPath>
      <clipPath id='fi-alerts-web_svg__c'>
        <rect x={166} y={226} width={256} height={97} rx={8} fill='#FFF' />
      </clipPath>
      <clipPath id='fi-alerts-web_svg__d'>
        <rect x={166} y={331} width={256} height={97} rx={8} fill='#FFF' />
      </clipPath>
    </defs>
  </svg>
)

export default FakeAlertScreenShot
