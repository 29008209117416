export const plotCreationSteps = [
  'location',
  'dataSource',
  'devicesSelection',
  'submit',
  'success'
] as const

export const plotCreationDataSources = ['vision', 'devices'] as const

export const plot_creation_href = '/plots/create'
export const plot_modification_href = '/plots/edit'

export const MARKER_WIDTH = 48
export const FOCUSED_MARKER_WIDTH = 160

/** The number of path a polygon must have to be considered valid */
export const MINIMUM_NUMBER_OF_POLYGON_PATHS = 3
