import { HorizonOpt } from '@weenat/client/dist/core/horizons'
import { useIntl } from '@weenat/wintl'
import Text from 'app/src/kit/Text'
import WeenatPressable from 'app/src/kit/WeenatPressable'
import { styled, useTheme } from 'styled-components'

interface HorizonOptionProps {
  $isLast: boolean
  $isActive: boolean
  $isHorizontal?: boolean
}

const HorizonOption = styled(WeenatPressable)<HorizonOptionProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${(p) => p.theme.spacings.md}px;
  width: 100%;
  border-bottom: ${(p) =>
    ` ${p.$isLast || p.$isHorizontal ? '0px' : '1px'} solid ${p.theme.colors.grayscale[700]}`};
  border-right: ${(p) =>
    `${p.$isLast || !p.$isHorizontal ? '0px' : '1px'} solid ${p.theme.colors.grayscale[700]}`};
  ${(p) =>
    p.$isHorizontal &&
    `
      flex: 1;
    `};
  background-color: ${(p) =>
    p.$isActive ? p.theme.colors.primary[500] : p.theme.colors.grayscale.white};
`

export interface UniverseHorizonOptionsProps {
  currentHorizon: number | null
  availableHorizonOptions: HorizonOpt[]
  onHorizonChange: (horizonId: number) => void
  isHorizontal?: boolean
  showUnit?: boolean
}

const UniverseHorizonOptions: FC<UniverseHorizonOptionsProps> = ({
  currentHorizon,
  onHorizonChange,
  availableHorizonOptions,
  isHorizontal = false,
  showUnit = false
}) => {
  const { colors } = useTheme()
  const { t } = useIntl()

  const depthUnit = t('units.centimeters_abbr')

  return (
    <Flex
      $flexDirection={isHorizontal ? 'row' : 'column'}
      $alignItems={isHorizontal ? 'stretch' : 'center'}
      $height={isHorizontal ? '100%' : undefined}
      $flex={isHorizontal ? 1 : undefined}
      $width={isHorizontal ? undefined : '100%'}
    >
      {availableHorizonOptions.map((hrz, i) => {
        const isActive = currentHorizon === hrz.value
        const color = isActive ? colors.grayscale.white : colors.grayscale.black

        const isLast = i === availableHorizonOptions.length - 1
        const label = `${hrz.label} ${showUnit ? depthUnit : ''}`.trim()

        return (
          <HorizonOption
            $isActive={isActive}
            key={hrz.value}
            onPress={(e) => {
              e.preventDefault()
              onHorizonChange(hrz.value)
            }}
            $isLast={isLast}
            $isHorizontal={isHorizontal}
          >
            <Text $fontSize='rg' $fontWeight='bold' $textAlign='center' $color={color}>
              {label}
            </Text>
          </HorizonOption>
        )
      })}
    </Flex>
  )
}

export default UniverseHorizonOptions
