import Card, { CardProps } from 'app/src/kit/Card'

interface AuthCardProps extends CardProps {}

const AuthCard: FC<AuthCardProps> = ({ children, ...props }) => {
  return (
    <Card
      $maxWidth={480}
      $minWidth={400}
      $width='100%'
      $alignSelf='center'
      $p={0}
      $maxHeight='100%'
      {...props}
    >
      <Box $overflowY='auto' $p='lg' $m='sm'>
        {children}
      </Box>
    </Card>
  )
}

export default AuthCard
