import { firstLettersOfWeekDays } from '@weenat/client/dist/core/history'
import Text from 'app/src/kit/Text'
import capitalize from 'lodash-es/capitalize'
import Grid from '../Grid'

const MAX_WEEK_PER_MONTH = 6

interface CalendarGridProps {
  dayPadding: number
  dayHeight: number
}

const CalendarGrid: FC<CalendarGridProps> = ({ children, dayHeight }) => {
  return (
    <Box>
      {/* Grid Header (days list) */}
      <Flex $py='sm' $mb='md' $alignItems='center'>
        {firstLettersOfWeekDays().map((letter, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={`${i}-${letter}`} $flex={1 / 7}>
            <Text $textAlign='center' $color={'grayscale.700'}>
              {capitalize(letter)}
            </Text>
          </Box>
        ))}
      </Flex>
      {/* Grid */}
      <Grid
        $templateRows={`repeat(${MAX_WEEK_PER_MONTH}, ${dayHeight}px)`}
        $templateColumns='repeat(7, 1fr)'
        $rowGap={'md'}
      >
        {children}
      </Grid>
    </Box>
  )
}

export default CalendarGrid
