import { useIntl } from '@weenat/wintl'
import { useNavigate } from 'app/routx-router'
import Button from 'app/src/kit/Button'
import DelimitedBox from 'app/src/kit/DelimitedBox'
import ErrorMessage from 'app/src/kit/ErrorMessage'
import Icons from 'app/src/kit/Icons'
import { FlexProps } from 'app/src/kit/primitives/themeMappings/props'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import { MINIMUM_NUMBER_OF_POLYGON_PATHS, plot_creation_href } from './constants'
import { PlotCreationStepProps } from './types'

const InstructionStepHolder: FC<{ $backgroundColor?: FlexProps['$backgroundColor'] }> = ({
  children,
  $backgroundColor
}) => {
  return (
    <Flex
      $borderRadius={'rounded'}
      $width={32}
      $height={32}
      $backgroundColor={$backgroundColor ?? 'grayscale.black'}
      $alignItems='center'
      $justifyContent='center'
      $p={'md'}
    >
      {children}
    </Flex>
  )
}

const InstructionStepNumber: FC = ({ children }) => {
  return (
    <Text
      $fontFamily='numbers'
      $fontWeight='bold'
      $textAlign='center'
      $color={'grayscale.white'}
      $lineHeight='none'
    >
      {children}
    </Text>
  )
}

interface ChooseLocationStepProps
  extends Pick<
    PlotCreationStepProps,
    | 'searchParams'
    | 'shape'
    | 'drawComponent'
    | 'trashComponent'
    | 'grabComponent'
    | 'autoCompleteRef'
  > {}

export const ChooseLocationStep = ({
  searchParams,
  shape,
  drawComponent,
  trashComponent,
  grabComponent,
  autoCompleteRef
}: ChooseLocationStepProps) => {
  const { t } = useIntl()
  const nav = useNavigate()

  const shapeIsNil = isNil(shape)

  // Haven't found a better way to test shapes (to discriminate between circles and polygon) than to test if the getPath method exist
  // see https://developers.google.com/maps/documentation/javascript/reference/polygon
  const isValid = !isNil(shape)
    ? !isNil(shape.getPath)
      ? shape.getPath().getArray().length >= MINIMUM_NUMBER_OF_POLYGON_PATHS
      : true
    : false

  return (
    <>
      {
        //minHeight is for small screen
      }
      <DelimitedBox $minHeight='auto' $pb={'md'}>
        <Text>{t('models.plot.creation.location_step_draw_title')}</Text>
      </DelimitedBox>
      <Flex
        $my={12}
        $flexDirection='column'
        $gap='lg'
        style={{ overflowY: 'auto', minHeight: '220px' }}
      >
        <Flex $justifyContent='space-between' $alignItems='center' $gap={'md'}>
          <InstructionStepHolder>
            <InstructionStepNumber>1</InstructionStepNumber>
          </InstructionStepHolder>
          <Text $flexed>{t('models.plot.creation.location_step_draw_search_input')}</Text>
          <Icons.TravelExplore
            $size='lg'
            $backgroundColor={'grayscale.100'}
            $p='md'
            onPress={() => autoCompleteRef.current?.focus()}
          />
        </Flex>
        <Flex $justifyContent='space-between' $alignItems='center' $gap={'md'}>
          <InstructionStepHolder>
            <InstructionStepNumber>2</InstructionStepNumber>
          </InstructionStepHolder>
          <Text $flexed>{t('models.plot.creation.location_draw_pen')}</Text>
          <Box $backgroundColor={'grayscale.100'} $borderRadius={8}>
            {drawComponent}
          </Box>
        </Flex>
        <Flex $justifyContent='space-between' $alignItems='center' $gap={'md'}>
          <InstructionStepHolder>
            <InstructionStepNumber>3</InstructionStepNumber>
          </InstructionStepHolder>
          <Text $flexed>{t('models.plot.creation.location_step_draw_hand')}</Text>
          <Box $backgroundColor={'grayscale.100'} $borderRadius={8}>
            {grabComponent}
          </Box>
        </Flex>
        <Flex $justifyContent='space-between' $alignItems='center' $gap={'md'}>
          <InstructionStepHolder $backgroundColor={'tertiary.500'}>
            <Icons.Lightbulb $size='rg' $color={'grayscale.white'} />
          </InstructionStepHolder>
          <Text $flexed>{t('models.plot.creation.location_step_draw_bin')}</Text>
          {trashComponent}
        </Flex>
      </Flex>
      <Button
        isDisabled={shapeIsNil || !isValid}
        isError={!shapeIsNil && !isValid}
        onPress={() => {
          if (!shapeIsNil && isValid) {
            nav(plot_creation_href, {
              search: {
                ...searchParams,
                step: 'dataSource'
              }
            })
          }
        }}
      >
        {t('actions.confirm')}
      </Button>
      {!shapeIsNil && !isValid ? (
        <Box $mt='md'>
          <ErrorMessage
            $textAlign='center'
            $fontSize='rg'
            $error={t(`models.plot.errors.shape_is_invalid`)}
          />
        </Box>
      ) : null}
    </>
  )
}
