import { useIntl } from '@weenat/wintl'
import CollapsibleCard from 'app/src/kit/CollapsibleCard'
import DelimitedFlex from 'app/src/kit/DelimitedFlex'
import Link from 'app/src/kit/LinkComponent'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import { memo } from 'react'

interface SectionProps {
  title: string
  description: string
  link?: {
    label: string
    href: string
  }
}

const Section: FC<SectionProps> = ({ title, description, link }) => {
  return (
    <DelimitedFlex $flexDirection='column' $py='lg' $width='100%'>
      <Text $lineHeight='md' $fontWeight='bold'>
        {title}
      </Text>
      <Box $width='100%' $mt='md'>
        <Text $lineHeight='md'>{description}</Text>
      </Box>
      {!isNil(link) ? (
        <Box $mt='md'>
          <Link $underlined $fontWeight='medium' href={link.href} $color='primary.500'>
            {link.label}
          </Link>
        </Box>
      ) : null}
    </DelimitedFlex>
  )
}

const CookiesPolicySection: FC = () => {
  const { t } = useIntl()

  const cookieContentDescription = [
    t('help.cookies_content_2'),
    t('help.cookies_content_2_1', t('help.cookies_content_2_2'), t('help.cookies_content_2_3'))
  ].join('\n - ')

  return (
    <CollapsibleCard title={t('help.cookies_main_title')} $mb='lg'>
      <Box $pt='lg'>
        <Section title={t('help.cookies_title_1')} description={t('help.cookies_content_1')} />

        <Section title={t('help.cookies_title_2')} description={cookieContentDescription} />

        <Section title={t('help.cookies_title_3')} description={t('help.cookies_content_3')} />
        <Section title={t('help.cookies_title_4')} description={t('help.cookies_content_4')} />
        <Section title={t('help.cookies_title_5')} description={t('help.cookies_content_5')} />
        <Section
          title={t('help.cookies_title_6')}
          description={t('help.cookies_content_6')}
          link={{ href: t('weenat.help.cnil.cookiesUrl'), label: t('weenat.help.cnil.cookiesUrl') }}
        />
      </Box>
    </CollapsibleCard>
  )
}

export default memo(CookiesPolicySection)
