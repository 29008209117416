import { css } from 'styled-components'
import createGlobalStyles from './createGlobalStyles'

const hackForFirefoxScrollable = css`
  /* See https://bugzilla.mozilla.org/show_bug.cgi?id=1135332 */
  * {
    min-height: 0;
  }
`

const hideScrollBarIE = css`
  html {
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
`
/** cf https://developers.google.com/maps/documentation/javascript/places-autocomplete#add-autocomplete */
const placesAutocompleteStylesHack = css`
  .pac-container {
    border-radius: 8px;
    font-family: 'Inter', sans-serif;
  }

  .pac-item-query {
    font-size: 1rem;
  }
`

const additionalStyles = css`
  #root {
    height: 100vh;
  }

  /* Prevent Pop fade in left layout shift */
  body {
    overflow: hidden;
  }

  ${hackForFirefoxScrollable}
  ${hideScrollBarIE}
  ${placesAutocompleteStylesHack}
`

const GlobalStyles = createGlobalStyles(additionalStyles)

export default GlobalStyles
