import { formatMailTo } from '../../utils'
import Link, { LinkProps } from './LinkComponent'

interface EmailLinkProps extends Omit<LinkProps, 'href' | 'target' | 'to'> {
  subject?: string
  body?: string
  email: string
}

const EmailLink: FC<EmailLinkProps> = ({ email, subject, body, ...props }) => (
  <Link
    href={formatMailTo({
      email,
      subject,
      body
    })}
    target='_blank'
    {...props}
  />
)

export default EmailLink
