import { useSlotProvider } from 'app/state'
import { Children, useEffect } from 'react'

export const MapTopControlsArea: FC = () => {
  const [currentSlotContent] = useSlotProvider()
  return <Flex $px='lg'>{currentSlotContent.mapTopControls}</Flex>
}
const MapTopControls: FC = ({ children }) => {
  const [, setSlotContent] = useSlotProvider()
  useEffect(() => {
    setSlotContent((currentSlotContent) => ({
      ...currentSlotContent,
      mapTopControls: Children.toArray(children)
    }))
  }, [])
  return null
}

export default MapTopControls
