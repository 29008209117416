import { useClient, useQuery } from '@weenat/client'
import { useIntl } from '@weenat/wintl'
import { Head, useParams } from 'app/routx-router'
import CreateNewAlert from 'app/src/alerts/CreateNewAlert'

const CreateAlertPage: FC = () => {
  const { t } = useIntl()
  const client = useClient()

  const { deviceId, plotId } = useParams()

  const hasParams = deviceId !== undefined || plotId !== undefined

  const plot = useQuery(client.plots.get(plotId), { enabled: plotId !== undefined }).data
  const device = useQuery(client.devices.get(deviceId), { enabled: deviceId !== undefined }).data

  const [serial] = device?.endUserName || []

  const target =
    plotId !== undefined && plot ? plot : deviceId !== undefined && device ? device : undefined

  return (
    <>
      {hasParams && (
        <Head title={plot ? plot.name : serial != null ? serial : t('status.loading')} />
      )}
      <CreateNewAlert initialTarget={target} />
    </>
  )
}

export const Component = CreateAlertPage
export default CreateAlertPage
