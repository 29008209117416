const FakeUniversesScreenShot = () => (
  <svg height='100%' viewBox='0 0 493 320' fill='none'>
    <rect width='493' height='320' rx='16' fill='#FAFBFC' />
    <rect x='13.0613' y='13.0612' width='466.588' height='19.5918' rx='9.79592' fill='#CCD4D7' />
    <rect x='39.1838' y='17.9592' width='52.2449' height='9.79592' rx='4.89796' fill='#018FA4' />
    <rect x='97.9592' y='17.9592' width='52.2449' height='9.79592' rx='4.89796' fill='white' />
    <rect x='156.735' y='17.9592' width='52.2449' height='9.79592' rx='4.89796' fill='white' />
    <rect x='215.51' y='17.9592' width='52.2449' height='9.79592' rx='4.89796' fill='white' />
    <rect x='19.5918' y='17.9592' width='9.79592' height='9.79592' rx='4.89796' fill='#018FA4' />
    <rect x='459.592' y='17.9592' width='9.79592' height='9.79592' rx='4.89796' fill='#CCD4D7' />
    <rect x='440' y='17.9592' width='9.79592' height='9.79592' rx='4.89796' fill='#CCD4D7' />
    <path
      d='M0 45.7143H111.347C120.183 45.7143 127.347 52.8777 127.347 61.7143V320H0V45.7143Z'
      fill='#CCD4D7'
    />
    <rect x='11.9287' y='73.9694' width='100.224' height='51.2449' rx='7.5' fill='white' />
    <rect x='24.49' y='86.5306' width='75.102' height='6.53061' rx='3.26531' fill='#B3BEC3' />
    <rect x='24.49' y='99.5918' width='20.6803' height='13.0612' rx='4' fill='#B3BEC3' />
    <rect x='51.7009' y='99.5918' width='20.6803' height='13.0612' rx='4' fill='#B3BEC3' />
    <rect x='78.9116' y='99.5918' width='20.6803' height='13.0612' rx='4' fill='#B3BEC3' />
    <rect x='11.9287' y='73.9694' width='100.224' height='51.2449' rx='7.5' stroke='#CCD4D7' />
    <rect x='11.9287' y='132.745' width='100.224' height='51.2449' rx='7.5' fill='white' />
    <rect x='24.49' y='145.306' width='75.102' height='6.53061' rx='3.26531' fill='#B3BEC3' />
    <rect x='24.49' y='158.367' width='20.6803' height='13.0612' rx='4' fill='#B3BEC3' />
    <rect x='51.7009' y='158.367' width='20.6803' height='13.0612' rx='4' fill='#B3BEC3' />
    <rect x='78.9116' y='158.367' width='20.6803' height='13.0612' rx='4' fill='#B3BEC3' />
    <rect x='11.9287' y='132.745' width='100.224' height='51.2449' rx='7.5' stroke='#CCD4D7' />
    <rect x='11.9287' y='191.52' width='100.224' height='51.2449' rx='7.5' fill='white' />
    <rect x='24.49' y='204.082' width='75.102' height='6.53061' rx='3.26531' fill='#B3BEC3' />
    <rect x='24.49' y='217.143' width='20.6803' height='13.0612' rx='4' fill='#B3BEC3' />
    <rect x='51.7009' y='217.143' width='20.6803' height='13.0612' rx='4' fill='#B3BEC3' />
    <rect x='78.9116' y='217.143' width='20.6803' height='13.0612' rx='4' fill='#B3BEC3' />
    <rect x='11.9287' y='191.52' width='100.224' height='51.2449' rx='7.5' stroke='#CCD4D7' />
    <rect x='11.4287' y='57.1429' width='101.224' height='9.79592' rx='2' fill='#4D6872' />
    <rect x='133.877' y='47.3469' width='52.2449' height='13.0612' rx='6.53061' fill='#018FA4' />
    <rect x='195.918' y='47.3469' width='52.2449' height='13.0612' rx='6.53061' fill='#CCD4D7' />
    <rect x='133.878' y='290.612' width='52.2449' height='19.5918' rx='9.79592' fill='#CCD4D7' />
    <rect x='283.265' y='290.612' width='19.5918' height='19.5918' rx='4' fill='#2E75B6' />
    <rect x='309.388' y='290.612' width='19.5918' height='19.5918' rx='4' fill='#CCD4D7' />
    <rect x='335.51' y='290.612' width='19.5918' height='19.5918' rx='4' fill='#CCD4D7' />
    <rect x='368.163' y='290.612' width='78.3673' height='19.5918' rx='4' fill='#018FA4' />
    <rect x='459.592' y='290.612' width='19.5918' height='19.5918' rx='9.79592' fill='#CCD4D7' />
    <rect x='208.979' y='117.98' width='48.9796' height='78.3673' rx='24.4898' fill='#CCD4D7' />
    <rect x='213.061' y='123.694' width='40.8163' height='40.8163' rx='20.4082' fill='#2E75B6' />
    <rect x='382.041' y='109' width='40.8163' height='40.8163' rx='20.4082' fill='#2E75B6' />
    <rect x='319.184' y='170.224' width='40.8163' height='40.8163' rx='20.4082' fill='#2E75B6' />
  </svg>
)

export default FakeUniversesScreenShot
