import * as Sentry from '@sentry/react'

export const initSentry = () => {
  if (import.meta.env.PROD) {
    Sentry.init({
      dsn: 'https://64b95f7e1b8247c7a45c074c15b34451@sentry.io/1322038',
      integrations: [new Sentry.BrowserTracing()],
      release: import.meta.env.PACKAGE_VERSION,
      tracesSampleRate: 0.5,
      ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications.',
        'Plot code unknown',
        'Failed to fetch'
      ],
      environment: import.meta.env.VITE_API_HOST?.includes('-preprod') ? 'preprod' : 'production'
    })
  }
}
