import { useClient, useMutation, useQuery } from '@weenat/client'
import { MobileDevice } from '@weenat/client/dist/resources/mobileDevices'
import { useIntl } from '@weenat/wintl'
import DelimitedBox from 'app/src/kit/DelimitedBox'
import Icons from 'app/src/kit/Icons'
import InfoCard from 'app/src/kit/InfoCard'
import ListEmpty from 'app/src/kit/ListEmpty'
import LoadingList from 'app/src/kit/LoadingList'
import SmartText from 'app/src/kit/SmartText'
import Text from 'app/src/kit/Text'
import TrashButtonWithConfirm from 'app/src/kit/TrashButtonWithConfirm'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import moment from 'moment-timezone'

const MobileDevicesExplanation = () => {
  const { t } = useIntl()

  return (
    <InfoCard title={t('models.mobileDevices.title')} $my='lg'>
      <SmartText tkey='models.mobileDevices.explanations' />
    </InfoCard>
  )
}

const MobileDeviceItem: FC<{ mobileDevice: MobileDevice }> = ({
  mobileDevice: { brand, name, lastLoggedInAt, id }
}) => {
  const client = useClient()
  const { t } = useIntl()
  const [deleteMobileDevice] = useMutation(client.mobileDevices.delete(id))

  const BrandIcon = brand === 'Apple' ? Icons.Apple : Icons.Android

  return (
    <DelimitedBox $p='lg'>
      <Flex $alignItems='center' $justifyContent='center' $width='100%'>
        <Flex $flex={1}>
          <BrandIcon $size='lg' $mr='md' />
          <Flex $flexDirection='column'>
            <Text $lineHeight='md'>{name}</Text>
            <Text $fontSize='sm'>
              {t('models.mobileDevices.lastLoggedInAt', {
                date: moment.unix(lastLoggedInAt).format(t('formats.datetime'))
              })}
            </Text>
          </Flex>
        </Flex>
        <TrashButtonWithConfirm
          onPress={() => {
            deleteMobileDevice()
          }}
          $size='lg'
        />
      </Flex>
    </DelimitedBox>
  )
}

const MobileDevices = () => {
  const client = useClient()
  const mobileDevicesRequest = useQuery(client.mobileDevices.getMany())

  const mobileDevices = !isNil(mobileDevicesRequest.data) ? mobileDevicesRequest.data : []

  return (
    <Flex $flexDirection='column'>
      <MobileDevicesExplanation />
      {mobileDevicesRequest.isLoading ? (
        <LoadingList uniqueKey='MobileDevicesLoadingList' size={3} variant='single-line' />
      ) : isEmpty(mobileDevices) ? (
        <ListEmpty model='mobileDevices' />
      ) : (
        mobileDevices.map((x) => <MobileDeviceItem key={x.id} mobileDevice={x} />)
      )}
    </Flex>
  )
}

export const Component = MobileDevices
export default MobileDevices
