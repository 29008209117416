import { useIntl } from '@weenat/wintl'
import { Head, Slot } from 'app/routx-router'
import EditAppPreferences from 'app/src/me/EditAppPreferences'

const Preferences = () => {
  const { t } = useIntl()

  return (
    <>
      <Head title={t('titles.preferences')} />
      <EditAppPreferences />
      <Slot />
    </>
  )
}

export const Component = Preferences
export default Preferences
