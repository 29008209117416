import Icons from 'app/src/kit/Icons'
import { memo } from 'react'
import MarkerArrow from './MarkerArrow'
import MarkerText from './MarkerText'

const DevicesClusterMarker: FC<{ count: number }> = ({ count }) => {
  return (
    <Flex $flexDirection='column' $alignItems='center'>
      <Flex
        $borderRadius={8}
        $borderColor={'grayscale.white'}
        $borderWidth='sm'
        $backgroundColor={'grayscale.black'}
        $p='md'
        $alignItems='center'
        $gap='lg'
        $boxShadow='0px 2px 8px 0px rgba(0, 38, 53, 0.1)'
      >
        <Icons.SingleSensorFilled $size='md' $color={'grayscale.white'} />
        <MarkerText $color={'grayscale.white'}>{count}</MarkerText>
      </Flex>
      <MarkerArrow />
    </Flex>
  )
}

export default memo(DevicesClusterMarker)
