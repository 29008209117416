import {
  enums,
  mergeMutationState,
  schemas,
  useClient,
  useMutation,
  useQuery
} from '@weenat/client'
import ErrCode from '@weenat/client/dist/ErrCode'
import { PossibleError } from '@weenat/client/dist/errors'
import * as zodSchemas from '@weenat/client/dist/zod-schemas'
import { useIntl } from '@weenat/wintl'
import { useNavigate, useSearchParams } from 'app/routx-router'
import AuthCard from 'app/src/authentication/components/AuthCard'
import { useLogout } from 'app/src/authentication/components/Logout'
import useToasts from 'app/src/hooks/useToasts'
import useWishedNetworkFromUrl from 'app/src/hooks/useWishedNetworkFromUrl'
import Button from 'app/src/kit/Button'
import DelimitedFlex from 'app/src/kit/DelimitedFlex'
import Link from 'app/src/kit/LinkComponent'
import SubmitButton from 'app/src/kit/SubmitButton'
import SuperForm from 'app/src/kit/SuperForm'
import Text from 'app/src/kit/Text'
import EmailField from 'app/src/kit/fields/EmailField'
import PasswordField from 'app/src/kit/fields/PasswordField'
import { useToken } from 'app/state'
import logEvent from 'app/utils/analytics'
import { FormikProps } from 'formik'
import { isEmpty } from 'lodash-es'
import isNil from 'lodash-es/isNil'
import { useEffect, useRef } from 'react'

export const settings = {
  search: zodSchemas.email.merge(zodSchemas.wishedNetwork)
}

/**
 * After 10 days we redirect users with an unverified email toward email validation form
 */
const useHandleUnverifiedUserEmail = () => {
  const logout = useLogout()
  const nav = useNavigate()
  return (e: PossibleError) => {
    if (e.status === 401) {
      // TODO: check that location.assign is correct
      logout().then(() => nav('/', { replace: true }))
    }
    if (Array.isArray(e.nonFieldErrors)) {
      const [firstNonFieldError] = e.nonFieldErrors
      if (firstNonFieldError?.code === ErrCode.userUnverifiedEmail) {
        // TODO: see https://linear.app/weenat/issue/APP-403#comment-b7abea8c
        logout('/auth/send-verification-email')
      }
    }
  }
}

const LOGIN_FORM_SCHEMA = schemas.auth.login
type LoginFormValues = typeof LOGIN_FORM_SCHEMA.initialValues

const Login = () => {
  const nav = useNavigate()
  const { t } = useIntl()
  const client = useClient()
  const [searchParams] = useSearchParams(settings.search)
  const { addErrorToast } = useToasts()
  const [token, setToken] = useToken()

  const emailRef = useRef<string>('')
  const formRef = useRef<FormikProps<Partial<LoginFormValues>>>(null)

  const wishedNetwork = useWishedNetworkFromUrl() || searchParams.wishedNetwork

  const networkName = useQuery(client.networks.get(wishedNetwork?.networkId), {
    enabled: !isNil(wishedNetwork?.networkId)
  }).data?.name

  const handleAfterBegNetworkMembership = () => {
    nav('/plots')
  }

  const [begNetworkMemberShip, begNetworkMemberShipRequest] = useMutation(
    client.invitations.beg.networkMembership(wishedNetwork?.networkId ?? -1),
    {
      onSettled: handleAfterBegNetworkMembership
    }
  )

  const ownedOrgsRequest = useQuery(client.orgs.getAllPages({ myRole: enums.OrgRole.owner }), {
    enabled: !isNil(wishedNetwork) && !isNil(token)
  })
  const ownedOrgs = ownedOrgsRequest.data

  const {
    isError: begNetworkMemberShipRequestIsError,
    isSuccess: begNetworkMemberShipRequestIsSuccess
  } = begNetworkMemberShipRequest

  const handleUnverifiedUserEmail = useHandleUnverifiedUserEmail()

  const [login, loginRequest] = useMutation(client.auth.login(), {
    onSuccess: (data) => {
      setToken(data.token)
      if (data.me.lastSeen === null) {
        if (!wishedNetwork) {
          nav('/onboarding/welcome')
        } else {
          nav('/onboarding/welcome', { search: { networkName } })
        }
      } else if (!wishedNetwork) {
        nav('/plots')
      }
    },
    onError: (e) => {
      handleUnverifiedUserEmail(e)
      const [firstGlobalError] = e.nonFieldErrors ?? []
      if (!isNil(firstGlobalError)) {
        let message: string

        if (!isEmpty(t(`error_codes.${firstGlobalError.code as '501'}`))) {
          message = t(`error_codes.${firstGlobalError.code as '501'}`)
        } else {
          const { message: newMessage } = firstGlobalError
          message = newMessage
        }

        addErrorToast(message)
      }
    }
  })

  const mutationState = mergeMutationState(loginRequest, begNetworkMemberShipRequest)

  const { isLoading, isError, isSuccess } = !isNil(wishedNetwork)
    ? {
        isError: mutationState.isError || ownedOrgsRequest.isError,
        isLoading: mutationState.isPending || ownedOrgsRequest.isLoading,
        isSuccess: mutationState.isPending || ownedOrgsRequest.isLoading
      }
    : {
        isLoading: loginRequest.isPending,
        isError: loginRequest.isError,
        isSuccess: loginRequest.isSuccess
      }

  const sharedProps = { isDisabled: loginRequest.isPending, errorState: loginRequest.error }

  const setEmailRefAndLogUser = async (values: LoginFormValues) => {
    emailRef.current = values.email
    await login(values)
  }

  const initialValues = { email: searchParams?.email ?? '' }

  useEffect(() => {
    // we can send request to join network automatically
    // only if there is only one org
    if (
      !isNil(ownedOrgs) &&
      ownedOrgs.length === 1 &&
      //don't retrigger if we have error or success
      begNetworkMemberShipRequestIsError === false &&
      begNetworkMemberShipRequestIsSuccess === false
    ) {
      const [firstOwnedOrg] = ownedOrgs
      if (
        !isNil(wishedNetwork) &&
        !isNil(firstOwnedOrg) &&
        firstOwnedOrg.subscribedNetworkId === null
      ) {
        begNetworkMemberShip({ organizationId: firstOwnedOrg.id, trial: wishedNetwork.trial })
      }
    }
  }, [
    begNetworkMemberShip,
    begNetworkMemberShipRequestIsError,
    begNetworkMemberShipRequestIsSuccess,
    ownedOrgs,
    wishedNetwork
  ])

  return (
    <AuthCard>
      <Flex $flexDirection='column' $gap='lg'>
        <Text $fontSize='md' $fontWeight='bold' $textAlign='center'>
          {t('models.account.actions.login')}
        </Text>

        <SuperForm
          schema={LOGIN_FORM_SCHEMA}
          onSubmit={setEmailRefAndLogUser}
          initialValues={initialValues}
          formRef={formRef}
        >
          <Flex $flexDirection='column' width={'100%'} $gap='xl'>
            <Flex $flexDirection='column'>
              <EmailField {...sharedProps} />
              <PasswordField {...sharedProps} withStrengthMeter={false} />
              <Link
                $underlined
                onPress={() => {
                  logEvent('reset_password')
                  // could not be a to props because formRef is a ref and is not updated at each render but value is correct on click
                  nav(`/auth/reset-password?email=${formRef.current?.values.email}`)
                }}
                $textAlign='right'
              >
                {t('auth.forgot_password')}
              </Link>
            </Flex>
            <SubmitButton isLoading={isLoading} isError={isError} isSuccess={isSuccess}>
              {t('models.account.actions.login')}
            </SubmitButton>
          </Flex>
        </SuperForm>
        <Link
          $underlined
          onPress={() => {
            logEvent('login_magic')
            // could not be a props because formRef is a ref and is not updated at each render but value is correct on click
            nav(`/auth/login/magic?email=${formRef.current?.values.email}`)
          }}
          $textAlign='center'
        >
          {t('auth.login_magic')}
        </Link>

        <DelimitedFlex
          $isDelimitedOnTop
          $pt='lg'
          $mt='lg'
          $alignSelf='stretch'
          $alignItems='stretch'
        >
          <Button
            importance='sd'
            onPress={() =>
              nav('/auth/register', {
                search: {
                  // could not be a props because formRef is a ref and is not updated at each render but value is correct on click
                  email: formRef.current?.values.email,
                  wishedNetwork: wishedNetwork
                    ? {
                        ...wishedNetwork,
                        networkName: networkName as string
                      }
                    : undefined
                }
              })
            }
            isDisabled={loginRequest.isPending}
            style={{ width: '100%' }}
          >
            {t('models.account.actions.register')}
          </Button>
        </DelimitedFlex>
      </Flex>
    </AuthCard>
  )
}

export const Component = Login
export default Login
