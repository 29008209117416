import { useIntl } from '@weenat/wintl'
import TextField, { TextFieldProps } from './TextField'

const AddressLine1Field = (props: TextFieldProps) => {
  const { t } = useIntl()
  return (
    <TextField
      {...props}
      inputType='addressLine1'
      label={t('models.billingAccount.fields.addressLine1.label', { capitalize: true })}
    />
  )
}
export default AddressLine1Field
