import { Href } from '@weenat/client/dist/routx/runtime-core'
import { useNavigate } from 'app/routx-router'
import isNil from 'lodash-es/isNil'
import Button, { ButtonProps } from './Button'
import Icons from './Icons'

export interface ButtonCreateProps extends ButtonProps {
  to?: Href
}

const ButtonCreate: FC<ButtonCreateProps> = ({ children, to, isDisabled, ...props }) => {
  const nav = useNavigate()

  return (
    <Button
      IconLeft={Icons.PlusSign}
      onPress={() => {
        if (!isDisabled && !isNil(to)) nav(to)
      }}
      isDisabled={isDisabled}
      {...props}
    >
      {children}
    </Button>
  )
}

export default ButtonCreate
