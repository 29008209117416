import { FlexProps } from 'app/src/kit/primitives/themeMappings/props'

import { useIntl } from '@weenat/wintl'
import Button, { ButtonProps } from './Button'
import Icons from './Icons'

type Variant = 'icons' | 'buttons'

type Status = {
  isPending: boolean
  isError: boolean
  isSuccess: boolean
}

interface DeclineAndAcceptButtonsProps extends FlexProps {
  variant?: Variant
  handleDeclinePress: () => void
  handleAcceptPress: () => void
  declineStatus: Status
  acceptStatus: Status
}

const DeclineAndAcceptButtons: FC<DeclineAndAcceptButtonsProps> = ({
  variant,
  handleDeclinePress,
  handleAcceptPress,
  declineStatus,
  acceptStatus,
  ...flexProps
}) => {
  const { t } = useIntl()

  const areButtonsDisabled = declineStatus.isPending || acceptStatus.isPending
  const buttonProps: ButtonProps = { importance: 'sd', style: { width: '100%' } }

  return (
    <Flex {...flexProps}>
      <Box $mr='md'>
        {variant === 'icons' ? (
          <Icons.Close
            $rounded
            $size='lg'
            $color={'grayscale.white'}
            $backgroundColor={'feedback.error.500'}
            onPress={handleDeclinePress}
            $isLoading={declineStatus.isPending}
            $p='sm'
          />
        ) : (
          <Button
            isDisabled={areButtonsDisabled}
            isLoading={declineStatus.isPending}
            isDanger
            onPress={handleDeclinePress}
            isError={declineStatus.isError}
            isSuccess={declineStatus.isSuccess}
            IconLeft={Icons.Close}
            {...buttonProps}
          >
            {t('actions.decline')}
          </Button>
        )}
      </Box>
      <Box>
        {variant === 'icons' ? (
          <Icons.Checkmark
            $rounded
            $size='lg'
            $color={'grayscale.white'}
            $backgroundColor='primary.500'
            onPress={handleAcceptPress}
            $isLoading={acceptStatus.isPending}
            $p='sm'
          />
        ) : (
          <Button
            isDisabled={areButtonsDisabled}
            isLoading={acceptStatus.isPending}
            isSuccess={acceptStatus.isSuccess}
            onPress={handleAcceptPress}
            isError={acceptStatus.isError}
            IconLeft={Icons.Checkmark}
            {...buttonProps}
          >
            {t('actions.accept')}
          </Button>
        )}
      </Box>
    </Flex>
  )
}

export default DeclineAndAcceptButtons
