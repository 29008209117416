import isNil from 'lodash-es/isNil'

const encodeAndPrefixIfNonNil = (prefixer: string, value?: string) =>
  !isNil(value) ? `${prefixer}${encodeURIComponent(value)}` : ''

const formatMailTo = ({ email, subject, body }: { email: string; subject: string; body: string }) =>
  `mailto:${email}${encodeAndPrefixIfNonNil('?subject=', subject)}${encodeAndPrefixIfNonNil(
    '&body=',
    body
  )}`

export default formatMailTo
