import { memo } from 'react'
import { styled } from 'styled-components'
import Text from '../kit/Text'
import JoinNetworkCTASection from './JoinNetworkCTASection'
import NetworkDescriptionSection from './NetworkDescriptionSection'
import { NetworkPresentationCommonProps } from './NetworkPresentationLayoutTools'
import NetworkResumeSection from './NetworkResumeSection'

const NetworkLogo = styled.img`
  width: 100%;
  max-height: 128px;
  object-fit: contain;
`

const Container = styled(Box)`
  background-color: ${(p) => p.theme.colors.grayscale.white};
  border: 1px solid ${(p) => p.theme.colors.grayscale[300]};
  border-radius: ${(p) => p.theme.radiuses.lg}px;
  padding-bottom: ${(p) => p.theme.spacings['2xl']}px;
  margin-bottom: -${(p) => p.theme.spacings.xl}px;
`

interface NetworkPresentationProps extends NetworkPresentationCommonProps {
  showName?: boolean
}

const NetworkPresentation = ({ network, showName = false }: NetworkPresentationProps) => {
  return (
    <Container>
      {showName ? (
        <Box $p='lg'>
          <Text $fontWeight='bold' $fontSize='lg'>
            {network.name}
          </Text>
        </Box>
      ) : null}

      <Box $p='lg'>
        <NetworkLogo src={network.logo} />
      </Box>
      <NetworkDescriptionSection network={network} />
      <NetworkResumeSection network={network} />
      <JoinNetworkCTASection network={network} />
    </Container>
  )
}

export default memo(NetworkPresentation)
