import { mergeQueryState, useClient, useQuery } from '@weenat/client'
import { GetManyAlertsQueryParams } from '@weenat/client/dist/resources/alerts'
import { Alert } from '@weenat/client/dist/resources/alerts.types'
import { useIntl } from '@weenat/wintl'
import useDebounce from 'app/src/hooks/useDebounce'
import ListEmpty from 'app/src/kit/ListEmpty'
import LoadingList from 'app/src/kit/LoadingList'
import SearchQueryForm from 'app/src/kit/SearchQueryForm'
import { useUserContext } from 'app/userProvider'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { useMemo, useState } from 'react'
import AlertListItem from './AlertListItem'

const EMPTY_ALERTS: Alert[] = []

interface AlertsListProps {
  queryParams?: Partial<GetManyAlertsQueryParams>
  searchable?: boolean
}

const AlertsList: FC<AlertsListProps> = ({ queryParams, searchable = false }) => {
  const client = useClient()
  const { t } = useIntl()
  const [searchInputValue, setSearchInputValue] = useState('')
  const debouncedSearchInputValue = useDebounce(searchInputValue, 500)

  const { user, requestState } = useUserContext()
  const preferredUnits = user?.profile ? user?.profile.units : undefined

  const alertsRequest = useQuery(client.alerts.getMany(preferredUnits, queryParams), {
    enabled: !isNil(preferredUnits)
  })
  const allAlerts = alertsRequest.data ?? EMPTY_ALERTS

  const visibleAlerts = useMemo(() => {
    let temp = allAlerts

    if (!isEmpty(debouncedSearchInputValue)) {
      temp = allAlerts.filter((alert) =>
        alert.name.toLowerCase().includes(debouncedSearchInputValue.toLowerCase())
      )
    }

    return temp
  }, [allAlerts, debouncedSearchInputValue])

  const { isLoading } = mergeQueryState(requestState, alertsRequest)

  return !isLoading ? (
    <Flex $flexDirection='column' $py='lg' $gap='md'>
      {searchable ? (
        <SearchQueryForm
          onQueryChange={(query) => {
            setSearchInputValue(query)
          }}
          placeholder={t('models.alert.model.search_item')}
        />
      ) : null}
      {isEmpty(visibleAlerts) ? (
        <ListEmpty mood='confused' model='alert' />
      ) : (
        <Flex $flexDirection='column' $gap='lg'>
          {visibleAlerts.map((alert, i) => {
            return i === 0 ? (
              <AlertListItem key={alert._id} alert={alert} withPop />
            ) : (
              <AlertListItem key={alert._id} alert={alert} />
            )
          })}
        </Flex>
      )}
    </Flex>
  ) : (
    <LoadingList variant='card' uniqueKey='AlertsList' />
  )
}

export default AlertsList
