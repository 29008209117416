import { Universe } from '@weenat/client/dist/core/universes'
import isNil from 'lodash-es/isNil'
import union from 'lodash-es/union'
import UniverseOption from './UniverseOption'

const EMPTY_UNIVERSES: Universe[] = [] as const
const EMPTY_UNIVERSES_OPTIONS: { value: Universe; label: JSX.Element; isDisabled: boolean }[] =
  [] as const

/**
 * All available options for a universe selector/chips tabs
 */
const useUniverseOptions = (
  universes: Universe[] | undefined,
  isAccessRestrictedByUnpaidBill?: boolean
) => {
  const allUniverses = isNil(universes) ? EMPTY_UNIVERSES : union([Universe.weather], universes)

  // We do not display the options if there is only the weather universe
  return allUniverses.length === 1 && allUniverses[0] === Universe.weather
    ? EMPTY_UNIVERSES_OPTIONS
    : allUniverses.map((univ) => ({
        value: univ,
        label: (
          <UniverseOption
            universe={univ}
            isAccessRestrictedByUnpaidBill={isAccessRestrictedByUnpaidBill}
          />
        ),
        isDisabled: isAccessRestrictedByUnpaidBill
      }))
}

export default useUniverseOptions
