import { SwitchUniverseParams } from '@weenat/client/dist/core/analytics'
import { Universe } from '@weenat/client/dist/core/universes'
import { useOrgContext } from 'app/orgProvider'
import usePlotsOrDevicesFromPathname from 'app/src/hooks/usePlotsOrDevicesFromPathname'
import ChipsTabs from 'app/src/kit/ChipsTabs'
import Pop from 'app/src/kit/Pop'
import FadeIn from 'app/src/kit/animations/FadeIn'
import { useUniverse } from 'app/state'
import { logSwitchUniverse } from 'app/utils/analytics'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { useEffect } from 'react'
import useUniverseOptionsQuery from './useUniverseOptionsQuery'

const UniverseChipsTabs = ({ omit }: { omit?: Universe[] }) => {
  const [currentUniv, setUniv] = useUniverse()
  const { currentOrgId } = useOrgContext()
  const plotsOrDevices = usePlotsOrDevicesFromPathname()
  const { universeOptions: allUniverseOption, universesRequest } =
    useUniverseOptionsQuery(currentOrgId)
  const universeOptions = !isNil(omit)
    ? allUniverseOption.filter((univOption) => !omit?.includes(univOption.value))
    : allUniverseOption
  const onChange = (u: Universe) => {
    !isNil(plotsOrDevices) &&
      logSwitchUniverse({
        action: `${plotsOrDevices}_switch_universe`,
        selected: Universe[u] as SwitchUniverseParams['selected']
      })
    setUniv(u)
  }
  // Resetting the current universe to weather if the org ID is defined and the current universe is not inside the available options
  useEffect(() => {
    const currentOptionAvailable =
      !isEmpty(universeOptions) &&
      universeOptions.some((opt) => opt.value === currentUniv && !opt.isDisabled)

    if (!isNil(currentOrgId) && universesRequest.isSuccess && !currentOptionAvailable) {
      setUniv(Universe.weather)
    }
  }, [currentOrgId, universeOptions])

  return universesRequest.isSuccess ? (
    <FadeIn>
      <Box $pointerEvents='auto'>
        <Pop tourName='discover_irrigation' stepName='change_univ'>
          <ChipsTabs
            options={universeOptions}
            value={currentUniv}
            onChange={onChange}
            $isRounded
            $isShadowed
          />
        </Pop>
      </Box>
    </FadeIn>
  ) : null
}

export default UniverseChipsTabs
