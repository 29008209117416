import { core, schemas } from '@weenat/client'
import { useIntl } from '@weenat/wintl'
import Text from 'app/src/kit/Text'
import UseOnChange from 'app/src/kit/fields/UseOnChange'
import isArray from 'lodash-es/isArray'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { transparentize } from 'polished'
import { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react'
import { styled, useTheme } from 'styled-components'
import Button from '../Button'
import DelimitedFlex from '../DelimitedFlex'
import Icons from '../Icons'
import LoadingList from '../LoadingList'
import Modal from '../Modal'
import SuperForm from '../SuperForm'
import SelectOptionItem from './Select/SelectOptionItem'
import TextField from './TextField'

const StyledForm = styled(SuperForm)`
  position: sticky;
  z-index: 1;
  background-color: ${(p) => p.theme.colors.grayscale[50]};
  border-bottom: 1px solid ${(p) => p.theme.colors.grayscale[300]};
  left: 0;
  top: -8px;
`

const FiltersContainer = styled(Box)`
  position: sticky;
  left: 0;
  top: 0;
  flex-direction: column;
  margin-top: 16px;
  border-radius: ${(props) => props.theme.radiuses.md}px;
  padding: 8px 16px 12px;
  border: 1px solid ${(props) => props.theme.colors.grayscale[300]};
  background-color: ${(props) => transparentize(0.5, props.theme.colors.grayscale[100])};
  flex: 1;
  overflow-y: auto;
`

const FiltersHeader = styled(Flex)`
  margin: 8px 0 16px;
  align-items: center;
  gap: 8px;
`

const ResultsContainer = styled(Box)<{ $hasFilter: boolean }>`
  padding: ${(props) => (props.$hasFilter ? '8px 16px' : '0px')};
  flex: 2;
  overflow-y: auto;
`

const SEARCH_SCHEMA = schemas.searchQuery
type InitialValues = typeof SEARCH_SCHEMA.initialValues
type FieldName = keyof InitialValues

interface SearchQueryFormProps {
  onSubmit: () => void
  onChange: (x: Record<string, unknown>) => void
}

const SearchQueryForm: FC<SearchQueryFormProps> = ({ onSubmit, onChange }) => {
  const { t } = useIntl()

  const orderedFieldsProps = core.useOrderedFields<FieldName>(['searchQuery'])

  return (
    <StyledForm schema={SEARCH_SCHEMA} onSubmit={onSubmit}>
      <TextField
        {...orderedFieldsProps.searchQuery}
        placeholder={t('actions.search')}
        leftAdornment={<Icons.Search $size='md' />}
        hideErrors
      />
      <UseOnChange onChange={onChange} />
    </StyledForm>
  )
}

interface SelectOptionType {
  value: unknown
  label: string
}

interface Props {
  currentValue: unknown
  close: () => void
  emptyOptionsMessage?: string
  handleSelectionChange: (option: SelectOptionType | undefined) => void
  isOpen: boolean
  options: SelectOptionType[]
  title?: string
  filterPage?: React.ReactElement
  /** Number of active filters */
  activeFilters?: number
  isLoading?: boolean
  width?: number | string
  height?: number | string
  optionItemChildren?: (item: SelectOptionType) => ReactNode
  isMulti?: boolean
  footer?: ReactElement
}

/**
 * Do not use outside of a SelectField
 */
const SearchSelectOptionsModal: FC<Props> = ({
  activeFilters,
  currentValue,
  close,
  emptyOptionsMessage,
  handleSelectionChange,
  isOpen = false,
  options,
  title,
  filterPage,
  isLoading = false,
  width,
  height,
  optionItemChildren,
  isMulti = false,
  footer
}) => {
  const { t } = useIntl()
  const { colors } = useTheme()

  const [searchQuery, setSearchQuery] = useState<string | undefined | null>('')

  const filteredOptions = useMemo(
    () =>
      isNil(searchQuery) || isEmpty(searchQuery)
        ? options
        : options.filter((x) => x.label.toLowerCase().includes(searchQuery.toLowerCase())),
    [searchQuery, options]
  )

  // If the value is set through helpers we still want to close the modal
  useEffect(() => {
    if (!isMulti && isOpen) {
      close()
    }
  }, [currentValue])

  return (
    <Modal
      width={!isNil(width) ? width : '50%'}
      maxWidth={1280}
      height={height}
      isOpen={isOpen}
      close={close}
      title={title}
      headerProps={{ $pb: 0 }}
      footer={footer}
    >
      <SearchQueryForm
        onSubmit={() => {
          if (filteredOptions.length === 1) handleSelectionChange(filteredOptions[0])
        }}
        onChange={(values) => {
          setSearchQuery(values.searchQuery as string)
        }}
      />
      <Flex $flexDirection='column' $height='75vh'>
        {isLoading ? (
          <LoadingList variant='navigable' uniqueKey='SelectFieldModalSearchLoading' size={6} />
        ) : (
          <Flex $flex={1} $flexDirection='column'>
            <Flex $flex={1} $justifyContent='stretch'>
              {!isNil(filterPage) ? (
                <FiltersContainer>
                  <FiltersHeader>
                    {isNil(activeFilters) || (!isNil(activeFilters) && activeFilters <= 0) ? (
                      <Icons.FilterList $size='md' $color={colors.grayscale.black} />
                    ) : (
                      <Flex
                        $borderRadius='sm'
                        $minWidth={24}
                        $height={24}
                        $alignItems='center'
                        $justifyContent='center'
                        $backgroundColor={colors.grayscale.black}
                        $p='sm'
                      >
                        <Text $fontSize='sm' $fontWeight='bold' $color={colors.grayscale.white}>
                          {activeFilters}
                        </Text>
                      </Flex>
                    )}
                    <Text $fontWeight='bold' $fontSize='md'>
                      {t('filtering.filters', { capitalize: true })}
                    </Text>
                  </FiltersHeader>
                  {filterPage}
                </FiltersContainer>
              ) : null}

              <ResultsContainer $hasFilter={!isNil(filterPage)}>
                {!isNil(filterPage) ? (
                  <Flex $mt='md' $mb='lg'>
                    <Text $fontWeight='bold' $fontSize='md'>
                      {t('filtering.matching_results_abbr', {
                        numberOfMatches: filteredOptions.length
                      })}
                    </Text>
                  </Flex>
                ) : null}
                {isEmpty(filteredOptions) ? (
                  <Text>{emptyOptionsMessage}</Text>
                ) : (
                  filteredOptions.map((option) => {
                    const isSelected =
                      isArray(currentValue) && isMulti
                        ? currentValue.includes(option.value)
                        : currentValue === option.value

                    return (
                      <SelectOptionItem
                        key={`${option.label}-${option.value}`}
                        isSelected={isSelected}
                        onClick={() => handleSelectionChange(option)}
                        isMultiChoice={isMulti}
                      >
                        <Box>
                          <Text>{option.label}</Text>
                          {!isNil(optionItemChildren) ? optionItemChildren(option) : null}
                        </Box>
                      </SelectOptionItem>
                    )
                  })
                )}
              </ResultsContainer>
            </Flex>
            {isMulti ? (
              <DelimitedFlex
                $isDelimitedOnTop
                $width='100%'
                $justifyContent='flex-end'
                $gap='lg'
                $pt='lg'
              >
                <Button onPress={close}>{t('actions.confirm', { capitalize: true })}</Button>
              </DelimitedFlex>
            ) : null}
          </Flex>
        )}
      </Flex>
    </Modal>
  )
}

export default SearchSelectOptionsModal
