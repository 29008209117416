import { mergeMutationState, schemas, useClient, useMutation, useQuery } from '@weenat/client'
import { getDeviceCommonName } from '@weenat/client/dist/core/devices'
import {
  DeviceIdsRecord,
  buildConflictsFromIdsRecord
} from '@weenat/client/dist/core/plots/useUpdateOrgPlotDevices'
import { Brand } from '@weenat/client/dist/enums/Devices'
import { isMetricWithHorizon } from '@weenat/client/dist/enums/MetricIds'
import { Device, DeviceAvailableMeasure } from '@weenat/client/dist/resources/devices'
import { Org } from '@weenat/client/dist/resources/orgs'
import { useIntl } from '@weenat/wintl'
import { useMatch, useNavigate } from 'app/routx-router'
import CreateDevice from 'app/src/administration/devices/CreateDevice'
import DeviceConflictModal from 'app/src/administration/plots/DeviceConflictModal'
import DeviceSelectionCard, { DeviceSelectionCardProps } from 'app/src/devices/DeviceSelectionCard'
import HorizonModal from 'app/src/devices/HorizonModal'
import useDebounce from 'app/src/hooks/useDebounce'
import useDisclosure from 'app/src/hooks/useDisclosure'
import useToasts from 'app/src/hooks/useToasts'
import Button from 'app/src/kit/Button'
import Icons from 'app/src/kit/Icons'
import ListEmpty from 'app/src/kit/ListEmpty'
import Modal from 'app/src/kit/Modal'
import SubmitButton from 'app/src/kit/SubmitButton'
import SuperForm from 'app/src/kit/SuperForm'
import Text from 'app/src/kit/Text'
import CheckBoxGroupField from 'app/src/kit/fields/CheckboxGroupField'
import RadioGroupField, { RadioGroupPrimitive } from 'app/src/kit/fields/RadioGroupField'
import { TextFieldPrimitive } from 'app/src/kit/fields/TextField'
import LoadingCircle from 'app/src/kit/loaders/LoadingCircle'
import TextTooltip from 'app/src/kit/tooltips/TextTooltip'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { CSSProperties, useCallback, useEffect, useState } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import { styled, useTheme } from 'styled-components'
import { plot_creation_href, plot_modification_href } from './constants'
import { PlotCreationSearchParams, PlotCreationStepProps } from './types'
import { flex1, getDeviceDepth, isDeviceSelected } from './utils'

const StyledTextFields = styled(TextFieldPrimitive)`
  flex: 1;
`

const DebounceSearchField: FC<{ onChange: (newVal: string) => void }> = ({ onChange }) => {
  const { t } = useIntl()
  const [value, setValue] = useState<string>()

  const debouncedValue = useDebounce(value, 500)

  useEffect(() => {
    if (!isNil(debouncedValue)) {
      onChange(debouncedValue)
    }
  }, [debouncedValue, onChange])

  return (
    <StyledTextFields
      name='q'
      placeholder={t('models.device.model.search_item')}
      leftAdornment={<Icons.Search />}
      hideErrors
      onChange={(e) => setValue(e.currentTarget.value)}
    />
  )
}

const ADevice: FC<{
  index: number
  data: Omit<DeviceSelectionCardProps, 'style'>[]
  style: CSSProperties
}> = ({ index, data, style }) => {
  const props = data[index]
  return <DeviceSelectionCard key={props.device.id} {...props} style={style} />
}

interface AddDeviceToOrgButtonProps {
  org: Org
  add: PlotCreationStepProps['add']
}

const AddDeviceToOrgButton = ({ org, add }: AddDeviceToOrgButtonProps) => {
  const { isOpen, close, open } = useDisclosure()
  const { colors } = useTheme()
  const { t } = useIntl()

  const [createdDevice, setCreatedDevice] = useState<Device | undefined>(undefined)

  const title = t('models.device.actions.create_short', { capitalize: true })

  return (
    <>
      <TextTooltip content={title}>
        <Icons.SingleSensorFilledAdd
          $size='lg'
          $backgroundColor={colors.grayscale.white}
          $borderColor={colors.grayscale[100]}
          $p='md'
          onPress={open}
        />
      </TextTooltip>
      <Modal isOpen={isOpen} close={close} title={title}>
        <CreateDevice
          hideFollowupActions
          organization={org}
          successMessage={t('models.device.actions.created_and_added_to_selection_success')}
          onDeviceCreated={(newDevice) => {
            const { availableMeasures, id } = newDevice

            if (availableMeasures.some((m) => isMetricWithHorizon(m))) {
              setCreatedDevice(newDevice)
            } else {
              add?.(id)
              close()
            }
          }}
        />
      </Modal>
      <HorizonModal
        close={() => {
          setCreatedDevice(undefined)
        }}
        deviceId={!isNil(createdDevice) ? createdDevice.id : undefined}
        deviceName={!isNil(createdDevice) ? getDeviceCommonName(createdDevice) : ''}
        isOpen={!isNil(createdDevice)}
        depth={undefined}
        onAdd={(deviceId, depth, horizonId) => {
          add?.(deviceId, depth, horizonId)
          close()
        }}
      />
    </>
  )
}

const SortDeviceButton: FC<{ setOrder: PlotCreationStepProps['setOrder'] }> = ({ setOrder }) => {
  const { colors } = useTheme()
  const { t } = useIntl()

  const { isOpen, close, open } = useDisclosure()
  const [sortOrder, setSortOrder] = useState<0 | 1 | 2 | 3 | undefined>()

  useEffect(() => {
    switch (sortOrder) {
      case 0:
        setOrder('distance_from', 'asc')
        break
      case 1:
        setOrder('distance_from', 'desc')
        break
      case 2:
        setOrder('created_at', 'asc')
        break
      case 3:
        setOrder('created_at', 'desc')
        break
    }
    close()
  }, [sortOrder])

  const title = t('models.plot.creation.sort_devices_title', { capitalize: true })

  return (
    <>
      <TextTooltip content={title}>
        <Icons.SortByAlpha
          $size='lg'
          $backgroundColor={colors.grayscale.white}
          $borderColor={colors.grayscale[100]}
          $p='md'
          onPress={open}
        />
      </TextTooltip>
      <Modal isOpen={isOpen} close={close} title={title}>
        <Box $px='sm'>
          <RadioGroupPrimitive
            options={[
              { label: t('models.plot.creation.sort_devices_distance_asc'), value: 0 },
              { label: t('models.plot.creation.sort_devices_distance_desc'), value: 1 },
              {
                label: t('models.plot.creation.sort_devices_creation_asc'),
                value: 2
              },
              {
                label: t('models.plot.creation.sort_devices_creation_desc'),
                value: 3
              }
            ]}
            value={sortOrder}
            //@ts-expect-error setter could be of two type and ts not clever enought to detect that we only want one of them
            onChange={setSortOrder}
          />
        </Box>
      </Modal>
    </>
  )
}

const devicesMetrics: DeviceAvailableMeasure[] = [
  'T',
  'RR',
  'U',
  'T_DRY',
  'T_WET',
  'U_CAPA',
  'HPOT',
  'T_SOIL',
  'FF',
  'DD',
  'LW_D'
] as const

const StyledForm = styled(SuperForm)`
  height: 100%;
`

const filtersSchema = schemas.devices.filter
type FiltersSchemaValues = typeof filtersSchema.initialValues

interface FilterDeviceButtonProps {
  setFilter: PlotCreationStepProps['setFilter']
  brand: PlotCreationSearchParams['brand']
  metrics: PlotCreationSearchParams['metrics']
  ownedByOrganizationId: PlotCreationSearchParams['ownedByOrganizationId']
  hasActiveFilters: boolean
  org: Org
}

const FilterDeviceButton: FC<FilterDeviceButtonProps> = ({
  setFilter,
  metrics,
  brand,
  hasActiveFilters,
  ownedByOrganizationId,
  org
}) => {
  const { isOpen, close, open } = useDisclosure()
  const { t } = useIntl()

  const title = t('models.plot.creation.filter_devices_title', { capitalize: true })

  const initialValues: FiltersSchemaValues = {
    brand: typeof brand === 'string' ? parseInt(brand, 10) : brand,
    metrics,
    organizationId:
      typeof ownedByOrganizationId === 'string'
        ? parseInt(ownedByOrganizationId, 10)
        : ownedByOrganizationId
  }

  const deviceBrandFilteringOptions = [
    { label: t('summaries.metric_providers.weenat'), value: Brand.weenat },
    { label: t('summaries.metric_providers.pessl'), value: Brand.pessl },
    { label: t('summaries.metric_providers.davis'), value: Brand.davis }
  ]

  const deviceOrgFilteringOptions = [
    {
      label: t('models.device.filters.org_devices', { capitalize: true }),
      value: org.id
    }
  ]
  return (
    <>
      <TextTooltip content={title}>
        <Icons.FilterList
          $size='lg'
          $backgroundColor={hasActiveFilters ? 'primary.500' : 'grayscale.white'}
          $borderColor={hasActiveFilters ? 'primary.800' : 'grayscale.300'}
          $color={hasActiveFilters ? 'grayscale.white' : undefined}
          $p='md'
          onPress={open}
        />
      </TextTooltip>
      <Modal isOpen={isOpen} close={close} title={title} height='90vh' maxHeight={840}>
        <StyledForm
          schema={filtersSchema}
          initialValues={initialValues}
          onSubmit={(values) => {
            try {
              setFilter({
                brand: values.brand,
                metrics: values.metrics,
                ownedByOrganizationId: values.organizationId
              })
            } catch {}
            close()
          }}
        >
          <Flex $flexDirection='column' $height='100%' $gap='md'>
            <Flex $flexDirection='column' $flex={1} $gap='lg' style={{ overflowY: 'auto' }}>
              <RadioGroupField
                name='organizationId'
                label={t('models.device.filters.sections.by_owner', { capitalize: true })}
                options={deviceOrgFilteringOptions}
              />
              <RadioGroupField
                name='brand'
                label={t('models.device.filters.sections.by_brand', { capitalize: true })}
                options={deviceBrandFilteringOptions}
              />
              <CheckBoxGroupField
                name='metrics'
                label={t('models.device.filters.sections.by_measures', { capitalize: true })}
                options={devicesMetrics.map((metric) => ({
                  label: t(`metrics.${metric as 'T'}`, { capitalize: true }),
                  value: metric
                }))}
              />
            </Flex>
            <SubmitButton />
          </Flex>
        </StyledForm>
      </Modal>
    </>
  )
}

const StyledFixedSizeList = styled(FixedSizeList)`
  * {
    scroll-behavior: smooth;
  }
`

const ITEM_HEIGHT = 188

interface EmptyStateProps {
  hasActiveFilters: boolean
  reinitializeFilters: () => void
}

const EmptyState = ({ hasActiveFilters, reinitializeFilters }: EmptyStateProps) => {
  const { t } = useIntl()

  return (
    <Flex $flexDirection='column' $alignItems='center' $justifyContent='center'>
      <ListEmpty model='device' />
      {hasActiveFilters ? (
        <Button importance='sd' onPress={reinitializeFilters}>
          {t('filtering.reinitialize', { capitalize: true })}
        </Button>
      ) : null}
    </Flex>
  )
}

type SelectDevicesStepProps = Pick<
  PlotCreationStepProps,
  | 'add'
  | 'closestDeviceId'
  | 'devices'
  | 'devicesLoading'
  | 'focus'
  | 'plot'
  | 'horizons'
  | 'listRef'
  | 'org'
  | 'remove'
  | 'search'
  | 'searchParams'
  | 'setFilter'
  | 'setOrder'
>

const SelectDevicesStep: FC<SelectDevicesStepProps> = ({
  add,
  closestDeviceId,
  devices,
  devicesLoading: isLoading,
  focus,
  plot,
  horizons,
  listRef,
  org,
  remove,
  search,
  searchParams,
  setFilter,
  setOrder
}) => {
  const { t } = useIntl()
  const nav = useNavigate()
  const client = useClient()
  const { addErrorToast } = useToasts()

  const plot_url = !isNil(searchParams.plotId) ? plot_modification_href : plot_creation_href

  const editionPathMatch = useMatch(plot_modification_href)
  const isPlotEdition = !isNil(editionPathMatch)

  const deviceIdsRecord: DeviceIdsRecord = {}

  searchParams.selectedDeviceIds?.reduce((acc, id) => {
    acc[id] = { isActive: true }
    return acc
  }, deviceIdsRecord)

  const deviceRequest = useQuery(client.devices.getMany({ ids: searchParams.selectedDeviceIds }))
  const allDevices = deviceRequest.data?.results || []

  const conflicts = buildConflictsFromIdsRecord(deviceIdsRecord, allDevices)

  const setParamsAfterConflictResolution = useCallback(
    (devicesToExclude: Device[]) => {
      const deviceIdsToExclude = devicesToExclude.map((d) => d.id)
      nav(plot_url, {
        search: {
          ...searchParams,
          selectedDeviceIds: (searchParams.selectedDeviceIds ?? []).reduce((acc, deviceId) => {
            if (!deviceIdsToExclude.includes(deviceId)) {
              acc.push(deviceId)
            }
            return acc
          }, [] as number[]),
          depths: (searchParams.depths ?? []).reduce((acc, depth) => {
            const [deviceIdString] = depth.split('_')
            if (!deviceIdsToExclude.includes(parseInt(deviceIdString, 10))) {
              acc.push(depth)
            }
            return acc
          }, [] as string[])
        }
      })
    },
    [nav, plot_url, searchParams]
  )

  const reinitializeFilters = useCallback(
    () => setFilter({ brand: undefined, metrics: undefined }),
    [setFilter]
  )

  const [updateHorizon, updateHorizonRequest] = useMutation(
    client.measurementsConfig.plots.changeHorizon()
  )

  const [updateDevices, updatePlotDevicesState] = useMutation(
    client.plots.updateDevices(searchParams?.plotId),
    {
      onSuccess: async (data) => {
        const { depths } = searchParams
        if (!isNil(depths) && !isEmpty(depths)) {
          const updatePromises = depths.map((depth) => {
            const [deviceId, , horizonId] = depth.split('_')

            return updateHorizon({
              deviceId: parseInt(deviceId, 10),
              horizonId: parseInt(horizonId, 10),
              plotId: data.id
            })
          })
          await Promise.all(updatePromises)
        }
        nav(`/administration/${org?.id}/plots/${searchParams.plotId}/devices`)
      },
      onError: () => {
        addErrorToast(t('models.plot.errors.linkDevicesOnPlot'))
      }
    }
  )

  const state = mergeMutationState(updatePlotDevicesState, updateHorizonRequest)

  const hasActiveFilters =
    !isNil(searchParams.brand) || (!isNil(searchParams.metrics) && !isEmpty(searchParams.metrics))

  const handleSubmit = () => {
    if (!isEmpty(conflicts.duplications) || !isEmpty(conflicts.incompatibilities)) {
      nav(plot_url, { search: { ...searchParams, hasConflict: true } })
    } else if (
      isPlotEdition &&
      !isNil(searchParams.plotId) &&
      !isNil(searchParams.selectedDeviceIds)
    ) {
      updateDevices({
        deviceIds: searchParams.selectedDeviceIds
      })
    } else {
      nav(plot_url, {
        search: { ...searchParams, step: 'submit', focusedDevice: undefined }
      })
    }
  }

  const handleCancel = () => {
    if (isPlotEdition) {
      plot && nav(`/administration/${org.id}/plots/${plot.id}/devices`)
    } else {
      nav(plot_url, {
        search: { ...searchParams, step: 'dataSource', focusedDevice: undefined }
      })
    }
  }

  return searchParams.hasConflict ? (
    <DeviceConflictModal
      isOpen={true}
      close={() => nav(plot_url, { search: { ...searchParams, hasConflict: false } })}
      selectedDeviceIds={deviceIdsRecord}
      devices={allDevices}
      onAllConflictsResolution={() => {
        nav(plot_url, { search: { ...searchParams, hasConflict: false, step: 'submit' } })
      }}
      onDeviceChoice={setParamsAfterConflictResolution}
    />
  ) : (
    <Flex $flex={1} $flexDirection='column' $pointerEvents='auto'>
      <Flex $flexDirection='column' $flex={1}>
        <Flex $gap='md' $alignItems='center' $mb='md'>
          <DebounceSearchField onChange={search} />
          <FilterDeviceButton
            brand={searchParams.brand}
            metrics={searchParams.metrics}
            ownedByOrganizationId={searchParams.ownedByOrganizationId}
            setFilter={setFilter}
            hasActiveFilters={hasActiveFilters}
            org={org}
          />
          <SortDeviceButton setOrder={setOrder} />
          <AddDeviceToOrgButton org={org} add={add} />
        </Flex>

        <div style={{ flex: '1 1 auto' }}>
          {isLoading ? (
            <Box $p='lg'>
              <LoadingCircle size='lg' />
            </Box>
          ) : isEmpty(devices) ? (
            <EmptyState
              reinitializeFilters={reinitializeFilters}
              hasActiveFilters={hasActiveFilters}
            />
          ) : (
            <AutoSizer>
              {({ height, width }) => (
                <StyledFixedSizeList
                  ref={listRef}
                  itemCount={devices.length}
                  itemSize={ITEM_HEIGHT}
                  height={height}
                  width={width}
                  itemData={devices.map((d) => {
                    const result: Omit<DeviceSelectionCardProps, 'style'> = {
                      device: d,
                      add,
                      remove,
                      isClosest: d.id === closestDeviceId,
                      depth: getDeviceDepth(searchParams, d),
                      isSelected: isDeviceSelected(searchParams, d),
                      focus,
                      org,
                      horizons
                    }
                    return result
                  })}
                >
                  {ADevice}
                </StyledFixedSizeList>
              )}
            </AutoSizer>
          )}
        </div>
        <Flex $mt='md' $p='sm' $alignItems='center' $gap='xl'>
          <Button
            isDisabled={!isNil(isPlotEdition) && state.isPending}
            importance='sd'
            color='transparent'
            onPress={handleCancel}
          >
            <Text $color='primary.500' $fontWeight='semiBold'>
              {isPlotEdition ? t('actions.cancel') : t('actions.previous')}
            </Text>
          </Button>
          <Button
            isDisabled={!isNil(isPlotEdition) && state.isPending}
            isLoading={!isNil(isPlotEdition) && state.isPending}
            style={flex1}
            onPress={handleSubmit}
          >
            {isPlotEdition ? t('actions.confirm') : t('actions.next')}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SelectDevicesStep
