const FakeIrrigationScreenShot = () => (
  <svg
    width='493'
    height='320'
    viewBox='0 0 493 320'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='492.709' height='320' rx='16' fill='white' />
    <rect x='13' y='13' width='466.588' height='19.5918' rx='9.79592' fill='#CCD4D7' />
    <rect x='39.1224' y='17.8979' width='52.2449' height='9.79592' rx='4.89796' fill='#018FA4' />
    <rect x='97.8979' y='17.8979' width='52.2449' height='9.79592' rx='4.89796' fill='white' />
    <rect x='156.673' y='17.8979' width='52.2449' height='9.79592' rx='4.89796' fill='white' />
    <rect x='215.449' y='17.8979' width='52.2449' height='9.79592' rx='4.89796' fill='white' />
    <rect x='19.5306' y='17.8979' width='9.79592' height='9.79592' rx='4.89796' fill='#018FA4' />
    <rect x='459.531' y='17.8979' width='9.79592' height='9.79592' rx='4.89796' fill='#CCD4D7' />
    <rect x='439.939' y='17.8979' width='9.79592' height='9.79592' rx='4.89796' fill='#CCD4D7' />
    <path
      d='M0 45.7142H111.347C120.183 45.7142 127.347 52.8777 127.347 61.7142V320H16C7.16345 320 0 312.836 0 304V45.7142Z'
      fill='#CCD4D7'
    />
    <rect x='11.9287' y='75.4387' width='100.224' height='51.2449' rx='7.5' fill='white' />
    <rect x='24.4899' y='88' width='75.102' height='6.53061' rx='3.26531' fill='#CCD4D7' />
    <rect x='24.4899' y='101.061' width='20.6803' height='13.0612' rx='4' fill='#CCD4D7' />
    <rect x='51.7008' y='101.061' width='20.6803' height='13.0612' rx='4' fill='#CCD4D7' />
    <rect x='78.9117' y='101.061' width='20.6803' height='13.0612' rx='4' fill='#CCD4D7' />
    <rect x='11.9287' y='75.4387' width='100.224' height='51.2449' rx='7.5' stroke='#CCD4D7' />
    <rect x='11.9287' y='135.684' width='100.224' height='51.2449' rx='7.5' fill='white' />
    <rect x='24.4899' y='148.245' width='75.102' height='6.53061' rx='3.26531' fill='#CCD4D7' />
    <rect x='24.4899' y='161.306' width='20.6803' height='13.0612' rx='4' fill='#CCD4D7' />
    <rect x='51.7008' y='161.306' width='20.6803' height='13.0612' rx='4' fill='#CCD4D7' />
    <rect x='78.9117' y='161.306' width='20.6803' height='13.0612' rx='4' fill='#CCD4D7' />
    <rect x='11.9287' y='135.684' width='100.224' height='51.2449' rx='7.5' stroke='#CCD4D7' />
    <rect x='11.9287' y='195.928' width='100.224' height='51.2449' rx='7.5' fill='white' />
    <rect x='24.4899' y='208.49' width='75.102' height='6.53061' rx='3.26531' fill='#CCD4D7' />
    <rect x='24.4899' y='221.551' width='20.6803' height='13.0612' rx='4' fill='#CCD4D7' />
    <rect x='51.7008' y='221.551' width='20.6803' height='13.0612' rx='4' fill='#CCD4D7' />
    <rect x='78.9117' y='221.551' width='20.6803' height='13.0612' rx='4' fill='#CCD4D7' />
    <rect x='11.9287' y='195.928' width='100.224' height='51.2449' rx='7.5' stroke='#CCD4D7' />
    <rect x='11.4287' y='57.1428' width='101.224' height='9.79592' rx='2' fill='#4D6872' />
    <rect x='194.122' y='45.7142' width='52.2449' height='13.0612' rx='6.53061' fill='#018FA4' />
    <rect x='133.878' y='45.7142' width='52.2449' height='13.0612' rx='6.53061' fill='#CCD4D7' />
    <rect x='254.367' y='45.7142' width='52.2449' height='13.0612' rx='6.53061' fill='#CCD4D7' />
    <rect x='133.878' y='291.204' width='52.2449' height='20' rx='10' fill='#CCD4D7' />
    <rect x='266.459' y='291.204' width='20' height='20' rx='4' fill='#A6C157' />
    <rect x='294.051' y='291.204' width='20' height='20' rx='4' fill='#CCD4D7' />
    <rect x='321.643' y='291.204' width='20' height='20' rx='4' fill='#CCD4D7' />
    <rect x='349.235' y='291.204' width='103' height='20' rx='4' fill='#A6C157' />
    <rect x='460.235' y='230.204' width='20' height='51' rx='4' fill='#A6C157' />
    <rect x='460.235' y='291.204' width='20' height='20' rx='10' fill='#CCD4D7' />
    <rect x='226.805' y='133.632' width='30.72' height='49.152' rx='15.36' fill='#E6EAEB' />
    <rect x='229.365' y='137.216' width='25.6' height='25.6' rx='12.8' fill='#FFC44C' />
    <rect x='335.35' y='128' width='25.6' height='25.6' rx='12.8' fill='#FF4A4A' />
    <rect x='295.925' y='166.4' width='25.6' height='25.6' rx='12.8' fill='#80CE9E' />
  </svg>
)

export default FakeIrrigationScreenShot
