import { SpacingToken } from '@weenat/theme'
import { BoxProps, createSpacingAttribute } from 'app/src/kit/primitives/themeMappings/props'
import { CSSProperties, styled } from 'styled-components'

type GridGapProps = {
  $gap?: SpacingToken | CSSProperties['gap'] | undefined
}

type RowGapProps = {
  $rowGap?: SpacingToken | CSSProperties['rowGap'] | undefined
}

type ColumnGapProps = {
  $columnGap?: SpacingToken | CSSProperties['columnGap'] | undefined
}

const gridGapCss = createSpacingAttribute<GridGapProps>('$gap', 'grid-gap')
const gridRowGapCss = createSpacingAttribute<RowGapProps>('$rowGap', 'row-gap')
const gridColumnGapCss = createSpacingAttribute<ColumnGapProps>('$columnGap', 'column-gap')

interface GridProps extends BoxProps, GridGapProps, RowGapProps, ColumnGapProps {
  $templateColumns?: string
  $templateRows?: string
  $autoRows?: string
}

const Grid = styled(Box)<GridProps>`
  display: grid;

  grid-template-columns: ${(p) => p.$templateColumns ?? 'initial'};
  grid-template-rows: ${(p) => p.$templateRows ?? 'initial'};
  grid-auto-rows: ${(p) => p.$autoRows ?? 'initial'};

  ${gridGapCss}
  ${gridRowGapCss}
  ${gridColumnGapCss}
`

export default Grid
