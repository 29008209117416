import { Redirect } from 'app/routx-router'

/**
 * Redirecting to administration/plots/create when on administration/plots
 * because it's his only subpath
 */
const Index = () => {
  return <Redirect to={`create`} replace />
}
export const Component = Index
export default Index
