import { useClient, useMutation } from '@weenat/client'
import { useIntl } from '@weenat/wintl'
import useToasts from 'app/src/hooks/useToasts'
import Button from 'app/src/kit/Button'
import SmartText from 'app/src/kit/SmartText'
import { useCallback } from 'react'
import { styled } from 'styled-components'

const Container = styled(Box)`
  text-align: center;
`

const AcceptPrivacyPolicy: FC = () => {
  const { t } = useIntl()
  const client = useClient()
  const { addSuccessToast } = useToasts()

  const [acceptPrivacyPolicy, acceptPrivacyPolicyState] = useMutation(
    client.me.acceptPrivacyPolicy(),
    {
      keysToInvalidate: [client.me.getMyProfile().key],
      onSuccess: () => {
        addSuccessToast(t('help.policy_privacy_acceptance_success', { capitalize: true }))
      }
    }
  )

  const handleAcceptPrivacyPolicy = useCallback(() => {
    acceptPrivacyPolicy()
  }, [acceptPrivacyPolicy])

  return (
    <Container>
      <Flex
        height={'100%'}
        $p='lg'
        $gap='lg'
        $justifyContent={'center'}
        $flexDirection={'column'}
        $alignItems={'center'}
      >
        <SmartText
          tkey='help.privacy_policy_update_warning'
          vars={{
            privacy_policy_url: t('weenat.help.termsAndConditions.privacy_policy')
          }}
        />
        <Flex $justifyContent='center' $mt='lg'>
          <Button onPress={handleAcceptPrivacyPolicy} state={acceptPrivacyPolicyState}>
            {t('actions.accept')}
          </Button>
        </Flex>
      </Flex>
    </Container>
  )
}
export default AcceptPrivacyPolicy
