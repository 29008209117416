import { useIntl } from '@weenat/wintl'
import Text from 'app/src/kit/Text'
import EmailLink from './EmailLink'
import Icons from './Icons'
import PhoneLink from './PhoneLink'

const ICON_SIZE = 'md'

const SupportContactInfos: FC = () => {
  const { t } = useIntl()

  return (
    <Flex $flexDirection='column' $alignItems='center' $gap='md'>
      <EmailLink email={t('weenat.support.email')}>
        <Flex $justifyContent='center'>
          <Icons.Mail $size={ICON_SIZE} />
          <Box $ml='md'>
            <Text>{t('weenat.support.email')}</Text>
          </Box>
        </Flex>
      </EmailLink>
      <Flex $justifyContent='center'>
        <Icons.Phone $size={ICON_SIZE} />
        <Flex $ml='md'>
          <PhoneLink phoneNumber={t('weenat.support.phoneNumber1')}>
            <Text>{t('weenat.support.phoneNumber1')}</Text>
          </PhoneLink>
          <Text>&nbsp; / &nbsp;</Text>
          <PhoneLink phoneNumber={t('weenat.support.phoneNumber2')}>
            <Text>{t('weenat.support.phoneNumber2')}</Text>
          </PhoneLink>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SupportContactInfos
