import { useDeviceBrandName } from '@weenat/client/dist/core/devices'
import { Device } from '@weenat/client/dist/resources/devices'
import { useIntl } from '@weenat/wintl'
import BoldLabeledInfo from 'app/src/kit/BoldLabeledInfo'
import RestrictedDataBox from 'app/src/kit/RestrictedDataBox'
import Text from 'app/src/kit/Text'
import moment from 'moment-timezone'
import DeviceOriginBadge from './DeviceOriginBadge'

type Variant = 'complete' | 'short'
interface DeviceDescriptionProps {
  device: Device
  variant?: Variant
  /** Whether or not the device is Weenat Expert only */
  isRestricted?: boolean
}

const DeviceDescription: FC<DeviceDescriptionProps> = ({
  device,
  variant = 'complete',
  isRestricted = false
}) => {
  const { t } = useIntl()

  const [model, serial] = device.endUserName
  const brandName = useDeviceBrandName(device)

  return (
    <Box $width='100%'>
      {variant === 'complete' ? (
        <>
          <BoldLabeledInfo label={t('models.device.fields.model.label')}>{model}</BoldLabeledInfo>
          <BoldLabeledInfo label={t('models.device.fields.serial.label')}>{serial}</BoldLabeledInfo>
          <BoldLabeledInfo label={t('models.device.fields.brand.label')}>
            {brandName}
          </BoldLabeledInfo>
          <BoldLabeledInfo label={t('models.device.fields.origin.label')}>
            <DeviceOriginBadge device={device} />
          </BoldLabeledInfo>
          <BoldLabeledInfo label={t('models.device.fields.createdAt.label')}>
            {moment.unix(+device.createdAt).format(t('formats.date'))}
          </BoldLabeledInfo>
        </>
      ) : (
        <>
          <Flex $alignItems='center' $mb='md'>
            <DeviceOriginBadge device={device} />
          </Flex>
          <Flex $flexWrap='wrap' $alignItems='center'>
            <Box $mr='md'>
              <Text $fontWeight='bold'>{model}</Text>
            </Box>
            <Text $fontSize='sm' $ellipsis>
              {serial}
            </Text>
          </Flex>
          {isRestricted ? <RestrictedDataBox /> : null}
        </>
      )}
    </Box>
  )
}

export default DeviceDescription
