import styled from 'styled-components'

/**
 * Overlay used to render components above a map
 */
const MapOverlay = styled(Flex)`
  position: relative;
  /* To be above the map */
  z-index: 1;

  flex: 1;

  /* To not catch the map mouse events */
  pointer-events: none;
`

export default MapOverlay
