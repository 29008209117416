function SvgTractor(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 39 36' xmlns='http://www.w3.org/2000/svg' fill='currentColor' {...props}>
      <path
        d='M25.18 26.605h-6.432l.29-1.059c.172-.625.26-1.271.26-1.921a7.158 7.158 0 00-2.006-4.978 6.75 6.75 0 00-4.835-2.065 6.617 6.617 0 00-2.798.616l-1.142.527V6.899c.001-.769.298-1.506.826-2.049a2.778 2.778 0 011.99-.85h7.256a2.774 2.774 0 011.492.444c.448.288.808.7 1.041 1.188l3.454 7.317h4.464a3.132 3.132 0 012.243.958 3.32 3.32 0 01.931 2.31v8.773l-1.289-.981a3.486 3.486 0 00-2.127-.72 3.525 3.525 0 00-2.168.755 3.704 3.704 0 00-1.286 1.95l-.164.61zm-4.363-1.663h3.172a5.29 5.29 0 012.72-2.865 5.069 5.069 0 013.888-.124v-5.737c0-.425-.164-.833-.456-1.133-.292-.3-.688-.47-1.1-.47h-5.478L19.668 6.36a1.23 1.23 0 00-.444-.505 1.181 1.181 0 00-.635-.19h-7.253c-.318 0-.623.13-.848.361-.224.231-.351.544-.352.871v8.352a8.202 8.202 0 012.32-.333 8.347 8.347 0 015.98 2.554 8.85 8.85 0 012.479 6.156c0 .441-.033.881-.098 1.317z'
        fill='currentColor'
      />
      <path
        d='M28.029 13.613h-1.243V9.082c0-.373.07-.741.208-1.085.138-.344.341-.657.597-.92.255-.263.559-.472.893-.614a2.68 2.68 0 011.053-.214.606.606 0 01.464.173.64.64 0 01.196.466.656.656 0 01-.196.467.62.62 0 01-.463.173 1.333 1.333 0 00-.603.073c-.193.07-.368.183-.513.332a1.449 1.449 0 00-.393 1.148v4.532zM12.457 32.332a8.346 8.346 0 01-5.977-2.553A8.85 8.85 0 014 23.625a8.849 8.849 0 012.48-6.154 8.346 8.346 0 015.977-2.553 8.346 8.346 0 015.978 2.553 8.85 8.85 0 012.48 6.154 8.85 8.85 0 01-2.48 6.154 8.346 8.346 0 01-5.978 2.553zm0-15.75a6.75 6.75 0 00-4.834 2.065 7.158 7.158 0 00-2.006 4.978 7.158 7.158 0 002.005 4.978 6.75 6.75 0 004.835 2.066 6.75 6.75 0 004.836-2.066 7.158 7.158 0 002.005-4.979 7.158 7.158 0 00-2.006-4.977 6.75 6.75 0 00-4.835-2.065z'
        fill='currentColor'
      />
      <path
        d='M12.457 27.338a3.42 3.42 0 01-2.45-1.046 3.627 3.627 0 01-1.016-2.523c0-.946.366-1.853 1.016-2.523a3.421 3.421 0 012.45-1.046c.92 0 1.8.377 2.45 1.047a3.628 3.628 0 011.017 2.523 3.628 3.628 0 01-1.017 2.522 3.421 3.421 0 01-2.45 1.046zm0-5.474c-.49.001-.96.202-1.306.559a1.936 1.936 0 000 2.69c.347.357.816.558 1.306.558.49 0 .96-.201 1.307-.558a1.935 1.935 0 000-2.69 1.826 1.826 0 00-1.307-.559zM28.798 32.332a5.131 5.131 0 01-3.674-1.571 5.441 5.441 0 01-1.524-3.783c.002-1.42.55-2.78 1.525-3.784a5.131 5.131 0 013.675-1.57c1.379.002 2.7.566 3.675 1.57A5.44 5.44 0 0134 26.978a5.44 5.44 0 01-1.525 3.784 5.131 5.131 0 01-3.677 1.57zm0-9.044c-.95.002-1.861.39-2.533 1.082a3.75 3.75 0 00-1.05 2.608 3.75 3.75 0 001.05 2.607 3.538 3.538 0 002.533 1.082c.95-.001 1.86-.39 2.533-1.082a3.752 3.752 0 001.051-2.607 3.751 3.751 0 00-1.051-2.608 3.537 3.537 0 00-2.533-1.082zM20.367 14.421a.61.61 0 01-.345-.106.636.636 0 01-.23-.286l-2.135-5.28a1.242 1.242 0 00-.448-.562c-.2-.137-.435-.21-.676-.209h-1.729a.607.607 0 01-.44-.188.642.642 0 01-.182-.452.658.658 0 01.183-.453.622.622 0 01.44-.187h1.729c.486-.001.961.147 1.365.424.405.278.72.673.906 1.135l2.136 5.278a.659.659 0 01-.057.6.628.628 0 01-.224.21.607.607 0 01-.293.076z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgTractor
