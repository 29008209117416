import {
  legendGradients,
  radarHailItems,
  RadarLegendItem,
  radarRainfallsItems
} from '@weenat/client/dist/core/radar'
import { useIntl } from '@weenat/wintl'
import Text from 'app/src/kit/Text'
import { styled } from 'styled-components'

interface RadarLegendColorsProps {
  colors: string[]
}

const RadarLegendColors = styled.div<RadarLegendColorsProps>`
  background: linear-gradient(to bottom, ${(p) => p.colors.join(',')});
  height: 300px;
  width: 50px;
  border-radius: ${(p) => p.theme.radiuses.rounded}px;
  box-shadow: ${(p) => p.theme.shadows.md.boxShadow};
`

const RadarLegendColorsWithLabels: FC<
  RadarLegendColorsProps & { labels: string[]; title: string }
> = ({ colors, title, labels }) => (
  <Flex $flexDirection='column' $alignItems='center'>
    <Flex>
      <Box $mr='sm'>
        <RadarLegendColors colors={colors} />
      </Box>
      <Flex $flexDirection='column' $justifyContent='space-between'>
        {labels.map((label) => (
          <Text $fontSize='xs' key={label}>
            {label}
          </Text>
        ))}
      </Flex>
    </Flex>
    <Box $mt='sm'>
      <Text>{title}</Text>
    </Box>
  </Flex>
)

const RadarLegend = () => {
  const { t } = useIntl()

  const formatRadarLegend = (legend: RadarLegendItem) => t(`radarLegends.${legend}`)

  const rainfallsLabels = radarRainfallsItems.map(formatRadarLegend)
  const hailLabels = radarHailItems.map(formatRadarLegend)

  return (
    <Flex $mt='lg' $justifyContent='space-around'>
      <RadarLegendColorsWithLabels
        colors={legendGradients.rainfalls}
        labels={rainfallsLabels}
        title={t('radarLegends.rainfalls_title')}
      />
      <RadarLegendColorsWithLabels
        colors={legendGradients.hail}
        labels={hailLabels}
        title={t('radarLegends.hail_title')}
      />
    </Flex>
  )
}

export default RadarLegend
