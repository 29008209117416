import { defaultFeature, Features, Storer } from '@weenat/client'
import { CurrentTour, LightTourKey } from '@weenat/client/dist/core/tours'
import { Universe, UniverseAgg, UniverseMetric } from '@weenat/client/dist/core/universes'
import { HistoryState } from '@weenat/client/dist/HistoryContext'
import { MyFarmItem } from '@weenat/client/dist/resources/myFarm'
import type { Locale } from '@weenat/wintl'
import isNil from 'lodash-es/isNil'
import { ReactNode } from 'react'
import { detectBrowserLanguage } from './utils'

export const str = Storer({
  engine: {
    // eslint-disable-next-line @typescript-eslint/require-await
    async getItem(key: string) {
      return localStorage.getItem(key)
    },
    // eslint-disable-next-line @typescript-eslint/require-await
    async removeItem(key: string) {
      return localStorage.removeItem(key)
    },
    // eslint-disable-next-line @typescript-eslint/require-await
    async setItem(key: string, value: string) {
      return localStorage.setItem(key, value)
    }
  },
  migration: {
    version: JSON.stringify(defaultFeature) + '#1.0.57',
    keep: ['token', 'lightTour', 'clusterSettings']
  }
})

/** Auth */

export const [useToken, getToken] = str.create<string | null>(null, { persistKey: 'token' })

export const [useLoginAs, getLoginAs] = str.create<{ email: string; id: number } | null>(null, {
  persistKey: 'loginAs'
})

/** User preferences */

export type SelectedOrgs = {
  organizationId: number | null
  childOrganizationId: number | null
}

export const [useSelectedOrgs, getSelectedOrgs] = str.create<SelectedOrgs>(
  {
    organizationId: null,
    childOrganizationId: null
  },
  { persistKey: 'selectedOrg' }
)

/**
 * Get current organization id outside react context
 */
export const getCurrentOrgId = () => {
  const selectedOrgs = getSelectedOrgs()
  return !isNil(selectedOrgs)
    ? isNil(selectedOrgs.childOrganizationId)
      ? selectedOrgs.organizationId
      : selectedOrgs.childOrganizationId
    : null
}

/**
 * Current orgId from the application state not the url
 */
export const useCurrentOrganizationId = () => {
  const [selectedOrgs] = useSelectedOrgs()
  return isNil(selectedOrgs.childOrganizationId)
    ? selectedOrgs.organizationId
    : selectedOrgs.childOrganizationId
}

export const [useUserLanguage, getUserLanguage] = str.create<Locale>(detectBrowserLanguage())

export const [useOptionActivationTarget] = str.create<MyFarmItem | undefined>(undefined)

/** Feature Flags */

export const [useToggleFeature] = str.create<Features>(defaultFeature, { persistKey: 'features' })

export const flushAllButKeepConnected = () =>
  str.flush({ except: ['token', 'loginAs', 'features', 'lightTour'] })

/** History */

export const [useNewHistoryState] = str.create<Partial<HistoryState> | undefined>(undefined)

/** Tours */

export const [useCurrentTour] = str.create<CurrentTour>(undefined)
export const [useLightTours] = str.create<LightTourKey[]>([], { persistKey: 'lightTour' })

/** Universes */

export const [useUniverse] = str.create<Universe>(Universe.weather, { persistKey: 'universe' })
export const [useActiveMetricByUniverse] = str.create<Partial<
  Record<Universe, UniverseMetric>
> | null>(null, {
  persistKey: 'universeMetricByUniv'
})
export const useCurrentUnivMetric = () => {
  const [universe] = useUniverse()
  const [metricByUniverse] = useActiveMetricByUniverse()
  return metricByUniverse?.[universe]
}
export const [useUniverseAggregate] = str.create<UniverseAgg | null>(null, {
  persistKey: 'universeAgg'
})

export const [useUniverseHorizon] = str.create</** Horizon id */ number | null>(null)
export const [useAcquittedExternalErrors] = str.create<string[]>([], {
  persistKey: 'acquittedExternalErrors'
})

/** Breadcrumbs */

export const [useBreadcrumbs] = str.create<{
  enabled: boolean
  breadcrumbs: { label: string; path: string }[]
}>({ enabled: true, breadcrumbs: [] })

export const [useBreadcrumbsCtx] = str.create<Record<string, string>>({})

/** Clusters */

interface ClusterSettings {
  maxZoom: number
  minPointsPerCluster: number
}

export const [useClusterSettings] = str.create<ClusterSettings | undefined>(undefined, {
  persistKey: 'clusterSettings'
})

/** Other */
export const [useSlotProvider] = str.create<
  Record<'headerActions' | 'mapTopControls', ReactNode[] | undefined>
>({ headerActions: undefined, mapTopControls: undefined })

/** PAYMENT */
interface PaymentCtx {
  from?: 'subscription' | 'onboarding' | 'invoicePayment'
  selectedOrgs?: SelectedOrgs
}

export const [usePaymentCtx] = str.create<PaymentCtx>({}, { persistKey: 'paymentContext' })
