import { UniverseAgg } from '@weenat/client/dist/core/universes'
import { GoogleMapApi } from 'app/src/map/utils'
import isNil from 'lodash-es/isNil'

const MAP_PADDING = 96

interface Args {
  googleApi: GoogleMapApi
  bounds:
    | google.maps.LatLngBounds
    | {
        sw: google.maps.LatLng
        ne: google.maps.LatLng
      }
  correctedPadding?: number
}

const fitBoundsWithPadding = ({ googleApi: { map, maps }, bounds, correctedPadding }: Args) => {
  if (!isNil(map) && !isNil(maps)) {
    const newBounds =
      'sw' in bounds ? new maps.LatLngBounds(bounds.sw, bounds.ne) : new maps.LatLngBounds(bounds)

    if (!isNil(newBounds)) map.fitBounds(newBounds, MAP_PADDING)
    if (!isNil(correctedPadding)) map.panBy(-correctedPadding / 2, 0)
  }
}

export default fitBoundsWithPadding

export function isFreemiumAccessibleAggregate(agg: UniverseAgg | null) {
  return agg === 'cumul-measured-today' || agg?.startsWith('real-time')
}
