import { canUser, ops } from '@weenat/client/dist/core/access'
import paths from '@weenat/client/dist/core/appPaths'
import { Me } from '@weenat/client/dist/resources/me'
import { useIntl } from '@weenat/wintl'
import { useNavigate } from 'app/routx-router'
import { useLogout } from 'app/src/authentication/components/Logout'
import useDisclosure from 'app/src/hooks/useDisclosure'
import CountIndicator from 'app/src/kit/CountIndicator'
import DelimitedFlex from 'app/src/kit/DelimitedFlex'
import Text from 'app/src/kit/Text'
import { DropdownContent, useDropdownContext } from 'app/src/kit/fields/Select/SelectDropdown'
import { FocusLayoutMenuSection } from 'app/src/layouts/FocusLayout'
import isNil from 'lodash-es/isNil'
import { useMemo } from 'react'
import { styled, useTheme } from 'styled-components'
import Icons from '../kit/Icons'
import MenuItem from '../kit/MenuItem'
import TextTooltip from '../kit/tooltips/TextTooltip'
import FeatureToggleModal from './FeatureToggleModal'
import ProfileCard from './ProfileCard'

const Footer = styled(DelimitedFlex)`
  &:hover {
    background-color: ${(p) => p.theme.colors.feedback.error[200]};
    cursor: pointer;
  }

  transition: background-color 0.3s linear;
`

const LogOutFooter = ({ closeDropDown }: { closeDropDown: () => void }) => {
  const { t } = useIntl()

  const handleLogout = useLogout()

  return (
    <Footer
      $alignItems='center'
      $justifyContent='center'
      $isDelimitedOnTop
      $gap='md'
      $p='lg'
      onClick={async () => {
        await handleLogout()
        closeDropDown()
      }}
    >
      <Icons.Logout $size='md' $color={'feedback.error.500'} />
      <Text $fontWeight='semiBold' $color={'feedback.error.500'}>
        {t('models.account.actions.logout')}
      </Text>
    </Footer>
  )
}

const Header = styled(Box)`
  background-color: ${(p) => p.theme.colors.grayscale.white};
  border-bottom: 1px solid ${(p) => p.theme.colors.grayscale[300]};
`

const ProfileHead = styled(ProfileCard)`
  border: none;
  border-radius: ${(p) => p.theme.radiuses.none}px;
  box-shadow: none;
`

const DropdownLinkContainer = styled(MenuItem)`
  display: flex;
  align-items: center;

  gap: ${(p) => p.theme.spacings.md}px;
  padding: ${(p) => p.theme.spacings.md}px;
  border-radius: ${(p) => p.theme.radiuses.md}px;

  p {
    font-size: ${(p) => p.theme.typography.sizes.rg}px;
  }
`

const TOOLTIPS_STYLE = { maxWidth: 196 }

interface MyAccountDropdownProps {
  me: Me
  pendingInvitationCount: number
}

const MyAccountDropdown: FC<MyAccountDropdownProps> = ({ me, pendingInvitationCount }) => {
  const { t } = useIntl()
  const nav = useNavigate()
  const { colors } = useTheme()

  const { isOpen: areFeaturesOpen, close: closeFeatures, open: openFeatures } = useDisclosure()
  const { onVisibleChange } = useDropdownContext()
  const close = () => onVisibleChange(false)

  const canViewAdminTools = canUser(ops.view, 'debugMode', { account: me })

  const sections: FocusLayoutMenuSection[] = useMemo(() => {
    const tempSections: FocusLayoutMenuSection[] = [
      {
        id: 'preferences-section',
        title: t('titles.preferences'),
        items: [
          {
            id: 'preferences',
            LeftIcon: Icons.DiscoverTune,
            to: '/me/preferences',
            label: t('me_menu.app_preferences_title'),
            description: t('me_menu.preferences_subtitle'),
            matchExactPath: true
          },
          {
            id: 'notifications_preferences',
            LeftIcon: Icons.Notifications,
            to: '/me/preferences/notifications',
            label: t('breadcrumbs./me/preferences/notifications'),
            description: t('models.notificationPreference.description'),
            matchExactPath: true
          }
        ]
      },
      {
        id: 'my-account-section',
        title: t('me_menu.my_account_group'),
        items: [
          {
            id: 'account_settings',
            LeftIcon: Icons.AccountSettings,
            to: '/me/account',
            description: t('me_menu.security_privacy_subtitle'),
            label: t('me_menu.security_privacy_title'),
            matchExactPath: true
          },
          {
            id: 'authorized_devices',
            LeftIcon: Icons.AuthorizedDevice,
            to: '/me/mobile-devices',
            description: t('me_menu.devices_subtitle'),
            label: t('titles.mobile_devices'),
            matchExactPath: true
          },
          {
            id: 'external_connections',
            to: paths.me.externalConnections,
            description: t('titles.external_connection_baseline'),
            label: t('titles.external_connection'),
            LeftIcon: Icons.ExternalConnexions,
            matchExactPath: false
          }
        ]
      },
      {
        id: 'contact-section',
        title: t('me_menu.contact_group'),
        items: [
          {
            id: 'contact_information',
            LeftIcon: Icons.Phone,
            label: t('breadcrumbs./me/contact-information'),
            to: '/me/contact-information',
            description: t('me_menu.contact_infos_subtitle'),
            matchExactPath: true
          },
          {
            id: 'invitations',
            LeftIcon: Icons.Inbox,
            label: t('breadcrumbs./me/invitations'),
            to: '/me/invitations',
            indicator: pendingInvitationCount,
            matchExactPath: false
          }
        ]
      }
    ]

    if (canViewAdminTools) {
      tempSections.push({
        id: 'admin-tools-section',
        title: t('me_menu.admin_tool_group'),
        items: [
          {
            id: 'debug',
            to: paths.me.debug,
            label: t('titles.debug'),
            LeftIcon: Icons.Bug,
            matchExactPath: true
          },
          {
            id: 'feature_toggle',
            to: paths.me.featureToggle,
            label: t('titles.toggle_feature'),
            LeftIcon: Icons.New,
            matchExactPath: true
          },
          {
            id: 'cluster_settings',
            to: paths.me.clusterSettings,
            label: t('titles.cluster_settings'),
            LeftIcon: Icons.Cluster,
            matchExactPath: true
          }
        ]
      })
    }

    return tempSections
  }, [canViewAdminTools, pendingInvitationCount, t])

  return (
    <>
      <DropdownContent
        width={320}
        Header={
          <TextTooltip
            placement='left-start'
            style={TOOLTIPS_STYLE}
            content={t('user_settings.page_descriptions.profile')}
          >
            <Header>
              <ProfileHead me={me} hideFooter onNav={close} />
            </Header>
          </TextTooltip>
        }
        Footer={<LogOutFooter closeDropDown={close} />}
      >
        <Flex $flexDirection='column' $gap='md' $p='md'>
          {sections.map((section, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Flex $flexDirection='column' $gap='md' key={`${section.title}-${i}`}>
              {!isNil(section.title) ? (
                <Text $fontSize='sm' $fontWeight='bold'>
                  {section.title}
                </Text>
              ) : null}
              <Flex $flexDirection='column'>
                {section.items.map((item) => {
                  const Icon = item.LeftIcon
                  return (
                    <TextTooltip
                      key={item.id}
                      placement='left-start'
                      content={!isNil(item.description) ? item.description : ''}
                      disabled={isNil(item.description)}
                      style={TOOLTIPS_STYLE}
                    >
                      <DropdownLinkContainer
                        onPress={() => {
                          if (item.id === 'feature_toggle') {
                            openFeatures()
                          } else if (!isNil(item.to)) {
                            nav(item.to)
                          }

                          close()
                        }}
                      >
                        <Icon $size='md' />
                        <Box $flex={1} style={{ minWidth: 0 }}>
                          <Text $ellipsis style={{ verticalAlign: 'middle' }}>
                            {item.label}
                          </Text>
                        </Box>
                        {!isNil(item.indicator) ? (
                          <CountIndicator
                            backgroundColor='primary.500'
                            color={colors.grayscale.white}
                            count={item.indicator}
                          />
                        ) : null}
                      </DropdownLinkContainer>
                    </TextTooltip>
                  )
                })}
              </Flex>
            </Flex>
          ))}
        </Flex>
      </DropdownContent>
      <FeatureToggleModal isOpen={areFeaturesOpen} close={closeFeatures} />
    </>
  )
}

export default MyAccountDropdown
