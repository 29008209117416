import { schemas, useClient, useMutation } from '@weenat/client'
import { useIntl } from '@weenat/wintl'
import useToasts from 'app/src/hooks/useToasts'
import SubmitButton from 'app/src/kit/SubmitButton'
import SuperForm from 'app/src/kit/SuperForm'
import PasswordField from 'app/src/kit/fields/PasswordField'
import UseOnChange from 'app/src/kit/fields/UseOnChange'
import AccountSection from 'app/src/me/AccountSection'
import { useCallback } from 'react'

const ChangePassword: React.FC = () => {
  const { t } = useIntl()
  const client = useClient()
  const { addToast } = useToasts()

  const [changePassword, changePasswordState] = useMutation(client.auth.changePassword(), {
    onSuccess: () => addToast(t('models.account.actions.edit_success'), { appearance: 'success' })
  })
  const sharedProps = {
    isDisabled: changePasswordState.isPending,
    errorState: changePasswordState.error
  }

  const onChange = useCallback(() => {
    changePasswordState.reset()
  }, [changePasswordState])

  return (
    <AccountSection title={t('models.account.actions.edit_password')}>
      <SuperForm schema={schemas.auth.changePassword} onSubmit={(values) => changePassword(values)}>
        <PasswordField
          name='oldPassword'
          {...sharedProps}
          label={t('models.account.fields.old_password.label', { capitalize: true })}
        />
        <PasswordField
          name='newPassword'
          {...sharedProps}
          label={t('models.account.fields.new_password.label', { capitalize: true })}
        />
        <UseOnChange onChange={onChange} />
        <Flex $justifyContent='flex-end' $width='100%' $my='lg'>
          <SubmitButton state={changePasswordState} />
        </Flex>
      </SuperForm>
    </AccountSection>
  )
}

export default ChangePassword
