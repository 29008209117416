import {
  getAlreadyWatchedStepsFromNewScreen,
  ScreenQueryParam
} from '@weenat/client/dist/core/tours'
import { useNavigate, useParams, usePathname } from 'app/routx-router'
import { useCurrentTour } from 'app/state'
import isNil from 'lodash-es/isNil'
import { useEffect } from 'react'

export const useHandleScreenQueryParamChange = () => {
  const hookPn = usePathname()
  const nav = useNavigate()
  const [, setCurrentTour] = useCurrentTour()
  const { plotId, deviceId } = useParams()

  return (tourScreen: ScreenQueryParam, predicate?: () => boolean, pathname = hookPn) => {
    if (!predicate || predicate()) {
      const searchParams: Record<string, string> = { tourScreen: tourScreen }

      if (!isNil(plotId)) {
        searchParams.plotId = plotId.toString()
      }

      if (!isNil(deviceId)) {
        searchParams.deviceId = deviceId.toString()
      }

      nav(pathname, { replace: true, search: searchParams })

      // @ts-expect-error currentTour.name may not be defined
      setCurrentTour((currentTour) => {
        return {
          ...(currentTour || {}),
          stepIndex: 0,
          isWaitingNextScreen: false,
          ...(tourScreen === 'intro' || !currentTour?.name
            ? {}
            : { alreadyWatchedSteps: getAlreadyWatchedStepsFromNewScreen(currentTour, tourScreen) })
        }
      })
    }
  }
}

/** Sync tourScreen query param with a useEffect */
const useScreenQueryParam = (tourScreen: ScreenQueryParam, predicate?: () => boolean) => {
  const handle = useHandleScreenQueryParamChange()

  useEffect(() => {
    handle(tourScreen, predicate)
  }, [tourScreen])
}

export default useScreenQueryParam
