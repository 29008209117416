import { useIntl } from '@weenat/wintl'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import moment from 'moment-timezone'

interface AlertLastUpdateProps {
  timestamp: number
}

const AlertLastUpdate: FC<AlertLastUpdateProps> = ({ timestamp }) => {
  const { t } = useIntl()

  return !isNil(timestamp) ? (
    <Text $fontSize='sm' $color={`grayscale.700`}>{`${t('resourcesInformation.last_modified', {
      capitalize: true
    })} ${moment.unix(timestamp).format(t('formats.datetime'))}`}</Text>
  ) : null
}

export default AlertLastUpdate
