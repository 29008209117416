import { SVGProps } from 'react'

const SvgCluster = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.5 7.5a5.5 5.5 0 1 1 11 0v9a5.5 5.5 0 1 1-11 0v-9Zm5.5-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z'
      fill='currentColor'
    />
  </svg>
)

export default SvgCluster
