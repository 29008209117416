import { Aggregations } from '@weenat/client/dist/enums'
import { useIntl } from '@weenat/wintl'
import { RestrictionReason } from 'app/src/hooks/useIsAppAccessRestricted'
import { memo } from 'react'
import useUniverseBackgroundMapContext from '../universes/useUniverseBackgroundMapContext'
import PlotListItemValue, { PlotSummaryValue } from './PlotListItemValue'
import PlotListItemValuesContainer from './PlotListItemValuesContainer'

const metric = 'RR'

interface RainfallsPlotListItemValuesProps {
  rainfallsToday: PlotSummaryValue
  rainfallsYesterday: PlotSummaryValue
  rainfallsLastSevenDays: PlotSummaryValue
  isVirtualDevice: boolean
  isRestricted: boolean
  reason: RestrictionReason
}

/** PlotListItem values for rainfalls */
const RainfallsPlotListItemValues: FC<RainfallsPlotListItemValuesProps> = ({
  rainfallsToday,
  rainfallsYesterday,
  rainfallsLastSevenDays,
  isRestricted,
  isVirtualDevice,
  reason
}) => {
  const { t } = useIntl()
  const { aggregate } = useUniverseBackgroundMapContext()

  return (
    <PlotListItemValuesContainer $isRestricted={isRestricted}>
      <PlotListItemValue
        isMainValue
        value={rainfallsToday}
        name={t('periods.today')}
        metric={metric}
        selected={aggregate === Aggregations.today}
        isRestricted={isRestricted && !isVirtualDevice}
        reason={reason}
      />
      <PlotListItemValue
        value={rainfallsYesterday}
        name={t('periods.last_day')}
        metric={metric}
        selected={aggregate === Aggregations.yesterday}
        isRestricted={isRestricted}
        reason={reason}
      />
      <PlotListItemValue
        value={rainfallsLastSevenDays}
        name={t('periods.last_7_days_abbr')}
        metric={metric}
        selected={aggregate === Aggregations.week}
        isRestricted={isRestricted}
        reason={reason}
      />
    </PlotListItemValuesContainer>
  )
}

export default memo(RainfallsPlotListItemValues)
