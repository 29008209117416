import isNil from 'lodash-es/isNil'
import { useEffect } from 'react'
interface ExternalRedirectProps {
  href: string | undefined
  /** delay in ms */
  delay?: number
}

const ExternalRedirect: FC<ExternalRedirectProps> = ({ href, delay }) => {
  function openHref() {
    if (!isNil(href)) {
      // eslint-disable-next-line no-restricted-properties
      window.location.assign(href)
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      openHref()
    }, delay ?? 0)

    return () => {
      clearTimeout(timeout)
    }
  }, [href])

  return null
}

export default ExternalRedirect
