// Generated code. DO NOT EDIT WILL BE OVERRIDE.
// eslint-disable-next-line no-restricted-imports
import { RouteObject } from 'react-router-dom'
import Layout from './routes/_layout'
import AcceptPp from './routes/accept-pp'
import AcceptTos from './routes/accept-tos'
import Actura from './routes/actura'
import AdministrationOrgId from './routes/administration/[orgId]/index'
import Administration from './routes/administration/index'
import AdministrationOrganizations from './routes/administration/organizations/index'
import AdministrationPlots from './routes/administration/plots/index'
import AdministrationUpgradeToPremium from './routes/administration/upgrade-to-premium'
import AlertsLayout from './routes/alerts/_layout'
import AlertsAlertId from './routes/alerts/[alertId]'
import AlertsCreate from './routes/alerts/create'
import Alerts, { ErrorBoundary as AlertsErrorBoundary } from './routes/alerts/index'
import AuthLayout from './routes/auth/_layout'
import Auth from './routes/auth/index'
import AuthLogin from './routes/auth/login/index'
import AuthRegister from './routes/auth/register'
import AuthResetPassword from './routes/auth/reset-password/index'
import AuthSendVerificationEmail from './routes/auth/send-verification-email'
import AuthVerifyEmail from './routes/auth/verify-email/index'
import DiscoverLayout from './routes/discover/_layout'
import DiscoverSectionSlug from './routes/discover/[sectionSlug]/index'
import Discover, { ErrorBoundary as DiscoverErrorBoundary } from './routes/discover/index'
import Export from './routes/export'
import HelpLayout from './routes/help/_layout'
import Help from './routes/help/index'
import Root from './routes/index'
import MeLayout from './routes/me/_layout'
import MeAccount from './routes/me/account'
import MeClusterSettings from './routes/me/cluster-settings'
import MeContactInformation from './routes/me/contact-information'
import MeDebug from './routes/me/debug'
import MeExternalConnections from './routes/me/external-connections'
import MeFeatureToggle from './routes/me/feature-toggle'
import Me from './routes/me/index'
import MeInvitations from './routes/me/invitations/index'
import MeMobileDevices from './routes/me/mobile-devices'
import MePreferences from './routes/me/preferences/index'
import MeProfile from './routes/me/profile'
import NetworksLayout from './routes/networks/_layout'
import NetworksNetworkId from './routes/networks/[networkId]/index'
import Networks from './routes/networks/index'
import OnboardingLayout from './routes/onboarding/_layout'
import Onboarding from './routes/onboarding/index'
import OnboardingMembersTips from './routes/onboarding/members-tips'
import OnboardingMyFarm from './routes/onboarding/my-farm'
import OnboardingNetworks from './routes/onboarding/networks/index'
import OnboardingSensors from './routes/onboarding/sensors/index'
import OnboardingWelcome from './routes/onboarding/welcome'
import PaymentFailed from './routes/payment/failed'
import PaymentSuccess from './routes/payment/success'
import PlotsLayout from './routes/plots/_layout'
import PlotsPlotId from './routes/plots/[plotId]/index'
import PlotsCreate from './routes/plots/create'
import PlotsEdit from './routes/plots/edit'
import PushNotification from './routes/push-notification'
import Smag from './routes/smag'
import StationsLayout from './routes/stations/_layout'
import StationsStationId from './routes/stations/[stationId]/index'
import Stations from './routes/stations/index'
import UnsubscribeEmails from './routes/unsubscribe-emails'
import WeatherMapLayout from './routes/weather-map/_layout'
import WeatherMapDeviceId from './routes/weather-map/[deviceId]/index'
import Unmatched from './routes/[...unmatched]'

export const routesConfig: RouteObject[] = [
  {
    path: '/',

    element: <Layout />,
    children: [
      {
        path: 'accept-pp',

        element: <AcceptPp />
      },
      {
        path: 'accept-tos',

        element: <AcceptTos />
      },
      {
        path: 'actura',

        element: <Actura />
      },
      {
        path: 'administration',

        children: [
          {
            path: ':orgId',

            lazy: () => import('./routes/administration/[orgId]/_layout'),
            children: [
              {
                path: 'billing',

                lazy: () => import('./routes/administration/[orgId]/billing/_layout'),
                children: [
                  {
                    path: 'history',

                    lazy: () => import('./routes/administration/[orgId]/billing/history')
                  },
                  {
                    index: true,
                    lazy: () => import('./routes/administration/[orgId]/billing/index')
                  },
                  {
                    path: 'my-subscription-detail',

                    lazy: () =>
                      import('./routes/administration/[orgId]/billing/my-subscription-detail')
                  },
                  {
                    path: 'next',

                    lazy: () => import('./routes/administration/[orgId]/billing/next')
                  }
                ]
              },
              {
                path: 'devices',

                lazy: () => import('./routes/administration/[orgId]/devices/_layout'),
                children: [
                  {
                    path: ':deviceId',

                    lazy: () =>
                      import('./routes/administration/[orgId]/devices/[deviceId]/_layout'),
                    children: [
                      {
                        path: 'events',

                        lazy: () =>
                          import('./routes/administration/[orgId]/devices/[deviceId]/events')
                      },
                      {
                        index: true,
                        lazy: () =>
                          import('./routes/administration/[orgId]/devices/[deviceId]/index')
                      },
                      {
                        path: 'linked-plots',

                        lazy: () =>
                          import('./routes/administration/[orgId]/devices/[deviceId]/linked-plots')
                      }
                    ]
                  },
                  {
                    path: 'add',

                    lazy: () => import('./routes/administration/[orgId]/devices/add')
                  },
                  {
                    path: 'agro-weather',

                    lazy: () => import('./routes/administration/[orgId]/devices/agro-weather')
                  },
                  {
                    index: true,
                    lazy: () => import('./routes/administration/[orgId]/devices/index')
                  },
                  {
                    path: 'irrigation',

                    lazy: () => import('./routes/administration/[orgId]/devices/irrigation')
                  }
                ]
              },
              {
                path: 'edit',

                lazy: () => import('./routes/administration/[orgId]/edit/_layout'),
                children: [
                  {
                    path: 'headquarter',

                    lazy: () => import('./routes/administration/[orgId]/edit/headquarter')
                  },
                  {
                    index: true,
                    lazy: () => import('./routes/administration/[orgId]/edit/index')
                  }
                ]
              },
              {
                index: true,
                element: <AdministrationOrgId />
              },
              {
                path: 'info',

                lazy: () => import('./routes/administration/[orgId]/info')
              },
              {
                path: 'members',

                lazy: () => import('./routes/administration/[orgId]/members/_layout'),
                children: [
                  {
                    path: ':memberId',

                    lazy: () =>
                      import('./routes/administration/[orgId]/members/[memberId]/_layout'),
                    children: [
                      {
                        index: true,
                        lazy: () =>
                          import('./routes/administration/[orgId]/members/[memberId]/index')
                      }
                    ]
                  },
                  {
                    index: true,
                    lazy: () => import('./routes/administration/[orgId]/members/index')
                  },
                  {
                    path: 'invite',

                    lazy: () => import('./routes/administration/[orgId]/members/invite')
                  }
                ]
              },
              {
                path: 'members-invitations',

                lazy: () => import('./routes/administration/[orgId]/members-invitations/_layout'),
                children: [
                  {
                    path: ':invitationId',

                    lazy: () =>
                      import(
                        './routes/administration/[orgId]/members-invitations/[invitationId]/_layout'
                      ),
                    children: [
                      {
                        index: true,
                        lazy: () =>
                          import(
                            './routes/administration/[orgId]/members-invitations/[invitationId]/index'
                          )
                      }
                    ]
                  },
                  {
                    index: true,
                    lazy: () => import('./routes/administration/[orgId]/members-invitations/index')
                  }
                ]
              },
              {
                path: 'my-farm',

                lazy: () => import('./routes/administration/[orgId]/my-farm')
              },
              {
                path: 'network',

                lazy: () => import('./routes/administration/[orgId]/network/_layout'),
                children: [
                  {
                    index: true,
                    lazy: () => import('./routes/administration/[orgId]/network/index')
                  },
                  {
                    path: 'invitations',

                    lazy: () =>
                      import('./routes/administration/[orgId]/network/invitations/_layout'),
                    children: [
                      {
                        path: ':invitationId',

                        lazy: () =>
                          import(
                            './routes/administration/[orgId]/network/invitations/[invitationId]/_layout'
                          ),
                        children: [
                          {
                            index: true,
                            lazy: () =>
                              import(
                                './routes/administration/[orgId]/network/invitations/[invitationId]/index'
                              )
                          }
                        ]
                      },
                      {
                        index: true,
                        lazy: () =>
                          import('./routes/administration/[orgId]/network/invitations/index')
                      }
                    ]
                  },
                  {
                    path: 'invite',

                    lazy: () => import('./routes/administration/[orgId]/network/invite')
                  },
                  {
                    path: 'members',

                    lazy: () => import('./routes/administration/[orgId]/network/members/_layout'),
                    children: [
                      {
                        path: ':networkOrgId',

                        lazy: () =>
                          import(
                            './routes/administration/[orgId]/network/members/[networkOrgId]/_layout'
                          ),
                        children: [
                          {
                            index: true,
                            lazy: () =>
                              import(
                                './routes/administration/[orgId]/network/members/[networkOrgId]/index'
                              )
                          },
                          {
                            path: 'upgrade',

                            lazy: () =>
                              import(
                                './routes/administration/[orgId]/network/members/[networkOrgId]/upgrade'
                              )
                          }
                        ]
                      },
                      {
                        index: true,
                        lazy: () => import('./routes/administration/[orgId]/network/members/index')
                      }
                    ]
                  },
                  {
                    path: 'members-trials',

                    lazy: () =>
                      import('./routes/administration/[orgId]/network/members-trials/_layout'),
                    children: [
                      {
                        path: ':networkOrgId',

                        lazy: () =>
                          import(
                            './routes/administration/[orgId]/network/members-trials/[networkOrgId]/_layout'
                          ),
                        children: [
                          {
                            index: true,
                            lazy: () =>
                              import(
                                './routes/administration/[orgId]/network/members-trials/[networkOrgId]/index'
                              )
                          }
                        ]
                      },
                      {
                        index: true,
                        lazy: () =>
                          import('./routes/administration/[orgId]/network/members-trials/index')
                      }
                    ]
                  },
                  {
                    path: 'membership-requests',

                    lazy: () =>
                      import('./routes/administration/[orgId]/network/membership-requests/_layout'),
                    children: [
                      {
                        path: ':membershipRequestId',

                        lazy: () =>
                          import(
                            './routes/administration/[orgId]/network/membership-requests/[membershipRequestId]/_layout'
                          ),
                        children: [
                          {
                            index: true,
                            lazy: () =>
                              import(
                                './routes/administration/[orgId]/network/membership-requests/[membershipRequestId]/index'
                              )
                          }
                        ]
                      },
                      {
                        index: true,
                        lazy: () =>
                          import(
                            './routes/administration/[orgId]/network/membership-requests/index'
                          )
                      }
                    ]
                  },
                  {
                    path: 'option-requests',

                    lazy: () =>
                      import('./routes/administration/[orgId]/network/option-requests/_layout'),
                    children: [
                      {
                        path: ':invitationId',

                        lazy: () =>
                          import(
                            './routes/administration/[orgId]/network/option-requests/[invitationId]/_layout'
                          ),
                        children: [
                          {
                            index: true,
                            lazy: () =>
                              import(
                                './routes/administration/[orgId]/network/option-requests/[invitationId]/index'
                              )
                          }
                        ]
                      },
                      {
                        index: true,
                        lazy: () =>
                          import('./routes/administration/[orgId]/network/option-requests/index')
                      }
                    ]
                  }
                ]
              },
              {
                path: 'options',

                lazy: () => import('./routes/administration/[orgId]/options/_layout'),
                children: [
                  {
                    path: ':optionCode',

                    lazy: () =>
                      import('./routes/administration/[orgId]/options/[optionCode]/_layout'),
                    children: [
                      {
                        index: true,
                        lazy: () =>
                          import('./routes/administration/[orgId]/options/[optionCode]/index')
                      },
                      {
                        path: 'plots-activations',

                        lazy: () =>
                          import(
                            './routes/administration/[orgId]/options/[optionCode]/plots-activations'
                          )
                      },
                      {
                        path: 'settings',

                        lazy: () =>
                          import('./routes/administration/[orgId]/options/[optionCode]/settings')
                      },
                      {
                        path: 'stations-activations',

                        lazy: () =>
                          import(
                            './routes/administration/[orgId]/options/[optionCode]/stations-activations'
                          )
                      }
                    ]
                  },
                  {
                    index: true,
                    lazy: () => import('./routes/administration/[orgId]/options/index')
                  }
                ]
              },
              {
                path: 'org-plots',

                lazy: () => import('./routes/administration/[orgId]/org-plots'),
                children: [
                  {
                    path: ':plotId',

                    lazy: () => import('./routes/administration/[orgId]/org-plots/[plotId]')
                  }
                ]
              },
              {
                path: 'plots',

                lazy: () => import('./routes/administration/[orgId]/plots/_layout'),
                children: [
                  {
                    path: ':plotId',

                    lazy: () => import('./routes/administration/[orgId]/plots/[plotId]/_layout'),
                    children: [
                      {
                        path: 'devices',

                        lazy: () =>
                          import('./routes/administration/[orgId]/plots/[plotId]/devices/_layout'),
                        children: [
                          {
                            index: true,
                            lazy: () =>
                              import('./routes/administration/[orgId]/plots/[plotId]/devices/index')
                          }
                        ]
                      },
                      {
                        index: true,
                        lazy: () => import('./routes/administration/[orgId]/plots/[plotId]/index')
                      },
                      {
                        path: 'option-activations',

                        lazy: () =>
                          import(
                            './routes/administration/[orgId]/plots/[plotId]/option-activations'
                          )
                      }
                    ]
                  },
                  {
                    index: true,
                    lazy: () => import('./routes/administration/[orgId]/plots/index')
                  }
                ]
              },
              {
                path: 'stations',

                lazy: () => import('./routes/administration/[orgId]/stations/_layout'),
                children: [
                  {
                    path: ':stationId',

                    lazy: () =>
                      import('./routes/administration/[orgId]/stations/[stationId]/_layout'),
                    children: [
                      {
                        index: true,
                        lazy: () =>
                          import('./routes/administration/[orgId]/stations/[stationId]/index')
                      },
                      {
                        path: 'option-activations',

                        lazy: () =>
                          import(
                            './routes/administration/[orgId]/stations/[stationId]/option-activations'
                          )
                      }
                    ]
                  },
                  {
                    index: true,
                    lazy: () => import('./routes/administration/[orgId]/stations/index')
                  }
                ]
              }
            ]
          },
          {
            index: true,
            element: <Administration />
          },
          {
            path: 'organizations',

            lazy: () => import('./routes/administration/organizations/_layout'),
            children: [
              {
                index: true,
                element: <AdministrationOrganizations />
              },
              {
                path: '*',

                lazy: () => import('./routes/administration/organizations/[...unmatched]')
              }
            ]
          },
          {
            path: 'plots',

            children: [
              {
                index: true,
                element: <AdministrationPlots />
              }
            ]
          },
          {
            path: 'upgrade-to-premium',

            element: <AdministrationUpgradeToPremium />
          }
        ]
      },
      {
        path: 'alerts',

        element: <AlertsLayout />,
        children: [
          {
            path: ':alertId',

            element: <AlertsAlertId />
          },
          {
            path: 'create',

            element: <AlertsCreate />
          },
          {
            errorElement: <AlertsErrorBoundary />,
            index: true,
            element: <Alerts />
          }
        ]
      },
      {
        path: 'auth',

        element: <AuthLayout />,
        children: [
          {
            index: true,
            element: <Auth />
          },
          {
            path: 'login',

            lazy: () => import('./routes/auth/login/_layout'),
            children: [
              {
                index: true,
                element: <AuthLogin />
              },
              {
                path: 'magic',

                lazy: () => import('./routes/auth/login/magic/_layout'),
                children: [
                  {
                    path: 'confirm',

                    lazy: () => import('./routes/auth/login/magic/confirm'),
                    children: [
                      {
                        path: ':loginMagicToken',

                        lazy: () => import('./routes/auth/login/magic/confirm/[loginMagicToken]')
                      }
                    ]
                  },
                  {
                    index: true,
                    lazy: () => import('./routes/auth/login/magic/index')
                  }
                ]
              }
            ]
          },
          {
            path: 'register',

            element: <AuthRegister />
          },
          {
            path: 'reset-password',

            children: [
              {
                path: ':resetPasswordUid',

                lazy: () => import('./routes/auth/reset-password/[resetPasswordUid]'),
                children: [
                  {
                    path: ':resetPasswordToken',

                    lazy: () =>
                      import('./routes/auth/reset-password/[resetPasswordUid]/[resetPasswordToken]')
                  }
                ]
              },
              {
                index: true,
                element: <AuthResetPassword />
              }
            ]
          },
          {
            path: 'send-verification-email',

            element: <AuthSendVerificationEmail />
          },
          {
            path: 'verify-email',

            children: [
              {
                path: ':verifyEmailKey',

                lazy: () => import('./routes/auth/verify-email/[verifyEmailKey]')
              },
              {
                index: true,
                element: <AuthVerifyEmail />
              }
            ]
          }
        ]
      },
      {
        path: 'discover',

        element: <DiscoverLayout />,
        children: [
          {
            path: ':sectionSlug',

            children: [
              {
                path: ':articleSlug',

                lazy: () => import('./routes/discover/[sectionSlug]/[articleSlug]')
              },
              {
                index: true,
                element: <DiscoverSectionSlug />
              }
            ]
          },
          {
            errorElement: <DiscoverErrorBoundary />,
            index: true,
            element: <Discover />
          }
        ]
      },
      {
        path: 'export',

        element: <Export />
      },
      {
        path: 'help',

        element: <HelpLayout />,
        children: [
          {
            index: true,
            element: <Help />
          }
        ]
      },
      {
        index: true,
        element: <Root />
      },
      {
        path: 'me',

        element: <MeLayout />,
        children: [
          {
            path: 'account',

            element: <MeAccount />
          },
          {
            path: 'cluster-settings',

            element: <MeClusterSettings />
          },
          {
            path: 'contact-information',

            element: <MeContactInformation />
          },
          {
            path: 'debug',

            element: <MeDebug />
          },
          {
            path: 'external-connections',

            element: <MeExternalConnections />
          },
          {
            path: 'feature-toggle',

            element: <MeFeatureToggle />
          },
          {
            index: true,
            element: <Me />
          },
          {
            path: 'invitations',

            lazy: () => import('./routes/me/invitations/_layout'),
            children: [
              {
                path: 'accept',

                lazy: () => import('./routes/me/invitations/accept/_layout'),
                children: [
                  {
                    path: ':invitationBase64',

                    lazy: () => import('./routes/me/invitations/accept/[invitationBase64]')
                  },
                  {
                    index: true,
                    lazy: () => import('./routes/me/invitations/accept/index')
                  }
                ]
              },
              {
                index: true,
                element: <MeInvitations />
              }
            ]
          },
          {
            path: 'mobile-devices',

            element: <MeMobileDevices />
          },
          {
            path: 'preferences',

            children: [
              {
                index: true,
                element: <MePreferences />
              },
              {
                path: 'notifications',

                lazy: () => import('./routes/me/preferences/notifications')
              }
            ]
          },
          {
            path: 'profile',

            element: <MeProfile />
          }
        ]
      },
      {
        path: 'networks',

        element: <NetworksLayout />,
        children: [
          {
            path: ':networkId',

            children: [
              {
                index: true,
                element: <NetworksNetworkId />
              }
            ]
          },
          {
            index: true,
            element: <Networks />
          }
        ]
      },
      {
        path: 'onboarding',

        element: <OnboardingLayout />,
        children: [
          {
            index: true,
            element: <Onboarding />
          },
          {
            path: 'members-tips',

            element: <OnboardingMembersTips />
          },
          {
            path: 'my-farm',

            element: <OnboardingMyFarm />
          },
          {
            path: 'networks',

            children: [
              {
                path: ':networkId',

                lazy: () => import('./routes/onboarding/networks/[networkId]')
              },
              {
                path: 'find',

                lazy: () => import('./routes/onboarding/networks/find')
              },
              {
                index: true,
                element: <OnboardingNetworks />
              }
            ]
          },
          {
            path: 'sensors',

            children: [
              {
                path: 'add',

                lazy: () => import('./routes/onboarding/sensors/add')
              },
              {
                index: true,
                element: <OnboardingSensors />
              },
              {
                path: 'success',

                lazy: () => import('./routes/onboarding/sensors/success')
              }
            ]
          },
          {
            path: 'welcome',

            element: <OnboardingWelcome />
          }
        ]
      },
      {
        path: 'payment',

        children: [
          {
            path: 'failed',

            element: <PaymentFailed />
          },
          {
            path: 'success',

            element: <PaymentSuccess />
          }
        ]
      },
      {
        path: 'plots',

        element: <PlotsLayout />,
        children: [
          {
            path: ':plotId',

            lazy: () => import('./routes/plots/[plotId]/_layout'),
            children: [
              {
                path: 'alerts',

                lazy: () => import('./routes/plots/[plotId]/alerts')
              },
              {
                path: 'forecasts',

                lazy: () => import('./routes/plots/[plotId]/forecasts')
              },
              {
                path: 'history',

                lazy: () => import('./routes/plots/[plotId]/history')
              },
              {
                index: true,
                element: <PlotsPlotId />
              },
              {
                path: 'irrelis',

                lazy: () => import('./routes/plots/[plotId]/irrelis/_layout'),
                children: [
                  {
                    index: true,
                    lazy: () => import('./routes/plots/[plotId]/irrelis/index')
                  },
                  {
                    path: 'settings',

                    lazy: () => import('./routes/plots/[plotId]/irrelis/settings')
                  }
                ]
              },
              {
                path: 'summary',

                lazy: () => import('./routes/plots/[plotId]/summary/_layout'),
                children: [
                  {
                    path: 'crop-tracking',

                    lazy: () => import('./routes/plots/[plotId]/summary/crop-tracking')
                  },
                  {
                    path: 'decitrait',

                    lazy: () => import('./routes/plots/[plotId]/summary/decitrait/_layout'),
                    children: [
                      {
                        index: true,
                        lazy: () => import('./routes/plots/[plotId]/summary/decitrait/index')
                      },
                      {
                        path: 'sanitary-state-notes',

                        lazy: () =>
                          import('./routes/plots/[plotId]/summary/decitrait/sanitary-state-notes')
                      },
                      {
                        path: 'settings',

                        lazy: () => import('./routes/plots/[plotId]/summary/decitrait/settings')
                      },
                      {
                        path: 'treatments',

                        lazy: () =>
                          import('./routes/plots/[plotId]/summary/decitrait/treatments/_layout'),
                        children: [
                          {
                            path: 'add',

                            lazy: () =>
                              import('./routes/plots/[plotId]/summary/decitrait/treatments/add')
                          },
                          {
                            index: true,
                            lazy: () =>
                              import('./routes/plots/[plotId]/summary/decitrait/treatments/index')
                          }
                        ]
                      }
                    ]
                  },
                  {
                    index: true,
                    lazy: () => import('./routes/plots/[plotId]/summary/index')
                  },
                  {
                    path: 'irrigation',

                    lazy: () => import('./routes/plots/[plotId]/summary/irrigation'),
                    children: [
                      {
                        path: 'reports',

                        lazy: () => import('./routes/plots/[plotId]/summary/irrigation/reports')
                      },
                      {
                        path: 'settings',

                        lazy: () =>
                          import('./routes/plots/[plotId]/summary/irrigation/settings/_layout'),
                        children: [
                          {
                            path: 'thresholds',

                            lazy: () =>
                              import(
                                './routes/plots/[plotId]/summary/irrigation/settings/thresholds/_layout'
                              ),
                            children: [
                              {
                                path: ':thresholdId',

                                lazy: () =>
                                  import(
                                    './routes/plots/[plotId]/summary/irrigation/settings/thresholds/[thresholdId]'
                                  )
                              },
                              {
                                path: 'create',

                                lazy: () =>
                                  import(
                                    './routes/plots/[plotId]/summary/irrigation/settings/thresholds/create'
                                  )
                              },
                              {
                                index: true,
                                lazy: () =>
                                  import(
                                    './routes/plots/[plotId]/summary/irrigation/settings/thresholds/index'
                                  )
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    path: 'maizy',

                    lazy: () => import('./routes/plots/[plotId]/summary/maizy')
                  },
                  {
                    path: 'tavmills',

                    lazy: () => import('./routes/plots/[plotId]/summary/tavmills')
                  },
                  {
                    path: 'water-balance',

                    lazy: () => import('./routes/plots/[plotId]/summary/water-balance/_layout'),
                    children: [
                      {
                        index: true,
                        lazy: () => import('./routes/plots/[plotId]/summary/water-balance/index')
                      },
                      {
                        path: 'reports',

                        lazy: () => import('./routes/plots/[plotId]/summary/water-balance/reports')
                      },
                      {
                        path: 'settings',

                        lazy: () => import('./routes/plots/[plotId]/summary/water-balance/settings')
                      }
                    ]
                  },
                  {
                    path: 'water-balance-arvalis',

                    lazy: () =>
                      import('./routes/plots/[plotId]/summary/water-balance-arvalis/_layout'),
                    children: [
                      {
                        path: 'create-plot',

                        lazy: () =>
                          import(
                            './routes/plots/[plotId]/summary/water-balance-arvalis/create-plot'
                          )
                      },
                      {
                        index: true,
                        lazy: () =>
                          import('./routes/plots/[plotId]/summary/water-balance-arvalis/index')
                      },
                      {
                        path: 'reports',

                        lazy: () =>
                          import('./routes/plots/[plotId]/summary/water-balance-arvalis/reports')
                      },
                      {
                        path: 'settings',

                        lazy: () =>
                          import('./routes/plots/[plotId]/summary/water-balance-arvalis/settings')
                      }
                    ]
                  },
                  {
                    path: 'water-balance-ri',

                    lazy: () => import('./routes/plots/[plotId]/summary/water-balance-ri/_layout'),
                    children: [
                      {
                        index: true,
                        lazy: () => import('./routes/plots/[plotId]/summary/water-balance-ri/index')
                      },
                      {
                        path: 'reports',

                        lazy: () =>
                          import('./routes/plots/[plotId]/summary/water-balance-ri/reports')
                      },
                      {
                        path: 'settings',

                        lazy: () =>
                          import('./routes/plots/[plotId]/summary/water-balance-ri/settings')
                      }
                    ]
                  },
                  {
                    path: 'weephyt',

                    lazy: () => import('./routes/plots/[plotId]/summary/weephyt')
                  }
                ]
              }
            ]
          },
          {
            path: 'create',

            element: <PlotsCreate />
          },
          {
            path: 'edit',

            element: <PlotsEdit />
          }
        ]
      },
      {
        path: 'push-notification',

        element: <PushNotification />
      },
      {
        path: 'smag',

        element: <Smag />
      },
      {
        path: 'stations',

        element: <StationsLayout />,
        children: [
          {
            path: ':stationId',

            lazy: () => import('./routes/stations/[stationId]/_layout'),
            children: [
              {
                path: 'alerts',

                lazy: () => import('./routes/stations/[stationId]/alerts')
              },
              {
                path: 'forecasts',

                lazy: () => import('./routes/stations/[stationId]/forecasts')
              },
              {
                path: 'history',

                lazy: () => import('./routes/stations/[stationId]/history')
              },
              {
                index: true,
                element: <StationsStationId />
              },
              {
                path: 'summary',

                lazy: () => import('./routes/stations/[stationId]/summary/_layout'),
                children: [
                  {
                    index: true,
                    lazy: () => import('./routes/stations/[stationId]/summary/index')
                  },
                  {
                    path: 'weephyt',

                    lazy: () => import('./routes/stations/[stationId]/summary/weephyt')
                  }
                ]
              }
            ]
          },
          {
            index: true,
            element: <Stations />
          }
        ]
      },
      {
        path: 'unsubscribe-emails',

        element: <UnsubscribeEmails />
      },
      {
        path: 'weather-map',

        element: <WeatherMapLayout />,
        children: [
          {
            path: ':deviceId',

            lazy: () => import('./routes/weather-map/[deviceId]/_layout'),
            children: [
              {
                path: 'alerts',

                lazy: () => import('./routes/weather-map/[deviceId]/alerts')
              },
              {
                path: 'forecasts',

                lazy: () => import('./routes/weather-map/[deviceId]/forecasts')
              },
              {
                path: 'history',

                lazy: () => import('./routes/weather-map/[deviceId]/history')
              },
              {
                index: true,
                element: <WeatherMapDeviceId />
              },
              {
                path: 'summary',

                lazy: () => import('./routes/weather-map/[deviceId]/summary')
              }
            ]
          }
        ]
      },
      {
        path: '*',

        element: <Unmatched />
      }
    ]
  }
]
