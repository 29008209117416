import styled from 'styled-components'
import SidePanelLink, { SidePanelLinkProps } from './SidePanelLink'

const SublinksContainer = styled(Box)`
  border-left: 2px solid ${(p) => p.theme.colors.grayscale[200]};
  margin-left: 18px;
  padding-left: 12px;
`

interface SidePanelLinkGroupProps {
  mainLink: SidePanelLinkProps
  subLinks: SidePanelLinkProps[]
}

const SidePanelLinkGroup = ({ mainLink, subLinks }: SidePanelLinkGroupProps) => {
  return (
    <Box>
      <SidePanelLink {...mainLink} />
      <SublinksContainer>
        {subLinks.map((link) => {
          return <SidePanelLink key={link.id} {...link} />
        })}
      </SublinksContainer>
    </Box>
  )
}

export default SidePanelLinkGroup
