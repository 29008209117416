import { css } from 'styled-components'

export default {
  bottom: (additionalAfterCss = '') => css`
    position: relative;

    &::after {
      content: ' ';
      position: absolute;
      left: 0;
      right: 0;
      height: 20px;
      bottom: -20px;

      /* Do not use transparent otherwise safari do weird things */
      background: linear-gradient(to bottom, rgb(0 38 53 / 8%), rgb(0 38 53 / 0.1%));

      /** Without z-index this does not work */
      z-index: 1;

      /** Important, if we have a scrollbar for example below the gradient */
      pointer-events: none;
      ${additionalAfterCss}
    }
  `
}
