import isNil from 'lodash-es/isNil'
import { ReactNode } from 'react'

interface CustomMarkerProps {
  lat: number
  lng: number
  children: ReactNode
  size: number
  onClick?: () => void
  zIndex?: number
  /** Only for marker positioning - apply a negative margin top / 2 */
  height?: number
}

const CustomMarker: FC<CustomMarkerProps> = ({
  size,
  onClick,
  children,
  zIndex,
  height = size
}) => (
  <Box
    $mt={-height / 2}
    $ml={-size / 2}
    $width={size}
    onClick={
      !isNil(onClick)
        ? (e) => {
            e.stopPropagation()
            onClick()
          }
        : undefined
    }
    $zIndex={zIndex}
    $position='relative'
  >
    {children}
  </Box>
)

export default CustomMarker
