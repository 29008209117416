import { schemas, useClient, useMutation } from '@weenat/client'
import * as zodSchemas from '@weenat/client/dist/zod-schemas'
import { useIntl } from '@weenat/wintl'
import { useSearchParams } from 'app/routx-router'
import AuthCard from 'app/src/authentication/components/AuthCard'
import GoToLoginWithEmail from 'app/src/authentication/components/GoToLoginWithEmail'
import useToasts from 'app/src/hooks/useToasts'
import DelimitedFlex from 'app/src/kit/DelimitedFlex'
import SubmitButton from 'app/src/kit/SubmitButton'
import SuperForm from 'app/src/kit/SuperForm'
import Text from 'app/src/kit/Text'
import EmailField from 'app/src/kit/fields/EmailField'
import { FormikProps } from 'formik'
import isNil from 'lodash-es/isNil'
import { useRef } from 'react'

export const settings = {
  search: zodSchemas.email
}

const MAIL_SCHEMA = schemas.shared.mailed
type FormValues = typeof MAIL_SCHEMA.initialValues

const ResetPassword = () => {
  const { t, formatErrorMessage } = useIntl()
  const client = useClient()
  const { addToast } = useToasts()
  const [{ email }] = useSearchParams(settings.search)

  const emailRef = useRef<string>('')
  const formRef = useRef<FormikProps<FormValues>>(null)

  const [resetPassword, resetPasswordRequest] = useMutation(client.auth.resetPassword(), {
    onSuccess: () => {
      addToast(
        t('auth.reset_password_success', {
          email: emailRef.current
        }),
        {
          appearance: 'success'
        }
      )
    },
    onError: (e) => {
      const [error] = e.nonFieldErrors || []
      if (!isNil(error)) {
        addToast(formatErrorMessage({ error, model: 'account' }), {
          appearance: 'error'
        })
      }
    }
  })

  const title = t('actions.reset_password')

  const onSubmit = async (values: FormValues) => {
    emailRef.current = values.email
    await resetPassword(values)
  }

  const initialValues = { email: !isNil(email) ? email : '' }

  return (
    <AuthCard>
      <Flex $flexDirection='column' $gap='xl'>
        <Text $fontSize='md' $fontWeight='bold' $textAlign='center'>
          {title}
        </Text>
        <SuperForm
          schema={MAIL_SCHEMA}
          onSubmit={onSubmit}
          initialValues={initialValues}
          formRef={formRef}
        >
          <Flex $flexDirection='column' $gap='md'>
            <EmailField
              $isDisabled={resetPasswordRequest.isPending}
              errorState={resetPasswordRequest.error}
            />
            <SubmitButton style={{ width: '100%' }} state={resetPasswordRequest}>
              {title}
            </SubmitButton>
          </Flex>
        </SuperForm>

        <DelimitedFlex $isDelimitedOnTop $pt='lg' $mt='lg'>
          <GoToLoginWithEmail
            style={{ width: '100%' }}
            email={
              !isNil(email)
                ? email
                : !isNil(formRef) && !isNil(formRef.current)
                  ? formRef.current.values.email
                  : undefined
            }
          />
        </DelimitedFlex>
      </Flex>
    </AuthCard>
  )
}

export const Component = ResetPassword
export default ResetPassword
