import { Target } from '@weenat/client/dist/core/alerts'
import { isPlot } from '@weenat/client/dist/core/utils/resourcesTypeCheckers'
import useItemName from '@weenat/client/dist/core/utils/useItemName'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import noop from 'lodash-es/noop'
import { styled } from 'styled-components'
import Checkbox from '../kit/Checkbox'
import Icons from '../kit/Icons'

const StyledBox = styled(Box)<{ $isSelected: boolean }>`
  padding: 8px;
  margin: 8px 0;
  border-radius: ${({ theme }) => theme.radiuses.md}px;
  border: 2px solid
    ${({ $isSelected, theme }) =>
      !$isSelected ? theme.colors.grayscale[300] : theme.colors.primary[500]};
  background-color: ${({ $isSelected, theme }) =>
    $isSelected ? theme.colors.primary[200] : 'transparent'};

  &:hover {
    cursor: pointer;
    border-color: ${({ theme }) => theme.colors.primary[500]};
  }
`

type TargetWithOrgName = Target & {
  orgName?: string
}

interface AlertTargetListItemProps {
  $isSelected?: boolean
  onPress?: () => void
  target: TargetWithOrgName
}

const AlertTargetListItem: FC<AlertTargetListItemProps> = ({
  $isSelected: isSelected = false,
  onPress = noop,
  target
}) => {
  const name = useItemName(target)

  const TargetIcn = isPlot(target) ? Icons.PlotsFilled : Icons.SingleSensorFilled

  return (
    <StyledBox $isSelected={isSelected} onClick={onPress}>
      <Flex $alignItems='center' $gap='md'>
        <Checkbox checked={isSelected} />
        <Flex $alignItems='center' $gap='md'>
          <TargetIcn $size='lg' />
          <Flex $flexDirection='column' $gap='lg'>
            <Text $fontWeight='bold'>{name}</Text>
            <Text $fontSize='sm'>
              {!isNil(target) && !isNil(target.orgName) ? target.orgName : '-'}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </StyledBox>
  )
}

export default AlertTargetListItem
