import { transparentize } from 'polished'
import { createPortal } from 'react-dom'
import { styled } from 'styled-components'

interface ModalOverlayProps {
  $zIndex?: number
}

const ModalOverlay = styled.div<ModalOverlayProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${(p) => p.$zIndex ?? 999};
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding: ${(p) => p.theme.spacings.xl}px;
  background-color: ${(props) => transparentize(0.7, props.theme.colors.grayscale.white)};
  backdrop-filter: blur(5px);
`

export default ModalOverlay

interface ControlledModalOverlayProps {
  isOpen: boolean
  close: () => void
  className?: string
}

export const ControlledModalOverlay: FC<ControlledModalOverlayProps> = ({
  isOpen,
  close,
  children,
  className
}) => {
  return isOpen
    ? createPortal(
        <ModalOverlay
          className={className}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            close?.()
          }}
        >
          {children}
        </ModalOverlay>,
        document.body
      )
    : null
}
