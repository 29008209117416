import { Plot } from '@weenat/client/dist/resources/plots'
import { useIntl } from '@weenat/wintl'
import { useOrgContext } from 'app/orgProvider'
import { OriginBadge } from '../devices/DeviceOriginBadge'

const PlotOriginBadge = ({ plot }: { plot: Plot }) => {
  const { t } = useIntl()
  const { currentOrgId } = useOrgContext()

  const plotComesFromCurrentOrganization = plot.organizationId === currentOrgId

  return (
    <OriginBadge
      label={plotComesFromCurrentOrganization ? t('titles.my_farm') : plot.organizationName}
      bgColor={plotComesFromCurrentOrganization ? 'grayscale.500' : 'tertiary.500'}
      lbColor={plotComesFromCurrentOrganization ? 'grayscale.white' : 'grayscale.black'}
    />
  )
}

export default PlotOriginBadge
