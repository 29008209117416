import { metricsWithEditableHorizon } from '@weenat/client/dist/enums/MetricIds'
import { Device } from '@weenat/client/dist/resources/devices'
import { googleMapsPolygonToGeoJSONPolygon } from 'app/src/administration/plots/DrawPlotShapeMap'
import intersection from 'lodash-es/intersection'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { GeoJSON, PlotCreationSearchParams } from './types'

export function isDeviceSelected(searchParams: PlotCreationSearchParams, device: Device): boolean {
  return searchParams.selectedDeviceIds?.includes(device.id) ?? false
}
export function isDeviceFocused(searchParams: PlotCreationSearchParams, device: Device): boolean {
  return parseInt(searchParams.focusedDevice as unknown as string, 10) === device.id
}

export function getDeviceDepth(
  searchParams: PlotCreationSearchParams,
  device: Device
): string | undefined {
  return searchParams.depths?.find((depth) => depth.includes(`${device.id}`))?.split('_')?.[1]
}

export function showHorizonForDevice(device?: Device): boolean {
  return !isEmpty(intersection(metricsWithEditableHorizon, device?.availableMeasures ?? []))
}

export function getDeviceHorizonId(
  searchParams: PlotCreationSearchParams,
  device: Device
): string | undefined {
  return searchParams.depths?.find((depth) => depth.includes(`${device.id}`))?.split('_')?.[2]
}

function get_polygon_centroid(pts: { x: number; y: number }[]) {
  const [first] = pts
  const last = pts[pts.length - 1]
  if (first.x != last.x || first.y != last.y) pts.push(first)
  let twicearea = 0
  let x = 0
  let y = 0
  const nPts = pts.length
  let p1
  let p2
  let f
  for (let i = 0, j = nPts - 1; i < nPts; j = i++) {
    p1 = pts[i]
    p2 = pts[j]
    f = p1.x * p2.y - p2.x * p1.y
    twicearea += f
    x += (p1.x + p2.x) * f
    y += (p1.y + p2.y) * f
  }
  f = twicearea * 3
  return { x: x / f, y: y / f }
}

export interface Point {
  type: 'Point'
  coordinates: number[]
}

export function getLatLngFromShape(shape: google.maps.Polygon | google.maps.Circle | undefined) {
  let latitude: number | undefined
  let longitude: number | undefined
  let geoJSON: GeoJSON | Point | undefined

  if (!isNil(shape)) {
    if ('getCenter' in shape) {
      const circleLatitude = shape.getCenter()?.lat
      if (typeof circleLatitude === 'number') latitude = circleLatitude
      else latitude = circleLatitude?.()

      const circleLongitude = shape.getCenter()?.lng
      if (typeof circleLongitude === 'number') longitude = circleLongitude
      else longitude = circleLongitude?.()

      if (!isNil(longitude) && !isNil(latitude)) {
        geoJSON = {
          type: 'Point',
          coordinates: [longitude, latitude]
        }
      }
    } else {
      geoJSON = googleMapsPolygonToGeoJSONPolygon(shape)
      const { x, y } = get_polygon_centroid(
        geoJSON.coordinates[0].map((coords) => ({ x: coords[0], y: coords[1] }))
      )
      latitude = y
      longitude = x
    }
  }
  return { latitude, longitude, geoJSON }
}

export const flex1 = { flex: 1 } as const
