import { getDssLabelPerSource } from '@weenat/client/dist/core/alerts/alerts'
import {
  allDssSources,
  allWeatherSources,
  Source
} from '@weenat/client/dist/resources/alerts.types'
import { useIntl } from '@weenat/wintl'
import MetricIcons from 'app/src/kit/icons/MetricIcons'
import Pop from 'app/src/kit/Pop'
import SelectChip from 'app/src/kit/SelectChip'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import { ReactNode, useState } from 'react'
import AlertCreationStep, { AlertCreationStepProps } from './AlertCreationStep'

type SourceChipItem = {
  value: Source
  label: string
  Icon?: ReactNode
}

interface SourceChipsProps {
  title: string
  items: SourceChipItem[]
  onChange: (value?: Source) => void
  currentValue?: Source
  disabledSources: Source[]
}

const SourceChips: FC<SourceChipsProps> = ({
  disabledSources,
  title,
  items,
  onChange,
  currentValue
}) => {
  return (
    <Flex $flexDirection='column' $gap='xl'>
      <Text $fontWeight='bold'>{title}</Text>
      <Flex $flexWrap='wrap' $width='100%'>
        {items.map(({ value, label, Icon }) => {
          const isSelected = value === currentValue || (value === 'LW_V' && currentValue === 'LW_D')
          return (
            <SelectChip
              canBeRemoved={isSelected}
              key={value}
              Icon={Icon}
              isSelected={isSelected}
              isDisabled={disabledSources.includes(value)}
              onPress={() => onChange(value)}
              onRemove={() => onChange(undefined)}
            >
              {label}
            </SelectChip>
          )
        })}
      </Flex>
    </Flex>
  )
}

type SourceStepProps = AlertCreationStepProps<Source>

interface PickPredicateSourceProps extends SourceStepProps {
  initialSource?: Source
  disabledSources: Source[]
}

const PickPredicateSource: FC<PickPredicateSourceProps> = ({
  disabledSources,
  initialSource,
  onPressNext,
  ...props
}) => {
  const { t } = useIntl()

  const weatherItems: SourceChipItem[] = allWeatherSources
    .filter((source) => source !== 'LW_D')
    .map((value) => ({
      value,
      Icon: MetricIcons[value],
      label: t(`metrics.${value}`, { capitalize: true })
    }))

  const dssItems: SourceChipItem[] = allDssSources.map((value) => ({
    value,
    Icon: undefined,
    label: getDssLabelPerSource(value)
  }))

  const [selectedSource, setSelectedSource] = useState<SourceChipItem['value'] | undefined>(
    initialSource
  )

  return (
    <AlertCreationStep
      {...props}
      canProgressToNext={!isNil(selectedSource)}
      onPressNext={() => onPressNext?.(selectedSource)}
    >
      <SourceChips
        title={t('alerts.meteo_data_source')}
        items={weatherItems}
        onChange={setSelectedSource}
        currentValue={selectedSource}
        disabledSources={disabledSources}
      />
      <Pop tourName='discover_alerts' stepName='dss_sources'>
        <SourceChips
          title={t('alerts.dss_data_source')}
          items={dssItems}
          onChange={setSelectedSource}
          currentValue={selectedSource}
          disabledSources={disabledSources}
        />
      </Pop>
    </AlertCreationStep>
  )
}

export default PickPredicateSource
