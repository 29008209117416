import { useIntl } from '@weenat/wintl'
import Card from '../kit/Card'
import { IconProps } from '../kit/Icon'
import Icons from '../kit/Icons'
import Modal, { ModalProps } from '../kit/Modal'
import Text from '../kit/Text'

interface IncentiveCardProps {
  Icon: FC<IconProps>
  message: string
}

const IncentiveCard = ({ Icon, message }: IncentiveCardProps) => {
  return (
    <Card>
      <Flex $flexDirection='column' $alignItems='center' $justifyContent='center' $gap='lg'>
        <Icon $size='lg' $backgroundColor={'grayscale.black'} $color={'grayscale.white'} />
        <Text $textAlign='center'>{message}</Text>
      </Flex>
    </Card>
  )
}

interface WhyJoinNetworkModalProps extends Omit<ModalProps, 'title'> {}

const WhyJoinNetworkModal: FC<WhyJoinNetworkModalProps> = ({ isOpen, close }) => {
  const { t } = useIntl()

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      title={t('models.network.misc.presentation_why_join_title')}
    >
      <Flex $flexDirection='column' $gap='lg'>
        <IncentiveCard
          Icon={Icons.Target}
          message={t('models.network.misc.presentation_why_join_localized_weather_data')}
        />
        <IncentiveCard
          Icon={Icons.PartnerExchange}
          message={t('models.network.misc.presentation_why_join_mutualize')}
        />
        <IncentiveCard
          Icon={Icons.Crowdsource}
          message={t('models.network.misc.presentation_why_join_community')}
        />
      </Flex>
    </Modal>
  )
}

export default WhyJoinNetworkModal
