import { WeenatTheme } from '@weenat/theme'

const getShapeOptionsFromTheme = (theme: WeenatTheme) => ({
  fillColor: theme.colors.grayscale.white,
  fillOpacity: 0.75,
  strokeColor: theme.colors.grayscale.white,
  strokeOpacity: 1,
  strokeWeight: 3,
  zIndex: 1
})

export default getShapeOptionsFromTheme
