import { WeenatTheme } from '@weenat/theme'
import { MultiPolygon, Point, Polygon, Position } from 'geojson'
import isNil from 'lodash-es/isNil'
import fromLngLatArrayToObject from './fromLngLatArrayToObject'
import getShapeOptionsFromTheme from './getShapeOptionsFromTheme'
import { GMCircle, GMMultiPolygon, GMPolygon } from './types'

const DEFAULT_CIRCLE_RADIUS_IN_METERS = 100

const toLatLngObjectWithExtendedBounds = (bounds: google.maps.LatLngBounds) => (path: Position) => {
  const latLng = fromLngLatArrayToObject(path)
  bounds.extend(latLng)
  return latLng
}

interface DrawShapeOptions {
  recenterOnShape?: boolean
  radius?: number
  offset?: google.maps.Padding
}

const DEFAULT_DRAW_SHAPE_OPTIONS: DrawShapeOptions = {
  recenterOnShape: false,
  radius: DEFAULT_CIRCLE_RADIUS_IN_METERS,
  offset: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }
}

interface DrawShapeArgs {
  map: google.maps.Map
  maps: typeof google.maps
  theme: WeenatTheme
  shape: Point | Polygon | MultiPolygon
  options?: DrawShapeOptions
}

interface DrawShapeReturn {
  shape: GMPolygon | GMMultiPolygon | GMCircle
  bounds: google.maps.LatLngBounds | null
}

// This function return either a circle instance or an array of polygon instances
const drawShape = ({
  map,
  maps,
  theme,
  // GeoJSON shape
  shape,
  options = DEFAULT_DRAW_SHAPE_OPTIONS
}: DrawShapeArgs): DrawShapeReturn => {
  const googleMapsShapeOptions = getShapeOptionsFromTheme(theme)
  const { radius, recenterOnShape, offset } = options
  const { type } = shape

  switch (type) {
    case 'Point': {
      const { coordinates } = shape

      const circle = new maps.Circle({
        radius: radius ?? DEFAULT_CIRCLE_RADIUS_IN_METERS,
        center: fromLngLatArrayToObject(coordinates as [number, number]),
        ...googleMapsShapeOptions
      })

      circle.setMap(map)
      const bounds = circle.getBounds()
      if (recenterOnShape && !isNil(bounds)) map.fitBounds(bounds, offset)

      return { shape: circle, bounds }
    }

    // We receive individual path coords as [lng, lat] arrays,
    // but google maps expect {lat, lng} objects
    case 'Polygon': {
      const { coordinates } = shape

      const polygonBounds = new maps.LatLngBounds()

      const formattedPaths = coordinates.map((paths) =>
        paths.map(toLatLngObjectWithExtendedBounds(polygonBounds))
      )
      const polygon = new maps.Polygon({
        paths: formattedPaths,
        ...googleMapsShapeOptions
      })

      polygon.setMap(map)

      if (recenterOnShape) map.fitBounds(polygonBounds, offset)

      return { shape: polygon, bounds: polygonBounds }
    }

    // A MultiPolygon may have inner holes, hence, paths is an array,
    // the first one is the outer path and the subsequent ones inner path
    case 'MultiPolygon': {
      const { coordinates } = shape

      const multiPolygonBounds = new maps.LatLngBounds()
      const multiPolygons: google.maps.Polygon[] = []

      coordinates.forEach((entry) => {
        const formattedPaths = entry.map((polygon) =>
          polygon.map(toLatLngObjectWithExtendedBounds(multiPolygonBounds))
        )

        const multiPolygon = new maps.Polygon({
          paths: formattedPaths,
          ...googleMapsShapeOptions
        })

        multiPolygon.setMap(map)
        multiPolygons.push(multiPolygon)
      })

      if (recenterOnShape) map.fitBounds(multiPolygonBounds, offset)

      return { shape: multiPolygons, bounds: multiPolygonBounds }
    }

    default:
      throw new Error(`drawShape function can not handle provided ${type} GeoJSON type of shape`)
  }
}

export default drawShape
