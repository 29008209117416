import { HydricStressMetric } from '@weenat/client/dist/enums/MetricIds'
import { useIntl } from '@weenat/wintl'
import Text from 'app/src/kit/Text'
import isEmpty from 'lodash-es/isEmpty'
import noop from 'lodash-es/noop'
import { styled } from 'styled-components'
import UniverseHorizonOptions, {
  UniverseHorizonOptionsProps
} from './universes/UniverseHorizonOptions'

const StyledContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  background-color: ${(p) => p.theme.colors.grayscale[100]};
  padding: ${(p) => p.theme.spacings.lg}px;
  height: 100%;
`

const HorizonBar = styled(Box)`
  border-radius: ${(p) => p.theme.radiuses.md}px;
  border: 1px solid ${(p) => p.theme.colors.grayscale[300]};
  margin-top: ${(p) => p.theme.radiuses.md}px;
  background-color: ${(p) => p.theme.colors.grayscale.white};
  height: 40px;
  overflow: hidden;
`

interface NoDataForHorizonProps
  extends Partial<
    Pick<
      UniverseHorizonOptionsProps,
      'availableHorizonOptions' | 'currentHorizon' | 'onHorizonChange'
    >
  > {
  metric: HydricStressMetric
}

const NoDataForHorizon: FC<NoDataForHorizonProps> = ({
  metric,
  availableHorizonOptions = [],
  currentHorizon = null,
  onHorizonChange = noop
}) => {
  const { t } = useIntl()

  const isCapa = metric === 'U_CAPA'
  const noDataMessage = t(`models.plot.misc.${isCapa ? 'no_data' : 'no_data_for_horizon'}`)

  return (
    <StyledContainer>
      <Text $textAlign='center'>{noDataMessage}</Text>
      {!isEmpty(availableHorizonOptions) ? (
        <HorizonBar>
          <UniverseHorizonOptions
            isHorizontal
            availableHorizonOptions={availableHorizonOptions}
            currentHorizon={currentHorizon}
            onHorizonChange={onHorizonChange}
            showUnit
          />
        </HorizonBar>
      ) : null}
    </StyledContainer>
  )
}

export default NoDataForHorizon
