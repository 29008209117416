import { useIntl } from '@weenat/wintl'
import { useNavigate } from 'app/routx-router'
import Button, { ButtonProps } from 'app/src/kit/Button'

interface GoToLoginWithEmailProps extends Partial<ButtonProps> {
  email: string | undefined
  wishedNetwork?: {
    networkId: number
    trial: boolean
    networkName: string
  }
}

const GoToLoginWithEmail: FC<GoToLoginWithEmailProps> = ({
  email,
  wishedNetwork,
  ...buttonProps
}) => {
  const { t } = useIntl()
  const nav = useNavigate()

  return (
    <Button
      importance='sd'
      onPress={() => {
        nav('/auth/login', {
          search: { email, wishedNetwork }
        })
      }}
      {...buttonProps}
    >
      {t('actions.go_back_to_login')}
    </Button>
  )
}

export default GoToLoginWithEmail
