import { useClient, useMutation } from '@weenat/client'
import { useIntl } from '@weenat/wintl'
import useToasts from 'app/src/hooks/useToasts'
import { ClientButtonWithConfirm } from 'app/src/kit/ButtonWithConfirm'
import logEvent from 'app/utils/analytics'

const DeleteMyAccountButton: FC = () => {
  const { t } = useIntl()
  const client = useClient()
  const { addToast } = useToasts()

  const deleteAccountMessage = t('actions.delete_my_account')

  const [deleteAccount, deleteAccountRequest] = useMutation(client.me.deleteMyAccount(), {
    onError: (e) => {
      if (e.status !== 401) {
        addToast(e.message, { appearance: 'error' })
      } else {
        addToast(t('actions.confirm_success'), { appearance: 'success' })
      }
    }
  })

  return (
    <ClientButtonWithConfirm
      isDanger
      state={deleteAccountRequest}
      onPress={() => {
        deleteAccount()
        logEvent('delete_account')
      }}
      confirmationMessage={t('accountDeletionModal.confirmation_message', { capitalize: true })}
      title={deleteAccountMessage}
    >
      {deleteAccountMessage}
    </ClientButtonWithConfirm>
  )
}

export default DeleteMyAccountButton
