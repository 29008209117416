import useDisclosure from 'app/src/hooks/useDisclosure'
import Text from 'app/src/kit/Text'
import { ReactNode } from 'react'
import Card, { CardProps } from './Card'
import Collapsible from './Collapsible'
import Icons from './Icons'

interface CollapsibleCardProps extends CardProps {
  title: ReactNode
  animationDuration?: number
}

const CollapsibleCard: FC<CollapsibleCardProps> = ({
  children,
  title,
  animationDuration,
  ...propsRest
}) => {
  const { isOpen, toggle } = useDisclosure()
  const rotation = isOpen ? 90 : -90

  return (
    <Card {...propsRest}>
      {/* Spread props rest so that we can use width, p and everything else box related */}
      <Flex $cursor='pointer' $justifyContent='space-between' $alignItems='center' onClick={toggle}>
        <Box>{typeof title === 'string' ? <Text $fontWeight='bold'>{title}</Text> : title}</Box>
        <Icons.ChevronLeft $rotate={rotation} $size='md' $p='sm' $ml='lg' onPress={toggle} />
      </Flex>
      <Collapsible isOpen={isOpen} direction='vertical'>
        {children}
      </Collapsible>
    </Card>
  )
}

export default CollapsibleCard
