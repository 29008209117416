import type { SVGProps } from 'react'

const SvgIrrelis = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      fill='#F79F00'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M14.698 15.95C11.611 18.709 4 18 4 18S2.672 10.023 5.69 6.372c3.036-3.674 11.687-3.519 11.687-3.519s1.213 9.618-2.68 13.096z'
    />
    <path
      fill='#116967'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M14.465 19.888C10.551 21.238 4 18.189 4 18.189S5.667 9.772 9.86 7.572C14.083 5.358 22 8.846 22 8.846s-2.6 9.339-7.534 11.042z'
    />
  </svg>
)

export default SvgIrrelis
