import type { SVGProps } from 'react'

const SvgNotificationsUnread = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 -960 960 960' {...props}>
    <path d='M480-80c-22 0-40.833-7.833-56.5-23.5C407.833-119.167 400-138 400-160h160c0 22-7.833 40.833-23.5 56.5C520.833-87.833 502-80 480-80M200-200c-11.333 0-20.833-3.833-28.5-11.5S160-228.667 160-240s3.833-20.833 11.5-28.5S188.667-280 200-280h40v-280c0-55.333 16.667-104.5 50-147.5s76.667-71.167 130-84.5v-28c0-16.667 5.833-30.833 17.5-42.5S463.333-880 480-880s30.833 5.833 42.5 17.5S540-836.667 540-820v13c-6.667 13.333-11.667 27.333-15 42s-5 29.667-5 45c0 55.333 19.5 102.5 58.5 141.5S664.667-520 720-520v240h40c11.333 0 20.833 3.833 28.5 11.5S800-251.333 800-240s-3.833 20.833-11.5 28.5S771.333-200 760-200z' />
    <path
      fill='red'
      d='M720-600c-33.333 0-61.667-11.667-85-35s-35-51.667-35-85 11.667-61.667 35-85 51.667-35 85-35 61.667 11.667 85 35 35 51.667 35 85-11.667 61.667-35 85-51.667 35-85 35'
    />
  </svg>
)

export default SvgNotificationsUnread
