import { useIntl } from '@weenat/wintl'
import { useField } from 'formik'
import CountryPicker, { CountryPickerProps } from './CountryPicker'
import { CustomFieldProps, TextFieldPrimitive, TextFieldProps } from './TextField'
import useBackendFieldError from './useBackendFieldError'

interface TelephoneFieldsPrimitiveProps extends TextFieldProps {
  onCountrySelect: (countryCode: CountryPickerProps['value']) => void
  countryValue: CountryPickerProps['value']
  errors: TextFieldProps['$displayedError']
  hideLabel?: boolean
  value: string
  onChange: (newValue: unknown) => void
  isLoading?: boolean
  isDisabled?: boolean
  errorPath: string
}

export const TelephoneFieldsPrimitive: FC<TelephoneFieldsPrimitiveProps> = ({
  onCountrySelect,
  countryValue,
  errors,
  hideLabel,
  value,
  onChange,
  isLoading,
  isDisabled,
  ref,
  ...props
}) => {
  const { t } = useIntl()

  return (
    <TextFieldPrimitive
      $displayedError={errors}
      $isDisabled={isDisabled}
      inputType='telephoneNumber'
      leftAdornment={
        <CountryPicker
          onChange={(ctr) => {
            onCountrySelect(ctr.cca2)
          }}
          value={countryValue}
        />
      }
      label={
        hideLabel
          ? undefined
          : t('models.profile.fields.telephone_number.label', { capitalize: true })
      }
      value={value}
      onChange={(e) => onChange(e.currentTarget.value)}
      placeholder='06 12 12 12 12'
      isLoading={isLoading}
      {...props}
    />
  )
}

type TelephoneFieldsProps = Omit<CustomFieldProps, 'name'> & {
  names?: {
    countryCode?: string
    phoneNumber?: string
  }
  errorPaths?: {
    countryCode?: string
    phoneNumber?: string
  }
  hideLabel?: boolean
}

/**
 * This component handle both telephoneNumber & telephoneCountryCode fields,
 * this should be the last input otherwise input submit keyboard key does not trigger onSubmitEditing which would lead to bad next focus
 */
const TelephoneFields: FC<TelephoneFieldsProps> = ({
  names: propsNames,
  errorPaths: propsErrorPaths,
  hideLabel = false,
  errorState,
  ...props
}) => {
  const names = {
    countryCode: 'telephoneCountryCode',
    phoneNumber: 'telephoneNumber',
    ...(propsNames || {})
  }

  /** TODO: country is not handled but this should not be a real problem for now */
  const errorPaths = {
    ...names,
    ...propsErrorPaths
  }

  const [countryCodeField, , countryCodeFieldHelpers] = useField(names.countryCode)
  const [phoneNumberField, phoneMeta, phoneFieldHelpers] = useField(names.phoneNumber)

  const displayedError = phoneMeta.touched ? phoneMeta.error : null

  useBackendFieldError({
    name: names.phoneNumber,
    errorPath: names.phoneNumber,
    errorState
  })

  return (
    <TelephoneFieldsPrimitive
      errorPath={errorPaths.phoneNumber}
      name={names.phoneNumber}
      hideLabel={hideLabel}
      countryValue={countryCodeField.value}
      onCountrySelect={(ctr) => countryCodeFieldHelpers.setValue(ctr)}
      value={phoneNumberField.value}
      onChange={(txt) => {
        phoneFieldHelpers.setTouched(true)
        phoneFieldHelpers.setValue(txt)
      }}
      $displayedError={displayedError}
      {...phoneNumberField.value}
      {...props}
      onBlur={() => phoneFieldHelpers.setTouched(true)}
    />
  )
}

export default TelephoneFields
