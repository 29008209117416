import { isNil } from 'lodash-es'
import { styled } from 'styled-components'
import { IconProps } from './Icon'
import Icons from './Icons'
import Text from './Text'
import LoadingCircle from './loaders/LoadingCircle'

const NavIconContainer = styled(Flex)`
  margin-left: 8px;
  padding-bottom: 4px;
  align-items: center;
  gap: 8px;
`

interface PaginationControlsProps {
  handleNextClick: () => void
  handlePreviousClick: () => void
  hasNext: boolean
  hasPrevious: boolean
  isLoading: boolean
  isPendingAndHasNoItems: boolean
  startOfPage: number
  endOfPage: number
  totalNumberOfItems: number | undefined
}

const PaginationControls = ({
  handleNextClick,
  handlePreviousClick,
  hasNext,
  hasPrevious,
  isLoading,
  isPendingAndHasNoItems,
  totalNumberOfItems,
  startOfPage,
  endOfPage
}: PaginationControlsProps) => {
  const paginationLabel = `${startOfPage} - ${isPendingAndHasNoItems ? '—' : endOfPage} / ${
    !isNil(totalNumberOfItems) ? totalNumberOfItems : '—'
  }`

  const previousNavIcnProps: IconProps | undefined = { $rotate: -180, $size: 'lg' }
  const nextNavIcnProps: IconProps = { $size: 'lg' }

  return (
    <Flex $justifyContent='flex-end' $alignItems='center' $ml='auto'>
      {isLoading ? (
        <Box $minWidth={80}>
          <LoadingCircle />
        </Box>
      ) : (
        <Text $fontSize='sm'>{paginationLabel}</Text>
      )}
      <NavIconContainer>
        <Icons.ArrowRight
          onPress={handlePreviousClick}
          $isDisabled={!hasPrevious || isLoading}
          {...previousNavIcnProps}
        />
        <Icons.ArrowRight
          onPress={handleNextClick}
          $isDisabled={!hasNext || isLoading}
          {...nextNavIcnProps}
        />
      </NavIconContainer>
    </Flex>
  )
}

export default PaginationControls
