import { AbsoluteHref } from '@weenat/client/dist/routx/runtime-core'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import isNumber from 'lodash-es/isNumber'
import { CSSProperties, ReactNode } from 'react'
import { css, styled } from 'styled-components'
import Link from './LinkComponent'
import ListEmpty, { ListEmptyProps } from './ListEmpty'
import LoadingTable from './LoadingTable'

const ROW_HEIGHT = 32
const SMALL_SCREEN_BREAKPOINT = 1024

interface ExtraTableCellProps {
  $fontWeight?: number
  $hideOnSmallScreen?: boolean
  $ellipsis?: boolean
  $width: number
  $fontVariantNumeric?: CSSProperties['fontVariantNumeric']
}

export const TableCell = styled.div<Partial<ExtraTableCellProps>>`
  padding: 8px;
  width: ${(p) => (!isNil(p.$width) ? `${p.$width}px` : '100%')};
  min-height: ${ROW_HEIGHT}px;
  color: ${({ theme }) => theme.colors.grayscale.black};
  line-height: ${({ theme }) => theme.typography.lineHeights.md};
  font-weight: ${({ $fontWeight }) => (!isNil($fontWeight) ? $fontWeight : 300)};
  font-size: ${(p) => p.theme.typography.sizes.rg}px;
  overflow-wrap: break-word;

  ${({ $fontVariantNumeric }) =>
    !isNil($fontVariantNumeric)
      ? css`
          font-variant-numeric: ${$fontVariantNumeric};
        `
      : ''}

  ${({ $hideOnSmallScreen }) =>
    $hideOnSmallScreen &&
    css`
      @media (max-width: ${SMALL_SCREEN_BREAKPOINT}px) {
        display: none;
      }
    `}

  ${({ $ellipsis }) =>
    $ellipsis &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `};
`

interface TableRowProps {
  $gridTemplateColumns: string
  $clickable?: boolean
}

const TableRow = styled(Flex)<TableRowProps>`
  position: relative;

  display: grid;
  grid-template-columns: ${(p) => p.$gridTemplateColumns};
  grid-template-rows: repeat(1, 1fr);
  grid-gap: 4px;
  padding: 4px;
  border-bottom: 1px solid ${(p) => p.theme.colors.grayscale[100]};

  flex: none;

  ${(p) =>
    p.$clickable &&
    css`
      &:hover {
        cursor: pointer;
        background-color: ${p.theme.colors.primary[200]} !important;
      }
    `}
`

const RowAsLink = styled(Link)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`

const TableHeader = styled(TableRow)`
  border-color: ${(props) => props.theme.colors.grayscale[300]};
  color: ${(props) => props.theme.colors.grayscale[700]};
  font-weight: 400;
  line-height: 1;
`

const TableHeaderCell = styled(TableCell)`
  color: ${(props) => props.theme.colors.grayscale[700]};
  font-weight: ${(props) => props.theme.typography.weights.medium};
  font-size: ${(props) => props.theme.typography.sizes.sm}px;
  line-height: 1;
`

const StyledTable = styled(Flex)`
  height: 100%;
  width: 100%;
  flex-direction: column;
`

const TableHolder = styled(Box)<{ $outlined?: boolean }>`
  ${(props) =>
    props.$outlined &&
    css`
      border-radius: ${props.theme.radiuses.md}px;
      border: 1px solid ${props.theme.colors.grayscale[300]};
    `}
  height: 100%;
  width: 100%;
  margin: ${(p) => `${p.theme.spacings.md}px 0px`};
  overflow: hidden;
`

const TableBody = styled(Flex)<{ $maxHeight?: CSSProperties['maxHeight'] }>`
  flex-direction: column;
  flex: 1;
  overflow-y: auto;

  ${(props) =>
    !isNil(props.$maxHeight) &&
    css`
      max-height: ${props.$maxHeight};
    `}

  /** Hiding scrollbar but keeping scroll */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /** Chromium based */
  &::-webkit-scrollbar {
    display: none;
  }
`

export interface TableColumn {
  label: string
  width: number | string
  hideOnSmallScreen?: boolean
}

export interface TableProps<T> {
  data?: T[]
  getLinkFromItem?: (item?: T) => AbsoluteHref
  renderRow: (item: T, index?: number) => ReactNode
  keyExtractor: (item: T) => string | number
  columns: TableColumn[]
  emptyMessage?: string
  isLoading?: boolean
  tableName: string
  /** Whether or not to add a border around the table */
  outlined?: boolean
  model?: ListEmptyProps['model']
  /** Whether or not the search query is empty */
  isSearchEmpty?: boolean
  stickyFooter?: ReactNode
  maxHeight?: CSSProperties['maxHeight']
}

function Table<T>({
  data = [],
  getLinkFromItem,
  renderRow,
  keyExtractor,
  columns = [],
  emptyMessage,
  isLoading = false,
  tableName,
  outlined = false,
  model,
  isSearchEmpty = false,
  stickyFooter = null,
  maxHeight
}: TableProps<T>) {
  const gridTemplateColumns = columns
    .map(({ width }) => (isNumber(width) ? `minmax(0, ${width}fr)` : `minmax(0, ${width})`))
    .join(' ')
  const isTableEmpty = isEmpty(data)

  return (
    <TableHolder $outlined={outlined}>
      <StyledTable>
        {/* Headers */}
        {!isEmpty(columns) ? (
          <TableHeader $gridTemplateColumns={gridTemplateColumns}>
            {columns.map(({ label, hideOnSmallScreen }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableHeaderCell $hideOnSmallScreen={hideOnSmallScreen} key={`${label}-${index}`}>
                {label}
              </TableHeaderCell>
            ))}
          </TableHeader>
        ) : null}
        {isLoading ? (
          <LoadingTable
            uniqueKey={`${tableName}-loader`}
            numberOfColumns={!isEmpty(columns) ? columns.length : 6}
            numberOfRows={3}
          />
        ) : isTableEmpty ? (
          <ListEmpty
            message={emptyMessage}
            model={model}
            mood={isSearchEmpty ? 'confused' : 'sad'}
          />
        ) : (
          <TableBody $maxHeight={maxHeight}>
            {data.map((item, idx) => {
              const to = getLinkFromItem?.(item)

              return (
                <TableRow
                  key={keyExtractor(item)}
                  $gridTemplateColumns={gridTemplateColumns}
                  $clickable={!isNil(to)}
                >
                  {!isNil(to) ? <RowAsLink to={to} /> : null}
                  {renderRow(item, idx)}
                </TableRow>
              )
            })}
          </TableBody>
        )}
        {stickyFooter}
      </StyledTable>
    </TableHolder>
  )
}

export default Table
