import { useSlotProvider } from 'app/state'
import { Children, cloneElement, useEffect } from 'react'

const LayoutHeaderActions: FC = ({ children }) => {
  const [, setSlotContent] = useSlotProvider()
  useEffect(() => {
    setSlotContent((currentSlotContent) => ({
      ...currentSlotContent,
      headerActions: [
        ...Children.toArray(children).map((c, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={idx} $pl='lg'>
            {cloneElement(c)}
          </Box>
        ))
      ]
    }))
  }, [children])
  return null
}

export default LayoutHeaderActions
