import { schemas, useClient, useMutation, useQuery } from '@weenat/client'
import * as zodSchemas from '@weenat/client/dist/zod-schemas'
import { useIntl } from '@weenat/wintl'
import { useNavigate, useSearchParams } from 'app/routx-router'
import SubmitButton from 'app/src/kit/SubmitButton'
import SuperForm from 'app/src/kit/SuperForm'
import Text from 'app/src/kit/Text'
import TextField from 'app/src/kit/fields/TextField'
import OnboardingCard from 'app/src/onboardings/OnboardingCard'
import OnboardingNavigationHeader from 'app/src/onboardings/OnboardingNavigationHeader'
import { useCurrentOrganizationId } from 'app/state'
import { isNil } from 'lodash-es'
import styled from 'styled-components'
import { z } from 'zod'

const ContentCard = styled(OnboardingCard)`
  & {
    align-items: stretch;
    gap: 24px;
  }
`

const FullWidthBtn = styled(SubmitButton)`
  & {
    width: 100%;
  }
`

export const settings = {
  search: z.object({ invite: zodSchemas.emailInvitation.optional() })
}

const OnboardingMyFarm = () => {
  const { t } = useIntl()
  const nav = useNavigate()
  const client = useClient()
  const orgId = useCurrentOrganizationId()
  const [{ invite }] = useSearchParams(settings.search)

  const orgRequest = useQuery(client.orgs.get(orgId), { enabled: !isNil(orgId) })
  const org = orgRequest.data

  const [mutation, mutationRequest] = useMutation(client.orgs.update(orgId), {
    onSuccess: () => {
      nav('/onboarding/sensors', { search: { invite } })
    }
  })

  return (
    <Flex $flexDirection='column' $gap='lg'>
      <OnboardingNavigationHeader />
      <ContentCard>
        <Text $fontWeight='bold' $fontSize='md' $textAlign='center'>
          {t('onboardings.start_by_setup_org')}
        </Text>
        <SuperForm
          schema={schemas.shared.named}
          initialValues={{ name: org?.name ?? '' }}
          onSubmit={async ({ name }) => {
            await mutation({ name })
          }}
        >
          <TextField name='name' label={undefined} />
          <FullWidthBtn state={mutationRequest}>
            {t('actions.next', { capitalize: true })}
          </FullWidthBtn>
        </SuperForm>
      </ContentCard>
    </Flex>
  )
}

export default OnboardingMyFarm
