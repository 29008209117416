import { useIntl } from '@weenat/wintl'
import Banner from 'app/src/kit/Banner'
import Text from 'app/src/kit/Text'

const ShouldVerifyEmail: FC<{ email: string }> = ({ email }) => {
  const { t } = useIntl()

  return (
    <Banner $feedback='warn'>
      <Text>{t('auth.email_verification_warning', { email })}</Text>
    </Banner>
  )
}

export default ShouldVerifyEmail
