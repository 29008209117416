import { schemas } from '@weenat/client'
import { useIntl } from '@weenat/wintl'
import { ButtonProps } from 'app/src/kit/Button'
import CheckboxField from 'app/src/kit/fields/CheckboxField'
import TelephoneFields from 'app/src/kit/fields/TelephoneFields'
import Modal, { ModalProps } from 'app/src/kit/Modal'
import SubmitButton from 'app/src/kit/SubmitButton'
import SuperForm from 'app/src/kit/SuperForm'

const SINGLE_PHONE_SCHEMA = schemas.me.newPhoneNumber
type SinglePhoneFormValues = typeof SINGLE_PHONE_SCHEMA.initialValues

const ADD_PHONE_INITIAL_VALUES = {
  enabled: false,
  telephoneCountryCode: 'FR',
  telephoneNumber: ''
}

interface AddPhoneNumberModalProps extends ModalProps {
  onSubmit: (values: SinglePhoneFormValues) => void
  buttonState: ButtonProps['state']
}

const AddPhoneNumberModal: FC<AddPhoneNumberModalProps> = ({ onSubmit, buttonState, ...props }) => {
  const { t } = useIntl()

  return (
    <Modal {...props}>
      <Box $my='md'>
        <SuperForm
          schema={SINGLE_PHONE_SCHEMA}
          onSubmit={onSubmit}
          initialValues={ADD_PHONE_INITIAL_VALUES}
        >
          <TelephoneFields hideLabel isLoading={buttonState?.isPending} />
          <Box $mt='md'>
            <CheckboxField
              label={t('user_settings.phone_numbers.activate_number_by_default')}
              name='enabled'
            />
          </Box>
          <Flex $width='100%' $justifyContent='flex-end'>
            <SubmitButton state={buttonState} />
          </Flex>
        </SuperForm>
      </Box>
    </Modal>
  )
}

export default AddPhoneNumberModal
