import { FormikContextType, FormikErrors, useFormikContext } from 'formik'
import { useEffect } from 'react'

interface UseOnChangeProps<V> {
  onChange: (values: V, ctx: FormikContextType<V>) => void
  onError?: (errors: FormikErrors<V>, ctx: FormikContextType<V>) => void
}

function UseOnChange<V = Record<string, unknown>>({ onChange, onError }: UseOnChangeProps<V>) {
  const ctx = useFormikContext<V>()

  useEffect(() => {
    onChange(ctx.values, ctx)
  }, [ctx.values])

  useEffect(() => {
    onError?.(ctx.errors, ctx)
  }, [ctx.errors])

  return null
}

export default UseOnChange
