/* eslint-disable no-restricted-imports */
import { UseToasts } from '@weenat/client/dist/core/types'
import { ReactNode, useCallback } from 'react'
import { Options as OptionsFromLib, useToasts as useToastsFromLib } from 'react-toast-notifications'

type AppearanceLessOptions = Omit<OptionsFromLib, 'appearance'>

type CustomAddToast = (
  content: ReactNode,
  options?: AppearanceLessOptions,
  callback?: (id: string) => void
) => void

/**
 * Hook supercharging useToasts from react-toast-notifications to avoid repeating the appearance option prop
 */
const useToasts: UseToasts = () => {
  const { addToast, removeAllToasts, removeToast, toastStack, updateToast } = useToastsFromLib()

  // preemptively using useCallback since the hook is used often
  const addSuccessToast: CustomAddToast = useCallback(
    (content, options, callback) => {
      return addToast(content, { ...options, appearance: 'success' as const }, callback)
    },
    [addToast]
  )

  // preemptively using useCallback since the hook is used often
  const addInfoToast: CustomAddToast = useCallback(
    (content, options, callback) => {
      return addToast(content, { ...options, appearance: 'info' as const }, callback)
    },
    [addToast]
  )

  // preemptively using useCallback since the hook is used often
  const addErrorToast: CustomAddToast = useCallback(
    (content, options, callback) => {
      return addToast(
        content,
        { ...options, autoDismiss: false, appearance: 'error' as const },
        callback
      )
    },
    [addToast]
  )

  return {
    addErrorToast,
    addInfoToast,
    addSuccessToast,
    addToast: addToast as CustomAddToast,
    removeAllToasts,
    removeToast,
    toastStack,
    updateToast
  }
}

export default useToasts
