import useDeviceOrigin from '@weenat/client/dist/core/devices/useDeviceOrigin'
import { Device } from '@weenat/client/dist/resources/devices'
import { WeenatColor } from '@weenat/theme'
import { useIntl } from '@weenat/wintl'
import AdminBadge from 'app/src/kit/AdminBadge'

export const OriginBadge: FC<{ label: string; bgColor?: WeenatColor; lbColor?: WeenatColor }> = ({
  label,
  bgColor,
  lbColor
}) => {
  const badgeColor = bgColor ?? 'grayscale.500'
  const labelColor = lbColor ?? 'grayscale.black'

  return <AdminBadge label={label} $backgroundColor={badgeColor} labelColor={labelColor} />
}

const OwnerBadge = () => {
  const { t } = useIntl()

  const label = t('titles.my_farm', { capitalize: true })

  return (
    <AdminBadge label={label} $backgroundColor='grayscale.500' labelColor={'grayscale.white'} />
  )
}

/**
 * This component may be displayed in both the devices list and the devices map,
 * so we have to account for both possibilities and fetch organization/network/networkOrganizations
 */
const DeviceOriginBadge: FC<{ device: Device }> = ({ device }) => {
  const { label, simplifiedOrigin } = useDeviceOrigin(device)

  const badgeBackground: WeenatColor | undefined =
    simplifiedOrigin === 'network'
      ? 'primary.500'
      : simplifiedOrigin === 'adherentFarm'
        ? 'tertiary.500'
        : undefined

  const labelColor: WeenatColor | undefined =
    simplifiedOrigin === 'network'
      ? 'grayscale.white'
      : simplifiedOrigin === 'adherentFarm'
        ? 'tertiary.black'
        : undefined

  return simplifiedOrigin === 'currentOrg' ? (
    <OwnerBadge />
  ) : simplifiedOrigin === 'network' || simplifiedOrigin === 'adherentFarm' ? (
    <OriginBadge label={label} bgColor={badgeBackground} lbColor={labelColor} />
  ) : null
}

export default DeviceOriginBadge
