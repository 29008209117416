import { MultiPolygon, Polygon } from 'geojson'
import isNil from 'lodash-es/isNil'
import { useCallback, useState } from 'react'
import { styled, useTheme } from 'styled-components'
import StandaloneGoogleMap from '../map/StandaloneGoogleMap'
import { GoogleMapApi, drawShape } from '../map/utils'

const MapWrapper = styled(Box)`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
`

const MapContainer = styled(Box)`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  overflow: hidden;
  border-radius: ${(p) => p.theme.radiuses.md}px;
`

interface NetworkDeviceLocationsMapProps {
  locations: Polygon | MultiPolygon
}

const NetworkDeviceLocationsMap = ({ locations }: NetworkDeviceLocationsMapProps) => {
  const theme = useTheme()

  const [didAlreadyDrawnNetworkLocations, setDidAlreadyDrawnNetworkLocations] = useState(false)
  const [{ map, maps }, setGoogleApi] = useState<GoogleMapApi>({ map: null, maps: null })

  const onGoogleApiLoaded = useCallback((googleApi: GoogleMapApi) => setGoogleApi(googleApi), [])

  if (!isNil(map) && !isNil(locations) && !didAlreadyDrawnNetworkLocations) {
    drawShape({
      map,
      maps,
      theme,
      shape: locations,
      options: {
        recenterOnShape: true
      }
    })
    setDidAlreadyDrawnNetworkLocations(true)
  }

  return (
    <MapWrapper>
      <MapContainer>
        <StandaloneGoogleMap onGoogleApiLoaded={onGoogleApiLoaded} />
      </MapContainer>
    </MapWrapper>
  )
}

export default NetworkDeviceLocationsMap
