import { Device } from '@weenat/client/dist/resources/devices'
import isNil from 'lodash-es/isNil'
import { defaultCenter } from './defaults'
import fromLngLatArrayToObject from './fromLngLatArrayToObject'

const getCoordinatesFromDevice = (device: Device, activeDefaultCenter = true) => {
  const { location } = device

  if (isNil(location)) {
    if (activeDefaultCenter) return defaultCenter
    else return undefined
  }

  const { coordinates } = location

  return fromLngLatArrayToObject(coordinates)
}

export default getCoordinatesFromDevice
