import isArray from 'lodash-es/isArray'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import isString from 'lodash-es/isString'
import { HTMLProps, ReactNode, forwardRef } from 'react'
import { styled } from 'styled-components'
import ErrorMessage from '../ErrorMessage'
import Icons from '../Icons'
import LoadingCircle from '../loaders/LoadingCircle'
import FieldLabel, { FieldLabelProps } from './FieldLabel'
import InputFieldBackground, { InputFieldBackgroundProps } from './InputFieldBackground'

interface ContainerProps {
  $isDisabled?: boolean
  $isPointerCursor?: boolean
}

/* We want the input to fill its container (hence width). so modify its container if the input is too wide. */
const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 4px 0 8px;
  color: ${(props) => props.theme.colors.grayscale.black};

  ${(p) =>
    p.$isDisabled &&
    `
      opacity: 0.5;
    `};

  ${(p) =>
    p.$isPointerCursor &&
    `
      cursor: pointer;
    `}
`

// The flex-shrink because we want it to fill as much space as available
const InputFieldContent = styled(Flex)`
  align-items: center;
  flex: 1 2 0;
  width: fill-available;
  //prevent content from overflowing above input limit
  min-width: 0;
`

const TextInputErrorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 0;
`

export interface InputFieldContainerProps
  extends ContainerProps,
    Omit<FieldLabelProps, 'isFieldRequired' | 'label'>,
    Omit<InputFieldBackgroundProps, '$isFocused'> {
  rightAdornment?: React.ReactNode
  leftAdornment?: React.ReactNode
  children: React.ReactNode
  $isDisabled?: boolean
  $isFocused?: boolean
  className?: HTMLProps<HTMLDivElement>['className']
  onPress?: HTMLProps<HTMLDivElement>['onClick']
  isRequired?: boolean
  isLoading?: boolean
  hideErrors?: boolean
  isClearable?: boolean
  label?: string
  value: unknown
  $displayedError?: ReactNode
  onClear?: () => void
}

/**
 * Handles an input container behavior adornments & styling
 * Avoids reusing a TextField every time and having to hack away using invisible values, as well as right and left adornments
 */
const InputFieldContainer = forwardRef<HTMLDivElement, InputFieldContainerProps>(
  (
    {
      children,
      className,
      $displayedError,
      helpers,
      hideErrors = false,
      isClearable = false,
      $isDisabled = false,
      $isFocused = false,
      isLoading = false,
      $isPointerCursor = false,
      isRequired = true,
      label,
      leftAdornment,
      onClear,
      onPress,
      rightAdornment,
      value,
      warning
    },
    ref
  ) => {
    const isStringOrArray = isString(value) || isArray(value)

    return (
      <Container
        $isDisabled={$isDisabled || isLoading}
        onClick={$isDisabled || isLoading ? undefined : onPress}
        className={className}
        $isPointerCursor={$isPointerCursor}
        ref={ref}
      >
        {!isNil(label) ? (
          <FieldLabel
            label={label}
            isFieldRequired={isRequired}
            warning={warning}
            helpers={helpers}
          />
        ) : null}

        {!isNil(helpers) && typeof helpers !== 'string' ? <div>{helpers}</div> : null}

        <InputFieldBackground
          $isFocused={$isFocused}
          $displayedError={hideErrors ? null : $displayedError}
        >
          <Flex $alignItems='center' $flex={1} $width='100%' $height='100%' $gap='md'>
            {!isNil(leftAdornment) ? (
              <Flex $alignItems='center' $height='100%'>
                {leftAdornment}
              </Flex>
            ) : null}
            <InputFieldContent>{children}</InputFieldContent>
            {isClearable &&
            ((isStringOrArray && !isEmpty(value)) || (!isStringOrArray && !isNil(value))) ? (
              <Icons.Close
                $size='md'
                onPress={(e) => {
                  e.stopPropagation()
                  onClear?.()
                }}
                $p='sm'
              />
            ) : null}
            {isLoading ? <LoadingCircle size='md' /> : rightAdornment}
          </Flex>
        </InputFieldBackground>

        {hideErrors ? null : (
          <TextInputErrorsContainer>
            <ErrorMessage $error={$displayedError}>{$displayedError}&nbsp;</ErrorMessage>
          </TextInputErrorsContainer>
        )}
      </Container>
    )
  }
)

export default InputFieldContainer
