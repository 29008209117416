import { useClient, useMutation, useQuery } from '@weenat/client'
import { useIntl } from '@weenat/wintl'
import { useNavigate } from 'app/routx-router'
import Button from 'app/src/kit/Button'
import Card from 'app/src/kit/Card'
import Text from 'app/src/kit/Text'
import { useLayoutEffect } from 'react'

const SmagConnect: FC = () => {
  const { t } = useIntl()
  const client = useClient()
  const nav = useNavigate()

  const buildUrlQuery = useQuery(client.smag.buildUrl())
  const infos = useQuery(client.smag.infos()).data
  const [dissociate] = useMutation(client.smag.dissociate())

  useLayoutEffect(() => {
    if (infos?.token && infos?.refreshToken && infos?.applicationId) {
      new SmagButton(
        '.exampleContainer',
        infos.token,
        infos.refreshToken,
        infos.applicationId,
        true,
        '',
        false
      )
    }
  }, [infos])

  return (
    <Card $my={'2xl'}>
      <Text $fontWeight='bold'>{t('smag.title')}</Text>
      <Box $mt={'xl'}>
        <Flex $alignItems='center' $mb={32}>
          <Box className='exampleContainer' $mr='md' />
          <Text>{t('smag.linkLabel')}</Text>
        </Flex>
        {buildUrlQuery.data && infos?.token === undefined ? (
          <Button
            onPress={() => {
              if (buildUrlQuery.data?.url) {
                nav(buildUrlQuery.data?.url)
              }
            }}
          >
            {t('smag.associate')}
          </Button>
        ) : (
          <Button
            onPress={() => {
              dissociate()
            }}
            isDanger
          >
            {t('smag.dissociate')}
          </Button>
        )}
      </Box>
    </Card>
  )
}

export default SmagConnect
