import { schemas } from '@weenat/client'
import { COUNTRIES_WITH_SUBSIDIARIES } from '@weenat/client/dist/core/billing'
import { useIntl } from '@weenat/wintl'
import { useFormikContext } from 'formik'
import isNil from 'lodash-es/isNil'
import Collapsible from '../Collapsible'
import TextField, { TextFieldProps } from './TextField'

const schema = schemas.billing.editBillingAccount

type EditBillingAccountFormValues = typeof schema.initialValues

const VATNumberField = (props: TextFieldProps) => {
  const { t } = useIntl()

  const { values } = useFormikContext<EditBillingAccountFormValues>()

  const isVatNumberVisible =
    !isNil(values.country) && !COUNTRIES_WITH_SUBSIDIARIES.includes(values.country)

  return isVatNumberVisible ? (
    <Collapsible isOpen={true} direction='vertical'>
      <TextField
        {...props}
        label={t('models.billingAccount.fields.vatNumber.label', { capitalize: true })}
      />
    </Collapsible>
  ) : null
}
export default VATNumberField
