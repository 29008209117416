import { Placement } from '@floating-ui/react'
import { useIntl } from '@weenat/wintl'
import useToasts from 'app/src/hooks/useToasts'
import { useCallback, useState } from 'react'
import Button from '../kit/Button'
import { IconProps } from '../kit/Icon'
import Icons from '../kit/Icons'
import TextTooltip from '../kit/tooltips/TextTooltip'

interface Props {
  onSuccess: PositionCallback
  tooltipPlacement?: Placement
  variant?: 'button' | 'icon'
  label?: string
  iconProps?: IconProps
}

const Geolocation = ({
  onSuccess,
  tooltipPlacement,
  variant = 'icon',
  label,
  iconProps
}: Props) => {
  const { t } = useIntl()
  const { addErrorToast } = useToasts()

  const [isPending, setIsPending] = useState(false)

  const handleGeolocationRequest = useCallback(() => {
    setIsPending(true)
    navigator.geolocation.getCurrentPosition(
      (args) => {
        setIsPending(false)
        onSuccess(args)
      },
      (error) => {
        setIsPending(false)

        addErrorToast(
          t(
            error.code === 1
              ? `os_permissions.location.unsufficient_permissions_on_browser`
              : error.code === 2
                ? `os_permissions.location.position_unavailable_error`
                : error.code === 3
                  ? `os_permissions.location.timeout_error`
                  : `errors.unknown`
          )
        )
      },
      { timeout: 60 * 1000, enableHighAccuracy: true, maximumAge: 15 * 60 * 1000 }
    )
  }, [onSuccess, addErrorToast, t])

  const finalLabel = t(`actions.locate_me`) ?? label

  return variant === 'icon' ? (
    <TextTooltip content={finalLabel} placement={tooltipPlacement}>
      <Icons.LocationFound
        $size={'xl'}
        $color={'primary.500'}
        onPress={handleGeolocationRequest}
        $isLoading={isPending}
        $m='sm'
        $rounded
        {...iconProps}
      />
    </TextTooltip>
  ) : (
    <Button
      importance='sd'
      onPress={handleGeolocationRequest}
      isLoading={isPending}
      IconRight={Icons.LocationFound}
    >
      {finalLabel}
    </Button>
  )
}

export default Geolocation
