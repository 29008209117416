import { useIntl } from '@weenat/wintl'
import { useField } from 'formik'
import { useState } from 'react'
import Icons from '../Icons'
import StrengthMeter from '../StrengthMeter'
import TextField, { CustomFieldProps } from './TextField'

type PasswordFieldProps = CustomFieldProps & { withStrengthMeter?: boolean }

const PasswordField: FC<PasswordFieldProps> = ({
  name = 'password',
  withStrengthMeter = true,
  errorPath,
  ...props
}) => {
  const [field] = useField(name)
  const { t } = useIntl()

  const [isPasswordHidden, setIsPasswordHidden] = useState(true)

  const TogglePasswordMaskIcon = isPasswordHidden ? Icons.VisibilityOn : Icons.VisibilityOff

  return (
    <TextField
      name={name}
      helpers={
        withStrengthMeter ? (
          <Box $mb='sm'>
            <StrengthMeter password={field.value} maxLength={15} minLength={8} />
          </Box>
        ) : undefined
      }
      maxLength={128}
      inputType='password'
      type={isPasswordHidden ? 'password' : 'text'}
      rightAdornment={
        <Box $pl='lg'>
          <TogglePasswordMaskIcon
            $size='md'
            onPress={() => {
              setIsPasswordHidden((x) => !x)
            }}
          />
        </Box>
      }
      label={t('models.account.fields.password.label', { capitalize: true })}
      {...props}
    />
  )
}

export default PasswordField
