import {
  BorderWidthToken,
  RadiusToken,
  ShadowPalette,
  SpacingToken,
  WeenatColor,
  WeenatTheme,
  fromColorPathToColor,
  isBorderWidthToken,
  isRadiusToken,
  isSpacingToken,
  themes
} from '@weenat/theme'
import isNil from 'lodash-es/isNil'
import { CSSProperties, css } from 'styled-components'

const { shadows } = themes.shared

type ChildrenProps = {
  children?: React.ReactNode
}

type MagicString = string & Record<never, never>

type JustifyContentProps = {
  $justifyContent?: CSSProperties['justifyContent']
}

type FlexDirectionProps = {
  $flexDirection?: CSSProperties['flexDirection']
}

type AlignItemsProps = {
  $alignItems?: CSSProperties['alignItems']
}

type FlexWrapProps = {
  $flexWrap?: CSSProperties['flexWrap']
}

type FlexBasisProps = {
  $flexBasis?: CSSProperties['flexBasis']
}

type FlexShrinkProps = {
  $flexShrink?: CSSProperties['flexShrink']
}

type AlignSelfProps = {
  $alignSelf?: CSSProperties['alignSelf']
}

type FlexShorthandProps = {
  /**
   * The flex CSS property specifies how a flex item will grow or shrink so as to fit the space available in
   * its flex container. This is a shorthand property that sets flex-grow, flex-shrink, and flex-basis.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/flex)
   */
  $flex?: CSSProperties['flex']
}

type GapProps = {
  $gap?: SpacingToken | CSSProperties['gap'] | undefined
}

type MarginVal = SpacingToken | CSSProperties['margin'] | undefined

type PaddingVal = SpacingToken | CSSProperties['padding'] | undefined

export type SpacingProps = {
  /** Margin on top, left, bottom and right */
  $m?: MarginVal
  /** Margin on top */
  $mt?: MarginVal
  /** Margin on right */
  $mr?: MarginVal
  /** Margin on bottom */
  $mb?: MarginVal
  /** Margin on left */
  $ml?: MarginVal
  /** Margin on left and right */
  $mx?: MarginVal
  /** Margin on top and bottom */
  $my?: MarginVal
  /** Padding on top, left, bottom and right */
  $p?: PaddingVal
  /** Padding on top */
  $pt?: PaddingVal
  /** Padding on right */
  $pr?: PaddingVal
  /** Padding on bottom */
  $pb?: PaddingVal
  /** Padding on left */
  $pl?: PaddingVal
  /** Padding on left and right */
  $px?: PaddingVal
  /** Padding on top and bottom */
  $py?: PaddingVal
}

type WidthVal = CSSProperties['width'] | undefined

type WidthProps = {
  /**
   *   The width utility parses a component's `width` prop and converts it into a CSS width declaration.
   *
   *   - Numbers from 0-1 are converted to percentage widths.
   *   - Numbers greater than 1 are converted to pixel values.
   *   - String values are passed as raw CSS values.
   *   - And arrays are converted to responsive width styles.
   */
  $width?: WidthVal
  $minWidth?: WidthVal
  $maxWidth?: WidthVal
}

type HeightVal = CSSProperties['height'] | undefined

type HeightProps = {
  $height?: HeightVal
  $minHeight?: HeightVal
  $maxHeight?: HeightVal
}

type ColorableProps = {
  $backgroundColor?: WeenatColor
  $color?: WeenatColor
}

interface BorderProps {
  $borderColor?: WeenatColor
  $borderWidth?: BorderWidthToken | number | MagicString | undefined
  $borderRadius?: RadiusToken | number | MagicString | undefined
}

interface ZIndexProps {
  $zIndex?: number
}

interface BoxShadowProps {
  $boxShadow?: keyof ShadowPalette | CSSProperties['boxShadow']
}

interface PositionProps {
  $position?: CSSProperties['position']
}

interface PointerEventsProps {
  $pointerEvents?: CSSProperties['pointerEvents']
}

interface OpacityProps {
  $opacity?: CSSProperties['opacity']
}

interface OverflowProps {
  $overflow?: CSSProperties['overflow']
  $overflowY?: CSSProperties['overflowY']
  $overflowX?: CSSProperties['overflowX']
}

interface CursorProps {
  $cursor?: CSSProperties['cursor']
}

interface DisplayProps {
  $display?: CSSProperties['display']
}

type BoxKnownProps = AlignSelfProps &
  BorderProps &
  BoxShadowProps &
  ChildrenProps &
  ColorableProps &
  CursorProps &
  DisplayProps &
  FlexShorthandProps &
  HeightProps &
  OpacityProps &
  OverflowProps &
  PointerEventsProps &
  PositionProps &
  SpacingProps &
  WidthProps &
  ZIndexProps

type FlexKnownProps = AlignItemsProps &
  BoxKnownProps &
  FlexBasisProps &
  FlexDirectionProps &
  FlexShrinkProps &
  FlexWrapProps &
  GapProps &
  JustifyContentProps

export type BoxProps = BoxKnownProps & Omit<React.HTMLProps<HTMLDivElement>, keyof BoxKnownProps>

export type FlexProps = FlexKnownProps & Omit<React.HTMLProps<HTMLDivElement>, keyof FlexKnownProps>

/** Shared helpers */

const flexShorthandCss = css<FlexProps>`
  ${(p) => (!isNil(p.$flex) ? `flex: ${p.$flex};` : '')}
`

function injectSpacingAttribute<T extends object>(
  propName: keyof T,
  cssName: string,
  p: { theme: WeenatTheme } & T
) {
  const value = p[propName]
  return !isNil(value)
    ? typeof value === 'string'
      ? isSpacingToken(value)
        ? `${cssName}: ${p.theme.spacings[value]}px;`
        : `${cssName}: ${p[propName]};`
      : `${cssName}:${p[propName]}px;`
    : ''
}

export function createSpacingAttribute<T extends object>(propName: keyof T, cssName: string) {
  return css<T>`
    ${(p) => injectSpacingAttribute(propName, cssName, p)}
  `
}

export const gapCss = createSpacingAttribute<GapProps>('$gap', 'gap')

const alignSelfCss = css<AlignSelfProps>`
  align-self: ${(p) => p.$alignSelf ?? 'auto'};
`

export const alignItemsCss = css<AlignItemsProps>`
  align-items: ${(p) => p.$alignItems ?? 'normal'};
`

export const justifyContentCss = css<JustifyContentProps>`
  justify-content: ${(p) => p.$justifyContent ?? 'normal'};
`

export const flexDirectionCss = css<FlexDirectionProps>`
  flex-direction: ${(p) => p.$flexDirection ?? 'row'};
`

export const flexWrapCss = css<FlexWrapProps>`
  ${(p) => (p.$flexWrap ? `flex-wrap: ${p.$flexWrap};` : '')}
`

export const flexBasisCss = css<FlexBasisProps>`
  ${(p) => (!isNil(p.$flexBasis) ? `flex-basis: ${p.$flexBasis};` : '')}
`

export const flexShrinkCss = css<FlexShrinkProps>`
  ${(p) => (!isNil(p.$flexShrink) ? `flex-shrink: ${p.$flexShrink};` : '')}
`

const colorCss = css<ColorableProps>`
  ${(p) => (!isNil(p.$color) ? `color: ${fromColorPathToColor(p.$color)};` : '')}
  ${(p) =>
    !isNil(p.$backgroundColor)
      ? `background-color: ${fromColorPathToColor(p.$backgroundColor)};`
      : ''}
`

const heightCss = css<HeightProps>`
  ${(p) =>
    !isNil(p.$height)
      ? `height: ${typeof p.$height === 'string' ? p.$height : `${p.$height}px`};`
      : ''}
  ${(p) =>
    !isNil(p.$minHeight)
      ? `min-height: ${typeof p.$minHeight === 'string' ? p.$minHeight : `${p.$minHeight}px`};`
      : ''}
  ${(p) =>
    !isNil(p.$maxHeight)
      ? `max-height: ${typeof p.$maxHeight === 'string' ? p.$maxHeight : `${p.$maxHeight}px`};`
      : ''}
`

const widthCss = css<WidthProps>`
  ${(p) =>
    !isNil(p.$width) ? `width: ${typeof p.$width === 'string' ? p.$width : `${p.$width}px`};` : ''}
  ${(p) =>
    !isNil(p.$minWidth)
      ? `min-width: ${typeof p.$minWidth === 'string' ? p.$minWidth : `${p.$minWidth}px`};`
      : ''}
  ${(p) =>
    !isNil(p.$maxWidth)
      ? `max-width: ${typeof p.$maxWidth === 'string' ? p.$maxWidth : `${p.$maxWidth}px`};`
      : ''}
`

const borderCss = css<BorderProps>`
  ${(p) =>
    !isNil(p.$borderWidth)
      ? `border: ${
          typeof p.$borderWidth === 'string'
            ? isBorderWidthToken(p.$borderWidth)
              ? `${p.theme.borderWidths[p.$borderWidth]}px`
              : `${p.$borderWidth}`
            : `${p.$borderWidth}px`
        } solid ${!isNil(p.$borderColor) ? fromColorPathToColor(p.$borderColor) : ''};`
      : ''}

  ${(p) =>
    !isNil(p.$borderRadius)
      ? `border-radius: ${
          typeof p.$borderRadius === 'string'
            ? isRadiusToken(p.$borderRadius)
              ? `${p.theme.radiuses[p.$borderRadius]}px`
              : p.$borderRadius
            : `${p.$borderRadius}px`
        };`
      : ''}
`

const zIndexCss = css<ZIndexProps>`
  ${(p) => (!isNil(p.$zIndex) ? `z-index: ${p.$zIndex};` : '')}
`

const positionCss = css<PositionProps>`
  ${(p) => (!isNil(p.$position) ? `position: ${p.$position};` : '')}
`

const overflowCss = css<OverflowProps>`
  ${(p) =>
    !isNil(p.$overflow)
      ? css`
          overflow: ${p.$overflow};
        `
      : ''}
  ${(p) =>
    !isNil(p.$overflowY)
      ? css`
          overflow-y: ${p.$overflowY};
        `
      : ''}
  ${(p) =>
    !isNil(p.$overflowX)
      ? css`
          overflow-x: ${p.$overflowX};
        `
      : ''}
`

const opacityCss = css<OpacityProps>`
  ${(p) =>
    !isNil(p.$opacity)
      ? css`
          opacity: ${p.$opacity};
        `
      : ''}
`

const boxShadowCss = css<BoxShadowProps>`
  ${(p) =>
    !isNil(p.$boxShadow)
      ? css`
          box-shadow: ${Object.keys(shadows).includes(p.$boxShadow)
            ? p.theme.shadows[p.$boxShadow].boxShadow
            : p.$boxShadow};
        `
      : ''}
`

const cursorCss = css<CursorProps>`
  ${(p) => (!isNil(p.$cursor) ? `cursor: ${p.$cursor};` : '')}
`

const pointerEventsCss = css<PointerEventsProps>`
  ${(p) => (!isNil(p.$pointerEvents) ? `pointer-events: ${p.$pointerEvents};` : '')}
`

const displayCss = css<DisplayProps>`
  ${(p) => (!isNil(p.$display) ? `display: ${p.$display};` : '')}
`

const spacingsCss = css<SpacingProps>`
  ${(p) => injectSpacingAttribute<SpacingProps>('$m', 'margin', p)}

  ${(p) => injectSpacingAttribute<SpacingProps>('$mb', 'margin-bottom', p)}
  ${(p) => injectSpacingAttribute<SpacingProps>('$mt', 'margin-top', p)}
  ${(p) => injectSpacingAttribute<SpacingProps>('$ml', 'margin-left', p)}
  ${(p) => injectSpacingAttribute<SpacingProps>('$mr', 'margin-right', p)}

  ${(p) => injectSpacingAttribute<SpacingProps>('$p', 'padding', p)}

  ${(p) => injectSpacingAttribute<SpacingProps>('$pb', 'padding-bottom', p)}
  ${(p) => injectSpacingAttribute<SpacingProps>('$pt', 'padding-top', p)}
  ${(p) => injectSpacingAttribute<SpacingProps>('$pl', 'padding-left', p)}
  ${(p) => injectSpacingAttribute<SpacingProps>('$pr', 'padding-right', p)}

  ${(p) =>
    !isNil(p.$mx)
      ? typeof p.$mx === 'string'
        ? isSpacingToken(p.$mx)
          ? `margin-left: ${p.theme.spacings[p.$mx]}px; margin-right: ${p.theme.spacings[p.$mx]}px;`
          : `margin-left: ${p.$mx}; margin-right: ${p.$mx};`
        : `margin-left: ${p.$mx}px; margin-right: ${p.$mx}px;`
      : ''}

  ${(p) =>
    !isNil(p.$my)
      ? typeof p.$my === 'string'
        ? isSpacingToken(p.$my)
          ? `margin-top: ${p.theme.spacings[p.$my]}px; margin-bottom: ${p.theme.spacings[p.$my]}px;`
          : `margin-top: ${p.$my}; margin-bottom: ${p.$my};`
        : `margin-top: ${p.$my}px; margin-bottom: ${p.$my}px;`
      : ''}

  ${(p) =>
    !isNil(p.$px)
      ? typeof p.$px === 'string'
        ? isSpacingToken(p.$px)
          ? `padding-left: ${p.theme.spacings[p.$px]}px; padding-right: ${
              p.theme.spacings[p.$px]
            }px;`
          : `padding-left: ${p.$px}; padding-right: ${p.$px};`
        : `padding-left: ${p.$px}px; padding-right: ${p.$px}px;`
      : ''}

  ${(p) =>
    !isNil(p.$py)
      ? typeof p.$py === 'string'
        ? isSpacingToken(p.$py)
          ? `padding-top: ${p.theme.spacings[p.$py]}px; padding-bottom: ${
              p.theme.spacings[p.$py]
            }px;`
          : `padding-top: ${p.$py}; padding-bottom: ${p.$py};`
        : `padding-top: ${p.$py}px; padding-bottom: ${p.$py}px;`
      : ''}
`

export const boxable = css<BoxKnownProps>`
  ${alignSelfCss}
  ${borderCss}
  ${boxShadowCss}
  ${colorCss}
  ${cursorCss}
  ${displayCss}
  ${flexShorthandCss}
  ${heightCss}
  ${opacityCss}
  ${overflowCss}
  ${pointerEventsCss}
  ${positionCss}
  ${spacingsCss}
  ${widthCss}
  ${zIndexCss}
`
