function SvgSwipeRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 24 24' {...props}>
      <path fill='none' d='M0 0h24v24H0z' />
      <path d='M13.9 11a5 5 0 100 2h4.27l-.88.88a.996.996 0 101.41 1.41l2.59-2.59a.996.996 0 000-1.41L18.7 8.7a.996.996 0 10-1.41 1.41l.88.89H13.9z' />
    </svg>
  )
}

export default SvgSwipeRight
