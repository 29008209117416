import isNil from 'lodash-es/isNil'
import { HTMLProps, useEffect, useRef } from 'react'
import { styled } from 'styled-components'

const StyledInput = styled.input<{ $isAutocomplete?: boolean }>`
  font-family: ${(p) => p.theme.typography.families.pm};
  font-size: ${(p) => p.theme.typography.sizes.rg};
  font-weight: ${(p) => p.theme.typography.weights.light};
  line-height: normal;
  color: ${(props) => props.theme.colors.grayscale.black};
  background-color: transparent;
  border: none;
  outline: none;
  pointer-events: ${(props) => (props.$isAutocomplete ? 'all' : 'none')};
  width: 100%;
`

interface InvisibleTextInputProps extends HTMLProps<HTMLInputElement> {
  shouldFocusOnMount?: boolean
}

const InvisibleTextInput: FC<InvisibleTextInputProps> = ({
  value,
  autoComplete,
  autoCorrect,
  shouldFocusOnMount,
  onChange,
  placeholder,
  id,
  name,
  ...props
}) => {
  const searchInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (shouldFocusOnMount && !isNil(searchInputRef.current)) {
      searchInputRef.current.focus()
    }
  }, [])

  return (
    <StyledInput
      id={id}
      name={name}
      ref={searchInputRef}
      autoComplete={autoComplete}
      autoCorrect={autoCorrect}
      type='text'
      value={value}
      $isAutocomplete={autoComplete === 'on'}
      onChange={onChange}
      placeholder={placeholder}
      {...props}
    />
  )
}

export default InvisibleTextInput
