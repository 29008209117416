import useDisclosure from 'app/src/hooks/useDisclosure'
import FeatureToggleModal from 'app/src/me/FeatureToggleModal'

const FeatureToggle = () => {
  const { isOpen, close } = useDisclosure(true)

  return <FeatureToggleModal isOpen={isOpen} close={close} />
}

export const Component = FeatureToggle
export default FeatureToggle
