import useVirtualDevice from '@weenat/client/dist/core/devices/useVirtualDevice'
import { BillingStatus } from '@weenat/client/dist/enums'
import { useIntl } from '@weenat/wintl'
import { useNavigate } from 'app/routx-router'
import useLogAndNavToUpgradePlans from 'app/src/hooks/useLogAndNavToUpgradePlans'
import Button from 'app/src/kit/Button'
import ChoiceCard from 'app/src/kit/ChoiceCard'
import DelimitedBox from 'app/src/kit/DelimitedBox'
import DelimitedFlex from 'app/src/kit/DelimitedFlex'
import Icons from 'app/src/kit/Icons'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import { plot_creation_href } from './constants'
import { PlotCreationStepProps } from './types'

const FLEXED_BUTTON = { flex: 1 }

type ChooseSourceStepProps = Pick<
  PlotCreationStepProps,
  'searchParams' | 'reason' | 'isRestricted' | 'org'
>

const ChooseSourceStep = ({ searchParams, reason, isRestricted, org }: ChooseSourceStepProps) => {
  const { t } = useIntl()
  const nav = useNavigate()

  const goToUpgradePlan = useLogAndNavToUpgradePlans()

  const { remainingVirtualDeviceActivations } = useVirtualDevice({
    orgId: org.id
  })

  const isVirtualWeatherActivationDisabled =
    !isNil(remainingVirtualDeviceActivations) && remainingVirtualDeviceActivations <= 0

  const isExpertOrg = org.billingStatus === BillingStatus.expert

  return (
    <Flex $flexDirection='column' $flex={1} $justifyContent='space-between' $gap={12}>
      <Text>{t('models.plot.creation.source_step_title')}</Text>
      {isRestricted && reason === 'downgrade' ? (
        <ChoiceCard
          icon={<Icons.Lock $size='lg' />}
          title={t('models.device.model.name')}
          body={t('ecommerce.plans.restrictions.carousel_value_downgrade_message')}
        >
          <DelimitedFlex $isDelimitedOnTop $p='lg' $justifyContent={'space-between'} $gap='md'>
            <Text $fontWeight='semiBold'>
              {t('ecommerce.plans.restrictions.carousel_value_downgrade_native_message')}
            </Text>
            <Icons.ArrowRight $size='lg' />
          </DelimitedFlex>
        </ChoiceCard>
      ) : isRestricted && reason === 'freemium' ? (
        <ChoiceCard
          $backgroundColor={'primary.500'}
          $borderColor={'primary.800'}
          icon={<Icons.Lock $size='lg' $color={'grayscale.white'} />}
          title={t('models.device.model.name')}
          body={t('models.plot.creation.source_step_device_choice_freemium')}
          onPress={goToUpgradePlan}
          color={'grayscale.white'}
        >
          <DelimitedFlex $isDelimitedOnTop $p='lg' $justifyContent={'space-between'} $gap='md'>
            <Text $fontWeight='semiBold' $color={'grayscale.white'}>
              {t('actions.i_subscribe')}
            </Text>
            <Icons.ArrowRight $size='lg' $color={'grayscale.white'} />
          </DelimitedFlex>
        </ChoiceCard>
      ) : (
        <ChoiceCard
          body={t('models.plot.creation.source_step_device_choice')}
          icon={<Icons.SingleSensorFilled $size='lg' />}
          onPress={() =>
            nav(plot_creation_href, {
              search: { ...searchParams, step: 'devicesSelection', source: 'devices' }
            })
          }
          title={t('models.device.model.name')}
        >
          <DelimitedFlex $isDelimitedOnTop $p='lg' $justifyContent={'space-between'} $gap='md'>
            <Text $fontWeight='semiBold'>{t('models.plot.creation.source_step_choice_cta')}</Text>
            <Icons.ArrowRight $size='lg' />
          </DelimitedFlex>
        </ChoiceCard>
      )}
      <ChoiceCard
        body={t('models.plot.creation.source_step_virtual_choice')}
        icon={<Icons.MeteoVision $size='lg' />}
        isDisabled={isVirtualWeatherActivationDisabled}
        onPress={() =>
          isVirtualWeatherActivationDisabled
            ? undefined
            : nav(plot_creation_href, {
                //clear devicesIds and depths if choosing vision
                search: {
                  ...searchParams,
                  step: 'submit',
                  source: 'vision',
                  selectedDeviceIds: undefined,
                  depths: undefined
                }
              })
        }
        title={
          isExpertOrg
            ? t('dataSources.meteo_vision', { capitalize: true })
            : t('dataSources.spatialized_weather', { capitalize: true })
        }
      >
        {!isNil(remainingVirtualDeviceActivations) ? (
          <DelimitedBox $isDelimitedOnTop $p='lg'>
            <Text>
              {t('models.plot.settings.spatial_weather_remaining_activations', {
                value: remainingVirtualDeviceActivations.toString()
              })}
            </Text>
          </DelimitedBox>
        ) : null}
        {isVirtualWeatherActivationDisabled ? null : (
          <DelimitedFlex $isDelimitedOnTop $p='lg' $justifyContent={'space-between'} $gap='md'>
            <Text $fontWeight='semiBold'>{t('models.plot.creation.source_step_choice_cta')}</Text>
            <Icons.ArrowRight $size='lg' />
          </DelimitedFlex>
        )}
      </ChoiceCard>
      <Flex $alignItems='center' $justifyContent='stretch' $py={12} $minHeight='auto'>
        <Button
          importance='sd'
          color='transparent'
          onPress={() => nav(plot_creation_href, { search: { ...searchParams, step: 'location' } })}
          style={FLEXED_BUTTON}
        >
          <Text $color='primary.500' $fontWeight='semiBold'>
            {t('actions.previous')}
          </Text>
        </Button>
      </Flex>
    </Flex>
  )
}

export default ChooseSourceStep
