import { useIntl } from '@weenat/wintl'
import { Head } from 'app/routx-router'
import ChangePassword from 'app/src/authentication/components/ChangePassword'
import DeleteMyAccount from 'app/src/me/DeleteMyAccount'
import MyEmail from 'app/src/me/MyEmail'
import SmagConnect from 'app/src/me/SmagConnect'
import { useToggleFeature } from 'app/state'

const Account = () => {
  const { t } = useIntl()
  const [features] = useToggleFeature()

  return (
    <>
      <Head title={t(`titles.my_account`)} />
      <MyEmail />
      <ChangePassword />
      {features.smagButton ? <SmagConnect /> : null}
      <DeleteMyAccount />
    </>
  )
}

export const Component = Account
export default Account
