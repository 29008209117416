import { getMetricNameForSelector } from '@weenat/client/dist/core/metrics'
import {
  getUniverseAggs,
  getUniverseMetrics,
  Universe,
  UniverseMetric
} from '@weenat/client/dist/core/universes'
import { isMetricThatNeedsToBeFromDeviceToBeDisplayedOnMap } from '@weenat/client/dist/enums/MetricIds'
import { DeviceAvailableMeasure } from '@weenat/client/dist/resources'
import {
  isDeviceSummary,
  isPlotSummary,
  isStationSummary,
  Summary
} from '@weenat/client/dist/resources/measurements.type'
import usePlotsOrDevicesFromPathname from 'app/src/hooks/usePlotsOrDevicesFromPathname'
import MetricIcons from 'app/src/kit/icons/MetricIcons'
import TextTooltip from 'app/src/kit/tooltips/TextTooltip'
import { useActiveMetricByUniverse, useUniverse, useUniverseAggregate } from 'app/state'
import { logSwitchUnivMetric } from 'app/utils/analytics'
import { intersection } from 'lodash-es'
import isNil from 'lodash-es/isNil'
import { memo, useEffect, useMemo } from 'react'
import { useTheme } from 'styled-components'
import { MAP_ICON_SIZE } from '../constants'
import ControlsContainer from '../ControlsContainer'

type UniverseMetricSelectorProps = { summaries: Summary[] }

const UniverseMetricSelector: FC<UniverseMetricSelectorProps> = ({ summaries }) => {
  const { colors } = useTheme()
  const [univ] = useUniverse()
  const [metricByUniv, setMetricByUniv] = useActiveMetricByUniverse()
  const [, setAggregate] = useUniverseAggregate()

  const metricsFromConfigs = useMemo(() => {
    const keysFromData: Set<string> = new Set()

    if (univ === Universe.irrelis) keysFromData.add('HDEF')

    summaries.forEach((sum) => {
      if (isPlotSummary(sum)) {
        const { currentMeasurementConfigs } = sum.metadata.plot

        currentMeasurementConfigs.forEach((conf) => {
          if (
            // we first check if we can add based on metric then if it's a metric that need to be from device we check that device id is not null
            !isMetricThatNeedsToBeFromDeviceToBeDisplayedOnMap(conf.metric) ||
            !isNil(conf.deviceId)
          ) {
            keysFromData.add(conf.metric)
          }
        })
      } else {
        let measures: DeviceAvailableMeasure[] = []

        if (isDeviceSummary(sum)) {
          const { availableMeasures } = sum.metadata.device
          measures = availableMeasures
        }
        if (isStationSummary(sum)) {
          const { availableMeasures } = sum.metadata.station
          measures = availableMeasures
        }

        measures.forEach((m) => keysFromData.add(m))
      }
    })

    return Array.from(keysFromData)
  }, [summaries, univ])

  const allUniverseMetrics = useMemo(() => getUniverseMetrics(univ), [univ])

  // metrics that are in both configs and possible universe metrics
  const enabledMetrics: UniverseMetric[] = useMemo(() => {
    return intersection(allUniverseMetrics, metricsFromConfigs)
  }, [allUniverseMetrics, metricsFromConfigs])

  const currentMetric = metricByUniv?.[univ]

  const plotsOrDevices = usePlotsOrDevicesFromPathname()

  const setUnivMetricAndLog = (metric: UniverseMetric) => {
    !isNil(plotsOrDevices) &&
      logSwitchUnivMetric({ action: `${plotsOrDevices}_switch_metrics`, selected: metric })
    if (metricByUniv?.[univ] !== metric) {
      setMetricByUniv((previousState) => ({ ...(previousState || {}), [univ]: metric }))
      const universeAggregate = getUniverseAggs(univ, metric)
      if (!isNil(universeAggregate)) {
        setAggregate(universeAggregate[0])
      }
    }
  }

  const handleResetMetric = () => {
    if (isNil(currentMetric) || !enabledMetrics.includes(currentMetric)) {
      setUnivMetricAndLog(enabledMetrics[0])
    }
  }

  useEffect(() => {
    handleResetMetric()
  }, [currentMetric, enabledMetrics])

  return (
    <Flex $justifyContent='flex-end' $alignItems='center' $gap='md'>
      {enabledMetrics.map((metric) => {
        const Icon = MetricIcons[metric]

        const isActive = metric === currentMetric

        const borderColor = isActive ? colors.metrics[metric][800] : colors.grayscale[100]
        const bgColor = isActive ? colors.metrics[metric]['500'] : colors.grayscale.white
        const color = isActive ? colors.grayscale.white : colors.grayscale.black

        const metricName = getMetricNameForSelector(metric)
        const isDisabled = !enabledMetrics.includes(metric)

        return !isNil(Icon) ? (
          <TextTooltip key={metric} content={metricName}>
            <ControlsContainer
              $borderColor={borderColor}
              $backgroundColor={bgColor}
              $borderRadius='md'
            >
              <Icon
                $size={MAP_ICON_SIZE}
                $color={color}
                onPress={() => {
                  setUnivMetricAndLog(metric)
                }}
                $isDisabled={isDisabled}
              />
            </ControlsContainer>
          </TextTooltip>
        ) : null
      })}
    </Flex>
  )
}

export default memo(UniverseMetricSelector)
