import { HorizonOpt } from '@weenat/client/dist/core/horizons'
import { useMetricProvider } from '@weenat/client/dist/core/metrics'
import { useToggleIsFavorite } from '@weenat/client/dist/core/plots'
import { getMetricLastUpdateFromSummary } from '@weenat/client/dist/core/summary'
import { UniverseMetric } from '@weenat/client/dist/core/universes'
import { isFertigationMetric, isFrost, isHydricStress } from '@weenat/client/dist/enums/MetricIds'
import { PlotSummary, isPlotSummary } from '@weenat/client/dist/resources/measurements.type'
import { isArvalisPlotSummary } from '@weenat/client/dist/resources/waterBalanceArvalis'
import { useIntl } from '@weenat/wintl'
import { useIsAppAccessRestricted } from 'app/src/hooks/useIsAppAccessRestricted'
import useToasts from 'app/src/hooks/useToasts'
import Card from 'app/src/kit/Card'
import DelimitedFlex from 'app/src/kit/DelimitedFlex'
import { IconProps } from 'app/src/kit/Icon'
import Icons from 'app/src/kit/Icons'
import Text from 'app/src/kit/Text'
import UpdateInformation, { UpdateInformationProps } from 'app/src/kit/UpdateInformation'
import TextTooltip from 'app/src/kit/tooltips/TextTooltip'
import isNil from 'lodash-es/isNil'
import { MouseEvent, ReactNode, memo, useCallback } from 'react'
import { styled } from 'styled-components'
import NoDataForHorizon from './NoDataForHorizon'
import PlotListItemMetricSummary from './plotListItem/PlotListItemMetricSummary'

const PLOT_CARD_HEADER_HEIGHT = 48
const PLOT_CARD_FOOTER_HEIGHT = 32

/** include padding */
const DEFAULT_PLOT_CARD_CONTENT_HEIGHT = 64 + 16
const HYDRIC_PLOT_CARD_CONTENT_HEIGHT = 80 + 16

export const DEFAULT_PLOT_CARD_HEIGHT =
  PLOT_CARD_HEADER_HEIGHT + PLOT_CARD_FOOTER_HEIGHT + DEFAULT_PLOT_CARD_CONTENT_HEIGHT
export const HYDRIC_PLOT_CARD_HEIGHT =
  PLOT_CARD_HEADER_HEIGHT + PLOT_CARD_FOOTER_HEIGHT + HYDRIC_PLOT_CARD_CONTENT_HEIGHT

const Titles = styled(Flex)`
  flex: 1;
  align-items: center;
  gap: 4px;
  overflow: hidden;
`

interface PlotCardHeaderProps {
  isFavorite: boolean
  isFavoriteRequestLoading: boolean
  name: string
  onFavoritePress: IconProps['onPress']
  Icon: ReactNode
}

const DumbPlotCardHeader: FC<PlotCardHeaderProps> = ({
  name,
  isFavoriteRequestLoading,
  isFavorite,
  onFavoritePress,
  Icon
}) => {
  const { t } = useIntl()
  return (
    <DelimitedFlex $alignItems='center' $p='md' $gap='lg'>
      <Titles>
        {Icon}
        <Text $flexed $fontWeight='bold' $ellipsis>
          {name}
        </Text>
      </Titles>
      <TextTooltip
        restMs={1000}
        content={t(`actions.${!isFavorite ? 'add_to_favorite' : 'remove_from_favorite'}`)}
      >
        <Icons.Star
          $size='lg'
          $isLoading={isFavoriteRequestLoading}
          onPress={onFavoritePress}
          $color={isFavorite ? 'primary.500' : 'grayscale.300'}
        />
      </TextTooltip>
    </DelimitedFlex>
  )
}

export const PlotCardHeader = memo(DumbPlotCardHeader)

const FooterContainer = styled(DelimitedFlex)`
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: ${(p) => p.theme.spacings.md}px;
`

type PlotCardFooter = UpdateInformationProps

const DumbPlotCardFooter: FC<PlotCardFooter> = ({
  warningThreshold,
  isVirtualMetric,
  timestamp,
  name
}) => {
  return (
    <FooterContainer $isDelimitedOnTop>
      <UpdateInformation
        timestamp={timestamp}
        isVirtualMetric={isVirtualMetric}
        warningThreshold={warningThreshold}
        name={name}
      />
    </FooterContainer>
  )
}

export const PlotCardFooter = memo(DumbPlotCardFooter)

export const StyledCard = styled(Card)<{ $isSelected: boolean }>`
  height: 100%;
  width: 100%;

  border-width: 2px;
  border-radius: ${(p) => p.theme.radiuses.lg}px;

  &:hover {
    border-color: ${(p) => p.theme.colors.primary[500]};
    cursor: pointer;
  }

  ${(p) =>
    p.$isSelected
      ? `
          border-color: ${p.theme.colors.primary[500]};
        `
      : ''}

  padding: 0;
  transition: border-color 0.3s ease-in-out;
  overflow: hidden;
`

interface PlotCardProps {
  availableHorizonOptions: HorizonOpt[]
  focusedHorizon: number | null
  focusedMetric: UniverseMetric
  hasNoDataForHorizon: boolean
  isSelected: boolean
  onHorizonChange: (newHorizon: number | null) => void
  summariesQueryCacheKey: string
  summary: PlotSummary
  isExpertOrg: boolean
}

const PlotCard: FC<PlotCardProps> = ({
  availableHorizonOptions = [],
  focusedHorizon = null,
  focusedMetric,
  hasNoDataForHorizon = false,
  isSelected = false,
  onHorizonChange,
  summariesQueryCacheKey,
  summary,
  isExpertOrg
}) => {
  const { t } = useIntl()
  const { addSuccessToast } = useToasts()

  const { name, isFavorite } = summary.metadata.plot

  const { mutation: toggleIsFavorite, request: isFavoriteRequest } = useToggleIsFavorite({
    summary,
    summariesQueryCacheKey,
    onSuccess: (data) => {
      addSuccessToast(
        t(
          data.isFavorite
            ? 'actions.add_plot_to_favorites_success'
            : 'actions.remove_plot_from_favorites_success',
          { plotName: name }
        )
      )
    }
  })

  const onFavoritePress = useCallback(
    (e: MouseEvent<Element>) => {
      // Otherwise we open the summary
      e.preventDefault()
      toggleIsFavorite()
    },
    [toggleIsFavorite]
  )

  const lastUpdate = getMetricLastUpdateFromSummary({
    summary,
    metric: focusedMetric,
    horizonId: focusedHorizon
  })

  const {
    isVirtualDevice,
    warningThreshold,
    name: providerName
  } = useMetricProvider({
    resource: summary.metadata.plot,
    metric: focusedMetric,
    isExpertOrg
  })

  const { isRestricted, reason } = useIsAppAccessRestricted()

  const isArvalisPlot = isArvalisPlotSummary(summary)
  const plotIsSetupForCurrentSeason = isArvalisPlot && summary.plotIsSetupForCurrentSeason
  const shouldConfigureArvalis = isArvalisPlot && !plotIsSetupForCurrentSeason
  const arvalisWBComputing = isArvalisPlot && summary.waterBalanceStatus === 'running'
  const arvalisWBError = isArvalisPlot && summary.waterBalanceStatus === 'error'
  const shouldConfigureMetric =
    isPlotSummary(summary) && !isNil(focusedMetric)
      ? summary.metadata.notConfigured?.includes(focusedMetric) ?? false
      : false

  let footer: ReactNode = (
    <PlotCardFooter
      timestamp={lastUpdate}
      isVirtualMetric={isVirtualDevice}
      warningThreshold={warningThreshold}
      name={providerName}
    />
  )
  if (hasNoDataForHorizon || isFrost(focusedMetric)) {
    footer = null
  } else if (shouldConfigureArvalis || shouldConfigureMetric) {
    footer = (
      <DelimitedFlex $isDelimitedOnTop $py={shouldConfigureMetric ? 'md' : 'lg'}>
        <Text $fontWeight='bold' style={{ margin: 'auto' }}>
          {t('models.option.actions.configure')}
        </Text>
      </DelimitedFlex>
    )
  }

  return (
    <StyledCard $isSelected={isSelected}>
      <PlotCardHeader
        isFavorite={isFavorite}
        isFavoriteRequestLoading={isFavoriteRequest.isPending}
        name={name}
        onFavoritePress={onFavoritePress}
        Icon={<Icons.PlotsFilled $size='lg' />}
      />

      {!isNil(focusedMetric) ? (
        isHydricStress(focusedMetric) && hasNoDataForHorizon ? (
          <NoDataForHorizon
            metric={focusedMetric}
            currentHorizon={focusedHorizon}
            onHorizonChange={onHorizonChange}
            availableHorizonOptions={availableHorizonOptions}
          />
        ) : (
          <Flex
            $flexDirection='column'
            $alignItems='center'
            $justifyContent='center'
            $flex={1}
            $px='md'
            $py={shouldConfigureArvalis ? 'xs' : 'md'}
          >
            {shouldConfigureArvalis ||
            arvalisWBComputing ||
            arvalisWBError ||
            shouldConfigureMetric ? (
              <Box $p='sm'>
                <Text $textAlign='center'>
                  {t(
                    shouldConfigureArvalis
                      ? 'dss.waterBalance.arvalis_plot_creation.not_configure_summary'
                      : arvalisWBComputing
                        ? 'dss.waterBalance.arvalis_plot_creation.computing'
                        : arvalisWBError
                          ? 'dss.waterBalance.arvalis_plot_creation.error_fetching'
                          : isFertigationMetric(focusedMetric)
                            ? 'models.plot.misc.setup_irrigation'
                            : 'errors.unknown'
                  )}
                </Text>
              </Box>
            ) : (
              <PlotListItemMetricSummary
                metric={focusedMetric}
                data={summary.data}
                focusedHorizon={focusedHorizon}
                isRestricted={isRestricted}
                reason={reason}
                isVirtualDevice={isVirtualDevice}
                provider={providerName}
              />
            )}
          </Flex>
        )
      ) : null}

      {footer}
    </StyledCard>
  )
}

export default memo(PlotCard)
