import { TKey, useIntl } from '@weenat/wintl'
import Icons from 'app/src/kit/Icons'
import Text from 'app/src/kit/Text'
import { styled } from 'styled-components'

const Container = styled.a`
  display: block;

  background-color: ${(p) => p.theme.colors.grayscale.white};
  border-radius: ${(p) => p.theme.radiuses.md}px;
  border: 1px solid ${(p) => p.theme.colors.grayscale[300]};

  &:hover {
    border-color: ${(p) => p.theme.colors.primary[500]};
  }
`

export interface HelpLinkProps {
  href: string
  message: TKey
  source: 'pdf' | 'youtube'
}

const HelpLink: FC<HelpLinkProps> = ({ href, message, source }) => {
  const { t } = useIntl()

  return (
    <Container href={href} target='_blank'>
      <Flex $alignItems='center' $p='md' $gap='md'>
        <Box>
          {source === 'pdf' ? (
            <Icons.PdfFile $size='lg' $p='md' $backgroundColor={'grayscale.100'} />
          ) : source === 'youtube' ? (
            <Icons.Youtube $size='lg' $p='md' $backgroundColor={'grayscale.100'} />
          ) : null}
        </Box>

        <Box $flex={1}>
          <Text $color='primary.500' $fontWeight='medium'>
            {t(message)}
          </Text>
        </Box>

        <Icons.OpenInNew $size='lg' $p='md' />
      </Flex>
    </Container>
  )
}

export default HelpLink
