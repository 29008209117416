import { mergeQueryState, useClient, useQuery } from '@weenat/client'
import { Device } from '@weenat/client/dist/resources/devices'
import { Horizon } from '@weenat/client/dist/resources/horizons'
import { RawPlotShape } from '@weenat/client/dist/resources/plots'
import { useIntl } from '@weenat/wintl'
import { useOrgContext } from 'app/orgProvider'
import { useNavigate, useSearchParams } from 'app/routx-router'
import ControlsContainer from 'app/src/dashboard/components/DashboardMap/ControlsContainer'
import {
  useBackgroundMapContext,
  useBackgroundMapDispatcher
} from 'app/src/dashboard/components/DashboardMap/contexts/BackgroundMapContext'
import DeviceMarker from 'app/src/dashboard/components/DashboardMap/markers/DeviceMarker'
import DevicesClusterMarker from 'app/src/dashboard/components/DashboardMap/markers/DevicesClusterMarker'
import FocusedDeviceDetailsCard from 'app/src/devices/FocusedDeviceDetailsCard'
import Card from 'app/src/kit/Card'
import CloseButton from 'app/src/kit/CloseButton'
import DelimitedFlex from 'app/src/kit/DelimitedFlex'
import Icons from 'app/src/kit/Icons'
import Text from 'app/src/kit/Text'
import { TextFieldPrimitive } from 'app/src/kit/fields/TextField'
import Box from 'app/src/kit/primitives/Box'
import Flex from 'app/src/kit/primitives/Flex'
import CustomMarker from 'app/src/map/CustomMarker'
import Geolocation from 'app/src/map/Geolocation'
import {
  GoogleMapApi,
  LatLng,
  fromLngLatArrayToObject,
  getShapeOptionsFromTheme
} from 'app/src/map/utils'
import SelectDevicesStep from 'app/src/plots/creation/SelectDevicesStep'
import {
  FOCUSED_MARKER_WIDTH,
  MARKER_WIDTH,
  plot_modification_href
} from 'app/src/plots/creation/constants'
import {
  GeoJSON,
  PlotCreationSearchParams,
  PlotCreationStepProps,
  plotCreationSearchParamsSchema
} from 'app/src/plots/creation/types'
import {
  Point,
  getLatLngFromShape,
  isDeviceFocused,
  isDeviceSelected
} from 'app/src/plots/creation/utils'
import isNil from 'lodash-es/isNil'
import noop from 'lodash-es/noop'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDeepCompareEffect } from 'react-use'
import { FixedSizeList } from 'react-window'
import { keyframes, styled, useTheme } from 'styled-components'
import Supercluster from 'supercluster'
import useSupercluster from 'use-supercluster'

const CONTROLS_ICONS_SIZE = 'lg'

const EMPTY_DEVICES: Device[] = []
const GOOGLE_PLACES_ID = 'googlePlacesAutocompleteInput'

interface DeviceAdditionalProps {
  onPress: (device: Device) => void
  idx: number
  isSelected: boolean
  isFocused: boolean
}

const blink = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const renderCluster =
  (
    map: GoogleMapApi['map'],
    supercluster: Supercluster<Device & DeviceAdditionalProps, Supercluster.AnyProps> | undefined,
    step: PlotCreationSearchParams['step']
  ) =>
  (
    cluster:
      | Supercluster.PointFeature<Device & DeviceAdditionalProps>
      | Supercluster.PointFeature<Supercluster.ClusterProperties & Supercluster.AnyProps>
  ) => {
    const { properties } = cluster
    if ('cluster' in properties) {
      return (
        <CustomMarker
          key={properties.cluster_id}
          lat={cluster.geometry.coordinates[1]}
          lng={cluster.geometry.coordinates[0]}
          size={40}
          height={22}
          zIndex={1}
          onClick={() => {
            map?.panTo(fromLngLatArrayToObject(cluster.geometry.coordinates as [number, number]))
            if (!isNil(supercluster)) {
              map?.setZoom(supercluster.getClusterExpansionZoom(properties.cluster_id))
            }
          }}
        >
          <DevicesClusterMarker count={properties.point_count} />
        </CustomMarker>
      )
    } else {
      const device = properties
      const { location } = device
      return location != null ? (
        <CustomMarker
          key={device.id}
          lat={location.coordinates[1]}
          lng={location.coordinates[0]}
          onClick={() => device.onPress(device)}
          size={device.isFocused ? FOCUSED_MARKER_WIDTH : MARKER_WIDTH}
          height={device.isFocused ? 48 : undefined}
          zIndex={device.isFocused ? 999999 : device.isSelected ? 9999 : 0}
        >
          <DeviceMarker
            key={`${device.id}_${device.isSelected}`}
            isSelected={device.isSelected}
            isFocused={device.isFocused}
            step={step}
            device={device}
          />
        </CustomMarker>
      ) : null
    }
  }

const PlacesSearch = styled(TextFieldPrimitive)`
  margin: 0;
  max-width: 300px;
  pointer-events: auto;
  height: fit-content;
  &:has(input:focus) {
    div {
      animation: ${blink} 0.4s ease-in-out 2;
    }
  }
`

const RightToolsBar = styled(Flex)`
  position: absolute;
  right: 16px;
  top: 0px;
  height: 100%;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  pointer-events: auto;
`

const EMPTY_HORIZONS: Horizon[] = []

const ZOOM_ON_FOCUS = 18

const Component = () => {
  const theme = useTheme()
  const { t } = useIntl()
  const nav = useNavigate()
  const client = useClient()
  const { currentOrgId, org } = useOrgContext()
  const [searchParams, setSearchParams] = useSearchParams(plotCreationSearchParamsSchema)

  const [shape, setShape] = useState<google.maps.Polygon | google.maps.Circle | undefined>()
  const [isPlotInsideBoundaries, setIsPlotInsideBoundaries] = useState(true)
  const [location, setLocation] = useState<LatLng | undefined>(undefined)

  // we use a ref to keep track of searchParams without rerender
  // and without modifying callback this way callback could be the same across rerender
  // which allow us to avoid rerender in list items
  const lastVersionSearchParams = useRef<typeof plotCreationSearchParamsSchema._type | null>(null)
  lastVersionSearchParams.current = searchParams

  const autocompleteInputRef = useRef<HTMLInputElement>(null)
  const listRef = useRef<FixedSizeList<Device>>(null)

  const { api, mapDetails } = useBackgroundMapContext<
    | Supercluster.PointFeature<Device & DeviceAdditionalProps>
    | Supercluster.PointFeature<Supercluster.ClusterProperties & Supercluster.AnyProps>
  >()

  const dispatch = useBackgroundMapDispatcher()

  const plotRequest = useQuery(client.plots.get(searchParams.plotId), {
    enabled: !isNil(searchParams.plotId)
  })
  const plot = plotRequest.data

  const add = useCallback(
    (deviceId: number, depthValue?: number, horizonId?: number) => {
      setSearchParams({
        ...(lastVersionSearchParams.current ?? {}),
        selectedDeviceIds: [
          ...(lastVersionSearchParams.current?.selectedDeviceIds ?? []),
          deviceId
        ],
        depths: isNil(depthValue)
          ? lastVersionSearchParams.current?.depths
          : [
              ...(lastVersionSearchParams.current?.depths ?? []),
              `${deviceId}_${depthValue}_${horizonId}`
            ]
      })
    },
    [setSearchParams]
  )

  const remove = useCallback(
    (deviceId: number) => {
      setSearchParams({
        ...(lastVersionSearchParams.current ?? {}),
        selectedDeviceIds:
          lastVersionSearchParams.current?.selectedDeviceIds?.filter((id) => deviceId !== id) ??
          undefined,
        depths:
          lastVersionSearchParams.current?.depths?.filter(
            (depth) => !depth.includes(`${deviceId}`)
          ) ?? undefined
      })
    },
    [setSearchParams]
  )

  const search = useCallback(
    (newVal: string) => {
      setSearchParams({
        ...(lastVersionSearchParams.current ?? {}),
        q: newVal
      })
    },
    [setSearchParams]
  )

  const setOrder: PlotCreationStepProps['setOrder'] = useCallback(
    (sortBy, sortOrder) => {
      setSearchParams({
        ...(lastVersionSearchParams.current ?? {}),
        sortBy,
        sortOrder
      })
      listRef.current?.scrollToItem(0)
    },
    [setSearchParams]
  )

  const setFilter: PlotCreationStepProps['setFilter'] = useCallback(
    (newVals) => {
      setSearchParams({
        ...(lastVersionSearchParams.current ?? {}),
        ...newVals
      })
      listRef.current?.scrollToItem(0)
    },
    [setSearchParams]
  )

  const focus = useCallback(
    (deviceId: number) => {
      setSearchParams({
        ...(lastVersionSearchParams.current ?? {}),
        focusedDevice: deviceId
      })
    },
    [setSearchParams]
  )

  const devicesRequest = useQuery(
    client.devices.getAll({
      viewableByOrganizationId: currentOrgId as number,
      distanceFrom: location,
      q: searchParams.q,
      sortBy: searchParams.sortBy as 'distance_from',
      sortOrder: searchParams.sortOrder as 'asc',
      brand: searchParams.brand,
      metrics: searchParams.metrics as ['T'],
      ownedByOrganizationId: searchParams.ownedByOrganizationId
    }),
    {
      enabled: !isNil(currentOrgId)
    }
  )

  const devices: Device[] = devicesRequest.data ?? EMPTY_DEVICES

  const focusedDevice = devices.find(
    (d) => d.id === parseInt(searchParams.focusedDevice as unknown as string, 10)
  )

  const { id: closestDeviceId } = devices.reduce(
    (acc, device) =>
      (device.distanceFrom ?? Number.MAX_SAFE_INTEGER) < acc.distance
        ? { distance: device.distanceFrom as number, id: device.id }
        : acc,
    { distance: Number.MAX_SAFE_INTEGER, id: 0 }
  )

  const horizonsRequest = useQuery(client.horizons.getAll())
  const horizons = horizonsRequest.data?.results || EMPTY_HORIZONS

  const points: Supercluster.PointFeature<Device & DeviceAdditionalProps>[] = useMemo(() => {
    return devices.reduce(
      (acc, device, idx) => {
        if (device.location) {
          acc.push({
            geometry: { type: 'Point', coordinates: device.location?.coordinates },
            type: 'Feature',
            properties: {
              ...device,
              isSelected: isDeviceSelected(searchParams, device),
              isFocused: isDeviceFocused(searchParams, device) ?? false,
              onPress: () => {
                listRef.current?.scrollToItem(idx, 'start')
                setSearchParams({ ...searchParams, focusedDevice: device.id })
              },
              idx
            }
          })
        }
        return acc
      },
      [] as Supercluster.PointFeature<Device & DeviceAdditionalProps>[]
    )
  }, [devices, searchParams, setSearchParams])

  const { clusters, supercluster } = useSupercluster<Device & DeviceAdditionalProps>({
    points: points,
    zoom: !isNil(mapDetails.zoom) ? mapDetails.zoom : api.map?.getZoom() ?? 8,
    bounds: !isNil(mapDetails.bbox) ? mapDetails.bbox : undefined
  })

  const onGeolocationSuccess: PositionCallback = ({ coords }) => {
    if (!isNil(coords)) {
      nav(plot_modification_href, { search: { ...searchParams } })
      dispatch({
        type: 'setGeolocation',
        newGeolocation: { lat: coords.latitude, lng: coords.longitude }
      })
    }
  }

  const currentBounds = !isNil(api.map) ? api.map.getBounds() : undefined
  const { isLoading } = mergeQueryState(devicesRequest, plotRequest)

  useEffect(() => {
    const {
      latitude,
      longitude
    }: {
      latitude: number | undefined
      longitude: number | undefined
      geoJSON: GeoJSON | Point | undefined
    } = getLatLngFromShape(shape)

    if (!isNil(latitude) && !isNil(longitude)) {
      setLocation({ lat: latitude, lng: longitude })
    }
  }, [shape])

  // Setting up onChange callback in context when location changes
  useEffect(() => {
    if (!isNil(location?.lat) && !isNil(location.lng)) {
      const isInsideBoundaries = !isNil(currentBounds)
        ? currentBounds.contains({ lat: location.lat, lng: location.lng })
        : false

      // The point is in the box
      setIsPlotInsideBoundaries(isInsideBoundaries)
    }
  }, [location, currentBounds])

  // Initializing drawingManager when api is initialized
  useEffect(() => {
    let listener: google.maps.MapsEventListener | undefined

    if (!isNil(api.map)) {
      listener = api.map.addListener('click', () => {
        setSearchParams({ ...lastVersionSearchParams.current, focusedDevice: undefined })
      })
    }

    return () => {
      if (!isNil(listener)) {
        listener.remove()
      }
    }
  }, [api.map, api.maps])

  // Initializing places listeners when api is initialized
  useEffect(() => {
    let placesAutocompleteListener: google.maps.MapsEventListener | undefined
    let placesAutocompletTimeout: string | number | undefined

    if (!isNil(autocompleteInputRef.current) && !isNil(api.maps) && !isNil(api.map)) {
      const googlePlacesAutocompleteInput = new api.maps.places.Autocomplete(
        autocompleteInputRef.current
      )

      placesAutocompleteListener = api.maps.event.addListener(
        googlePlacesAutocompleteInput,
        'place_changed',
        () => {
          const { geometry: { location } = {} } = googlePlacesAutocompleteInput.getPlace()
          if (!isNil(location)) {
            api.map?.panTo(location)
            api.map?.setZoom(17)
          }
        }
      )
    }

    return () => {
      if (!isNil(placesAutocompleteListener)) {
        api.maps?.event.removeListener(placesAutocompleteListener)
      }

      if (!isNil(placesAutocompletTimeout)) {
        clearTimeout(placesAutocompletTimeout)
      }
    }
  }, [api.map, api.maps])

  // Updating context when cluster are changed
  useDeepCompareEffect(() => {
    dispatch({
      type: 'setMarkers',
      newMarkers: clusters
    })
  }, [clusters])

  // Updating context renderCluster arguments are changed
  useEffect(() => {
    dispatch({
      type: 'setRenderMarkers',
      newRenderMarkers: renderCluster(api.map, supercluster, searchParams.step)
    })
  }, [api.map, supercluster])

  // Listening for change on searchParams.step
  useEffect(() => {
    const defaultedSearchParams = { ...searchParams }

    if (isNil(searchParams.sortBy)) {
      defaultedSearchParams.sortBy = 'distance_from'
      defaultedSearchParams.sortOrder = 'asc'
    }

    nav(plot_modification_href, { search: defaultedSearchParams, replace: true })
  }, [searchParams.step])

  // If the focusedDevice changes we pan the map to the focused device location
  useEffect(() => {
    if (
      !isNil(api.map) &&
      !isNil(api.maps) &&
      !isNil(focusedDevice) &&
      !isNil(focusedDevice.location)
    ) {
      const newPosition = fromLngLatArrayToObject(
        focusedDevice.location?.coordinates as [number, number]
      )
      api.map.panTo(newPosition)
      api.map.setZoom(ZOOM_ON_FOCUS)
    }
  }, [focusedDevice])

  // get the shape if a plotId is defined
  useEffect(() => {
    function createNewShape(plotShape: RawPlotShape | undefined) {
      const googleMapsShapeOptions = getShapeOptionsFromTheme(theme)

      let points = null

      if (isNil(plotShape)) return null

      if (plotShape.type === 'Polygon') {
        points = plotShape.coordinates[0].map((p) => {
          return {
            lat: p[1],
            lng: p[0]
          }
        })
        return new google.maps.Polygon({
          ...googleMapsShapeOptions,
          paths: points
        })
      } else if (plotShape.type === 'Point') {
        points = {
          lat: plotShape.coordinates[1],
          lng: plotShape.coordinates[0]
        }
        return new google.maps.Circle({
          ...googleMapsShapeOptions,
          radius: 80,
          center: points
        })
      } else {
        return null
      }
    }

    const plotShape = plot?.shape

    if (!isNil(plotShape) && api?.maps) {
      const newShape = createNewShape(plotShape)

      if (newShape) {
        setShape(newShape)
        newShape.setMap(api.map)
      } else {
        setShape(undefined)
      }

      if (!isNil(api.map) && !isNil(api.maps) && !isNil(plot?.location?.coordinates)) {
        api.map.panTo(fromLngLatArrayToObject(plot?.location?.coordinates))
        api.map.setZoom(ZOOM_ON_FOCUS)
      }
    }
  }, [plot, api?.map, api?.maps])

  return (
    <>
      {/* Overlay with all devices, stepper, input search and google controller */}
      <Flex $flexDirection='column' $width='100%' $height='100%' $p='lg' $gap='lg'>
        <Box
          $height='fit-content'
          $width='100%'
          $zIndex={1}
          $position='relative'
          $pointerEvents='none'
        >
          <Box $backgroundColor='primary.500' $width='fit-content' $p='md' $borderRadius='sm'>
            <Text $fontSize='sm' $color={'grayscale.white'}>
              {org?.name}
            </Text>
            <Text $fontSize='sm' $color={'grayscale.white'}>
              {plot?.name}
            </Text>
          </Box>
        </Box>
        <Flex
          $flex={1}
          $zIndex={1}
          $position='relative'
          $pointerEvents='none'
          $gap={12}
          $width='100%'
        >
          <Flex $flexDirection='column' $width={420}>
            <Card
              $boxShadow='md'
              $p={0}
              $backgroundColor='grayscale.50'
              $pointerEvents='auto'
              $flex={1}
            >
              <Flex $alignItems='center' $p='lg' $gap='md'>
                <Box $flex={1}>
                  <Text $fontSize='md' $fontWeight='bold'>
                    {t('models.plot.edition.title', { capitalize: true })}
                  </Text>
                </Box>
                <CloseButton
                  $size='lg'
                  $p='md'
                  onPress={() => {
                    nav(`/administration/${org?.id}/plots/${searchParams.plotId}/devices`)
                  }}
                />
              </Flex>
              <DelimitedFlex />
              <Flex $flexDirection='column' $flex={1} $p='lg' style={{ overflowY: 'auto' }}>
                {org && (
                  <SelectDevicesStep
                    searchParams={searchParams}
                    remove={remove}
                    add={add}
                    focus={focus}
                    search={search}
                    setOrder={setOrder}
                    setFilter={setFilter}
                    org={org}
                    plot={plot}
                    devices={devices}
                    devicesLoading={isLoading}
                    listRef={listRef}
                    closestDeviceId={closestDeviceId}
                    horizons={horizons}
                  />
                )}
              </Flex>
            </Card>
          </Flex>
          <Flex $flexDirection='column' $gap={12} $width={300}>
            <Box $minHeight='auto'>
              <PlacesSearch
                id={GOOGLE_PLACES_ID}
                type='text'
                ref={autocompleteInputRef}
                placeholder={t('actions.search_city')}
                // The input is "controlled" by google maps, no need for an onChange handler
                onChange={noop}
                marginHeight={0}
              />
            </Box>
            {!isNil(focusedDevice) && (
              <FocusedDeviceDetailsCard
                device={focusedDevice}
                add={add}
                remove={remove}
                searchParams={searchParams}
                billingStatus={org?.billingStatus}
                horizons={horizons}
              />
            )}
          </Flex>
          <RightToolsBar>
            <ControlsContainer $flexDirection='column' $p={'sm'} $borderRadius={'md'}>
              <Icons.PlusSign
                onPress={() => api.map?.setZoom((api.map.getZoom() ?? 0) + 1)}
                $p={'md'}
                $size={CONTROLS_ICONS_SIZE}
                $m={0}
              />
              <Icons.MinusSign
                $p={'md'}
                $size={CONTROLS_ICONS_SIZE}
                $m={0}
                onPress={() => api.map?.setZoom((api.map.getZoom() ?? 0) - 1)}
              />
            </ControlsContainer>
            <ControlsContainer $flexDirection='column' $p={'sm'} $borderRadius={'md'}>
              <Geolocation
                onSuccess={onGeolocationSuccess}
                iconProps={{ $size: CONTROLS_ICONS_SIZE, $p: 'md', $m: 0, $rounded: false }}
              />
            </ControlsContainer>
            {!isPlotInsideBoundaries ? (
              <ControlsContainer $flexDirection='column' $p={'sm'} $borderRadius={'md'}>
                <Icons.FocusPin
                  $size={CONTROLS_ICONS_SIZE}
                  onPress={() => {
                    if (!isNil(api.map) && !isNil(api.maps) && !isNil(location)) {
                      const latLngBounds = new api.maps.LatLngBounds(location)
                      api.map.fitBounds(latLngBounds)
                      api.map.setZoom(ZOOM_ON_FOCUS)
                    }
                  }}
                />
              </ControlsContainer>
            ) : null}
          </RightToolsBar>
        </Flex>
      </Flex>
    </>
  )
}

export default Component
