import {
  GeneratedTypes,
  HeadProps,
  LinkProps,
  RedirectProps,
  SearchParamsEncoding,
  UseMatchFunction,
  UseNavigateFunction,
  UsePathnameFunction,
  joinToAndSearchParams,
  makeResolvePathPattern,
  makeUseProtectedRoute,
  makeUseSearchParams,
  matchPath,
  parseParams
} from '@weenat/client/dist/routx/runtime-core'
import { Helmet } from 'react-helmet-async'
import * as RR from 'react-router-dom'
import { paramsParserMap, pathPatternResolver, publicPathPatterns } from '../router-meta'
const ENCODING = SearchParamsEncoding.Json
export const Redirect = (props: RedirectProps) => {
  return <RR.Navigate to={props.to} replace={props.replace} />
}
export const resolvePathPattern = makeResolvePathPattern(pathPatternResolver)
export const Head = ({ title, ...props }: HeadProps) => {
  return <Helmet title={title} {...props} />
}

export const useParams = () => {
  const rawParams = RR.useParams()
  return parseParams(rawParams as Record<string, string>, paramsParserMap)
}

export const usePathname: UsePathnameFunction = () => {
  const pathnameWithMaybeTrailingSlash = RR.useLocation().pathname

  return (
    pathnameWithMaybeTrailingSlash.endsWith('/') && pathnameWithMaybeTrailingSlash.length > 1
      ? pathnameWithMaybeTrailingSlash.slice(0, -1)
      : pathnameWithMaybeTrailingSlash
  ) as GeneratedTypes['AbsoluteHref']
}

export const useNavigate: UseNavigateFunction = () => {
  const rrNav = RR.useNavigate()

  return (to, opts) => {
    if (typeof to === 'number') {
      rrNav(to)
    } else {
      rrNav(
        joinToAndSearchParams(
          to,
          // @ts-expect-error this is ok
          opts?.search,
          ENCODING
        ),
        { replace: opts?.replace }
      )
    }
  }
}

export const useProtectedRoute = makeUseProtectedRoute(
  '/auth/login',
  '/',
  usePathname,
  useNavigate,
  publicPathPatterns
)

export const { Outlet: Slot, RouterProvider } = RR
/**
 * TODO: maybe we should only rely on {@link matchPath}
 */
export const useMatch: UseMatchFunction = <P extends GeneratedTypes['PathPattern']>(pattern: P) => {
  const pathname = usePathname()
  return matchPath(pattern, pathname)
}

export const useSearchParams = makeUseSearchParams(ENCODING, RR.useSearchParams)

export const Link: FC<LinkProps> = ({ search, to, ...p }) => {
  const finalTo = joinToAndSearchParams(
    to,
    // @ts-expect-error this is ok
    search,
    ENCODING
  )
  return <RR.NavLink to={finalTo} {...p} />
}
