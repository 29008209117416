import { CalendarEventType } from '@weenat/client/dist/core/calendars'
import Text from 'app/src/kit/Text'
import { transparentize } from 'polished'
import { styled } from 'styled-components'

const EventContainer = styled(Flex)`
  align-items: center;
  padding: 4px;
  margin-top: 4px;
  width: 100%;
  border-radius: ${(p) => p.theme.radiuses.sm}px;

  &:hover {
    background-color: ${(props) => transparentize(0.5, props.theme.colors.grayscale[300])};
    cursor: pointer;
  }
`

interface CalendarEventProps {
  event: CalendarEventType
  onPress: (event: CalendarEventType) => void
}

const CalendarEvent: FC<CalendarEventProps> = ({ event, onPress }) => {
  return (
    <EventContainer
      onClick={(e) => {
        onPress(event)
        e.stopPropagation()
      }}
      key={event.id}
    >
      <Box $width={8} $height={8} $borderRadius='xl' $backgroundColor={event.color} />
      <Box $ml='sm'>
        <Text $fontSize='sm'>{event.name}</Text>
      </Box>
    </EventContainer>
  )
}

export default CalendarEvent
