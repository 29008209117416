import { useIntl } from '@weenat/wintl'
import { useNavigate } from 'app/routx-router'
import { str } from 'app/state'
import { styled, useTheme } from 'styled-components'
import useGoBack from '../hooks/useGoBack'
import Badge from '../kit/Badge'
import Button from '../kit/Button'
import Text from '../kit/Text'
import Cloudy, { CloudyMood } from '../kit/icons/Cloudy'

const Background = styled(Box)`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${(p) => p.theme.colors.grayscale[50]};
`

const StretchedButton = styled(Button)`
  width: 100%;
`

interface ErrorScreenProps {
  status?: string | number
}

export const ErrorComponent: FC<ErrorScreenProps> = ({ status = 999 }) => {
  const { colors } = useTheme()
  const nav = useNavigate()
  const { goBack } = useGoBack()
  const { t } = useIntl()

  const finalStatus = typeof status === 'string' ? parseInt(status, 10) : status

  let errorName: string
  let errorDescription: string
  let cloudyMood: CloudyMood = 'sad'

  switch (finalStatus) {
    case 401:
      errorName = t('errors.no_session_error')
      errorDescription = t('errors.no_session_error_explained')
      break
    case 403:
      errorName = t('errors.access_denied')
      errorDescription = t('errors.access_denied_explained')
      break
    case 404:
      errorName = t('errors.page_not_found')
      errorDescription = t('errors.page_not_found_explained')
      cloudyMood = 'confused'
      break
    case 503:
    case 502:
      errorName = t('errors.server_connection_error')
      errorDescription = t('errors.server_connection_error_explained')
      break
    // 400, 405, 429, 500,
    default:
      errorName = t('errors.unknown')
      errorDescription = t('errors.unknown_explained')
      break
  }

  const CloudyIcon = Cloudy[cloudyMood]

  const flushLocalStorage = async () => {
    const keysToNotFlush = ['lightTour', 'clusterSettings', 'features', 'token']

    await str.flush({ except: keysToNotFlush })
  }

  return (
    <Flex
      $alignItems='center'
      $justifyContent='center'
      $flexDirection='column'
      $p='lg'
      $pt={82}
      $width={'100%'}
      $height={'100%'}
    >
      <Flex
        $alignItems='center'
        $justifyContent='flex-start'
        $flexDirection='column'
        $width={480}
        $gap='xl'
      >
        <Flex
          $alignItems='center'
          $justifyContent='center'
          $backgroundColor={colors.primary[200]}
          $borderRadius={16}
          $width={'100%'}
          $height={256}
        >
          <CloudyIcon $size='2xl' />
        </Flex>
        <Flex $alignItems='center' $justifyContent='flex-start' $flexDirection='column' $gap='md'>
          <Badge $py={6} $px={12} $backgroundColor='primary.200' $borderRadius='sm'>
            <Text $fontSize='md' $fontFamily='numbers' $color='primary.500'>
              {finalStatus}
            </Text>
          </Badge>
          <Text $fontSize='lg' $lineHeight='lg' $fontWeight='bold' $textAlign='center'>
            {errorName}
          </Text>
          <Text $textAlign='center' $lineHeight='md'>
            {errorDescription}
          </Text>
        </Flex>
        <StretchedButton
          importance='sd'
          onPress={() => {
            flushLocalStorage()
            goBack()
          }}
        >
          {t('actions.go_back')}
        </StretchedButton>
        <StretchedButton
          onPress={() => {
            flushLocalStorage()
            nav('/plots')
          }}
        >
          {t('actions.go_home')}
        </StretchedButton>
      </Flex>
    </Flex>
  )
}

const ErrorScreen: FC<ErrorScreenProps> = ({ status }) => {
  return (
    <Background>
      <ErrorComponent status={status} />
    </Background>
  )
}

export default ErrorScreen
