import { useIntl } from '@weenat/wintl'
import CheckboxField from './CheckboxField'
import { CustomFieldProps } from './TextField'

const IsSubscribedToNewsletterField: FC<CustomFieldProps> = ({
  name = 'isSubscribedToNewsletter',
  ...props
}) => {
  const { t } = useIntl()

  return <CheckboxField name={name} label={t('actions.agree_to_marketing_emails')} {...props} />
}

export default IsSubscribedToNewsletterField
