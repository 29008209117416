import {
  AlertCreationType,
  AlertValidationForm,
  getAlertDefaultNameKey,
  isFrostAlert,
  PredicateWithOptionalParam
} from '@weenat/client/dist/core/alerts/alerts'
import {
  Alert,
  getPredicateKey,
  Predicate,
  TargetsWithTZ
} from '@weenat/client/dist/resources/alerts.types'
import schemas from '@weenat/client/dist/schemas'
import { TKey, useIntl } from '@weenat/wintl'
import CheckboxField from 'app/src/kit/fields/CheckboxField'
import TextField from 'app/src/kit/fields/TextField'
import Pop from 'app/src/kit/Pop'
import SuperForm from 'app/src/kit/SuperForm'
import Text from 'app/src/kit/Text'
import { FormikProps } from 'formik'
import isNil from 'lodash-es/isNil'
import { useMemo, useRef } from 'react'
import AlertCreationStep, { AlertCreationStepProps } from './AlertCreationStep'
import AlertTargetTable from './AlertTargetTable'
import PredicateTable from './PredicateTable'

type AlertValidationProps = AlertCreationStepProps<AlertValidationForm>

interface ValidateAlertProps extends AlertValidationProps {
  alertType: AlertCreationType
  predicates: PredicateWithOptionalParam[]
  targets: TargetsWithTZ
  alertValidationForm: AlertValidationForm
  isNextLoading: boolean
}

const ValidateAlert: FC<ValidateAlertProps> = ({
  alertType,
  onPressNext,
  onPressPrevious,
  predicates,
  targets,
  alertValidationForm,
  ...props
}) => {
  const { t } = useIntl()

  const defaultAlertNameKey = useMemo(
    () => getAlertDefaultNameKey(alertType, getPredicateKey(predicates[0] as Predicate)),
    [alertType, predicates]
  )

  const formRef = useRef<FormikProps<AlertValidationForm>>(null)

  return (
    <AlertCreationStep
      {...props}
      canProgressToNext={props.canProgressToNext || !props.isNextLoading}
      onPressPrevious={() => onPressPrevious?.()}
      onPressNext={() => formRef.current?.submitForm()}
    >
      <Text $fontWeight='bold' $fontSize='md'>
        {t('titles.general_infos')}
      </Text>
      <Box $my='lg'>
        <SuperForm
          schema={schemas.alertCreation.validation}
          initialValues={{
            name: isNil(alertValidationForm.name)
              ? t(
                  defaultAlertNameKey as TKey,
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  {},
                  {
                    capitalize: true
                  }
                )
              : alertValidationForm.name || '',
            isVoiceCallEnabled: !!alertValidationForm.isVoiceCallEnabled,
            isActive: true
          }}
          onSubmit={(values) => {
            onPressNext?.(values)
          }}
          formRef={formRef}
        >
          <Pop tourName='discover_alerts' stepName='custom_name'>
            <TextField
              label={t('models.plot.fields.name.label', { capitalize: true })}
              name={'name'}
            />
          </Pop>
          {isFrostAlert(predicates) && (
            <CheckboxField
              label={t('alerts.paramSetups.fieldLabels.enable_voice_call')}
              name='isVoiceCallEnabled'
            />
          )}
        </SuperForm>
      </Box>

      <Text $fontWeight='bold' $fontSize='md'>
        {t('alerts.validation.events', { capitalize: true })}
      </Text>
      <Box $my='lg'>
        <PredicateTable isAllowingEdition={false} predicates={predicates as Alert['predicates']} />
      </Box>

      <Text $fontWeight='bold' $fontSize='md'>{`${t('alerts.validation.targets', {
        capitalize: true
      })} (${targets.devices.length + targets.plots.length + targets.stations.length})`}</Text>
      <Box $my='lg'>
        <AlertTargetTable isAllowingEdition={false} isShowingStatuses={false} targets={targets} />
      </Box>
    </AlertCreationStep>
  )
}

export default ValidateAlert
