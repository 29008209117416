import { useIntl } from '@weenat/wintl'
import HelmetAndBreadcrumbs from 'app/src/kit/HelmetAndBreadcrumbs'
import EditMyInfos from 'app/src/me/EditMyInfos'

const Profile = () => {
  const { t } = useIntl()

  return (
    <>
      <HelmetAndBreadcrumbs screenTitle={t('titles.profile')} />
      <EditMyInfos />
    </>
  )
}

export const Component = Profile
export default Profile
