import { styled } from 'styled-components'

const HeroBox = styled(Flex)`
  flex-direction: column;

  border-radius: ${(p) => p.theme.radiuses.lg}px;
  background-color: ${(p) => p.theme.colors.grayscale[100]};
  min-height: 256px;
`

export default HeroBox
