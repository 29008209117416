import { TKey, useIntl } from '@weenat/wintl'
import Text from 'app/src/kit/Text'
import HelpLink, { HelpLinkProps } from './HelpLink'

export interface SubSectionProps {
  title: TKey
  links: HelpLinkProps[]
}

const hasLink = (l: HelpLinkProps) => l.href.length

const HelpSubSection: FC<SubSectionProps> = ({ title, links }) => {
  const { t } = useIntl()

  return links.some(hasLink) ? (
    <Flex $flexDirection='column' $gap='lg' $pt='lg'>
      <Text $fontWeight='bold'>{t(title)}</Text>
      {links.filter(hasLink).map((link: HelpLinkProps) => (
        <HelpLink {...link} key={link.message} />
      ))}
    </Flex>
  ) : null
}

export default HelpSubSection
