import Text from 'app/src/kit/Text'
import { useField } from 'formik'
import isNil from 'lodash-es/isNil'
import { ReactElement, useCallback } from 'react'
import { styled } from 'styled-components'
import Checkbox from '../Checkbox'

const BOX_HEIGHT = 24

type CheckboxProps = {
  name: string
  label: React.ReactElement | string
  $isDisabled?: boolean
  $hideErrors?: boolean
}

const CheckboxAndLabelContainer = styled(Flex)<{ $isDisabled?: boolean }>`
  flex-direction: column;
  min-height: ${BOX_HEIGHT}px;
  margin: 4px 0;
  ${(p) =>
    p.$isDisabled
      ? `
        cursor: not-allowed;
        opacity: 0.5;
      `
      : `
        cursor: pointer;
      `}
`

const CheckboxAndLabelTouchable = styled.div<Pick<CheckboxProps, '$isDisabled'>>`
  display: flex;
  flex-direction: row;

  align-items: center;
  gap: ${(p) => p.theme.spacings.md}px;
`

const CheckboxLabel = styled(Text)`
  flex: 1;
  flex-wrap: wrap;
`

const CheckboxErrorMessage = styled(Text)`
  padding-left: 32px;
  min-height: 16px;
  color: ${(props) => props.theme.colors.feedback.error['500']};
  font-size: ${(p) => p.theme.typography.sizes.sm}px;
`

interface CheckboxPrimitiveProps extends Omit<CheckboxProps, 'name'> {
  value: boolean
  onChange: (value: boolean) => void
  error?: string | null
  helpers?: ReactElement
}

export const CheckboxPrimitive: FC<CheckboxPrimitiveProps> = ({
  value,
  label,
  $isDisabled,
  onChange,
  error,
  helpers = null,
  $hideErrors = false
}) => {
  const handlePress = useCallback(() => {
    if (!$isDisabled) {
      onChange(!value)
    }
  }, [onChange, $isDisabled, value])

  const labelElt = typeof label === 'string' ? <CheckboxLabel>{label}</CheckboxLabel> : label

  return (
    <CheckboxAndLabelContainer $isDisabled={$isDisabled}>
      <CheckboxAndLabelTouchable $isDisabled={$isDisabled} onClick={handlePress}>
        <Checkbox checked={value} />
        {isNil(helpers) ? (
          labelElt
        ) : (
          <Flex $flexDirection='column'>
            {labelElt}
            {helpers}
          </Flex>
        )}
      </CheckboxAndLabelTouchable>
      {$hideErrors ? null : <CheckboxErrorMessage>{error}</CheckboxErrorMessage>}
    </CheckboxAndLabelContainer>
  )
}

const CheckboxField: FC<CheckboxProps> = ({
  name,
  label,
  $isDisabled: isDisabled,
  $hideErrors: hideErrors
}) => {
  const [field, meta, helpers] = useField<boolean>(name)
  const displayableError = meta.touched ? meta.error : null

  const handleChange = useCallback(
    (value: boolean) => {
      if (!isDisabled) {
        helpers.setTouched(true)
        helpers.setValue(value)
      }
    },
    [helpers, isDisabled]
  )

  return (
    <CheckboxPrimitive
      value={field.value}
      label={label}
      $isDisabled={isDisabled}
      onChange={handleChange}
      error={displayableError}
      $hideErrors={hideErrors}
    />
  )
}

export default CheckboxField
