import { defaultAlert, stepsWithoutBreadcrumbs } from '@weenat/client/dist/core/alerts/alerts'
import usePredicateEdition from '@weenat/client/dist/core/alerts/usePredicateEdition'
import { Alert, Predicate, Source } from '@weenat/client/dist/resources/alerts.types'
import { useIntl } from '@weenat/wintl'
import CountBadge from 'app/src/kit/CountBadge'
import Modal, { ModalProps } from 'app/src/kit/Modal'
import Text from 'app/src/kit/Text'
import { styled, useTheme } from 'styled-components'
import AlertBreadcrumbs from './AlertBreadcrumbs'
import PickPredicateOperation from './PickPredicateOperation'
import PickPredicateSource from './PickPredicateSource'
import SetupPredicateParams from './SetupPredicateParams'

const APPROXIMATE_MODAL_HEADER_HEIGHT = 64
const ContentContainer = styled(Flex)`
  flex-direction: column;
  height: ${`calc(100% - ${APPROXIMATE_MODAL_HEADER_HEIGHT}px)`};

  /** Hiding scrollbar but keeping scroll */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /** Chromium based */
  &::-webkit-scrollbar {
    display: none;
  }
`

interface CreateOrEditPredicateInterface extends Pick<ModalProps, 'isOpen' | 'close'> {
  alert: Alert
  initialPredicate?: Predicate
  onValidation: (predicate: Predicate) => void
  disabledSources: Source[]
}

const CreateOrEditPredicate: FC<CreateOrEditPredicateInterface> = ({
  isOpen,
  close,
  alert,
  initialPredicate,
  onValidation,
  disabledSources
}) => {
  const { t } = useIntl()
  const { colors } = useTheme()

  const {
    currentPredicate,
    currentStep,
    currentStepNumber,
    currentSteps,
    goToStep,
    handleOperationSelection,
    handleParamsSelection,
    handlePreviousOnOperation,
    handlePreviousOnParams,
    handleSourceSelection,
    stepTitle,
    unavailableSources
  } = usePredicateEdition({ initialPredicate, disabledSources, onValidation })

  const currentAlertCreationType =
    Object.keys(alert.predicates).length > 1 ? 'multiParams' : 'simple'

  return (
    <Modal
      isOpen={isOpen}
      disableCloseOnBackgroundClick
      width={812}
      height={720}
      title={
        <Box>
          <Text $fontWeight='bold' $fontSize='lg'>
            {t(initialPredicate ? 'alerts.edit_predicate' : 'alerts.add_more_predicates', {
              capitalize: true
            })}
          </Text>
          {alert.name && (
            <Text $fontSize='sm' $color={colors.grayscale[300]}>
              {alert.name}
            </Text>
          )}
        </Box>
      }
      close={close}
    >
      <ContentContainer>
        {/* HEADER */}
        <Flex $alignItems='center' $mb='md' $gap={'md'}>
          <CountBadge total={currentSteps.length} count={currentStepNumber} />
          <Text $fontWeight='bold'>{stepTitle}</Text>
        </Flex>

        {/* BREADCRUMBS */}
        {!stepsWithoutBreadcrumbs.includes(currentStep) && (
          <AlertBreadcrumbs
            predicatesLength={Object.keys(defaultAlert.predicates).length}
            creationType={'simple'}
            displayedPredicate={currentPredicate}
            navigateToOp={() => goToStep('pickPredicateOperation')}
            navigateToParams={() => goToStep('setupPredicate')}
            navigateToSource={() => goToStep('pickPredicateSource')}
          />
        )}

        {/* STEP CONTENT */}
        {currentStep === 'pickPredicateSource' ? (
          <PickPredicateSource
            initialSource={currentPredicate.source}
            disabledSources={unavailableSources}
            currentStepNumber={currentStepNumber}
            onPressNext={handleSourceSelection}
          />
        ) : currentStep === 'pickPredicateOperation' && currentPredicate.source ? (
          <PickPredicateOperation
            currentAlertCreationType={currentAlertCreationType}
            initialOperation={currentPredicate.op}
            source={currentPredicate.source}
            currentStepNumber={currentStepNumber}
            onPressPrevious={handlePreviousOnOperation}
            onPressNext={handleOperationSelection}
          />
        ) : currentStep === 'setupPredicate' ? (
          <SetupPredicateParams
            isLastStep
            predicateToSetup={currentPredicate as Predicate}
            currentStepNumber={currentStepNumber}
            onPressPrevious={handlePreviousOnParams}
            onPressNext={handleParamsSelection}
          />
        ) : null}
      </ContentContainer>
    </Modal>
  )
}
export default CreateOrEditPredicate
