import { useClient, useMutation, useQuery } from '@weenat/client'
import { Requester } from '@weenat/client/dist/Client'
import { InvitationStatus, OrgRole } from '@weenat/client/dist/enums'
import { PossibleError } from '@weenat/client/dist/errors'
import { Invitation, RequestType } from '@weenat/client/dist/resources/invitations'
import { Org } from '@weenat/client/dist/resources/orgs'
import { PaginatedResponse } from '@weenat/client/dist/resources/types'
import { useIntl } from '@weenat/wintl'
import { useOrgContext } from 'app/orgProvider'
import useDisclosure from 'app/src/hooks/useDisclosure'
import Text from 'app/src/kit/Text'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { ReactNode, useEffect, useState } from 'react'
import { styled } from 'styled-components'
import useToasts from '../hooks/useToasts'
import Button from './Button'
import LinkComponent from './LinkComponent'
import Modal from './Modal'

const StyledUl = styled.ul`
  margin: ${(p) => p.theme.spacings.md}px 0;
  list-style: disc;
  padding: revert;
  max-height: 300px;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  flex-flow: column wrap;
`

const StyledLinkComponent = styled(LinkComponent)`
  margin-left: 5px;
`

const MultipleStyledFlex = styled(Flex)`
  align-self: center;
`

const SingleFlexContainer = styled(Flex)`
  align-items: center;
  flex-direction: column;
`
const SingleButtonsContainer = styled(Flex)`
  justify-content: space-between;
  width: 70%;
  margin: ${(p) => p.theme.spacings.xl}px 0 ${(p) => p.theme.spacings.md}px;
`

const MultipleTitle = styled(Text)`
  align-self: center;
`

const SuccessMediumText = styled(Text)`
  margin: ${(p) => p.theme.spacings.lg}px 0;
  color: ${(p) => p.theme.colors.feedback.success['500']};
`

const ErrorMediumText = styled(Text)`
  margin: ${(p) => p.theme.spacings.lg}px 0;
  color: ${(p) => p.theme.colors.feedback.error['500']};
`

interface SingleInvitationProps {
  invitation: Invitation
  org: Org
  onAcceptSuccess: () => void
  onDeclineSuccess: () => void
  invitationsRequester: Requester<
    PaginatedResponse<Invitation>,
    PossibleError,
    PaginatedResponse<Invitation>
  >
}

const SingleInvitation: FC<SingleInvitationProps> = ({
  invitation,
  org,
  onAcceptSuccess,
  onDeclineSuccess,
  invitationsRequester
}) => {
  const client = useClient()
  const { t } = useIntl()
  const { addErrorToast, addSuccessToast } = useToasts()

  const isNetworkInvitation = [
    RequestType.adherentFarmInvitation,
    RequestType.adherentFarmInvitationWithTrial
  ].includes(invitation.type)

  const myOrgsRequest = useQuery(client.orgs.getAllPages({ myRole: OrgRole.owner }), {
    enabled: isNetworkInvitation
  })

  const myOrgsNotPartOfNetworks = myOrgsRequest.data?.filter(({ subscribedNetworkId }) =>
    isNil(subscribedNetworkId)
  )

  const selectedOrgCouldJoinNetwork =
    myOrgsNotPartOfNetworks?.find((anOrg) => anOrg.id === org.id) !== undefined

  const orgsAbleToJoinNetworkCount =
    !isNil(myOrgsNotPartOfNetworks) && !isEmpty(myOrgsNotPartOfNetworks)
      ? myOrgsNotPartOfNetworks?.length
      : 0

  const [step, setStep] = useState<'acceptOrDecline' | 'no_org' | 'error' | 'success'>(
    'acceptOrDecline'
  )

  const keysToInvalidate = [
    invitationsRequester.invalidationKey,
    client.orgs.getAllPages().invalidationKey
  ]

  const [acceptRemoteCall, acceptationState] = useMutation(
    client.invitations.received.accept(invitation.id),
    {
      onSuccess: () => {
        addSuccessToast(t('models.invitation.actions.accept_success'))
        onAcceptSuccess()
      },
      onError: () => {
        addErrorToast(t('models.invitation.misc.modal_invitation_acceptation_generic_error'))
      },
      keysToInvalidate
    }
  )
  const [decline, declineState] = useMutation(client.invitations.received.decline(invitation.id), {
    keysToInvalidate,
    onSuccess: () => {
      addSuccessToast(t('models.invitation.actions.decline_success'))
      onDeclineSuccess()
    },
    onError: () => {
      addErrorToast(t('models.invitation.misc.modal_invitation_refusal_generic_error'))
    }
  })

  let informationMessage

  if (isNetworkInvitation) {
    // multi owner
    if (orgsAbleToJoinNetworkCount > 1) {
      if (selectedOrgCouldJoinNetwork) {
        informationMessage = t(
          'models.invitation.misc.modal_invitation_information_message_mutiple_orga',
          { organizationName: org.name }
        )
      } else {
        informationMessage = t(
          'models.invitation.misc.modal_invitation_selected_org_could_not_join',
          { organizationName: org.name }
        )
      }
    } else if (!selectedOrgCouldJoinNetwork) {
      informationMessage = t(
        'models.invitation.misc.modal_invitation_selected_org_could_not_join',
        { organizationName: org.name }
      )
    } else {
      // mono owner not member of network
      informationMessage = t(
        'models.invitation.misc.modal_invitation_information_message_single_orga',
        { organizationName: org.name }
      )
    }
  }

  let result: ReactNode = null

  if (!isNetworkInvitation || myOrgsRequest.isSuccess) {
    if (step === 'acceptOrDecline') {
      result = (
        <SingleFlexContainer>
          <Text>
            {t('models.invitation.misc.modal_invitation_main_message', {
              userName: invitation.senderName,
              orgName: invitation.senderOrganizationName
            })}
          </Text>
          {!isNil(informationMessage) ? (
            <Text $textAlign='center' $fontStyle='italic'>
              {informationMessage}
            </Text>
          ) : null}
          <SingleButtonsContainer>
            <Button
              state={declineState}
              isDanger
              onPress={() => {
                decline()
              }}
            >
              {t('actions.decline')}
            </Button>
            <Button
              isDisabled={isNetworkInvitation && !selectedOrgCouldJoinNetwork}
              state={acceptationState}
              onPress={() => {
                isNetworkInvitation
                  ? acceptRemoteCall({ organizationId: org.id })
                  : acceptRemoteCall()
              }}
            >
              {t('actions.accept')}
            </Button>
          </SingleButtonsContainer>
        </SingleFlexContainer>
      )
    } else if (step === 'no_org') {
      result = (
        <Text>
          {t('models.invitation.misc.modal_invitation_no_org', {
            userName: invitation.senderName,
            networkName: invitation.senderOrganizationName
          })}
        </Text>
      )
    } else if (step === 'success') {
      result = (
        <SuccessMediumText>{t('models.invitation.actions.accept_success')}</SuccessMediumText>
      )
    } else if (step === 'error') {
      result = (
        <ErrorMediumText>
          {t('models.invitation.misc.modal_invitation_acceptation_generic_error')}
        </ErrorMediumText>
      )
    }
  }

  useEffect(() => {
    if (myOrgsRequest.isSuccess && orgsAbleToJoinNetworkCount === 0) {
      setStep('no_org')
    }
  }, [myOrgsRequest.isSuccess, orgsAbleToJoinNetworkCount])

  return result
}

const Multiple = ({ invitations, close }: { invitations: Invitation[]; close: () => void }) => {
  const { t } = useIntl()
  return (
    <Flex $flexDirection='column'>
      <MultipleTitle>
        {t('models.invitation.misc.modal_invitation_multiple_invitations_1')}
      </MultipleTitle>
      <StyledUl>
        {invitations.map((invitation) => (
          <li key={invitation.id}>
            <Text>{invitation.senderOrganizationName}</Text>
          </li>
        ))}
      </StyledUl>
      <MultipleStyledFlex>
        <Text>{t('models.invitation.misc.modal_invitation_multiple_invitations_2')}</Text>
        <StyledLinkComponent to='/me/invitations/' $underlined onClick={close}>
          <Text>{t('models.invitation.misc.modal_invitation_multiple_invitations_3')}</Text>
        </StyledLinkComponent>
      </MultipleStyledFlex>
    </Flex>
  )
}

const EMPTY_INVITATIONS: Invitation[] = []
const linesPerCol = 12

const InvitNotificationPresentation: FC = () => {
  const { t } = useIntl()
  const client = useClient()
  const { org, currentOrgId } = useOrgContext()

  const { close, isOpen, open } = useDisclosure()

  // Fetching the 5 first invitations should always be enough
  const pendingInvitationsRequester = client.invitations.received.getPage(0, {
    limit: 5,
    status: InvitationStatus.pending
  })

  const pendingInvitationsRequest = useQuery(pendingInvitationsRequester)

  const pendingInvitations = !isNil(pendingInvitationsRequest.data)
    ? pendingInvitationsRequest.data.results
    : EMPTY_INVITATIONS

  const invitationReceivedCount = !isNil(pendingInvitationsRequest.data)
    ? pendingInvitationsRequest.data.count
    : 0

  useEffect(() => {
    if (invitationReceivedCount > 0) {
      open()
    }
  }, [invitationReceivedCount, currentOrgId])

  return !isEmpty(pendingInvitations) && pendingInvitations.length > 0 && org ? (
    <Modal
      isOpen={isOpen}
      close={close}
      title={t('models.invitation.misc.modal_invitation_title')}
      width={invitationReceivedCount > linesPerCol * 2 ? 800 : 600}
    >
      {pendingInvitations.length > 1 ? (
        <Multiple close={close} invitations={pendingInvitations} />
      ) : pendingInvitations.length === 1 ? (
        <SingleInvitation
          invitation={pendingInvitations[0]}
          org={org}
          onDeclineSuccess={close}
          onAcceptSuccess={close}
          invitationsRequester={pendingInvitationsRequester}
        />
      ) : null}
    </Modal>
  ) : null
}

export default InvitNotificationPresentation
