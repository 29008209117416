import { FlexProps } from 'app/src/kit/primitives/themeMappings/props'
import isNil from 'lodash-es/isNil'
import { css, styled } from 'styled-components'

type ControlsContainerProps = {
  $isTransparent?: boolean
  $padding?: number
  $isShadowed?: boolean
} & FlexProps

export const controlsContainerStyle = css<ControlsContainerProps>`
  align-items: ${(p) => (isNil(p.$alignItems) ? 'center' : p.$alignItems)};
  justify-content: space-between;
  background-color: ${(p) =>
    !isNil(p.$backgroundColor)
      ? p.$backgroundColor
      : p.$isTransparent
        ? 'transparent'
        : p.theme.colors.grayscale[50]};
  border-radius: ${(p) => (!isNil(p.$borderRadius) ? p.$borderRadius : p.theme.radiuses.rounded)}px;
  border: 1px solid
    ${(p) =>
      !isNil(p.$borderColor)
        ? p.$borderColor
        : p.$isTransparent
          ? 'transparent'
          : p.theme.colors.grayscale[300]};

  ${(p) =>
    p.$isShadowed &&
    `
      box-shadow: ${p.theme.shadows.md.boxShadow};
    `}

  overflow: hidden;
  pointer-events: auto;
`

const ControlsContainer = styled(Flex)<ControlsContainerProps>`
  ${controlsContainerStyle}
`

export default ControlsContainer
