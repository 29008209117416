import { schemas } from '@weenat/client'
import { OrgRole } from '@weenat/client/dist/enums'
import { MutationRequestState } from '@weenat/client/dist/mergeState'
import { Network } from '@weenat/client/dist/resources/networks'
import { Id } from '@weenat/client/dist/resources/types'
import { useIntl } from '@weenat/wintl'
import Modal, { ModalProps } from 'app/src/kit/Modal'
import SubmitButton from 'app/src/kit/SubmitButton'
import SuperForm from 'app/src/kit/SuperForm'
import Text from 'app/src/kit/Text'
import { useField } from 'formik'
import { memo, useState } from 'react'
import { useTheme } from 'styled-components'
import Button from '../kit/Button'
import SelectOrganization from '../kit/SelectOrganization'

interface SelectOrgFieldProps {
  onNoOrganizationsFound: () => void
}

const SelectOrgField: FC<SelectOrgFieldProps> = ({ onNoOrganizationsFound }) => {
  const [, , helpers] = useField('organizationId')

  return (
    <SelectOrganization
      queryParams={{ myRole: [OrgRole.owner, OrgRole.admin] }}
      onNoOrganizationsFound={onNoOrganizationsFound}
      onChange={(id) => {
        helpers.setValue(id)
      }}
    />
  )
}

const SCHEMA = schemas.network.requestMembership
type Fields = typeof SCHEMA.initialValues

interface RequestNetworkMembershipModalProps extends Pick<ModalProps, 'isOpen' | 'close'> {
  onSubmit: (orgId: Id) => void
  requestStatus: MutationRequestState
  network: Network
}

const RequestNetworkMembershipModal: FC<RequestNetworkMembershipModalProps> = ({
  isOpen,
  close,
  network,
  requestStatus,
  onSubmit
}) => {
  const { t } = useIntl()
  const { colors } = useTheme()

  const [hasNoOrganizations, setHasNoOrganizations] = useState(false)

  return (
    <Modal
      // Select and modal outside click conflicts, we don't want from
      // the default close behavior when we are not in the success state
      disableCloseOnBackgroundClick={requestStatus.isSuccess}
      title={
        requestStatus.isSuccess
          ? t('models.networkMembershipRequest.model.singular_name', { capitalize: true })
          : t(
              hasNoOrganizations
                ? 'models.network.actions.unable_to_select_org'
                : 'models.network.actions.select_org_to_join'
            )
      }
      isOpen={isOpen}
      close={close}
    >
      {hasNoOrganizations ? null : requestStatus.isSuccess ? (
        <Flex $flexDirection='column' $alignItems='center' $justifyContent='center'>
          <Text $textAlign='center' $color={colors.feedback.success[500]}>
            {t('models.network.actions.request_membership_success', {
              network: network.name
            })}
          </Text>
          <Box $pt='lg'>
            <Button importance='sd' onPress={close}>
              {t('actions.close')}
            </Button>
          </Box>
        </Flex>
      ) : (
        <SuperForm
          schema={SCHEMA}
          initialValues={{ organizationId: undefined }}
          onSubmit={(values: Fields) => onSubmit(values.organizationId)}
        >
          <SelectOrgField onNoOrganizationsFound={() => setHasNoOrganizations(true)} />

          <SubmitButton state={requestStatus}>
            {t('models.network.actions.ask_to_join', { capitalize: true })}
          </SubmitButton>
        </SuperForm>
      )}
    </Modal>
  )
}

export default memo(RequestNetworkMembershipModal)
