import { useIntl } from '@weenat/wintl'
import isNil from 'lodash-es/isNil'
import TextField, { CustomFieldProps } from './TextField'

const EmailField: FC<CustomFieldProps> = ({ name = 'email', label, ...props }) => {
  const { t } = useIntl()
  const finalLabel = !isNil(label)
    ? label
    : t('models.account.fields.email.label', { capitalize: true })

  return <TextField label={finalLabel} name={name} inputType='email' {...props} />
}

export default EmailField
