import {
  CompleteTargets,
  getAlertSources,
  getDssLabelPerSource
} from '@weenat/client/dist/core/alerts'
import useGetCompleteAlertTargets from '@weenat/client/dist/core/alerts/useGetCompleteAlertTargets'
import useItemName from '@weenat/client/dist/core/utils/useItemName'
import { Alert, Source, isDssSource } from '@weenat/client/dist/resources/alerts.types'
import { WeenatColor } from '@weenat/theme'
import { useIntl } from '@weenat/wintl'
import { useNavigate } from 'app/routx-router'
import Badge from 'app/src/kit/Badge'
import Icons from 'app/src/kit/Icons'
import Pop from 'app/src/kit/Pop'
import Text from 'app/src/kit/Text'
import { useMemo } from 'react'
import { styled, useTheme } from 'styled-components'
import DelimitedFlex from '../kit/DelimitedFlex'
import AlertActiveStateToggle from './AlertActiveStateToggle'
import AlertLastUpdate from './AlertLastUpdate'

const TARGET_TO_DISPLAY_COUNT = 2

const BadgeLabel = styled(Text)`
  margin-left: 2px;
  font-weight: ${(p) => p.theme.typography.weights.regular};
`

const LastUpdateContainer = styled(Box)`
  flex: 1;
  text-align: right;
  padding: 12px 0;
`

interface AlertDetailsBadgeProps {
  color: WeenatColor
  iconName: keyof typeof Icons
  text: string
}

const AlertDetailsBadge: FC<AlertDetailsBadgeProps> = ({ color, text, iconName }) => {
  const Icn = Icons[iconName]

  return (
    <Badge $py={2} $pr={6} $pl={4} $color={color} $borderWidth='md' $backgroundColor='transparent'>
      <Flex $alignItems='center'>
        <Icn $color={color} $size='rg' />
        <BadgeLabel $fontSize='sm' $color={color}>
          {text}
        </BadgeLabel>
      </Flex>
    </Badge>
  )
}

interface TargetBadgeProps {
  target: CompleteTargets
  withComma: boolean
}

const TargetName: FC<TargetBadgeProps> = ({ target, withComma }) => {
  const name = useItemName(target)

  return (
    <Text $ellipsis>
      {name}
      {withComma ? <>,&nbsp;</> : ''}
    </Text>
  )
}

interface AlertCardProps {
  $isTriggered: boolean
  $isDisabled: boolean
}

const AlertCard = styled(Box)<AlertCardProps>`
  border-width: 2px;

  border-radius: ${(p) => p.theme.radiuses.lg}px;
  border: 2px solid
    ${(p) =>
      p.$isDisabled
        ? p.theme.colors.grayscale[300]
        : p.$isTriggered
          ? p.theme.colors.feedback.error[500]
          : p.theme.colors.grayscale[300]};
  background-color: ${({ theme }) => theme.colors.grayscale.white};

  overflow: hidden;

  &:hover {
    cursor: pointer;
    border-color: ${(p) =>
      p.$isDisabled
        ? p.theme.colors.primary[500]
        : p.$isTriggered
          ? p.theme.colors.feedback.error[500]
          : p.theme.colors.primary[500]};
  }

  transition: border-color 0.2s ease-out;
  will-change: border-color;
`

const TargetsList: FC<{ targets: CompleteTargets[]; targetsCount: number }> = ({
  targets,
  targetsCount
}) => {
  const { t } = useIntl()

  const isTargetsEmpty = targetsCount === 0
  const displayed = isTargetsEmpty ? [] : targets.slice(0, TARGET_TO_DISPLAY_COUNT)
  const moreCount = isTargetsEmpty ? 0 : targetsCount - displayed.length

  return (
    <Flex $alignItems='center'>
      <Icons.PinDrop $size='lg' />
      {isTargetsEmpty ? (
        <Text>{t('alerts.no_targets')}</Text>
      ) : (
        displayed.map((target, i) => (
          <TargetName key={target.id} target={target} withComma={i !== displayed.length - 1} />
        ))
      )}
      {moreCount !== 0 && (
        <Box $ml='sm'>
          <Text $fontWeight='bold'>{` + ${moreCount}`}</Text>
        </Box>
      )}
    </Flex>
  )
}

const SourcesList: FC<{ sources: Source[] }> = ({ sources }) => {
  const { t } = useIntl()
  const displayed = sources.slice(0, 2)
  const moreCount = sources.length - displayed.length
  const label = displayed
    .map((s) => {
      return isDssSource(s) ? getDssLabelPerSource(s) : t(`metrics.${s}`, { capitalize: true })
    })
    .join(', ')

  return (
    <Flex $alignItems='center' $mb='sm'>
      <Icons.SwipeRight $size='lg' />
      <Box $mr='sm'>
        <Text $ellipsis>{label}</Text>
      </Box>
      {moreCount !== 0 && <Text $fontWeight='medium'>{` + ${moreCount}`}</Text>}
    </Flex>
  )
}

const AlertListItem: FC<{ alert: Alert; withPop?: boolean }> = ({ alert, withPop }) => {
  const { colors } = useTheme()
  const { t } = useIntl()
  const nav = useNavigate()

  const { isVoiceCallEnabled, isActive } = alert
  const sources = useMemo(() => getAlertSources(alert), [alert])

  //we only display name for the first two so we slice targets to only fetch additional data for the first two of each kind
  const completeOnlyOnFirstTwo = Object.entries(alert.targets).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: value.slice(0, TARGET_TO_DISPLAY_COUNT) }),
    {} as Alert['targets']
  )

  const { completeTargets, isLoading } = useGetCompleteAlertTargets(
    completeOnlyOnFirstTwo,
    sources,
    false
  )

  const isAlertTriggered = alert.currentlyTriggerCount > 0

  const goToAlert = () => nav(`/alerts/${alert._id}`)

  const content = !isLoading ? (
    <AlertCard $isTriggered={isAlertTriggered} $isDisabled={!isActive}>
      {/* Triggered Banner */}
      {(isAlertTriggered || !isActive) && (
        <Box
          $p='md'
          $backgroundColor={!isActive ? colors.grayscale[300] : colors.feedback.error[500]}
          $width='100%'
        >
          <Flex $alignItems='center' $gap='sm'>
            {!isActive ? (
              <Icons.UpdateDisabled $color={'grayscale.black'} $size='lg' />
            ) : (
              <Icons.Clock $color={'grayscale.white'} $size='lg' />
            )}
            <Text $fontWeight='medium' $color={!isActive ? 'grayscale.black' : 'grayscale.white'}>
              {t(!isActive ? 'alerts.deactivated' : 'models.event.model.ongoing', {
                capitalize: true
              })}
            </Text>
          </Flex>
        </Box>
      )}

      <Box $p='md' $pb={0} onClick={goToAlert}>
        {/* Badge Row */}
        <Flex $mb='md' $gap='md'>
          {isVoiceCallEnabled && (
            <AlertDetailsBadge
              color={'secondary.500'}
              text={t('alerts.voice_call_active', {
                capitalize: true
              })}
              iconName='Phone'
            />
          )}
        </Flex>

        <Text $fontWeight='bold' $ellipsis>
          {alert.name}
        </Text>

        {/* Targets & Sources Row */}
        <Box $mb='lg' $mt='md'>
          <SourcesList sources={sources} />

          <TargetsList
            targets={completeTargets}
            targetsCount={
              alert.targets.devices.length +
              alert.targets.plots.length +
              alert.targets.stations.length
            }
          />
        </Box>
      </Box>

      {/* Bottom Row */}
      <DelimitedFlex $py='sm' $px='md' $alignItems='center'>
        <AlertActiveStateToggle alert={alert} />
        <LastUpdateContainer onClick={goToAlert}>
          <AlertLastUpdate timestamp={alert.updatedAt} />
        </LastUpdateContainer>
      </DelimitedFlex>
    </AlertCard>
  ) : null

  return content ? (
    withPop ? (
      <Pop key={alert._id} tourName='discover_alerts' stepName='desactivate' placement='bottom'>
        {content}
      </Pop>
    ) : (
      content
    )
  ) : null
}

export default AlertListItem
