function SvgPlaceholder(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' {...props}>
      <path fill='none' d='M0 0h24v24H0z' />
      <path d='M3 5v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2z' />
    </svg>
  )
}

export default SvgPlaceholder
