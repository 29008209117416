import { newPlansMonthlyPrice } from '@weenat/client/dist/core/billing'
import { getFeaturesBulletPoints } from '@weenat/client/dist/core/plans'
import { PlanName } from '@weenat/client/dist/enums'
import { Org } from '@weenat/client/dist/resources/orgs'
import { useIntl } from '@weenat/wintl'
import useDisclosure from 'app/src/hooks/useDisclosure'
import isNil from 'lodash-es/isNil'
import Card, { CardProps } from '../kit/Card'
import Collapsible from '../kit/Collapsible'
import DelimitedFlex from '../kit/DelimitedFlex'
import Icons from '../kit/Icons'
import Text from '../kit/Text'
import SubscriptionBillDetails from './SubscriptionBillDetails'

interface SubscriptionCardProps {
  plan: PlanName
  onPress?: CardProps['onPress']
  /** defaults to false */
  isInitiallyCollapsed?: boolean
  org?: Org
  variant?: 'features' | 'billing'
  isDisabled?: boolean
  hidePrice?: boolean
}

const SubscriptionCard: FC<SubscriptionCardProps> = ({
  plan,
  onPress,
  isInitiallyCollapsed = false,
  variant = 'features',
  org,
  isDisabled = false,
  hidePrice = false
}) => {
  const { t, formatPrice } = useIntl()

  const { isOpen: isContentCollapsed, toggle: toggleContentCollapse } =
    useDisclosure(isInitiallyCollapsed)

  const headerContentColor = 'grayscale.white'
  const monthlyPrice = newPlansMonthlyPrice[plan]

  const bulletPoints = getFeaturesBulletPoints(plan).filter((obj) => obj.id !== 'data_one_app')

  return (
    <Card
      $borderWidth={'rg'}
      $borderColor={!isDisabled ? `plans.${plan}.800` : undefined}
      onPress={isDisabled ? undefined : onPress}
      $p={0}
      $overflow='hidden'
      $flex={1}
    >
      {/* Header */}
      <Flex $p='lg' $backgroundColor={isDisabled ? 'grayscale.300' : `plans.${plan}.500`}>
        <Box $flex={1}>
          <Text $fontWeight='semiBold' $fontSize='lg' $color={headerContentColor}>
            {t(`ecommerce.plans.${plan}.label`, { capitalize: true })}
          </Text>
          {hidePrice ? null : (
            <>
              <Text $fontWeight='medium' $fontSize='lg' $color={headerContentColor}>
                {formatPrice(monthlyPrice)}
              </Text>
              <Text $color={headerContentColor}>
                {t('ecommerce.prices.per_month_per_user', { capitalize: false })}
              </Text>
            </>
          )}
        </Box>
        <Box>
          <Icons.ChevronLeft
            $rotate={isContentCollapsed ? -90 : 90}
            $size='lg'
            $p='md'
            $backgroundColor={'grayscale.white'}
            onPress={toggleContentCollapse}
          />
        </Box>
      </Flex>
      {/* Content */}
      <Collapsible direction='vertical' isOpen={!isContentCollapsed}>
        {variant === 'features' ? (
          <Box $p='lg'>
            <ul>
              {bulletPoints.map((point) => {
                return (
                  <li key={point.id}>
                    <Text $fontSize='rg' $lineThrough={point.isDisabled}>
                      {point.label}
                    </Text>
                  </li>
                )
              })}
            </ul>
          </Box>
        ) : !isNil(org) ? (
          <Box $width='100%' $maxWidth={'100%'}>
            <SubscriptionBillDetails
              plan={plan}
              org={org}
              title={
                <Box>
                  <Text $fontSize='md' $fontWeight='bold'>
                    {t('ecommerce.plans.details')}
                  </Text>
                  <Text $fontSize='sm'>
                    {t('ecommerce.plans.for_org')} <strong>{org.name}</strong>
                  </Text>
                </Box>
              }
            />
          </Box>
        ) : null}
      </Collapsible>
      {isDisabled && plan === 'plus' && (
        <DelimitedFlex $isDelimitedOnTop $alignItems='flex-start' $p='lg' $gap='md'>
          <Icons.InfoCircle $size='md' />
          <Text $flexed>{t('ecommerce.plans.weenat_plus_restriction')}</Text>
        </DelimitedFlex>
      )}
    </Card>
  )
}

export default SubscriptionCard
