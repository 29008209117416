import { fromColorPathToColor } from '@weenat/theme'
import isNil from 'lodash-es/isNil'
import { styled } from 'styled-components'

interface FullScreenContainerProps {
  $backgroundColor?: string
}

const FullScreenSurface = styled.div<FullScreenContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;

  display: flex;

  height: 100vh;
  width: 100vw;

  background-color: ${(p) =>
    !isNil(p.$backgroundColor)
      ? fromColorPathToColor(p.$backgroundColor)
      : p.theme.colors.grayscale[50]};
`

export default FullScreenSurface
