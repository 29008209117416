import { Source, TargetsWithTZ } from '@weenat/client/dist/resources/alerts.types'
import isEmpty from 'lodash-es/isEmpty'
import AlertCreationStep, { AlertCreationStepProps } from './AlertCreationStep'
import AlertTargetsSelector from './AlertTargetsSelector'

type TargetsStepProps = AlertCreationStepProps<TargetsWithTZ>

interface PickAlertTargetsProps extends TargetsStepProps {
  initialTargets: TargetsWithTZ
  sources: Source[]
  setTargets: (newTargets: TargetsWithTZ) => void
}

const PickAlertTargets: FC<PickAlertTargetsProps> = ({
  initialTargets,
  onPressNext,
  setTargets,
  sources,
  ...props
}) => {
  return (
    <AlertCreationStep
      {...props}
      canProgressToNext={
        !isEmpty(initialTargets.plots) ||
        !isEmpty(initialTargets.devices) ||
        !isEmpty(initialTargets.stations)
      }
      onPressNext={() => onPressNext?.(initialTargets)}
      isContentScrollable={false}
    >
      <AlertTargetsSelector
        initialTargets={initialTargets}
        onTargetsChange={setTargets}
        sources={sources}
      />
    </AlertCreationStep>
  )
}

export default PickAlertTargets
