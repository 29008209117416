import {
  HorizonOpt,
  useExtractAvailableHorizonsFromSummaries
} from '@weenat/client/dist/core/horizons'
import { PlotOrStationSummary } from '@weenat/client/dist/resources/measurements'
import { Summary } from '@weenat/client/dist/resources/measurements.type'
import { SelectedOrgs } from 'app/state'
import { useUserContext } from 'app/userProvider'
import isNil from 'lodash-es/isNil'
import { memo, useCallback } from 'react'
import { useWindowSize } from 'react-use'
import Geolocation from '../../../map/Geolocation'
import ControlsContainer from './ControlsContainer'
import DashboardMapLoading from './DashboardMapLoading'
import MapControlsOverlay from './MapControlsOverlay'
import MapOverlay from './MapOverlay'
import PlotList, { PLOT_LIST_WIDTH } from './PlotList'
import Radar from './Radar/Radar'
import useIsRadarEnabled from './Radar/hooks/useIsRadarEnabled'
import ZoomButtons from './ZoomButtons'
import { elementIds } from './constants'
import {
  useBackgroundMapContext,
  useBackgroundMapDispatcher
} from './contexts/BackgroundMapContext'
import NewActionsControls from './universes/NewActionsControls'
import UniverseChipsTabs from './universes/UniverseChipsTabs'
import UniverseHorizonSelector from './universes/UniverseHorizonSelector'
import useUniverseBackgroundMapContext from './universes/useUniverseBackgroundMapContext'

const LIST_BREAKPOINT = 1024

type PlotsMapControlsProps = {
  availableHorizonOptions: HorizonOpt[]
  summaries: Summary[]
}

const PlotsMapControls: FC<PlotsMapControlsProps> = memo(
  ({ availableHorizonOptions, summaries }) => {
    const { width: windowWidth } = useWindowSize()
    const shouldDisplayList = windowWidth > LIST_BREAKPOINT

    const {
      api: { map, maps }
    } = useBackgroundMapContext()
    const dispatch = useBackgroundMapDispatcher()

    const [isRadarEnabled] = useIsRadarEnabled()

    const onGeolocationSuccess = useCallback<PositionCallback>(
      ({ coords: { latitude, longitude } }) => {
        dispatch({
          type: 'setGeolocation',
          newGeolocation: {
            lat: latitude,
            lng: longitude
          }
        })
      },
      [dispatch]
    )

    return (
      <MapControlsOverlay $left={shouldDisplayList ? PLOT_LIST_WIDTH : 0}>
        {!isRadarEnabled ? (
          <Flex $flex={1} $flexDirection='column' $justifyContent='space-between'>
            {/* TOP */}
            <UniverseChipsTabs />

            {/* FILLS THE SCREEN */}
            <Flex $flex={1} $flexDirection='column' $width='100%' $gap='xl'>
              {/* SMASHED RIGHT */}
              <Flex
                $flexDirection='column'
                $gap='lg'
                $justifyContent='center'
                $alignItems='center'
                $alignSelf='flex-end'
                $flex={1}
              >
                <Flex $flexDirection='column' $gap='lg'>
                  <ControlsContainer $flexDirection='column-reverse' $py='sm'>
                    <ZoomButtons />
                  </ControlsContainer>

                  <ControlsContainer>
                    <Geolocation onSuccess={onGeolocationSuccess} tooltipPlacement='left' />
                  </ControlsContainer>

                  <Radar map={map} maps={maps} />
                </Flex>

                <UniverseHorizonSelector availableHorizonOptions={availableHorizonOptions} />
              </Flex>

              {/* BOTTOM */}
              <NewActionsControls summaries={summaries} />
            </Flex>
          </Flex>
        ) : (
          <Radar map={map} maps={maps} />
        )}
      </MapControlsOverlay>
    )
  }
)

const emptyArray: PlotOrStationSummary[] = []

interface PlotsMapOverlayProps {
  currentItems: PlotOrStationSummary[]
  focusedHorizon: number | null
  hasAlreadyFetchedAndHasNoItems: boolean
  hasNoItemsMatchingSearchTerm: boolean
  isError: boolean
  isExpertOrg: boolean
  isLoading: boolean
  onHorizonChange: (newHorizon: number | null) => void
  selectedOrgs: SelectedOrgs
  setSelectedOrgs: (s: SelectedOrgs) => void
  allSummaries: PlotOrStationSummary[]
  summariesQueryCacheKey: string
}

const PlotsMapOverlay: FC<PlotsMapOverlayProps> = ({
  allSummaries: summaries,
  children,
  currentItems,
  focusedHorizon,
  hasAlreadyFetchedAndHasNoItems,
  hasNoItemsMatchingSearchTerm,
  isError,
  isExpertOrg,
  isLoading,
  onHorizonChange,
  selectedOrgs,
  setSelectedOrgs,
  summariesQueryCacheKey
}) => {
  const { profile } = useUserContext()

  const { focusedMetricId } = useUniverseBackgroundMapContext()

  const { width: windowWidth } = useWindowSize()

  const {
    api: { map, maps }
  } = useBackgroundMapContext()

  const myPreferredUnits = profile?.units

  // Sending an empty array when we want to avoid computations of available horizons
  // HPOT being available on plots map only cast to PlotSummary[] should be fine
  const availableHorizonOptions = useExtractAvailableHorizonsFromSummaries(
    focusedMetricId === 'HPOT' ? summaries : emptyArray,
    focusedMetricId === 'HPOT'
  )

  const shouldDisplayList = !isNil(children) ? windowWidth > LIST_BREAKPOINT : true
  const shouldShowControls = !isNil(map) && !isNil(maps)

  return (
    <MapOverlay id={elementIds.OverlayContainer}>
      {shouldDisplayList ? (
        <PlotList
          availableHorizonOptions={availableHorizonOptions}
          currentItems={currentItems}
          focusedHorizon={focusedHorizon}
          hasAlreadyFetchedAndHasNoItems={hasAlreadyFetchedAndHasNoItems}
          hasNoItemsMatchingSearchTerm={hasNoItemsMatchingSearchTerm}
          isError={isError}
          isExpertOrg={isExpertOrg}
          isLoading={isLoading}
          myPreferredUnits={myPreferredUnits}
          onHorizonChange={onHorizonChange}
          selectedOrgs={selectedOrgs}
          setSelectedOrgs={setSelectedOrgs}
          summariesQueryCacheKey={summariesQueryCacheKey}
        />
      ) : null}
      {isLoading ? (
        <DashboardMapLoading />
      ) : (
        <>
          {shouldShowControls ? (
            <PlotsMapControls
              availableHorizonOptions={availableHorizonOptions}
              // all summaries not only current
              summaries={summaries}
            />
          ) : null}
          {children}
        </>
      )}
    </MapOverlay>
  )
}

export default PlotsMapOverlay
