import type { SVGProps } from 'react'

const SvgHeadquartersLocationPin = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 48 48' {...props}>
    <path
      fill='#002635'
      stroke='#FFF'
      strokeWidth={2}
      d='M24 4C15.6 4 8 10.546 8 20.669c0 6.464 4.9 14.067 14.68 22.828.76.67 1.9.67 2.66 0C35.1 34.736 40 27.133 40 20.669 40 10.546 32.4 4 24 4Z'
      clipPath='url(#headquarters-location-pin_svg__a)'
      filter='url(#headquarters-location-pin_svg__b)'
      transform='translate(-.5)'
    />
    <path
      fill='#FFF'
      d='M15.818 27.227a.792.792 0 0 1-.583-.235.792.792 0 0 1-.235-.583v-6.525a1.593 1.593 0 0 1 .695-1.33l4.091-2.925a1.553 1.553 0 0 1 .941-.307 1.553 1.553 0 0 1 .94.307l4.092 2.925a1.588 1.588 0 0 1 .695 1.33v6.525a.792.792 0 0 1-.235.583.791.791 0 0 1-.583.235h-2.454a.791.791 0 0 1-.583-.235.791.791 0 0 1-.236-.583v-3.273c0-.232-.078-.426-.235-.583s-.35-.235-.583-.235H19.91a.792.792 0 0 0-.583.235.792.792 0 0 0-.235.583v3.273a.792.792 0 0 1-.235.583.792.792 0 0 1-.583.235zm15.545 0h-2.454a.792.792 0 0 1-.583-.235.792.792 0 0 1-.236-.583v-7.343c0-.273-.058-.525-.174-.757a1.5 1.5 0 0 0-.5-.573l-5.053-3.64c0-.437.157-.812.47-1.126.314-.313.69-.47 1.126-.47h7.404c.45 0 .835.16 1.156.48.32.32.48.706.48 1.156V25.59c0 .45-.16.835-.48 1.156-.32.32-.706.48-1.156.48z'
      clipPath='url(#headquarters-location-pin_svg__c)'
      transform='translate(-.5)'
    />
    <defs>
      <clipPath id='headquarters-location-pin_svg__c'>
        <path fill='#FFF' d='M0 0h48v48H0z' />
      </clipPath>
      <clipPath id='headquarters-location-pin_svg__a'>
        <path fill='#FFF' d='M0 0h48v48H0z' />
      </clipPath>
      <filter
        id='headquarters-location-pin_svg__b'
        width={48}
        height={56}
        x={1}
        y={-3}
        colorInterpolationFilters='sRGB'
        filterUnits='userSpaceOnUse'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          result='hardAlpha'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dx={1} dy={1} />
        <feGaussianBlur stdDeviation={4} />
        <feColorMatrix values='0 0 0 0 0 0 0 0 0 0.14902 0 0 0 0 0.207843 0 0 0 0.25 0' />
        <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_417_19' />
        <feBlend in='SourceGraphic' in2='effect1_dropShadow_417_19' result='shape' />
      </filter>
    </defs>
  </svg>
)
export default SvgHeadquartersLocationPin
