import { useIntl } from '@weenat/wintl'
import { countryCodeEmoji } from 'country-code-emoji'
import { useField } from 'formik'
import * as i18nCountries from 'i18n-iso-countries'
import { allowedCountryCodes } from './CountryPicker'
import { SelectFieldPrimitive, SelectFieldPrimitiveProps } from './SelectField'

export interface CountryPickerFieldProps
  extends Omit<
    SelectFieldPrimitiveProps<string>,
    'options' | 'name' | 'label' | 'onChange' | 'value'
  > {
  name?: string
  label?: string
}

const CountryPickerField: FC<CountryPickerFieldProps> = ({
  name = 'country',
  label,
  ...selectFieldProps
}) => {
  const { t, locales } = useIntl()
  const [selectField, , selectFieldHelpers] = useField(name)

  //don't use useMemo here since i18nCountries is changed asynchronously when local is loaded from a network call
  const countriesAsOptions = allowedCountryCodes.map((countryCode) => ({
    value: countryCode,
    label: `${countryCodeEmoji(countryCode)} ${i18nCountries.getName(countryCode, locales.current)}`
  }))

  return (
    <SelectFieldPrimitive
      {...selectField}
      isAutocomplete
      label={label ?? t('models.billingAccount.fields.addressCountry.label', { capitalize: true })}
      options={countriesAsOptions}
      {...selectFieldProps}
      onChange={(newValue) => selectFieldHelpers.setValue(newValue)}
    />
  )
}

export default CountryPickerField
