import { mergeQueryState, useClient, useQuery } from '@weenat/client'
import { newPlansMonthlyPrice } from '@weenat/client/dist/core/billing'
import { BillingStatus, PlanName } from '@weenat/client/dist/enums'
import { Org } from '@weenat/client/dist/resources/orgs'
import { useIntl } from '@weenat/wintl'
import isNil from 'lodash-es/isNil'
import { FC, ReactNode, useMemo } from 'react'
import DescriptionList from '../kit/DescriptionList'

interface SubscriptionBillDetailsProps {
  plan: PlanName
  title: ReactNode
  org: Org
  showPlanName?: boolean
}

const SubscriptionBillDetails: FC<SubscriptionBillDetailsProps> = ({
  plan,
  title,
  org,
  showPlanName = false
}) => {
  const { t, formatPrice } = useIntl()
  const client = useClient()

  const membersRequest = useQuery(client.members.getMany({ orgId: org.id }), {
    enabled: !isNil(org.id)
  })

  const numberOfMembers =
    !isNil(membersRequest) && !isNil(membersRequest.data) ? membersRequest.data.count : null

  const subscriptionPricesRequest = useQuery(client.billing.getSubscriptionPrices(org.id), {
    enabled: !isNil(org.id)
  })

  const priceDetails = !isNil(subscriptionPricesRequest.data)
    ? subscriptionPricesRequest.data.find(
        (details) => details.billingStatus === BillingStatus[plan]
      )
    : undefined

  const price = newPlansMonthlyPrice[plan] * 12

  const finalTotalPrice = !isNil(priceDetails) ? priceDetails?.totalPrice : null
  const finalTaxlessPrice = !isNil(priceDetails) ? priceDetails?.taxlessPrice : null
  const finalTaxAmount =
    !isNil(finalTotalPrice) && !isNil(finalTaxlessPrice)
      ? finalTotalPrice - finalTaxlessPrice
      : null

  const listItems = useMemo(() => {
    const nameItem = {
      label: t('ecommerce.billing.subscription.kind', { capitalize: true }),
      value: t(`ecommerce.plans.${plan}.label`, { capitalize: true })
    }

    const baseItems = [
      {
        label: t('ecommerce.plans.annual_subscription'),
        value: formatPrice(price)
      },
      {
        label: t('ecommerce.plans.members_count'),
        value: !isNil(numberOfMembers) ? numberOfMembers.toString() : null
      },
      {
        label: t('ecommerce.plans.tax_excluded_total'),
        value: !isNil(finalTaxlessPrice) ? formatPrice(finalTaxlessPrice) : null
      },
      {
        label: t('ecommerce.plans.tax_percentage'),
        value: !isNil(finalTaxAmount) ? formatPrice(finalTaxAmount) : null
      },
      {
        label: t('ecommerce.plans.tax_included_total'),
        value: !isNil(finalTotalPrice) ? formatPrice(finalTotalPrice) : null
      }
    ]

    return showPlanName ? [nameItem, ...baseItems] : baseItems
  }, [formatPrice, price, numberOfMembers, finalTaxAmount, finalTaxlessPrice, finalTotalPrice])

  const { isLoading } = mergeQueryState(membersRequest, subscriptionPricesRequest)

  return <DescriptionList isLoading={isLoading} title={title} items={listItems} />
}

export default SubscriptionBillDetails
