import { useIntl } from '@weenat/wintl'
import { useFormikContext } from 'formik'
import isNil from 'lodash-es/isNil'
import Button, { ButtonProps } from './Button'

type SubmitButtonProps = Omit<ButtonProps, 'type'> & {
  topPadded?: boolean
}

const SubmitButton: FC<SubmitButtonProps> = ({ children, topPadded, ...props }) => {
  const { t } = useIntl()
  const { isValid } = useFormikContext()
  const button = (
    <Button
      {...props}
      type='submit'
      data-testid='submit-button'
      isDisabled={props.isDisabled || props.state?.isPending || !isValid}
    >
      {!isNil(children) ? children : t('actions.confirm')}
    </Button>
  )
  if (topPadded) return <Box $pt='lg'>{button}</Box>
  return button
}

export default SubmitButton
