import { Redirect } from 'app/routx-router'
import AcceptPrivacyPolicy from 'app/src/authentication/components/AcceptPrivacyPolicy'
import Flex from 'app/src/kit/primitives/Flex'
import { useToken } from 'app/state'
import { useUserContext } from 'app/userProvider'
import isNil from 'lodash-es/isNil'
import { useTheme } from 'styled-components'

const AcceptPP = () => {
  const [token] = useToken()
  const { colors, radiuses } = useTheme()
  const { profile, requestState } = useUserContext()

  const callToServiceHasBeenMade = requestState.isSuccess && !!profile
  const hasAcceptPP = callToServiceHasBeenMade && profile.hasAcceptedPolicyPrivacy
  const hasNotAcceptPP = callToServiceHasBeenMade && !profile.hasAcceptedPolicyPrivacy

  return !isNil(token) && hasNotAcceptPP ? (
    <Flex $width='100%' $alignItems='center' $justifyContent='center'>
      <Box $backgroundColor={colors.grayscale.white} $p={12} style={{ borderRadius: radiuses.md }}>
        <AcceptPrivacyPolicy />
      </Box>
    </Flex>
  ) : !isNil(token) && hasAcceptPP ? (
    <Redirect to='/' />
  ) : null
}
export const Component = AcceptPP
export default AcceptPP
