import { DeviceFaultEventKind } from '@weenat/client/dist/core/devices/events'
import Icon, { IconProps } from '../Icon'
import {
  SensorEventHorizontalDevice,
  SensorEventLocationChangedWarning,
  SensorEventSignalFound,
  SensorEventSignalLost,
  SensorEventStuckSpoon,
  SensorEventStuckSpoonFrequency
} from './generic-icons'

interface DeviceEventIconProps extends IconProps {
  svgIcon: React.ReactElement
}

const DeviceEventIcon: FC<DeviceEventIconProps> = ({ svgIcon, ...props }) => (
  <Icon {...props}>{svgIcon}</Icon>
)

const DeviceEventIcons: Record<DeviceFaultEventKind, typeof Icon> = {
  [DeviceFaultEventKind.lockedSpoon]: (props: IconProps) => (
    <DeviceEventIcon svgIcon={<SensorEventStuckSpoon />} {...props} />
  ),
  [DeviceFaultEventKind.lockedSpoon10Events]: (props: IconProps) => (
    <DeviceEventIcon svgIcon={<SensorEventStuckSpoonFrequency />} {...props} />
  ),
  [DeviceFaultEventKind.notVertical]: (props: IconProps) => (
    <DeviceEventIcon svgIcon={<SensorEventHorizontalDevice />} {...props} />
  ),
  [DeviceFaultEventKind.gps]: (props: IconProps) => (
    <DeviceEventIcon svgIcon={<SensorEventLocationChangedWarning />} {...props} />
  ),
  [DeviceFaultEventKind.unlockedSpoon]: (props: IconProps) => (
    <DeviceEventIcon svgIcon={<SensorEventStuckSpoon />} {...props} />
  ),
  [DeviceFaultEventKind.noMessages]: (props: IconProps) => (
    <DeviceEventIcon svgIcon={<SensorEventSignalLost />} {...props} />
  ),
  [DeviceFaultEventKind.noMessagesEnd]: (props: IconProps) => (
    <DeviceEventIcon svgIcon={<SensorEventSignalFound />} {...props} />
  )
}

export default DeviceEventIcons
