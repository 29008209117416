import { useIntl } from '@weenat/wintl'
import CollapsibleCard from 'app/src/kit/CollapsibleCard'
import DelimitedFlex from 'app/src/kit/DelimitedFlex'
import EmailLink from 'app/src/kit/EmailLink'
import PhoneLink from 'app/src/kit/PhoneLink'
import Text from 'app/src/kit/Text'
import { memo } from 'react'

interface SectionProps {
  title: string
  description: string
  link?: {
    label: string
    href: string
  }
}

const Section: FC<SectionProps> = ({ title, description }) => {
  return (
    <DelimitedFlex $flexDirection='column' $p='lg' $width='100%'>
      <Text $fontSize='md' $lineHeight='md' $fontWeight='bold'>
        {title}
      </Text>
      <Box $width='100%' $mt='md'>
        <Text $lineHeight='md'>{description}</Text>
      </Box>
    </DelimitedFlex>
  )
}

const LegalNoticeSection = () => {
  const { t } = useIntl()

  return (
    <CollapsibleCard title={t('help.legal_notice_title')} $mb='lg'>
      <Box $pt='lg'>
        <Section
          title={t('help.legal_notice_company_title')}
          description={[t('help.legal_notice_company_1'), t('help.legal_notice_company_2')].join(
            '\n'
          )}
        />

        <Section
          title={t('help.legal_notice_address_title')}
          description={[t('help.legal_notice_address_1'), t('help.legal_notice_address_2')].join(
            '\n'
          )}
        />

        <DelimitedFlex $flexDirection='column' $p='lg'>
          <Text $fontSize='md' $fontWeight='bold' $lineHeight='md'>
            {t('help.legal_notice_contact_title')}
          </Text>
          <PhoneLink phoneNumber={t('weenat.support.phoneNumber2')}>
            {t('weenat.support.phoneNumber2')}
          </PhoneLink>
          <EmailLink email={t('weenat.contact.email')}>{t('weenat.contact.email')}</EmailLink>
        </DelimitedFlex>

        <Section
          title={t('help.legal_notice_hosting_title')}
          description={[t('help.legal_notice_hosting_1'), t('help.legal_notice_hosting_2')].join(
            '\n'
          )}
        />
      </Box>
    </CollapsibleCard>
  )
}

export default memo(LegalNoticeSection)
