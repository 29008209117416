import { Features } from '@weenat/client'
import { canUser, ops } from '@weenat/client/dist/core/access'
import { useIntl } from '@weenat/wintl'
import Modal, { ModalProps } from 'app/src/kit/Modal'
import { TogglePrimitive } from 'app/src/kit/fields/ToggleField'
import { useToggleFeature } from 'app/state'
import { useUserContext } from 'app/userProvider'
import { capitalize } from 'app/utils'
import isNil from 'lodash-es/isNil'
import { useEffect, useState } from 'react'
import { styled, useTheme } from 'styled-components'
import Link from '../kit/LinkComponent'

const ActivateAllToggle = styled(TogglePrimitive)`
  padding-bottom: 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grayscale[100]};
`

const PaddedToggle = styled(TogglePrimitive)`
  padding: 8px 0;
`

interface FeatureToggleModalProps extends ModalProps {}

const FeatureToggleModal = ({ isOpen, close }: FeatureToggleModalProps) => {
  const { t } = useIntl()
  const { colors } = useTheme()
  const [features, setFeatures] = useToggleFeature()
  const { me } = useUserContext()
  const [areAllFeaturesActive, setAllFeatureActive] = useState(
    Object.entries(features).every(([, isActive]) => isActive)
  )

  const canView = !isNil(me) ? canUser(ops.view, 'debugMode', { account: me }) : false

  const handleToggleAll = (value: boolean) => {
    const updatedFeatures: Record<string, boolean> = {}
    const newValue = !value

    Object.entries(features).forEach(([feature]) => {
      updatedFeatures[feature] = newValue
    })

    setAllFeatureActive(newValue)
    setFeatures((feats) => ({ ...feats, ...(updatedFeatures as Features) }))
  }

  /** update global toggle on features change */
  useEffect(() => {
    const updatedAllFeatureActives = Object.entries(features).every(([, isActive]) => isActive)
    setAllFeatureActive(updatedAllFeatureActives)
  }, [features])

  if (!canView) return null

  return (
    <Modal isOpen={isOpen} close={close} title={t('titles.toggle_feature')} width={480}>
      <Box $px='md'>
        <ActivateAllToggle
          label={t(
            areAllFeaturesActive ? 'toggle_features.disable_all' : 'toggle_features.enable_all'
          )}
          onToggle={handleToggleAll}
          value={areAllFeaturesActive}
          $backgroundColor={colors.feedback.success['500']}
          togglePosition='right'
        />
        <Box $py='md'>
          {Object.entries(features).map(([feature, isActive]) => (
            <PaddedToggle
              key={feature}
              value={isActive}
              onToggle={(value: boolean) =>
                setFeatures((feats) => ({ ...feats, [feature]: !value }))
              }
              label={capitalize(feature)}
              togglePosition='right'
            />
          ))}
        </Box>
        <Flex $p='lg' $mt='auto' $justifyContent='center'>
          <Link to='/plots' onPress={close} $underlined>
            {t('actions.go_home', { capitalize: true })}
          </Link>
        </Flex>
      </Box>
    </Modal>
  )
}

export default FeatureToggleModal
