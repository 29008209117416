import { getDeviceCommonName } from '@weenat/client/dist/core/devices'
import { Device } from '@weenat/client/dist/resources/devices'
import Icons from 'app/src/kit/Icons'
import Text from 'app/src/kit/Text'
import { FOCUSED_MARKER_WIDTH } from 'app/src/plots/creation/constants'
import { PlotCreationSearchParams } from 'app/src/plots/creation/types'
import { memo, useState } from 'react'
import { useTheme } from 'styled-components'
import MarkerArrow from './MarkerArrow'

interface DeviceMarkerProps {
  device: Device
  isSelected: boolean
  isFocused: boolean
  step: PlotCreationSearchParams['step']
}

const DeviceMarker: FC<DeviceMarkerProps> = ({ isSelected, device, isFocused, step }) => {
  const { colors } = useTheme()
  const [hover, setHover] = useState<boolean>(false)
  const finalFocused = isFocused || (hover && step === 'location')

  return finalFocused ? (
    <Flex $flexDirection='column' $alignItems='center' onPointerLeave={() => setHover(false)}>
      <Flex
        $gap='md'
        $alignItems='center'
        $justifyContent='space-between'
        $backgroundColor={isSelected ? 'feedback.success.500' : 'primary.500'}
        $borderWidth='sm'
        $borderColor={'grayscale.white'}
        $borderRadius={8}
        $p='md'
      >
        {isSelected ? (
          <Icons.CheckCircle $size='md' $color={'grayscale.white'} />
        ) : (
          <Icons.SingleSensorFilled $size='md' $color={'grayscale.white'} />
        )}
        <Box>
          <Flex $maxWidth={FOCUSED_MARKER_WIDTH} $flexWrap='wrap'>
            <Text $color={'grayscale.white'} $ellipsis $fontWeight='regular' $fontSize='sm'>
              {getDeviceCommonName(device)}
            </Text>
          </Flex>
          <Text $color={'grayscale.white'} $fontSize='sm'>
            {device.endUserName.join(' ')}
          </Text>
        </Box>
      </Flex>
      <MarkerArrow />
    </Flex>
  ) : (
    <Flex $flexDirection='column' $alignItems='center' onPointerEnter={() => setHover(true)}>
      <Flex
        $borderRadius={8}
        $borderColor={'grayscale.white'}
        $borderWidth='sm'
        $backgroundColor={isSelected ? 'feedback.success.500' : colors.grayscale.black}
        $p='md'
        $boxShadow='0px 2px 8px 0px rgba(0, 38, 53, 0.1)'
      >
        {isSelected ? (
          <Icons.CheckCircle $size='md' $color={'grayscale.white'} />
        ) : (
          <Icons.SingleSensorFilled $size='md' $color={'grayscale.white'} />
        )}
      </Flex>
      <MarkerArrow />
    </Flex>
  )
}

export default memo(DeviceMarker)
