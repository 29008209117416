import { useClient, useQuery } from '@weenat/client'
import { canUser, ops } from '@weenat/client/dist/core/access'
import paths from '@weenat/client/dist/core/appPaths'
import { InvitationStatus } from '@weenat/client/dist/enums'
import { Me } from '@weenat/client/dist/resources/me'
import { useIntl } from '@weenat/wintl'
import { Slot } from 'app/routx-router'
import Logout from 'app/src/authentication/components/Logout'
import Icons from 'app/src/kit/Icons'
import Scrollable from 'app/src/kit/Scrollable'
import Text from 'app/src/kit/Text'
import FocusLayout, { FocusLayoutMenuSection } from 'app/src/layouts/FocusLayout'
import ProfileCard from 'app/src/me/ProfileCard'
import { useUserContext } from 'app/userProvider'
import { getVersion } from 'app/utils'
import { isNil } from 'lodash-es'
import { useMemo } from 'react'

const LoggedInLayout = ({ account }: { account: Me }) => {
  const { t } = useIntl()
  const client = useClient()

  const receivedInvitations = useQuery(
    client.invitations.received.getMany({ limit: 1, status: InvitationStatus.pending })
  ).data

  const canViewAdminTools = canUser(ops.view, 'debugMode', { account })

  const title = t('titles.user_settings')

  const menuHeader = (
    <Box $minHeight='64px' $p='lg'>
      <ProfileCard me={account} />
    </Box>
  )

  // trick to force refresh on language change
  // adding user profile language in memo deps doesn't work
  const preferencesTitle = t('me_menu.app_preferences_title')
  const menuSections: FocusLayoutMenuSection[] = useMemo(() => {
    const sections: FocusLayoutMenuSection[] = [
      {
        id: 'preferences-section',
        title: t('titles.preferences'),
        items: []
      }
    ]

    sections[0].items.push(
      {
        id: 'preferences',
        LeftIcon: Icons.DiscoverTune,
        to: '/me/preferences',
        label: preferencesTitle,
        description: t('me_menu.preferences_subtitle'),
        matchExactPath: true
      },
      {
        id: 'notifications_preferences',
        LeftIcon: Icons.Notifications,
        to: '/me/preferences/notifications',
        label: t('breadcrumbs./me/preferences/notifications'),
        description: t('models.notificationPreference.description'),
        matchExactPath: true
      }
    )

    sections.push(
      {
        id: 'my-account-section',
        title: t('me_menu.my_account_group'),
        items: [
          {
            id: 'account-settings',
            LeftIcon: Icons.AccountSettings,
            to: '/me/account',
            description: t('me_menu.security_privacy_subtitle'),
            label: t('me_menu.security_privacy_title'),
            matchExactPath: true
          },
          {
            id: 'authorized-devices',
            LeftIcon: Icons.AuthorizedDevice,
            to: '/me/mobile-devices',
            description: t('me_menu.devices_subtitle'),
            label: t('titles.mobile_devices'),
            matchExactPath: true
          },
          {
            id: 'external-connections',
            to: paths.me.externalConnections,
            label: t('titles.external_connection'),
            description: t('titles.external_connection_baseline'),
            LeftIcon: Icons.ExternalConnexions,
            matchExactPath: false
          }
        ]
      },
      {
        id: 'contact-section',
        title: t('me_menu.contact_group'),
        items: [
          {
            id: 'contact-information',
            LeftIcon: Icons.Phone,
            label: t('breadcrumbs./me/contact-information'),
            to: '/me/contact-information',
            description: t('me_menu.contact_infos_subtitle'),
            matchExactPath: true
          },
          {
            id: 'invitations',
            LeftIcon: Icons.Inbox,
            label: t('breadcrumbs./me/invitations'),
            to: '/me/invitations',
            indicator: receivedInvitations?.count ?? 0,
            matchExactPath: false
          }
        ]
      }
    )

    if (canViewAdminTools) {
      sections.push({
        id: 'admin-tools',
        title: t('me_menu.admin_tool_group'),
        items: [
          {
            id: 'debug',
            to: paths.me.debug,
            label: t('titles.debug'),
            LeftIcon: Icons.Bug,
            matchExactPath: true
          },
          {
            id: 'feature_toggle',
            to: paths.me.featureToggle,
            label: t('titles.toggle_feature'),
            LeftIcon: Icons.New,
            matchExactPath: true
          },
          {
            id: 'cluster_settings',
            to: paths.me.clusterSettings,
            label: t('titles.cluster_settings'),
            LeftIcon: Icons.Cluster,
            matchExactPath: true
          }
        ]
      })
    }

    return sections
  }, [canViewAdminTools, receivedInvitations?.count, t, preferencesTitle])

  const menuFooter = useMemo(
    () => (
      <Flex $flexDirection='column' $alignItems='center' $minHeight='96px' $p='lg' $gap='md'>
        <Logout />
        <Text $textAlign='center'>{`${t('titles.version')}: ${getVersion()}`}</Text>
      </Flex>
    ),
    [t]
  )

  return (
    <FocusLayout
      title={title}
      titleTemplate={`${title} / %s - Weenat`}
      defaultTitle={title}
      menuHeader={menuHeader}
      menuFooter={menuFooter}
      menuSections={menuSections}
    >
      <Scrollable>
        <Slot />
      </Scrollable>
    </FocusLayout>
  )
}

const Layout = () => {
  const { me } = useUserContext()
  return !isNil(me) ? <LoggedInLayout account={me} /> : <Slot />
}

export const Component = Layout
export default Layout
