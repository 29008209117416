import { useListItem } from '@floating-ui/react'
import Text from 'app/src/kit/Text'
import noop from 'lodash-es/noop'
import { HTMLProps, MouseEventHandler, ReactNode, forwardRef } from 'react'
import { styled, useTheme } from 'styled-components'
import Checkbox from '../../Checkbox'
import { useDropdownContext } from './SelectDropdown'

interface ContainerProps {
  $color: string
  $isActive: boolean
  $isDisabled?: boolean
  $isSelected: boolean
}

const Container = styled(Flex)<ContainerProps>`
  align-items: center;
  gap: 8px;
  border-top: 1px solid ${(props) => props.theme.colors.grayscale[100]};

  background-color: ${(props) =>
    props.$isActive || props.$isSelected ? props.theme.colors.primary[200] : 'transparent'};

  opacity: ${(props) => (props.$isDisabled ? 0.4 : 1)};

  padding: ${(p) => p.theme.spacings.md}px;

  cursor: ${(props) => (props.$isDisabled ? 'not-allowed' : 'pointer')};

  transition:
    color 0.3s ease,
    background-color 0.3s ease;

  p {
    color: ${(props) =>
      props.$isSelected && !props.$isDisabled ? props.theme.colors.primary[500] : 'inherit'};
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.primary[200]};

    p {
      color: ${(props) => props.color};
    }
  }

  .check-icon svg {
    fill: ${(props) => props.$color};
  }

  &:hover .check-icon svg {
    fill: ${(props) => props.theme.colors.grayscale.black};
  }
`

interface PureSelectOptionItemProps {
  children?: ReactNode
  isMultiChoice?: boolean
  isDisabled?: boolean
  isSelected: boolean
  isActive?: boolean
  textColor?: string
  /** used onClick rather than onPress to match with floating-ui api */
  onClick?: MouseEventHandler<HTMLDivElement>
  onKeyDown?: HTMLProps<HTMLDivElement>['onKeyDown']
}

/**
 * A SelectOptionItem working on its own
 */
export const PureSelectOptionItem = forwardRef<HTMLDivElement, PureSelectOptionItemProps>(
  (
    {
      children,
      textColor,
      isMultiChoice = false,
      isSelected = false,
      isDisabled = false,
      onClick = noop,
      isActive = false,
      onKeyDown
    },
    ref
  ) => {
    const { colors } = useTheme()

    const color = isSelected ? colors.grayscale.white : textColor ?? colors.grayscale.black

    return (
      <Container
        ref={ref}
        role='option'
        onClick={!isDisabled ? onClick : noop}
        $isSelected={isSelected}
        $isActive={isActive}
        $isDisabled={isDisabled}
        $color={color}
        onKeyDown={onKeyDown}
      >
        {isMultiChoice ? <Checkbox checked={isSelected} /> : null}
        {typeof children === 'string' ? (
          <Text $ellipsis $color={color}>
            {children}
          </Text>
        ) : (
          children
        )}
      </Container>
    )
  }
)

type SelectOptionItemProps = Omit<PureSelectOptionItemProps, 'onKeyDown' | 'isActive'>

/**
 * Has to be used inside a Dropdown context
 */
const SelectOptionItem: FC<SelectOptionItemProps> = ({
  children,
  textColor,
  isMultiChoice = false,
  isSelected = false,
  isDisabled = false,
  onClick = noop
}) => {
  const { interactions, onVisibleChange, activeIndex } = useDropdownContext()
  const { ref, index } = useListItem()

  const isActive = index === activeIndex

  const handleSelect: MouseEventHandler<HTMLDivElement> = (event) => {
    onClick(event)
    if (!isMultiChoice) {
      onVisibleChange(false)
    }
  }

  return (
    <PureSelectOptionItem
      ref={ref}
      isSelected={isSelected}
      isActive={isActive}
      isDisabled={isDisabled}
      isMultiChoice={isMultiChoice}
      textColor={textColor}
      {...interactions.getItemProps({
        // Handle pointer select.
        onClick: handleSelect,
        // Handle keyboard select.
        onKeyDown: (event) => {
          if (event.key === 'Enter') {
            event.preventDefault()
            handleSelect(event)
          }

          if (event.key === ' ') {
            event.preventDefault()
            handleSelect(event)
          }
        }
      })}
    >
      {children}
    </PureSelectOptionItem>
  )
}

export default SelectOptionItem
