import { useField } from 'formik'
import isNil from 'lodash-es/isNil'
import { forwardRef, HTMLProps, useEffect, useRef, useState } from 'react'
import { css, styled } from 'styled-components'
import getDerivedInputPropsFromType from './getDerivedInputPropsFromType'
import InputFieldContainer, { InputFieldContainerProps } from './InputFieldContainer'
import useBackendFieldError from './useBackendFieldError'

type InputType =
  | 'number'
  | 'email'
  | 'password'
  | 'confirmationPassword'
  | 'firstName'
  | 'lastName'
  | 'telephoneNumber'
  | 'organizationName'
  | 'addressLine1'
  | 'addressLine2'
  | 'zipcode'
  | 'city'
  | 'country'
  | 'cardOwnerName'
  | 'cardNumber'
  | 'cardExpiry'
  | 'cardValidationCode'

type FieldErrorState = {
  nonFieldErrors?: unknown
  fieldErrors?: Record<string, unknown>
} | null

type HTMLInputProps = Omit<HTMLProps<HTMLInputElement>, 'children' | 'label'>
type IFCProps = Omit<InputFieldContainerProps, 'children' | 'value'>

interface TextFieldPrimitiveProps extends HTMLInputProps, IFCProps {
  $caretHidden?: boolean
  errorPath?: string
  $displayedError?: React.ReactElement | string | null
  errorState?: FieldErrorState
  focus?: boolean
  hideErrors?: boolean
  inputType?: InputType
  isTextEntryDisabled?: boolean
  /** Whether of not show the input value */
  $isValueInvisible?: boolean
}

export interface TextFieldProps
  extends Omit<TextFieldPrimitiveProps, 'onClear' | 'name' | 'hideErrors'> {
  name: string
}

export type CustomFieldProps = Partial<TextFieldProps>

const StyledTextInput = styled.input<Pick<TextFieldProps, '$isValueInvisible' | '$caretHidden'>>`
  font-family: ${(p) => p.theme.typography.families.pm};
  font-size: ${(p) => p.theme.typography.sizes.rg}px;
  font-weight: ${(p) => p.theme.typography.weights.regular};
  color: ${(p) => p.theme.colors.grayscale.black};
  background-color: ${(p) => p.theme.colors.grayscale[100]};
  line-height: normal;

  border: 0;
  width: 100%;

  ::-ms-reveal,
  ::-ms-clear {
    display: none;
  }

  ${(p) =>
    p.$isValueInvisible &&
    css`
      color: transparent;
      width: 0;
      /** Otherwise FF is not happy and won't display the placeholder */
      ::placeholder {
        color: ${(p) => p.theme.colors.grayscale.black};
      }
    `}
  ${(p) =>
    p.$caretHidden &&
    css`
      caret-color: transparent;
    `}
`

export const TextFieldPrimitive = forwardRef<HTMLInputElement, TextFieldPrimitiveProps>(
  (props, forwardedRef) => {
    const {
      autoComplete = 'on',
      autoCorrect = 'off',
      $caretHidden = false,
      className,
      $displayedError: displayedError,
      focus,
      helpers = null,
      hideErrors = false,
      isClearable = false,
      $isDisabled: isDisabled,
      $isPointerCursor = false,
      isRequired = true,
      isTextEntryDisabled = false,
      $isValueInvisible = false,
      label,
      leftAdornment = null,
      maxLength,
      onBlur,
      onChange,
      onClear,
      onFocus,
      placeholder,
      rightAdornment = null,
      spellCheck = false,
      type = 'text',
      value,
      warning,
      name,
      id,
      isLoading,
      inputMode,
      inputType
    } = props

    const derivedProps = getDerivedInputPropsFromType({
      type,
      name,
      autoComplete,
      inputType,
      inputMode,
      maxLength
    })

    const [isFocused, setIsFocused] = useState(false)

    const textInputRef = useRef<HTMLInputElement>(null)
    const ref = forwardedRef || textInputRef

    const handleFocus = () => ref.current?.focus()

    useEffect(() => {
      if (focus) handleFocus()
    }, [focus])

    return (
      <InputFieldContainer
        className={className}
        hideErrors={hideErrors}
        $displayedError={displayedError}
        helpers={helpers}
        isClearable={isClearable}
        $isDisabled={isDisabled}
        $isFocused={isFocused}
        isLoading={isLoading}
        $isPointerCursor={$isPointerCursor}
        isRequired={isRequired}
        label={label}
        leftAdornment={leftAdornment}
        onClear={onClear}
        onPress={
          isDisabled
            ? undefined
            : (e) => {
                e.stopPropagation()
                handleFocus()
              }
        }
        rightAdornment={rightAdornment}
        value={value}
        warning={warning}
      >
        <StyledTextInput
          data-testid={`${!isNil(id) ? id : name}-input`}
          autoCorrect={autoCorrect}
          $caretHidden={$caretHidden}
          disabled={isDisabled || isTextEntryDisabled || isLoading}
          $isValueInvisible={$isValueInvisible}
          ref={ref}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={(e) => {
            onFocus?.(e)
            setIsFocused(true)
          }}
          onBlur={(e) => {
            onBlur?.(e)
            setIsFocused(false)
          }}
          spellCheck={spellCheck}
          required={isRequired}
          {...derivedProps}
          autoComplete={autoComplete}
          maxLength={maxLength}
          type={isTextEntryDisabled ? 'hidden' : type}
        />
      </InputFieldContainer>
    )
  }
)

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    { name, $displayedError: errorFromProps, errorPath, errorState, isRequired, onBlur, ...props },
    forwardedRef
  ) => {
    const [field, meta, fieldHelpers] = useField(name)

    /** Custom error take precedence */
    const finalError = !isNil(errorFromProps) ? errorFromProps : meta.error
    const displayedError = meta.touched ? finalError : null

    useBackendFieldError({
      name,
      errorPath: !isNil(errorPath) ? errorPath : name,
      errorState
    })

    return (
      <TextFieldPrimitive
        name={name}
        value={field.value}
        onChange={(e) => {
          fieldHelpers.setValue(e.currentTarget.value)
        }}
        $displayedError={displayedError}
        isRequired={isRequired}
        ref={forwardedRef}
        // Not setting undefined since inputs in react should always be defined
        // https://react.dev/reference/react-dom/components/input#im-getting-an-error-a-component-is-changing-an-uncontrolled-input-to-be-controlled
        onClear={() => {
          fieldHelpers.setValue('')
        }}
        onBlur={(e) => {
          field.onBlur(e)
          onBlur?.(e)
        }}
        {...props}
      />
    )
  }
)

export default TextField
