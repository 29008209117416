import { styled } from 'styled-components'
import {
  FlexProps,
  alignItemsCss,
  boxable,
  flexBasisCss,
  flexDirectionCss,
  flexShrinkCss,
  flexWrapCss,
  gapCss,
  justifyContentCss
} from './themeMappings/props'

const Flex = styled.div<FlexProps>`
  display: flex;
  flex-direction: row;
  ${boxable}
  ${gapCss}
  ${justifyContentCss}
  ${alignItemsCss}
  ${flexDirectionCss}
  ${flexWrapCss}
  ${flexBasisCss}
  ${flexShrinkCss}
`

export default Flex
