import { useCallback, useState } from 'react'

// Generic solution to boring boilerplate to open a modal
const useDisclosure = (initiallyOpen = false) => {
  const [isOpen, setIsOpen] = useState(initiallyOpen)
  const open = useCallback(() => setIsOpen(true), [])
  const close = useCallback(() => setIsOpen(false), [])
  const toggle = isOpen ? close : open
  return {
    toggle,
    open,
    close,
    isOpen
  }
}

export default useDisclosure
