import { AlertCreationType, getAvailableOperationsAsOptions } from '@weenat/client/dist/core/alerts'
import { Operation, Source } from '@weenat/client/dist/resources/alerts.types'
import Pop from 'app/src/kit/Pop'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import { useMemo, useState } from 'react'
import { styled } from 'styled-components'
import AlertCreationStep, { AlertCreationStepProps } from './AlertCreationStep'

interface OperationAsOption {
  value: Operation
  label: string
  description: string
}

const OperationCardContainer = styled(Box)<{ $isSelected: boolean }>`
  padding: 16px;
  margin-bottom: 8px;
  border-radius: ${(p) => p.theme.radiuses.md}px;
  border: 2px solid;
  border-color: ${(props) =>
    props.$isSelected ? props.theme.colors.primary[500] : props.theme.colors.grayscale[300]};
  background-color: ${(props) =>
    props.$isSelected ? props.theme.colors.primary[200] : 'transparent'};

  &:hover {
    cursor: pointer;
    border-color: ${(p) => p.theme.colors.primary[500]};
  }
`

interface OperationCardProps {
  label: string
  description: string
  isSelected: boolean
  onPress: () => void
}

const OperationCard: FC<OperationCardProps> = ({ description, label, isSelected, onPress }) => (
  <OperationCardContainer $isSelected={isSelected} onClick={onPress}>
    <Text $fontWeight='bold'>{label}</Text>
    <Text>{description}</Text>
  </OperationCardContainer>
)

type OperationStepProps = AlertCreationStepProps<Operation>

interface PickPredicateOperationProps extends OperationStepProps {
  initialOperation?: Operation
  source: Source
  currentAlertCreationType: AlertCreationType
}

const PickPredicateOperation: FC<PickPredicateOperationProps> = ({
  initialOperation,
  onPressNext,
  source,
  currentAlertCreationType,
  ...props
}) => {
  const [selectedOp, setSelectedOp] = useState<Operation | undefined>(initialOperation)

  /** Sort available operation depending on the given source */
  const availableOperationsAsOptions: OperationAsOption[] = useMemo(() => {
    return getAvailableOperationsAsOptions(source, currentAlertCreationType)
  }, [source])

  return (
    <AlertCreationStep
      canProgressToNext={!isNil(selectedOp)}
      onPressNext={() => onPressNext?.(selectedOp)}
      {...props}
    >
      <Pop tourName='discover_alerts' stepName='pick_op'>
        {/* List of allowed operations/events */}
        {availableOperationsAsOptions.map((op) => {
          const isSelected = selectedOp === op.value

          return (
            <OperationCard
              key={op.value}
              isSelected={isSelected}
              label={op.label}
              description={op.description}
              onPress={() => {
                if (isSelected) {
                  setSelectedOp(undefined)
                } else {
                  setSelectedOp(op.value)
                }
              }}
            />
          )
        })}
      </Pop>
    </AlertCreationStep>
  )
}

export default PickPredicateOperation
