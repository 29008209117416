import { useClient, useQuery } from '@weenat/client'
import { canUser, ops } from '@weenat/client/dist/core/access'
import {
  SwitchUnivAggParams,
  SwitchUnivMetricParams,
  SwitchUniverseParams
} from '@weenat/client/dist/core/analytics'
import { useLoginAs, useToken } from 'app/state'
import { useUserContext } from 'app/userProvider'
import {
  EventParams,
  logEvent as firebaseLogEvent,
  getAnalytics,
  setUserProperties
} from 'firebase/analytics'
import isNil from 'lodash-es/isNil'
import { useEffect } from 'react'

const logEvent = (eventName: string, params?: EventParams) => {
  const analytics = getAnalytics()

  firebaseLogEvent(analytics, eventName, params)
}

const logEventFb = (
  eventName: string,
  params?: Record<string, unknown>,
  type: FbqTrackingType = 'trackCustom'
) => {
  if (!isNil(fbq) && typeof fbq === 'function') {
    fbq(type, eventName, params)
  }
}

const logAll = (eventName: string, params?: Record<string, unknown>) => {
  logEvent(eventName, params)
  logEventFb(eventName, params)
}

export const useInitTracking = () => {
  const client = useClient()
  const [token] = useToken()
  const [loginAs] = useLoginAs()
  const { me } = useUserContext()

  const myInfoRequest = useQuery(client.me.getMyInfo(), {
    enabled: !isNil(token)
  })

  const myInfo = myInfoRequest.data

  useEffect(() => {
    if (!isNil(me) || !isNil(myInfo)) {
      const analyticsInstance = getAnalytics()
      if (!isNil(me)) {
        const isStaff = loginAs != null || canUser(ops.view, 'debugMode', { account: me })
        setUserProperties(analyticsInstance, { isStaff: `${isStaff}` })
      }
      if (!isNil(myInfo)) {
        if (myInfo.subscriptions.expert > 0 || myInfo.subscriptions.plus > 0) {
          setUserProperties(analyticsInstance, { premium: 'true' })
        } else {
          setUserProperties(analyticsInstance, { freemium: 'true' })
        }
        if (myInfo.subscriptions.expert > 0) {
          setUserProperties(analyticsInstance, { plan: 'expert' })
        } else if (myInfo.subscriptions.plus > 0) {
          setUserProperties(analyticsInstance, { plan: 'plus' })
        } else {
          setUserProperties(analyticsInstance, { plan: 'essential' })
        }
      }
    }
  }, [loginAs, me, myInfo])
}

export const logRegistered = () => {
  logEvent('registered')
  logEventFb('CompleteRegistration', undefined, 'track')
}

export const logNavigateOnRegistration = () => {
  logAll('register_page_view')
}

export const logSubscribeWeenatEssential = (isFromNetwork: boolean) => {
  logAll('subscribe_weenat_essential', { fromNetwork: isFromNetwork })
}

export const logSubscribeWeenatPlus = () => {
  logAll('subscribe_weenat_plus')
}
export const logSwitchUniverse = (params: SwitchUniverseParams) => {
  logEvent(params.action, params)
}
export const logSwitchUnivAgg = (params: SwitchUnivAggParams) => {
  logEvent(params.action, params)
}
export const logSwitchUnivMetric = (params: SwitchUnivMetricParams) => {
  logEvent(params.action, params)
}

export default logEvent
