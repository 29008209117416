import {
  getPredicateMetric,
  getSourceLabel,
  useGetThresholdFromParams
} from '@weenat/client/dist/core/alerts/alerts'
import { useConvertedValue } from '@weenat/client/dist/hooks'
import {
  Alert,
  Predicate,
  getPredicateKey,
  isAppleScabParams,
  isBased,
  isCumul,
  isForecasted,
  isWaterStatusThresholded
} from '@weenat/client/dist/resources/alerts.types'
import { useIntl } from '@weenat/wintl'
import Button from 'app/src/kit/Button'
import Pop from 'app/src/kit/Pop'
import Table, { TableCell, TableColumn } from 'app/src/kit/Table'
import Text from 'app/src/kit/Text'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import isNumber from 'lodash-es/isNumber'
import moment from 'moment-timezone'
import { useCallback, useMemo } from 'react'
import Icons from '../kit/Icons'
import TextTooltip from '../kit/tooltips/TextTooltip'

const SMALLER_CELL_WIDTH = 96

interface PredicateRowProps {
  isAllowingEdition?: boolean
  onEdit: () => void
  onRemove: () => void
  predicate: Predicate
}

const PredicateRow: FC<PredicateRowProps> = ({
  isAllowingEdition = true,
  onEdit,
  onRemove,
  predicate
}) => {
  const { t } = useIntl()
  const { source, op, params } = predicate
  const { getUnit, formatConvertedValue } = useConvertedValue()

  const key = getPredicateKey(predicate)
  const metric = getPredicateMetric(predicate)
  const unit = getUnit(metric)

  const thresholdValue = useGetThresholdFromParams(params)

  const appendUnit = (value: typeof thresholdValue) =>
    isNumber(value)
      ? metric === 'LW_D'
        ? `${value} ${t(`units.hours_abbr`)}`
        : !isNil(unit)
          ? `${formatConvertedValue({ metric, value, maximumFractionDigits: 2 })} ${t(
              `units.${unit}_abbr`
            )}`
          : ''
      : ''

  return (
    <>
      <TableCell $ellipsis>{!isNil(source) && <>{getSourceLabel(source)}</>}</TableCell>
      <TableCell $ellipsis>
        {!isNil(op) ? (
          <>
            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              !isEmpty(t(`alerts.predicateKeys.${key}.label`))
                ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  t(`alerts.predicateKeys.${key}.label`)
                : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  t(`alerts.operators.${op}.label`)
            }
          </>
        ) : null}
      </TableCell>
      <TableCell $ellipsis>
        {!isNil(thresholdValue) && !isNil(params) ? (
          <>
            {isAppleScabParams(params) && !isNumber(thresholdValue) ? (
              <Text $fontSize='sm'>{thresholdValue.infectionLevels}</Text>
            ) : isWaterStatusThresholded(params) && !isNumber(thresholdValue) ? (
              <Box>
                <Text $fontSize='sm'>{thresholdValue.horizons}</Text>
                <Text $fontSize='sm'>{thresholdValue.thresholds}</Text>
              </Box>
            ) : isForecasted(params) ? (
              <>
                {t(
                  'alerts.paramSetups.fieldLabels.amountInHours.optionsLabel',
                  { numberOfHours: thresholdValue },
                  { capitalize: true }
                )}
              </>
            ) : !isCumul(params) ? (
              <>{appendUnit(thresholdValue)}</>
            ) : (
              <Box>
                {isBased(params) ? (
                  <Text $fontSize='sm'>
                    {`${t('models.alert.fields.base.label', { capitalize: true })} ${params.base}`}
                  </Text>
                ) : null}
                {!isNil(params.since) ? (
                  <Text $fontSize='sm'>
                    {moment.unix(params.since).format(t('formats.datetime_chart'))}
                  </Text>
                ) : null}

                <Text $fontSize='sm'>{appendUnit(thresholdValue)}</Text>
                {params.isRepeatable ? (
                  <Text $fontSize='sm'>
                    {t('alerts.paramSetups.fieldLabels.repeatable_abbrev')}
                  </Text>
                ) : null}
              </Box>
            )}
          </>
        ) : null}
      </TableCell>
      {isAllowingEdition && (
        <TableCell $width={SMALLER_CELL_WIDTH}>
          <Flex $alignItems='center' $justifyContent='flex-end'>
            <TextTooltip content={t('alerts.edit_predicate', { capitalize: true })}>
              <Icons.Edit $ml='md' $size='lg' onPress={onEdit} />
            </TextTooltip>
            <TextTooltip content={t('alerts.delete_predicate', { capitalize: true })}>
              <Icons.Bin $ml='md' $size='lg' onPress={onRemove} />
            </TextTooltip>
          </Flex>
        </TableCell>
      )}
    </>
  )
}

interface PredicateTableProps {
  isAllowingEdition?: boolean
  onPredicateAddition?: (predicates: Alert['predicates']) => void
  onPredicateDeletion?: (idx: number) => void
  onPredicateEdition?: (idx: number) => void
  predicates: Alert['predicates']
}

const PredicateTable: FC<PredicateTableProps> = ({
  isAllowingEdition = true,
  onPredicateAddition,
  onPredicateDeletion,
  onPredicateEdition,
  predicates
}) => {
  const { t } = useIntl()
  const valuesFromPredicates = useMemo(() => Object.values(predicates), [predicates])

  const columns = useMemo(() => {
    const results: TableColumn[] = []

    results.push(
      { label: t('alerts.predicate_source'), width: 1 },
      { label: t('alerts.predicate_op'), width: 1 },
      { label: t('alerts.predicate_parameters'), width: 1 }
    )

    if (isAllowingEdition) results.push({ label: '', width: '96px' })

    return results
  }, [isAllowingEdition, t])

  const renderItem = useCallback(
    (pdc: Predicate, idx: number) => {
      return (
        <PredicateRow
          key={`${getPredicateKey(pdc)}`}
          predicate={pdc}
          onRemove={() => onPredicateDeletion?.(idx)}
          onEdit={() => onPredicateEdition?.(idx)}
          isAllowingEdition={isAllowingEdition}
        />
      )
    },
    [onPredicateDeletion, onPredicateEdition, isAllowingEdition]
  )

  return (
    <Box>
      <Table
        outlined
        data={valuesFromPredicates}
        renderRow={renderItem}
        keyExtractor={getPredicateKey}
        tableName='alert-predicates'
        columns={columns}
        emptyMessage={t('alerts.no_predicates', { capitalize: true })}
      />
      {isAllowingEdition && (
        <Pop tourName='discover_alerts' stepName='add_other_predicate' placement='bottom'>
          <Flex $width='100%' $p='md' $alignItems='center' $justifyContent='center'>
            <Button
              importance='sd'
              onPress={() => onPredicateAddition?.(predicates)}
              IconLeft={Icons.PlusSignCircle}
            >
              {t('alerts.add_more_predicates', { capitalize: true })}
            </Button>
          </Flex>
        </Pop>
      )}
    </Box>
  )
}

export default PredicateTable
