import { useClient } from '@weenat/client'
import {
  ALERTS_NOTIFICATIONS_SET,
  DEVICES_NOTIFICATIONS_SET,
  NotificationFilterCategoryId,
  ORG_ADMIN_NOTIFICATIONS_SET,
  useNotificationFilterCategories
} from '@weenat/client/dist/core/notifications'
import {
  GetManyNotificationsQueryParams,
  Notification,
  NotificationStatus
} from '@weenat/client/dist/resources/notifications'
import { useIntl } from '@weenat/wintl'
import ListEmpty from 'app/src/kit/ListEmpty'
import { useToken } from 'app/state'
import { FilterCategory } from 'app/utils/lists'
import isNil from 'lodash-es/isNil'
import noop from 'lodash-es/noop'
import { useCallback, useMemo } from 'react'
import AdvancedClientList from '../kit/AdvancedClientList'
import NotificationListItem, { NotificationListItemProps } from './NotificationListItem'

type FilterCategoryId = 'notification-type'

interface NotificationsListProps extends Pick<NotificationListItemProps, 'onNotificationPress'> {
  isShowingUnread: boolean
}

const NotificationsList = ({ isShowingUnread, onNotificationPress }: NotificationsListProps) => {
  const client = useClient()
  const [token] = useToken()
  const { t } = useIntl()

  const notificationFilterCategories = useNotificationFilterCategories()

  const notificationsRequestParams: GetManyNotificationsQueryParams = useMemo(
    () => ({
      offset: 0,
      limit: 20,
      status: isShowingUnread ? NotificationStatus.unread : NotificationStatus.read
    }),
    [isShowingUnread]
  )

  const renderNotificationItem = useCallback(
    (notif: Notification) => {
      let categoryId: NotificationFilterCategoryId | undefined

      if (ALERTS_NOTIFICATIONS_SET.includes(notif.type)) {
        categoryId = 'alerts'
      } else if (ORG_ADMIN_NOTIFICATIONS_SET.includes(notif.type)) {
        categoryId = 'org-administration'
      } else if (DEVICES_NOTIFICATIONS_SET.includes(notif.type)) {
        categoryId = 'equipment-park'
      }

      const category = notificationFilterCategories.find((cat) => cat.id === categoryId)

      return (
        <NotificationListItem
          key={notif.id}
          notification={notif}
          onMarkedAsRead={noop}
          onNotificationPress={onNotificationPress}
          hideReadButton={!isShowingUnread}
          category={category}
        />
      )
    },
    [onNotificationPress, isShowingUnread, notificationFilterCategories]
  )

  const filterCategories: FilterCategory<FilterCategoryId, GetManyNotificationsQueryParams>[] = [
    {
      id: 'notification-type',
      label: t('models.alert.fields.type.label', { capitalize: true }),
      multi: true,
      queryParamKey: 'type',
      filters: notificationFilterCategories.map((cat) => {
        return {
          id: cat.name,
          value: cat.types,
          label: cat.name,
          active: false
        }
      })
    }
  ]

  return (
    <AdvancedClientList
      hideBottomNav={false}
      filterCategories={filterCategories}
      enableQueries={!isNil(token)}
      model='notification'
      ListEmptyComponent={<ListEmpty model='notification' />}
      renderItem={renderNotificationItem}
      initialQueryParams={notificationsRequestParams}
      requesterBuilder={client.notifications.getPage}
      filtersBarMargin={'lg'}
    />
  )
}

export default NotificationsList
