import { useIntl } from '@weenat/wintl'
import Text from 'app/src/kit/Text'
import Link from './LinkComponent'

const GoHomeLink = () => {
  const { t } = useIntl()
  return (
    <Link to='/' $underlined>
      <Text $fontSize='sm'>{t('actions.go_home')}</Text>
    </Link>
  )
}

export default GoHomeLink
