import { LinkProps as RouterLinkProps } from '@weenat/client/dist/routx/runtime-core'
import { Link as RouterLink, useNavigate } from 'app/routx-router'
import isNil from 'lodash-es/isNil'
import {
  Children,
  HTMLProps,
  MouseEvent,
  cloneElement,
  forwardRef,
  useCallback,
  useEffect
} from 'react'
import { DefaultTheme, css, styled } from 'styled-components'
import { SetOptional } from 'type-fest'

export type StyledLinkProps = SetOptional<RouterLinkProps, 'to'> & {
  $active?: boolean
  onActive?: () => void
  cancelActiveForward?: boolean
  $withoutHoverEffect?: boolean
  $underlined?: boolean
  $textAlign?: string
  $color?: string
  $disabled?: boolean
  children?: React.ReactNode
  $fontWeight?: keyof DefaultTheme['typography']['weights']
} & Pick<HTMLProps<HTMLAnchorElement>, 'href' | 'target'>

const anchorStyle = css<StyledLinkProps>`
  all: unset;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  cursor: pointer;

  ${({ $disabled: $disabled, $withoutHoverEffect }) =>
    $disabled
      ? `
          cursor: not-allowed;
          opacity: 0.75;
        `
      : $withoutHoverEffect
        ? ''
        : `
      &:hover {
       opacity: 0.75;
      }
      &:active {
        opacity: 0.5;
      }`}

  ${({ $underlined }) =>
    $underlined &&
    `
      text-decoration: underline;
    `}

  ${({ $textAlign }) =>
    !isNil($textAlign) &&
    `
      text-align: ${$textAlign};
    `}

  ${({ $color }) =>
    !isNil($color) &&
    `
      color: ${$color};
    `}

  ${({ $fontWeight }) =>
    !isNil($fontWeight) &&
    `
      font-weight: ${$fontWeight};
    `}
`

const StyledAnchor = styled.a<StyledLinkProps>`
  ${anchorStyle}
`

const StyledRouterLink = styled(RouterLink)`
  ${anchorStyle}
`

interface AnchorLinkProps extends Omit<StyledLinkProps, '$active'>, HTMLProps<HTMLAnchorElement> {
  active?: boolean
}

const AnchorLink = forwardRef<HTMLAnchorElement, AnchorLinkProps>(
  ({ active, onActive, children, cancelActiveForward, ...rest }, ref) => {
    useEffect(() => {
      if (active && onActive) onActive()
      return undefined
    }, [active])

    return (
      <StyledAnchor $active={active} {...rest} ref={ref}>
        {/* FIXME: is it useful */}
        {/* pass down the active prop to link Children so it can be used in styled components */}
        {Children.map(children, (child) => {
          return typeof child === 'object' && !cancelActiveForward
            ? cloneElement(child, { $active: active })
            : child
        })}
      </StyledAnchor>
    )
  }
)

export interface LinkProps extends AnchorLinkProps {
  disabled?: boolean
  replace?: boolean
  onPress?: (e?: MouseEvent<HTMLAnchorElement>) => void
  cancelActiveForward?: boolean
}

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      to,
      disabled,
      replace,
      onPress,
      active,
      search,
      onActive: _,
      cancelActiveForward: _cancelActiveForward,
      ...props
    },
    ref
  ) => {
    const nav = useNavigate()

    const handleClick = useCallback(
      (e: MouseEvent<HTMLAnchorElement>) => {
        if (!isNil(onPress)) {
          onPress(e)
        }
        if (replace && !isNil(to)) {
          e.preventDefault()
          nav(to, { replace: true, search })
        }
        if (disabled) {
          e.preventDefault()
        }
      },
      // do not add nav to the dependency array
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onPress, replace, to, disabled, search]
    )

    // if link is not an internal link
    // (e.g. a link wth an href prop to an external website)
    // return the LinkComponent directly
    if (isNil(to)) {
      return <AnchorLink onClick={handleClick} $disabled={disabled} {...props} ref={ref} />
    }

    return (
      <StyledRouterLink
        to={to}
        $active={active}
        onClick={handleClick}
        $disabled={disabled}
        search={search}
        {...props}
        ref={ref}
      />
    )
  }
)

export default Link
