import { themes } from '@weenat/theme'
import { styled } from 'styled-components'

const lightTheme = themes.light

const MarkerArrow = styled.div.attrs({})`
  width: 0px;
  height: 0px;
  background-color: transparent;
  border-top-width: 0px;
  border-left-width: 4px;
  border-right-width: 4px;
  border-bottom-width: 6px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-style: solid;
  border-bottom-color: ${lightTheme.colors.grayscale.white};
  transform: rotate(180deg);
`

export default MarkerArrow
