import {
  buildConflictsFromIdsRecord,
  DeviceIdsRecord,
  DuplicatedMetricDeviceConflict,
  IncompatibleMetricsConflict
} from '@weenat/client/dist/core/plots/useUpdateOrgPlotDevices'
import { Device } from '@weenat/client/dist/resources/devices'
import { useIntl } from '@weenat/wintl'
import Button from 'app/src/kit/Button'
import Modal from 'app/src/kit/Modal'
import Text from 'app/src/kit/Text'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import SelectableDeviceCard from './SelectableDeviceCard'

const DeviceCard = styled(SelectableDeviceCard)`
  margin: 16px 0;
`

interface DeviceConflictModalProps {
  isOpen: boolean
  close: () => void
  onDeviceChoice: (devicesToExclude: Device[]) => void
  devices: Device[]
  selectedDeviceIds: DeviceIdsRecord
  onAllConflictsResolution: () => void
}

const DeviceConflictModal: FC<DeviceConflictModalProps> = ({
  isOpen,
  close,
  onDeviceChoice,
  devices,
  selectedDeviceIds,
  onAllConflictsResolution
}) => {
  const { t } = useIntl()

  const [selectedDeviceId, setSelectedDeviceId] = useState<number | undefined>(undefined)
  const [currentConflict, setCurrentConflict] = useState<
    DuplicatedMetricDeviceConflict | IncompatibleMetricsConflict | undefined
  >(undefined)

  useEffect(() => {
    const updatedConflicts = buildConflictsFromIdsRecord(selectedDeviceIds, devices)

    if (!isEmpty(updatedConflicts.incompatibilities)) {
      const [newCurrent] = updatedConflicts.incompatibilities
      setCurrentConflict(newCurrent)
    } else if (!isEmpty(updatedConflicts.duplications)) {
      const [newCurrent] = updatedConflicts.duplications
      setCurrentConflict(newCurrent)
    } else {
      onAllConflictsResolution()
      close()
    }
  }, [selectedDeviceIds, devices])

  const isIncompatibilityConflict = !isNil(currentConflict) && 'metrics' in currentConflict

  return (
    <Modal
      title={t('models.device.metric_conflict_detected', { capitalize: true })}
      isOpen={isOpen}
      close={close}
      maxWidth={600}
    >
      <Box $px='md' $pb='md'>
        {!isNil(currentConflict) ? (
          <>
            <Text>
              {isIncompatibilityConflict
                ? t('models.device.incompatible_metric_conflict_description', {
                    metrics: currentConflict.metrics.map((m) => t(`metrics.${m}`)).join(', ')
                  })
                : t('models.device.duplicated_metric_conflict_description', {
                    metric: t(`metrics.${currentConflict.metric}` as const)
                  })}
            </Text>

            {/* Conflicted devices list */}
            <Box $mt='lg' $mb='md'>
              {!isNil(currentConflict) ? (
                isIncompatibilityConflict ? (
                  <Flex $alignItems='center' $flexDirection='column' $gap='lg'>
                    {currentConflict.metrics.includes('HPOT') ? (
                      <Button
                        importance='sd'
                        onPress={() => {
                          const devicesToExclude = currentConflict.devices.filter(
                            (d) =>
                              d.availableMeasures.includes('U_CAPA') ||
                              d.availableMeasures.includes('ECP')
                          )

                          onDeviceChoice(devicesToExclude)
                        }}
                      >
                        {t('models.device.metric_conflict_resolution_HPOT', { capitalize: true })}
                      </Button>
                    ) : null}
                    {currentConflict.metrics.includes('U_CAPA') ? (
                      <Button
                        importance='sd'
                        onPress={() => {
                          const devicesToExclude = currentConflict.devices.filter(
                            (d) =>
                              d.availableMeasures.includes('HPOT') ||
                              d.availableMeasures.includes('ECP')
                          )

                          onDeviceChoice(devicesToExclude)
                        }}
                      >
                        {t('models.device.metric_conflict_resolution_CAPA', { capitalize: true })}
                      </Button>
                    ) : null}
                    {currentConflict.metrics.includes('ECP') ? (
                      <Button
                        importance='sd'
                        onPress={() => {
                          const devicesToExclude = currentConflict.devices.filter(
                            (d) =>
                              d.availableMeasures.includes('U_CAPA') ||
                              d.availableMeasures.includes('HPOT')
                          )

                          onDeviceChoice(devicesToExclude)
                        }}
                      >
                        {t('models.device.metric_conflict_resolution_ECP', { capitalize: true })}
                      </Button>
                    ) : null}
                  </Flex>
                ) : (
                  currentConflict.devices.map((device: Device) => {
                    const isSelected = selectedDeviceId === device.id

                    return (
                      <DeviceCard
                        key={device.id.toString()}
                        isSelected={isSelected}
                        onPress={() =>
                          !isSelected
                            ? setSelectedDeviceId(device.id)
                            : setSelectedDeviceId(undefined)
                        }
                        device={device}
                      />
                    )
                  })
                )
              ) : (
                []
              )}
            </Box>

            {!isIncompatibilityConflict ? (
              <Flex $width='100%' $alignItems='center' $justifyContent='flex-end'>
                <Button importance='sd' onPress={() => close()}>
                  {t('actions.go_back')}
                </Button>
                <Box $ml='md'>
                  <Button
                    isDisabled={isNil(selectedDeviceId)}
                    onPress={() => {
                      if (!isNil(selectedDeviceId) && !isNil(currentConflict)) {
                        const devicesToExclude = currentConflict.devices.filter(
                          (d) => d.id !== selectedDeviceId
                        )

                        onDeviceChoice(devicesToExclude)
                      }
                    }}
                  >
                    {t('actions.confirm')}
                  </Button>
                </Box>
              </Flex>
            ) : null}
          </>
        ) : null}
      </Box>
    </Modal>
  )
}

export default DeviceConflictModal
