import { fromWindAngleToDirection } from '@weenat/client/dist/core/metrics'
import { useIntl } from '@weenat/wintl'
import useDisclosure from 'app/src/hooks/useDisclosure'
import { RestrictionReason } from 'app/src/hooks/useIsAppAccessRestricted'
import Icons from 'app/src/kit/Icons'
import { LockedCarouselValueModal } from 'app/src/kit/LockedFeat'
import Text from 'app/src/kit/Text'
import WindDirectionIcon from 'app/src/kit/icons/WindDirectionIcon'
import isNil from 'lodash-es/isNil'
import { memo } from 'react'
import { useTheme } from 'styled-components'
import useUniverseBackgroundMapContext from '../universes/useUniverseBackgroundMapContext'
import PlotListItemValue, { PlotSummaryValue } from './PlotListItemValue'
import PlotListItemValueName from './PlotListItemValueName'
import PlotListItemValuesContainer from './PlotListItemValuesContainer'

const metric = 'FF'

interface WindPlotListItemValuesProps {
  averageWindNow: PlotSummaryValue
  windGustNow: PlotSummaryValue
  windDirectionNow: PlotSummaryValue
  isVirtualDevice: boolean
  isRestricted: boolean
  reason: RestrictionReason
}

/** PlotListItem values for Winds */
const WindPlotListItemValues: FC<WindPlotListItemValuesProps> = ({
  averageWindNow,
  windGustNow,
  windDirectionNow,
  isRestricted: isRestrictedFromProps,
  reason,
  isVirtualDevice
}) => {
  const { t } = useIntl()
  const { colors } = useTheme()
  const { universeAggregate } = useUniverseBackgroundMapContext()

  const isRestricted = isRestrictedFromProps && !isVirtualDevice
  const color = isRestricted ? 'grayscale.300' : `metrics.${metric as const}.500`

  const selectedStyle: React.CSSProperties = {
    border: `2px solid ${universeAggregate === 'real-time-DD' ? color : colors.grayscale.white}`,
    borderRadius: 10
  } as const

  const numberWindDirection = !isNil(windDirectionNow)
    ? typeof windDirectionNow === 'string'
      ? parseInt(windDirectionNow, 10)
      : windDirectionNow
    : null

  const { close, isOpen, open } = useDisclosure()

  return (
    <PlotListItemValuesContainer $isRestricted={isRestricted}>
      <PlotListItemValue
        isMainValue
        value={averageWindNow}
        name={t('wind.average')}
        metric={metric}
        selected={universeAggregate === 'real-time-FF'}
        isRestricted={isRestricted}
        reason={reason}
      />
      <PlotListItemValue
        value={windGustNow}
        name={t('wind.gust')}
        metric={metric}
        selected={universeAggregate === 'real-time-FXY'}
        isRestricted={isRestricted}
        reason={reason}
      />

      <Flex
        $flexDirection='column'
        $justifyContent={isRestricted ? 'space-between' : undefined}
        $alignItems='flex-start'
        $flex={1}
        style={selectedStyle}
        $p='md'
        onClick={
          isRestricted
            ? (e) => {
                e.preventDefault()
                e.stopPropagation()
                open()
              }
            : undefined
        }
      >
        <PlotListItemValueName name={t('wind.direction')} color={color} />
        {isRestricted ? (
          <>
            <Icons.Lock $size='lg' $color={'grayscale.700'} />
            <LockedCarouselValueModal close={close} isOpen={isOpen} reason={reason} />
          </>
        ) : (
          !isNil(numberWindDirection) && (
            <Flex $alignItems='center' $pt='sm' $gap={'sm'}>
              <WindDirectionIcon
                windOrigin={numberWindDirection}
                $backgroundColor={color}
                $color={'grayscale.white'}
                $rounded
                $p='md'
                $size='xs'
              />
              <Text $fontSize='rg' $fontWeight='regular'>
                {t(`windDirections.${fromWindAngleToDirection(numberWindDirection)}`)}
              </Text>
            </Flex>
          )
        )}
      </Flex>
    </PlotListItemValuesContainer>
  )
}

export default memo(WindPlotListItemValues)
