import {
  getStepTitleKey,
  ScreenQueryParam,
  stepsByScreenByTour,
  Tour
} from '@weenat/client/dist/core/tours'
import { useIntl } from '@weenat/wintl'
import useIrrigationUniverseTourSetup from 'app/src/dashboard/components/DashboardMap/universes/useIrrigationUniverseTourSetup'
import { useHandleScreenQueryParamChange } from 'app/src/hooks/useScreenQueryParam'
import useToasts from 'app/src/hooks/useToasts'
import Button, { ButtonProps } from 'app/src/kit/Button'
import Link from 'app/src/kit/LinkComponent'
import { useCurrentTour } from 'app/state'
import isNil from 'lodash-es/isNil'

interface ReplayTourLinkProps {
  tour: Tour
  isButton?: boolean
  buttonProps?: Omit<ButtonProps, 'onPress'>
}

const ReplayTourLink = ({ tour, isButton, buttonProps }: ReplayTourLinkProps) => {
  const { t } = useIntl()
  const [, setCurrentTour] = useCurrentTour()
  const handleScreenQueryParamChange = useHandleScreenQueryParamChange()
  const { addErrorToast } = useToasts()
  const irriUnivTourSetup = useIrrigationUniverseTourSetup()

  const handlePress = () => {
    if (!irriUnivTourSetup.handleTourSetup(tour)) {
      // Return is important to short circuit
      return addErrorToast(t('tours.only_available_with_irrigation'))
    }
    setCurrentTour({
      name: tour.name,
      stepIndex: 0,
      isWaitingNextPop: false,
      isWaitingNextScreen: false
    })
    const [firstScreenName] = Object.keys(stepsByScreenByTour[tour.name])
    const firstStep = stepsByScreenByTour[tour.name][firstScreenName]?.[0]

    handleScreenQueryParamChange(
      firstScreenName as ScreenQueryParam,
      () => true,
      firstStep?.initPath
    )
  }

  return isNil(isButton) ? (
    <Link $underlined onPress={handlePress}>
      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        t(getStepTitleKey(tour.name, stepsByScreenByTour[tour.name]['intro'][0].name))
      }
    </Link>
  ) : (
    <Button onPress={handlePress} importance='sd' {...buttonProps}>
      {t('actions.lets_go')}
    </Button>
  )
}
export default ReplayTourLink
