import { WeenatColor, fromColorPathToColor } from '@weenat/theme'
import { styled } from 'styled-components'
import Card from './Card'
import Icons from './Icons'
import SlideIn from './animations/SlideIn'

type ToastKind = 'error' | 'success' | 'warning' | 'info'

const ToastContainer = styled(Card)`
  margin: ${(p) => p.theme.spacings.md}px;
  padding: 0px;

  max-width: 500px;
  min-width: 196px;

  font-size: ${(p) => p.theme.typography.sizes.rg}px;
  font-weight: ${(p) => p.theme.typography.weights.regular};
  line-height: ${(p) => p.theme.typography.lineHeights.rg}px;

  border-color: ${(p) => p.$borderColor};

  overflow: hidden;
`

interface ToastProps {
  appearance: ToastKind
  onDismiss: () => void
}

const lineHeightNormal = { lineHeight: 'normal' }
const Toast: FC<ToastProps> = ({ appearance, onDismiss, children }) => {
  let color: WeenatColor | undefined = undefined
  let Icon = undefined

  switch (appearance) {
    case 'success':
      color = 'feedback.success.500'
      Icon = Icons.CheckCircle
      break
    case 'error':
      color = 'feedback.error.500'
      Icon = Icons.AlertTriangle
      break
    case 'warning':
      color = 'feedback.warning.500'
      Icon = Icons.AlertTriangle
      break
    default:
      color = 'primary.500'
      Icon = Icons.InfoCircle
      break
  }

  return (
    <SlideIn>
      <ToastContainer $borderColor={fromColorPathToColor(color)}>
        <Flex $alignItems='center' $justifyContent='space-between' $p='lg' $gap='md'>
          <Icon $size='md' $color={color} />
          <Icons.Close $p='sm' $backgroundColor={'grayscale.100'} onPress={onDismiss} $size='md' />
        </Flex>
        {typeof children === 'string' ? (
          <Box $p='lg' style={lineHeightNormal}>
            {children}
          </Box>
        ) : (
          <Box style={lineHeightNormal}>{children}</Box>
        )}
      </ToastContainer>
    </SlideIn>
  )
}

export default Toast
