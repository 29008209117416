import { Network } from '@weenat/client/dist/resources/networks'
import { AbsoluteHref } from '@weenat/client/dist/routx/runtime-core'
import { useIntl } from '@weenat/wintl'
import { useNavigate } from 'app/routx-router'
import Card from 'app/src/kit/Card'
import Text from 'app/src/kit/Text'
import { memo } from 'react'
import DelimitedFlex from '../kit/DelimitedFlex'
import Icons from '../kit/Icons'

interface NetworksListItemProps {
  network: Network
  context: 'onboarding' | 'networks'
}

const NetworksListItem = ({ network, context = 'networks' }: NetworksListItemProps) => {
  const { t, formatNumber } = useIntl()
  const nav = useNavigate()

  const { name, id, distanceToTarget, deviceCount, adherentFarmsCount } = network

  const destination: AbsoluteHref =
    context === 'networks' ? `/networks/${id.toString()}` : `/onboarding/networks/${id.toString()}`

  return (
    <Card $p={0} onPress={() => nav(destination)}>
      <DelimitedFlex $alignItems='center' $p='lg' $gap='md'>
        <Box $flex={1}>
          <Text $fontWeight='bold' $ellipsis>
            {name}
          </Text>
          <Text $fontSize='sm' $color={'grayscale.700'}>
            {t(
              'models.organization.headquarters.distance_to',
              { distance: `${formatNumber(distanceToTarget)} km` },
              { capitalize: true }
            )}
          </Text>
        </Box>
        <Icons.ArrowRight $size='lg' />
      </DelimitedFlex>
      <Flex $flexDirection='column' $p='lg' $gap='md'>
        <Flex $alignItems='center' $gap='md'>
          <Icons.GroupPersons $size='md' />
          <Text>
            {t('models.networkOrganization.model.item_count', {
              count: adherentFarmsCount ?? '-'
            })}
          </Text>
        </Flex>
        <Flex $alignItems='center' $gap='md'>
          <Icons.SingleSensorFilled $size='md' />
          <Text>{t('models.device.model.item_count', { count: deviceCount ?? '-' })}</Text>
        </Flex>
      </Flex>
    </Card>
  )
}

export default memo(NetworksListItem)
