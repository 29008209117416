function SvgDottedCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 40 40' {...props}>
      <circle
        strokeDasharray={7}
        r={18}
        cx={20}
        cy={20}
        strokeWidth={3}
        fill='none'
        strokeLinecap='round'
        stroke='#000'
      />
    </svg>
  )
}

export default SvgDottedCircle
