import isNil from 'lodash-es/isNil'
import { useLayoutEffect, useRef, useState } from 'react'
import { styled, useTheme } from 'styled-components'

const DEFAULT_HEIGHT = 8

const BackgroundBar = styled.div<{ $height: number; $width: number }>`
  display: flex;
  align-items: center;
  background-color: ${(p) => p.theme.colors.grayscale[50]};
  height: ${(p) => p.$height}px;
  width: ${(p) => p.$width}px;
  border-radius: ${(p) => p.theme.radiuses.rounded}px;
  overflow: hidden;
`

interface ProgressBarProps {
  progress: number
  total: number
  height?: number
  width?: number
}

const ProgressBar: FC<ProgressBarProps> = ({
  progress,
  total,
  height = DEFAULT_HEIGHT,
  width: initialWidth
}) => {
  const theme = useTheme()
  const ref = useRef<HTMLDivElement | null>(null)

  const [barWidth, setBarWidth] = useState(initialWidth)

  const fillWidth = !isNil(barWidth) ? (progress / total) * barWidth : 0

  useLayoutEffect(() => {
    if (!isNil(ref.current) && isNil(barWidth)) {
      setBarWidth(ref.current.clientWidth)
    }
  }, [barWidth])

  return (
    <BackgroundBar $height={height} $width={barWidth ?? 0} ref={ref}>
      <Box
        $width={fillWidth}
        $backgroundColor={theme.colors.feedback.success['500']}
        $height={height}
      />
    </BackgroundBar>
  )
}

export default ProgressBar
