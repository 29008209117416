function SvgYoutube(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className='youtube_svg__svg-inline--fa youtube_svg__fa-youtube youtube_svg__fa-w-18'
      aria-hidden='true'
      data-icon='youtube'
      data-prefix='fab'
      viewBox='0 0 512 512'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M439.98 163.25c-4.416-16.629-17.428-29.725-33.95-34.17-29.946-8.076-150.03-8.076-150.03-8.076s-120.08 0-150.03 8.076c-16.521 4.445-29.533 17.541-33.95 34.17-8.024 30.141-8.024 93.027-8.024 93.027s0 62.886 8.024 93.027c4.416 16.629 17.428 29.18 33.95 33.624 29.947 8.076 150.03 8.076 150.03 8.076s120.08 0 150.03-8.076c16.521-4.444 29.533-16.995 33.95-33.624 8.024-30.141 8.024-93.027 8.024-93.027s0-62.886-8.024-93.027zM216.73 313.37V199.18l100.36 57.097z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgYoutube
