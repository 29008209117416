import { thresholdColorScale } from '@weenat/client/dist/core/dss/irrigation'
import { useConvertedValue } from '@weenat/client/dist/hooks'
import { PlotSummary } from '@weenat/client/dist/resources/measurements.type'
import { ThresholdType } from '@weenat/client/dist/resources/thresholds.types'
import { useIntl } from '@weenat/wintl'
import { fontFamily } from 'app/src/kit/charts/victoryTheme'
import { useUniverseAggregate } from 'app/state'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { lighten } from 'polished'
import React, { useMemo } from 'react'
import { useTheme } from 'styled-components'

const SVG_HEIGHT = 80
const SVG_WIDTH = 352

const NB_MEASURE = 4

const FONT_SIZE = 12
const TITLE_FONT_SIZE = 10

const STROKE_WIDTH = 2

const MEASURE_RECT_HEIGHT = 80 - 2 * STROKE_WIDTH
const MEASURE_RECT_PADDING = 8

const RECT_WIDTH =
  (SVG_WIDTH - (NB_MEASURE - 1) * MEASURE_RECT_PADDING - NB_MEASURE * (2 * STROKE_WIDTH)) /
  NB_MEASURE

const VALUE_HEADER_PADDING_TOP = 10
const VALUE_HEADER_HEIGHT = 3 * VALUE_HEADER_PADDING_TOP + 2 * TITLE_FONT_SIZE
const VALUE_HEIGHT = MEASURE_RECT_HEIGHT - VALUE_HEADER_HEIGHT - FONT_SIZE
const CAPTION_OFFSET = 20
/** below this number text is displayed above point under it's the opposite */
const BREAKING_LINE = -20

const getXPositionFromIdx = (idx: number) => {
  const leftOffset = 0
  const measureRectWidth = RECT_WIDTH
  return (
    STROKE_WIDTH +
    idx * 2 * STROKE_WIDTH +
    idx * (measureRectWidth + MEASURE_RECT_PADDING) +
    leftOffset
  )
}

const getMinMax = (values: { value: number | null | undefined }[]) => {
  const nonNullValues = values.reduce((acc, v) => {
    if (!isNil(v.value)) {
      acc.push(v.value)
    }
    return acc
  }, [] as number[])
  const minValue = isEmpty(nonNullValues) ? 0 : Math.min(...nonNullValues)
  const maxValue = isEmpty(nonNullValues) ? 0 : Math.max(...nonNullValues)
  return [minValue, maxValue]
}

// The lower the sensitivity the more it will variate for a given delta
// The tensiometers scales and amplitudes between values being higher we set a high sensitivity
// On the opposite capacitive only variates between 10 and 30 percent so we set a low sensitivity
const Y_SENSITIVITY = 1

const getYPositionFromValue = (min: number, max: number, value?: number | null, offsetY = 0) => {
  const sensitivity = Y_SENSITIVITY
  if (isNil(value)) {
    return 0
  } else {
    let delta = Math.ceil((max - min) / sensitivity)
    if (delta === 0) {
      delta = 1
    }
    const ratio = VALUE_HEIGHT / delta
    return ((value - min) / sensitivity) * -ratio + offsetY
  }
}

const getCaptionYPositionFromValue = (min: number, max: number, value?: number, offsetY = 0) => {
  const circleCenterPosition = getYPositionFromValue(min, max, value, offsetY)
  return circleCenterPosition - offsetY < BREAKING_LINE
    ? circleCenterPosition + CAPTION_OFFSET
    : circleCenterPosition - CAPTION_OFFSET / 2
}

interface IrrigationValueProps {
  value?: number | null
  idx: number
  min: number
  max: number
  nextValue?: number | null
  threshold?: ThresholdType
  id: string
}

const IrrelisValue: React.FC<IrrigationValueProps> = ({
  value,
  idx,
  min,
  max,
  nextValue,
  threshold,
  id
}) => {
  const { colors } = useTheme()
  const { t } = useIntl()
  const { convertValue } = useConvertedValue()

  const thresholdColor = !isNil(threshold) ? thresholdColorScale(threshold) : colors.grayscale.black

  const halfWidth = RECT_WIDTH / 2

  const xPosition = getXPositionFromIdx(idx)
  const yPosition = getYPositionFromValue(min, max, value, VALUE_HEADER_HEIGHT + VALUE_HEIGHT)
  const nextYPosition = !isNil(nextValue)
    ? getYPositionFromValue(min, max, nextValue, VALUE_HEADER_HEIGHT + VALUE_HEIGHT)
    : undefined

  const circleXPosition = xPosition + halfWidth
  const nextCircleXPosition = getXPositionFromIdx(idx + 1) + halfWidth

  let dateLabel: string | undefined = undefined

  switch (id) {
    case '5D':
      dateLabel = t('map.universes.aggregate.d+5-measure')
      break
    case '3D':
      dateLabel = t('map.universes.aggregate.d+3-measure')
      break
    case 'DD':
      dateLabel = t('map.universes.aggregate.d+1-measure')
      break
    case 'D':
      dateLabel = t('map.universes.aggregate.d-0-measure')
      break
  }

  return (
    <>
      {/* LINK */}
      {!isNil(nextValue) && !isNil(value) && (
        <line
          x1={circleXPosition}
          x2={nextCircleXPosition}
          y1={yPosition}
          y2={nextYPosition}
          stroke={colors.grayscale.black}
          strokeWidth={1}
        />
      )}
      {/* DATE */}
      {isNil(dateLabel) ? null : (
        <text
          key={dateLabel}
          fill={colors.grayscale.black}
          x={circleXPosition}
          y={TITLE_FONT_SIZE + 1 + TITLE_FONT_SIZE / 2}
          fontSize={TITLE_FONT_SIZE}
          fontFamily={fontFamily}
          fontWeight={700}
          textAnchor='middle'
        >
          {dateLabel}
        </text>
      )}
      {!isNil(value) && (
        <>
          <circle cx={circleXPosition} cy={yPosition} r='10' fill={colors.grayscale[50]} />
          <circle
            cx={circleXPosition}
            cy={yPosition}
            r='6'
            fill={lighten(0.2, thresholdColor)}
            fillOpacity='0.2'
            stroke={thresholdColor}
          />
          <text
            fill={colors.grayscale.black}
            x={circleXPosition}
            y={getCaptionYPositionFromValue(min, max, value, VALUE_HEADER_HEIGHT + VALUE_HEIGHT)}
            fontSize={FONT_SIZE}
            fontFamily={fontFamily}
            fontWeight={500}
            textAnchor='middle'
          >
            {`${convertValue({ metric: 'RR', value })}`}
          </text>
        </>
      )}
    </>
  )
}

const Rect: FC<{ aKey: string; idx: number }> = ({ aKey, idx }) => {
  const [universeAggregate] = useUniverseAggregate()
  const { colors } = useTheme()
  return (
    <rect
      width={RECT_WIDTH}
      height={MEASURE_RECT_HEIGHT}
      rx={8}
      y={1}
      x={getXPositionFromIdx(idx)}
      fill={colors.grayscale[50]}
      strokeWidth={STROKE_WIDTH}
      stroke={universeAggregate === aKey ? colors.metrics.HDEF[500] : colors.grayscale[300]}
    />
  )
}

interface IrrigationPlotListItemValuesProps {
  /** Plot summary data */
  summaryData: PlotSummary['data'] | undefined
}

const IrrelisPlotListItemValues: React.FC<IrrigationPlotListItemValuesProps> = ({
  summaryData
}) => {
  const data = useMemo(
    () => [
      {
        id: 'D',
        value: summaryData?.HDEF_D?.[0],
        key: 'd-0-measure',
        threshold: summaryData?.HDEF_D?.[1]
      },
      {
        id: 'DD',
        value: summaryData?.HDEF_DD?.[0],
        key: 'd+1-measure',
        threshold: summaryData?.HDEF_DD?.[1]
      },
      {
        id: '3D',
        value: summaryData?.HDEF_3D?.[0],
        key: 'd+3-measure',
        threshold: summaryData?.HDEF_3D?.[1]
      },
      {
        id: '5D',
        value: summaryData?.HDEF_5D?.[0],
        key: 'd+5-measure',
        threshold: summaryData?.HDEF_5D?.[1]
      }
    ],
    [summaryData]
  )

  const [min, max] = useMemo(() => getMinMax(data), [data])

  return (
    <Box $width='100%'>
      <svg viewBox={`0 0 ${SVG_WIDTH} ${SVG_HEIGHT}`} width='100%' height={SVG_HEIGHT}>
        {data.map(({ key }, idx) => (
          <Rect key={key} aKey={key} idx={idx} />
        ))}
        {data.map(({ value, id, key, threshold }, idx) => (
          <IrrelisValue
            key={key}
            id={id}
            idx={idx}
            max={max}
            min={min}
            value={value}
            nextValue={data[idx + 1]?.value}
            threshold={threshold}
          />
        ))}
      </svg>
    </Box>
  )
}

export default IrrelisPlotListItemValues
