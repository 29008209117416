import { canUser, ops } from '@weenat/client/dist/core/access'
import useVirtualDevice from '@weenat/client/dist/core/devices/useVirtualDevice'
import { BillingStatus } from '@weenat/client/dist/enums'
import { useIntl } from '@weenat/wintl'
import { useOrgContext } from 'app/orgProvider'
import { useNavigate } from 'app/routx-router'
import { IconProps } from 'app/src/kit/Icon'
import Icons from 'app/src/kit/Icons'
import { TextFieldPrimitive } from 'app/src/kit/fields/TextField'
import gradientFader from 'app/src/kit/gradientFader'
import TextTooltip from 'app/src/kit/tooltips/TextTooltip'
import { SelectedOrgs } from 'app/state'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { useEffect } from 'react'
import { styled } from 'styled-components'
import logEvent from '../../../../utils/analytics'
import useDebounce from '../../../hooks/useDebounce'
import RefreshBackgroundMap from './RefreshBackgroundMap'
import SelectOrg from './SelectOrg'
import { elementIds } from './constants'
import {
  useBackgroundMapContext,
  useBackgroundMapDispatcher
} from './contexts/BackgroundMapContext'

const iconSizes: Partial<IconProps> = {
  $size: 'lg',
  $p: 'md'
}

const AddPlotLink: FC = () => {
  const { t } = useIntl()
  const nav = useNavigate()

  const { currentOrgId } = useOrgContext()

  const { org, remainingVirtualDeviceActivations } = useVirtualDevice({ orgId: currentOrgId })

  // IF the users has the rights && current org still have plot creation tokens
  // Not using useCanCreateMorePlots because we want to display the remaining number of plots here
  const canCreateMorePlots =
    !isNil(org) &&
    canUser(ops.create, 'plots', { organization: org }) &&
    (org?.billingStatus !== BillingStatus.essential ||
      (org?.billingStatus === BillingStatus.essential && remainingVirtualDeviceActivations !== 0))

  const message = `${t('models.plot.actions.create')}${
    !isNil(org) &&
    org.billingStatus === BillingStatus.essential &&
    !isNil(remainingVirtualDeviceActivations)
      ? `\n${t('models.plot.actions.virtual_device_remaining_activations', {
          value: remainingVirtualDeviceActivations
        })}`
      : ''
  }`

  return (
    <TextTooltip content={message}>
      <Icons.PlusSign
        {...iconSizes}
        onPress={() =>
          nav('/plots/create', {
            replace: true
          })
        }
        $isDisabled={!canCreateMorePlots}
      />
    </TextTooltip>
  )
}

const ActionsContainer = styled(Flex)`
  align-items: center;
  border-top: 1px solid ${(p) => p.theme.colors.grayscale[100]};
  margin-top: ${(p) => p.theme.spacings.lg}px;
  padding-top: ${(p) => p.theme.spacings.lg}px;
  gap: ${(p) => p.theme.spacings.md}px;
`

const SearchInput: FC = () => {
  const { t } = useIntl()

  const { search } = useBackgroundMapContext()
  const dispatch = useBackgroundMapDispatcher()

  const debouncedSearchTerm = useDebounce(search, 500)

  useEffect(() => {
    if (!isEmpty(debouncedSearchTerm)) {
      logEvent('map_plot_search', { term: debouncedSearchTerm })
    }
  }, [debouncedSearchTerm])

  return (
    <TextFieldPrimitive
      id='search-summaries'
      isClearable
      hideErrors
      leftAdornment={<Icons.Search $size='md' />}
      onClear={() => {
        dispatch({ type: 'resetSearch' })
      }}
      onChange={(e) => {
        dispatch({ type: 'setSearch', newSearch: e.currentTarget.value })
      }}
      value={search}
      placeholder={t('models.plot.actions.search_plot')}
    />
  )
}

const ListHeaderContainer = styled(Flex)`
  border-bottom: 1px solid ${(p) => p.theme.colors.grayscale[300]};

  ${gradientFader.bottom()}
`

interface PlotListHeaderProps {
  selectedOrgs: SelectedOrgs
  setSelectedOrgs: React.Dispatch<React.SetStateAction<SelectedOrgs>>
}

const PlotListHeader: FC<PlotListHeaderProps> = ({ selectedOrgs, setSelectedOrgs }) => {
  return (
    <ListHeaderContainer $flexDirection='column' $p='lg' id={elementIds.ListHeader}>
      <SelectOrg selectedOrgs={selectedOrgs} setSelectedOrgs={setSelectedOrgs} />
      <ActionsContainer>
        <Box $flex={1}>
          <SearchInput />
        </Box>
        <Flex $alignItems='center' $mt='sm' $mb='md' $gap='md'>
          <RefreshBackgroundMap {...iconSizes} />
          <AddPlotLink />
        </Flex>
      </ActionsContainer>
    </ListHeaderContainer>
  )
}

export default PlotListHeader
