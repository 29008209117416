import { Placement } from '@floating-ui/react'
import { invalidateQueriesContainingPattern, queryClient } from '@weenat/client'
import { useIntl } from '@weenat/wintl'
import { IconProps } from 'app/src/kit/Icon'
import Icons from 'app/src/kit/Icons'
import TextTooltip from 'app/src/kit/tooltips/TextTooltip'

interface RefreshProps extends IconProps {
  tooltipPlacement?: Placement
}
const patternsToInvalidate = ['summary', 'invitation', 'bbox', 'profile'] as const

const RefreshBackgroundMap: FC<RefreshProps> = ({ $size = 'lg', tooltipPlacement, ...props }) => {
  const { t } = useIntl()

  const refetchAllData = () => {
    patternsToInvalidate.forEach((p) => {
      invalidateQueriesContainingPattern(p)
    })
  }

  return (
    <TextTooltip content={t(`actions.refresh_data`)} placement={tooltipPlacement}>
      <Icons.Refresh
        $size={$size}
        onPress={refetchAllData}
        $isLoading={
          queryClient.isFetching({
            predicate: ({ queryKey }) =>
              patternsToInvalidate.some((pattern) =>
                (queryKey[0] as string)?.toString().includes(`${pattern}`)
              )
          }) > 0
        }
        $useFakeLoading
        {...props}
      />
    </TextTooltip>
  )
}

export default RefreshBackgroundMap
