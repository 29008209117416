function SvgTemperatureGround(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M14.992 3.75c-.813 0-1.502.294-2.06.853-.56.559-.834 1.25-.834 2.063V16.74a5.182 5.182 0 00-1.675 1.87 5.102 5.102 0 00-.6 2.416 5 5 0 00.692 2.59 5.152 5.152 0 001.889 1.878 5.092 5.092 0 002.588.691c.944 0 1.808-.234 2.6-.691a5.052 5.052 0 001.88-1.878 5.135 5.135 0 00.69-2.6c0-.863-.193-1.677-.59-2.419a5.23 5.23 0 00-1.665-1.867V6.656a2.83 2.83 0 00-.843-2.063c-.558-.558-1.25-.843-2.072-.843zm0 1.766c.345 0 .631.112.865.326.233.223.344.498.344.823v10.928c0 .153.05.254.152.295l.285.142a3.32 3.32 0 011.342 1.25c.325.538.486 1.127.486 1.757 0 .924-.345 1.726-1.026 2.407-.68.68-1.492 1.015-2.436 1.015a3.246 3.246 0 01-2.398-1.005 3.342 3.342 0 01-.995-2.427v-.002c0-.65.153-1.228.467-1.766.315-.539.75-.954 1.31-1.249l.274-.142c.091-.04.142-.144.142-.296V6.666c0-.335.113-.61.347-.824.233-.213.516-.325.841-.325zm-15 2.318v1.319H10.32a.66.66 0 100-1.319zm19.674 0a.66.66 0 100 1.318h10.326V7.834zm-4.734 2.873a.289.289 0 00-.213.081.311.311 0 00-.082.215v7.453a2.578 2.578 0 00-1.585.865 2.488 2.488 0 00-.648 1.706c0 .72.242 1.34.74 1.848.497.508 1.087.761 1.787.761.701 0 1.302-.253 1.82-.761a2.486 2.486 0 00.77-1.839c0-.64-.223-1.208-.66-1.696-.436-.487-.975-.781-1.604-.863v-7.474c0-.061-.03-.134-.102-.195a.304.304 0 00-.224-.101zm6.128 1.338a1.091 1.091 0 000 2.18 1.09 1.09 0 001.09-1.09 1.09 1.09 0 00-1.09-1.09zm-15.94 1.05l-2.567.603.903.962.904.96.38-1.262zm19.79.488l.341 1.274.34 1.275 1.867-1.863-1.274-.343zM7.281 17.015a1.091 1.091 0 10.001 2.182 1.091 1.091 0 000-2.182zm15.275 2.935l.341 1.273.34 1.274.934-.93.933-.932-1.274-.342zm-18.24.728a1.09 1.09 0 00-1.09 1.09 1.09 1.09 0 102.18 0c0-.601-.489-1.09-1.09-1.09zm-.509 4.242l.34 1.274.34 1.275.935-.933.932-.93-1.273-.343zm21.801.524l.34 1.274.34 1.273.935-.93.932-.932-1.273-.342z'
        color='#000'
        style={{
          fontFeatureSettings: 'normal',
          fontVariantAlternates: 'normal',
          fontVariantCaps: 'normal',
          fontVariantEastAsian: 'normal',
          fontVariantLigatures: 'normal',
          fontVariantNumeric: 'normal',
          fontVariantPosition: 'normal',
          fontVariationSettings: 'normal',
          inlineSize: 0,
          isolation: 'auto',
          mixBlendMode: 'normal',
          shapeMargin: 0,
          shapePadding: 0,
          textDecorationColor: '#000',
          textDecorationLine: 'none',
          textDecorationStyle: 'solid',
          textIndent: 0,
          textOrientation: 'mixed',
          textTransform: 'none',
          whiteSpace: 'normal'
        }}
      />
    </svg>
  )
}

export default SvgTemperatureGround
