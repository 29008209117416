import { useClient, useMutation } from '@weenat/client'
import * as zodSchemas from '@weenat/client/dist/zod-schemas'
import { useIntl } from '@weenat/wintl'
import { useSearchParams } from 'app/routx-router'
import Card from 'app/src/kit/Card'
import GoHomeLink from 'app/src/kit/GoHomeLink'
import SmartText from 'app/src/kit/SmartText'
import Text from 'app/src/kit/Text'
import Cloudy from 'app/src/kit/icons/Cloudy'
import LoadingCircle from 'app/src/kit/loaders/LoadingCircle'
import { useEffect } from 'react'
export const settings = {
  public: 'always',
  search: zodSchemas.unsubscribeMailings
}

const UnsubscribeEmails = () => {
  const { t } = useIntl()
  const [searchParams] = useSearchParams(settings.search)
  const client = useClient()
  const [unsubscribe, { isPending: isLoading, isSuccess, isError }] = useMutation(
    client.me.mailingPreferences.unsubscribe()
  )

  useEffect(() => {
    unsubscribe(searchParams)
  }, [])

  return (
    <Flex $alignItems='center' $justifyContent='center' $flex={1}>
      <Card $maxWidth={640} $borderRadius={'lg'}>
        <Text $fontWeight='bold' $fontSize='lg' $textAlign='center'>
          {t('breadcrumbs./unsubscribe-emails')}
        </Text>
        <Flex
          $flexDirection='column'
          $py='lg'
          $gap='md'
          $alignItems='center'
          $justifyContent='center'
        >
          <Cloudy.sad $size='2xl' />
          {isLoading ? (
            <LoadingCircle />
          ) : isError ? (
            <SmartText tkey='actions.unsubscribe_emails_error' $textAlign='center' />
          ) : isSuccess ? (
            <Text $textAlign='center'>{t('actions.unsubscribe_emails_success')}</Text>
          ) : null}
        </Flex>
        <Flex $alignItems='center' $justifyContent='center'>
          <GoHomeLink />
        </Flex>
      </Card>
    </Flex>
  )
}

export default UnsubscribeEmails
