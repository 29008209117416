import { HTMLProps } from 'react'

type InputType =
  | 'number'
  | 'email'
  | 'password'
  | 'confirmationPassword'
  | 'firstName'
  | 'lastName'
  | 'telephoneNumber'
  | 'organizationName'
  | 'addressLine1'
  | 'addressLine2'
  | 'zipcode'
  | 'city'
  | 'country'
  | 'cardOwnerName'
  | 'cardNumber'
  | 'cardExpiry'
  | 'cardValidationCode'

const defaultTo = (
  defaultValue: string | number | undefined,
  mayBeUndefined: string | number | undefined
) => (mayBeUndefined !== undefined ? mayBeUndefined : defaultValue)

const defaultNameTo = (
  defaultValue: string | number | undefined,
  mayBeUndefined: string | number | undefined
) => defaultTo(defaultValue, mayBeUndefined)

const defaultAutoCompleteTo = (
  defaultValue: string | number | undefined,
  mayBeUndefined: string | number | undefined
) => defaultTo(defaultValue, mayBeUndefined)

interface DerivedProps {
  inputMode?: HTMLProps<HTMLInputElement>['inputMode']
  type: HTMLProps<HTMLInputElement>['type']
  name?: HTMLProps<HTMLInputElement>['name']
  autoComplete?: HTMLProps<HTMLInputElement>['autoComplete']
  maxLength?: HTMLProps<HTMLInputElement>['maxLength']
}

type GetDerivedInputPropsFromTypeArgs = {
  inputType?: InputType
  inputMode?: HTMLProps<HTMLInputElement>['inputMode']
  type: HTMLProps<HTMLInputElement>['type']
  name?: HTMLProps<HTMLInputElement>['name']
  autoComplete?: HTMLProps<HTMLInputElement>['autoComplete']
  maxLength?: HTMLProps<HTMLInputElement>['maxLength']
}

const getDerivedInputPropsFromType = ({
  type,
  name,
  autoComplete,
  inputType,
  inputMode,
  maxLength
}: GetDerivedInputPropsFromTypeArgs): DerivedProps => {
  const defaultDerived = {
    type: defaultTo(inputType, type),
    name: defaultNameTo(inputType, name),
    autoComplete: defaultAutoCompleteTo(inputType, autoComplete),
    maxLength: undefined,
    inputMode: undefined
  }

  let inputProps: DerivedProps | undefined = undefined

  switch (inputType) {
    case 'email':
      inputProps = {
        ...defaultDerived,
        type: defaultTo('email', type),
        name: defaultNameTo('email', name),
        maxLength: defaultTo(254, maxLength),
        inputMode: defaultTo('email', inputMode)
      }
      break
    case 'password':
      inputProps = {
        ...defaultDerived,
        type: defaultTo('password', type),
        name: defaultNameTo('password', name),
        autoComplete: defaultAutoCompleteTo('current-password', autoComplete),
        maxLength: defaultTo(128, maxLength)
      }
      break
    case 'confirmationPassword':
      inputProps = {
        ...defaultDerived,
        type: defaultTo('password', type),
        name: defaultNameTo('password', name),
        autoComplete: defaultAutoCompleteTo('new-password', autoComplete),
        maxLength: defaultTo(128, maxLength)
      }
      break
    case 'firstName':
      inputProps = {
        ...defaultDerived,
        type: defaultTo('text', type),
        name: defaultNameTo('fname', name),
        autoComplete: defaultAutoCompleteTo('given-name', autoComplete),
        maxLength: defaultTo(30, maxLength)
      }
      break
    case 'lastName':
      inputProps = {
        ...defaultDerived,
        type: defaultTo('text', type),
        name: defaultNameTo('lname', name),
        autoComplete: defaultAutoCompleteTo('family-name', autoComplete),
        maxLength: defaultTo(30, maxLength)
      }
      break
    case 'telephoneNumber':
      inputProps = {
        ...defaultDerived,
        type: defaultTo('tel', type),
        name: defaultNameTo('phone', name),
        autoComplete: defaultAutoCompleteTo('tel', autoComplete),
        maxLength: defaultTo(20, maxLength),
        inputMode: defaultTo('tel', inputMode)
      }
      break
    case 'organizationName':
      inputProps = {
        ...defaultDerived,
        type: defaultTo('text', type),
        name: defaultNameTo('organization', name),
        autoComplete: defaultAutoCompleteTo('organization', autoComplete),
        maxLength: defaultTo(104, maxLength)
      }
      break
    case 'addressLine1':
      inputProps = {
        ...defaultDerived,
        type: defaultTo('text', type),
        name: defaultNameTo('address-line1', name),
        autoComplete: defaultAutoCompleteTo('address-line1', autoComplete),
        maxLength: defaultTo(128, maxLength)
      }
      break
    case 'addressLine2':
      inputProps = {
        ...defaultDerived,
        type: defaultTo('text', type),
        name: defaultNameTo('address-line2', name),
        autoComplete: defaultAutoCompleteTo('address-line2', autoComplete),
        maxLength: defaultTo(128, maxLength)
      }
      break
    case 'zipcode':
      inputProps = {
        ...defaultDerived,
        type: defaultTo('tel', type),
        name: defaultNameTo('zip', name),
        autoComplete: defaultAutoCompleteTo('postal-code', autoComplete),
        maxLength: defaultTo(32, maxLength)
      }
      break
    case 'city':
      inputProps = {
        ...defaultDerived,
        type: defaultTo('text', type),
        name: defaultNameTo('city', name),
        autoComplete: defaultAutoCompleteTo('address-level2', autoComplete),
        maxLength: defaultTo(128, maxLength)
      }
      break
    case 'country':
      inputProps = {
        ...defaultDerived,
        type: defaultTo('text', type),
        name: defaultNameTo('country', name),
        autoComplete: defaultAutoCompleteTo('country', autoComplete),
        maxLength: defaultTo(128, maxLength)
      }
      break
    case 'cardOwnerName':
      inputProps = {
        ...defaultDerived,
        type: defaultTo('text', type),
        name: defaultNameTo('ccname', name),
        autoComplete: defaultAutoCompleteTo('cc-name', autoComplete),
        maxLength: defaultTo(30, maxLength)
      }
      break
    case 'cardNumber':
      inputProps = {
        ...defaultDerived,
        type: defaultTo('tel', type),
        name: defaultNameTo('cardnumber', name),
        autoComplete: defaultAutoCompleteTo('cc-number', autoComplete),
        maxLength: defaultTo(16, maxLength),
        inputMode: defaultTo('numeric', inputMode)
      }
      break
    case 'cardExpiry':
      inputProps = {
        ...defaultDerived,
        type: defaultTo('tel', type),
        name: defaultNameTo('cc-exp', name),
        autoComplete: defaultAutoCompleteTo('cc-exp', autoComplete),
        maxLength: defaultTo(4, maxLength),
        inputMode: defaultTo('numeric', inputMode)
      }
      break
    case 'cardValidationCode':
      inputProps = {
        ...defaultDerived,
        type: defaultTo('tel', type),
        name: defaultNameTo('cvc', name),
        autoComplete: defaultAutoCompleteTo('cc-csc', autoComplete),
        maxLength: defaultTo(3, maxLength),
        inputMode: defaultTo('numeric', inputMode)
      }
      break

    default:
      inputProps = defaultDerived
      break
  }

  return inputProps
}

export default getDerivedInputPropsFromType
