import Icon, { IconProps } from '../Icon'
import SvgCloudyConfused from './generic-icons/CloudyConfused'
import SvgCloudyHappy from './generic-icons/CloudyHappy'
import SvgCloudySad from './generic-icons/CloudySad'
import SvgCloudySleepy from './generic-icons/CloudySleepy'

interface CloudyIconProps extends IconProps {
  svgIcon: React.ReactElement
}

const CloudyIcon: FC<CloudyIconProps> = ({ svgIcon, ...props }) => <Icon {...props}>{svgIcon}</Icon>

const Cloudy = {
  sad: (props: IconProps) => <CloudyIcon svgIcon={<SvgCloudySad />} {...props} />,
  confused: (props: IconProps) => <CloudyIcon svgIcon={<SvgCloudyConfused />} {...props} />,
  sleepy: (props: IconProps) => <CloudyIcon svgIcon={<SvgCloudySleepy />} {...props} />,
  happy: (props: IconProps) => <CloudyIcon svgIcon={<SvgCloudyHappy />} {...props} />
} as const

export type CloudyMood = keyof typeof Cloudy

export default Cloudy
