import { extractValueFromSummaryData } from '@weenat/client/dist/core/measurements'
import { Aggregations } from '@weenat/client/dist/enums'
import { PlotSummary } from '@weenat/client/dist/resources/measurements.type'
import { useIntl } from '@weenat/wintl'
import { RestrictionReason } from 'app/src/hooks/useIsAppAccessRestricted'
import React from 'react'
import useUniverseBackgroundMapContext from '../universes/useUniverseBackgroundMapContext'
import PlotListItemValue from './PlotListItemValue'
import PlotListItemValuesContainer from './PlotListItemValuesContainer'

interface LeafWetnessPlotListItemValuesProps {
  data: PlotSummary['data']
  isRestricted: boolean
  reason: RestrictionReason
}

const LeafWetnessPlotListItemValues: React.FC<LeafWetnessPlotListItemValuesProps> = ({
  data,
  isRestricted,
  reason
}) => {
  const { t } = useIntl()
  const { aggregate, universeAggregate } = useUniverseBackgroundMapContext()

  const { value: realTimeWetness } = extractValueFromSummaryData({
    data,
    metricId: 'LW_V',
    aggregate: Aggregations.latest,
    universeAggregate
  })

  const { value: last4hWetnessDuration } = extractValueFromSummaryData({
    data,
    metricId: 'LW_D',
    aggregate: Aggregations.lastFourHours,
    universeAggregate
  })

  const { value: last12hWetnessDuration } = extractValueFromSummaryData({
    data,
    metricId: 'LW_D',
    aggregate: Aggregations.lastTwelveHours,
    universeAggregate
  })

  return (
    <PlotListItemValuesContainer $isRestricted={isRestricted}>
      <PlotListItemValue
        isMainValue
        value={realTimeWetness}
        name={t('metrics.intensity')}
        metric={'LW_V'}
        selected={aggregate === Aggregations.latest}
        isRestricted={isRestricted}
        reason={reason}
        displayedUnit={'lws_intensity_level'}
      />
      <PlotListItemValue
        value={last4hWetnessDuration}
        name={t('map.universes.aggregate.cumul-measured-4H')}
        metric={'LW_D'}
        selected={aggregate === Aggregations.lastFourHours}
        isRestricted={isRestricted}
        reason={reason}
        displayedUnit={'hours'}
      />
      <PlotListItemValue
        value={last12hWetnessDuration}
        name={t('map.universes.aggregate.cumul-measured-12H')}
        metric={'LW_D'}
        selected={aggregate === Aggregations.lastTwelveHours}
        isRestricted={isRestricted}
        reason={reason}
        displayedUnit={'hours'}
      />
    </PlotListItemValuesContainer>
  )
}

export default LeafWetnessPlotListItemValues
