import { useIntl } from '@weenat/wintl'
import Text from 'app/src/kit/Text'
import { styled } from 'styled-components'
import DeleteMyAccountButton from './DeleteMyAccountButton'

const Container = styled(Box)`
  width: 100%;
  padding: ${(p) => p.theme.spacings.lg}px;
  border-radius: ${(p) => p.theme.radiuses.lg}px;
  border: 1px solid ${(p) => p.theme.colors.feedback.error[500]};
`

const DeleteMyAccount: FC = () => {
  const { t } = useIntl()

  return (
    <Container>
      <Text $color='feedback.error.500' $fontSize='md' $fontWeight='medium' $lineHeight='lg'>
        {t('actions.delete_my_account')}
      </Text>
      <Text>{t('user_settings.delete_account_explanation')}</Text>
      <Flex $mt='lg' $width='100%' $alignItems='center' $justifyContent='flex-end'>
        <DeleteMyAccountButton />
      </Flex>
    </Container>
  )
}

export default DeleteMyAccount
