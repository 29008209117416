import { Org } from '@weenat/client/dist/resources/orgs'
import { useIntl } from '@weenat/wintl'
import Text from 'app/src/kit/Text'
import UpgradeToPremiumButton from './UpgradeToPremiumButton'

const UpgradeToPremiumCTA: FC<{
  organization?: Org
  callToActionMessage?: string
  /** Already formatted */
  callToAction?: string
}> = ({ callToActionMessage, callToAction, organization }) => {
  const { t } = useIntl()

  return (
    <Box $py='2xl'>
      <Text $textAlign='center'>
        {callToAction ||
          t(callToActionMessage || 'ecommerce.plans.essential.common_call_to_action', {
            plan: t('ecommerce.plans.plus.label')
          })}
      </Text>
      <Flex $pt='2xl' $justifyContent='center'>
        <UpgradeToPremiumButton organizationId={organization?.id} />
      </Flex>
    </Box>
  )
}
export default UpgradeToPremiumCTA
