import { Month, fromDateToMonth } from '@weenat/client/dist/core/history'
import { useIntl } from '@weenat/wintl'
import useDisclosure from 'app/src/hooks/useDisclosure'
import Text from 'app/src/kit/Text'
import { useField } from 'formik'
import isNil from 'lodash-es/isNil'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import WeenatCalendarModal from '../calendar/WeenatCalendarModal'
import InputFieldContainer, { InputFieldContainerProps } from './InputFieldContainer'
import { TextFieldPrimitive } from './TextField'

const hourRegex = new RegExp(/^[0-9]{2}:[0-9]{2}$/)

const PseudoValueLeftAdornment: FC = ({ children }) => (
  <Flex $width='100%'>
    <Text $fontSize='sm' $lineHeight='sm'>
      {children}
    </Text>
  </Flex>
)

const DateTimePickerField: FC<
  Omit<InputFieldContainerProps, 'children' | 'value'> & {
    name: string
    minimumDate?: Date
    maximumDate?: Date
    initialMonth?: Month
    isShowingTimePicker?: boolean
    timeFieldLabel?: string
    timezone: string
    errorPath?: string
  }
> = ({
  name,
  minimumDate,
  maximumDate,
  isShowingTimePicker = false,
  timeFieldLabel,
  initialMonth,
  errorPath,
  timezone,
  $displayedError: displayedError,
  ...props
}) => {
  const { t } = useIntl()

  const { isOpen, close, open } = useDisclosure()

  const [dateField, meta, dateFieldHelpers] = useField<Date>(name)
  const [hour, setHour] = useState<string>(moment(dateField.value).format('HH:mm'))

  const momentDate = moment(dateField.value)

  const isDateValidInvalid =
    (!isNil(maximumDate) && momentDate.isAfter(maximumDate)) ||
    (!isNil(minimumDate) && momentDate.isBefore(minimumDate))

  const isHourValid = hourRegex.test(hour)

  useEffect(() => {
    if (!isHourValid) {
      dateFieldHelpers.setError(' ')
    } else {
      if (isDateValidInvalid) {
        dateFieldHelpers.setError(errorPath)
      } else {
        dateFieldHelpers.setError(undefined)
      }
    }
  }, [isHourValid])

  useEffect(() => {
    if (!isNil(dateField.value) && isDateValidInvalid && meta.error === undefined) {
      dateFieldHelpers.setError(errorPath)
    }
  }, [dateField.value?.getTime(), maximumDate?.getTime(), minimumDate?.getTime(), meta.error])

  return (
    <Flex
      $flex={1}
      onClick={() => {
        open(), dateFieldHelpers.setTouched(true)
      }}
    >
      <InputFieldContainer
        $isPointerCursor
        label={props.label}
        value={dateField.value}
        className={props.className}
        $displayedError={isNil(meta.error) ? displayedError : meta.error}
        helpers={props.helpers}
        isClearable={props.isClearable}
        hideErrors={props.hideErrors || !meta.touched}
        $isDisabled={props.$isDisabled}
        $isFocused={props.$isFocused}
        isLoading={props.isLoading}
        onClear={props.onClear}
        leftAdornment={props.leftAdornment}
        rightAdornment={props.rightAdornment}
        isRequired={props.isRequired}
        warning={props.warning}
      >
        {!isNil(dateField.value) ? (
          <PseudoValueLeftAdornment>
            {moment(dateField.value).format(t('formats.date'))}
          </PseudoValueLeftAdornment>
        ) : null}
      </InputFieldContainer>
      <WeenatCalendarModal
        isOpen={isOpen}
        close={close}
        variant='singleDate'
        title={props.label}
        initialMonth={
          !isNil(initialMonth)
            ? initialMonth
            : !isNil(dateField.value)
              ? fromDateToMonth(dateField.value)
              : undefined
        }
        maxDate={maximumDate}
        minDate={minimumDate}
        value={dateField.value}
        timezone={timezone}
        onChange={(d) => {
          const newDate = moment(d as Date)
            .set('hours', 0)
            .set('minutes', 0)
            .set('seconds', 0)
            .toDate()

          dateFieldHelpers.setValue(newDate)
          close()
        }}
        onCancel={() => {
          close()
        }}
      />
      {isShowingTimePicker && (
        <Box $ml='md'>
          <TextFieldPrimitive
            label={t('units.hours', { capitalize: true })}
            value={hour}
            onChange={(x) => {
              let newValue = x.currentTarget.value
              if (
                hour.length === 1 &&
                newValue.length === 2 &&
                !newValue.includes(':') &&
                !hour.includes(':')
              ) {
                newValue += ':'
              }
              if (newValue.slice(-2) === '::') {
                newValue = newValue.slice(0, -1)
              }
              if (hourRegex.test(newValue)) {
                const [newHour, minutes] = newValue.split(':')
                dateFieldHelpers.setValue(
                  momentDate
                    .clone()
                    .set('h', parseInt(newHour, 10))
                    .set('minute', parseInt(minutes, 10))
                    .set('second', 0)
                    .set('millisecond', 0)
                    .toDate()
                )
              }
              setHour(newValue)
            }}
            $displayedError={isHourValid ? undefined : t('forms.invalid_time_field_input')}
          />
        </Box>
      )}
    </Flex>
  )
}

export default DateTimePickerField
