import { AbsoluteHref } from '@weenat/client/dist/routx/runtime-core'
import { Head, usePathname } from 'app/routx-router'
import Text from 'app/src/kit/Text'
import { useBreadcrumbs, useSlotProvider } from 'app/state'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import {
  Fragment,
  ReactNode,
  RefObject,
  createRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useDeepCompareEffect } from 'react-use'
import { styled } from 'styled-components'
import { Breadcrumbs } from '../kit/Breadcrumbs'
import Scrollable from '../kit/Scrollable'
import SidePanelLink, { SidePanelLinkProps } from '../kit/SidePanelLink'
import SidePanelLinkGroup from '../kit/SidePanelLinkGroup'
import TextEllipsisContainer from '../kit/TextEllipsisContainer'
import LayoutHeaderActionsArea from './LayoutHeaderActionsArea'

const editPlotDevicePathnameRegex =
  /\/administration\/organizations\/\d+\/plots\/\d+\/devices\/update\//
const editPaymentMethodPathnameRegex =
  /\/administration\/organizations\/\d+\/billing\/payment-method\//

const LEFT_PANEL_WIDTH = 400
const MAX_CONTENT_WIDTH = 1080

const LeftPanel = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;

  flex-direction: column;
  height: 100%;
  width: ${LEFT_PANEL_WIDTH}px;
  min-width: ${LEFT_PANEL_WIDTH}px;
  max-width: ${LEFT_PANEL_WIDTH}px;

  background-color: ${(p) => p.theme.colors.grayscale.white};
  border-radius: 0 ${(p) => p.theme.radiuses.xl}px 0 0;
  box-shadow: ${(p) => p.theme.shadows.md.boxShadow};
`

const LeftMenuLinksContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const SectionTitle = styled(Text)`
  padding: 16px 16px 8px;
  color: ${(p) => p.theme.colors.grayscale[700]};
  font-size: ${(p) => p.theme.typography.sizes.rg}px;
  font-weight: ${(p) => p.theme.typography.weights.bold};
`

const ChildrenWrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  flex: 1;
  width: calc(100vw - 16px);
  background-color: ${(p) => p.theme.colors.grayscale[50]};
  border-radius: 0 ${(p) => p.theme.radiuses.xl}px 0 0;
  padding-left: ${LEFT_PANEL_WIDTH}px;
  margin-right: ${(p) => p.theme.spacings.lg}px;
`

const ChildrenContainer = styled(Flex)<{ $useAllWidth: boolean; $useRelativeHeight: boolean }>`
  flex: 1;
  padding: ${(p) => p.theme.spacings.xl}px;
  padding-top: 0;
  ${(p) =>
    !p.$useAllWidth &&
    `
      max-width: ${MAX_CONTENT_WIDTH}px;
    `}
  margin: auto;
  height: 100%;
  ${(p) =>
    p.$useRelativeHeight &&
    `
      height: initial;
    `}
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  margin-bottom: 16px;
`

const HeaderContent = styled(Flex)`
  align-items: center;
  justify-content: space-between;
`

const Header = styled(Box)`
  padding: 8px 32px 16px;
  width: 100%;
  max-width: ${MAX_CONTENT_WIDTH}px;
  margin: auto;
`

interface SectionItem extends SidePanelLinkProps {
  to: AbsoluteHref
  id: string
}

interface SectionGroup {
  id: string
  mainLink: SectionItem
  subLinks: SectionItem[]
}

function isSectionGroup(v: SectionItem | SectionGroup): v is SectionGroup {
  return 'mainLink' in v
}

export interface FocusLayoutMenuSection {
  id: string
  title?: string
  items: (SectionItem | SectionGroup)[]
}

interface FocusLayoutProps {
  title: string
  titleTemplate?: string
  defaultTitle?: string
  /** Menu or panel title */
  menuTitle?: string
  /** Menu or panel header */
  menuHeader?: ReactNode
  /** Menu or panel footer */
  menuFooter?: ReactNode
  menuSections: FocusLayoutMenuSection[]
}

/** Layout with left side panel */
const FocusLayout: FC<FocusLayoutProps> = ({
  children,
  defaultTitle,
  menuFooter = null,
  menuHeader = null,
  menuSections,
  menuTitle = null,
  title,
  titleTemplate
}) => {
  const pathname = usePathname()
  const [, setSlotProvider] = useSlotProvider()
  const [, setBC] = useBreadcrumbs()

  const [currentLink, setCurrentLink] = useState<
    Pick<SectionItem, 'to' | 'label' | 'description'> | undefined
  >({ to: pathname, label: '', description: undefined })

  const isEditDeviceOnPlot = !isNil(pathname) ? editPlotDevicePathnameRegex.test(pathname) : false
  const isPaymentMethod = !isNil(pathname) ? editPaymentMethodPathnameRegex.test(pathname) : false

  const resetHeaderActions = useCallback(
    () => setSlotProvider((currentSlotProvider) => ({ ...currentSlotProvider, headerActions: [] })),
    [setSlotProvider]
  )

  const scrollableRef = useRef<HTMLDivElement>(null)

  const linkRefs = useMemo(() => {
    let refs: Record<string, RefObject<HTMLDivElement>> = {}

    if (!isEmpty(menuSections)) {
      const allSectionsItems = menuSections?.reduce((acc, s) => {
        s.items.forEach((it) => {
          if (isSectionGroup(it)) {
            const linksInGroup = [it.mainLink, ...it.subLinks]
            acc.push(...linksInGroup)
          } else {
            acc.push(it)
          }
        })

        return acc
      }, [] as SectionItem[])

      refs = allSectionsItems?.reduce(
        (acc, { to }) => {
          acc[to] = createRef<HTMLDivElement>()
          return acc
        },
        {} as Record<string, RefObject<HTMLDivElement>>
      )
    }

    return refs
  }, [menuSections])

  const onLinkActive = useCallback(
    ({ to, label, description }: Pick<SectionItem, 'to' | 'label' | 'description'>) => {
      setBC({ breadcrumbs: [], enabled: true })
      resetHeaderActions()
      setCurrentLink({ to, label, description })

      const activeLinkRef = linkRefs[to]

      if (!isNil(scrollableRef.current) && !isNil(activeLinkRef) && !isNil(activeLinkRef.current)) {
        scrollableRef.current.scrollTo({
          left: 0,
          top: !isNil(activeLinkRef.current) ? activeLinkRef.current.offsetTop - 48 * 7 : 0
        })
      }
    },
    [linkRefs, resetHeaderActions, setBC]
  )

  /**
   * Would overwrite the screen set header actions if this was not a useLayoutEffect,
   * prevent stale headerActions from showing if there is no explicit HeaderActions reset rendered in the current screen
   */
  useLayoutEffect(() => {
    resetHeaderActions()
  }, [pathname])

  // update current link if menuLinks has change
  useDeepCompareEffect(() => {
    let newCurrent: SectionItem | undefined

    if (!isEmpty(menuSections)) {
      const allSectionsItems = menuSections?.reduce((acc, s) => {
        s.items.forEach((it) => {
          if (isSectionGroup(it)) {
            const linksInGroup = [it.mainLink, ...it.subLinks]
            acc.push(...linksInGroup)
          } else {
            acc.push(it)
          }
        })

        return acc
      }, [] as SectionItem[])

      // Matching the first element of every items
      // Will match on /, no slash, and /id
      newCurrent = allSectionsItems?.find(({ to }) => {
        const stringAsRegex = to?.split('/').join(`\/`)
        const regex = new RegExp(`${stringAsRegex}(\/*\\d*)*$`)

        return !isNil(pathname) ? pathname.match(regex) : false
      })

      if (newCurrent)
        setCurrentLink({
          to: newCurrent.to,
          label: newCurrent.label,
          description: newCurrent.description
        })
    }
  }, [menuSections, pathname])

  return (
    <>
      <Head title={title} titleTemplate={titleTemplate} defaultTitle={defaultTitle} />
      <ChildrenWrapper>
        <LeftPanel>
          {!isNil(menuTitle) ? (
            <Box $p='lg'>
              <Text $fontSize='lg' $fontWeight='medium' $ellipsis>
                {menuTitle}
              </Text>
            </Box>
          ) : null}
          {menuHeader}
          <LeftMenuLinksContainer>
            <Scrollable ref={scrollableRef} scrollToTopOnRouteChange={false}>
              {!isNil(menuSections)
                ? menuSections.map((section) => (
                    <Fragment key={section.id}>
                      {!isNil(section.title) ? <SectionTitle>{section.title}</SectionTitle> : null}
                      <Box $mx={'md'}>
                        {section.items.map((item) => {
                          if (isSectionGroup(item)) {
                            const ref = null

                            return (
                              <div key={item.id} ref={ref}>
                                <SidePanelLinkGroup {...item} />
                              </div>
                            )
                          } else {
                            const ref = linkRefs[item.to]

                            // added a div so that we can use refs
                            return (
                              <div key={item.id} ref={ref}>
                                <SidePanelLink
                                  {...item}
                                  onActive={() => onLinkActive({ to: item.to, label: item.label })}
                                  LeftIcon={item.LeftIcon}
                                />
                              </div>
                            )
                          }
                        })}
                      </Box>
                    </Fragment>
                  ))
                : null}
            </Scrollable>
          </LeftMenuLinksContainer>
          {menuFooter}
        </LeftPanel>

        {/* Header */}
        <Header>
          <HeaderContent>
            <TextEllipsisContainer>
              <Breadcrumbs />
            </TextEllipsisContainer>
            <LayoutHeaderActionsArea />
          </HeaderContent>
          {!isNil(currentLink) && 'description' in currentLink ? (
            <Text>{currentLink.description}</Text>
          ) : null}
        </Header>

        {/* CONTENT */}
        <ChildrenContainer $useAllWidth={isEditDeviceOnPlot} $useRelativeHeight={isPaymentMethod}>
          {children}
        </ChildrenContainer>
      </ChildrenWrapper>
    </>
  )
}

export default FocusLayout
