import { useClient, useQuery } from '@weenat/client'
import { useOrgContext } from 'app/orgProvider'
import ItemLocationPin from 'app/src/administration/plots/ItemLocationPin'
import CustomMarker from 'app/src/map/CustomMarker'
import { useUniverse } from 'app/state'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { useCallback, useEffect } from 'react'
import useDebounce from '../../../hooks/useDebounce'
import useEffectExceptOnMount from '../../../hooks/useEffectExceptOnMount'
import UserGeolocationMarker from '../../../map/UserGeolocationMarker'
import BackgroundGoogleMap from './BackgroundGoogleMap'
import { PLOT_LIST_WIDTH } from './PlotList'
import useIsRadarEnabled from './Radar/hooks/useIsRadarEnabled'
import {
  useBackgroundMapContext,
  useBackgroundMapDispatcher
} from './contexts/BackgroundMapContext'
import fitBoundsWithPadding from './utils/fitBoundsWithPadding'

type ClientType = ReturnType<typeof useClient>

interface BackgroundMapProps {
  getBoundingBoxRequester:
    | ClientType['boundingBoxes']['devices']['getBoundingBox']
    | ClientType['boundingBoxes']['plots']['getBoundingBox']
    | undefined

  /** Wether or not this map is used on plot */
  isPlotsMap?: boolean
}

const EMPTY_BBOX: [] = []

const BackgroundMap: FC<BackgroundMapProps> = ({
  isPlotsMap,
  getBoundingBoxRequester,
  children
}) => {
  const { org, currentOrgId } = useOrgContext()
  const [universe] = useUniverse()
  const [isRadarEnabled] = useIsRadarEnabled()

  const {
    api: { map, maps },
    userGeolocation,
    search,
    townGeolocation,
    headquarterLocation
  } = useBackgroundMapContext()
  const dispatch = useBackgroundMapDispatcher()

  const headquarter = headquarterLocation || org?.headquarter

  const boundingBoxRequester = getBoundingBoxRequester?.({
    organizationId: currentOrgId,
    universe
  })

  const bboxRequest = useQuery(boundingBoxRequester, {
    enabled:
      (isPlotsMap ? !isNil(currentOrgId) : true) &&
      // we won't add useLocation to not trigger multiple rerender
      // And the condition is here because we don't want to get the bbox when we pass a query location
      // eslint-disable-next-line no-restricted-globals
      !location.search.includes('latitude') &&
      !isNil(getBoundingBoxRequester)
  })

  const initialBbox = bboxRequest.data ?? EMPTY_BBOX

  function safeMapCall<Args extends Array<unknown>>(
    cb: (map: google.maps.Map, maps: typeof google.maps) => (...args: Args) => void
  ) {
    return (...args: Args) => map && maps && cb(map, maps)(...args)
  }

  const debouncedSearchTerm = useDebounce(search, 300)
  const isSearching = !isEmpty(debouncedSearchTerm) && isPlotsMap

  const fitToBounds = useCallback(
    (
      bounds:
        | google.maps.LatLngBounds
        | {
            sw: google.maps.LatLng
            ne: google.maps.LatLng
          }
    ) => {
      fitBoundsWithPadding({
        googleApi: { map, maps },
        correctedPadding: isPlotsMap ? PLOT_LIST_WIDTH : undefined,
        bounds
      })
    },
    [map, maps, isPlotsMap]
  )

  const animateToInitialBoundingBox = useCallback(() => {
    if (!isEmpty(initialBbox) && !isNil(maps)) {
      const castedBbox = initialBbox as [number, number, number, number]

      const [longitudeSouthwest, latitudeSouthwest, longitudeNortheast, latitudeNortheast] =
        castedBbox

      fitToBounds({
        sw: new maps.LatLng(latitudeSouthwest, longitudeSouthwest),
        ne: new maps.LatLng(latitudeNortheast, longitudeNortheast)
      })
    }
  }, [fitToBounds, initialBbox, maps])

  const handleRadarOpen = safeMapCall((map, maps) => () => {
    const wantedWidthInMeter = 200000
    const wantedHeightInMeter = 100000

    const bboxNE = map.getBounds()?.getNorthEast()
    const bboxSW = map.getBounds()?.getSouthWest()

    if (!isNil(bboxNE) && !isNil(bboxSW)) {
      const displayedWidthInMeter = maps.geometry.spherical.computeDistanceBetween(
        new maps.LatLng(bboxNE.lat(), bboxNE.lng()),
        new maps.LatLng(bboxNE.lat(), bboxSW.lng())
      )
      const displayedHeightInMeter = maps.geometry.spherical.computeDistanceBetween(
        new maps.LatLng(bboxNE.lat(), bboxNE.lng()),
        new maps.LatLng(bboxSW.lat(), bboxNE.lng())
      )

      // we only want to dezoom
      if (
        displayedHeightInMeter < wantedHeightInMeter &&
        displayedWidthInMeter < wantedWidthInMeter
      ) {
        const diffInLng = Math.abs(bboxSW.lng() - bboxNE.lng())
        const diffInLat = Math.abs(bboxSW.lat() - bboxNE.lat())
        // compute expected difference between NE SW latitude using ratio wanted width displayed width
        const expectedDiffInLg = (diffInLng * wantedWidthInMeter) / displayedWidthInMeter
        const expectedDiffInLat = (diffInLat * wantedHeightInMeter) / displayedHeightInMeter
        // compute how much we need to add in latitude and longitude to have the correct size
        const diffBetweenExpectedAndCurrentLat = expectedDiffInLat - diffInLat
        const diffBetweenExpectedAndCurrentLng = expectedDiffInLg - diffInLng

        // add half of missing part in both side to keep the center
        const newBbox = {
          sw: new maps.LatLng(
            bboxSW.lat() - diffBetweenExpectedAndCurrentLat / 2.0,
            bboxSW.lng() - diffBetweenExpectedAndCurrentLng / 2.0
          ),
          ne: new maps.LatLng(
            bboxNE.lat() + diffBetweenExpectedAndCurrentLat / 2.0,
            bboxNE.lng() + diffBetweenExpectedAndCurrentLng / 2.0
          )
        }
        fitToBounds(newBbox)
      }
    }
  })

  const handleZoomAnimationEnd = useCallback(() => {
    if (!isNil(map)) {
      const currentZoom = map.getZoom()
      if (!isNil(currentZoom)) {
        dispatch({ type: 'setZoomLevelTooHighForRadar', newValue: Math.round(currentZoom) > 13 })
      }
    }
  }, [map, dispatch])

  // Animate to town
  useEffectExceptOnMount(() => {
    const { location, bounds } = townGeolocation
    if (!isNil(location) && !isNil(map) && !isNil(bounds)) {
      fitToBounds({ sw: location, ne: location, bounds })
    }
  }, [townGeolocation])

  // Animate to self marker
  useEffectExceptOnMount(() => {
    if (!isNil(userGeolocation) && !isNil(map)) {
      const latLng = userGeolocation
      fitToBounds({ sw: latLng, ne: latLng })
    }
  }, [userGeolocation])

  // Resetting initial bbox on initialBbox change and map updates
  useEffect(() => {
    if (!isSearching) {
      animateToInitialBoundingBox()
    }
  }, [isSearching, animateToInitialBoundingBox])

  useEffect(() => {
    if (isRadarEnabled) {
      handleRadarOpen()
    }
  }, [isRadarEnabled])

  return (
    <BackgroundGoogleMap onZoomAnimationEnd={handleZoomAnimationEnd}>
      {children}

      {!isNil(userGeolocation) ? (
        <UserGeolocationMarker lng={userGeolocation.lng} lat={userGeolocation.lat} />
      ) : null}

      {!isNil(headquarter) ? (
        // Since Custom Marker is made for centered marker the bottom of the pin won't really point to the actual location
        // So we fake double the height to place it at the right spot
        <CustomMarker zIndex={-1} size={48} height={96} lat={headquarter.lat} lng={headquarter.lng}>
          <ItemLocationPin item='headquarter' />
        </CustomMarker>
      ) : null}
    </BackgroundGoogleMap>
  )
}

export default BackgroundMap
