import type { SVGProps } from 'react'
const SvgLocationPinHome = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox='0 0 47 48' {...props}>
    <g filter='url(#location-pin-home_svg__a)'>
      <path
        fill='#018FA4'
        d='M23 42.65q-.7 0-1.4-.25a3.7 3.7 0 0 1-1.25-.75q-3.25-3-5.75-5.85t-4.175-5.525-2.55-5.15T7 20.4q0-7.5 4.825-11.95T23 4t11.175 4.45T39 20.4q0 2.25-.875 4.725-.874 2.475-2.55 5.15T31.4 35.8t-5.75 5.85a3.7 3.7 0 0 1-1.25.75q-.7.25-1.4.25'
      />
      <path
        stroke='currentColor'
        d='M23 42.15q-.608.001-1.232-.22a3.2 3.2 0 0 1-1.08-.649q-3.234-2.985-5.712-5.81-2.478-2.826-4.127-5.461-1.65-2.638-2.503-5.052C7.78 23.353 7.5 21.835 7.5 20.4c0-4.882 1.565-8.724 4.664-11.582C15.292 5.933 18.897 4.5 23 4.5s7.708 1.433 10.836 4.318c3.099 2.858 4.664 6.7 4.664 11.582 0 1.435-.279 2.953-.846 4.558q-.853 2.414-2.503 5.052-1.65 2.635-4.127 5.46-2.479 2.827-5.712 5.811a3.2 3.2 0 0 1-1.08.648q-.623.222-1.232.221Z'
      />
    </g>
    <path
      fill='currentColor'
      d='M27.546 27.014v-9.068q0-.455-.194-.841a1.67 1.67 0 0 0-.556-.636l-5.614-4.046q0-.727.534-1.25A1.77 1.77 0 0 1 23 10.65h8.182q.75 0 1.284.534.534.535.534 1.285v12.727q0 .75-.534 1.284a1.75 1.75 0 0 1-1.284.534zm0-3.636h1.818v-1.819h-1.819zm0-3.637h1.818v-1.818h-1.819zm0-3.636h1.818v-1.818h-1.819zM13 26.105v-7.25a1.77 1.77 0 0 1 .773-1.477l4.545-3.25a1.76 1.76 0 0 1 1.046-.341q.567 0 1.045.34l4.546 3.25a1.76 1.76 0 0 1 .772 1.478v7.25a.88.88 0 0 1-.261.648.88.88 0 0 1-.648.261h-3.636V21.56h-3.636v5.454h-3.637a.88.88 0 0 1-.648-.261.88.88 0 0 1-.261-.648'
    />
    <defs>
      <filter
        id='location-pin-home_svg__a'
        width={48}
        height={54.65}
        x={0}
        y={-3}
        colorInterpolationFilters='sRGB'
        filterUnits='userSpaceOnUse'
      >
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          result='hardAlpha'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
        />
        <feOffset dx={1} dy={1} />
        <feGaussianBlur stdDeviation={4} />
        <feColorMatrix values='0 0 0 0 0 0 0 0 0 0.14902 0 0 0 0 0.207843 0 0 0 0.25 0' />
        <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_929_1581' />
        <feBlend in='SourceGraphic' in2='effect1_dropShadow_929_1581' result='shape' />
      </filter>
    </defs>
  </svg>
)
export default SvgLocationPinHome
