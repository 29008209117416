import { styled } from 'styled-components'
import { FlexProps } from './primitives/themeMappings/props'

export type BadgeProps = Omit<FlexProps, '$alignItems'>

const BadgeContainer = styled(Flex)<BadgeProps>`
  width: fit-content;

  & * {
    line-height: 1;
    white-space: nowrap;
  }
`

const Badge: FC<BadgeProps> = ({
  $backgroundColor = 'primary.500',
  $px = 'md',
  $borderColor = 'transparent',
  $borderRadius = 'rounded',
  children,
  ...props
}) => {
  return (
    <BadgeContainer
      $backgroundColor={$backgroundColor}
      $borderWidth={'sm'}
      $borderColor={$borderColor}
      $borderRadius={$borderRadius}
      $px={$px}
      {...props}
      $alignItems='center'
    >
      {children}
    </BadgeContainer>
  )
}

export default Badge
