import { useEffect, useRef } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useEffectExceptOnMount = (effectCalledExceptOnMount: () => void, deps?: any[]) => {
  const didMountRef = useRef(false)
  const didMount = didMountRef.current

  useEffect(() => {
    if (didMount) effectCalledExceptOnMount()
    else didMountRef.current = true
  }, deps || [])
}

export default useEffectExceptOnMount
