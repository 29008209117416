import { BoxProps } from 'app/src/kit/primitives/themeMappings/props'
import { MutableRefObject, useRef } from 'react'
import { css, styled } from 'styled-components'
import useComponentSize from '../hooks/useComponentSize'

type Direction = 'vertical' | 'horizontal'

type CollapsibleContainerProps = {
  $isOpen: boolean
  $contentHeight: number | undefined
  $direction: Direction
}

const CollapsibleContainer = styled(Box)<CollapsibleContainerProps>`
  overflow: hidden;

  .overflow-visible {
    overflow: visible;
  }

  height: ${(p) => (p.$isOpen ? p.$contentHeight ?? 0 : 0)}px;
  opacity: ${(p) => (p.$isOpen ? 1 : 0)};
  will-change: height, opacity;
  transition:
    height 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
`

const CollapsibleContent = styled(Box)<Pick<CollapsibleContainerProps, '$direction'>>`
  ${({ $direction }) =>
    $direction === 'horizontal' &&
    css`
      min-width: max-content;
      height: 100%;
    `}
`

interface CollapsibleProps extends Omit<BoxProps, 'ref'> {
  direction: Direction
  isOpen: boolean
  className?: string
}

const Collapsible: FC<CollapsibleProps> = ({
  direction,
  isOpen,
  width,
  as: asCompoLike,
  className,
  ...rest
}) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const { height: contentHeight } = useComponentSize(contentRef as MutableRefObject<HTMLDivElement>)

  const isHorizontal = direction === 'horizontal'

  return (
    <Box $height={'100%'}>
      <CollapsibleContainer $contentHeight={contentHeight} $isOpen={isOpen} $direction={direction}>
        <div ref={contentRef} style={isHorizontal ? { height: '100%' } : {}}>
          <CollapsibleContent $direction={direction} {...rest} />
        </div>
      </CollapsibleContainer>
    </Box>
  )
}

export default Collapsible
