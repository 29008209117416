import { useIntl } from '@weenat/wintl'
import CollapsibleCard from 'app/src/kit/CollapsibleCard'
import { memo } from 'react'
import HelpLink, { HelpLinkProps } from './HelpLink'

const DSSGuidesSection = () => {
  const {
    t,
    locales: { current }
  } = useIntl()

  const isDe = current === 'de'

  const dssLinks: HelpLinkProps[] = [
    ...(isDe
      ? []
      : [
          {
            href: t('weenat.help.application.weephyt.guideUrl'),
            message: 'help.app_use_dss_weephyt_guide',
            source: 'pdf'
          } as const
        ]),
    {
      href: t('weenat.help.application.decitrait.guideUrl'),
      message: 'help.app_use_dss_decitrait_guide',
      source: 'pdf'
    } as const,
    {
      href: t('weenat.help.application.maizy.guideUrl'),
      message: 'help.app_use_dss_maizy_guide',
      source: 'pdf'
    } as const
  ]

  return (
    <CollapsibleCard title={t('help.dss')} $mb='lg'>
      <Flex $flexDirection='column' $gap='lg' $pt='lg'>
        {dssLinks.map((link) => (
          <HelpLink {...link} key={link.href} />
        ))}
      </Flex>
    </CollapsibleCard>
  )
}

export default memo(DSSGuidesSection)
