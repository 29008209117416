import { schemas } from '@weenat/client'
import { useIntl } from '@weenat/wintl'
import useToasts from 'app/src/hooks/useToasts'
import SubmitButton from 'app/src/kit/SubmitButton'
import SuperForm from 'app/src/kit/SuperForm'
import NumberField from 'app/src/kit/fields/NumberField'
import { useClusterSettings } from 'app/state'

const ClusterSettings = () => {
  const [clusterSettings, setClusterSettings] = useClusterSettings()
  const { t } = useIntl()
  const { addToast } = useToasts()
  return (
    <SuperForm
      schema={schemas.clusterSettings}
      onSubmit={(values) => {
        setClusterSettings(values)
        addToast(t('actions.success'), { appearance: 'success' })
      }}
      initialValues={clusterSettings || { maxZoom: 21, minPointsPerCluster: 3 }}
    >
      <NumberField name='maxZoom' label={t('clusterSettings.maxZoom')} max={21} />
      <NumberField
        name='minPointsPerCluster'
        label={t('clusterSettings.minPointsPerCluster')}
        min={2}
      />
      <SubmitButton />
    </SuperForm>
  )
}
export const Component = ClusterSettings
export default ClusterSettings
