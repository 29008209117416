function SvgCloudySad(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M13.5 39.45c-2.896 0-5.369-1-7.42-2.997C4.025 34.454 3 32.012 3 29.125c0-2.474.748-4.678 2.243-6.613 1.496-1.935 3.452-3.172 5.87-3.71.796-2.919 2.387-5.282 4.773-7.09C18.273 9.904 20.977 9 24 9c3.723 0 6.88 1.292 9.473 3.877 2.594 2.585 3.89 5.734 3.89 9.445 2.196.254 4.018 1.197 5.466 2.83C44.276 26.786 45 28.697 45 30.886c0 2.379-.835 4.401-2.505 6.067-1.67 1.665-3.7 2.497-6.086 2.497H13.5zm0-3.806h22.91c1.336 0 2.465-.46 3.388-1.38.922-.92 1.384-2.046 1.384-3.378 0-1.332-.462-2.458-1.384-3.378-.923-.92-2.053-1.38-3.389-1.38h-2.864v-3.806c0-2.633-.93-4.877-2.79-6.733-1.863-1.855-4.114-2.783-6.755-2.783-2.64 0-4.892.928-6.753 2.783-1.861 1.856-2.793 4.1-2.793 6.733H13.5c-1.845 0-3.42.65-4.725 1.95-1.305 1.301-1.957 2.871-1.957 4.71 0 1.84.652 3.41 1.957 4.711 1.304 1.3 2.88 1.95 4.725 1.95z'
        fill='#002635'
      />
      <path
        d='M29 33c0-.796-.527-1.559-1.465-2.121C26.598 30.316 25.326 30 24 30s-2.598.316-3.535.879C19.527 31.44 19 32.204 19 33'
        stroke='#002635'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <g filter='url(#cloudy-sad_svg__filter0_d_22_299)'>
        <circle cx={19.5} cy={23.5} r={3.5} fill='#FFF' />
      </g>
      <circle cx={20} cy={24} r={2} fill='#002635' />
      <g filter='url(#cloudy-sad_svg__filter1_d_22_299)'>
        <circle cx={28.5} cy={23.5} r={3.5} fill='#FFF' />
      </g>
      <circle cx={28} cy={24} r={2} fill='currentColor' />
      <defs>
        <filter
          id='cloudy-sad_svg__filter0_d_22_299'
          x={11}
          y={17}
          width={15}
          height={15}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={-1} dy={1} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_22_299' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_22_299' result='shape' />
        </filter>
        <filter
          id='cloudy-sad_svg__filter1_d_22_299'
          x={22}
          y={17}
          width={15}
          height={15}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx={1} dy={1} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_22_299' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow_22_299' result='shape' />
        </filter>
      </defs>
    </svg>
  )
}

export default SvgCloudySad
