import { useClient, useQuery } from '@weenat/client'
import { GetManyQueryParams, isOrgPremium, Org } from '@weenat/client/dist/resources/orgs'
import { Id } from '@weenat/client/dist/resources/types'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { useCallback, useEffect, useState } from 'react'
import { SelectFieldPrimitive } from './fields/SelectField'

interface SelectOrganizationProps {
  onChange: (id: number, org: Org) => void
  onNoOrganizationsFound: () => void
  label?: string
  value?: Id
  queryParams: GetManyQueryParams
  selectorPredicate?: (org: Org) => boolean
}

const SelectOrganization: FC<SelectOrganizationProps> = ({
  onChange,
  onNoOrganizationsFound,
  label,
  value,
  queryParams
}) => {
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(value)

  const client = useClient()
  const organizationsRequest = useQuery(client.orgs.getAllPages(queryParams))

  const organizations = organizationsRequest.data
  const hasNoAvailableOrganization = isEmpty(organizations)

  // Resetting the select if we have organizations but none selected
  useEffect(() => {
    if (!isNil(organizations) && !isNil(organizations[0]) && isNil(selectedOrganizationId)) {
      const [firstOrg] = organizations
      setSelectedOrganizationId(firstOrg?.id)
      onChange(firstOrg?.id, organizations[0])
    }
  }, [organizations, selectedOrganizationId])

  // Checking if any organization have been found
  useEffect(() => {
    if (organizationsRequest.isSuccess && hasNoAvailableOrganization) {
      onNoOrganizationsFound()
    }
  }, [hasNoAvailableOrganization, organizationsRequest.isSuccess])

  const options = !isNil(organizations)
    ? organizations
        .sort((a) => (isOrgPremium(a) ? -1 : 1))
        .map((organization) => ({
          value: organization.id,
          label: organization.name
        }))
    : []

  const onSelect = useCallback(
    (id?: number) => {
      if (!isNil(id)) {
        const newOrg = organizations?.find((org) => org.id === id)
        if (!isNil(newOrg)) {
          setSelectedOrganizationId(id)
          onChange(id, newOrg)
        }
      }
    },
    [organizations, onChange, setSelectedOrganizationId]
  )

  return (
    <SelectFieldPrimitive
      label={label}
      options={options}
      isDisabled={organizationsRequest.isLoading}
      isLoading={organizationsRequest.isLoading}
      value={selectedOrganizationId}
      onChange={(selectedId?: number) => onSelect(selectedId)}
    />
  )
}

export default SelectOrganization
