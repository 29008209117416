function SvgSmartphone(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 36 36' xmlns='http://www.w3.org/2000/svg' fill='currentColor' {...props}>
      <path
        d='M24.84 10.018v.75a.75.75 0 00.75-.75h-.75zm0-.847h-.75.75zm-13.84 0h-.75.75zm0 17.675h-.75v.005l.75-.005zM14.198 30v-.75.75zm10.643-3.172h.75-.75zm.75-8.82a.75.75 0 10-1.5 0h1.5zM11 24.119a.75.75 0 000 1.5v-1.5zm13.84 1.5a.75.75 0 100-1.5v1.5zm-10.543-8.394a.75.75 0 00-1.023 1.097l1.023-1.097zm2.759 3.6l-.512.548a.75.75 0 001.03-.005l-.518-.543zm8.302-6.883a.75.75 0 00-1.035-1.085l1.035 1.085zM11 9.268a.75.75 0 000 1.5v-1.5zm14.59.75V9.17h-1.5v.847h1.5zm0-.847c0-.515-.102-1.026-.3-1.502l-1.385.578c.123.293.186.607.186.924h1.5zm-.3-1.502a3.92 3.92 0 00-.858-1.273l-1.056 1.065c.227.226.407.493.53.786l1.383-.578zm-.858-1.273a3.95 3.95 0 00-1.28-.848l-.57 1.387c.297.122.567.301.794.526l1.056-1.065zm-1.28-.848a3.972 3.972 0 00-1.51-.298v1.5c.323 0 .642.063.94.185l.57-1.387zm-1.51-.298h-7.444v1.5h7.444v-1.5zm-7.444 0a3.962 3.962 0 00-2.79 1.146l1.057 1.065a2.462 2.462 0 011.733-.711v-1.5zm-2.79 1.146a3.908 3.908 0 00-1.158 2.775h1.5c0-.64.256-1.255.715-1.71l-1.056-1.065zM10.25 9.171v17.675h1.5V9.171h-1.5zm0 17.68a3.908 3.908 0 001.167 2.76l1.053-1.068a2.408 2.408 0 01-.72-1.7l-1.5.008zm1.167 2.76c.74.73 1.74 1.139 2.781 1.139v-1.5c-.649 0-1.27-.255-1.728-.707l-1.053 1.069zm2.781 1.139h7.444v-1.5h-7.444v1.5zm7.444 0a3.962 3.962 0 002.79-1.146l-1.056-1.066a2.462 2.462 0 01-1.734.712v1.5zm2.79-1.146a3.908 3.908 0 001.159-2.776h-1.5c0 .64-.257 1.256-.715 1.71l1.056 1.066zm1.159-2.776v-8.82h-1.5v8.82h1.5zM11 25.618h13.84v-1.5H11v1.5zm2.274-7.297l3.27 3.051 1.023-1.096-3.27-3.052-1.023 1.097zm4.3 3.046l7.784-7.426-1.035-1.085-7.785 7.425 1.035 1.086zm7.267-12.1H11v1.5h13.84v-1.5z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgSmartphone
