import { usePathname } from 'app/routx-router'
import { useEffect } from 'react'
import useIsRadarEnabled from './useIsRadarEnabled'

const useCloseRadarOnPageChange = () => {
  const pathname = usePathname()

  const [isRadarEnabled, setIsRadarEnabled] = useIsRadarEnabled()

  useEffect(() => {
    if (isRadarEnabled) {
      setIsRadarEnabled(false)
    }
  }, [pathname])
}

export default useCloseRadarOnPageChange
