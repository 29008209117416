import { useNavigate } from 'app/routx-router'

const useGoBack = () => {
  const nav = useNavigate()

  return {
    goBack: () => {
      nav(-1)
    }
  }
}
export default useGoBack
