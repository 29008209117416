import ContentLoader, { List as ContentLoaderList } from 'react-content-loader'
import Navigable from './Navigable'
import LoadingCard from './loaders/LoadingCard'

type LoadingItemVariant = 'navigable' | 'card' | 'simple' | 'single-line'

const LoadingList: FC<{
  size?: number
  variant?: LoadingItemVariant
  uniqueKey: string
  height?: number
}> = ({ size = 10, variant = 'navigable', uniqueKey, height = 48 }) => (
  <Flex $flexDirection='column' $flex={1} $width='100%'>
    {Array.from({ length: size }, (_, i) => {
      switch (variant) {
        case 'single-line':
          return (
            <ContentLoader
              key={i}
              uniqueKey={`${uniqueKey}-${i}`}
              width='100%'
              height={height}
              viewBox={`0 0 960 ${height}`}
              style={{ maxWidth: '100%' }}
            >
              <rect x='0' y='16' rx='8' ry='8' width='896' height={height / 2} />
              <rect x='936' y='16' rx='8' ry='8' width={height / 2} height={height / 2} />
            </ContentLoader>
          )

        case 'navigable':
          return <Navigable key={i} isLoading />

        case 'card':
          return <LoadingCard uniqueKey={uniqueKey} key={i} />

        default:
          return <ContentLoaderList uniqueKey={uniqueKey} key={i} />
      }
    })}
  </Flex>
)

export default LoadingList
