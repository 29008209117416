import { OrgRole } from '@weenat/client/dist/enums'
import * as zodSchemas from '@weenat/client/dist/zod-schemas'
import { useIntl } from '@weenat/wintl'
import { useNavigate, useSearchParams } from 'app/routx-router'
import Button from 'app/src/kit/Button'
import SmartText from 'app/src/kit/SmartText'
import Text from 'app/src/kit/Text'
import OnboardingCard from 'app/src/onboardings/OnboardingCard'
import { isNil } from 'lodash-es'
import { z } from 'zod'

export const settings = {
  search: z.object({ invite: zodSchemas.emailInvitation.optional() })
}

const OnboardingMembersTips = () => {
  const { t } = useIntl()
  const nav = useNavigate()

  const [{ invite }] = useSearchParams(settings.search)

  return (
    <OnboardingCard>
      <Flex $flexDirection='column' $gap='lg'>
        <SmartText
          fontSize='md'
          $textAlign='center'
          tkey='onboardings.members_tips.joined_as'
          vars={{
            farmName: invite?.orgName,
            role:
              !isNil(invite) && !isNil(invite.role)
                ? t(`roles.${invite.role}`, { capitalize: false })
                : '-'
          }}
        />

        <Text $textAlign='center'>{t('onboardings.members_tips.see_data')}</Text>
        {invite?.role !== OrgRole.viewer ? (
          <Text $textAlign='center'>{t('onboardings.members_tips.manage')}</Text>
        ) : null}
      </Flex>
      <Button
        onPress={() => {
          nav('/', { replace: true })
        }}
      >
        {t('actions.view_farm', { capitalize: true })}
      </Button>
    </OnboardingCard>
  )
}

export default OnboardingMembersTips
