import assign from 'lodash-es/assign'
import { VictoryThemeDefinition } from 'victory'
import theme from '../../../theme'

const { colors: weenatColors } = theme

/** Colors */
const yellow200 = '#FFF59D'
const deepOrange600 = '#F4511E'
const lime300 = '#DCE775'
const lightGreen500 = '#8BC34A'
const teal700 = '#00796B'
const cyan900 = '#006064'
const colors = [deepOrange600, yellow200, lime300, lightGreen500, teal700, cyan900]
const blueGrey50 = '#ECEFF1'
const blueGrey300 = weenatColors.grayscale['300']
const blueGrey700 = '#455A64'
const grey900 = '#212121'

/** Typography @deprecated remove with chartWithD3 flag  */
export const fontFamily = "'Inter', sans-serif"
const letterSpacing = 'normal'
const labelFontSize = 10
/** @deprecated remove with chartWithD3 flag  */
export const xPadding = 48
/** @deprecated remove with chartWithD3 flag  */
export const bottomPadding = 45

/** Layout */
const padding = 8

const baseProps = {
  padding: { top: 16, left: xPadding, right: xPadding, bottom: bottomPadding }
}

/** Labels */
const baseLabelStyles = {
  fontFamily: fontFamily,
  fontSize: labelFontSize,
  letterSpacing,
  padding: 4,
  fill: weenatColors.grayscale.black,
  stroke: 'transparent',
  strokeWidth: 0
}

const centeredLabelStyles = assign({ textAnchor: 'middle' }, baseLabelStyles)

/** Strokes */
const strokeDasharray = '10, 5'
const strokeLinecap = 'round'
const strokeLinejoin = 'round'

/** @deprecated remove with chartWithD3 flag */
const victoryTheme: VictoryThemeDefinition = {
  area: assign(
    {
      style: {
        data: {
          fill: grey900
        },
        labels: { ...baseLabelStyles, fill: 'none' }
      }
    },
    baseProps
  ),
  axis: assign(
    {
      style: {
        axis: {
          fill: 'transparent',
          stroke: weenatColors.grayscale['300'],
          strokeWidth: 2,
          strokeLinecap,
          strokeLinejoin
        },
        axisLabel: assign({}, centeredLabelStyles, {
          padding: 32,
          stroke: 'transparent',
          fontSize: 12
        }),
        grid: {
          fill: 'none',
          stroke: blueGrey50,
          strokeDasharray,
          strokeLinecap,
          strokeLinejoin,
          pointerEvents: 'painted'
        },
        ticks: {
          fill: 'transparent',
          size: 4,
          stroke: blueGrey300,
          strokeWidth: 1,
          strokeLinecap,
          strokeLinejoin
        },
        tickLabels: assign({}, baseLabelStyles, {
          fill: blueGrey700
        })
      }
    },
    baseProps
  ),
  bar: assign(
    {
      style: {
        data: {
          padding,
          strokeWidth: 0.5,
          width: 3
        },
        labels: { ...baseLabelStyles, fill: 'none' }
      }
    },
    baseProps
  ),
  boxplot: assign(
    {
      style: {
        max: { padding, stroke: blueGrey700, strokeWidth: 1 },
        maxLabels: assign({}, baseLabelStyles, { padding: 3 }),
        median: { padding, stroke: blueGrey700, strokeWidth: 1 },
        medianLabels: assign({}, baseLabelStyles, { padding: 3 }),
        min: { padding, stroke: blueGrey700, strokeWidth: 1 },
        minLabels: assign({}, baseLabelStyles, { padding: 3 }),
        q1: { padding, fill: blueGrey700 },
        q1Labels: assign({}, baseLabelStyles, { padding: 3 }),
        q3: { padding, fill: blueGrey700 },
        q3Labels: assign({}, baseLabelStyles, { padding: 3 })
      },
      boxWidth: 20
    },
    baseProps
  ),
  candlestick: assign(
    {
      style: {
        data: {
          stroke: blueGrey700
        },
        labels: assign({}, baseLabelStyles, { padding: 5 })
      },
      candleColors: {
        positive: '#ffffff',
        negative: blueGrey700
      }
    },
    baseProps
  ),
  chart: assign({
    ...baseProps,
    // Leaving space for tooltips
    domainPadding: { x: [4, 4], y: [8, 16] }
  }),
  errorbar: assign(
    {
      borderWidth: 8,
      style: {
        data: {
          fill: 'transparent',
          opacity: 1,
          stroke: blueGrey700,
          strokeWidth: 2
        },
        labels: baseLabelStyles
      }
    },
    baseProps
  ),
  group: assign(
    {
      colorScale: colors
    },
    baseProps
  ),
  histogram: assign(
    {
      style: {
        data: {
          fill: blueGrey700,
          stroke: grey900,
          strokeWidth: 2
        },
        labels: { ...baseLabelStyles, fill: 'none' }
      }
    },
    baseProps
  ),
  legend: {
    colorScale: colors,
    gutter: 10,
    orientation: 'vertical',
    titleOrientation: 'top',
    style: {
      data: {
        type: 'circle'
      },
      labels: baseLabelStyles,
      title: assign({}, baseLabelStyles, { padding: 5 })
    }
  },
  line: assign(
    {
      style: {
        data: {
          fill: 'transparent',
          opacity: 1,
          stroke: blueGrey700,
          strokeWidth: 2
        },
        labels: { ...baseLabelStyles, fill: 'none' }
      }
    },
    baseProps
  ),
  pie: assign(
    {
      colorScale: colors,
      style: {
        data: {
          padding,
          stroke: blueGrey50,
          strokeWidth: 1
        },
        labels: assign({}, baseLabelStyles, { padding: 20 })
      }
    },
    baseProps
  ),
  scatter: assign(
    {
      style: {
        data: {
          fill: blueGrey700,
          opacity: 1,
          stroke: 'transparent',
          strokeWidth: 0
        },
        labels: { ...baseLabelStyles, fill: 'none' }
      }
    },
    baseProps
  ),
  stack: assign(
    {
      colorScale: colors
    },
    baseProps
  ),
  tooltip: {
    style: assign({}, baseLabelStyles, { padding: 0, pointerEvents: 'none' }),
    flyoutStyle: {
      stroke: grey900,
      strokeWidth: 1,
      fill: '#f0f0f0',
      pointerEvents: 'none'
    },
    flyoutPadding: 5,
    cornerRadius: 5,
    pointerLength: 10
  },
  voronoi: assign(
    {
      style: {
        data: {
          fill: 'transparent',
          stroke: 'transparent',
          strokeWidth: 0
        },
        labels: assign({}, baseLabelStyles, {
          padding: 5,
          pointerEvents: 'none',
          fill: 'none'
        }),
        flyout: {
          stroke: grey900,
          strokeWidth: 1,
          fill: '#f0f0f0',
          pointerEvents: 'none'
        }
      }
    },
    baseProps
  )
}

export default victoryTheme
