import { BackgroundMapUniverseMetric } from '@weenat/client/dist/core/universes'
import { TimeSteps } from '@weenat/client/dist/enums/TimeSteps'
import { Unit } from '@weenat/client/dist/enums/UnitChoices'
import { useConvertedValue } from '@weenat/client/dist/hooks'
import { useIntl } from '@weenat/wintl'
import useDisclosure from 'app/src/hooks/useDisclosure'
import { RestrictionReason } from 'app/src/hooks/useIsAppAccessRestricted'
import Icons from 'app/src/kit/Icons'
import { LockedCarouselValueModal } from 'app/src/kit/LockedFeat'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import isNumber from 'lodash-es/isNumber'
import { memo } from 'react'
import { useTheme } from 'styled-components'
import PlotListItemValueName from './PlotListItemValueName'

export type PlotSummaryValue = string | number | null | undefined

interface PlotListItemValueProps {
  isMainValue?: boolean
  value: PlotSummaryValue
  metric: BackgroundMapUniverseMetric
  timeStep?: TimeSteps
  name?: string
  selected?: boolean
  className?: string
  isRestricted?: boolean
  reason?: RestrictionReason
  /** To convert to a specific unit */
  displayedUnit?: Unit
}

const PlotListItemValue: FC<PlotListItemValueProps> = ({
  metric,
  isMainValue = false,
  name,
  value,
  selected,
  className,
  isRestricted,
  reason,
  displayedUnit
}) => {
  const { colors, radiuses } = useTheme()
  const { convertValue, getUnit } = useConvertedValue()
  const { t } = useIntl()

  const { close, isOpen, open } = useDisclosure()

  const unit = displayedUnit ?? getUnit(metric)

  const color = isRestricted ? colors.grayscale[300] : colors.metrics[metric]['500']
  const selectedStyle: React.CSSProperties = {
    border: `2px solid ${selected ? color : 'transparent'}`,
    borderRadius: radiuses.md
  } as const

  const finalValue =
    !isNil(value) && isNumber(value) ? convertValue({ metric, displayUnit: false, value }) : null

  const displayedValue = `${!isNil(finalValue) ? finalValue : '-'} ${t(`units.${unit}_abbr`)}`

  const valueSize = isMainValue ? 'md' : 'rg'
  const valueWeight = isMainValue ? 'bold' : 'regular'

  return (
    <>
      <Flex
        $flexDirection='column'
        $flex={1}
        $justifyContent={!isNil(name) ? 'space-between' : 'flex-end'}
        $alignItems='flex-start'
        style={selectedStyle}
        $p='md'
        className={className}
        onClick={
          isRestricted
            ? (e) => {
                e.stopPropagation()
                e.preventDefault()
                open()
              }
            : undefined
        }
      >
        {!isNil(name) ? <PlotListItemValueName name={name} color={color} /> : null}
        <Flex
          $flexDirection='column'
          $alignItems='flex-start'
          $justifyContent='flex-end'
          $width='100%'
          $mt={!isNil(name) ? 'sm' : 'auto'}
        >
          {isRestricted ? (
            <Flex $alignItems='flex-end' $flex={1}>
              <Icons.Lock $size='lg' $color={'grayscale.700'} />
            </Flex>
          ) : (
            <Text
              $fontVariantNumeric='tabular-nums'
              $fontSize={valueSize}
              $fontWeight={valueWeight}
            >
              {displayedValue}
            </Text>
          )}
        </Flex>
      </Flex>
      {isRestricted && (
        <LockedCarouselValueModal close={close} isOpen={isOpen} reason={reason ?? 'freemium'} />
      )}
    </>
  )
}

export default memo(PlotListItemValue)
