import { PossibleError } from '@weenat/client/dist/errors'
import { useIntl } from '@weenat/wintl'
import { useField } from 'formik'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { transparentize } from 'polished'
import { HTMLProps, useCallback, useEffect, useRef, useState } from 'react'
import { styled, useTheme } from 'styled-components'
import Icons from '../Icons'
import InputFieldContainer, { InputFieldContainerProps } from './InputFieldContainer'
import useBackendFieldError from './useBackendFieldError'

const InvisibleInput = styled.input`
  font-family: ${(p) => p.theme.typography.families.pm};
  font-size: ${(p) => p.theme.typography.sizes.sm};
  font-weight: ${(p) => p.theme.typography.weights.light};
  color: ${(p) => p.theme.colors.grayscale.black};
  background-color: transparent;
  border: 0;
  outline: none;
  pointer-events: all;
  width: 100%;
  line-height: normal;
  font-variant-numeric: tabular-nums;
`

const allNumberRegex = /^-?[0-9]\d*([.,]\d+)?$/

type HTMLInputProps = Omit<HTMLProps<HTMLInputElement>, 'children' | 'label' | 'onChange' | 'as'>
type IFCProps = Omit<InputFieldContainerProps, 'children' | 'value' | 'isFocused' | 'onPress'>

interface NumberFieldPrimitiveProps extends IFCProps, HTMLInputProps {
  max?: number
  min?: number
  onChange: (newValue: number | undefined) => void
  onError?: () => void
  step?: number
  value: number | undefined
}

export const NumberFieldPrimitive: FC<NumberFieldPrimitiveProps> = ({
  min,
  max,
  onChange,
  step = 1,
  value,
  onError,
  className,
  $displayedError,
  helpers,
  hideErrors = false,
  isClearable = false,
  $isDisabled = false,
  isLoading = false,
  isRequired = true,
  label,
  leftAdornment,
  onClear,
  rightAdornment,
  warning,
  onBlur,
  ...htmlInputProps
}) => {
  const { colors } = useTheme()

  const [displayedValue, setDisplayedValue] = useState<string>(
    !isNil(value) ? value.toString() : ''
  )
  const [hasTrailingComma, setHasTrailingComma] = useState<boolean>(false)
  const [isFocused, setIsFocused] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)

  const icnBackground = transparentize(0.9, colors.grayscale.black)

  const handleFocus = () => {
    inputRef.current?.focus()
    setIsFocused(true)
  }

  const handleChange = useCallback(
    (newValue: string) => {
      if (!isNil(newValue) && !isEmpty(newValue)) {
        setDisplayedValue(newValue)
        setHasTrailingComma(newValue.endsWith(','))

        if (newValue.match(allNumberRegex)) {
          const cleansedValue = newValue.replace(',', '.')
          const final = parseFloat(cleansedValue)

          onChange(final)
        } else if (newValue === '-' || (isEmpty(newValue) && !isRequired)) {
          setDisplayedValue(newValue)
          setHasTrailingComma(false)
          onChange(undefined)
        } else {
          onError?.()
        }
      } else {
        setHasTrailingComma(false)
        setDisplayedValue('')
        onChange(undefined)
      }
    },
    [setDisplayedValue, setHasTrailingComma, onChange, isRequired, onError]
  )

  const handlePressMinus = () => {
    let newValue

    if (!isNil(value)) {
      newValue = Math.round((value - step + Number.EPSILON) * 100) / 100
    } else {
      newValue = Math.round((0 - step + Number.EPSILON) * 100) / 100
    }

    handleChange(newValue.toString())
  }

  const handlePressPlus = () => {
    let newValue

    if (!isNil(value)) {
      newValue = Math.round((value + step + Number.EPSILON) * 100) / 100
    } else {
      newValue = Math.round((0 + step + Number.EPSILON) * 100) / 100
    }

    handleChange(newValue.toString())
  }

  useEffect(() => {
    if (!hasTrailingComma) {
      if (!isNil(value) && !isEmpty(value.toString())) {
        setDisplayedValue(value.toString().replace('.', ','))
      } else {
        setDisplayedValue('')
      }
    }
    if (!isNil(value) && typeof value === 'string') {
      onChange(Number.parseFloat((value as string).replace(',', '.')))
    }
  }, [value])

  return (
    <InputFieldContainer
      value={value}
      $isPointerCursor={false}
      label={label}
      isRequired={isRequired}
      warning={warning}
      helpers={helpers}
      $isDisabled={$isDisabled}
      isLoading={isLoading}
      className={className}
      $isFocused={isFocused}
      hideErrors={hideErrors}
      isClearable={isClearable}
      $displayedError={$displayedError}
      onPress={(e) =>
        $isDisabled
          ? null
          : () => {
              e.stopPropagation()
              handleFocus()
            }
      }
      leftAdornment={leftAdornment}
      rightAdornment={
        !isNil(rightAdornment) ? (
          rightAdornment
        ) : (
          <Flex $alignItems='center'>
            <Icons.MinusSign
              $size='md'
              $backgroundColor={icnBackground}
              $mr='md'
              $p='sm'
              onPress={handlePressMinus}
              $isDisabled={!isNil(value) && !isNil(min) && value - step < min}
            />
            <Icons.PlusSign
              $size='md'
              $backgroundColor={icnBackground}
              $p='sm'
              onPress={handlePressPlus}
              $isDisabled={!isNil(value) && !isNil(max) && value + step > max}
            />
          </Flex>
        )
      }
    >
      <InvisibleInput
        value={displayedValue}
        onBlur={(e) => {
          onBlur?.(e)
          setIsFocused(false)
        }}
        onChange={(e) => handleChange(e.currentTarget.value)}
        {...htmlInputProps}
        ref={inputRef}
        type='text'
      />
    </InputFieldContainer>
  )
}

export type NumberFieldProps = Omit<NumberFieldPrimitiveProps, 'value' | 'onChange' | 'onError'> & {
  name: string
  errorPath?: string
  errorState?: PossibleError
  onChange?: NumberFieldPrimitiveProps['onChange']
}

const NumberField: FC<NumberFieldProps> = ({ name, errorPath, errorState, onChange, ...props }) => {
  const { t } = useIntl()
  const [field, , fieldHelpers] = useField<number | undefined>(name)

  useBackendFieldError({
    name,
    errorPath: !isNil(errorPath) ? errorPath : name,
    errorState
  })

  return (
    <NumberFieldPrimitive
      {...props}
      {...field}
      onError={() => {
        fieldHelpers.setError(t('forms.invalid_number_field'))
      }}
      value={field.value}
      onChange={(newValue) => {
        fieldHelpers.setValue(newValue)
        onChange?.(newValue)
      }}
      type='number'
      name={name}
    />
  )
}

export const PositiveNumberField: FC<NumberFieldProps> = (props) => (
  <NumberField min={0} {...props} />
)

export const OnePrecisionPositiveNumberField: FC<NumberFieldProps> = (props) => (
  <PositiveNumberField step={0.1} {...props} />
)

export const TwoPrecisionPositiveNumberField: FC<NumberFieldProps> = (props) => (
  <PositiveNumberField step={0.01} {...props} />
)

export default NumberField
