import { useIntl } from '@weenat/wintl'
import TextField, { CustomFieldProps } from './TextField'

const LastNameField: FC<CustomFieldProps> = ({ name = 'lastName', ...props }) => {
  const { t } = useIntl()
  return (
    <TextField
      name={name}
      label={t('models.account.fields.last_name.label', { capitalize: true })}
      inputType='lastName'
      {...props}
    />
  )
}

export default LastNameField
