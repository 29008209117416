import {
  getPredicateMetric,
  getSourceLabel,
  PredicateWithOptionalParam,
  useGetThresholdFromParams
} from '@weenat/client/dist/core/alerts/alerts'
import { useConvertedValue } from '@weenat/client/dist/hooks'
import {
  getPredicateKey,
  isAppleScabParams,
  isBased,
  isCumul,
  isForecasted,
  isWaterStatusThresholdParams,
  Predicate
} from '@weenat/client/dist/resources/alerts.types'
import { useIntl } from '@weenat/wintl'
import SelectChip from 'app/src/kit/SelectChip'
import Text from 'app/src/kit/Text'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import noop from 'lodash-es/noop'
import moment from 'moment-timezone'
import { useMemo } from 'react'
import { styled } from 'styled-components'
import Icons from '../kit/Icons'

const SmallSelectChip = styled(SelectChip)`
  margin-right: 0;
`

interface PredicateBreadcrumbsProps {
  navigateToOp?: () => void
  navigateToParams?: () => void
  navigateToSource?: () => void
  predicate: PredicateWithOptionalParam
}

const Chevron = () => {
  return <Icons.ChevronLeft $rotate={180} $size='lg' $mb='md' />
}

const PredicateBreadcrumbs: FC<PredicateBreadcrumbsProps> = ({
  navigateToOp,
  navigateToParams,
  navigateToSource,
  predicate
}) => {
  const { t } = useIntl()
  const { source, op, params } = predicate
  const { getUnit, formatConvertedValue } = useConvertedValue()

  const predicateKey = useMemo(
    () => (!isNil(source) && !isNil(op) ? getPredicateKey(predicate as Predicate) : undefined),
    [predicate]
  )
  const metric =
    !isNil(source) && !isNil(op) ? getPredicateMetric(predicate as Predicate) : undefined
  const unit = !isNil(metric) ? getUnit(metric) : undefined

  const thresholdValue = useGetThresholdFromParams(params)

  const appendUnit = (value: number) =>
    metric === 'LW_D'
      ? `${value} ${t(`units.hours_abbr`)}`
      : !isNil(unit) && !isNil(metric)
        ? `${formatConvertedValue({ metric, value, maximumFractionDigits: 2 })} ${t(
            `units.${unit}_abbr`
          )}`
        : ''

  return (
    <Flex $mt='md' $alignItems='center'>
      {!isNil(predicate) ? (
        <>
          {!isNil(source) && (
            <SmallSelectChip isSelected={false} onPress={navigateToSource || noop}>
              {getSourceLabel(source)}
            </SmallSelectChip>
          )}
          {!isNil(op) && !isNil(predicateKey) && (
            <>
              <Chevron />
              <SmallSelectChip isSelected={false} onPress={navigateToOp || noop}>
                {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  !isEmpty(t(`alerts.predicateKeys.${predicateKey}.label`))
                    ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      t(`alerts.predicateKeys.${predicateKey}.label`)
                    : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      t(`alerts.operators.${op}.label`)
                }
              </SmallSelectChip>
            </>
          )}
          {!isNil(thresholdValue) && !isNil(params) ? (
            <>
              <Chevron />
              <SmallSelectChip $isSelected={false} onPress={navigateToParams || noop}>
                {isForecasted(params) ? (
                  <Text>
                    {t(
                      'alerts.paramSetups.fieldLabels.amountInHours.optionsLabel',
                      { numberOfHours: thresholdValue },
                      { capitalize: true }
                    )}
                  </Text>
                ) : isAppleScabParams(params) ? (
                  <Text $fontSize='sm'>{thresholdValue.infectionLevels}</Text>
                ) : isWaterStatusThresholdParams(params) ? (
                  <Box>
                    <Text $fontSize='sm'>{thresholdValue.thresholds}</Text>
                    <Text $fontSize='sm'>{thresholdValue.horizons}</Text>
                  </Box>
                ) : !isCumul(params) ? (
                  <Text>{appendUnit(thresholdValue)}</Text>
                ) : (
                  <Box>
                    {isBased(params) && (
                      <Text $fontSize='sm'>
                        {`${t('models.alert.fields.base.label', { capitalize: true })} ${
                          params.base
                        }`}
                      </Text>
                    )}
                    <Text $fontSize='sm'>
                      {!isNil(params.since)
                        ? moment.unix(params.since).format(t('formats.datetime_chart'))
                        : ''}
                    </Text>
                    <Text $fontSize='sm'>{appendUnit(thresholdValue)}</Text>
                    {params.isRepeatable && (
                      <Text $fontSize='sm'>
                        {t('alerts.paramSetups.fieldLabels.repeatable_abbrev')}
                      </Text>
                    )}
                  </Box>
                )}
              </SmallSelectChip>
            </>
          ) : null}
        </>
      ) : null}
    </Flex>
  )
}

export default PredicateBreadcrumbs
