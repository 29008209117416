import { useClient, useQuery } from '@weenat/client'
import { useIntl } from '@weenat/wintl'
import { useParams } from 'app/routx-router'
import HelmetAndBreadcrumbs from 'app/src/kit/HelmetAndBreadcrumbs'
import NetworkPresentation from 'app/src/networks/NetworkPresentation'

const Network = () => {
  const client = useClient()
  const { networkId } = useParams()
  const { t } = useIntl()

  const networkRequest = useQuery(client.networks.get(networkId))
  const network = networkRequest.data

  const networksTitle = t('models.network.model.name', { capitalize: true })

  return network ? (
    <>
      <HelmetAndBreadcrumbs screenTitle={`${networksTitle} / ${network.name}`} />
      <NetworkPresentation network={network} />
    </>
  ) : null
}

export const Component = Network
export default Network
