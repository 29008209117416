import { useSearchParams } from 'app/routx-router'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { z } from 'zod'

// TODO: this should be set on each respective screen (login/register) to not confuse with wishedNetwork
const searchParamsSchema = z.object({
  networkId: z.coerce.number().optional(),
  trial: z.boolean().optional()
})

const useWishedNetworkFromUrl = (): { networkId: number; trial: boolean } | undefined => {
  const [{ networkId, trial }] = useSearchParams(searchParamsSchema)

  let result

  if (!isNil(networkId) && !isEmpty(networkId) && !isNil(trial)) {
    result = { networkId, trial }
  }
  return result
}

export default useWishedNetworkFromUrl
