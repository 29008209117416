import Text from 'app/src/kit/Text'
import { memo } from 'react'

interface PlotListItemValueNameProps {
  name: string
  color: string
}

const PlotListItemValueName: FC<PlotListItemValueNameProps> = ({ name, color }) => {
  return (
    <Text
      $lineHeight='none'
      $fontSize='xs'
      $fontWeight='bold'
      $letterSpacing='wider'
      $color={color}
    >
      {name.toUpperCase()}
    </Text>
  )
}

export default memo(PlotListItemValueName)
