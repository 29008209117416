import { WeenatTheme } from '@weenat/theme'
import { useIntl } from '@weenat/wintl'
import { useBackgroundMapContext } from 'app/src/dashboard/components/DashboardMap/contexts/BackgroundMapContext'
import ErrorMessage from 'app/src/kit/ErrorMessage'
import { TextFieldPrimitive } from 'app/src/kit/fields/TextField'
import { MapsControlsOptions } from 'app/src/map/utils/defaults'
import { MINIMUM_NUMBER_OF_POLYGON_PATHS } from 'app/src/plots/creation/constants'
import { Position } from 'geojson'
import isNil from 'lodash-es/isNil'
import noop from 'lodash-es/noop'
import { useCallback, useEffect, useRef, useState } from 'react'
import { styled, useTheme } from 'styled-components'
import useEffectExceptOnMount from '../../hooks/useEffectExceptOnMount'
import MapMediumContainer from '../../map/MapMediumContainer'
import StandaloneGoogleMap from '../../map/StandaloneGoogleMap'
import UserGeolocationMarker from '../../map/UserGeolocationMarker'
import {
  GMDrawingManager,
  GMDrawingManagerOptions,
  GMPlacesService,
  GMPolygon,
  GMPolygonDrawingOverlay,
  GoogleMapApi,
  drawShape,
  geoJsonShapeTypes,
  getShapeOptionsFromTheme
} from '../../map/utils'

const GooglePlacesInputContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: all;
  flex: 2/3;
  padding: ${(p) => p.theme.spacings.lg}px;
`

export const googleMapsPolygonToGeoJSONPolygon = (polygon: GMPolygon) => {
  const paths = polygon.getPath().getArray()
  const points = paths.map((point) => [point.lng(), point.lat()] as Position)
  const [first] = points
  // See WRONG first answer of this thread, and CORRECT comment of this answer:
  // https://stackoverflow.com/questions/47198683/getting-a-geojson-from-a-google-maps-drawing-manager-polygon
  return {
    type: geoJsonShapeTypes.Polygon,
    // Coordinates is an array of array of array (needed to represent holes even if we have none)
    // We concatenates the first point inside points because GeoJSON polygon object require
    // the last point of a Polygon shape to be the same as the first one
    coordinates: [points.concat([first])]
  }
}

// Directly choose drawing mode, otherwise the user would be in non-drawing mode
// and would have to select the tool to draw polygons
const getDrawingManagerOptions = (theme: WeenatTheme): GMDrawingManagerOptions => ({
  drawingMode: 'polygon',
  drawingControl: false,
  polygonOptions: getShapeOptionsFromTheme(theme)
})

interface DrawPlotShapeMapProps {
  containerHeight: number
  isDrawingControlDisabled: boolean
  isFullscreenControlDisabled: boolean
  isTrashControlDisabled: boolean
  onCompletedGeoJSONShapeChange: (value: unknown) => void
  onFullscreen?: (newValue?: boolean) => void
  onIsDegeneratedPolygonChange: (value: boolean) => void
  previousGeoJSON: unknown
}

const DrawPlotShapeMap = ({
  containerHeight,
  isDrawingControlDisabled,
  isFullscreenControlDisabled,
  isTrashControlDisabled,
  onCompletedGeoJSONShapeChange,
  onFullscreen,
  onIsDegeneratedPolygonChange,
  previousGeoJSON
}: DrawPlotShapeMapProps) => {
  const { t } = useIntl()
  const theme = useTheme()

  const { userGeolocation } = useBackgroundMapContext()

  const autocompleteInputRef = useRef(null)

  const [completedPolygon, setCompletedPolygon] = useState<GMPolygon | null>(null)
  // cf. MINIMUM_NUMBER_OF_POLYGON_PATHS
  const [isDegeneratedPolygon, setIsDegeneratedPolygon] = useState<boolean>(false)

  // The Google Maps instance of the drawn shape
  const [drawnPreviousShape, setDrawnPreviousShape] = useState<GMPolygon | null>(null)
  // Used to avoid redrawing the shape when
  // the component is rerendered (for example due to a rts fetch),
  // this would be problematic in the case where
  // the user cleared the drawn shape and now see a new shape appear from nowhere
  const [didAlreadyDrawnPreviousShape, setDidAlreadyDrawnPreviousShape] = useState(false)
  const [{ maps, map, drawingManager, placesService }, setGoogleMapsApi] = useState<{
    maps: GoogleMapApi['maps']
    map: GoogleMapApi['map']
    drawingManager: GMDrawingManager | null
    placesService: GMPlacesService | null
  }>({
    map: null,
    maps: null,
    drawingManager: null,
    placesService: null
  })
  const [currentDrawingMode, setCurrentDrawingMode] = useState<GMPolygonDrawingOverlay | null>(null)
  const [isFullscreenActive, setIsFullscreenActive] = useState(false)

  // Those callback also call the props callback given by the consumer of this component
  const setIsDegeneratedPolygonWithHandler = useCallback(
    (value: boolean) => {
      onIsDegeneratedPolygonChange(value)
      setIsDegeneratedPolygon(value)
    },
    [onIsDegeneratedPolygonChange]
  )

  const setCompletedPolygonWithHandler = useCallback(
    (value: GMPolygon | null) => {
      onCompletedGeoJSONShapeChange(isNil(value) ? value : googleMapsPolygonToGeoJSONPolygon(value))
      setCompletedPolygon(value)
    },
    [onCompletedGeoJSONShapeChange]
  )

  const setLocalMapsApi = useCallback(
    (googleApi: GoogleMapApi) => {
      if (!isNil(googleApi.maps) && !isNil(googleApi.map)) {
        const service = new googleApi.maps.places.PlacesService(googleApi.map)
        const manager = new googleApi.maps.drawing.DrawingManager(getDrawingManagerOptions(theme))

        setGoogleMapsApi({
          map: googleApi.map,
          maps: googleApi.maps,
          drawingManager: manager,
          placesService: service
        })
      }
    },
    [theme]
  )

  const toggleFullscreen = () => {
    if (!isNil(onFullscreen) && !isFullscreenControlDisabled) {
      setIsFullscreenActive(!isFullscreenActive)
      onFullscreen()
    }
  }

  // Clear either the drawnPreviousShape or the user drawn completedPolygon
  const clearDrawings = useCallback(() => {
    if (!isNil(drawnPreviousShape)) {
      drawnPreviousShape.setMap(null)
      // This will trigger the call to the prop callback,
      // with the useEffect watching drawnPreviousShape
      setDrawnPreviousShape(null)
    } else if (!isNil(completedPolygon)) {
      // Remove the completed polygon, set the drawing mode
      // back to polygon because we were in navigation mode and redisplay the controls
      completedPolygon.setMap(null)
      setCompletedPolygonWithHandler(null)
      setIsDegeneratedPolygonWithHandler(false)
    }
    setCurrentDrawingMode('polygon')
  }, [
    drawnPreviousShape,
    completedPolygon,
    setCompletedPolygonWithHandler,
    setIsDegeneratedPolygonWithHandler
  ])

  const activateDrawingMode = useCallback(() => {
    setCurrentDrawingMode('polygon')
  }, [setCurrentDrawingMode])

  const activateGrabMode = useCallback(() => {
    setCurrentDrawingMode(null)
  }, [setCurrentDrawingMode])

  const controlsOptions: MapsControlsOptions = {
    ['DRAWING']: {
      ['GRAB']: {
        active: currentDrawingMode == null,
        onPress: activateGrabMode
      },
      ['POLYGON']: {
        //should be ok since, no other values than polygon or null can be attributed to currentDrawingMode
        active: !isNil(maps) && !isNil(currentDrawingMode),
        onPress: activateDrawingMode,
        disabled: !isNil(completedPolygon) || !isNil(drawnPreviousShape) || isDrawingControlDisabled
      },
      ['TRASH']: {
        onPress: clearDrawings,
        disabled: (isNil(completedPolygon) && isNil(drawnPreviousShape)) || isTrashControlDisabled
      }
    }
  }

  // if onFullScreen isn't defined then we're not adding the fullscreen control
  if (!isNil(onFullscreen)) {
    controlsOptions['DRAWING']['FULLSCREEN'] = {
      onPress: toggleFullscreen,
      active: isFullscreenActive,
      disabled: isFullscreenControlDisabled
    }
  }

  // Zoom on location marker
  useEffectExceptOnMount(() => {
    if (!isNil(userGeolocation) && !isNil(map) && !isNil(maps)) {
      const latLng = userGeolocation

      map.fitBounds(new maps.LatLngBounds(latLng, latLng), 90)
      map.setZoom(16)
    }
  }, [userGeolocation])

  // As soon as we draw the shape of the previousGeoJson, call the callback prop
  // with the previousGeoJSON they just gave us and from which we constructed the drawnPreviousShape
  // Or null if the drawnPreviousShape was cleared
  useEffect(() => {
    onCompletedGeoJSONShapeChange(isNil(drawnPreviousShape) ? drawnPreviousShape : previousGeoJSON)
  }, [drawnPreviousShape])

  // The drawingManager variable is required in deps,
  // because it may not be there in the first changes of currentDrawingMode
  useEffect(() => {
    if (!isNil(drawingManager)) drawingManager.setDrawingMode(currentDrawingMode)
  }, [currentDrawingMode, drawingManager])

  // Draw the previousGeoJSON only if it has not been drawn
  useEffect(() => {
    if (
      !isNil(map) &&
      !isNil(maps) &&
      !isNil(previousGeoJSON) &&
      isNil(drawnPreviousShape) &&
      !didAlreadyDrawnPreviousShape
    ) {
      const { shape: drawnShape } = drawShape({
        map,
        maps,
        theme,
        shape: previousGeoJSON,
        options: {
          recenterOnShape: true
        }
      })
      setDrawnPreviousShape(drawnShape)
      setDidAlreadyDrawnPreviousShape(true)
    }
  }, [maps, map, previousGeoJSON, didAlreadyDrawnPreviousShape])

  // In the getDrawingManagerOptions function, we set the overlay type to Polygon by default
  // But the first time we receive the previousGeoJSON prop
  // (hence it is not drawn) we want to switch back to the grab control mode,
  // since we can not edit the shape before putting the previousShape to the trash
  // But ONLY if we have a previousGeoJSON
  useEffect(() => {
    if (!isNil(previousGeoJSON) && !didAlreadyDrawnPreviousShape) {
      setCurrentDrawingMode(null)
    }
  }, [previousGeoJSON, didAlreadyDrawnPreviousShape])

  useEffect(() => {
    if (!isNil(map) && !isNil(maps) && !isNil(drawingManager)) {
      drawingManager.setMap(map)
      // The callback receive a reference to the completed polygon, not an event
      // Be aware that the drawing of the previousGeoJSON won't trigger this event handler
      // Since those shape are not created via the drawing manager. (desired behavior)
      const polygonCompleteListener = maps.event.addListener(
        drawingManager,
        'polygoncomplete',
        (polygon: GMPolygon) => {
          // Switch back to non-drawing mode after drawing a polygon
          // so that the user can undo his hard work by clicking the trash control
          setCurrentDrawingMode(null)
          setCompletedPolygonWithHandler(polygon)
          // See https://fr.wikipedia.org/wiki/Polygone#Classement_suivant_le_nombre_de_c%C3%B4t%C3%A9s
          // Our polygon consist of two superposed path, which is dumb and invalid
          if (polygon.getPath().getArray().length < MINIMUM_NUMBER_OF_POLYGON_PATHS)
            setIsDegeneratedPolygonWithHandler(true)
        }
      )

      return () => maps.event.removeListener(polygonCompleteListener)
    }

    return undefined
  }, [drawingManager, map])

  // Setup the binding between the google places api and our autocomplete input
  // When a new place is selected, we set the map bounds and center to the given location
  useEffect(() => {
    if (
      !isNil(map) &&
      !isNil(maps) &&
      !isNil(placesService) &&
      !isNil(autocompleteInputRef.current)
    ) {
      const googlePlacesAutocompleteInput = new maps.places.Autocomplete(
        autocompleteInputRef.current
      )

      const placeChangeListener = maps.event.addListener(
        googlePlacesAutocompleteInput,
        'place_changed',
        () => {
          const { geometry: { location, viewport } = {} } = googlePlacesAutocompleteInput.getPlace()
          // Guards are mandatory
          if (location) map.panTo(location)
          if (viewport) map.fitBounds(viewport)
        }
      )
      return () => maps.event.removeListener(placeChangeListener)
    }
    return undefined
  }, [autocompleteInputRef, placesService, maps, map])

  return (
    <Flex $flex={1} $flexDirection='column' $width='100%'>
      <MapMediumContainer>
        <StandaloneGoogleMap
          containerHeight={containerHeight}
          controls={['DRAWING']}
          controlsOptions={controlsOptions}
          onGoogleApiLoaded={setLocalMapsApi}
        >
          {!isNil(userGeolocation) && (
            <UserGeolocationMarker lat={userGeolocation.lat} lng={userGeolocation.lng} />
          )}
        </StandaloneGoogleMap>
        <GooglePlacesInputContainer>
          <TextFieldPrimitive
            id='googlePlacesAutocompleteInput'
            type='text'
            ref={autocompleteInputRef}
            placeholder={t('actions.search_city')}
            onFocus={() => {
              onFullscreen?.(true)
            }}
            onBlur={() => {
              onFullscreen?.(false)
            }}
            // The input is "controlled" by google maps, no need for an onChange handler
            onChange={noop}
          />
        </GooglePlacesInputContainer>
      </MapMediumContainer>
      <Box $mt='lg'>
        {isDegeneratedPolygon && (
          <Box $mb='lg'>
            <ErrorMessage $error={t(`models.plot.errors.shape_is_invalid`)} />
          </Box>
        )}
      </Box>
    </Flex>
  )
}

export default DrawPlotShapeMap
