import { WeenatColor } from '@weenat/theme'
import Text from 'app/src/kit/Text'
import Badge, { BadgeProps } from './Badge'

interface AdminBadgeProps extends Omit<BadgeProps, '$backgroundColor'> {
  label: string
  $backgroundColor: WeenatColor
  labelColor: WeenatColor
}

/** Badge used on admin lists */
const AdminBadge: FC<AdminBadgeProps> = ({ $backgroundColor, labelColor, label, ...props }) => {
  return (
    <Badge $backgroundColor={$backgroundColor} $px='md' $py='sm' {...props}>
      <Text $fontSize='sm' $fontWeight='medium' $color={labelColor} $ellipsis>
        {label}
      </Text>
    </Badge>
  )
}

export default AdminBadge
