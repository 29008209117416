import { Device } from '@weenat/client/dist/resources/devices'
import { MeasurementConfig } from '@weenat/client/dist/resources/measurementConfigs'
import SelectableCard from 'app/src/kit/SelectableCard'
import { FlexProps } from 'app/src/kit/primitives/themeMappings/props'
import isNil from 'lodash-es/isNil'
import { styled } from 'styled-components'
import DeviceDescription from '../devices/DeviceDescription'
import DeviceHorizonSelector from '../devices/DeviceHorizonSelector'

const SELECTABLE_DEVICE_CARD_HEIGHT = 96

interface ContainerProps {
  $height: number
}

const Container = styled(SelectableCard)<ContainerProps>`
  /** So that every list item is the same size, with or without hpot horizon */
  height: ${({ $height }) => $height}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(p) => p.isDisabled && p.theme.colors.grayscale[100]};
  border: 2px solid
    ${(p) => (p.isSelected ? p.theme.colors.primary[500] : p.theme.colors.grayscale[100])};

  &:hover {
    border-color: ${(p) =>
      p.isDisabled
        ? 'none'
        : p.isSelected
          ? p.theme.colors.primary[200]
          : p.theme.colors.grayscale[300]};
  }
`

interface SelectableDeviceCardProps {
  className?: string
  device: Device
  isDisabled?: boolean
  isSelected: boolean
  measurementConfig?: MeasurementConfig
  onHorizonChange?: (horizonId: number) => void
  onPress: FlexProps['onClick']
  showHorizons?: boolean
  initialHorizonId?: number
}

const SelectableDeviceCard: FC<SelectableDeviceCardProps> = ({
  className,
  device,
  isDisabled = false,
  isSelected = false,
  measurementConfig,
  onHorizonChange,
  onPress,
  showHorizons = false,
  initialHorizonId
}) => {
  const handlePress = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!isDisabled && !isNil(onPress)) {
      onPress(e)
    }
  }

  return (
    <Container
      className={className}
      onPress={isDisabled ? undefined : handlePress}
      isSelected={isSelected}
      isDisabled={isDisabled}
      height={SELECTABLE_DEVICE_CARD_HEIGHT}
    >
      <Box $flex={showHorizons ? 2 / 3 : 1}>
        <DeviceDescription isRestricted={false} variant='short' device={device} />
      </Box>
      {showHorizons ? (
        <Box $flex={1 / 3} $mt='md'>
          <DeviceHorizonSelector
            initialId={initialHorizonId}
            measurementConfig={measurementConfig}
            onChange={(hId: number) => {
              if (!isNil(onHorizonChange)) {
                onHorizonChange(hId)
              }
            }}
            canEdit={true}
          />
        </Box>
      ) : null}
    </Container>
  )
}

export default SelectableDeviceCard
