import { core } from '@weenat/client'
import { useIntl } from '@weenat/wintl'
import { Slot, useNavigate } from 'app/routx-router'
import AlertsCTAIllustration from 'app/src/discover-menu/svg/AlertsCTAIllustration'
import { useIsAppAccessRestricted } from 'app/src/hooks/useIsAppAccessRestricted'
import useLogAndNavToUpgradePlans from 'app/src/hooks/useLogAndNavToUpgradePlans'
import CTAFullScreen from 'app/src/kit/CTAFullScreen'
import LockedFeat from 'app/src/kit/LockedFeat'
import PublicLayout from 'app/src/layouts/PublicLayout'

const AlertsLayout: FC = () => {
  const { hasAtLeastOneBilledOrg, isLoading: isLoadingOneBilledOrg } =
    core.me.useHasAtLeastOneBilledOrg()
  const { isRestricted, reason, isLoading: isLoadingRestriction } = useIsAppAccessRestricted()
  const logAndNavToUpgradePlans = useLogAndNavToUpgradePlans()
  const nav = useNavigate()
  const { t } = useIntl()
  return (
    <PublicLayout>
      {isLoadingRestriction || isLoadingOneBilledOrg ? null : hasAtLeastOneBilledOrg &&
        !isRestricted ? (
        <Slot />
      ) : isRestricted && reason === 'freemium' ? (
        <CTAFullScreen
          isOpen
          onClose={() => {
            nav('/')
          }}
          title={t('discover_menu.calls_to_action.alerts_title', { capitalize: true })}
          description={t('discover_menu.calls_to_action.alerts_description', {
            capitalize: true
          })}
          mainCTA={{
            label: t('actions.i_subscribe', { capitalize: true }),
            onPress: () => {
              logAndNavToUpgradePlans()
            }
          }}
          secondaryCTA={{
            label: t('discover_menu.calls_to_action.alert_secondary_cta', { capitalize: true }),
            to: '/discover/application/alerts'
          }}
          Illustration={<AlertsCTAIllustration />}
        />
      ) : isRestricted && reason === 'downgrade' ? (
        <LockedFeat
          title='ecommerce.plans.restrictions.alerts_title'
          body='ecommerce.plans.restrictions.carousel_value_downgrade_message'
          showCTA
        />
      ) : null}
    </PublicLayout>
  )
}

export const Component = AlertsLayout
export default AlertsLayout
