import Text, { TextProps } from 'app/src/kit/Text'
import { styled } from 'styled-components'

const StyledText = styled(Text)`
  text-align: center;
  user-select: none;
  font-weight: ${(p) => p.theme.typography.weights.medium};
  line-height: normal;
  font-variant-numeric: tabular-nums;
`

const MarkerText: FC<TextProps> = ({ children, ...props }) => {
  return <StyledText {...props}>{children}</StyledText>
}

export default MarkerText
