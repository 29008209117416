import Text from 'app/src/kit/Text'
import FadeIn from './animations/FadeIn'

const Headline: FC = ({ children }) => {
  return (
    <FadeIn>
      <Flex $p='lg' $justifyContent='center'>
        <Text $fontSize='lg' $fontWeight='bold' $color={'primary.500'} $textAlign='center'>
          {children}
        </Text>
      </Flex>
    </FadeIn>
  )
}

export default Headline
