import { getMarkerBackgroundColor, getMarkerColorScale } from '@weenat/client/dist/core/map'
import {
  ClusterProperties,
  extractValueFromSummaryData,
  getZIndex,
  metricUsingMaxAgg,
  metricUsingMinAgg
} from '@weenat/client/dist/core/measurements'
import { AllKey } from '@weenat/client/dist/enums/MetricIds'
import { useConvertedValue } from '@weenat/client/dist/hooks'
import { HydricStressTrend } from '@weenat/client/dist/resources/measurements.type'
import { useIsAppAccessRestricted } from 'app/src/hooks/useIsAppAccessRestricted'
import Icons from 'app/src/kit/Icons'
import WindDirectionIcon from 'app/src/kit/icons/WindDirectionIcon'
import { useUniverseHorizon } from 'app/state'
import { isEmpty, max, mean, min } from 'lodash-es'
import isNil from 'lodash-es/isNil'
import { readableColor } from 'polished'
import { styled, useTheme } from 'styled-components'
import useUniverseBackgroundMapContext from '../universes/useUniverseBackgroundMapContext'
import { isFreemiumAccessibleAggregate } from '../utils/fitBoundsWithPadding'
import MarkerText from './MarkerText'

const SHADOW_RGBA = 'rgba(0, 38, 53, 0.25)'
const PILL_VALUE_CONTAINER_SIZE = 48

const CLUSTER_MARKER_WIDTH = PILL_VALUE_CONTAINER_SIZE + 8
const CLUSTER_MARKER_HEIGHT = 92

interface ClusterPillProps {
  $rawValue: number | null
  $metricId?: AllKey
  $radius: 'rounded' | 'md'
}

const ClusterPill = styled(Flex)<ClusterPillProps>`
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  border: 2px solid ${(p) => p.theme.colors.grayscale[300]};
  border-radius: ${(p) => p.theme.radiuses[p.$radius]}px;

  width: ${CLUSTER_MARKER_WIDTH}px;
  height: ${CLUSTER_MARKER_HEIGHT}px;

  padding: 4px 28px;

  background-color: ${(p) => p.theme.colors.grayscale.white};
  box-shadow: 0px 0px 16px ${SHADOW_RGBA};

  z-index: ${(p) => getZIndex({ metricId: p.$metricId, rawValue: p.$rawValue })};

  cursor: pointer;
  pointer-events: auto;

  transition:
    background-color 0.2s ease-out,
    border-color 0.2s ease-out,
    box-shadow 0.2s ease-out;

  will-change: border-color, box-shadow, background-color, z-index;

  &:hover {
    background-color: ${(p) => p.theme.colors.primary[500]};
    border-color: ${(p) => p.theme.colors.primary[800]};
    z-index: ${(p) => getZIndex({ isFocused: true, metricId: p.$metricId, rawValue: p.$rawValue })};
    box-shadow: 0 2px 4px ${SHADOW_RGBA};
  }
`

const ClusterPillValueContainer = styled(Flex)<{ $radius: 'rounded' | 'md' }>`
  position: relative;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: ${(p) => p.theme.radiuses[p.$radius]}px;

  width: ${PILL_VALUE_CONTAINER_SIZE}px;
  height: ${PILL_VALUE_CONTAINER_SIZE}px;
`

interface ClusterMarkerProps
  extends Pick<ClusterProperties, 'summaries' | 'countByMetric' | 'isVirtualDeviceByMetric'> {
  isPlotsCluster: boolean
  lat: number
  lng: number
  ids: Set<number>
  onPress: () => void
}

const ClusterMarker = ({
  countByMetric,
  summaries,
  isVirtualDeviceByMetric,
  onPress,
  isPlotsCluster,
  lat: _l,
  lng: _lng
}: ClusterMarkerProps) => {
  const [horizon] = useUniverseHorizon()
  const { colors } = useTheme()
  const { convertValue } = useConvertedValue()

  const { isRestricted: isAccessRestrictedByPlan } = useIsAppAccessRestricted({
    enabled: true
  })

  const { focusedMetricId, aggregate, universeAggregate, span } = useUniverseBackgroundMapContext()
  let reducer = mean
  if (!isNil(focusedMetricId)) {
    if (metricUsingMaxAgg.includes(focusedMetricId)) {
      reducer = max
    } else if (metricUsingMinAgg.includes(focusedMetricId)) {
      reducer = min
    }
  }
  const summaryValueAndThresholds = summaries.reduce(
    (acc, summary) => {
      if (focusedMetricId != null && universeAggregate != null) {
        const extractedValue = extractValueFromSummaryData({
          data: summary,
          metricId: focusedMetricId,
          aggregate,
          universeAggregate,
          horizon
        })
        if (!isNil(extractedValue.value)) {
          acc.set(extractedValue.value, extractedValue)
        }
      }
      return acc
    },
    new Map<
      number,
      {
        value: number | null
        timestamp: string | null
        threshold?: 'saturation' | 'comfort' | 'vigilance' | 'stress' | undefined
        trend?: HydricStressTrend | undefined
      }
    >()
  )
  const values = Array.from(summaryValueAndThresholds.keys())
  const value =
    !isNil(focusedMetricId) && !isNil(universeAggregate) && !isEmpty(values)
      ? reducer(values)
      : null

  const displayed = !isNil(focusedMetricId)
    ? convertValue({ metric: focusedMetricId, value, displayUnit: false })
    : ''

  const isRestricted = isAccessRestrictedByPlan
    ? isNil(focusedMetricId)
      ? true
      : isVirtualDeviceByMetric.get(focusedMetricId) ?? true
        ? !isFreemiumAccessibleAggregate(universeAggregate)
        : true
    : false

  const colorScale = getMarkerColorScale(focusedMetricId, span)
  const backgroundColor = getMarkerBackgroundColor({
    rawValue: value,
    colorScale,
    colors,
    focusedMetricId,
    threshold: !isNil(value) ? summaryValueAndThresholds.get(value)?.threshold : undefined,
    isRestricted
  })

  const textColor = readableColor(backgroundColor)

  const radius = isPlotsCluster ? 'rounded' : 'md'

  return !isNil(focusedMetricId) &&
    countByMetric.has(focusedMetricId) &&
    (countByMetric.get(focusedMetricId) ?? 0) > 0 &&
    !isNil(value) ? (
    <ClusterPill $rawValue={value} $metricId={focusedMetricId} onClick={onPress} $radius={radius}>
      <ClusterPillValueContainer $backgroundColor={backgroundColor} $radius={radius}>
        {isRestricted ? (
          <Icons.Lock $color={textColor} $size='lg' />
        ) : !isNil(value) ? (
          <>
            {focusedMetricId === 'DD' && Number.isFinite(value) ? (
              <WindDirectionIcon windOrigin={value} $color={textColor} $size='rg' />
            ) : (
              <MarkerText $color={textColor}>{displayed}</MarkerText>
            )}
          </>
        ) : (
          <Icons.MinusSign $color={textColor} $size='lg' />
        )}
      </ClusterPillValueContainer>

      <Flex
        $mt='md'
        $width={40}
        $height={20}
        $alignItems='center'
        $justifyContent='center'
        $gap='xs'
      >
        <MarkerText $color={'grayscale.black'}>{countByMetric.get(focusedMetricId)}</MarkerText>
      </Flex>
    </ClusterPill>
  ) : null
}

export default ClusterMarker
