import { useClient, useQuery } from '@weenat/client'
import { Horizon } from '@weenat/client/dist/resources/horizons'
import { useIntl } from '@weenat/wintl'
import Modal, { ModalProps } from '../kit/Modal'
import SelectChip from '../kit/SelectChip'
import Text from '../kit/Text'

const EMPTY_HORIZONS: Horizon[] = []

interface HorizonModalProps extends Pick<ModalProps, 'isOpen' | 'close'> {
  deviceName: string
  deviceId: number
  depth?: string
  onAdd?: (deviceId: number, depth?: number, horizonId?: number) => void
}

const HorizonModal: FC<HorizonModalProps> = ({
  isOpen,
  close,
  deviceName,
  onAdd,
  depth,
  deviceId
}) => {
  const client = useClient()
  const { t } = useIntl()

  const horizonsRequest = useQuery(client.horizons.getAll())

  const horizons = horizonsRequest.data?.results ?? EMPTY_HORIZONS

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      title={t('models.device.actions.add_horizon', { capitalize: true })}
      maxWidth={480}
    >
      <Flex $flexDirection='column' $gap='lg'>
        <Box $backgroundColor='grayscale.100' $p={'md'} $borderRadius={'md'}>
          <Text $fontWeight='bold'>{deviceName}</Text>
        </Box>
        <Flex $gap='md' $flexWrap='wrap' $justifyContent='space-between'>
          {horizons.map((h) => (
            <SelectChip
              isSelected={`${depth}` === `${h.value}`}
              key={h.id}
              onPress={() => {
                onAdd?.(deviceId, h.value, h.id)
                close?.()
              }}
            >
              {`${h.value} ${t('units.centimeters_abbr')}`}
            </SelectChip>
          ))}
        </Flex>
      </Flex>
    </Modal>
  )
}

export default HorizonModal
