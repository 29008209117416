import { SVGProps } from 'react'

const SvgCloudyHappy = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M13.5 39.225c-2.896 0-5.369-1-7.42-2.997C4.025 34.229 3 31.787 3 28.9c0-2.473.748-4.678 2.243-6.613 1.496-1.935 3.452-3.172 5.87-3.71.796-2.919 2.387-5.282 4.773-7.09C18.273 9.679 20.977 8.775 24 8.775c3.723 0 6.88 1.292 9.473 3.877 2.594 2.585 3.89 5.734 3.89 9.445 2.196.254 4.018 1.197 5.466 2.83C44.276 26.56 45 28.472 45 30.66c0 2.379-.835 4.401-2.505 6.067-1.67 1.665-3.7 2.497-6.086 2.497H13.5Zm0-3.806h22.91c1.336 0 2.465-.46 3.388-1.38.922-.92 1.384-2.046 1.384-3.378 0-1.332-.462-2.458-1.384-3.378-.923-.92-2.053-1.38-3.389-1.38h-2.864v-3.806c0-2.633-.93-4.877-2.79-6.733C28.891 13.509 26.64 12.58 24 12.58c-2.64 0-4.892.928-6.753 2.783-1.861 1.856-2.793 4.1-2.793 6.733H13.5c-1.845 0-3.42.65-4.725 1.95-1.305 1.301-1.957 2.871-1.957 4.71 0 1.84.652 3.41 1.957 4.711 1.304 1.3 2.88 1.95 4.725 1.95Z'
      fill='#002635'
    />
    <path
      d='M36.41 35.419H13.5c-1.845 0-3.42-.65-4.725-1.951-1.305-1.3-1.957-2.87-1.957-4.71 0-1.84.652-3.41 1.957-4.71 1.304-1.3 2.88-1.951 4.725-1.951h.954c0-2.633.931-4.877 2.793-6.733C19.108 13.509 21.36 12.58 24 12.58c2.64 0 4.892.928 6.754 2.783 1.861 1.856 2.791 4.1 2.791 6.733v3.806h2.864c1.337 0 2.466.46 3.389 1.38.922.92 1.384 2.046 1.384 3.378 0 1.332-.462 2.458-1.384 3.378-.923.92-2.053 1.38-3.389 1.38Z'
      fill='#E6EAEB'
    />
    <path d='M23 23.275a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z' fill='#FFF' />
    <path d='M20 24.775a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z' fill='#002635' />
    <path d='M32 23.275a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z' fill='#FFF' />
    <path
      d='M28 24.775a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM29.02 27.906a5 5 0 0 1-9.528 3.036l9.528-3.036Z'
      fill='#002635'
    />
  </svg>
)

export default SvgCloudyHappy
