import { canUser, ops } from '@weenat/client/dist/core/access'
import * as zodSchemas from '@weenat/client/dist/zod-schemas'
import { useIntl } from '@weenat/wintl'
import { Head, useSearchParams } from 'app/routx-router'
import LoginAs from 'app/src/debug/components/LoginAs'
import { useLoginAs } from 'app/state'
import { useUserContext } from 'app/userProvider'

export const settings = {
  search: zodSchemas.loginAs
}

const Debug = () => {
  const { t } = useIntl()
  const [loginAs] = useLoginAs()
  const [searchParams] = useSearchParams(settings.search)
  const { me } = useUserContext()
  if (!me) return null
  const canView = canUser(ops.view, 'debugMode', { account: me }) || loginAs !== null
  if (!canView) return null

  return (
    <>
      <Head title={t('titles.debug')} />
      <LoginAs initialEmail={searchParams?.login_as} unknownEmail={searchParams.unknownEmail} />
    </>
  )
}
export const Component = Debug
export default Debug
