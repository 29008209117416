import Text from 'app/src/kit/Text'
import { createPortal } from 'react-dom'
import { styled } from 'styled-components'
import CloseButton from '../CloseButton'
import FullScreenSurface from './FullScreenSurface'

const ChildrenContainer = styled(Flex)`
  height: 100%;
  flex: 1;
  width: 100%;
`

const HeaderContainer = styled(Flex)<{ $hasBorderBottom: boolean }>`
  width: 100%;
  align-items: center;
  justify-content: center;
  ${({ $hasBorderBottom, theme }) =>
    $hasBorderBottom &&
    `
      border-bottom: 1px solid ${theme.colors.grayscale[300]};
    `}
`

const TitleAndCrossHolder = styled(Flex)`
  width: 100%;
  max-width: 980px;
`

type Title = React.ReactElement | string

interface FullScreenProps {
  title?: Title
  subtitle?: Title
  onClose?: () => void
  isHeaderCentered?: boolean
  headerHasBorder?: boolean
  backgroundColor?: string
}

const FullScreen: FC<FullScreenProps> = ({
  children,
  title,
  subtitle,
  onClose,
  headerHasBorder = false,
  isHeaderCentered = false,
  backgroundColor
}) => {
  const textAlign = isHeaderCentered ? 'center' : 'left'

  return createPortal(
    <FullScreenSurface $backgroundColor={backgroundColor}>
      <Flex $flexDirection='column' $flex={1}>
        {/** Header */}
        <HeaderContainer $hasBorderBottom={headerHasBorder}>
          <TitleAndCrossHolder
            $alignItems='center'
            $justifyContent='space-between'
            $px='lg'
            $py='md'
          >
            <Box $alignSelf='center' $width='100%'>
              {typeof title === 'string' ? (
                <Text $fontSize='lg' $textAlign={textAlign}>
                  {title}
                </Text>
              ) : (
                title || null
              )}
              {typeof subtitle === 'string' ? (
                <Text $fontSize='sm' $textAlign={textAlign}>
                  {subtitle}
                </Text>
              ) : (
                subtitle || null
              )}
            </Box>
            {onClose ? <CloseButton onPress={onClose} /> : null}
          </TitleAndCrossHolder>
        </HeaderContainer>
        {/** Content */}
        <ChildrenContainer>{children}</ChildrenContainer>
      </Flex>
    </FullScreenSurface>,
    document.body
  )
}

export default FullScreen
