import isNil from 'lodash-es/isNil'
import noop from 'lodash-es/noop'
import Geolocation from './Geolocation'
import MapControl from './MapControl'
import MapControlsContainer from './MapControlsContainer'
import {
  AllMapsControlsType,
  defaultControlsTypes,
  MapControlOptions,
  MapsControlsOptions,
  MapsZoomControlsType,
  zoomControlsTypes
} from './utils/defaults'

const defaultZoomOptions = {
  ['ZOOM']: zoomControlsTypes.reduce(
    (acc, type) => {
      acc[type] = {
        disabled: false,
        onPress: noop
      }
      return acc
    },
    {} as Partial<Record<MapsZoomControlsType, MapControlOptions>>
  ),
  ['GEOLOCALISATOR']: {
    onSuccess: noop
  }
}

interface MapBottomControlsProps {
  controls?: AllMapsControlsType[]
  controlsOptions?: MapsControlsOptions
}

const MapBottomDefaultControls: FC<MapBottomControlsProps> = ({
  controls = defaultControlsTypes,
  controlsOptions = defaultZoomOptions
}) => {
  const shouldDisplayGeolocalisator = controls.includes('GEOLOCALISATOR')
  const shouldDisplayZoom = controls.includes('ZOOM')

  const zoomOptions = controlsOptions['ZOOM'] as Partial<
    Record<MapsZoomControlsType, MapControlOptions>
  >

  const geolocationControlsOptions = controlsOptions['GEOLOCALISATOR'] as MapControlOptions

  const zoomIn = () => {
    if (!isNil(zoomOptions) && !zoomOptions['ZOOM_IN']?.disabled) {
      zoomOptions['ZOOM_IN']?.onPress?.()
    }
  }

  const zoomOut = () => {
    if (!isNil(zoomOptions) && !zoomOptions['ZOOM_OUT']?.disabled) {
      zoomOptions['ZOOM_OUT']?.onPress?.()
    }
  }

  const onGeolocationSuccess: PositionCallback = (args) => {
    if (
      !isNil(geolocationControlsOptions) &&
      !geolocationControlsOptions.disabled &&
      !isNil(geolocationControlsOptions.onSuccess)
    ) {
      const castedCb = geolocationControlsOptions.onSuccess as PositionCallback
      castedCb(args)
    }
  }

  return (
    <MapControlsContainer>
      {shouldDisplayGeolocalisator ? <Geolocation onSuccess={onGeolocationSuccess} /> : null}
      {shouldDisplayZoom ? (
        <>
          <MapControl.zoomOut onPress={zoomOut} $active={!zoomOptions['ZOOM_OUT']?.disabled} />
          <MapControl.zoomIn onPress={zoomIn} $active={!zoomOptions['ZOOM_IN']?.disabled} />
        </>
      ) : null}
    </MapControlsContainer>
  )
}

export default MapBottomDefaultControls
