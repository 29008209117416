import { styled } from 'styled-components'

const CIRCLE_SIZE = 8
const CIRCLE_PADDING = 8

const ProgressBar = styled.div<{ $progress: number }>`
  height: ${CIRCLE_SIZE}px;
  border-radius: ${(p) => p.theme.radiuses.rounded}px;
  background-color: ${({ theme }) => theme.colors.primary[500]};
  width: ${(props) => props.$progress}px;
  transition: width 0.5s ease-out;
`

const Circle = styled(Box)`
  width: ${CIRCLE_SIZE}px;
  height: ${CIRCLE_SIZE}px;
  margin-right: ${CIRCLE_PADDING}px;
  border-radius: ${(p) => p.theme.radiuses.sm}px;
  background-color: ${({ theme }) => theme.colors.grayscale[300]};
`

const CircleContainers = styled(Flex)`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

interface StepProgressIndicatorProps {
  numberOfSteps: number
  currentStepIndex: number
}

const StepProgressIndicator: FC<StepProgressIndicatorProps> = ({
  currentStepIndex,
  numberOfSteps
}) => {
  const currentProgress = (currentStepIndex + 1) * CIRCLE_SIZE + currentStepIndex * CIRCLE_PADDING
  const width = CIRCLE_SIZE * 2 * numberOfSteps

  return (
    <Flex $position='relative' $zIndex={0} $mb={'sm'} $width={width}>
      <CircleContainers>
        {Array.from({ length: numberOfSteps }, (_, i) => (
          <Circle key={i} />
        ))}
      </CircleContainers>
      <ProgressBar $progress={currentProgress} />
    </Flex>
  )
}

export default StepProgressIndicator
