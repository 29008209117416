import { schemas, useClient, useMutation } from '@weenat/client'
import { useIntl } from '@weenat/wintl'
import useToasts from 'app/src/hooks/useToasts'
import Button from 'app/src/kit/Button'
import SuperForm from 'app/src/kit/SuperForm'
import FirstNameField from 'app/src/kit/fields/FirstNameField'
import LastNameField from 'app/src/kit/fields/LastNameField'
import { useUserContext } from 'app/userProvider'
import { useMemo } from 'react'
import { styled } from 'styled-components'
import AccountSection from './AccountSection'

const SubmitButton = styled(Button)`
  display: block;
  margin-left: auto;
  margin-top: 8px;
  align-self: flex-end;
`

/** First and Last names forms */
const EditMyInfos = () => {
  const { t } = useIntl()
  const client = useClient()
  const { addSuccessToast } = useToasts()
  const { me } = useUserContext()

  const initialValues = useMemo(() => ({ firstName: me?.firstName, lastName: me?.lastName }), [me])

  const [editMyAccount, editMyAccountState] = useMutation(client.me.update(), {
    onSuccess: () => {
      addSuccessToast(t('models.account.actions.edit_success'), {
        appearance: 'success'
      })
    }
  })

  return (
    <AccountSection title={t('titles.general_infos')}>
      <SuperForm
        schema={schemas.me.name}
        initialValues={initialValues}
        onSubmit={({ firstName, lastName }) =>
          editMyAccount({
            firstName,
            lastName
          })
        }
      >
        <FirstNameField />
        <LastNameField />
        <SubmitButton type='submit' state={editMyAccountState}>
          {t('actions.confirm')}
        </SubmitButton>
      </SuperForm>
    </AccountSection>
  )
}

export default EditMyInfos
