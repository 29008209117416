import { FontSizeToken, WeenatColor, fromColorPathToColor } from '@weenat/theme'
import { transparentize } from 'polished'
import { css, keyframes, styled } from 'styled-components'

interface LoadingCircleProps {
  color?: WeenatColor
  size?: FontSizeToken
}

const SpinAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }`

const Animation = styled.div<LoadingCircleProps>`
  ${(props) => {
    const loaderSize = props.theme.typography.sizes[props.size || 'md']
    const color = fromColorPathToColor(props.color || 'grayscale.300')

    return css`
      margin: 0 auto;
      width: ${loaderSize}px;
      height: ${loaderSize}px;
      border-radius: 50%;
      border: 2px solid ${transparentize(0.9, color)};
      position: relative;
      animation: ${SpinAnimation} 1s infinite linear;

      &::before {
        content: '';
        width: ${loaderSize}px;
        height: ${loaderSize}px;
        border-radius: 50%;
        display: block;
        position: absolute;
        left: -2px;
        top: -2px;
        border-right: 2px solid transparent;
        border-top: 2px solid transparent;
        border-left: 2px solid ${transparentize(0.75, color)};
        border-bottom: 2px solid transparent;
      }
    `
  }};
`

const LoadingCircle: FC<LoadingCircleProps> = ({ color, size = 'rg' }) => {
  return <Animation color={color} size={size} />
}

export default LoadingCircle
