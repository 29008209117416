import { usePathname } from 'app/routx-router'

const usePlotsOrDevicesFromPathname = () => {
  const pathname = usePathname()

  return pathname.startsWith('/plots')
    ? ('plots' as const)
    : pathname.startsWith('/weather-map')
      ? ('devices' as const)
      : undefined
}

export default usePlotsOrDevicesFromPathname
