import { useClient, useMutation } from '@weenat/client'
import { useToken } from 'app/state'
import { isNil } from 'lodash-es'
import { useInterval } from 'react-use'

/** Minutes between token refreshes */
const INTERVAL = 1000 * 60 * 20

const useRefreshToken = () => {
  const [token, setToken] = useToken()
  const client = useClient()

  const [refresh] = useMutation(client.auth.refreshToken(), {
    onSuccess: ({ token: newToken }) => setToken(newToken)
  })

  useInterval(() => refresh({ token: token as string }), !isNil(token) ? INTERVAL : null)
}

export default useRefreshToken
