import useDisclosure from 'app/src/hooks/useDisclosure'
import ConfirmationModal, { ConfirmationModalProps } from './ConfirmationModal'
import { IconProps } from './Icon'
import Icons from './Icons'

interface IconWithConfirmProps
  extends Pick<ConfirmationModalProps, 'confirmationMessage' | 'title'>,
    IconProps {
  onPress: () => void
  Icon: React.FunctionComponent<IconProps>
}

export const IconWithConfirm: FC<IconWithConfirmProps> = ({
  confirmationMessage,
  onPress,
  Icon,
  title,
  ...iconProps
}: IconWithConfirmProps) => {
  const { isOpen, close, open } = useDisclosure()

  return (
    <>
      {isOpen && (
        <ConfirmationModal
          title={title}
          confirmationMessage={confirmationMessage}
          isOpen={isOpen}
          onCancel={close}
          onConfirm={() => {
            close()
            onPress()
          }}
        />
      )}
      <Icon onPress={open} {...iconProps} />
    </>
  )
}

const TrashButtonWithConfirm: FC<Omit<IconWithConfirmProps, 'children' | 'Icon'>> = ({
  $size = 'lg',
  ...props
}) => <IconWithConfirm Icon={Icons.Bin} $size={$size} {...props} />

export default TrashButtonWithConfirm
