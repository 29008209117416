import { useIntl } from '@weenat/wintl'
import ListEmpty from 'app/src/kit/ListEmpty'
import { useBackgroundMapContext } from './contexts/BackgroundMapContext'

const NoSummariesMatchingSearchTermContent = () => {
  const { t } = useIntl()

  const { search } = useBackgroundMapContext()

  return (
    <ListEmpty
      model='plot'
      message={t('map.no_summaries_matching_search_term', {
        searchTerm: search
      })}
    />
  )
}

export default NoSummariesMatchingSearchTermContent
