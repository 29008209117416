import { useClient, useQuery } from '@weenat/client'
import { Network } from '@weenat/client/dist/resources/networks'
import { Location } from '@weenat/client/dist/resources/types'
import { useIntl } from '@weenat/wintl'
import Text from 'app/src/kit/Text'
import { TextFieldPrimitive } from 'app/src/kit/fields/TextField'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { useCallback, useRef, useState } from 'react'
import useDebounce from '../hooks/useDebounce'
import useDisclosure from '../hooks/useDisclosure'
import Button from '../kit/Button'
import Grid from '../kit/Grid'
import Link from '../kit/LinkComponent'
import LoadingCircle from '../kit/loaders/LoadingCircle'
import Geolocation from '../map/Geolocation'
import StandaloneGoogleMap from '../map/StandaloneGoogleMap'
import { LatLng } from '../map/utils'
import NetworksListItem from './NetworksListItem'
import WhyJoinNetworkModal from './WhyJoinNetworkModal'

const EMPTY_NETWORKS: Network[] = []

/** In km */
const SEARCH_RADIUS = 250

interface NetworksListProps {
  context?: 'onboarding' | 'networks'
}

const NetworksList = ({ context = 'networks' }: NetworksListProps) => {
  const { t } = useIntl()
  const client = useClient()

  const autocompleteInputRef = useRef<HTMLInputElement>(null)

  const { isOpen, open, close } = useDisclosure()

  const [search, setSearch] = useState<string | undefined>(undefined)
  const [currentLocation, setLocation] = useState<Location['coordinates'] | null>(null)

  const networksRequest = useQuery(
    client.networks.getMany({
      location: currentLocation ?? [],
      maxDistance: SEARCH_RADIUS
    }),
    {
      enabled: !isNil(currentLocation)
    }
  )

  const onNewLocation = useCallback(({ lng, lat }: LatLng) => setLocation([lng, lat]), [])

  const onAutocompleteInputChange = useCallback((value?: string) => {
    setSearch(value)
    if (isNil(value)) setLocation(null)
  }, [])

  const onGeolocationSuccess = useCallback(
    (location: LatLng) => {
      if (autocompleteInputRef.current) autocompleteInputRef.current.value = ''
      onNewLocation(location)
    },
    [onNewLocation]
  )

  const networks = !isNil(networksRequest.data) ? networksRequest.data.results : EMPTY_NETWORKS

  const debouncedSearch = useDebounce(search, 300)
  const isSearching = !isEmpty(debouncedSearch)

  return (
    <>
      {/* Hidden Map so we can use APIs */}
      <Box style={{ visibility: 'hidden' }}>
        <StandaloneGoogleMap
          onGoogleApiLoaded={({ maps }) => {
            if (autocompleteInputRef.current && !isNil(maps)) {
              const googlePlacesAutocompleteInput = new maps.places.Autocomplete(
                autocompleteInputRef.current
              )

              maps.event.addListener(googlePlacesAutocompleteInput, 'place_changed', () => {
                const { geometry: { location } = {} } = googlePlacesAutocompleteInput.getPlace()
                if (!isNil(location))
                  onNewLocation({
                    lng: location.lng(),
                    lat: location.lat()
                  })
              })
            }
            return null
          }}
        />
      </Box>

      {/* SEARCH BAR & GEOLOCATION */}
      <Flex $mb='lg' $gap='lg' $justifyContent='space-between' $alignItems='center'>
        <Box $flex={1}>
          <TextFieldPrimitive
            name='searchQuery'
            id='googlePlacesAutocompleteInput'
            type='text'
            label={undefined}
            ref={autocompleteInputRef}
            onChange={(e) => {
              onAutocompleteInputChange(e.currentTarget.value)
            }}
            hideErrors
          />
        </Box>
        <Geolocation
          variant='button'
          onSuccess={(result) =>
            onGeolocationSuccess({ lat: result.coords.latitude, lng: result.coords.longitude })
          }
          label={t('models.network.misc.find_network_around_me')}
        />
      </Flex>

      {/* EMPTY LIST */}
      {networksRequest.isLoading ? (
        <LoadingCircle size='lg' />
      ) : isEmpty(networks) ? (
        isSearching ? (
          <Flex
            $p='lg'
            $flexDirection='column'
            $alignItems='center'
            $justifyContent='center'
            $gap='md'
            $backgroundColor={'grayscale.100'}
            $borderRadius={'lg'}
          >
            <Text $textAlign='center'>{t('models.network.misc.no_networks_around')}</Text>
            <Flex $justifyContent='center'>
              <Link href={t('weenat.links.quotePage')} target='_blank'>
                <Button>{t('actions.ask_for_a_quote')}</Button>
              </Link>
            </Flex>
          </Flex>
        ) : (
          <>
            <Flex
              $p={48}
              $flexDirection='column'
              $alignItems='center'
              $justifyContent='center'
              $gap='md'
              $backgroundColor={'grayscale.100'}
              $borderRadius={'lg'}
            >
              <Text $fontSize='md' $textAlign='center' $fontWeight='bold'>
                {t('models.network.misc.explore_networks_around_you')}
              </Text>
              <Text $textAlign='center'>
                {t('models.network.misc.use_search_to_explore_networks')}
              </Text>
              <Link $textAlign='center' $underlined onClick={open}>
                {t('models.network.misc.presentation_why_join_title')}
              </Link>
            </Flex>
            {isOpen ? <WhyJoinNetworkModal isOpen={isOpen} close={close} /> : null}
          </>
        )
      ) : (
        <Grid $templateColumns={'1fr 1fr'} $templateRows='1fr' $gap={8}>
          {networks.map((network) => (
            <NetworksListItem key={network.id} network={network} context={context} />
          ))}
        </Grid>
      )}

      {/* LOCATION ERROR */}
      {!currentLocation && !isEmpty(networks) ? (
        <Box $pl='lg' $pb='sm'>
          <Text $fontSize='sm' $fontStyle='italic'>
            {t('models.network.misc.last_search')}
          </Text>
        </Box>
      ) : null}
    </>
  )
}

export default NetworksList
