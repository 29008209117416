import { enums, schemas, useClient, useMutation, useQuery } from '@weenat/client'
import { PossibleError } from '@weenat/client/dist/errors'
import { Org } from '@weenat/client/dist/resources/orgs'
import * as zodSchemas from '@weenat/client/dist/zod-schemas'
import { useIntl } from '@weenat/wintl'
import { useNavigate, useSearchParams } from 'app/routx-router'
import AuthCard from 'app/src/authentication/components/AuthCard'
import GoToLoginWithEmail from 'app/src/authentication/components/GoToLoginWithEmail'
import useToasts from 'app/src/hooks/useToasts'
import useWishedNetworkFromUrl from 'app/src/hooks/useWishedNetworkFromUrl'
import DelimitedFlex from 'app/src/kit/DelimitedFlex'
import SubmitButton from 'app/src/kit/SubmitButton'
import SuperForm from 'app/src/kit/SuperForm'
import Text from 'app/src/kit/Text'
import CountryPickerField from 'app/src/kit/fields/CountryPickerField'
import EmailField from 'app/src/kit/fields/EmailField'
import FirstNameField from 'app/src/kit/fields/FirstNameField'
import HasAcceptedTermsOfServiceField from 'app/src/kit/fields/HasAcceptedTermsOfServiceField'
import IsSubscribedToNewsletterField from 'app/src/kit/fields/IsSubscribedToNewsletterField'
import LastNameField from 'app/src/kit/fields/LastNameField'
import PasswordField from 'app/src/kit/fields/PasswordField'
import TelephoneFields from 'app/src/kit/fields/TelephoneFields'
import { useSelectedOrgs, useToken } from 'app/state'
import { logNavigateOnRegistration, logRegistered } from 'app/utils/analytics'
import { FormikProps, useFormikContext } from 'formik'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import { useEffect, useMemo, useRef } from 'react'
import { z } from 'zod'

export const settings = {
  search: zodSchemas.email
    .merge(zodSchemas.wishedNetwork)
    .merge(z.object({ emailInvitation: zodSchemas.emailInvitation.optional() }))
    // TODO: maybe the marketing want more
    .merge(zodSchemas.knownUtmParams),
  public: 'only'
}

const REGISTER_FORM_SCHEMA = schemas.auth.register
type RegisterFormValues = typeof REGISTER_FORM_SCHEMA.initialValues

const RegisterFields = ({
  isLoading,
  errorState
}: {
  isLoading: boolean
  errorState: PossibleError | null
}) => {
  const { values, setFieldValue } = useFormikContext<RegisterFormValues>()

  const sharedFieldsProps = { $isDisabled: isLoading, errorState }

  useEffect(() => {
    if (values.country) {
      setFieldValue('telephoneCountryCode', values.country)
    }
  }, [values.country])

  return (
    <>
      <Flex $gap='md'>
        <Box $flex={1}>
          <FirstNameField {...sharedFieldsProps} />
        </Box>
        <Box $flex={1}>
          <LastNameField {...sharedFieldsProps} />
        </Box>
      </Flex>
      <EmailField {...sharedFieldsProps} />
      <PasswordField {...sharedFieldsProps} />
      <CountryPickerField name='country' />
      <TelephoneFields {...sharedFieldsProps} errorPath='telephoneNumber' />
      <Box $width='100%' $mt='lg'>
        <HasAcceptedTermsOfServiceField {...sharedFieldsProps} />
        <IsSubscribedToNewsletterField {...sharedFieldsProps} />
      </Box>
    </>
  )
}

const EMPTY_ORGS: Omit<Org, 'virtualDevicesCount' | 'virtualDevicesQuota'>[] = []

const Register = () => {
  const { t } = useIntl()
  const client = useClient()
  const [searchParams] = useSearchParams(settings.search)
  const { addToast } = useToasts()
  const [token, setToken] = useToken()
  const nav = useNavigate()
  const [, setSelectedOrgs] = useSelectedOrgs()

  const { emailInvitation } = searchParams
  const hasEmailInvitation = !isNil(emailInvitation)

  const formRef = useRef<FormikProps<RegisterFormValues>>(null)

  const wishedNetwork = useWishedNetworkFromUrl() || searchParams?.wishedNetwork

  const networkRequest = useQuery(client.networks.get(wishedNetwork?.networkId), {
    enabled: !isNil(wishedNetwork?.networkId)
  })

  const networkName = !isNil(networkRequest.data) ? networkRequest.data.name : undefined

  const startOnboardingWithWishedNetwork = () =>
    nav('/onboarding/welcome', { search: { networkName } })

  const startOnboardingWithAcceptedInvitation = (invite: NonNullable<typeof emailInvitation>) =>
    nav('/onboarding/welcome', { search: { invite } })

  const startClassicOnboarding = () => nav('/onboarding/welcome')

  const [begToJoinNetwork, begToJoinNetworkRequest] = useMutation(
    client.invitations.beg.networkMembership(wishedNetwork?.networkId as number),
    {
      onSettled: startOnboardingWithWishedNetwork
    }
  )

  const ownedOrgsRequest = useQuery(client.orgs.getAllPages({ myRole: enums.OrgRole.owner }), {
    enabled: !isNil(token)
  })

  const [firstOwnedOrg] = ownedOrgsRequest.data ?? EMPTY_ORGS

  const [register, registerRequest] = useMutation(
    client.auth.register({
      utm_campaign: searchParams.utm_campaign,
      utm_source: searchParams.utm_source,
      utm_medium: searchParams.utm_medium,
      utm_term: searchParams.utm_term,
      utm_content: searchParams.utm_content
    }),
    {
      onError: (e) => {
        // @ts-expect-error error fields are too generic
        const emailError: string | undefined = e.fieldErrors?.email?.[0]?.message
        if (!isNil(emailError)) addToast(emailError, { appearance: 'error' })
      },
      onSuccess: (data) => {
        setToken(data.token)
        logRegistered()
      }
    }
  )

  // We consider success, and loading depending on the use case
  // If the user registers from an invite or by itself, we have to find its already existing orgs before going through, and maybe await the accept invitation request
  // If the users registers from the marketing website, we wait for the begging request to be successful
  const { isLoading, isSuccess } = wishedNetwork
    ? {
        isLoading:
          registerRequest.isPending ||
          begToJoinNetworkRequest.isPending ||
          ownedOrgsRequest.isLoading,
        isSuccess:
          registerRequest.isSuccess ||
          begToJoinNetworkRequest.isSuccess ||
          ownedOrgsRequest.isSuccess
      }
    : {
        isLoading: registerRequest.isPending || ownedOrgsRequest.isLoading,
        isSuccess: registerRequest.isSuccess || ownedOrgsRequest.isSuccess
      }

  /** We want the email from the state (to handle login/register switch with same email), not from the emailInvitation */
  const email = !isNil(searchParams?.email) ? searchParams?.email : ''

  const initialValues = useMemo(
    () => ({
      email,
      firstName: isNil(emailInvitation?.firstName) ? '' : emailInvitation?.firstName,
      lastName: isNil(emailInvitation?.lastName) ? '' : emailInvitation?.lastName,
      country: isNil(emailInvitation?.country) ? 'FR' : emailInvitation?.country,
      telephoneCountryCode: isNil(emailInvitation?.phone?.country)
        ? 'FR'
        : emailInvitation?.phone?.country,
      telephoneNumber: isNil(emailInvitation?.phone?.number) ? '' : emailInvitation?.phone?.number
    }),
    [email, emailInvitation]
  )

  const onSubmit = async (values: RegisterFormValues) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { telephoneCountryCode, telephoneNumber, ...valuesWithoutPhone } = values
    await register(!isEmpty(telephoneNumber.trim()) ? values : valuesWithoutPhone)
  }

  useEffect(() => {
    logNavigateOnRegistration()
  }, [])

  // This effect should only be triggered when registration has taken effect since it's enabled on a token test
  useEffect(
    () => {
      if (!isNil(firstOwnedOrg) && !isNil(firstOwnedOrg.id)) {
        // Forcing user org to make sure onboarding actions are done on it rather than any other orgs
        setSelectedOrgs({ organizationId: firstOwnedOrg.id, childOrganizationId: null })

        if (hasEmailInvitation) {
          startOnboardingWithAcceptedInvitation(emailInvitation)
        } else if (wishedNetwork) {
          begToJoinNetwork({ organizationId: firstOwnedOrg.id, trial: wishedNetwork.trial })
        } else {
          startClassicOnboarding()
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [firstOwnedOrg?.id]
  )

  return (
    <AuthCard>
      <Box>
        <Text $fontWeight='bold' $fontSize='md' $textAlign='center'>
          {t('models.account.actions.register')}
        </Text>
        <SuperForm
          schema={REGISTER_FORM_SCHEMA}
          onSubmit={onSubmit}
          initialValues={initialValues}
          formRef={formRef}
        >
          <RegisterFields isLoading={isLoading} errorState={registerRequest.error} />
          <SubmitButton
            style={{ width: '100%' }}
            isDisabled={isLoading}
            isLoading={isLoading}
            isError={registerRequest.isError}
            isSuccess={isSuccess}
          >
            {t('models.account.actions.register')}
          </SubmitButton>
        </SuperForm>

        <DelimitedFlex
          $isDelimitedOnTop
          $alignItems='stretch'
          $justifyContent='stretch'
          $pt='lg'
          $mt='lg'
        >
          <GoToLoginWithEmail
            style={{ width: '100%' }}
            isDisabled={isLoading}
            email={
              !isNil(formRef) && !isNil(formRef.current) ? formRef.current.values.email : undefined
            }
            wishedNetwork={
              !isNil(wishedNetwork)
                ? {
                    ...wishedNetwork,
                    networkName: networkName as string
                  }
                : undefined
            }
          />
        </DelimitedFlex>
      </Box>
    </AuthCard>
  )
}

export const Component = Register
export default Register
