import { useIntl } from '@weenat/wintl'
import Text from 'app/src/kit/Text'
import { isEmpty, isNil } from 'lodash-es'
import { memo } from 'react'
import { styled } from 'styled-components'
import Grid from '../kit/Grid'
import NetworkDeviceLocationsMap from './NetworkDeviceLocationsMap'
import { NetworkPresentationCommonProps } from './NetworkPresentationLayoutTools'

const MapOnGridHolder = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
`

const MetricCardOnGridHolder = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: stretch;

  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
`

const DeviceCardOnGridHolder = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: stretch;

  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
`

const NumberCard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  background-color: ${(p) => p.theme.colors.grayscale[100]};
  border-radius: ${(p) => p.theme.radiuses.md}px;
  padding: 16px;
  border: 1px solid ${(p) => p.theme.colors.grayscale[300]};

  box-shadow: none;
`

const metrics = ['RR', 'T', 'U', 'FF', 'FXY', 'SSI', 'PPFD', 'LW_V', 'T_WET', 'T_DRY'] as const

const NetworkResumeSection: FC<NetworkPresentationCommonProps> = ({ network }) => {
  const { t, formatNumber } = useIntl()

  return (
    <Flex $flexDirection='column' $p='lg' $gap='lg'>
      <Text $fontSize='lg' $fontWeight='bold'>
        {t('models.network.misc.network_in_numbers')}
      </Text>

      <Grid $templateRows='1fr 1fr' $templateColumns='1fr 1fr' $gap={'lg'}>
        <DeviceCardOnGridHolder>
          <NumberCard>
            <Text $fontSize='xl' $fontWeight='bold'>
              {formatNumber(network.deviceCount, {
                roundDownPrecision: network.deviceCount > 10 ? -1 : undefined
              })}
            </Text>
            <Text>{t('models.network.misc.setup_weather_stations')}</Text>
          </NumberCard>
        </DeviceCardOnGridHolder>

        <MetricCardOnGridHolder>
          <NumberCard>
            <Text $fontSize='xl' $fontWeight='bold'>
              {metrics.length}
            </Text>
            <Text>
              {t('models.network.misc.metrics_monitored_with_list', {
                list: metrics.map((m) => t(`metrics.${m}`)).join(', ')
              })}
            </Text>
          </NumberCard>
        </MetricCardOnGridHolder>

        <MapOnGridHolder>
          {!isNil(network.deviceLocations) && !isEmpty(network.deviceLocations) ? (
            <NetworkDeviceLocationsMap locations={network.deviceLocations} />
          ) : null}
        </MapOnGridHolder>
      </Grid>
    </Flex>
  )
}

export default memo(NetworkResumeSection)
