import {
  defaultTimezone,
  fromDateToMonth,
  getAllWeeksOfMonth
} from '@weenat/client/dist/core/history'
import { useIntl } from '@weenat/wintl'
import Text from 'app/src/kit/Text'
import { isNil } from 'lodash-es'
import capitalize from 'lodash-es/capitalize'
import moment from 'moment-timezone'
import { useCallback, useMemo, useState } from 'react'
import Button from '../Button'
import { IconProps } from '../Icon'
import Icons from '../Icons'
import CalendarDay from './CalendarDay'
import CalendarGrid from './CalendarGrid'

const DAY_HEIGHT = 42
const DAY_PADDING = 4

interface PeriodExtremeDisplayProps {
  dateString: string
  extreme: 'start' | 'end'
  /** Selection mode */
  variant: 'singleDate' | 'period'
}

const PeriodExtremeDisplay: FC<PeriodExtremeDisplayProps> = ({ dateString, extreme, variant }) => {
  const { t } = useIntl()

  const textColor = 'grayscale.white'

  return (
    <Flex
      $flexDirection='column'
      $gap='xs'
      $backgroundColor='primary.500'
      $borderRadius={'md'}
      $p='md'
      $flex={1}
    >
      <Text $fontSize='sm' $color={textColor}>
        {t(variant === 'period' ? `periods.${extreme}` : 'calendar.date_picked', {
          capitalize: true
        })}
      </Text>
      <Text $fontWeight='bold' $color={textColor}>
        {dateString}
      </Text>
    </Flex>
  )
}

type CalendarValue = [Date, Date] | Date

interface Month {
  index: number
  year: number
}

const now = new Date(Date.now())

export interface WeenatCalendarProps {
  /** The date at which the calendar should open */
  initialMonth?: Month
  /** Minimum date before which no date can be selected */
  minDate?: Date
  /** Maximum date after which no date can be selected */
  maxDate?: Date
  /** Maximum relative date compute from startDate after which no date can be selected */
  relativeMaxDate?: (startDate: Date) => Date
  /** Method called when the period is changed */
  onCancel?: () => void
  /** Method called when the period is changed */
  onChange: (value: CalendarValue) => void
  /** User timezone */
  timezone: string
  /** Currently selected period of time */
  value: CalendarValue
  /** Selection mode */
  variant: 'singleDate' | 'period'
}

const isPeriod = (value: CalendarValue): value is [Date, Date] => {
  return Array.isArray(value)
}

/**
 * Calendar per month
 */
const WeenatCalendar: FC<WeenatCalendarProps> = ({
  initialMonth,
  maxDate,
  relativeMaxDate,
  minDate,
  onCancel,
  onChange,
  timezone = defaultTimezone,
  value,
  variant
}) => {
  const { t } = useIntl()

  const [currentMonth, setCurrentMonth] = useState<Month>(initialMonth ?? fromDateToMonth(now))

  const [selectedValue, setSelectedValue] = useState<CalendarValue>(value)
  const [displayedValue, setDisplayedValue] = useState<CalendarValue>(value)

  const momentFromMonth = moment()
    .tz(timezone)
    .set('month', currentMonth.index)
    .set('year', currentMonth.year)

  const nextMonth = () => {
    const newDate = momentFromMonth.clone().add(1, 'month').toDate()
    setCurrentMonth(fromDateToMonth(newDate))
  }

  const nextYear = () => {
    const newDate = momentFromMonth.clone().add(1, 'year').toDate()
    setCurrentMonth(fromDateToMonth(newDate))
  }

  const previousMonth = () => {
    const newDate = momentFromMonth.clone().subtract(1, 'month').toDate()
    setCurrentMonth(fromDateToMonth(newDate))
  }

  const previousYear = () => {
    const newDate = momentFromMonth.clone().subtract(1, 'year').toDate()
    setCurrentMonth(fromDateToMonth(newDate))
  }

  const handleSelectionChange = useCallback(
    (newDate: Date) => {
      const newMoment = moment(newDate).tz(timezone).startOf('day')

      if (isPeriod(selectedValue)) {
        let newPeriod: [Date, Date] = [
          newMoment.clone().toDate(),
          newMoment.clone().add(1, 'day').toDate()
        ]

        // IF the selected date is after the currently selected period AND isn't inside the current period
        if (
          newMoment.isAfter(moment(selectedValue[0]).tz(timezone), 'date') &&
          !newMoment.isBetween(
            moment(selectedValue[0]).tz(timezone),
            moment(selectedValue[1]).tz(timezone),
            'day',
            '[)'
          )
        ) {
          newPeriod = [selectedValue[0], newPeriod[1]]
        }

        setSelectedValue(newPeriod)
        setDisplayedValue([
          newPeriod[0],
          moment(newPeriod[1]).tz(timezone).subtract(1, 'second').toDate()
        ])
      } else {
        setSelectedValue(newMoment.clone().toDate())
        setDisplayedValue(newMoment.clone().toDate())
      }
    },
    [selectedValue, timezone]
  )

  const daysOfTheMonth = useMemo(() => {
    return getAllWeeksOfMonth({ index: currentMonth.index, year: currentMonth.year, timezone })
  }, [currentMonth.index, currentMonth.year, timezone])

  const iconProps: Partial<IconProps> = {
    $size: 'lg',
    $p: 'md',
    $backgroundColor: `grayscale.100`
  }

  return (
    <Flex $flexDirection='column' height='100%'>
      {/* Selection Header */}
      <Flex $my={1} $alignItems='center' $gap='md'>
        <PeriodExtremeDisplay
          extreme='start'
          variant={variant}
          dateString={moment(isPeriod(displayedValue) ? displayedValue[0] : displayedValue)
            .tz(timezone)
            .format('DD/MM/YYYY')}
        />

        {isPeriod(displayedValue) ? (
          <PeriodExtremeDisplay
            extreme='end'
            variant={variant}
            dateString={moment(displayedValue[1]).tz(timezone).format('DD/MM/YYYY')}
          />
        ) : null}
      </Flex>
      {/* Navigation Header */}
      <Flex $my='md' $gap='md' $alignItems='center'>
        <Icons.FastForward $rotate={180} onPress={previousYear} {...iconProps} />
        <Icons.ArrowRight $rotate={180} onPress={previousMonth} {...iconProps} />
        <Box $flex={1}>
          <Text $fontWeight='bold' $textAlign='center'>
            {capitalize(momentFromMonth.clone().format('MMMM YYYY'))}
          </Text>
        </Box>
        <Icons.ArrowRight onPress={nextMonth} {...iconProps} />
        <Icons.FastForward onPress={nextYear} {...iconProps} />
      </Flex>
      {/* Grid */}
      <CalendarGrid dayHeight={DAY_HEIGHT} dayPadding={DAY_PADDING}>
        {daysOfTheMonth.map((day) => {
          const currentMoment = moment(day).tz(timezone)

          let sinceMoment = undefined
          let beforeMoment = undefined
          let isSelected = false

          if (isPeriod(displayedValue)) {
            sinceMoment = moment(displayedValue[0]).tz(timezone)
            beforeMoment = moment(displayedValue[1]).tz(timezone)

            // Is selected if is the same or is included inside the period
            isSelected = currentMoment.isBetween(sinceMoment, beforeMoment, 'day', '[]')
          } else {
            sinceMoment = moment(displayedValue).tz(timezone).startOf('day')
            beforeMoment = moment(displayedValue).tz(timezone).endOf('day')

            isSelected = currentMoment.isSame(sinceMoment, 'day')
          }

          const isFromAnotherMonth = day.getMonth() !== currentMonth.index

          const isAfterMaxDate = maxDate
            ? currentMoment.isAfter(maxDate)
            : relativeMaxDate
              ? currentMoment.isAfter(relativeMaxDate(sinceMoment.clone().toDate()))
              : false
          const isBeforeMinDate = minDate ? currentMoment.isBefore(minDate) : false

          const isDisabled = isBeforeMinDate || isAfterMaxDate

          const isFirstOfSelection = currentMoment.isSame(sinceMoment, 'day')
          const isLastOfSelection = currentMoment.isSame(beforeMoment, 'day')

          return (
            <CalendarDay
              key={currentMoment.unix()}
              day={day}
              height={DAY_HEIGHT}
              isDisabled={isDisabled}
              isSelected={isSelected}
              isFirstOfSelection={isFirstOfSelection}
              isFromAnotherMonth={isFromAnotherMonth}
              isLastOfSelection={isLastOfSelection}
              onPress={() => handleSelectionChange(day)}
              timezone={timezone}
            />
          )
        })}
      </CalendarGrid>
      <Flex $my='md' $alignItems='center' $justifyContent='flex-end' $gap='md'>
        {!isNil(onCancel) ? (
          <Button importance='sd' onPress={onCancel}>
            {t('actions.cancel')}
          </Button>
        ) : null}
        <Button onPress={() => onChange(selectedValue)}>{t('actions.confirm')}</Button>
      </Flex>
    </Flex>
  )
}

export default WeenatCalendar
