import { Href } from '@weenat/client/dist/routx/runtime-core'
import { Redirect } from 'app/routx-router'
import { useToken } from 'app/state'
import isNil from 'lodash-es/isNil'

const RedirectToPlotsOrLogin = () => {
  const [token] = useToken()
  // TODO: replaced by useProtectedRoute
  const redirection: Href = !isNil(token) ? '/plots' : '/auth/login'
  return <Redirect to={redirection} />
}

export default RedirectToPlotsOrLogin
