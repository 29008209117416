function SvgWind(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 512 512' {...props}>
      <path
        d='M225.67 322.79c-6.505.555-11.796 8.902-11.388 17.788.407 8.902 6.505 16.125 13.018 15.571h8.136c6.513 0 12.212-7.777 12.212-16.68 0-8.902-6.106-16.68-12.212-16.68h-9.766zm-115.63 0c-7.215.555-13.076 8.902-12.627 17.788.448 8.902 7.214 16.125 14.437 15.571h72.18c7.215 0 13.533-7.777 13.533-16.68 0-8.902-6.766-16.68-13.533-16.68h-73.99zm-33.815-33.391c-6.978.424-12.644 6.684-12.203 13.353.432 6.685 6.97 12.114 13.948 11.69h309.02c19.598 0 34.859 14.609 34.859 33.391 0 18.783-15.261 33.392-34.859 33.392h-4.37c-19.598 0-34.859-14.609-34.859-33.392v-12.522c0-6.685-6.098-12.522-13.076-12.522-6.978 0-13.076 6.261-13.076 12.522v12.522c-.44 32.135 27.456 58.435 61.01 58.435h4.37c33.554 0 61.01-26.299 61.01-58.435s-27.456-58.435-61.01-58.435H76.229zm-.815-66.783c-6.506.555-11.796 8.902-11.388 17.788.407 8.902 6.505 16.125 13.018 15.57h8.136c6.513 0 12.212-7.777 12.212-16.679 0-8.902-6.107-16.68-12.212-16.68H75.41zm84.905-66.782c-26.52 0-49.443 18.212-49.443 41.739 0 6.065 6.294 11.381 13.484 11.381 7.198 0 13.484-5.69 13.484-11.381 0-10.63 11.242-18.978 22.476-18.978h4.5c11.234 0 22.476 8.348 22.476 18.978 0 11.005-10.337 18.978-21.58 18.978h-54.838c-7.19 0-13.484 5.299-13.484 11.381 0 6.066 6.741 11.381 13.484 11.381h54.839c26.519 0 48.546-18.587 48.546-41.739 0-23.527-22.924-41.739-49.443-41.739h-4.5zm195.53-50.087c-47.38 0-82.492 37.565-83.372 83.479v20.869c0 6.685 6.146 12.522 13.165 12.522 7.01 0 13.174-6.261 13.174-12.522v-20.869c.424-33.815 24.13-58.436 57.032-58.436h4.402c32.903 0 61.419 25.468 61.419 58.436 0 32.967-28.516 58.435-61.42 58.435h-249.69c-7.018 0-13.164 5.837-13.164 12.522 0 6.685 6.579 12.522 13.165 12.522h249.69c47.38 0 87.75-36.734 87.75-83.478 0-46.745-40.37-83.479-87.75-83.479z'
        fill='currentColor'
      />
    </svg>
  )
}

export default SvgWind
