import { getDeviceCommonName } from '@weenat/client/dist/core/devices/getDeviceCommonName'
import { Device } from '@weenat/client/dist/resources/devices'
import { useIntl } from '@weenat/wintl'
import useLogAndNavToUpgradePlans from 'app/src/hooks/useLogAndNavToUpgradePlans'
import Button from 'app/src/kit/Button'
import HeroBox from 'app/src/kit/HeroBox'
import Icons from 'app/src/kit/Icons'
import Modal from 'app/src/kit/Modal'
import SmartText from 'app/src/kit/SmartText'
import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import { useTheme } from 'styled-components'

const deviceName = (device?: Device) => {
  if (isNil(device)) return '-'

  const [model, serial] = device.endUserName
  const technicalName = `${model} (${serial})`

  return `${getDeviceCommonName(device)} ${technicalName}`
}

interface FreemiumWithDeviceCTAModalProps {
  device: Device | undefined
  onCloseHandler: () => void
}

const FreemiumWithDeviceCTAModal: FC<FreemiumWithDeviceCTAModalProps> = ({
  device,
  onCloseHandler
}) => {
  const { t } = useIntl()
  const { colors } = useTheme()

  const logAndNav = useLogAndNavToUpgradePlans()

  return (
    <Modal isOpen hasCloseIcon={false} width={600}>
      <Flex $flexDirection={'column'} $gap='lg' $mb={16}>
        <HeroBox
          width='100%'
          $justifyContent='center'
          $alignItems='center'
          $backgroundColor={colors.feedback.success[200]}
        >
          <Icons.CheckCircle $size='2xl' $color={'feedback.success.500'} />
        </HeroBox>

        {!isNil(device) ? (
          <SmartText
            tkey='models.device.actions.create_success_with_name'
            vars={{ deviceName: deviceName(device) }}
            $textAlign='center'
            $fontWeight='bold'
            $fontSize='lg'
          />
        ) : null}

        <Text $textAlign='center'>{t('ecommerce.plans.restrictions.add_device_freemium_cta')}</Text>
      </Flex>
      <Flex $flexDirection='column' $gap='lg'>
        <Button onPress={logAndNav}>{t('actions.i_subscribe')}</Button>
        <Button importance='sd' onPress={onCloseHandler}>
          {t('actions.ignore')}
        </Button>
      </Flex>
      <Box />
    </Modal>
  )
}

export default FreemiumWithDeviceCTAModal
