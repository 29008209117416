import { extendMoment } from 'moment-range'
import momentTZ from 'moment-timezone'
import 'moment/dist/locale/de'
import 'moment/dist/locale/es'
import 'moment/dist/locale/fr'
import 'moment/dist/locale/it'
import 'moment/dist/locale/pl'
import 'moment/dist/locale/pt'
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
extendMoment(momentTZ as any)
