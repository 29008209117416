import { styled } from 'styled-components'

const RoundedSurface = styled(Box)`
  background-color: ${(props) => props.theme.colors.grayscale[50]};
  border-radius: ${(props) => props.theme.radiuses.rounded}px;
  border-color: ${(props) => props.theme.colors.grayscale[700]};
  padding: ${(p) => p.theme.spacings.xs}px;

  box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
`

const MapControlsContainer: FC = ({ children }) => (
  <Flex $pointerEvents='all' $alignItems='center' $alignSelf='flex-end'>
    <RoundedSurface>
      <Flex $flexDirection='row' $alignItems='center' $justifyContent='space-between'>
        {children}
      </Flex>
    </RoundedSurface>
  </Flex>
)

export default MapControlsContainer
