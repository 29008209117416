import Text from 'app/src/kit/Text'
import isNil from 'lodash-es/isNil'
import { styled, useTheme } from 'styled-components'
import CountIndicator from './CountIndicator'

const Tab = styled(Flex)<{ $isFocused: boolean }>`
  position: relative;

  align-items: center;
  justify-content: center;

  overflow: hidden;

  padding: ${(p) => p.theme.spacings.lg}px;
  gap: ${(p) => p.theme.spacings.md}px;

  background-color: ${(p) => (p.$isFocused ? p.theme.colors.primary[200] : 'transparent')};

  border-top-left-radius: ${(p) => p.theme.radiuses.md}px;
  border-top-right-radius: ${(p) => p.theme.radiuses.md}px;

  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme, $isFocused }) => ($isFocused ? theme.colors.primary[500] : 'inherit')};

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary[200]};
    cursor: pointer;
  }

  transition: background-color 0.2s linear;
  will-change: background-color;
`

const ActivityIndicator = styled.span<{ $isFocused: boolean }>`
  position: absolute;

  bottom: 0px;
  left: 0px;

  height: 2px;

  width: 100%;

  background-color: ${({ theme, $isFocused }) =>
    $isFocused ? theme.colors.primary[500] : theme.colors.grayscale[300]};

  transition: background-color 0.2s linear;
  will-change: background-color;
`

interface TabsProps<V> {
  currentTab: V
  tabs: {
    label: string
    value: V
    onPress?: () => void
    indicator?: number
  }[]
  onTabChange: (value: V) => void
  flexTabs?: boolean
  className?: string
}

function Tabs<V>({ className, currentTab, tabs, onTabChange, flexTabs = false }: TabsProps<V>) {
  const { colors } = useTheme()

  return (
    <Flex className={className}>
      {tabs.map((tab) => {
        const isFocused = currentTab === tab.value
        const color = isFocused ? colors.primary[500] : colors.grayscale.black

        return (
          <Tab
            key={tab.value}
            $flex={flexTabs ? 1 : undefined}
            $isFocused={isFocused}
            onClick={() => {
              tab.onPress?.()
              onTabChange(tab.value)
            }}
          >
            <Text $fontWeight='bold' $textAlign='center' $color={color} $ellipsis>
              {tab.label}
            </Text>
            {!isNil(tab.indicator) ? (
              <CountIndicator
                count={tab.indicator}
                backgroundColor={color}
                color={colors.grayscale.white}
              />
            ) : null}
            <ActivityIndicator $isFocused={isFocused} />
          </Tab>
        )
      })}
    </Flex>
  )
}

export default Tabs
