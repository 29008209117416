import { BoxProps, FlexProps } from 'app/src/kit/primitives/themeMappings/props'
import isNil from 'lodash-es/isNil'

export interface CardProps extends Omit<FlexProps, 'onClick' | 'title'> {
  onPress?: BoxProps['onClick']
}

const Card: FC<CardProps> = ({
  $backgroundColor = 'grayscale.white',
  children,
  $boxShadow = undefined,
  onPress,
  className,
  $borderWidth = 'sm',
  $borderColor = 'grayscale.300',
  $borderRadius = 'lg',
  $p = 'lg',
  ...rest
}) => {
  return (
    <Flex
      $flexDirection='column'
      $p={$p}
      $backgroundColor={$backgroundColor}
      onClick={onPress}
      $boxShadow={$boxShadow}
      className={className}
      $borderColor={$borderColor}
      $borderWidth={$borderWidth}
      $borderRadius={$borderRadius}
      $cursor={!isNil(onPress) ? 'pointer' : undefined}
      {...rest}
    >
      {children}
    </Flex>
  )
}

export default Card
