import { schemas, useClient, useQuery } from '@weenat/client'
import { AlertCreationType } from '@weenat/client/dist/core/alerts'
import { useIntl } from '@weenat/wintl'
import InfoCard from 'app/src/kit/InfoCard'
import SuperForm from 'app/src/kit/SuperForm'
import CheckboxField from 'app/src/kit/fields/CheckboxField'
import TelephoneFields from 'app/src/kit/fields/TelephoneFields'
import UseOnChange from 'app/src/kit/fields/UseOnChange'
import isEmpty from 'lodash-es/isEmpty'
import isNil from 'lodash-es/isNil'
import noop from 'lodash-es/noop'
import { useMemo, useState } from 'react'
import AlertCreationStep, { AlertCreationStepProps } from './AlertCreationStep'

type StepResult = {
  isVoiceCallEnabled: boolean
  telephoneNumber?: string
  telephoneCountryCode?: string
}

type NotificationMethodStepProps = AlertCreationStepProps<StepResult>

type PickAlertNotificationMethodProps = NotificationMethodStepProps & {
  isVoiceCallEnabled: boolean
  currentAlertCreationType: AlertCreationType
}

const PickAlertNotificationMethod: FC<PickAlertNotificationMethodProps> = ({
  isVoiceCallEnabled: initialVoiceCallEnabled,
  onPressNext = noop,
  currentAlertCreationType,
  ...props
}) => {
  const { t } = useIntl()
  const client = useClient()

  const allPhoneNumbersRequest = useQuery(client.me.phoneNumbers.getAll())
  const allPhones = allPhoneNumbersRequest.data ?? []

  const primaryNumber = allPhones.find((phone) => phone.isPrimary)

  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const [stepResult, setStepResult] = useState<StepResult>({
    isVoiceCallEnabled: initialVoiceCallEnabled
  })

  const initialValues = useMemo(
    () => ({
      isVoiceCallEnabled: initialVoiceCallEnabled,
      telephoneNumber: !isNil(primaryNumber) ? primaryNumber.number : '',
      telephoneCountryCode: !isNil(primaryNumber) ? primaryNumber.countryCode : 'FR'
    }),
    [primaryNumber, initialVoiceCallEnabled]
  )

  return (
    <SuperForm
      schema={schemas.alertCreation.notificationMethod}
      initialValues={initialValues}
      onSubmit={noop}
    >
      {({ errors, values, isValid }) => (
        <>
          <AlertCreationStep
            {...props}
            canProgressToNext={isFormValid}
            onPressNext={() => onPressNext(stepResult)}
          >
            <UseOnChange
              onChange={({
                isVoiceCallEnabled,
                telephoneNumber,
                telephoneCountryCode
              }: StepResult) => {
                let isReallyValid = false

                if (isVoiceCallEnabled) {
                  isReallyValid =
                    isNil(errors.telephoneNumber) &&
                    !isEmpty(telephoneNumber) &&
                    isNil(errors.telephoneCountryCode) &&
                    !isEmpty(telephoneCountryCode)
                } else {
                  isReallyValid = true
                }

                setIsFormValid(isValid && isReallyValid)
                setStepResult({
                  isVoiceCallEnabled,
                  telephoneNumber: !isEmpty(telephoneNumber) ? telephoneNumber : undefined,
                  telephoneCountryCode: !isEmpty(telephoneNumber) ? telephoneCountryCode : undefined
                })
              }}
            />
            <CheckboxField
              name={'isVoiceCallEnabled'}
              label={t('alerts.paramSetups.fieldLabels.enable_voice_call')}
            />
            {values.isVoiceCallEnabled && <TelephoneFields isRequired />}
            <InfoCard
              title={t('alerts.voice_call_title')}
              message={t('alerts.voice_call_for_frost_explanation')}
              type={'info'}
            />
          </AlertCreationStep>
        </>
      )}
    </SuperForm>
  )
}

export default PickAlertNotificationMethod
