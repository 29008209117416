import { MouseEventHandler } from 'react'
import { useWindowSize } from 'react-use'
import { css, styled, useTheme } from 'styled-components'
import Icons from '../Icons'
import Text from '../Text'
import TextTooltip from '../tooltips/TextTooltip'

const CIRCLE_SIZE = 24
const BREAK_POINT = 1280

const StepItemContainer = styled(Flex)<{ $isInteractive: boolean }>`
  align-items: center;
  justify-content: center;

  gap: ${({ theme }) => theme.spacings.md}px;
  padding: ${({ theme }) => theme.spacings.sm}px;
  border-radius: ${({ theme }) => theme.radiuses.sm}px;

  ${({ theme, $isInteractive }) =>
    $isInteractive &&
    css`
      &:hover {
        background-color: ${theme.colors.grayscale[100]};
        cursor: pointer;
      }
    `}
`

const StepNumberContainer = styled(Flex)<{ $borderColor: string }>`
  align-items: center;
  justify-content: center;

  width: ${CIRCLE_SIZE}px;
  height: ${CIRCLE_SIZE}px;

  border-radius: ${({ theme }) => theme.radiuses.rounded}px;
  border: solid 1px ${({ $borderColor }) => $borderColor};
`

const ResponsiveLabel = styled(Text)`
  @media (max-width: ${BREAK_POINT}px) {
    display: none;
  }
`

interface StepItemProps {
  stepNumber: number
  label: string
  isActive: boolean
  isCompleted: boolean
  onPress?: MouseEventHandler<HTMLDivElement>
}

const StepItem: FC<StepItemProps> = ({
  stepNumber,
  label,
  isActive = false,
  isCompleted = false,
  onPress
}) => {
  const { colors } = useTheme()
  const { width } = useWindowSize()

  const borderColor = isCompleted
    ? colors.feedback.success[800]
    : isActive
      ? colors.primary[500]
      : colors.grayscale[300]
  const labelColor = isActive ? 'primary.500' : 'grayscale.black'
  const indicatorColor = isCompleted ? colors.feedback.success[500] : 'transparent'

  const Label = isActive ? Text : ResponsiveLabel

  return (
    <TextTooltip content={label} disabled={isActive || width > BREAK_POINT}>
      <StepItemContainer onClick={isCompleted ? onPress : undefined} $isInteractive={isCompleted}>
        <StepNumberContainer $backgroundColor={indicatorColor} $borderColor={borderColor}>
          {isCompleted ? (
            <Icons.Checkmark $size='rg' $color={'grayscale.white'} />
          ) : (
            <Text
              $color={labelColor}
              $fontSize='sm'
              $fontWeight='bold'
              $textAlign='center'
              $lineHeight='none'
            >
              {stepNumber}
            </Text>
          )}
        </StepNumberContainer>
        <Label $color={labelColor} $fontWeight='medium' $lineHeight='none'>
          {label}
        </Label>
      </StepItemContainer>
    </TextTooltip>
  )
}

const StepsContainer = styled(Flex)`
  width: 100%;
  gap: 16px;
  align-items: center;
  justify-content: center;
`

interface StepInterface {
  id: number | string
  label: string
  onPress?: MouseEventHandler<HTMLDivElement>
}

export interface FullScreenStepProgressIndicatorProps {
  steps: StepInterface[]
  currentStep: StepInterface
}

const FullScreenStepProgressIndicator: FC<FullScreenStepProgressIndicatorProps> = ({
  steps,
  currentStep
}) => {
  const currentIndex = steps.findIndex((stp) => stp.id === currentStep.id)

  return (
    <StepsContainer>
      {steps.map(({ label, id, onPress }, i) => {
        const isActive = currentStep.id === id
        const isComplete = currentIndex > i
        return (
          <StepItem
            key={id}
            label={label}
            stepNumber={i + 1}
            isActive={isActive}
            isCompleted={isComplete}
            onPress={onPress}
          />
        )
      })}
    </StepsContainer>
  )
}

export default FullScreenStepProgressIndicator
