function IrrigationSensors(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M13 4C13 3.44772 12.5523 3 12 3C11.4477 3 11 3.44772 11 4V8.17071C11.3128 8.06015 11.6494 8 12 8C12.3506 8 12.6872 8.06015 13 8.17071V4Z' />
      <path d='M13 13.8293C12.6872 13.9398 12.3506 14 12 14C11.6494 14 11.3128 13.9398 11 13.8293V20.2C11 20.7523 11.4477 21.2 12 21.2C12.5523 21.2 13 20.7523 13 20.2V13.8293Z' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6 7C6 5.89543 6.89543 5 8 5H16C17.1046 5 18 5.89543 18 7V13.126C17.6804 13.0438 17.3453 13 17 13C14.7909 13 13 14.7909 13 17H8C6.89543 17 6 16.1046 6 15V7ZM15 11C15 12.6569 13.6569 14 12 14C10.3431 14 9 12.6569 9 11C9 9.34315 10.3431 8 12 8C13.6569 8 15 9.34315 15 11Z'
      />
      <path d='M17 20C16.2611 20 15.6319 19.7444 15.1125 19.2333C14.5931 18.7222 14.3333 18.1021 14.3333 17.3731C14.3333 17.0058 14.4028 16.6663 14.5417 16.3547C14.6806 16.043 14.8722 15.7648 15.1167 15.5199L17 13.6667L18.8833 15.5199C19.1278 15.7648 19.3194 16.043 19.4583 16.3547C19.5972 16.6663 19.6667 17.0058 19.6667 17.3731C19.6667 18.1021 19.4069 18.7222 18.8875 19.2333C18.3681 19.7444 17.7389 20 17 20Z' />
    </svg>
  )
}

export default IrrigationSensors
