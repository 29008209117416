import { useIntl } from '@weenat/wintl'
import Text from 'app/src/kit/Text'
import { styled, useTheme } from 'styled-components'

const StyledBox = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: ${(p) => p.theme.spacings.md}px;
  padding: ${(p) => p.theme.spacings.lg}px;
  background-color: ${(p) => p.theme.colors.primary[500]};
  border: 1px solid ${(p) => p.theme.colors.primary[800]};
  border-radius: ${(p) => p.theme.radiuses.md}px;
`

const RestrictedDataBox: FC = () => {
  const { t } = useIntl()
  const { colors } = useTheme()

  return (
    <StyledBox>
      <Text $textAlign='center' $color={colors.grayscale.white}>
        {t('ecommerce.plans.irrigation_data_restricted_for_non_expert')}
      </Text>
    </StyledBox>
  )
}

export default RestrictedDataBox
