import { useIntl } from '@weenat/wintl'
import AdminBadge from './AdminBadge'

const TrialBadge: FC = () => {
  const { t } = useIntl()

  return (
    <AdminBadge
      $backgroundColor={'metrics.U.500'}
      labelColor={'grayscale.white'}
      label={t(`models.network.misc.trial`)}
    />
  )
}

export default TrialBadge
