function SvgDecitraitLeaf(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 72 72'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      preserveAspectRatio='xMidYMid'
      {...props}
    >
      <path
        d='M24 62l12-10 9.5 10H63l-3.5-15.5-4-3.5 9-6.5 4-17-17 1-6 4.5V15l-10-13L25 15v10l-5-4.5-17-1 4 17 8.5 6.5-3.5 3.5L8 62h16z'
        stroke='currentColor'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M36 52v18' stroke='currentColor' />
    </svg>
  )
}

export default SvgDecitraitLeaf
