import { getDeviceCommonName } from '@weenat/client/dist/core/devices'
import { metricsWithEditableHorizon } from '@weenat/client/dist/enums/MetricIds'
import { Device } from '@weenat/client/dist/resources/devices'
import { useIntl } from '@weenat/wintl'
import DeviceOriginBadge from 'app/src/administration/devices/DeviceOriginBadge'
import StackedMetrics from 'app/src/kit/StackedMetrics'
import Text from 'app/src/kit/Text'
import intersection from 'lodash-es/intersection'
import isEmpty from 'lodash-es/isEmpty'
import { memo } from 'react'
import { useTheme } from 'styled-components'

interface DeviceCardProps {
  variant?: 'full' | 'summary'
  device: Device
  depth?: string
}

const DeviceCard: FC<DeviceCardProps> = ({ variant = 'full', device, depth }) => {
  const { colors } = useTheme()
  const { t, formatRelativeTimeToNow } = useIntl()

  const showHorizons = !isEmpty(intersection(metricsWithEditableHorizon, device.availableMeasures))

  const technicalName = `${device.endUserName.join(' ')}`

  return (
    <Box
      $backgroundColor={colors.grayscale.white}
      $p='md'
      $borderRadius={12}
      $borderColor={colors.grayscale[100]}
      $borderWidth='sm'
    >
      <Flex $justifyContent='space-between' $alignItems='center' $mb='md'>
        <Flex $gap='lg' $alignItems='center'>
          {variant === 'full' && <DeviceOriginBadge device={device} />}
        </Flex>
        <StackedMetrics metrics={device.availableMeasures} />
      </Flex>
      <Flex $flexDirection='column' $gap='xs'>
        <Text $fontWeight='semiBold'>{getDeviceCommonName(device)}</Text>
        <Flex $gap='lg'>
          <Text>{technicalName}</Text>
          {showHorizons && !isEmpty(depth) && (
            <Text>{`- ${depth} ${t('units.centimeters_abbr')}`}</Text>
          )}
        </Flex>
        {variant === 'full' && (
          <Text $fontSize='sm'>
            {t('models.device.last_broadcast', {
              timeAgo: formatRelativeTimeToNow(device.lastMeasuredAt)
            })}
          </Text>
        )}
      </Flex>
    </Box>
  )
}

export default memo(DeviceCard)
